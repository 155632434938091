import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { Sidebar } from '../../shared/sidebar';
import {
  Button,
} from 'reactstrap';
import { staticConstants, CSS_ClASSES } from '../../../utils/staticConstants';
import OpenFieldTypes from 'OpenFieldTypes';
import { GoalEvents } from './listing/goalEvents';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
}
class EventManagementContainer extends React.Component<IProps> {

  public state = {
    activeTab: staticConstants.ADMIN_REQUEST_MANAGEMENT_TAB.ADMIN_ORGANIZATION_ADMIN_REQUESTS,
    activeTabRole: staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.ADMIN_LEARNING_GOALS,
  };


  /**
   * @description
   * render is used to render the html.
   */
  public render() {
    const { activeTab, activeTabRole } = this.state;
    return (
      <React.Fragment>
        <Sidebar {...this.props} />
        <div className="dashboard-wrapper">
          <h2 className="heading heading-sm roboto-medium text-uppercase">UPCOMING EVENTS</h2>
          <div className="dashboard-content">
            <div className="tab-btn-group">
              <Button
                color="secondary"
                className={'btn btn-50 h-50 tab-btn text-uppercase ' + (activeTab === staticConstants.ADMIN_REQUEST_MANAGEMENT_TAB.ADMIN_ORGANIZATION_ADMIN_REQUESTS ? CSS_ClASSES.btnActive : '')}
                onClick={() => this.setState({ activeTab: staticConstants.ADMIN_REQUEST_MANAGEMENT_TAB.ADMIN_ORGANIZATION_ADMIN_REQUESTS, activeTabRole: staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.ADMIN_LEARNING_GOALS })}
              >
                team admin
              </Button>
              <Button
                color="secondary"
                className={'btn btn-50 h-50 tab-btn text-uppercase ' + (activeTab === staticConstants.ADMIN_REQUEST_MANAGEMENT_TAB.ADMIN_PROFESSIONAL_REQUESTS ? CSS_ClASSES.btnActive : '')}
                onClick={() => this.setState({ activeTab: staticConstants.ADMIN_REQUEST_MANAGEMENT_TAB.ADMIN_PROFESSIONAL_REQUESTS, activeTabRole: staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.PROFESSIONSAL_LEARNING_GOALS })}
              >
                individual
              </Button>
            </div>
            <GoalEvents activeTab={activeTab} activeTabRole={activeTabRole} redirectFrom={'eventManagement'} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  return {};
}

const connectedEventManagementContainer = connect(mapStateToProps)(EventManagementContainer);
export { connectedEventManagementContainer as EventManagementContainer };
