import { ICreatePasswordReq } from '../../../../interfaces';
import { pageRoutes } from '../../../../utils/routeConstants';
import { staticConstants } from '../../../../utils/staticConstants';
import { history } from './../../../../helpers';
import { userServices } from './../../../../services/userServices';
import { alertActions } from './../../../alert/redux/actions';
import { actionTypes as authActionTypes } from './../../../auth/redux/types';
import { endLoading, startLoading } from './../../../loader/redux/actions';
import { actionTypes } from './types';
import OpenFieldTypes from 'OpenFieldTypes';

export const createPasswordAction = {
  createPassword,
  verifyToken,
};

function createPassword(data: ICreatePasswordReq) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(data));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.createPassword(data)
      .then(
        (user) => {
          dispatch(endLoading('MAIN_LOADER'));
          const isPaid = user.isPaid;
          const role = user.role;
          setTimeout(() => {
            if (role === staticConstants.ROLE.EXPERT) {
              dispatch({ type: authActionTypes.LOGIN_SUCCESS, user });
              if (user.isFirstTime) {
                history.push(`${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.EDIT_PROFILE.PATH}`);    // Todo: delete
              } else {
                history.push(`${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.DASHBOARD}`);
              }
            } else {
              if (role === staticConstants.ROLE.ORGANIZATION_LEARNER) {
                dispatch({ type: authActionTypes.LOGIN_SUCCESS, user });
                history.push(`${pageRoutes.LEARNER.PATH}${pageRoutes.LEARNER.EDIT_PROFILE.PATH}`);
              } else {
                if (!isPaid && [staticConstants.ROLE.ORGANIZATION_ADMIN, staticConstants.ROLE.PROFESSIONAL].indexOf(role) > -1) {
                  dispatch({ type: authActionTypes.LOGIN_SUCCESS, user });
                  history.push(`${pageRoutes.PAYMENT.PATH}${pageRoutes.PAYMENT.SUBSCRIPTION}`);
                } else {
                  dispatch(success(user));
                  dispatch({ type: authActionTypes.LOGIN_SUCCESS, user });
                  history.push(`${pageRoutes.PAYMENT.PATH}${pageRoutes.PAYMENT.SUBSCRIPTION}`);
                }
              }
            }
          },         0);
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request(res: ICreatePasswordReq) { return { type: actionTypes.CREATE_PASSWORD_REQUEST, res }; }
  function success(res: any) { return { type: actionTypes.CREATE_PASSWORD_SUCCESS, res }; }
  function failure(error: string) { return { type: actionTypes.CREATE_PASSWORD_FAILURE, error }; }
}

function verifyToken(token: string) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(token));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.verifyCreatePasswordToken(token)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(res));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request(data: any) { return { type: actionTypes.VERIFY_TOKEN_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.VERIFY_TOKEN_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.VERIFY_TOKEN_FAILURE, error }; }
}
