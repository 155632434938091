import React, { Component, Dispatch } from 'react';
import { connect } from 'react-redux';
import OpenFieldTypes from 'OpenFieldTypes';
import { pick } from 'lodash';
import Listing from '../organization/listing';
import {
  Button,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import Select from 'react-select';
import { customSelectStyles, staticConstants, mapGroupsFromApi, getMyUserId, deleteChatGroup, ALERT_MESSAGES, MAGIC_NUMBER } from '../../../../utils';
import { Back } from '../../back/back';
import { Sidebar } from '../../sidebar';
import { LocationDescriptorObject } from 'history';
import { history } from '../../../../helpers';
import { chatAction } from '../redux/actions';
import { alertActions } from '../../../alert/redux/actions';
import { userAction } from '../../../systemAdmin/userManagement/redux/actions';
import { IAllGroups, IUpdateProfileReq } from '../../../../interfaces';
import { ConfirmatioPopup } from '../../modals/confirmationPopup';
import { ViewGroupMembersModal } from '../../modals/ViewGroupMembersPopup';

interface IGroups {
  _id?: string;
  groupName: string;
  users: [string];
}

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  location: LocationDescriptorObject;
  allGroups: [IGroups];
  fetchingGroups: boolean;
  addedGroup?: boolean;
  profile: { [key: string]: string };
}

class AddMessageContainer extends Component<IProps> {
  public static getDerivedStateFromProps(props: IProps) {
    const newState = {};
    const allGroups = props.allGroups.map(gp => mapGroupsFromApi(gp));
    newState['allGroups'] = allGroups;
    return newState;
  }

  public state = {
    userType: staticConstants.MESSAGE_BOARD_TAB.ORGANIZATIONS,
    newMessage: '',
    groupName: '',
    groupNameError: false,
    newMessageError: false,
    isAddGroup: false,
    allGroups: [],
    groupMembers: [],
    currentSelectedGroup: null,
    selectedOranization: null,
    displayDeleteGroupPopup: false,
    viewGroupMembers: false,
  };

  /**
   * @description
   * handle group selection
   * @param {object} group
   */
  public handleGroupChange = (group: { value: string; label: string }) => {
    const { allGroups } = this.props;
    const members = allGroups.find(grp => grp._id === group.value);
    this.setState({ currentSelectedGroup: group, groupMembers: members.users, isAddGroup: false });
  }

  /**
   * @description
   * get list of selected users to create a new group
   * @param {object} groupMembers list of users
   */
  public updateGroupMembers = (groupMembers: Array<[string]>) => this.setState({ groupMembers, selectedOranization: null });

  /**
   * @description
   * get updated selection for organization
   */
  public updateSelectedOrganization = selectedOranization => this.setState({ selectedOranization, groupMembers: [] });

  /**
   * @description
   * create new group
   */
  public createGroup = () => {
    const { dispatch } = this.props;
    const { groupName, groupMembers } = this.state;
    if (!groupName.length) {
      this.setState({ groupNameError: true });
      return;
    }
    this.setState({ groupNameError: false });
    if (groupMembers.length) {
      dispatch(chatAction.createGroup(groupName, groupMembers, () => {
        this.setState({
          isAddGroup: false,
          groupName: '',
          groupNameError: false,
          currentSelectedGroup: null,
        });
      }));
    } else {
      dispatch(alertActions.errorAlert(ALERT_MESSAGES.MESSAGE_BOARD.SELECT_USER));
    }
  }

  public removeGroup = () => {
    const { currentSelectedGroup: { value } } = this.state;
    this.props.dispatch(chatAction.removeGroup(value, () => {
      this.setState({
        isAddGroup: false,
        groupName: '',
        groupNameError: false,
        currentSelectedGroup: null,
        displayDeleteGroupPopup: false,
      });
      this.props.dispatch(chatAction.getGroups());
    }));
  }

  public onMessageChange = (event) => {
    const { value } = event.target;
    this.setState({ newMessage: value });
    if (!value) this.setState({ newMessageError: true });
    else this.setState({ newMessageError: false });
  }

  public handleSendMessage = () => {
    const { dispatch } = this.props;
    const { userType, currentSelectedGroup, selectedOranization, groupMembers, newMessage } = this.state;
    if (!newMessage.length) {
      this.setState({ newMessageError : true });
      return;
    }
    this.setState({ newMessageError: false });
    if (userType === staticConstants.MESSAGE_BOARD_TAB.ORGANIZATIONS) {
      if (selectedOranization && selectedOranization._id) this.getOrgLearner(selectedOranization);
      else dispatch(alertActions.errorAlert(ALERT_MESSAGES.MESSAGE_BOARD.SELECT_ORGANIZATION));
    } else {
      if (currentSelectedGroup && currentSelectedGroup.value) this.broadcastMessage(currentSelectedGroup, groupMembers);
      else dispatch(alertActions.errorAlert(ALERT_MESSAGES.MESSAGE_BOARD.SELECT_GROUP));
    }
  }

  public getOrgLearner = (organization) => {
    const reqObj = {
      isPagination: false,
      userId: organization._id,
    };
    this.props.dispatch(userAction.getUsers(reqObj, (learners) => {
      if (learners) {
        const learnerIds = learners.data.map(learner => learner._id);
        this.broadcastMessage(organization, learnerIds);
      }
    }));
  }

  public broadcastMessage = (connection, rawMembers) => {
    const { profile: { firstName, lastName }, dispatch } = this.props;
    const { userType, newMessage } = this.state;
    const timeStamp = Date.now();
    let members = [...rawMembers];
    let targetName = '';
    let targetProfile = '';
    let sendingTo = '';
    if (userType === staticConstants.MESSAGE_BOARD_TAB.ORGANIZATIONS) {
      targetName = connection.organizationName;
      targetProfile = connection.profileImage || '';
      sendingTo = `${connection._id}**${connection.organizationName}`;
      members.push(connection._id);
    } else {
      targetName = connection.label;
      sendingTo = connection.value;
      members = rawMembers.map(user => user._id);
    }
    const msgObj = {
      content: newMessage,
      from: getMyUserId(),
      to: sendingTo,
      roomId: sendingTo,
      isRead: false,
      name: `${firstName} ${lastName}`,
      isBroadcast: true,
      target: userType,
      targetName,
      targetProfile,
      timeStamp,
    };
    dispatch(
      chatAction.sendMessage(msgObj, [sendingTo, ...members], () => {
        this.setState({ newMessage: '', currentSelectedGroup: null, selectedOranization: null, groupMembers: [] });
        dispatch(alertActions.successAlert(`${ALERT_MESSAGES.MESSAGE_BOARD.BROADCAST_SUCCESS}${targetName}`));
      }),
    );
  }

  public render() {
    const { fetchingGroups } = this.props;
    const {
      userType,
      newMessage,
      groupName,
      groupNameError,
      isAddGroup,
      allGroups,
      currentSelectedGroup,
      newMessageError,
      displayDeleteGroupPopup,
      viewGroupMembers,
      groupMembers,
      selectedOranization
    } = this.state;
    return (
      <div>
        <Sidebar {...this.props} />
        <div className="dashboard-wrapper">
          <Back {...this.props} />
          <div className="dashboard-content box-shadow add-message-box">
            <h2 className="heading heading-md roboto-medium">Add Message</h2>
            <p className="form-small-text">Select User Type</p>
            <div className="d-flex mb-4 flex-wrap">
              <label className="control control--radio mr-4 pl-4">
                <Input
                  type="radio"
                  name="userType"
                  value="organization"
                  defaultChecked
                  onChange={event => this.setState({ userType: event.target.value, isAddGroup: false, newMessage: '' })}
                />
                Team
                <div className="control__indicator" />
              </label>
              <label className="control control--radio mr-4 pl-4">
                <Input
                  type="radio"
                  name="userType"
                  value="users"
                  onChange={event => this.setState({ userType: event.target.value, isAddGroup: false, currentSelectedGroup: null, newMessage: '' })}
                />
                Users (Experts, Individual & Team Members)
                <div className="control__indicator" />
              </label>
            </div>
            {userType !== staticConstants.MESSAGE_BOARD_TAB.ORGANIZATIONS &&
              <>
                <div className="select-group-box">
                  <FormGroup className="floating-label disabled-input" onClick={() => this.props.dispatch(chatAction.getGroups())}>
                    <Select
                      name="selectGroup"
                      styles={customSelectStyles}
                      id="selectGroup"
                      options={allGroups}
                      value={currentSelectedGroup}
                      placeholder="Select Group"
                      className="react-select-box select-box-group select-box-group-static"
                      isLoading={fetchingGroups}
                      onChange={this.handleGroupChange}
                    />
                    <Label for="selectGroup" className="selected">Select Group</Label>
                  </FormGroup>
                  <div className="d-flex justify-content-start add-button-group mt-2">
                    {!isAddGroup &&
                      <Button
                        className="btn btn-icon "
                        onClick={() => this.setState({ isAddGroup: true, currentSelectedGroup: null })}
                      >
                        <em>+</em>
                        Add Group
                      </Button>
                    }
                  {currentSelectedGroup &&
                    <>
                      <Button
                        className="btn btn-icon "
                        onClick={() => this.setState({ viewGroupMembers: true })}
                      >
                        View Details
                      </Button>
                      <Button
                        className="btn btn-icon delete"
                        disabled={!currentSelectedGroup}
                        onClick={() => this.setState({ displayDeleteGroupPopup: true })}
                      >
                        <span className="icon icon-delete" />
                        Remove
                      </Button>
                    </>
                    }
                    {displayDeleteGroupPopup &&
                      <ConfirmatioPopup
                        hideModal={() => this.setState({ displayDeleteGroupPopup: false })}
                        isOpenModal={displayDeleteGroupPopup}
                        modalPopupObj={deleteChatGroup}
                        onClickYes={this.removeGroup}
                      />
                  }
                  <ViewGroupMembersModal
                    isOpenModal={viewGroupMembers}
                    hideModal={() => this.setState({ viewGroupMembers: false })}
                    groupMembers={groupMembers || []}
                  />
                  </div>
                </div>
                {isAddGroup &&
                  <div className="select-group-box">
                    <FormGroup className={`floating-label ${groupNameError && 'has-error'}`}>
                      <Input
                        type="text"
                        className="form-control"
                        id="groupName"
                        placeholder="Group Name"
                        value={groupName}
                        maxLength={MAGIC_NUMBER.EIGHTY}
                        onChange={event => this.setState({ groupName: event.target.value })}
                      />
                      <Label for="groupName">Group Name</Label>
                      {groupNameError && <div className="error-text">Please enter group name.</div>}
                    </FormGroup>
                    <div className="d-flex justify-content-start add-button-group mt-2">
                      <Button className="btn btn-icon delete" onClick={() => this.setState({ isAddGroup: false, groupName: '' })}>
                        <span className="icon icon-delete" />
                        Remove
                      </Button>
                      <Button className="btn btn-icon " onClick={this.createGroup}><em>+</em>Add Group</Button>
                    </div>
                  </div>
                }
              </>
            }
          </div>
          <Listing
            {...this.props}
            userType={userType}
            selectedTeam ={selectedOranization}
            updateGroupMembers={this.updateGroupMembers}
            updateSelectedOrganization={this.updateSelectedOrganization}
          />
          <div className="dashboard-content box-shadow white-bg">
            <div className="w-100 add-message-box">
              <FormGroup
                className={`floating-label disabled-input textarea-label mb-3 ${newMessageError && 'has-error'}`}>
                <textarea
                  className="form-control textarea-md"
                  id="newMessage"
                  name="newMessage"
                  placeholder="Write Message"
                  maxLength={MAGIC_NUMBER.CHAR_LENGTH}
                  value={newMessage}
                  onChange={this.onMessageChange}
                />
                <span className="text-count">{newMessage.length}/500</span>
                <Label for="bidDetail" className={newMessage.length > MAGIC_NUMBER.ZERO ? 'selected' : ''}>Message</Label>
                {newMessageError && <div className="error-text">Please enter a message.</div>}
              </FormGroup>
            </div>
            <div className="card-footer d-flex align-items-center justify-content-end mt-0">
              <Button className="btn btn-regular" onClick={() => history.goBack()}>Cancel</Button>
              <Button color="primary" className="btn ml-3" onClick={this.handleSendMessage}>Send</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: {
  users: any;
  chatMessages: {
    allGroups: IAllGroups | any;
    fetchingGroups: boolean;
    addedGroup: boolean;
  };
  updateProfile: { profile: IUpdateProfileReq };
}) {
  const {
    users,
    chatMessages: { allGroups, fetchingGroups, addedGroup },
    updateProfile: { profile },
  } = state;
  return {
    users,
    allGroups,
    fetchingGroups,
    addedGroup,
    profile: pick(profile, ['firstName', 'lastName', 'role', 'profileImage']),
  };
}

const connectedAddMessageContainer = connect(mapStateToProps)(AddMessageContainer);
export { connectedAddMessageContainer as AddMessageContainer };
