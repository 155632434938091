import React, { Fragment } from 'react';
import { Elements } from 'react-stripe-elements';
import { PaymentForm } from './PaymentForm';

class PaymentContainer extends React.Component {

  public render() {
    const props = this.props;

    return (
          <Fragment>
            <Elements>
                <PaymentForm {...props}/>
            </Elements>
          </Fragment>
    );
  }
}

export { PaymentContainer };
