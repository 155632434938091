import React, { Dispatch } from 'react';
import OpenFieldTypes from 'OpenFieldTypes';
import { contentManagementAction } from '../../../systemAdmin/contentManagement/redux/actions';
import { connect } from 'react-redux';
import { LandingHeader } from '../../landingHeader';
import { LandingFooter } from '../../landingFooter';
import { staticConstants } from '../../../../utils';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  content: Array<[]>;
}

class TermsConditions extends React.Component<IProps> {

  public state = {
    title: staticConstants.LANDING_PAGE_FOOTER_TITLE.TERMS_AND_CONDITIONS,
  };

  public componentDidMount() {
    this.props.dispatch(contentManagementAction.getContent({
      type: staticConstants.CONTENT_MANAGEMENT_TAB.TERMS_AND_CONDITIONS,
    }));

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'hs-script-loader';
    script.src = '//js.hs-scripts.com/7903349.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  public createMarkUp = () => {
    const { content } = this.props;
    if (content && content[0] !== undefined && content[0]['content']) {
      const data = content[0]['content'];
      return {
        __html: data,
      };
    }
    return {
      __html: '',
    };
  }

  public render() {
    const { title } = this.state;
    return (
      <>
        <LandingHeader title={title} banner={'privecy_banner'} />
        <div className="common_padding">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="privacy_text">
                  <div dangerouslySetInnerHTML={this.createMarkUp()} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <LandingFooter activeTab={staticConstants.CONTENT_MANAGEMENT_TAB.TERMS_AND_CONDITIONS} />
      </>
    );
  }
}

function mapStateToProps(state: { contentManagement: { content: Array<[]> } }) {
  const { content } = state.contentManagement;
  return {
    content,
  };
}

const connectedAboutUsContainer = connect(mapStateToProps)(TermsConditions);
export { connectedAboutUsContainer as TermsConditions };
