import React, { Dispatch } from 'react';
import { Button } from 'reactstrap';
import {
  tableItemsCount,
  staticConstants,
  userStatusTypes,
  pageRoutes,
  getSelectedValue,
  MAGIC_NUMBER,
} from './../../../../utils';
import OpenFieldTypes from 'OpenFieldTypes';
import { LocationDescriptorObject } from 'history';
import { ListingContainer } from '../../../shared/listingManagement/listingContainer';
import { FilterComponent } from '../../../shared/filter/filterComponent';
import { history } from '../../../../helpers';
import { expertPaymentAction } from '../redux/actions';

interface IProps {
  dispatch?: Dispatch<OpenFieldTypes.RootAction>;
  location: LocationDescriptorObject;
  paymentLogs?: any;
}

const TABLE_COL_10 = 'table-col-10';
const TABLE_COL_15 = 'table-col-15';
const TABLE_COL_13 = 'table-col-13';
const TABLE_COL_12 = 'table-col-12';
const TABLE_COL_45 = 'table-col-45';
const DOMAIN = 'Domain';
const SUBDOMAIN = 'Sub-Domain';
const EVENT_DATE = 'Event Date';
const EVENT_TIME = 'Event Time';
const DURATION = 'Duration';
const TITLE = 'Title';
const EVENT_TYPE = 'Event Type';
const EXPERT_FEE = 'Expert Fee';
const STATUS = 'Status';
const AMOUNT = 'Amount';
const DATE = 'Date';

const receivedPaymentHeaderContent = {
  title: { name: TITLE, dataLabel: TITLE, class: TABLE_COL_10, sort: true, path: `${pageRoutes.EVENTMANAGEMENT.PATH}${pageRoutes.EVENTMANAGEMENT.DETAIL}` },
  eventType: { name: EVENT_TYPE, dataLabel: EVENT_TYPE, class: TABLE_COL_10, sort: true },
  domain: { name: DOMAIN, dataLabel: DOMAIN, class: TABLE_COL_10, sort: true },
  subDomain: { name: SUBDOMAIN, dataLabel: SUBDOMAIN, class: TABLE_COL_15, sort: true },
  createdAt: { name: EVENT_DATE, dataLabel: EVENT_DATE, class: TABLE_COL_13, sort: true },
  createdTime: { name: EVENT_TIME, dataLabel: EVENT_TIME, class: TABLE_COL_12, sort: true },
  duration: { name: DURATION, dataLabel: DURATION, class: TABLE_COL_10, sort: true },
  expertFee: { name: EXPERT_FEE, dataLabel: EXPERT_FEE, class: TABLE_COL_10, sort: true },
  type: { name: STATUS, dataLabel: STATUS, class: TABLE_COL_10, sort: true },
};

const pendingPaymentHeaderContent = {
  title: { name: TITLE, dataLabel: TITLE, class: TABLE_COL_10, sort: true, path: `${pageRoutes.EVENTMANAGEMENT.PATH}${pageRoutes.EVENTMANAGEMENT.DETAIL}` },
  eventType: { name: EVENT_TYPE, dataLabel: EVENT_TYPE, class: TABLE_COL_10, sort: true },
  domain: { name: DOMAIN, dataLabel: DOMAIN, class: TABLE_COL_10, sort: true },
  subDomain: { name: SUBDOMAIN, dataLabel: SUBDOMAIN, class: TABLE_COL_15, sort: true },
  createdAt: { name: EVENT_DATE, dataLabel: EVENT_DATE, class: TABLE_COL_13, sort: true },
  createdTime: { name: EVENT_TIME, dataLabel: EVENT_TIME, class: TABLE_COL_12, sort: true },
  duration: { name: DURATION, dataLabel: DURATION, class: TABLE_COL_10, sort: true },
  expertFee: { name: EXPERT_FEE, dataLabel: EXPERT_FEE, class: TABLE_COL_10, sort: true },
  type: { name: STATUS, dataLabel: STATUS, class: TABLE_COL_10, sort: true },
};

const withdrawalHistoryHeaderContent = {
  date: { name: DATE, dataLabel: DATE, class: TABLE_COL_45, sort: true },
  amount: { name: AMOUNT, dataLabel: AMOUNT, class: TABLE_COL_45, sort: true },
  withdrawstatus: { name: STATUS, dataLabel: STATUS, class: TABLE_COL_45, sort: true },
};

class PaymentListingContainer extends React.Component<IProps> {

  /**
   * @description getDerivedStateFromProps is called when there in change in property
   * @param {Object} props
   */
  public static getDerivedStateFromProps(props: IProps) {
    const updateState = {};
    if (props.paymentLogs) {
      updateState['logs'] = props.paymentLogs;
    }
    return updateState;
  }

  public state = {
    activePage: MAGIC_NUMBER.ONE,
    title: '',
    items: tableItemsCount,
    domain: null,
    eventType: null,
    sort: '',
    sortBy: 0,
    logs: {
      count: 0,
      list: [],
    },
    reqObj: {},
    activeTab: staticConstants.EXPERT_PAYMENT_TAB.RECEIVED,
    filterTab: staticConstants.EXPERT_PAYMENT_TAB.RECEIVED,
    openFilter: false,
    tableContent: receivedPaymentHeaderContent,
  };

  /**
   * @description componentDidMount is called when component is loaded
   * call userAction.getUsers to fetch goal listings
   * call userAction.getUsers to fetch goal categories
   */
  public componentDidMount() {
    const { items, activePage } = this.state;
    const reqObj = {
      page: Number(activePage),
      limit: items,
    };
    const updateReqObj = this.updateReqObj(reqObj);
    this.props.dispatch(expertPaymentAction.getPaymentLogs(updateReqObj));
  }

  /**
   * @description updateReqObj to update object before sending to the server
   */
  public updateReqObj(reqObj: any) {
    reqObj.filter = this.state.activeTab;
    reqObj.title = this.state.title;
    reqObj.domain = getSelectedValue(this.state.domain);
    reqObj.eventType = getSelectedValue(this.state.eventType);
    return reqObj;
  }

  /**
   * @description
   * handle navigation to withdraw amount page
   */
  public goToWithdraw = () => history.push(`${pageRoutes.PAYMENT.PATH}${pageRoutes.PAYMENT.WITHDRAW}`);

  /**
   * @description fetchPaymentHistory is used on initial load
   */
  public fetchPaymentHistory(pageno: number) {
    const { items, sort, sortBy, activeTab } = this.state;
    let reqObj = {
      page: Number(pageno),
      limit: items,
    };
    if (sort) {
      reqObj = { ...reqObj, ...{ sort, sortBy } };
    }
    const updateReqObj = this.updateReqObj(reqObj);
    if (activeTab === staticConstants.EXPERT_PAYMENT_TAB.WITHDRAWAL_HISTORY) {
      delete updateReqObj.filter;
      this.props.dispatch(expertPaymentAction.getWithdrawHistory(updateReqObj));
    } else {
      this.props.dispatch(expertPaymentAction.getPaymentLogs(updateReqObj));
    }
  }

  /**
   * @description
   * handleChange is used to set the value on state from the input.
   * @param {Object} || {String} value
   * @param {String} key
   */
  public handleChange = (value: any, key: string) => this.setState({ ...this.state, [key]: value, activePage: MAGIC_NUMBER.ONE }, () => this.fetchPaymentHistory(1));

  /**
   * @description
   * handlePageChange is called someone click on the pagination.
   * @param {Number} pageno
   */
  public handlePageChange = (pageno: number) => this.setState({ activePage: pageno }, () => this.fetchPaymentHistory(pageno));

  /**
   * @description
   * sortData is used for sorting purpose.
   * @param {Number} sort
   * @param {String} sortBy
   */
  public sortData = (sort: string, sortBy: string) => {
    const { activePage } = this.state;
    this.setState({ sort, sortBy }, () =>
      this.fetchPaymentHistory(activePage),
    );
  }

  /**
   * @description
   * changeActiveTab is called when some click on the child tab.
   */
  public changeActiveTab(activeTab: string, tableContent) {
    this.setState({ activeTab, tableContent }, () => this.fetchPaymentHistory(1));
  }

  /**
   * @description
   * render is used to render HTML
   */
  public render() {
    const { tableContent, logs, activeTab, activePage, sort, sortBy } = this.state;
    let filterContent = ['search'];
    const filterObj = { activePage, sort, sortBy };
    const classVarArr = ['table-head'];
    const classes = classVarArr.join(' ');
    if (activeTab !== staticConstants.EXPERT_PAYMENT_TAB.WITHDRAWAL_HISTORY) {
      filterContent = ['search', 'domain', 'eventType'];
    }
    const cssClass = 'btn-active';
    return (
      <React.Fragment>
        <div className="filter-wrapper">
          <FilterComponent filterContent={filterContent}
            handleFilter={this.handleChange}
            placeholder="Search by Keywords, Name"
            status={userStatusTypes}
            activeTab={activeTab}
          />
          <Button
            className="btn btn-primary round-circle withdraw-amount-btn"
            onClick={this.goToWithdraw}
          >
            Withdraw Amount
          </Button>
        </div>
        <div className={`tab-btn-group multi-tab-buttons`}>
          <Button
            color="secondary"
            className={`btn h-50 tab-btn text-uppercase ${activeTab === staticConstants.EXPERT_PAYMENT_TAB.RECEIVED ? cssClass : ''}`}
            onClick={() => this.changeActiveTab(staticConstants.EXPERT_PAYMENT_TAB.RECEIVED, receivedPaymentHeaderContent)}
          >
            Received
          </Button>
          <Button
            color="secondary"
            className={`btn h-50 tab-btn text-uppercase ${activeTab === staticConstants.EXPERT_PAYMENT_TAB.PENDING ? cssClass : ''}`}
            onClick={() => this.changeActiveTab(staticConstants.EXPERT_PAYMENT_TAB.PENDING, pendingPaymentHeaderContent)}
          >
            Pending
          </Button>
          <Button
            color="secondary"
            className={`btn h-50 tab-btn text-uppercase ${activeTab === staticConstants.EXPERT_PAYMENT_TAB.WITHDRAWAL_HISTORY ? cssClass : ''}`}
            onClick={() => this.changeActiveTab(staticConstants.EXPERT_PAYMENT_TAB.WITHDRAWAL_HISTORY, withdrawalHistoryHeaderContent)}
          >
            Withdrawal History
          </Button>
        </div>

        <ListingContainer
          tableContent={tableContent}
          listData={logs}
          filterObj={filterObj}
          tableHeaderClasses={classes}
          handlePageChange={this.handlePageChange}
          handleSorting={this.sortData}
          activeTab={activeTab}
        />
      </React.Fragment >
    );
  }
}

export { PaymentListingContainer };
