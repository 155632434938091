import React, { Dispatch } from 'react';
import { Sidebar } from '../../shared/sidebar';
import { connect } from 'react-redux';
import Select from 'react-select';
import OpenFieldTypes from 'OpenFieldTypes';
import { customSelectStyles } from '../../../utils/common';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { dashboardActions } from './redux/actions';
import { AreaChart } from '../../shared/charts/areaChart/areaChart';
import { BarChart } from '../../shared/charts/barChart/barChart';
import { pageRoutes } from './../../../utils/routeConstants';
import { staticConstants, formatCurrency, MAGIC_NUMBER } from '../../../utils';
import { userAction } from '../userManagement/redux/actions';
import moment from 'moment';

interface IProps {
  dashboardData: object;
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  dashboardList: Array<[]>;
  latestUsers: Array<[]>;
}

interface IState {
  categoryOptionsTypes: object[];
  categoryUserTypes: object[];
  categoryStatusTypes: object[];
  categoryEventTypes: object[];
  currentDate: Date;
  year: number;
  month: number;
  date: number;
  learningGoalsChartData: object;
  totalData: object;
  learningEvents: object;
  roles: object;
  fromDate: object;
  toDate: object;
  createdUser: object;
  revenue: object;
  status: string;
  goalDateSelected: string;
  eventDateSelected: string;
  userDateSelected: string;
  revenueDateSelected: string;
  selectedRole: string;
}

class DashBoardPage extends React.Component<IProps, IState> {

  public static getDerivedStateFromProps(props: IProps, state: IState) {
    const newState = {};
    if (props.dashboardData) {
      if (props.dashboardData['learningGoals']) {
        newState['learningGoalsChartData'] = props.dashboardData['learningGoals'];
      }
      if (props.dashboardData['totalData']) {
        newState['totalData'] = props.dashboardData['totalData'];
      }
      if (props.dashboardData['learningEvents']) {
        newState['learningEvents'] = props.dashboardData['learningEvents'];
      }
      if (props.dashboardData['createdUser']) {
        newState['createdUser'] = props.dashboardData['createdUser'];
      }
      if (props.dashboardData['revenue']) {
        newState['revenue'] = props.dashboardData['revenue'];
      }
    }
    return newState;
  }
  constructor(props: IProps) {
    super(props);
    const fromDate = new Date(moment(new Date()).subtract(0, 'months').startOf('month').format(staticConstants.DASHBOARD_FORMAT));
    const toDate = new Date(moment(
      new Date()).subtract(0, 'months').endOf('month').format(staticConstants.DASHBOARD_FORMAT,
      ));
    this.state = {
      categoryOptionsTypes: [
        { value: 'thisMonth', label: 'Current Month' },
        { value: 'lastMonth', label: 'Last Month' },
        { value: 'last6Months', label: 'Last 6 Months' },
        { value: 'lastYear', label: 'Last Year' },
      ],
      categoryUserTypes: [
        { value: 'orgAdmin', label: 'Team Admin' },
        { value: 'orgLearner', label: 'Team Member' },
        { value: 'professional', label: 'Individual' },
        { value: 'expert', label: 'Expert' },
      ],
      categoryStatusTypes: [
        { value: 'pending', label: 'Pending Payment' },
        { value: 'received', label: 'Received Payment' },
      ],
      categoryEventTypes: [
        { value: 'upcoming', label: 'Upcoming Events' },
        { value: 'past', label: 'Past Events' },
      ],
      currentDate: new Date(),
      year: new Date().getFullYear(),
      month: new Date().getMonth(),
      date: new Date().getDate() + 1,
      learningGoalsChartData: {},
      totalData: {},
      learningEvents: {},
      createdUser: {},
      revenue: {},
      roles: {
        learningEvents: staticConstants.ROLE.ORGANIZATION_ADMIN,
        createdUser: staticConstants.ROLE.ORGANIZATION_ADMIN,
      },
      status: staticConstants.EXPERT_PAYMENT_TAB.PENDING,
      fromDate: {
        totalData: fromDate.toLocaleDateString('en-US'),
        learningGoals: fromDate.toLocaleDateString('en-US'),
        learningEvents: fromDate.toLocaleDateString('en-US'),
        createdUser: fromDate.toLocaleDateString('en-US'),
        revenue: fromDate.toLocaleDateString('en-US'),
      },
      toDate: {
        totalData: toDate.toLocaleDateString('en-US'),
        learningGoals: toDate.toLocaleDateString('en-US'),
        learningEvents: toDate.toLocaleDateString('en-US'),
        createdUser: toDate.toLocaleDateString('en-US'),
        revenue: toDate.toLocaleDateString('en-US'),
      },
      goalDateSelected: 'thisMonth',
      eventDateSelected: 'thisMonth',
      userDateSelected: 'thisMonth',
      revenueDateSelected: 'thisMonth',
      selectedRole: null
    };
  }

  public componentDidMount = () => {
    const { dispatch } = this.props;
    const fromDate = new Date(moment(new Date()).subtract(0, 'months').startOf('month').format(staticConstants.DASHBOARD_FORMAT));
    const toDate = new Date(moment(
      new Date()).subtract(0, 'months').endOf('month').format(staticConstants.DASHBOARD_FORMAT,
      ));
    const obj = [{
      label: 'totalData',
      startDate: fromDate.toLocaleDateString('en-US'),
      endDate: toDate.toLocaleDateString('en-US'),
    },
      {
        label: 'learningGoals',
        startDate: fromDate.toLocaleDateString('en-US'),
        endDate: toDate.toLocaleDateString('en-US'),
      },
      {
        label: 'learningEvents',
        startDate: fromDate.toLocaleDateString('en-US'),
        endDate: toDate.toLocaleDateString('en-US'),
        role: this.state.roles['learningEvents'],
      },
      {
        label: 'revenue',
        startDate: fromDate.toLocaleDateString('en-US'),
        endDate: toDate.toLocaleDateString('en-US'),
        status: this.state.status,
      },
      {
        label: 'createdUser',
        startDate: fromDate.toLocaleDateString('en-US'),
        endDate: toDate.toLocaleDateString('en-US'),
        role: this.state.roles['createdUser'],
      }];
    this.getData(obj, dispatch);

    this.getFlaggedUsersList();
    dispatch(dashboardActions.getLatestUsers());
  }

  public getFlaggedUsersList = () => {
    const reqObj = {
      limit: MAGIC_NUMBER.THREE,
      page: MAGIC_NUMBER.ONE,
      type: staticConstants.USER_MANAGEMENT_TAB.FLAG_PROFILES,
      isUserManagment: true,
    };
    this.props.dispatch(userAction.getUsers(reqObj));
  }

  public thisMonthDate = (dispatch, key) => {
    const fromDate = new Date(moment(new Date()).subtract(0, 'months').startOf('month').format(staticConstants.DASHBOARD_FORMAT));
    const toDate = new Date(moment(
      new Date()).subtract(0, 'months').endOf('month').format(staticConstants.DASHBOARD_FORMAT,
      ));
    this.dataObjToChange(key, fromDate, toDate, dispatch);
  }

  public lastYearDate = (dispatch, key) => {
    const fromDate = new Date(moment(new Date()).subtract(1, 'years').startOf('year').format(staticConstants.DASHBOARD_FORMAT));
    const toDate = new Date(moment(
      new Date()).subtract(1, 'years').endOf('year').format(staticConstants.DASHBOARD_FORMAT,
      ));
    this.dataObjToChange(key, fromDate, toDate, dispatch);
  }

  public lastMonthDate = (dispatch, key) => {
    const fromDate = new Date(moment(new Date()).subtract(1, 'months').startOf('month').format(staticConstants.DASHBOARD_FORMAT));
    const toDate = new Date(moment(
      new Date()).subtract(1, 'months').endOf('month').format(staticConstants.DASHBOARD_FORMAT,
      ));
    this.dataObjToChange(key, fromDate, toDate, dispatch);
  }

  public lastSixMonthsDate = (dispatch, key) => {
    const fromDate = new Date(moment(new Date()).subtract(MAGIC_NUMBER.SIX, 'months').startOf('month').format(staticConstants.DASHBOARD_FORMAT));
    const toDate = new Date(moment(
      new Date()).subtract(1, 'months').endOf('month').format(staticConstants.DASHBOARD_FORMAT,
      ));
    this.dataObjToChange(key, fromDate, toDate, dispatch);
  }

  public dataObjToChange = (key, dateStart, currentDate, dispatch) => {
    let dataToChange;
    dataToChange = [{
      label: key,
      startDate: dateStart.toLocaleDateString('en-US'),
      endDate: currentDate.toLocaleDateString('en-US'),
    }];
    this.getData(dataToChange, dispatch, key);
  }

  public getData = (obj, dispatch, key?: string) => {
    const reqObj = [];
    obj.map((el) => {
      this.setState({
        ...this.state,
        fromDate: {
          ...this.state.fromDate,
          [el.label]: el.startDate,
        },
        toDate: {
          ...this.state.toDate,
          [el.label]: el.endDate,
        },
        status: el.status || this.state.status,
      });
      reqObj.push({
        type: el.label,
        startDate: el.startDate,
        endDate: el.endDate,
        role: el.label === 'createdUser' || el.label === 'learningEvents' ?
        this.state.roles[key] || staticConstants.ROLE.ORGANIZATION_ADMIN : undefined,
        status: el.label === 'revenue' ? el.status : undefined,
      });
      dispatch(dashboardActions.getDashboardData({
        input: reqObj.filter(label => label.type === el.label),
      },                                         key));
      return reqObj;
    });
  }

  public getRole = (value, dispatch, key: string, dataType?: string) => {
    const userRole = staticConstants.ROLE;
    let role, label;
    switch (value) {
      case 'orgAdmin':
        role = userRole.ORGANIZATION_ADMIN;
        break;
      case 'orgLearner':
        role = userRole.ORGANIZATION_LEARNER;
        break;
      case 'professional':
        role = userRole.PROFESSIONAL;
        break;
      case 'expert':
        role = userRole.EXPERT;
    }
    label = (key === 'userType') ? 'learningEvents' : 'createdUser';
    this.setState({
      ...this.state,
      roles: {
        ...this.state.roles,
        [label]: role,
      },
      selectedRole: role
    },            () => {
      const dataToChange = [{
        label,
        startDate: this.state.fromDate[dataType],
        endDate: this.state.toDate[dataType],
      }];
      this.getData(dataToChange, dispatch, label);
    });
  }

  public getStatus = (value, dispatch, key?: string, dataType?: string) => {
    const paymentStatus = staticConstants.EXPERT_PAYMENT_TAB;
    let status;
    switch (value) {
      case 'pending':
        status = paymentStatus.PENDING;
        break;
      case 'received':
        status = paymentStatus.RECEIVED;
        break;
    }
    const dataToChange = [{
      label: 'revenue',
      startDate: this.state.fromDate[dataType],
      endDate: this.state.toDate[dataType],
      status,
    }];
    this.getData(dataToChange, dispatch);
  }

  public handleChange = (e: any, key: string, type?: string) => {
    const { dispatch } = this.props;
    if (e.value === 'last6Months') {
      this.lastSixMonthsDate(dispatch, key);
    } else if (e.value === 'lastMonth') {
      this.lastMonthDate(dispatch, key);
    } else if (e.value === 'lastYear') {
      this.lastYearDate(dispatch, key);
    } else if (key === 'userType' || key === 'createdUserRole') {
      this.getRole(e.value, dispatch, key, type);
    } else if (key === 'paymentStatus') {
      this.getStatus(e.value, dispatch, key, type);
    } else {
      this.thisMonthDate(dispatch, key);
    }
    if (key === 'learningGoals') {
      this.setState({ goalDateSelected: e.value });
    } else if (key === 'learningEvents') {
      this.setState({ eventDateSelected: e.value });
    } else if (key === 'createdUser') {
      this.setState({ userDateSelected: e.value });
    } else if (key === 'revenue') {
      this.setState({ revenueDateSelected: e.value });
    }
  }

  public render() {
    const { categoryOptionsTypes, categoryUserTypes, categoryStatusTypes,
      learningGoalsChartData, totalData, learningEvents, createdUser, revenue,
      goalDateSelected, eventDateSelected, userDateSelected, revenueDateSelected, selectedRole } = this.state;
    const { dashboardList, latestUsers } = this.props;
    let result = '';
    if (learningEvents['totalDuration'] !== undefined) {
      const duration = learningEvents['totalDuration'];
      const hours = Math.trunc(duration);
      const mins = Math.trunc((duration - Math.floor(duration)) * MAGIC_NUMBER.SIXTY);
      result = `${hours} hrs ${mins} mins`;
    }
    const getSysAdminProfileNavLink = (role: string, id: string, organizationId: string = null)  => {
      switch (role) {
        case staticConstants.ROLE.ORGANIZATION_LEARNER:
          return `${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.PATH}${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.DETAIL}/${staticConstants.USER_MANAGEMENT_TAB.ORG_ADMIN}/${organizationId}/${role}/${id}`;
        case staticConstants.ROLE.ORGANIZATION_ADMIN:
          return `${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.PATH}${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.DETAIL}/${staticConstants.USER_MANAGEMENT_TAB.ORG_ADMIN}/${id}`;
        default:
          return `${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.PATH}${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.DETAIL}/${role}/${id}`;
      }
    };
    return (
      <>
        <Sidebar  {...this.props} />
        <div className="dashboard-wrapper">
          <div className="dashboard-header">
            <h2 className="heading heading-lg roboto-medium text-uppercase mb-0">DashBoard</h2>
            <Select
              placeholder="Current Month"
              styles={customSelectStyles}
              onChange={(e) => this.handleChange(e, 'totalData')}
              options={categoryOptionsTypes}
              className="react-select-box"
            />
          </div>
          <div className="dashboard-cards mt-4">
            <div className="card card-col-3">
              <div className="card-data">
                <h2>{totalData && totalData['totalLearningGoal']}</h2>
                <p>Total Goals</p>
              </div>
              <span className="icon icon-goal" />
            </div>
            <div className="card card-col-3">
              <div className="card-data">
                <h2>{totalData && totalData['totalRfpRequest']}</h2>
                <p>Total Requests (RFP)</p>
              </div>
              <span className="icon icon-rfp" />
            </div>
            <div className="card card-col-3">
              <div className="card-data">
                <h2>{totalData && totalData['totalUser']}</h2>
                <p>Total Users</p>
              </div>
              <span className="icon icon-total-user" />
            </div>
          </div>
          <div className="dashboard-cards mt-4">
            <div className="card card-col-3">
              <div className="card-data">
                <h2>{totalData && totalData['totalUpcomingEvents']}</h2>
                <p>Total Upcoming Events</p>
              </div>
              <span className="icon icon-event" />
            </div>
            <div className="card card-col-3">
              <div className="card-data">
                <h2>{totalData && totalData['totalPastEvents']}</h2>
                <p>Total Past Events</p>
              </div>
              <span className="icon icon-event" />
            </div>
            <div className="card card-col-3">
              <div className="card-data">
                <h2>${totalData && formatCurrency(totalData['totalInvestedIncome'] && totalData['totalInvestedIncome'].toFixed(MAGIC_NUMBER.TWO))}</h2>
                <p>Total Overlap Income</p>
              </div>
              <span className="icon icon-bill" />
            </div>
          </div>
          <div className="graph-wrapper">
            <div className="dashboard-graph-header">
              <h2 className="heading heading-sm roboto-bold text-uppercase">Goals</h2>
            </div>
            <div>
              <Select
                placeholder="Current Month"
                styles={customSelectStyles}
                onChange={e => this.handleChange(e, 'learningGoals')}
                options={categoryOptionsTypes}
                className="react-select-box"
              />
              <AreaChart chartData={learningGoalsChartData} type="adminLearningGoals" dateSelected={goalDateSelected}/>
            </div>
          </div>
          <div className="graph-wrapper">
            <div className="dashboard-graph-header">
              <h2 className="heading heading-sm roboto-bold text-uppercase">Events</h2>
            </div>
            <div>
              <div className="graph-filter-row">
                <p>Total Event Hours : <span>{result}</span></p>
                <div className="d-inline-flex ml-auto">
                  <Select
                    placeholder="Team Admin"
                    styles={customSelectStyles}
                    onChange={e => this.handleChange(e, 'userType', 'learningEvents')}
                    options={categoryUserTypes}
                    className="react-select-box mr-3"
                  />
                  <Select
                    placeholder="Current Month"
                    styles={customSelectStyles}
                    onChange={e => this.handleChange(e, 'learningEvents')}
                    options={categoryOptionsTypes}
                    className="react-select-box"
                  />
                </div>
              </div>
              <BarChart chartData={learningEvents} type="admin" selectedRole={selectedRole} dateSelected={eventDateSelected} role={staticConstants.ROLE.SYSTEM_ADMIN} />
            </div>
          </div>
          <div className="graph-wrapper">
            <div className="dashboard-graph-header">
              <h2 className="heading heading-sm roboto-bold text-uppercase">USERS</h2>
            </div>
            <div>
              <div className="graph-filter-row">
                <div className="d-inline-flex ml-auto">
                  <Select
                    placeholder="Team Admin"
                    styles={customSelectStyles}
                    onChange={e => this.handleChange(e, 'createdUserRole', 'createdUser')}
                    options={categoryUserTypes}
                    className="react-select-box mr-3"
                  />
                  <Select
                    placeholder="Current Month"
                    styles={customSelectStyles}
                    onChange={e => this.handleChange(e, 'createdUser')}
                    options={categoryOptionsTypes}
                    className="react-select-box"
                  />
                </div>
              </div>
              <AreaChart chartData={createdUser} type="adminUsers" dateSelected={userDateSelected} />
            </div>
          </div>
          <div className="graph-wrapper">
            <div className="dashboard-graph-header">
              <h2 className="heading heading-sm roboto-bold text-uppercase">REVENUE</h2>
            </div>
            <div>
              <div className="graph-filter-row">
                <div className="d-inline-flex ml-auto">
                  <Select
                    placeholder="Pending Payment"
                    styles={customSelectStyles}
                    onChange={e => this.handleChange(e, 'paymentStatus', 'revenue')}
                    options={categoryStatusTypes}
                    className="react-select-box mr-3"
                  />
                  <Select
                    placeholder="Current Month"
                    styles={customSelectStyles}
                    onChange={e => this.handleChange(e, 'revenue')}
                    options={categoryOptionsTypes}
                    className="react-select-box"
                  />
                </div>
              </div>
              <AreaChart chartData={revenue} type="adminRevenue" dateSelected={revenueDateSelected} />
            </div>
          </div>
          <div className="graph-box-sm">
            <div className="graph-wrapper graph-col-2">
              <div className="dashboard-graph-header">
                <h2 className="heading heading-sm roboto-bold text-uppercase">USERS</h2>
                <Link
                    to={{
                      pathname: `${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.PATH}`,
                    }}
                >View All</Link>
              </div>
              {latestUsers && latestUsers[0] === undefined ? <p className="empty-data">No results found</p> :
                latestUsers && latestUsers.map((user: any, index: number) => {
                  const { firstName, lastName, _id, role, organizationId, email } = user;
                  return (
                    <div key={index} className="event-table-row">
                      <div className="event-table-col table-col-4">
                        <span>Name</span>
                        <p className="text-truncate">{firstName ? `${firstName} ${lastName}` : `${email}`}</p>
                      </div>
                      <div className="event-table-col table-col-4">
                        <span>Role</span>
                        <p className="text-truncate">
                          {role && role === 'orgAdmin' ? 'Team Admin'
                            : role && role === 'learner' ? 'Team Member'
                              : role && role === 'expert' ? 'Expert'
                                : role && role === 'professional' ? 'Individual' : ''} </p>
                      </div>
                      <div className="event-table-col table-col-2 text-right pt-2">
                        <Link
                          to={getSysAdminProfileNavLink(role, _id, organizationId)}
                        >
                          <Button className="btn btn-primary">View</Button>
                        </Link>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="graph-wrapper graph-col-2">
              <div className="dashboard-graph-header">
                <h2 className="heading heading-sm roboto-bold text-uppercase">FLAGGED PROFILES</h2>
                <Link
                    to={{
                      pathname: `${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.PATH}`,
                      state: { activeTab: staticConstants.USER_MANAGEMENT_TAB.FLAG_PROFILES },
                    }}
                >View All</Link>
              </div>
              {dashboardList && dashboardList[0] === undefined ? <p className="empty-data">No results found</p> :
                dashboardList && dashboardList.map((user: any, index: number) => {
                  const { firstName, lastName, _id, role, organizationId } = user;
                  return (
                    <div key={index} className="event-table-row">
                      <div className="event-table-col table-col-4">
                        <span>Name</span>
                        <p className="text-truncate">{firstName && lastName && `${firstName} ${lastName}`}</p>
                      </div>
                      <div className="event-table-col table-col-4">
                        <span>Role</span>
                        <p className="text-truncate">{role && role === 'orgAdmin' ? 'Team Admin' : '' } 
                        { role && role === 'learner' ? 'Team Member' : role.charAt(0).toUpperCase() + role.slice(1).toString()}</p>
                      </div>
                      <div className="event-table-col table-col-2 text-right pt-2">
                      <Link
                          to={getSysAdminProfileNavLink(role, _id, organizationId)}
                        >
                          <Button className="btn btn-primary">View</Button>
                        </Link>
                      </div>
                    </div>
                  );
                })}
            </div>
            </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state: { systemAdminDashboard: { dashboardData: object; latestUsers: Array<[]> };
  users: { dashboardList: Array<[]> }; }) {
  const { dashboardData, latestUsers } = state.systemAdminDashboard;
  const { dashboardList } = state.users;
  return {
    dashboardList,
    dashboardData,
    latestUsers,
  };
}
const connectedDashboardData = connect(mapStateToProps)(DashBoardPage);
export { connectedDashboardData as DashBoardPage };
