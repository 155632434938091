import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import OpenFieldTypes from 'OpenFieldTypes';
import { Modal, ModalBody, Button, Form, FormGroup, Label, Input, ModalFooter, ModalHeader } from 'reactstrap';
import { IValidator } from '../../../interfaces';
import { reeValidators, staticConstants, customSelectStyles, getUpdatedOptions,
  getSelectedValue, revertSelectedOptions, MAGIC_NUMBER } from '../../../utils';
import ReeValidate from 'ree-validate';
import { tagAction } from './../../shared/tags/redux/actions';
import Select from 'react-select';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  isOpenModal: boolean;
  onClickAddTag: any;
  modalTitle: string;
  modalButtonTitle: string;
  activeTab: string;
  domainOptions?: Array<[]>;
  subDomainOptions?: Array<[]>;
  categoryOptions?: Array<[]>;
  subCategoryOptions?: Array<[]>;
  selectedTagData?: object;
  isEditTag?: boolean;
  domainData?: any;
  previousCount: number;
  hideModal(): void;
}

interface IState {
  modal: boolean;
  errors?: any;
  formData: {
    name: string;
    domainId: any;
    subDomainId: any;
    categoryId: any;
    subCategoryId: any;
  };
  isSubmitted: boolean;
}
class CreateTag extends React.Component<IProps, IState> {
  public validator: IValidator;
  constructor(props: IProps) {
    super(props);
    const validateObj = {
      name: reeValidators.required_min_3_max_200,
    };
    this.validator = new ReeValidate(validateObj);
    this.state = {
      modal: this.props.isOpenModal,
      formData: {
        name: '',
        domainId: null,
        subDomainId: null,
        categoryId: null,
        subCategoryId: null,
      },
      isSubmitted: false,
      errors: this.validator.errors,
    };
  }

  /**
   * @description
   * reIntializeValidation is used to add validation again
   */
  public reIntializeValidation = () => {
    const { activeTab } = this.props;
    const validateObj = {
      name: reeValidators.required_min_3_max_200,
    };

    if (activeTab !== staticConstants.SYSTEM_ADMIN_TAGS_TAB.DOMAIN) {
      validateObj['domainId'] = 'required';
    }

    if (activeTab === staticConstants.SYSTEM_ADMIN_TAGS_TAB.CATEGORY || activeTab === staticConstants.SYSTEM_ADMIN_TAGS_TAB.SUB_CATEGORY
      || activeTab === staticConstants.SYSTEM_ADMIN_TAGS_TAB.TOPIC) {
      validateObj['subDomainId'] = 'required';
    }

    if (activeTab === staticConstants.SYSTEM_ADMIN_TAGS_TAB.SUB_CATEGORY || activeTab === staticConstants.SYSTEM_ADMIN_TAGS_TAB.TOPIC) {
      validateObj['categoryId'] = 'required';
    }

    if (activeTab === staticConstants.SYSTEM_ADMIN_TAGS_TAB.TOPIC) {
      validateObj['subCategoryId'] = 'required';
    }

    this.validator = new ReeValidate(validateObj);
  }
  public hideModal = () => {
    this.setState({
      modal: false,
      formData: {
        name: '',
        domainId: null,
        subDomainId: null,
        categoryId: null,
        subCategoryId: null,
      },
    });
    const { errors } = this.validator;
    errors.clear();
    this.props.hideModal();
  }

  public componentWillReceiveProps(props: IProps, nextProp: IProps) {
    this.setState({
      modal: props.isOpenModal,
    });
    if (props.selectedTagData && props['isEditTag']) {
      this.props.dispatch(tagAction.resetTagStatus());
      this.mapEditData();
    }
    this.reIntializeValidation();
  }

  /**
   * @description componentDidMount is called when component is loaded
   */
  public componentDidMount() {
    this.props.dispatch(tagAction.getDomains({ isPagination: true, previousCount: this.props.previousCount }));
    this.reIntializeValidation();
  }

  /**
   * @description mapEditData is used to edit and map data
   */
  public mapEditData = () => {
    const { selectedTagData, activeTab } =  this.props;
    switch (activeTab) {
      case staticConstants.SYSTEM_ADMIN_TAGS_TAB.DOMAIN:
        this.setState({
          formData: {
            ...this.state.formData,
            name: selectedTagData['name'],
          },
        });
        break;
      case staticConstants.SYSTEM_ADMIN_TAGS_TAB.SUB_DOMAIN:
        this.setState({
          formData: {
            ...this.state.formData,
            name: selectedTagData['name'],
            domainId: revertSelectedOptions(selectedTagData['connectingId']),
          },
        });
        break;
      case staticConstants.SYSTEM_ADMIN_TAGS_TAB.CATEGORY:
        this.setState({
          formData: {
            ...this.state.formData,
            name: selectedTagData['name'],
            domainId: revertSelectedOptions(selectedTagData['domainId']),
            subDomainId: revertSelectedOptions(selectedTagData['connectingId']),
          },
        });
        break;
      case staticConstants.SYSTEM_ADMIN_TAGS_TAB.SUB_CATEGORY:
        this.setState({
          formData: {
            ...this.state.formData,
            name: selectedTagData['name'],
            domainId: revertSelectedOptions(selectedTagData['domainId']),
            subDomainId: revertSelectedOptions(selectedTagData['subDomainId']),
            categoryId: revertSelectedOptions(selectedTagData['connectingId']),
          },
        });
        break;
      case staticConstants.SYSTEM_ADMIN_TAGS_TAB.TOPIC:
        this.setState({
          formData: {
            ...this.state.formData,
            name: selectedTagData['name'],
            domainId: revertSelectedOptions(selectedTagData['domainId']),
            subDomainId: revertSelectedOptions(selectedTagData['subDomainId']),
            categoryId: revertSelectedOptions(selectedTagData['categoryId']),
            subCategoryId: revertSelectedOptions(selectedTagData['connectingId']),
          },
        });
        break;
      default:
        break;
    }
  }

  public handleChange = (event: any) => {
    const { name, value } = event.target;
    const { errors } = this.validator;
    this.setState({ formData: { ...this.state.formData, [name]: value } });
    errors.remove(name);
    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors });
      });
  }

  /**
   * @description
   * handleDomain is used to set the domain.
   */
  public handleDomain = (domain: any) => {
    this.setState({ formData: { ...this.state.formData,
      domainId: domain,
      subDomainId: null,
      categoryId: null,
      subCategoryId: null,
    }},           () => {
      if (this.props.activeTab !== staticConstants.SYSTEM_ADMIN_TAGS_TAB.SUB_DOMAIN) {
        const dropdownValues = this.getTagsValue();
        dropdownValues['isPagination'] = true;
        dropdownValues['previousCount'] = this.props.previousCount;
        this.props.dispatch(tagAction.getSubDomains(domain, dropdownValues));
      }
    });
  }

  /**
   * @description
   * handleSubDomain is used to set the subDomain.
   */
  public handleSubDomain = (subDomain: any) => {
    this.setState({ formData: { ...this.state.formData, subDomainId: subDomain,
      categoryId: null,
      subCategoryId: null,
    }},           () => {
      if (this.props.activeTab !== staticConstants.SYSTEM_ADMIN_TAGS_TAB.SUB_DOMAIN && this.props.activeTab !== staticConstants.SYSTEM_ADMIN_TAGS_TAB.CATEGORY) {
        const dropdownValues = this.getTagsValue();
        dropdownValues['isPagination'] = true;
        dropdownValues['previousCount'] = this.props.previousCount;
        this.props.dispatch(tagAction.getCategories(subDomain, dropdownValues));
      }
    });
  }

  /**
   * @description
   * handleCategory is used to set the category.
   */
  public handleCategory = (category: any) => {
    this.setState({ formData: { ...this.state.formData, categoryId: category, subCategoryId: '' } }, () => {
      if (this.props.activeTab !== staticConstants.SYSTEM_ADMIN_TAGS_TAB.CATEGORY && this.props.activeTab !== staticConstants.SYSTEM_ADMIN_TAGS_TAB.SUB_CATEGORY) {
        const dropdownValues = this.getTagsValue();
        dropdownValues['isPagination'] = true;
        dropdownValues['previousCount'] = this.props.previousCount;
        this.props.dispatch(tagAction.getSubCategories(category, dropdownValues));
      }
    });
  }

  /**
   * @description
   * handleSubCategory is used to set the category.
   */
  public handleSubCategory = (subCategory: any) => {
    if (this.props.activeTab === staticConstants.SYSTEM_ADMIN_TAGS_TAB.TOPIC) {
      this.setState({ formData: { ...this.state.formData, subCategoryId: subCategory } });
    }
  }

  /**
   * @description
   * getSelectedValue is used to get all selected dropdown value.
   */
  public getTagsValue = () => {
    return {
      domain: getSelectedValue(this.state.formData.domainId),
      subDomain: getSelectedValue(this.state.formData.subDomainId),
      category: getSelectedValue(this.state.formData.categoryId),
      subCategory: getSelectedValue(this.state.formData.subCategoryId),
    };
  }

  public validateAndSubmit = (e) => {
    e.preventDefault();
    const { name } = this.state.formData;
    const { errors } = this.validator;
    this.setState({ isSubmitted: true });
    this.validator.validateAll({ name })
      .then((success: boolean) => {
        if (success) {
          this.handleSubmit();
        } else {
          this.setState({ errors });
        }
      });
  }

  public handleSubmit() {
    const obj = {};
    const { formData: { name, domainId, subDomainId, categoryId, subCategoryId } } = this.state;
    obj['name'] = name;
    obj['domainId'] = domainId && domainId['value'];
    obj['subDomainId'] = subDomainId && subDomainId['value'];
    obj['categoryId'] = categoryId && categoryId['value'];
    obj['subCategoryId'] = subCategoryId && subCategoryId['value'];

    if (this.props.modalButtonTitle === 'Add') {
      this.props.onClickAddTag(obj);
    } else {
      obj['tagId'] = this.props.selectedTagData['_id'];
      this.props.onClickAddTag(obj);
    }
  }

  public render() {
    const { modal, formData, errors, isSubmitted } = this.state;
    const { modalTitle, modalButtonTitle, activeTab, domainOptions, subDomainOptions, categoryOptions, subCategoryOptions } = this.props;
    let placeHolderTitle: string;
    let errorText: string;
    const disableClass = 'disabled-select';
    switch (activeTab) {
      case staticConstants.SYSTEM_ADMIN_TAGS_TAB.DOMAIN:
        placeHolderTitle = 'Domain Name';
        errorText = 'domain name';
        break;
      case staticConstants.SYSTEM_ADMIN_TAGS_TAB.SUB_DOMAIN:
        placeHolderTitle = 'Sub-Domain Name';
        errorText = 'sub-domain name';
        break;
      case staticConstants.SYSTEM_ADMIN_TAGS_TAB.CATEGORY:
        placeHolderTitle = 'Category Name';
        errorText = 'category name';
        break;
      case staticConstants.SYSTEM_ADMIN_TAGS_TAB.SUB_CATEGORY:
        placeHolderTitle = 'Sub-Category Name';
        errorText = 'sub-category name';
        break;
      case staticConstants.SYSTEM_ADMIN_TAGS_TAB.TOPIC:
        placeHolderTitle = 'Topic Name';
        errorText = 'topic name';
        break;
      default:
        break;
    }
    return (
      <Modal isOpen={modal} className="organization-code-modal create-user-modal" onClosed={this.hideModal}>
        <ModalHeader toggle={this.hideModal}>{modalTitle}</ModalHeader>
        <ModalBody>
           <Form onSubmit={this.validateAndSubmit}>
            {
              (activeTab !== staticConstants.SYSTEM_ADMIN_TAGS_TAB.DOMAIN) &&
                <FormGroup className={`floating-label disabled-input`}>
                  <Select
                    name="domainId"
                    styles={customSelectStyles}
                    options={domainOptions}
                    onChange={this.handleDomain}
                    id="domainId"
                    placeholder="Domain Name"
                    className="react-select-box select-box-group"
                    value={formData.domainId}
                  />
                <Label for="domainId" className={formData.domainId ? 'selected' : ''}>Domain Name</Label>
                {isSubmitted && !formData.domainId &&
                  <div className="error-text">The domain name field is required.</div>
                }
                </FormGroup>
            }
            {
              (activeTab === staticConstants.SYSTEM_ADMIN_TAGS_TAB.CATEGORY || activeTab === staticConstants.SYSTEM_ADMIN_TAGS_TAB.SUB_CATEGORY
                || activeTab === staticConstants.SYSTEM_ADMIN_TAGS_TAB.TOPIC) &&
              <FormGroup className={`floating-label disabled-input`}>
                <Select
                  value={formData.subDomainId}
                  name="subDomainId"
                  styles={customSelectStyles}
                  onChange={this.handleSubDomain}
                  options={subDomainOptions}
                  isDisabled={subDomainOptions.length === MAGIC_NUMBER.ZERO}
                  id="subDomainId"
                  placeholder="Sub-Domain Name"
                  className={`react-select-box select-box-group ${subDomainOptions.length === MAGIC_NUMBER.ZERO ?
                    disableClass : ''}`}
                />
                <Label for="subDomainId" className={formData.subDomainId ? 'selected' : ''}>Sub-Domain Name</Label>
                {isSubmitted && !formData.subDomainId &&
                  <div className="error-text">The sub-domain name field is required.</div>
                }
              </FormGroup>
            }

            {
              (activeTab === staticConstants.SYSTEM_ADMIN_TAGS_TAB.SUB_CATEGORY || activeTab === staticConstants.SYSTEM_ADMIN_TAGS_TAB.TOPIC) &&
              <FormGroup className={`floating-label disabled-input`}>
                <Select
                  value={formData.categoryId}
                  name="categoryId"
                  styles={customSelectStyles}
                  options={categoryOptions}
                  onChange={this.handleCategory}
                  isDisabled={categoryOptions.length === MAGIC_NUMBER.ZERO}
                  id="categoryId"
                  placeholder="Category Name"
                  className={`react-select-box select-box-group ${categoryOptions.length === MAGIC_NUMBER.ZERO ?
                    disableClass : ''}`}
                />
                <Label for="categoryId" className={formData.categoryId ? 'selected' : ''}>Category Name</Label>
                {isSubmitted && !formData.categoryId &&
                  <div className="error-text">The category name field is required.</div>
                }
              </FormGroup>
            }

            {
              (activeTab === staticConstants.SYSTEM_ADMIN_TAGS_TAB.TOPIC) &&
              <FormGroup className={`floating-label disabled-input`}>
                <Select
                  name="subCategoryId"
                  value={formData.subCategoryId}
                  styles={customSelectStyles}
                  options={subCategoryOptions}
                  onChange={this.handleSubCategory}
                  isDisabled={subCategoryOptions.length === MAGIC_NUMBER.ZERO}
                  id="subCategoryId"
                  placeholder="Sub-Category Name"
                  className={`react-select-box select-box-group ${subCategoryOptions.length === MAGIC_NUMBER.ZERO ?
                    disableClass : ''}`}
                />
                <Label for="subCategoryId" className={formData.subCategoryId ? 'selected' : ''}>Sub-Category Name</Label>
                {isSubmitted && !formData.subCategoryId &&
                  <div className="error-text">The sub-category name field is required.</div>
                }
              </FormGroup>
            }
              <FormGroup
                className={`floating-label ${
                  errors.has('name') ? ' has-error' : ''
                  }`}>
                <Input
                  className="form-control"
                  name="name"
                  id="name"
                  autoComplete="off"
                  value={formData.name}
                  onChange={this.handleChange}
                  maxLength={MAGIC_NUMBER.TWO_HUNDRED}
                placeholder={placeHolderTitle}
                />
              <Label for="summary" className={formData.name ? 'selected' : ''}>{placeHolderTitle}</Label>
                {errors.has('name') &&
                  <div className="error-text">{errors.first('name').replace('name',  errorText)}</div>
                }
              </FormGroup>
           </Form>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-regular mr-2" onClick={this.hideModal}>Cancel</Button>{' '}
          <Button color="primary" onClick={this.validateAndSubmit}>{modalButtonTitle}</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

function mapStateToProps(state: any) {
  const { tags: { domains, subDomains, categories, subCategories } } = state;
  return {
    domainOptions: getUpdatedOptions(domains, 'name'),
    subDomainOptions: getUpdatedOptions(subDomains, 'name'),
    categoryOptions: getUpdatedOptions(categories, 'name'),
    subCategoryOptions: getUpdatedOptions(subCategories, 'name'),
  };
}

const connectedCreateTag = connect(mapStateToProps)(CreateTag);
export { connectedCreateTag as CreateTag };
