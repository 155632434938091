import openSocket from "socket.io-client";
const socket = openSocket(process.env.REACT_APP_API_URL.replace("/graphql", ""),{
  reconnectionDelay: 1000,
  reconnection:true,
  reconnectionAttempts: 10,
  agent: false,
  upgrade: false,
  rejectUnauthorized: false
  });
function subscribeOnLogin(email) {
  socket.emit("init", {
    email,
  });
}

function subscribeOnEvent(_id, cb) {
  socket.on("getExtdEventStatus", isEventExtended => cb(null, isEventExtended));
  socket.emit("subscribeOnEvent", {
    goalId: _id,
    interval: 100000,
  });
}

function subscribeExtendEventRequest(_id, cb) {
  socket.on("extendEventRequest", isEventExtendedRequest => cb(null, isEventExtendedRequest));
}
function subscribeMeetingExtendedEvent(_id, cb) {
  socket.on("eventExtended", isMeetingExtended => {
    cb(null, isMeetingExtended);
  });
  socket.emit("subscribeMeetingExtendedEvent", {});
}

function subscribeMeetingEndEvent(_id, cb) {
  socket.on("meetingEnded", isEventExtended => {
    cb(null, isEventExtended);
  });
  socket.emit("subscribeMeetingEndEvent", {});
}

function subscribeMeetingStartEvent(_id, cb) {
  socket.on("meetingStarted", isMeetingStarted => {
    cb(null, isMeetingStarted);
  });
  socket.emit("subscribeMeetingStartEvent", {
    goalId: "sdad",
    interval: 1000,
  });
}

function subscribeEventExtendPayment(_id, cb) {
  socket.on("eventExtendPayment", eventExtendPayment => {
    cb(null, eventExtendPayment);
  });

  socket.emit("eventExtendPayment", {});
}

function subscribeAddOnExpired(cb) {
  socket.on("addOnExpired", data => {
    cb(null, data);
  });
  socket.emit("addOnExpired", {});
}

function subscribeRecordingCompleted(cb) {
  socket.on("recordingCompleted", data => {
    cb(null, data);
  });
  socket.emit("recordingCompleted", {});
}

function subscribeExtendMeetingReminder(cb) {
  socket.on("extendMeetingReminder", data => {
    cb(null, data);
  });
  socket.emit("extendMeetingReminder", {});
}

function unsubscribeAllEvent() {
  socket.off();
}

function disConnectSocket() {
  socket.disconnect();
}

export { subscribeOnLogin, subscribeMeetingExtendedEvent, subscribeOnEvent, disConnectSocket, subscribeMeetingEndEvent, subscribeMeetingStartEvent,
  subscribeExtendEventRequest, subscribeEventExtendPayment, subscribeAddOnExpired, subscribeRecordingCompleted, subscribeExtendMeetingReminder, unsubscribeAllEvent};
