import gql from 'graphql-tag';

const GET_CARDS_QUERY = gql`
  query getCards {
    getCards {
        message,
        default_source,
        data {
          id,
          brand,
          exp_month,
          exp_year,
          name,
          last4,
          country,
          address_zip,
          address_country,
        }
    }
  }`;

const GET_TOTAL_PAYMENT_QUERY = gql`
  query income {
    income {
      message,
      data {
        sessionFee,
        expertFee,
        commission,
        subscriptionFee,
        addonFee,
      }
    }
}`;

const GET_PAYMENT_QUERY = gql`
  query incomeLogs($limit: Int, $page: Int,
    $sort: String, $sortBy: Int, $type: String, $title: String, $eventType: String, $category: String, $domain: String,
    $filter: String, $columnHeaders:[String], $columnKeys: [String], $isGenerateReport: Boolean, $reportTitle: String, $userId: String, $startDate: Date, $endDate: Date) {
      incomeLogs(limit: $limit, page: $page,
      sort:$sort, sortBy: $sortBy, type: $type, title: $title, eventType: $eventType, category: $category, domain: $domain,
      filter: $filter, columnHeaders: $columnHeaders, columnKeys: $columnKeys,
      isGenerateReport: $isGenerateReport, reportTitle: $reportTitle, userId: $userId, startDate: $startDate, endDate: $endDate) {
      message,
      count,
      url,
      reportTitle,
      data {
        openfieldCommissionAmt,
        expertFee,
        sessionFee,
        bidData {
          _id,
          endedAt,
          participants {
            email
          },
          proposedDate
          statusTimeline{
            status,
            createdAt,
          }
        }
        goalData {
          title,
          _id,
          endedAt
        },
        senderData {
          organizationName,
          firstName,
          lastName,
          role
          email
        }
        receiverData {
          firstName
          lastName
          hourlyRate {
            current
          }
        },
        commission
        amount
        invoice
        subscriptionId
        quantity
        createdAt
        addOnAmount,
        type,
        status
        country,
        taxAmount,
      }
    }
  }`;

const GET_TAX_RATE = gql`
  query getTaxRate($postalCode: String!, $countryCode: String!) {
    getTaxRate(postalCode: $postalCode, countryCode: $countryCode) {
      message,
      taxRate
    }
}`;

const WITHDRAWL_REQUEST_LIST = gql`
  query withdrawnRequestList {
    withdrawnRequestList {
      data{
        status
        amount
        createdAt
      }
    }
}`;

const WITHDRAWL_LIST = gql`
  query withdrawnList($status: String, $page: Int, $limit: Int, $sort: String, $sortBy: Int, $isCsvDownload: Boolean,$searchKey: String ) {
    withdrawnList(status: $status, page: $page, limit: $limit, sort: $sort, sortBy: $sortBy, isCsvDownload: $isCsvDownload, searchKey: $searchKey) {
      data{
        _id,
        amount,
        createdAt,
        user{
        name,
        _id
      }
      payOutDate,
      }
      count,
      url,
    }
}`;

const WITHDRAWAL_HISTORY_LIST = gql`
  query userWithdrawnList($page: Int, $limit: Int, $sort: String, $sortBy: Int, $userId: ID! ) {
    userWithdrawnList(page: $page, limit: $limit, sort: $sort, sortBy: $sortBy, userId: $userId) {
      data{
        _id,
        amount,
        createdAt,
        user{
        name,
        _id
      }
      payOutDate,
      }
      count,
      url,
    }
}`;

export { GET_CARDS_QUERY, GET_TOTAL_PAYMENT_QUERY, GET_PAYMENT_QUERY, GET_TAX_RATE, WITHDRAWL_REQUEST_LIST, WITHDRAWL_LIST, WITHDRAWAL_HISTORY_LIST };
