import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { Sidebar } from '../../shared/sidebar';
import {
  Button,
} from 'reactstrap';
import { staticConstants, CSS_ClASSES } from '../../../utils';
import OpenFieldTypes from 'OpenFieldTypes';
import { GoalRequests } from './listing/goalRequests';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
}
class RequestManagementContainer extends React.Component<IProps> {

  public state = {
    activeTab: staticConstants.ADMIN_REQUEST_MANAGEMENT_TAB.ADMIN_ORGANIZATION_ADMIN_REQUESTS,
  };

  /**
   * @description
   * render is used to render the html.
   */
  public render() {
    const { activeTab } = this.state;
    return (
      <React.Fragment>
        <Sidebar {...this.props} />
        <div className="dashboard-wrapper">
          <h2 className="heading heading-sm roboto-medium text-uppercase">Request Management</h2>
          <div className="dashboard-content">
            <div className="tab-btn-group">
              <Button
                color="secondary"
                className={'btn btn-50 h-50 tab-btn text-uppercase ' + (activeTab === staticConstants.ADMIN_REQUEST_MANAGEMENT_TAB.ADMIN_ORGANIZATION_ADMIN_REQUESTS ? CSS_ClASSES.btnActive : '')}
                onClick={() => this.setState({ activeTab: staticConstants.ADMIN_REQUEST_MANAGEMENT_TAB.ADMIN_ORGANIZATION_ADMIN_REQUESTS })}
              >
               team admin
              </Button>
              <Button
                color="secondary"
                className={'btn btn-50 h-50 tab-btn text-uppercase ' + (activeTab === staticConstants.ADMIN_REQUEST_MANAGEMENT_TAB.ADMIN_PROFESSIONAL_REQUESTS ? CSS_ClASSES.btnActive : '')}
                onClick={() => this.setState({ activeTab: staticConstants.ADMIN_REQUEST_MANAGEMENT_TAB.ADMIN_PROFESSIONAL_REQUESTS })}
              >
                individual
              </Button>
            </div>
            <GoalRequests activeTab={activeTab} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  return {};
}

const connectedRequestManagementContainer = connect(mapStateToProps)(RequestManagementContainer);
export { connectedRequestManagementContainer as RequestManagementContainer };
