import React, { Dispatch } from 'react';
import OpenFieldTypes from 'OpenFieldTypes';
import classnames from 'classnames';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { LandingFooter } from '../../landingFooter';
import { LandingHeader } from '../../landingHeader';
import { connect } from 'react-redux';
import { contentManagementAction } from '../../../systemAdmin/contentManagement/redux/actions';
import { staticConstants } from '../../../../utils';
import jessicaImage from './../../../../assets/images/Jessica.png';
import saamiImage from './../../../../assets/images/Saami.png';
import qualifieldImage from './../../../../assets/images/qualifield.svg';
import customImage from './../../../../assets/images/custom.svg';
import enterpriceImage from './../../../../assets/images/enterprice.svg';
import activityImage from './../../../../assets/images/activity.svg';
import videoImage from './../../../../assets/images/video.svg';
import smartImage from './../../../../assets/images/smart.svg';
import linkedinImage from './../../../../assets/images/linkedin_small.png';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  content: Array<[]>;
}

interface IState {
  activeTab: any;
  title: string;
  subtitle: string;
  jessica: boolean;
  sammi: boolean;
}

class AboutUs extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);

    this.state = {
      activeTab: '1',
      jessica: false,
      sammi: false,
      title: staticConstants.LANDING_PAGE_FOOTER_TITLE.ABOUT_US,
      subtitle: staticConstants.LANDING_PAGE_SUB_TITLE.ABOUT_US
    };
  }

  public componentDidMount() {
    this.props.dispatch(contentManagementAction.getContent({
      type: staticConstants.CONTENT_MANAGEMENT_TAB.ABOUT_US,
    }));
  }

  public toggle = (tab: any) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  public createMarkUp = () => {
    const { content } = this.props;
    if (content && content[0] !== undefined && content[0]['content']) {
      const data = content[0]['content'];
      return {
        __html: data,
      };
    }
    return {
      __html: '',
    };
  }

  public openJesicaModal = () => {
    this.setState({
        jessica: true,
        sammi: false,
    });
  }

  public openSamiModal = () => {
    this.setState({
        sammi: true,
        jessica: false
    });
  }

  public hideJesicaModal = () => {
      this.setState({
          jessica: false,
      });
  }

  public hideSamiModal = () => {
    this.setState({
        sammi: false,
    });
  }

  public render() {
    const activeTab = this.state.activeTab;
    const { title, jessica, sammi, subtitle } = this.state;
    return (
      <>
        <LandingHeader title={title} subTitle={subtitle} banner={'about_banner'}/>
        <div className="common_padding pt-0">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="faq_tab">
                  <ul className="nav nav-tabs justify-content-center">
                    <li className="ml-lg-5 mr-lg-5 ml-3 mr-3">
                      <a href="javascript:void(0)"
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => { this.toggle('1'); }}
                      >Team</a>
                    </li>
                    <li><a href="javascript:void(0)"
                      className={classnames({ active: activeTab === '2' })}
                      onClick={() => { this.toggle('2'); }}
                    >product</a></li>
                  </ul>
                  <div className="tab-content pt-lg-5 pt-4">
                    <div className={`tab-pane container fade ${activeTab === '1' ? 'active show' : ''}`} >
                      <div className="row">
                        <div className="col-12 text-center mb-3">
                          <h2 className="common_heading">Who We Are</h2>
                        </div>
                        <div className="col-12 text-center">
                          <p className="common_p">We made Overlap because sustainability demands collaboration. Our commitment is to the people doing the work. We believe this a better way for you to find and book time with sustainability people who know your business.</p>
                        </div>
                        <div className="col-12 mt-lg-5 mt-3">
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 mb-md-0 mb-3 text-center">
                              <div className="team_member">
                                <img src={jessicaImage} alt="Jessica Wills" className="img-fluid" />
                                <h2 onClick={this.openJesicaModal} >Jessica Wills</h2>
                                <p>Co-Founder &amp; COO</p>
                                <a href="https://www.linkedin.com/in/jessica-wills-879352108/" target="_blank">
                                  <img src={linkedinImage} alt="Linkdin" /></a>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                              <div className="team_member">
                                <img src={saamiImage} alt="Saami Kalule-Sabiti" className="img-fluid" />
                                <h2 onClick={this.openSamiModal} >Saami Kalule-Sabiti</h2>
                                <p>Co-Founder &amp; CEO</p>
                                <a href="https://www.linkedin.com/in/saamisabiti" target="_blank"><img src={linkedinImage} alt="Linkdin" /></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`tab-pane container fade ${activeTab === '2' ? 'active show' : ''}`} >
                      <div className="row">
                        <div className="col-12 text-center mb-4">
                          <h2 className="common_heading">What We Offer</h2>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
                          <a href="javascript:void(0)" className="hover_none">
                            <div className="experience_three_div">
                              <div className="svg_div">
                                <img src={qualifieldImage} alt="image" />
                              </div>
                              <h3>Qualified People</h3>
                              <p>Access people with experience and knowledge.</p>
                            </div>
                          </a>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
                          <a href="javascript:void(0)" className="hover_none">
                            <div className="experience_three_div">
                              <div className="svg_div">
                                <img src={customImage} alt="image" />
                              </div>
                              <h3>Custom Events</h3>
                              <p>Get advice, instant training, live
                              problem-solving and more.
                            </p>
                            </div>
                          </a>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
                          <a href="javascript:void(0)" className="hover_none">
                            <div className="experience_three_div">
                              <div className="svg_div">
                                <img src={enterpriceImage} alt="image" />
                              </div>
                              <h3>Enterprise-ready</h3>
                              <p>Feel secure with our global knowledge-sharing standards.</p>
                            </div>
                          </a>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
                          <a href="javascript:void(0)" className="hover_none">
                            <div className="experience_three_div">
                              <div className="svg_div">
                                <img src={activityImage} alt="image" />
                              </div>
                              <h3>Activity Tracking</h3>
                              <p>Dashboard with at-a-glance metrics.</p>
                            </div>
                          </a>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
                          <a href="javascript:void(0)" className="hover_none">
                            <div className="experience_three_div">
                              <div className="svg_div">
                                <img src={videoImage} alt="image" />
                              </div>
                              <h3>Video Conferencing</h3>
                              <p>Zoom and download your events.
                            </p>
                            </div>
                          </a>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
                          <a href="javascript:void(0)" className="hover_none">
                            <div className="experience_three_div">
                              <div className="svg_div">
                                <img src={smartImage} alt="image" />
                              </div>
                              <h3>Smart Booking</h3>
                              <p>Hassle-free scheduling for busy people.</p>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={jessica} className="feedback-modal">
          <ModalHeader toggle={this.hideJesicaModal}>Jessica Wills</ModalHeader>
          <ModalBody>
              Jessica is Co-founder and COO at Overlap where she leads Product Development and Operations.
              She is also General Manager at the climate negotiation and consulting firm Summit Outcomes Group.
              She lives in Toronto, Canada.
          </ModalBody>
        </Modal>
        <Modal isOpen={sammi} className="feedback-modal">
          <ModalHeader toggle={this.hideSamiModal}>Saami Kalule-Sabiti</ModalHeader>
          <ModalBody>
              Saami is Co-founder and CEO at Overlap where he oversees Strategy and Product Development.
              He has worked in climate and workforce development policy and programs in New York City.
              He lives in Columbus, Ohio, in the United States.
          </ModalBody>
        </Modal>
        <LandingFooter activeTab={staticConstants.CONTENT_MANAGEMENT_TAB.ABOUT_US} />
      </>
    );
  }
}

function mapStateToProps(state: { contentManagement: { content: Array<[]> } }) {
  const { content } = state.contentManagement;
  return {
    content,
  };
}

const connectedAboutUsContainer = connect(mapStateToProps)(AboutUs);
export { connectedAboutUsContainer as AboutUs };
