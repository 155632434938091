import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Media } from 'reactstrap';
import { SliderContainer } from '../../slider';
import logoImage from './../../../../assets/images/logo.svg';
interface IProps {
  success: {
   actionLabel?: string;
   action?: any;
   title?: string;
   message: string;
 };
}

class SuccessMessageContainer extends React.Component<IProps> {

  public render() {
    const createMarkup = () => {
       return { __html: this.props.success.message };
     };
    return (
      <div className="container-fluid">
        <div className="row min-vh-100">
          <div className="col-lg-6 col-md-12 login_bg">
            <div className="login_banner_text">
              <p>Everyone is going to need to understand [climate change] the same way you’d assume everyone in business needs to have some fluency in social media today.</p>
              <h5>Andrew Winston</h5>
              <p className="text-right">Author</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="login_form">
              <div className="row">
                <div className="col-12 link-verified-section">
                  <div className="login_details">
                    <Link to="/"> <img src={logoImage} alt="Logo" className="img-fluid mb-4" /></Link>
                    <div className="form-wrapper d-flex align-items-center flex-wrap">
                      <div className="form-group-wrapper">
                        <Media>
                          <Media top className="icon"/>
                          <Media body>
                            <h2 className="common_heading mt-2">
                              {this.props.success.title}
                            </h2>
                            <p>
                              <div  dangerouslySetInnerHTML={createMarkup()}/>
                            </p>
                            {this.props.success.action &&
                              <Link className="get_start_btn cta_btn d-block text-center w-100" to={this.props.success.action} color="primary">
                              {this.props.success.actionLabel ? this.props.success.actionLabel : 'Done'}
                            </Link>
                            }
                          </Media>
                        </Media>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

     );
  }
}

export { SuccessMessageContainer };
