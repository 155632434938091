import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import { staticConstants } from '../../../utils/staticConstants';
import { registerAction } from './redux/actions';

import ReeValidate from 'ree-validate';
import { IOrgAdmin, IValidator } from '../../../interfaces';
import logoImage from './../../../assets/images/logo.svg';
import OpenFieldTypes from 'OpenFieldTypes';

interface IProps {
  user?: IOrgAdmin;
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
}

interface IState {
  user?: IOrgAdmin;
  errors?: any;
}

class OrgAdminRegisterForm extends React.Component<IProps, IState> {
  public validator: IValidator;
  constructor(props: IProps) {
    super(props);
    this.validator = new ReeValidate({
      email: 'required|email',
      organizationName: 'required|min:3',
      licence: 'required|max_value:14000|min_value:1',
    });
    this.state = {
      user: {
        email: '',
        organizationName: '',
        userType: staticConstants.ROLE.ORGANIZATION_ADMIN,
        licence: 0,
      },
      errors: this.validator.errors,
    };
  }

  public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const { errors } = this.validator;
    this.setState({ user: { ...this.state.user, [name]: value } });
    errors.remove(name);

    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors });
      });
  }

  public validateAndSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const { email, organizationName, licence } = this.state.user;
    const { errors } = this.validator;

    this.validator.validateAll({ email, organizationName, licence })
      .then((success: boolean) => {
        if (success) {
          this.handleSubmit();
        } else {
          this.setState({ errors });
        }
      });

  }
  public handleSubmit = () => {
    const { user } = this.state;
    const { dispatch } = this.props;
    user.licence = Number(user.licence);
    dispatch(registerAction.register(user));
  }

  public render() {
    const { user, errors } = this.state;
    const getErrClass = (field: string) => {
      return errors.has(field) ? ' has-error' : '';
    };

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-md-12 login_bg order-md-2">
            <div className="login_banner_text">
              <p>Everyone is going to need to understand [climate change] the same way you’d assume everyone in business needs to have some fluency in social media today.</p>
              <h5>Andrew Winston</h5>
              <p className="text-right">Author</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="login_form">
              <div className="row">
                <div className="col-12">
                  <div className="signup_account">
                    <span>Have an account?</span>
                    <Link to="/login" className="signup_btn">LOG IN</Link>
                  </div>
                </div>
                <div className="col-12">
                  <div className="login_details">
                    <Link to="/" ><img src={logoImage} alt="Logo" className="img-fluid mb-4" /></Link>
                    <h2 className="common_heading mt-2">Get Started & Browse Experts</h2>
                    <p>Find your match and achieve your goals.</p>
                    <Form onSubmit={this.validateAndSubmit} noValidate>
                      <FormGroup className={`floating-label ${getErrClass('organizationName')}`}>
                        <Input
                          type="text"
                          className="form-control"
                          name="organizationName"
                          id="organizationName"
                          value={user.organizationName}
                          onChange={this.handleChange}
                          placeholder="Name of organization"
                        />
                        <Label for="firstName">Name of organization</Label>
                        {errors.has('organizationName') &&
                          <div className="error-text">
                            {errors.first('organizationName').replace('organizationName', 'team name')}</div>
                        }
                      </FormGroup>
                      <FormGroup className={`floating-label ${getErrClass('email')}`}>
                        <Input
                          type="text"
                          className="form-control"
                          name="email"
                          id="email"
                          value={user.email}
                          onChange={this.handleChange}
                          placeholder="Email"
                        />
                        <Label for="email">Email</Label>
                        {errors.has('email') &&
                          <div className="error-text">{errors.first('email')}</div>
                        }
                      </FormGroup>
                      <FormGroup className={`floating-label ${getErrClass('licence')}`}>
                        <Input
                          type="number"
                          className="form-control"
                          name="licence"
                          id="licence"
                          value={user.licence}
                          onChange={this.handleChange}
                          placeholder="How many team member licenses do you need?"
                        />
                        <Label for="licence">How many team member licenses do you need?</Label>
                        {errors.has('licence') &&
                          <div className="error-text">{errors.first('licence')}</div>
                        }
                      </FormGroup>
                      <FormGroup className="login_button mt-5">
                        <Button color="get_start_btn" className="get_start_btn cta_btn d-block text-center w-100">
                          Sign Up
                        </Button>
                      </FormGroup>
                    </Form>
                    <div className="signup_term_con mt-4">
                      <p>By clicking “Sign Up” I agree to OVLP <Link to="/termsofuse" target="_blank">Terms of Use</Link> &amp; <Link to="/privacyPolicy" target="_blank">Privacy Policy</Link> .</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { registering } = state.registeration;
  return {
    registering,
  };
}

const connectedRegisterPage = connect(mapStateToProps)(OrgAdminRegisterForm);
export { connectedRegisterPage as OrgAdminRegisterForm };
