import gql from 'graphql-tag';

export const GET_MESSAGE_BOARD_CONNECTIONS = gql`
  query messageBoard{
    messageBoard {
      message,
      data {
        _id
        user1 {
          firstName
          lastName
          email
          _id
          role
          profileImage
          organizationId
        }
        user2{
          firstName
          lastName
          email
          _id
          role
          profileImage
          organizationId
        }
        roomId
      }
    }
}`;

export const GET_GROUPS = gql`
  query getGroup{
    getGroup {
      message
      data {
        _id
        groupName
        users{
          _id,
          firstName,
          lastName,
          email,
          role
        }
      }
    }
  }
`;
