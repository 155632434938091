import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Modal,
  ModalBody,
} from 'reactstrap';
import OpenFieldTypes from 'OpenFieldTypes';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  isOpenModal: boolean;
  modalPopupObj: any;
  hideModal(): void;
  onClickYes(): void;
}

interface IState {
  modal: boolean;
}

class CancelBidPopup extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      modal: props.isOpenModal,
    };
    this.hideModal = this.hideModal.bind(this);
  }

  public hideModal() {
    this.setState({
      modal: false,
    });
    this.props.hideModal();
  }

  public render() {
    const { modal } = this.state;
    const { modalPopupObj } = this.props;
    return (
      <Modal isOpen={modal} className="delete-modal">
        <ModalBody>
          <h2 className="heading heading-lg roboto-bold text-center">{modalPopupObj.title}</h2>
          <p>{modalPopupObj.body} <br /> {modalPopupObj.areYouSure}</p>
          <div className="button-group">
            {modalPopupObj.yesBtnText ?
              <Button className="btn btn-regular mx-1"  onClick={this.props.onClickYes} >{modalPopupObj.yesBtnText}</Button>
              : ''}
            {modalPopupObj.noBtnText ?
              <Button className={`btn mx-2 ${modalPopupObj.yesBtnClass ? modalPopupObj.yesBtnClass : 'btn-delete'}`}
              onClick={this.hideModal}>{modalPopupObj.noBtnText}</Button>
              : ''}
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const connectedCancelPopupPage = connect()(CancelBidPopup);
export { connectedCancelPopupPage as CancelBidPopup };
