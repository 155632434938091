import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { v4 } from 'uuid';
import Pagination from 'react-js-pagination';
import { FilterComponent } from '../../filter/filterComponent';
import { rfpAction } from '../redux/actions';
import OpenFieldTypes from 'OpenFieldTypes';
import {
  getUpdatedOptions, getSelectedValue, rfpStatusTypes, staticConstants,
  MAGIC_NUMBER, completeRequest
} from './../../../../utils';
import { RequestItem } from './requestItem';
import { CloseBidPopup } from '../../modals/closeBidPopup';
import { tagAction } from '../../../shared/tags/redux/actions';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  rfpRequest: any;
  role: any;
}

class Listing extends React.Component<IProps> {

  /**
   * @description getDerivedStateFromProps is called when there in change in property
   * @param fields {Object} props
   */
  public static getDerivedStateFromProps(props: IProps) {
    if (props.rfpRequest && props.rfpRequest.list) {
      return {
        rfpRequest: props.rfpRequest,
      };
    }
    return null;
  }

  public state = {
    items: MAGIC_NUMBER.TEN,
    activePage: 1,
    isRequestManagement: true,
    sort: '',
    sortBy: 0,
    rfpRequest: {
      count: 0,
      list: [],
      fetchedListing: false,
    },
    domain: '',
    status: '',
    title: '',
    eventType: '',
    displayCancelPopup: false,
    modalPopupObj: {},
    goalId: '',
    reqObj: {},
  };

  /**
   * @description componentDidMount is called when component is loaded
   * call goalAction.getGoals to fetch goal listings
   * call goalAction.getGoals to fetch goal categories
   */
  public componentDidMount() {
    const { items, activePage, isRequestManagement } = this.state;
    this.props.dispatch(rfpAction.getRequests({
      page: Number(activePage),
      limit: items,
      isRequestManagement,
    }));
    this.props.dispatch(tagAction.getDomains());
  }

  /**
   * @description fetchGoalsData is used on initial load
   */
  public fetchRfpData(pageno: number) {
    const { sort, sortBy, isRequestManagement } = this.state;
    let reqObj = {
      page: pageno,
      limit: this.state.items,
      eventType: getSelectedValue(this.state.eventType),
      domain: getSelectedValue(this.state.domain),
      status: getSelectedValue(this.state.status),
      title: this.state.title,
      isRequestManagement,
    };
    if (sort) {
      reqObj = Object.assign(reqObj, { sort, sortBy });
    }
    this.props.dispatch(rfpAction.getRequests(reqObj));
  }

  /**
   * @description
   * sortData is used for sorting purpose.
   * @param fields {Number} sort
   * @param fields {String} sortBy
   */
  public sortData(sort: any, sortBy: any) {
    const { activePage } = this.state;
    this.setState({ ...this.state, sort, sortBy },
                  () => this.fetchRfpData(activePage));
  }

  /**
   * @description
   * handleChange is used to set the value on state from the input.
   * @param fields {Object} e
   * @param fields {String} key
   */
  public handleChange = (value, key: string) => {
    this.setState({ ...this.state, [key]: value },
                  () => { this.fetchRfpData(1); });
  }

  /**
   * @description
   * handlePageChange is called someone click on the pagination.
   * @param fields {Number} pageno
   */
  public handlePageChange = (pageno: number) => {
    this.setState({
      activePage: pageno,
    },            () => this.fetchRfpData(pageno));
  }

  /**
   * @description
   * showCancelPopup is used show confirmation popup.
   * @param fields {String} id
   */
  public showCancelPopup = (id: string) => {
    const { sort, sortBy, isRequestManagement } = this.state;
    const modalPopupObj = completeRequest;
    let reqObj = {
      page: Number(this.state.activePage),
      limit: this.state.items,
      eventType: getSelectedValue(this.state.eventType),
      domain: getSelectedValue(this.state.domain),
      status: getSelectedValue(this.state.status),
      title: this.state.title,
      isRequestManagement,
    };
    if (sort) {
      reqObj = { ...reqObj, ...{ sort, sortBy } };
    }
    this.setState({
      displayCancelPopup: true,
      goalId: id,
      reqObj,
      modalPopupObj,
    });
  }

  /**
   * @description
   * cancelRequest is called when someone click on the ok button
   */
  public cancelRequest = () => {
    const { goalId, reqObj } = this.state;
    this.props.dispatch(rfpAction.updateRequestStatus(
      { status: 'cancelRequest', goalId },
      reqObj));
    this.setState({
      displayCancelPopup: false,
      modalPopupObj: {},
      goalId: '',
    });
  }

  /**
   * @description
   * completeRequest is called when someone click on the ok button
   */
  public cancelPopupHide = () => {
    this.setState({
      displayCancelPopup: false,
      modalPopupObj: {},
      goalId: '',
    });
  }

  /**
   * @description
   * completeRequest is called when someone click on the ok button
   */
  public completeRequest = (status: boolean) => {
    const { goalId, activePage } = this.state;
    this.props.dispatch(rfpAction.completeRequest(goalId, status));
    this.fetchRfpData(activePage);
    this.setState({
      displayCancelPopup: false,
      modalPopupObj: {},
      goalId: '',
    });
  }

  /**
   * @description
   * renderRequestListing is called to render the request listings
   */
  public renderRequestListing = () => {
    const { list } = this.state.rfpRequest;
    return _.map(list, (item) => {
      return (
        <RequestItem key={v4()} request={item} onCancel={this.showCancelPopup} />
      );
    });
  }

  /**
   * @description
   * render is used to render the html.
   */
  public render() {
    const { sort, sortBy, rfpRequest, items, activePage, displayCancelPopup, modalPopupObj } = this.state;
    const filterContent = this.props.role === staticConstants.ROLE.PROFESSIONAL
      ? ['search']
      : ['search', 'eventType', 'status', 'domain'];
    let minDisplay;
    let maxDisplay;
    if (rfpRequest.count === MAGIC_NUMBER.ZERO) {
      maxDisplay = MAGIC_NUMBER.ZERO;
      minDisplay = MAGIC_NUMBER.ZERO;
    } else if (rfpRequest.count <= items) {
      maxDisplay = rfpRequest.count;
      minDisplay = MAGIC_NUMBER.ZERO;
    } else {
      maxDisplay = (Number(activePage) * Number(items)) < rfpRequest.count
        ? (Number(activePage) * Number(items)) : rfpRequest.count;
      minDisplay = ((Number(activePage) - 1) * Number(items)) + 1;
    }
    return (
      <React.Fragment>
        <div className="filter-wrapper">
          <FilterComponent
            filterContent={filterContent}
            status={rfpStatusTypes}
            handleFilter={this.handleChange}
          />
        </div>
        <div className="table-wrapper">
          <div className="flex-table">
            <div className="table-head" >
              <div className="table-row">
                <div className="table-col table-col-15">
                  <span className="sorting-icon">Title
                      <strong>
                      <em
                        className={(sort === 'title' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                        onClick={() => { this.sortData('title', MAGIC_NUMBER.ONE); }}
                      />
                      <em
                        className={(sort === 'title' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                        onClick={() => { this.sortData('title', MAGIC_NUMBER.FOUND_INDEX); }}
                      />
                    </strong>
                  </span>
                </div>
                <div className="table-col table-col-10">
                  <span className="sorting-icon">Event Type
                      <strong>
                      <em
                        className={(sort === 'eventType' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                        onClick={() => { this.sortData('eventType', MAGIC_NUMBER.ONE); }}
                      />
                      <em
                        className={(sort === 'eventType' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                        onClick={() => { this.sortData('eventType', MAGIC_NUMBER.FOUND_INDEX); }}
                      />
                    </strong>
                  </span>
                </div>
                <div className="table-col table-col-12">
                  <span className="sorting-icon">Domain
                      <strong>
                      <em
                        className={(sort === 'domain' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                        onClick={() => { this.sortData('domain', MAGIC_NUMBER.ONE); }}
                      />
                      <em
                        className={(sort === 'domain' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                        onClick={() => { this.sortData('domain', MAGIC_NUMBER.FOUND_INDEX); }}
                      />
                    </strong>
                  </span>
                </div>
                <div className="table-col table-col-13">
                  <span className="sorting-icon">Sub-Domain
                      <strong>
                      <em
                        className={(sort === 'subDomain' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                        onClick={() => { this.sortData('subDomain', MAGIC_NUMBER.ONE); }}
                      />
                      <em
                        className={(sort === 'subDomain' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                        onClick={() => { this.sortData('subDomain', MAGIC_NUMBER.FOUND_INDEX); }}
                      />
                    </strong>
                  </span>
                </div>
                <div className="table-col table-col-13">
                  <span className="sorting-icon justify-content-center">Participants
                      <strong>
                      <em
                        className={(sort === 'totalParticipants' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                        onClick={() => { this.sortData('totalParticipants', MAGIC_NUMBER.ONE); }}
                      />
                      <em
                        className={(sort === 'totalParticipants' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                        onClick={() => { this.sortData('totalParticipants', MAGIC_NUMBER.FOUND_INDEX); }}
                      />
                    </strong>
                  </span>
                </div>
                <div className="table-col table-col-17">
                  <span className="sorting-icon">Date Availability
                  </span>
                </div>
                <div className="table-col table-col-10">
                  <span className="sorting-icon">Status
                    <strong>
                      <em
                        className={(sort === 'bidCount' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                        onClick={() => { this.sortData('bidCount', MAGIC_NUMBER.ONE); }}
                      />
                      <em
                        className={(sort === 'bidCount' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                        onClick={() => { this.sortData('bidCount', MAGIC_NUMBER.FOUND_INDEX); }}
                      />
                    </strong>
                  </span>
                </div>
                <div className="table-col table-col-10 text-center">
                  <span>Action</span>
                </div>
              </div>
            </div>
            <div className="table-body">
              {this.renderRequestListing()}
              {rfpRequest && rfpRequest.fetchedListing && rfpRequest.count === MAGIC_NUMBER.ZERO ?
                <div className="table-row table-no-data">
                  No Results Found.
                    </div>
                : ''}
            </div>
            {
              (rfpRequest && rfpRequest.count > MAGIC_NUMBER.TEN) &&
              <div className="table-footer">
                <React.Fragment>
                  <p>Showing {minDisplay} - {maxDisplay} of {rfpRequest.count}</p>
                  <div className="pagination-wrapper">
                    <Pagination
                      hideFirstLastPages
                      activePage={activePage}
                      itemsCountPerPage={items}
                      totalItemsCount={rfpRequest.count}
                      pageRangeDisplayed={MAGIC_NUMBER.FIVE}
                      onChange={this.handlePageChange}
                    />
                  </div>
                </React.Fragment>
              </div>
            }
          </div>
        </div>
        {displayCancelPopup &&
          <CloseBidPopup
                isOpenModal={displayCancelPopup}
                modalPopupObj={modalPopupObj}
                cancelPopupHide={this.cancelPopupHide}
                hideModal={() => { this.completeRequest(true); }}
                onClickYes={() => { this.completeRequest(false); }}
              />
        }
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  const { tags: { domains }, authentication } = state;
  const { user } = authentication;
  return {
    rfpRequest: state.rfpRequest,
    domainOptions: getUpdatedOptions(domains, 'name'),
    role: user.role,
  };
}

const connectedRequestListing = connect(mapStateToProps)(Listing);
export { connectedRequestListing as Listing };
