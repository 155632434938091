import React, { Dispatch } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input, Label,
} from 'reactstrap';
import { IChangeEmailReq, IValidator } from '../../../interfaces';
import { profileAction } from './redux/actions';
import ReeValidate from 'ree-validate';
import { validator } from '../../../helpers';
import OpenFieldTypes from 'OpenFieldTypes';
import { connect } from 'react-redux';
import { MAGIC_NUMBER } from '../../../utils';

validator(ReeValidate);

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  toggle: any;
  changeEmailSuccess: boolean;
  clearData: boolean;
}

interface IState {
  changeEmailFormData: {
    newEmail: string;
    password: string;
  };
  errors: any;
  createPassword?: any;
}

class ChangeEmailForm extends React.Component<IProps, IState> {
  public validator: IValidator;
  constructor (props: any) {
    super(props);
    this.validator = new ReeValidate({
      newEmail: 'required|email',
      password: 'required|verify_password',
    });
    this.state = {
      changeEmailFormData: {
        newEmail: '',
        password: '',
      },
      errors: this.validator.errors,
    };
  }

  public componentWillReceiveProps (props: any, nextProps: any) {
    const { errors } = this.validator;
    if (props.changeEmailSuccess || props.clearData) {
      errors.remove('newEmail');
      errors.remove('password');
      this.setState({
        changeEmailFormData: {
          newEmail: '',
          password: '',
        },
      });
    }
  }

  public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const { errors } = this.validator;

    this.setState({ changeEmailFormData: { ...this.state.changeEmailFormData, [name]: value } });
    errors.remove(name);

    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors });
      });
  }

  public validateAndSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const { password, newEmail } = this.state.changeEmailFormData;
    const { errors } = this.validator;
    this.validator.validateAll({ password, newEmail })
      .then((success: boolean) => {
        if (success) {
          this.handleSubmit();
        } else {
          this.setState({ errors });
        }
      });
  }

  public handleSubmit = () => {
    const { dispatch } = this.props;
    const { newEmail, password } = this.state.changeEmailFormData;
    const requestObject: IChangeEmailReq = {
      newEmail,
      password,
    };
    dispatch(profileAction.changeEmail(requestObject));
  }

  public render () {
    const { changeEmailFormData, errors } = this.state;
    return (<div className="form-section" >
      <div className="form-wrapper d-flex align-items-center flex-wrap">
        <div className="form-group-wrapper create-password-wrapper">
          <Form noValidate>
            <FormGroup
              className={ `floating-label ${ changeEmailFormData.newEmail.length > MAGIC_NUMBER.ZERO && !errors.has('newEmail') ? ' set-password' : ''
                }` }>

              <Input
                type="email"
                className="form-control"
                name="newEmail"
                id="newEmail"
                value={changeEmailFormData.newEmail}
                onChange={this.handleChange}
                placeholder="New Email"
                ref="newEmail"
              />
              <Label for="newEmail">New Email</Label>
              { errors.has('newEmail') &&
                <div className="error-text">{errors.first('newEmail').replace('newEmail', ' new email')}</div>
              }
            </FormGroup>
            <FormGroup
              className={ `floating-label ${ changeEmailFormData.password.length > MAGIC_NUMBER.ZERO && !errors.has('password') ? ' set-password' : ''
                }` }>

              <Input
                type="password"
                className="form-control"
                name="password"
                id="password"
                value={changeEmailFormData.password}
                onChange={this.handleChange}
                placeholder="Password"
                ref="password"
              />
              <Label for="password">Password</Label>
              { errors.has('password') &&
                <div className="error-text">{errors.first('password').replace('password', 'password')}</div>
              }
            </FormGroup>

            <FormGroup className="login-button-group mb-0 d-flex justify-content-end">
              <Button onClick={() => { this.props.toggle('1'); }} className="btn btn-regular mr-3">
                Cancel
              </Button>
              <Button color="primary" className="" onClick={this.validateAndSubmit}>
                Update
              </Button>
            </FormGroup>
          </Form>
        </div>
      </div>
    </div>);
  }
}
function mapStateToProps(state: any) {
  const { changeEmailSuccess } = state.updateProfile;
  return {
    changeEmailSuccess,
  };
}

const connectedChangeEmailForm = connect(mapStateToProps)(ChangeEmailForm);
export { connectedChangeEmailForm as ChangeEmailForm };
