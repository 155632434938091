import React from 'react';
import { LocationDescriptorObject } from 'history';
import { SuccessMessageContainer } from '../successMessage/SuccessMessageContainer';
interface IState {
  forgotPassword?: any;
}

interface IProps {
  location?: LocationDescriptorObject;
}

class ForgotPasswordSuccessMessageContainer extends React.Component<IProps, IState> {

  public render() {
    const email = this.props.location.state['email'] || '';
    const success = {
      title: 'Email Confirmation!',
      message: `We have sent a reset password email to <strong>${email}</strong>. Please click the reset password link to set your new password.`,
      action: '/login',
      actionLabel: 'Back to Log in',
    };
    return <SuccessMessageContainer success={success} />;
  }
}

export { ForgotPasswordSuccessMessageContainer };
