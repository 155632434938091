import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import _ from 'lodash';
import { v4 } from 'uuid';
import Pagination from 'react-js-pagination';
import { FilterComponent } from '../../filter/filterComponent';
import { eventAction } from '../redux/actions';
import OpenFieldTypes from 'OpenFieldTypes';
import { getUpdatedOptions, getSelectedValue, cancelEvent, staticConstants, MAGIC_NUMBER } from './../../../../utils';
import { pageRoutes } from './../../../../utils/routeConstants';
import { EventItem } from './eventItem';
import { ConfirmatioPopup } from '../../modals/confirmationPopup';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { tagAction } from '../../../shared/tags/redux/actions';
import { history } from '../../../../helpers';
import './../../../../assets/sass/theme/_calendar.scss';
interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  scheduledEvent: any;
  activeTab: string;
  role: string;
}

class Listing extends React.Component<IProps> {

  /**
   * @description getDerivedStateFromProps is called when there in change in property
   * @param fields {Object} props
   */
  public static getDerivedStateFromProps(props: IProps, state: any) {
    if (props.activeTab !== state.activeTab) {
      return {
        activeTab: props.activeTab,
        fetchEvents: true,
        activePage: 1,
        isPast: props.activeTab === staticConstants.EVENT_MANAGEMENT_TAB.PAST_EVENTS,
        showCalendar: props.activeTab === staticConstants.EVENT_MANAGEMENT_TAB.PAST_EVENTS ? false : state.showCalendar,
        eventType: '',
        domain: '',
      };
    }

    if (props.scheduledEvent && props.scheduledEvent.list) {
      const calendarEvents = [];
      _.map(props.scheduledEvent.list, (goal) => {
        calendarEvents.push({
          start: goal.bid[0]['proposedDate'],
          title: goal.title,
          id: goal.bid[0]._id,
        });
      });
      return {
        scheduledEvent: props.scheduledEvent,
        calendarEvents,
      };
    }
    return null;
  }

  public calendarComponentRef = React.createRef<FullCalendar>();
  public state = {
    interValId: MAGIC_NUMBER.ZERO,
    items: MAGIC_NUMBER.TEN,
    activePage: MAGIC_NUMBER.ONE,
    sort: '',
    sortBy: MAGIC_NUMBER.ZERO,
    scheduledEvent: {
      count: 0,
      list: [],
      fetchedEventListing: false,
      updateEventStatus: false,
    },
    domain: '',
    status: '',
    title: '',
    eventType: '',
    displayCancelPopup: false,
    modalPopupObj: {},
    goalId: '',
    bidId: '',
    reqObj: {},
    activeTab: this.props.activeTab,
    fetchEvents: false,
    isScheduleManagement: true,
    isPast: false,
    showCalendar: false,
    calendarWeekends: true,
    calendarEvents: [], // initial event data [{ title: 'Event Now', start: new Date() }],
    callUpdateEvent: false,
  };

  /**
   * @description componentDidMount is called when component is loaded
   * call eventAction.eventAction to fetch event listings
   */
  public componentDidMount() {
    this.fetchRfpData(1);
    const interValId = setInterval(() => {
      return this.updateEventStatus();
    },                             MAGIC_NUMBER.THREE_THOUSANT, this);
    this.setState({
      interValId: Number(interValId),
    });
    this.props.dispatch(tagAction.getDomains());
  }

  public componentWillUnmount() {
    clearInterval(this.state.interValId);
  }

  /**
   * @description componentDidUpdate is called when state gets updated
   * call eventAction.eventAction to fetch event listings
   */
  public componentDidUpdate() {
    if (this.state.fetchEvents) {
      this.fetchRfpData(1);
      this.setState({ fetchEvents: false });
    }
  }

  /**
   * @description fetchGoalsData is used on initial load
   */
  public fetchRfpData(pageno: number) {
    const { sort, sortBy, isScheduleManagement, isPast } = this.state;
    let reqObj = {
      page: pageno,
      limit: this.state.items,
      eventType: getSelectedValue(this.state.eventType),
      domain: getSelectedValue(this.state.domain),
      status: getSelectedValue(this.state.status),
      title: this.state.title,
      isScheduleManagement,
      isPast,
    };
    if (sort) {
      reqObj = { ...reqObj, ...{ sort, sortBy } };
    }
    this.props.dispatch(eventAction.getRequests(reqObj));
  }

  /**
   * @description
   * sortData is used for sorting purpose.
   * @param fields {Number} sort
   * @param fields {String} sortBy
   */
  public sortData(sort: any, sortBy: any) {
    const { activePage } = this.state;
    this.setState({ ...this.state, sort, sortBy },
                  () => this.fetchRfpData(activePage));
  }

  /**
   * @description
   * handleChange is used to set the value on state from the input.
   * @param fields {Object} e
   * @param fields {String} key
   */
  public handleChange(value: string, key: string) {
    this.setState({ ...this.state, [key]: value },
                  () => { this.fetchRfpData(1); });
  }

  /**
   * @description
   * handlePageChange is called someone click on the pagination.
   * @param fields {Number} pageno
   */
  public handlePageChange = (pageno: number) => {
    this.setState({
      activePage: pageno,
    },            () => this.fetchRfpData(pageno));
  }

  /**
   * @description
   * showCancelPopup is used show confirmation popup.
   * @param fields {String} id
   */
  public showCancelPopup = (id: string, bidId: string) => {
    const { sort, sortBy } = this.state;
    const modalPopupObj = cancelEvent;
    let reqObj = {
      page: Number(this.state.activePage),
      limit: this.state.items,
      eventType: getSelectedValue(this.state.eventType),
      domain: getSelectedValue(this.state.domain),
      status: getSelectedValue(this.state.status),
      title: this.state.title,
    };
    if (sort) {
      reqObj = { ...reqObj, ...{ sort, sortBy } };
    }
    this.setState({
      displayCancelPopup: true,
      goalId: id,
      bidId,
      reqObj,
      modalPopupObj,
    });
  }

  /**
   * @description
   * cancelEvent is called when someone click on the ok button
   */
  public cancelEvent = () => {
    const { goalId, bidId } = this.state;
    const reqObj = {
      goalId,
      bidId
    };
    this.props.dispatch(eventAction.cancelEvent(reqObj, () => {
      this.fetchRfpData(1);
    }));
    this.setState({
      displayCancelPopup: false,
      modalPopupObj: {},
      goalId: '',
      bidId: ''
    });
  }

  public handleDateClick = (info: any) => {
    history.push({
      pathname: `${pageRoutes.EVENTMANAGEMENT.PATH}${pageRoutes.EVENTMANAGEMENT.DETAIL}/${info.event.id}`,
      state: {
        activeTab: this.state.activeTab,
      },
    });
  }

  /**
   * @description
   * updateEventStatus is called to update the event status
   */
  public async updateEventStatus() {
    if (this.state && this.state.scheduledEvent) {
      const { scheduledEvent } = this.state;
      const systemDate = new Date(this.props.scheduledEvent.systemDate);
      for (const scheevent of scheduledEvent.list) {
        const fetchedTime = new Date(scheevent['bid'][0].proposedTime).getTime();
        const systemTime = systemDate.getTime();
        const timeDiff = ((fetchedTime - systemTime) / MAGIC_NUMBER.THOUSANT) / MAGIC_NUMBER.SIXTY;
        if (timeDiff > MAGIC_NUMBER.ZERO && timeDiff < MAGIC_NUMBER.TEN) {
          scheevent.isJoin = true;
        }
      }
      this.setState({ scheduledEvent });
    }
  }

  /**
   * @description renderRequestListing is called to render the request listings
   */
  public renderRequestListing = () => {

    const { list } = this.state.scheduledEvent;
    return _.map(list, (item) => {
      return (
        <EventItem key={v4()} request={item} onCancel={this.showCancelPopup} activeTab={this.state.activeTab} />
      );
    });
  }

  /**
   * @description
   * render is used to render the html.
   */
  public render() {
    const { role } = this.props;
    const filterContent = role === staticConstants.ROLE.PROFESSIONAL ? ['search'] : ['search', 'eventType', 'domain'];
    const { sort, sortBy, scheduledEvent, items, activePage,
      displayCancelPopup, modalPopupObj, activeTab, showCalendar } = this.state;
    let minDisplay;
    let maxDisplay;
    if (scheduledEvent.count === MAGIC_NUMBER.ZERO) {
      maxDisplay = MAGIC_NUMBER.ZERO;
      minDisplay = MAGIC_NUMBER.ZERO;
    } else if (scheduledEvent.count <= items) {
      maxDisplay = scheduledEvent.count;
      minDisplay = 1;
    } else {
      maxDisplay = (Number(activePage) * Number(items)) < scheduledEvent.count
        ? (Number(activePage) * Number(items)) : scheduledEvent.count;
      minDisplay = ((Number(activePage) - 1) * Number(items)) + 1;
    }
    return (
      <React.Fragment>
        <div className="filter-wrapper">
          <FilterComponent filterContent={filterContent} handleFilter={this.handleChange.bind(this)} activeTab={activeTab}/>
          {activeTab === staticConstants.EVENT_MANAGEMENT_TAB.UPCOMING_EVENTS &&
            <div className={`table-btn-group ${role === staticConstants.ROLE.PROFESSIONAL ? 'ml-auto' : ''}`}>
              <Button type="button"
                className={`btn btn-icon h-40 shadow-none br-1 ${!showCalendar ? 'info' : ''}`}
                onClick={() => this.setState({ showCalendar: false })}>List</Button>
              <Button type="button"
                className={`btn btn-icon h-40 shadow-none ${showCalendar ? 'info' : ''}`}
                onClick={() => this.setState({ showCalendar: true })}>Calendar</Button>
            </div>
          }
        </div>
        {showCalendar &&
          <div className="event-calendar">
            <FullCalendar
              defaultView="dayGridMonth"
              header={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
              }}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              ref={this.calendarComponentRef}
              weekends={this.state.calendarWeekends}
              events={this.state.calendarEvents}
              eventClick={this.handleDateClick}
            />
          </div>
        }
        {!showCalendar &&
          <div className="table-wrapper">
            <div className="flex-table">
            <div className={`table-head ${role === staticConstants.ROLE.ORGANIZATION_ADMIN || role === staticConstants.ROLE.PROFESSIONAL ? '' : 'upcoming-table-format'}`} >
                <div className="table-row">
                  <div className="table-col table-col-17">
                    <span className="sorting-icon">Title
                      <strong>
                        <em className={(sort === 'title' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                          onClick={() => { this.sortData('title', MAGIC_NUMBER.ONE); }} />
                        <em className={(sort === 'title' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                          onClick={() => { this.sortData('title', MAGIC_NUMBER.FOUND_INDEX); }} />
                      </strong>
                    </span>
                  </div>
                  <div className="table-col table-col-10">
                    <span className="sorting-icon">Event Type
                      <strong>
                        <em className={(sort === 'eventType' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                          onClick={() => { this.sortData('eventType', MAGIC_NUMBER.ONE); }} />
                        <em className={(sort === 'eventType' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                          onClick={() => { this.sortData('eventType', MAGIC_NUMBER.FOUND_INDEX); }} />
                      </strong>
                    </span>
                  </div>
                  <div className="table-col table-col-15">
                    <span className="sorting-icon">Domain
                      <strong>
                        <em className={(sort === 'domain' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                          onClick={() => { this.sortData('domain', MAGIC_NUMBER.ONE); }} />
                        <em className={(sort === 'domain' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                          onClick={() => { this.sortData('domain', MAGIC_NUMBER.FOUND_INDEX); }} />
                      </strong>
                    </span>
                  </div>
                  <div className="table-col table-col-13">
                    <span className="sorting-icon">Sub-Domain
                      <strong>
                        <em className={(sort === 'subDomain' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                          onClick={() => { this.sortData('subDomain', MAGIC_NUMBER.ONE); }} />
                        <em className={(sort === 'subDomain' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                          onClick={() => { this.sortData('subDomain', MAGIC_NUMBER.FOUND_INDEX); }} />
                      </strong>
                    </span>
                  </div>
                  <div className="table-col table-col-13">
                    <span className="sorting-icon justify-content-center">Participants
                      <strong>
                        <em className={(sort === 'totalParticipants' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                          onClick={() => { this.sortData('totalParticipants', MAGIC_NUMBER.ONE); }} />
                        <em className={(sort === 'totalParticipants' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                          onClick={() => { this.sortData('totalParticipants', MAGIC_NUMBER.FOUND_INDEX); }} />
                      </strong>
                    </span>
                  </div>
                  {activeTab === staticConstants.EVENT_MANAGEMENT_TAB.UPCOMING_EVENTS &&
                    <React.Fragment>
                      <div className="table-col table-col-12">
                        <span className="sorting-icon">Date
                         <strong>
                            <em className={(sort === 'scheduleAt' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                              onClick={() => { this.sortData('scheduleAt', MAGIC_NUMBER.ONE); }} />
                            <em className={(sort === 'scheduleAt' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                              onClick={() => { this.sortData('scheduleAt', MAGIC_NUMBER.FOUND_INDEX); }} />
                          </strong>
                        </span>
                      </div>
                      <div className="table-col table-col-10">
                        <span className="sorting-icon">Time
                      </span>
                      </div>
                      {role === staticConstants.ROLE.ORGANIZATION_ADMIN || role === staticConstants.ROLE.PROFESSIONAL ? <div className="table-col table-col-10 text-center">
                        <span>Action</span>
                      </div> : ''}
                    </React.Fragment>
                  }
                  {activeTab === staticConstants.EVENT_MANAGEMENT_TAB.PAST_EVENTS &&
                    <React.Fragment>
                      <div className="table-col table-col-12">
                        <span className="sorting-icon">Completed
                       <strong>
                            <em className={(sort === 'eventCompleted' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                              onClick={() => { this.sortData('eventCompleted', MAGIC_NUMBER.ONE); }} />
                            <em className={(sort === 'eventCompleted' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                              onClick={() => { this.sortData('eventCompleted', MAGIC_NUMBER.FOUND_INDEX); }} />
                          </strong>
                        </span>
                      </div>
                      <div className="table-col table-col-10">
                        <span className="sorting-icon">Time
                      </span>
                      </div>
                      <div className="table-col table-col-10 text-center">
                        <span>Session Fee</span>
                        <strong>
                          <em className={(sort === 'sessionFee' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                            onClick={() => { this.sortData('sessionFee', MAGIC_NUMBER.ONE); }} />
                          <em className={(sort === 'sessionFee' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                            onClick={() => { this.sortData('sessionFee', MAGIC_NUMBER.FOUND_INDEX); }} />
                        </strong>
                      </div>
                    </React.Fragment>
                  }
                </div>
              </div>
              <div className="table-body">
                {this.renderRequestListing()}
                {scheduledEvent && scheduledEvent.fetchedEventListing && scheduledEvent.list.length === MAGIC_NUMBER.ZERO ?
                  <div className="table-row table-no-data">
                    No Results Found.
                    </div>
                  : ''}
              </div>
              {(scheduledEvent && scheduledEvent.count > MAGIC_NUMBER.TEN) &&
                <div className="table-footer">
                    <React.Fragment>
                      <p>Showing {minDisplay} - {maxDisplay} of {scheduledEvent.count}</p>
                      <div className="pagination-wrapper">
                        <Pagination
                          hideFirstLastPages
                          activePage={activePage}
                          itemsCountPerPage={items}
                          totalItemsCount={scheduledEvent.count}
                          pageRangeDisplayed={MAGIC_NUMBER.FIVE}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    </React.Fragment>
                </div>
              }
            </div>
          </div>
        }
        {displayCancelPopup ?
          <ConfirmatioPopup isOpenModal={displayCancelPopup} modalPopupObj={modalPopupObj}
            hideModal={() => this.setState({ displayCancelPopup: false })} onClickYes={this.cancelEvent} />
          : ''}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  const { tags: { domains } } = state;
  return {
    scheduledEvent: state.scheduledEvent,
    domainOptions: getUpdatedOptions(domains, 'name'),
  };
}

const connectedRequestListing = connect(mapStateToProps)(Listing);
export { connectedRequestListing as Listing };
