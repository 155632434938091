import { actionTypes } from './types';

interface IAction {
  type: string;
  data: {};
  error: string;
}
const initialState = {
  status: {},
  expertIncome: {
    totalPending: 0,
    totalReceieved: 0,
  },
};

export function expertPayments(state = initialState, action: IAction) {
  switch (action.type) {
    case actionTypes.EXPERT_FEEDBACK_SUCCESS:
      return {
        ...state,
        requestedSendFeedback: false,
      };
    case actionTypes.EXPERT_FEEDBACK_FAILURE:
      return {
        ...state,
        requestedSendFeedback: false,
        error: action.error,
      };
    case actionTypes.CHANGE_RATE_PER_HOUR_SUCCESS:
      return {
        ...state,
        successChangeRateRequest: true,
      };
    case actionTypes.FETCH_TOTAL_PAYMENTS_SUCCESS:
      return {
        ...state,
        eventDetails: action.data,
        status: { successChangeRateRequest: true },
      };
    case actionTypes.FETCH_TOTAL_PAYMENTS_FAILURE:
      return { successTotalPaymentFetch: false };
    case actionTypes.GET_PAYMENT_LOGS_REQUEST:
    case actionTypes.GET_WITHDRAW_HISTORY_REQUEST:
      return {
        ...state,
        reuestedPaymentLogs: true,
      };
    case actionTypes.GET_PAYMENT_LOGS_SUCCESS:
    case actionTypes.GET_WITHDRAW_HISTORY_SUCCESS:
      return {
        ...state,
        reuestedPaymentLogs: false,
        paymentLogs: action.data,
      };
    case actionTypes.GET_PAYMENT_LOGS_FAILURE:
    case actionTypes.GET_WITHDRAW_HISTORY_FAILURE:
      return {
        ...state,
        reuestedPaymentLogs: false,
        error: action.error,
      };
    case actionTypes.GET_EXPERT_INCOME_REQUEST:
      return {
        ...state,
        requestedExpertIncome: true,
      };
    case actionTypes.GET_EXPERT_INCOME_SUCCESS:
      return {
        ...state,
        requestedExpertIncome: false,
        expertIncome: action.data,
      };
    case actionTypes.GET_EXPERT_INCOME_FAILURE:
      return {
        ...state,
        requestedExpertIncome: false,
        error: action.error,
      };
    default:
      return state;
  }
}
