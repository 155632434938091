import React from 'react';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import { connect } from 'react-redux';
import _ from 'lodash';
import profileImage from './../../../assets/images/profile.png';

interface IProps {
  isOpenModal: boolean;
  goalDetail: any;
  hideModal(): void;
  onClickYes(arg0: object): void;
}

interface IState {
  modal: boolean;
  addedParticipantsListInModal: any;
  renderParticipantsList: any;
  participantsListInModal: [];
}

class GoalAdminPopup extends React.Component<IProps, IState> {
  public selectedParticipantForAdmin: string;
  public selectedParticipants: string[] = [];
  public participantsList: string[] = [];
  constructor(props: IProps) {
    super(props);
    this.state = {
      modal: props.isOpenModal,
      addedParticipantsListInModal: [],
      renderParticipantsList: [],
      participantsListInModal: [],
    };
  }
     /**
      * @description componentWillReceiveProps is called when prop is changed
      * @param {Object} props
      * @param {Object} nextProps
      */
  public componentWillReceiveProps(props: IProps, nextProps: any) {

    if (props.goalDetail) {
      const { participants, participantsRaw  } = props.goalDetail.bid && props.goalDetail.bid[0] ||  props.goalDetail;
      const renderParticipantsList = [];
      _.map(participants, (user: any) => {
        const isAdmin = this.isAdmin(participantsRaw, user['_id']);
        user['isAdmin'] = isAdmin;
        if (isAdmin) {
          this.selectedParticipants.push(user['_id']);
          this.selectedParticipantForAdmin  =  user['_id'];
        }
        renderParticipantsList.push(user);
      });

      this.setState({
        renderParticipantsList,
        modal: props.isOpenModal,
        addedParticipantsListInModal: renderParticipantsList,
      });
    }
  }

  /**
   * @description
   * isAdmin is used to check participant is added as
   */
  public isAdmin(participants: any, participantId: any) {
    let isAdmin = false;
    for (const participant of participants) {
      if (participantId === participant['userId'] && participant.isAdmin) {
        isAdmin = true;
        break;
      }
    }
    return isAdmin;
  }

  public hideModal = () => {
    this.setState({
      modal: false,
      renderParticipantsList:[],
      addedParticipantsListInModal:[],
      participantsListInModal: [],
    },            () => { this.props.hideModal(); });
    this.participantsList = [];
    this.selectedParticipants = [];
    this.selectedParticipantForAdmin = null;
    this.props.hideModal();
  }
    /**
     * @description
     * handleAdmin is used to set the value on state
     * @param fields {Object} event
     */
  public handleAdmin = (event: any) => {
    const { value, checked } = event.target;
    if (checked) {
      this.selectedParticipantForAdmin = value;
    } else {
      this.selectedParticipantForAdmin = null;
    }
  }

  /**
   * @description
   * searchParticipant is used to search Participant.
   * @param fields {Object} event
   */
  public searchParticipant = (event: any, isSearchForAdmin: boolean) => {
    const { value } = event.target;
    const regX = new RegExp(value, 'i');
    let list: any;
    if (isSearchForAdmin) {
      list = this.state.renderParticipantsList;
    }
    if (value) {
      const filterParticipants = list.filter((participant: any) => {
        return participant.firstName.match(regX);
      });
      if (isSearchForAdmin) {
        this.setState({
          addedParticipantsListInModal: filterParticipants,
        });
      } else {
        this.setState({
          participantsListInModal: filterParticipants,
        });
      }
    } else {
      if (isSearchForAdmin) {
        this.setState({
          addedParticipantsListInModal: list,
        });
      }
    }
  }

  /**
   * @description
   * removeAdmin is used to add remove admin from the list
   */
  public removeAdmin = () => {
    const list = this.state.renderParticipantsList;
    const updatedList = [];
    for (const listItem of list) {
      const l = listItem;
      delete l.isAdmin;
      updatedList.push(l);
    }
    this.setState({
      renderParticipantsList: updatedList,
    });
  }

  public addAdmin = () => {
    this.removeAdmin();
    const list = this.state.renderParticipantsList;
    const updatedList = list.map(li => {
      const el = { userId: li._id, organizationId: li.organizationId };
      if (li._id === this.selectedParticipantForAdmin) {
        return { ...el, isAdmin: true } ;
      }
      return el;
    });

    const isMe = updatedList.some((pa: {isAdmin: boolean; organizationId: string}) => {
      return pa.isAdmin && !pa.organizationId;
    });
    const participants = updatedList.map(li => ({ userId: li.userId, isAdmin: li.isAdmin }));
    this.props.onClickYes({ participants, isMe });
    this.hideModal();
  }

    /**
     * @description
     * renderAddedParticipantsListInModal is used for render the Added ParticipantsList in modal
     */
  public renderAddedParticipantsListInModal() {

    const data = this.state.addedParticipantsListInModal;
    return _.map(data, (item: any, index: any) => {
      const checked = item.isAdmin;
      const fetchedProfileImage = item.profileImage !== '' ? item.profileImage : profileImage;
      return (
                <div className="list-item" key={index}>
                    <span className="profile-image">
                        <img src={fetchedProfileImage} alt="" className="img-cover" />
                    </span>
                    <div className="profile-detail">
                        <span>{item.firstName} {item.lastName}</span>
                        <em>{item.email}</em>
                    </div>
                    <label className="control control--radio">
                        <input type="radio" value={item._id} defaultChecked={checked}
                            onChange={this.handleAdmin} name="participants" />
                        <div className="control__indicator"/>
                    </label>
                </div>
      );
    });
  }
  public render() {
    const { modal } = this.state;
    return (
            <Modal isOpen={modal} className="add-participaint">
                <ModalHeader toggle={this.hideModal}>Select Event Lead</ModalHeader>
                <ModalBody>
                    <div className="search-bar">
                        <input type="search" name="search" placeholder="Search by Name"
                            onChange={(event: any) => this.searchParticipant(event, true)} autoComplete="off" />
                        <span className="icon icon-search-icon" />
                    </div>
                    <div className="participaint-list">
                        {this.renderAddedParticipantsListInModal()}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.hideModal}>Cancel</Button>
                    <Button color="primary" onClick={this.addAdmin}>Choose</Button>
                </ModalFooter>
            </Modal>
    );
  }
}

const connectedGoalAdminPopupPage = connect()(GoalAdminPopup);
export { connectedGoalAdminPopupPage as GoalAdminPopup };
