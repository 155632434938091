import React , { Dispatch } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Badge,
  Button,
  Form,
  FormGroup,
  Input, Label, Media,
} from 'reactstrap';
import { IResetPasswordReq, IValidator } from './../../../interfaces/';
import { resetPasswordAction } from './redux/actions';

import ReeValidate from 'ree-validate';
import logoImage from './../../../assets/images/logo.png';
import { validator } from './../../../helpers';
import OpenFieldTypes from 'OpenFieldTypes';

validator(ReeValidate);

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  match?: any;
  createPassword: any;
}

interface IState {
  passwordFormData: {
    myPassword: string;
    myConfirmPassword: string;
  };
  errors: any;
  token: string;
  verifiedToken?: any;
  createPassword?: any;
  verifyingToken?: boolean;
}

class ResetPasswordForm extends React.Component<IProps, IState> {
  public validator: IValidator;
  public myPassword: any;
  constructor(props: IProps) {
    super(props);
    this.validator = new ReeValidate({
      myPassword: 'required|verify_password',
      myConfirmPassword: 'required',
    });
    this.state = {
      passwordFormData: {
        myPassword: '',
        myConfirmPassword: '',
      },
      errors: this.validator.errors,
      token: '',
    };
  }

  public componentWillMount = () => {
    const { token } = this.props.match.params;
    const { dispatch } = this.props;
    dispatch(resetPasswordAction.verifyToken(token));
  }

  public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const { errors } = this.validator;
    this.setState({ passwordFormData: { ...this.state.passwordFormData, [name]: value } });
    errors.remove(name);

    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors });
      });
  }
  public validateAndSubmit = (e) => {
    e.preventDefault();
    const { myPassword, myConfirmPassword } = this.state.passwordFormData;
    const { errors } = this.validator;

    this.validator.validateAll({ myPassword, myConfirmPassword })
      .then((success: boolean) => {
        if (success) {
          this.handleSubmit();
        } else {
          this.setState({ errors });
        }
      });

  }

  public handleSubmit = () => {
    const { passwordFormData } = this.state;
    const { dispatch, createPassword, match } = this.props;
    const obj: IResetPasswordReq = {
      email: createPassword.token['email'],
      password: passwordFormData['myPassword'],
      token: match.params['token']};
    dispatch(resetPasswordAction.resetPassword(obj));
  }

  public render() {
    const { passwordFormData, errors } = this.state;

    if (this.props.createPassword && this.props.createPassword.isValidToken) {
      return(
      <div className="container-fluid">
        <div className="row min-vh-100">
          <div className="col-lg-6 col-md-12 login_bg">
            <div className="login_banner_text">
              <p>Everyone is going to need to understand [climate change] the same way you’d assume everyone in business needs to have some fluency in social media today.</p>
              <h5>Andrew Winston</h5>
              <p className="text-right">Author</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="login_form">
              <div className="row">
                <div className="col-12">
                  <div className="login_details">
                    <Link to="/"> <img src={logoImage} alt="Logo" className="img-fluid mb-4" /></Link>
                    <h2 className="common_heading mt-2">Reset Password</h2>
                    <p className="mb-4">Enter a new password</p>
                    <Form onSubmit={this.validateAndSubmit} noValidate>
                    <div className="text-truncate">
                          <Badge color="primary" className="my-3">{this.props.createPassword.token.email}</Badge>
                    </div>
                    <FormGroup
                        className={`floating-label ${
                          errors.has('myPassword') ? ' has-error' : ''
                          }`}
                      >

                        <Input
                          type="password"
                          className="form-control"
                          name="myPassword"
                          id="myPassword"
                          value={passwordFormData.myPassword}
                          onChange={this.handleChange}
                          placeholder="Password"
                          ref={(myPassword) => this.myPassword = myPassword}
                        />
                        <Label for="firstName">Password</Label>
                        {errors.has('myPassword') &&
                          <div className="error-text">{errors.first('myPassword').replace('myPassword', 'password')}</div>
                        }
                      </FormGroup>
                      <FormGroup
                        className={`floating-label ${
                          errors.has('confirmPassword') ? ' has-error' : ''
                          }`}
                      >
                        <Input
                          type="password"
                          className="form-control"
                          name="myConfirmPassword"
                          id="myConfirmPassword"
                          value={passwordFormData.myConfirmPassword}
                          onChange={this.handleChange}
                          placeholder="Confirm Password"
                        />
                        <Label for="myConfirmPassword">Confirm Password</Label>
                        {errors.has('myConfirmPassword') &&
                          <div className="error-text">{errors.first('myConfirmPassword').replace('myConfirmPassword', 'confirm password')}</div>
                        }
                      </FormGroup>
                      <div className="login_button mt-3">
                        <Button color="get_start_btn" className="get_start_btn cta_btn d-block text-center w-100">
                          Submit
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      );
    }
    return(
    <div className="container-fluid">
        <div className="row min-vh-100">
          <div className="col-lg-6 col-md-12 login_bg">
            <div className="login_banner_text">
              <p>Everyone is going to need to understand [climate change] the same way you’d assume everyone in business needs to have some fluency in social media today.</p>
              <h5>Andrew Winston</h5>
              <p className="text-right">Author</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="login_form">
              <div className="row">
                <div className="col-12">
                  <div className="login_details">
                    <Link to="/"> <img src={logoImage} alt="Logo" className="img-fluid mb-4" /></Link>
                    <div className="form-group-wrapper">
                      { !this.props.createPassword.verifyingToken ?
                      (
                        <Media>
                        <Media top className="icon"/>
                        <Media body>
                          <h2 className="common_heading mt-2">Error!</h2>
                          <p className="mb-4">Invalid Token</p>
                          <Button color="get_start_btn" className="get_start_btn cta_btn d-block text-center w-100">Done</Button>
                        </Media></Media>) :
                      '' }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     );
  }
}

function mapStateToProps(state: any) {
  const {  createPassword } = state;
  return {
    createPassword,
  };
}

const connectedResetPasswordForm = connect(mapStateToProps)(ResetPasswordForm);
export { connectedResetPasswordForm as ResetPasswordForm };
