import _ from 'lodash';
import OpenFieldTypes from 'OpenFieldTypes';
import queryString from 'query-string';
import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import Select, { createFilter } from 'react-select';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Modal,
  ModalBody,
  ModalHeader
} from 'reactstrap';
import ReeValidate from 'ree-validate';
import {
  allAvailableDays, alllanguageProficiencies, availableDays, educations,
  getUpdatedOptions, languageProficiencies, languages, reactSelectFilters,
  selectAllCheckboxes, staticConstants, unSelectAllCheckboxes, MAGIC_NUMBER
} from '../../../utils/';
import { customSelectStyles, mapEducationFromAPIRequest, mapTagsFromAPIRequest } from '../../../utils/common';
import { SelectTags } from '../../shared/selectTags/selectTags';
import { validator } from './../../../helpers';
import Delete from './../../../assets/images/delete_icon.png';
validator(ReeValidate);

interface IProps {
  education?: any;
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  successValidateSubmit: any;
  tagsSuggestions: any;
  profile?: any;
  props?: any;
  tagList?: any;
}

interface IState {
  education?: any;
  selectedEducation: any;
  isSubmitted: boolean;
  isAddedLanguageSubmitted: boolean;
  isErrorOnProfeciency: boolean;
  isErrorOnAlreadyAddedLanguage: boolean;
  isErrorOnAvailability: boolean;
  errors?: any;
  isDisAbledAutoCompleted: boolean;
  openLanguageModal: boolean;
  savedLangulages: any;
}

class EducationForm extends React.Component<IProps, IState> {

  public selectedProfeciencies: string[] = [];
  public selectedAvailabilities: string[] = [];
  public validator: any;
  public ref: any;

  constructor(props: IProps) {
    super(props);
    this.ref = React.createRef();
    this.validator = new ReeValidate({
      tags: 'required',
    });
    this.state = {
      selectedEducation: null,
      education: {
        tags: [],
        modeOfDelivery: 'both',
        languages: [],
      },
      isSubmitted: false,
      isAddedLanguageSubmitted: false,
      isErrorOnProfeciency: false,
      isErrorOnAlreadyAddedLanguage: false,
      isErrorOnAvailability: false,
      errors: this.validator.errors,
      isDisAbledAutoCompleted: false,
      openLanguageModal: false,
      savedLangulages: [{ label: '', value: '', profeciencies: [], errors: [] }]
    };
  }

  public openLanguageModal = () => {
    const { education } = this.state;
    if (education.languages.length > 0) {
      const popupValue = [];
      _.forEach(education.languages, (item) => {
        popupValue.push({ label: item.language, value: item.language, errors: [], profeciencies: item.profeciencies });
      });
      this.setState({
        savedLangulages: popupValue,
        openLanguageModal: true,
      });
    } else {
      this.setState({
        openLanguageModal: true,
      });
    }
  }
  public hideLanguageModal = () => {
    this.setState({
      openLanguageModal: false,
      savedLangulages: [{ label: '', value: '', profeciencies: [], errors: [] }]
    });
  }

  public componentDidUpdate(prevProps) {
    const { profile } = this.props;
    if (!_.isEqual(prevProps.profile, profile) && profile) {
      profile.tags && profile.tags.map((tag) => delete tag.__typename);
      profile.languages && profile.languages.map((language) => delete language.__typename);
      const { education } = this.state;
      education.tags = mapTagsFromAPIRequest(profile.tags) || [];
      education.languages = profile.languages || [];
      education.modeOfDelivery = profile.modeOfDelivery;
      this.selectedAvailabilities = profile.availableDays || [];
      this.handleAvailabilitiesOnEditProfile();

      this.setState({
        education,
        selectedEducation: mapEducationFromAPIRequest(profile.education),
      });
    }
  }

  public handleAvailabilitiesOnEditProfile = () => {
    if (this.selectedAvailabilities.length === availableDays.length) {
      selectAllCheckboxes('allAvailableDay');
    }
  }

  /**
   * @description
   * handleChange is used to set the value on state from the only input/textarea boxes.
   * @param fields {Object} event
   */
  public handleChange = (event: any) => {
    const { name, value } = event.target;
    this.setState({ education: { ...this.state.education, [name]: value } });
  }

  /**
   * @description
   * handleEducation is used to set the value on state from education select box
   * @param fields {Object} education
   */
  public handleEducation = (education: any) => {
    this.setState({ selectedEducation: education });
  }

  public handleTempSelection = (value: any, meta: any) => {
    const { savedLangulages } = this.state;
    const indexNo = meta.name.split('-');
    const { profeciencies } = savedLangulages[indexNo[0]];
    savedLangulages[indexNo[0]] = { ...value, profeciencies, errors: [] };
    this.setState({ savedLangulages }, () => {
      this.validateLanguagePopup();
    });
  }


  public addNewLanguage = () => {
    const newLang = { label: '', value: '', profeciencies: [], errors: [] };
    const { savedLangulages } = this.state;
    savedLangulages.push(newLang);
    this.setState({ savedLangulages });
  }


  public saveLanguage = () => {
    const invalidValue = this.validateLanguagePopup();
    const { savedLangulages, education } = this.state;
    if (!invalidValue) {
      const addedLanguages = [];
      _.forEach(savedLangulages, (item) => {
        addedLanguages.push({
          language: item.value,
          profeciencies: item.profeciencies,
        });
      });
      education.languages = addedLanguages;
      this.setState({
        education,
        isAddedLanguageSubmitted: true,
      }, () => {
        this.hideLanguageModal();
      });
    }
  }

  public validateLanguagePopup = () => {
    const { savedLangulages } = this.state;
    let errorFound = false;
    _.forEach(savedLangulages, (item, index) => {
      item.errors = [];

      if (!item.value) {
        item.errors.push(`Please select language.`);
      } else if (!item.profeciencies.length) {
        item.errors.push(`Please select profeciencies.`);
      } else {
        const isDuplicate = savedLangulages.filter((lang, tempIndex) => (tempIndex !== index && lang.value === item.value));
        if (isDuplicate.length > 0) {
          item.errors.push(`Selected language already selected.`);
        }
      }
      if (item.errors.length) {
        errorFound = true;
      }
      savedLangulages[index] = item;
    });
    this.setState({ savedLangulages });
    return errorFound;
  }

  /**
   * @description
   * unselectProfeciencies is used to clear the checkboxed of Profeciencies after adding
   * the language.
   */
  public unselectProfeciencies() {
    this.selectedProfeciencies = [];
    unSelectAllCheckboxes('profeciency');
    unSelectAllCheckboxes('allProfeciency');
  }

  /**
   * @description handleLanguageProfeciency is used when someone check/uncheck the Profeciency
   * @param fields {Object} event
   */
  public handleLanguageProfeciency = (event: any, selectTagName: any) => {
    const { savedLangulages } = this.state;
    const { name, value } = event.target;
    const indexNo = name.split('-');
    const selLang = savedLangulages[indexNo[0]];
    if (value === 'all') {
      if ((selLang.profeciencies.toString()).indexOf(['read', 'write', 'speak'].toString()) > -1) {
        selLang.profeciencies = [];
      } else {
        selLang.profeciencies = ['read', 'write', 'speak'];
      }
    } else {
      const indexValue = selLang.profeciencies.indexOf(value);
      if (indexValue !== -1) {
        selLang.profeciencies.splice(indexValue, 1);
      } else {
        selLang.profeciencies.push(value);
      }
    }
    savedLangulages[indexNo[0]] = selLang;
    this.setState({ savedLangulages }, () => {
      this.validateLanguagePopup();
    });
  }

  /**
   * @description handleAvailability is used when someone check/uncheck the Days of availablity
   * @param fields {Object} event
   */
  public handleAvailability = (event: any, selectTagName: any) => {
    const { value, checked } = event.target;
    if (!checked) {
      unSelectAllCheckboxes(selectTagName);
    }
    this.setState({
      isErrorOnAvailability: false,
    });
    if (!checked) {
      const findIndex = this.selectedAvailabilities.findIndex((val) => val === value);
      this.selectedAvailabilities.splice(findIndex, 1);
    } else {
      this.selectedAvailabilities.push(value);
    }
    if (this.selectedAvailabilities.length === availableDays.length) {
      selectAllCheckboxes(selectTagName);
    }
  }

  /**
   * @description selectAllLanguageProfeciency is used when someone check/uncheck on all
   * @param fields {Object} event
   * @param fields {selectTagName} string
   * @param fields {selectChildTagName} child checkbox name
   */
  public selectAllLanguageProfeciency(event: any, selectTagName: any, selectChildTagName: any) {
    const { checked } = event.target;
    if (checked) {
      selectAllCheckboxes(selectChildTagName);
      const result = _.map(languageProficiencies, (item: any) => {
        return item.value;
      });
      this.selectedProfeciencies = result;
      this.setState({
        isErrorOnProfeciency: false,
      });
    } else {
      unSelectAllCheckboxes(selectChildTagName);
    }
  }

  /**
   * @description
   * renderLanguageProfeciency is used to render the proficieny checkbox
   */
  public renderLanguageProfeciency() {
    const { savedLangulages } = this.state;
    return _.map(savedLangulages, (lan: any, lanIndex: any) => {
      const languageProficienciesWithAll = alllanguageProficiencies.concat(languageProficiencies);
      return (<tr>{_.map(languageProficienciesWithAll, (item: any, index: any) => {
        if (item.value === 'langulage') {
          return (
            <td key={`langulage-${index}`}>
              <FormGroup
                className="floating-label disabled-input mb-0"
              >
                <Select
                  name={`${lanIndex}-language`}
                  value={lan.value ? lan : null}
                  styles={customSelectStyles}
                  onChange={(val, meta) => this.handleTempSelection(val, meta)}
                  options={languages}
                  id={`language-${index}-${lanIndex}`}
                  placeholder="Language"
                  className="react-select-box select-box-group"
                  filterOption={createFilter(reactSelectFilters)}
                />
                <Label for={`language-${index}-${lanIndex}`} className={lan.value ? 'selected' : ''} >Language</Label>
                {lan.errors.length > 0 &&
                  <div className="error-text">{lan.errors[0]}</div>
                }
              </FormGroup>
            </td>
          );
        }
        if (item.value === 'all') {
          return (
            <td key={`profeciency-${lanIndex}-${index}`}>
              <label className="custom_checkbox mt-2">
                <Input
                  type="checkbox"
                  value={item.value}
                  onChange={(e) => this.handleLanguageProfeciency(e, 'allProfeciency')}
                  name={`${lanIndex}-${index}-allProfeciency`}
                  className="chekbox_input"
                  checked={lan.profeciencies.includes('read') && lan.profeciencies.includes('write') && lan.profeciencies.includes('speak')}
                />
                <span />
              </label>
            </td>
          );
        }
        return (
          <td key={`profeciency-${lanIndex}-${index}`}>
            <label className="custom_checkbox mt-2">
              <Input
                type="checkbox"
                value={item.value}
                onChange={(e) => this.handleLanguageProfeciency(e, 'allProfeciency')}
                name={`${lanIndex}-${index}-profeciency`}
                className="chekbox_input"
                checked={lan.profeciencies.includes(item.value)}
              />
              <span />
            </label>
          </td>
        );

      })}<td><div className="delete_icon mt-1">
        <img src={Delete} alt="Delete" onClick={() => this.removeTempLanguage(lanIndex)} />
      </div></td></tr>);
    });
  }


  /**
   * @description
   * selectAllAvailability is used when someone check/uncheck on all
   * @param fields {Object} event
   * @param fields {selectTagName} string
   * @param fields {selectChildTagName} child checkbox name
   */
  public selectAllAvailability(event: any, selectTagName: any, selectChildTagName: any) {
    const { checked } = event.target;
    if (checked) {
      selectAllCheckboxes(selectChildTagName);
      const result = _.map(availableDays, (item: any) => {
        return item.value;
      });
      this.selectedAvailabilities = result;
      this.setState({
        isErrorOnAvailability: false,
      });
    } else {
      unSelectAllCheckboxes(selectChildTagName);
      this.selectedAvailabilities = [];
    }
  }

  /**
   * @description
   * renderAvailability is used to render the days of availbility checkbox
   */
  public renderAvailability() {

    const availableDaysWithAll = allAvailableDays.concat(availableDays);
    return _.map(availableDaysWithAll, (item: any, index: any) => {
      if (item.value === 'allDays') {
        return (
          <div className="day-column" key={`availableDay-${index}`}>
            <label className="control control--checkbox">
              <input
                type="checkbox"
                value={item.value}
                onChange={(e) => this.selectAllAvailability(e, 'allAvailableDay', 'availableDay')}
                name="allAvailableDay"
              />
              {item.label}
              <div className="control__indicator" />
            </label>
          </div>
        );
      }
      return (
        <div className="day-column" key={`availableDay-${index}`}>
          <label className="control control--checkbox">
            <input
              type="checkbox"
              value={item.value}
              checked={this.selectedAvailabilities.includes(item.value)}
              onChange={(e) => this.handleAvailability(e, 'allAvailableDay')}
              name="availableDay"
            />
            {item.label}
            <div className="control__indicator" />
          </label>
        </div>
      );

    });
  }

  /**
   * @description
   * renderAddedLanguages is used to render the added langauage with Profeciency
   * call renderAddedProfeciency to show the profeciency
   */
  public renderAddedLanguages() {

    return _.map(this.state.education.languages, (item: any, index: any) => {
      return (
        <tr className="language-data" key={`addedLanguage-${index}`}>
          <td><span>{item.language}</span></td>
          {this.renderAddedProfeciencyCheck(item.profeciencies)}
          <td><div className="delete_icon">
            <img src={Delete} alt="Delete" onClick={() => this.removeAddedLanguage(index)} />
          </div>
          </td>
        </tr>
      );
    });
  }

  public removeAddedLanguage(langauageIndex: any) {
    const education = this.state.education;
    const selectedLanguages = education.languages;
    selectedLanguages.splice(langauageIndex, MAGIC_NUMBER.ONE);
    education.languages = selectedLanguages;
    const isAdded = selectedLanguages.length > MAGIC_NUMBER.ZERO;
    this.setState({ education, isAddedLanguageSubmitted: isAdded });
  }

  public removeTempLanguage(langauageIndex: any) {
    const { savedLangulages } = this.state;
    if (savedLangulages.length === MAGIC_NUMBER.ONE) return;
    savedLangulages.splice(langauageIndex, MAGIC_NUMBER.ONE);
    this.setState({ savedLangulages });
  }

  /**
   * @description
   * renderAddedProfeciency is used to show the profeciency
   */
  public renderAddedProfeciencyCheck(selectedProfeciencies: any) {
    const languageProficienciesWithAll = alllanguageProficiencies.concat(languageProficiencies);
    return _.map(languageProficienciesWithAll, (item: any, index: any) => {
      let checked = false;
      if (item.value !== 'langulage') {
        if (selectedProfeciencies.includes(item.value) || selectedProfeciencies.length === MAGIC_NUMBER.THREE) {
          checked = true;
        }

        return (
          <td key={`addedProfeciencyCheck-${index}`}>
            <div className="custom_checkbox">
              <Input type="checkbox" value={item.value} className="chekbox_input" checked={checked} disabled={true} />
              <span></span>
            </div>
          </td>
        );
      }
    });
  }

  public removeErrorAndReValidate = (name: string) => {
    const { errors } = this.validator;
    errors.remove(name);
    this.validator.validate(name, this.state.education[name])
      .then(() => {
        this.setState({ errors });
      });
  }

  public handleTagAdd = (tag: { value: string; label: string }) => {
    const ind = this.state.education.tags.findIndex((t: { [x: string]: string }) => t['value'] === tag['value']);
    const tags = [].concat(this.state.education.tags, tag);
    this.setState({ education: { ...this.state.education, tags } }, () => {
      this.removeErrorAndReValidate('tags');
      if (ind > -1) {
        const lind = _.findLastIndex(tags, (o) => o.value === tag.value);
        this.handleTagDelete(lind);
      }
    });
  }

  public handleTagDelete = (i) => {
    const tags = this.state.education.tags.slice(0);
    tags.splice(i, 1);
    this.setState({ education: { ...this.state.education, tags } }, () => {
      this.removeErrorAndReValidate('tags');
    });
  }

  /**
   * @description
   * validateAndSubmit is used to validate the form by clicking on the next button.
   */
  public validateAndSubmit = () => {
    this.setState({ isSubmitted: true });
    if (this.selectedAvailabilities.length === MAGIC_NUMBER.ZERO) {
      this.setState({
        isErrorOnAvailability: true,
      });
    } else {
      const {
        selectedEducation,
        isErrorOnAvailability,
        education: { tags, languages },
      } = this.state;
      if (selectedEducation && !isErrorOnAvailability && languages.length > MAGIC_NUMBER.ZERO) {
        const { errors } = this.validator;
        this.validator.validateAll({
          tags,
        })
          .then((success: boolean) => {
            if (success) {
              this.props.successValidateSubmit();
            } else {
              this.setState({ errors });
            }
          });
      } else if (languages.length > MAGIC_NUMBER.ZERO) {
        this.setState({ isAddedLanguageSubmitted: true });
      }
    }
  }

  public validateOnEditProfile = () => {
    const {
      isErrorOnAvailability,
      education: { tags, languages },
      selectedEducation,
    } = this.state;
    if (!languages.length || selectedEducation && selectedEducation.value === '') {
      this.setState({ isSubmitted: true });
    } else if (!this.selectedAvailabilities.length) {
      this.setState({ isErrorOnAvailability: true });
    } else {
      if (!isErrorOnAvailability && selectedEducation) {
        const { errors } = this.validator;
        this.validator.validateAll({
          tags,
        })
          .then((success: boolean) => {
            if (success) {
              this.props.successValidateSubmit();
            } else {
              this.setState({ errors });
            }
          });
      }
    }
  }
  public scrollToMyRef = () => {
    window.scrollTo(0, this.ref.current.offsetTop);
  }
  /**
   * @description
   * render method is used to render the form
   */
  public render() {
    const {
      selectedEducation,
      isSubmitted,
      education: { modeOfDelivery, tags },
      isErrorOnAvailability,
      errors,
      openLanguageModal,
      savedLangulages
    } = this.state;

    const { props, tagList } = this.props;
    let params = {};
    if (props && props.location) {
      params = queryString.parse(props.location.search);
    }

    return (
      <div className="form-wrapper">
        <Form>
          <Row>
            <Col xs="12" sm="6">
              <FormGroup className="floating-label disabled-input">
                <Select
                  name="education"
                  styles={customSelectStyles}
                  value={selectedEducation}
                  onChange={this.handleEducation}
                  options={educations}
                  id="education"
                  placeholder="Highest Level of Education"
                  className="react-select-box select-box-group"
                  filterOption={createFilter(reactSelectFilters)}
                />
                <Label for="education" className={selectedEducation ? 'selected' : ''}>Highest Level of Education</Label>
                {isSubmitted && selectedEducation && selectedEducation.value === '' &&
                  <div className="error-text">The education type field is required.</div>
                }
              </FormGroup>
            </Col>
            <Col xs="12">
              {this.state.education.languages.length > MAGIC_NUMBER.ZERO ?
                <Row>
                  <div className="col-12 table-responsive-md">
                    <table className="table select_lag">
                      <thead>
                        <tr>
                          <th>Language and Proficiency</th>
                          <th>All</th>
                          <th>Read</th>
                          <th>Write</th>
                          <th>Speak</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.renderAddedLanguages()}
                      </tbody>
                    </table>
                  </div>
                </Row>
                : ''}
              {this.state.education.languages.length < staticConstants.MAX_LENGTH.LANGUAGE &&
                <Row>
                  <Col xs="12" className="d-flex justify-content-start add-button-group">
                    <Button color="cta_btn" className="get_start_btn cta_btn add-language-btn d-inline-block text-white ft-15" onClick={this.openLanguageModal}><em>+</em>Add Language</Button>
                  </Col>
                </Row>
              }
              {isSubmitted && this.state.education.languages.length === MAGIC_NUMBER.ZERO &&
                <div className="error-text timeline-error mt-2">The Language is required.</div>
              }
            </Col>
            <Col xs="12" className="education-row-margin">
              <p className="form-small-text">Mode of Delivery</p>
              <Row className="add-mode-options">
                <Col xs="12" className="add-day-options">
                  <div className="day-column">
                    <label className="control control--radio">
                      <Input
                        type="radio"
                        name="modeOfDelivery"
                        value="both"
                        checked={modeOfDelivery === 'both'}
                        onChange={this.handleChange}
                      />
                      Both
                        <div className="control__indicator" />
                    </label>
                  </div>
                  <div className="day-column">
                    <label className="control control--radio">
                      <Input
                        type="radio"
                        name="modeOfDelivery"
                        value="audio"
                        checked={modeOfDelivery === 'audio'}
                        onChange={this.handleChange}
                      />
                      Audio
                        <div className="control__indicator" />
                    </label>
                  </div>
                  <div className="day-column">
                    <label className="control control--radio">
                      <Input
                        type="radio"
                        name="modeOfDelivery"
                        value="video"
                        checked={modeOfDelivery === 'video'}
                        onChange={this.handleChange}
                      />
                      Video
                        <div className="control__indicator" />
                    </label>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs="12">
              <p className="form-small-text">Days of Availability </p>
              <div className="add-day-options">
                {this.renderAvailability()}
              </div>
              {isErrorOnAvailability &&
                <div className="error-text timeline-error">Days of Availability field is required.</div>
              }
              <Row className="mt-3" ref={this.ref}>
                <Col>
                  <FormGroup className="autocomplete-wrapper">
                    <Label for="address">What sector-based categories best describe your work? (Start typing to see your tags)</Label>
                    <SelectTags
                      selectedTags={tags}
                      onAdd={this.handleTagAdd}
                      limitTags={MAGIC_NUMBER.TEN}
                    />
                    {errors.has('tags') &&
                      <div className="error-text">{errors.first('tags')}</div>
                    }
                    <em>Maximum 10 Tags</em>
                  </FormGroup>
                </Col>
              </Row>
              {params && params['isOpenRequest'] && params['bidId'] &&
                <Row className="mt-3">
                  <Col>
                    <Label for="address" className="heading heading-sm mt-3 roboto-bold">
                      Add one or more of the following tags to your profile to match with this Request.
                    </Label>
                    <div className="w-100">
                      {tagList.map((tag: any) =>
                        tag && <span key={tag['value']} className="tags-view-only">{tag['name']}</span>)}
                    </div>
                  </Col>
                </Row>
              }
            </Col>
          </Row>
        </Form>
        <Modal isOpen={openLanguageModal} className="modal-dialog-centered modal-lg add-language-modal overlap-container">
          <ModalHeader toggle={this.hideLanguageModal}>Add Language</ModalHeader>
          <ModalBody>
            <div className="table-responsive-md">
              <table className="table select_lag">
                <thead>
                  <tr>
                    <th>Language and Proficiency</th>
                    <th>All</th>
                    <th>Read</th>
                    <th>Write</th>
                    <th>Speak</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderLanguageProfeciency()}
                </tbody>
              </table>
              {(savedLangulages.length < staticConstants.MAX_LENGTH.LANGUAGE) ? <div className="col-12 mt-4">
                <Button className="get_start_btn cta_btn border_green_btn add-language-border-btn" onClick={this.addNewLanguage}
                ><em>+</em> Add New</Button>
              </div> : ''}
              <div className="col-12 text-center mt-4 mb-4">
                <div className="row justify-content-center">
                  <div className="col-lg-4 col-md-6 col-6">
                    <a className="get_start_btn cta_btn d-block text-white" data-dismiss="modal" onClick={this.saveLanguage} >Save</a>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { searchTags } = state.tags;
  return {
    tagsSuggestions: getUpdatedOptions(searchTags, 'name'),
  };
}

const connectedEducationFormPage = connect(mapStateToProps, null, null, { forwardRef: true })(EducationForm);
export { connectedEducationFormPage as EducationForm };
