import React from 'react';

const ERROR_TYPE = {
  REQUIRED: 'required',
  MINLENGTH: 'minLength',
  MAXLENGTH: 'maxLength',
  CONFIRM_PASSWORD: 'confirmPassword',
  VALIDATE_CONFIRM_PASSWORD: 'validate',
  INVALID_START_DATE: 'isValidStartDate',
  INVALID_END_DATE: 'isValidEndDate',
  PATTERN: 'pattern',
};
interface IProps {
  error?: {message: string; ref: {message: string}; type: string};
  message?: string;
  min?: number;
  max?: number;
}

function InputError(props: IProps) {
  const { message = null, error } = props;
  const getErrorMessage = (type) => {
    switch (type) {
      case ERROR_TYPE.REQUIRED:
        return 'Mandatory';
      case ERROR_TYPE.MINLENGTH:
        return `Minimum length required is ${props.min}`;
      case ERROR_TYPE.MAXLENGTH:
        return `Maxmimum length allowed is ${props.max}`;
      case ERROR_TYPE.CONFIRM_PASSWORD:
      case ERROR_TYPE.PATTERN:
        return 'Invalid URL';
      default: return 'invalid input data';
    }
  };
  return (
      <>
    {error && <div className="error-text">

     {message || error.message || error.ref.message || getErrorMessage(error.type)}

  </div>}
  </>
  );
}

export default InputError;
