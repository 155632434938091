import React, { Fragment, Dispatch } from 'react';
import { connect } from 'react-redux';
import { BidListings } from './listings/listingContainer';
import { GoalEvents } from './../eventManagment/listing/goalEvents';
import OpenFieldTypes from 'OpenFieldTypes';
import { Button } from 'reactstrap';
import { staticConstants, CSS_ClASSES } from '../../../utils/staticConstants';
import { LocationDescriptorObject } from 'history';

interface IProps {
  isOpenModal?: boolean;
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  location?: LocationDescriptorObject;
  userId: any;
}

interface IState {
  isOpenModal: boolean;
  fetchReqParams: any;
  filterTab: string;
}

class BidManagementContainer extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      isOpenModal: false,
      fetchReqParams: {},
      filterTab: staticConstants.BID_MANAGEMENT_TAB.NEW_REQUEST,
    };
  }

  public render() {
    const { filterTab } = this.state;
    return (
      <Fragment>
        <div className="dashboard-content">
          <div className="tab-btn-group multi-tab-buttons button-count-3">
            <Button
              color="secondary"
              className={`btn btn-25 h-50 tab-btn text-uppercase ${filterTab === staticConstants.BID_MANAGEMENT_TAB.NEW_REQUEST ? CSS_ClASSES.btnActive : ''}`}
              onClick={() => this.setState({ filterTab: staticConstants.BID_MANAGEMENT_TAB.NEW_REQUEST })}
            >
              New Requests
            </Button>
            <Button
              color="secondary"
              className={`btn btn-25 h-50 tab-btn text-uppercase ${filterTab === staticConstants.BID_MANAGEMENT_TAB.SENT_BIDS ? CSS_ClASSES.btnActive : ''}`}
              onClick={() => this.setState({ filterTab: staticConstants.BID_MANAGEMENT_TAB.SENT_BIDS })}
            >
              Sent Bids
            </Button>
            <Button
              color="secondary"
              className={`btn btn-25 h-50 tab-btn text-uppercase ${filterTab === staticConstants.BID_MANAGEMENT_TAB.EVENTS ? CSS_ClASSES.btnActive : ''}`}
              onClick={() => this.setState({ filterTab: staticConstants.BID_MANAGEMENT_TAB.EVENTS })}
            >
              Events
            </Button>
          </div>
          {filterTab === staticConstants.BID_MANAGEMENT_TAB.EVENTS ?

            <GoalEvents
              userId={this.props.userId}
              activeTab={staticConstants.ADMIN_REQUEST_MANAGEMENT_TAB.ADMIN_EXPERT_REQUESTS}
              activeTabRole={staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.EXPERT_LEARNING_GOALS}
              redirectFrom={'bidManagement'}
            /> :
            <BidListings {...this.props} filterTab={filterTab} userId={this.props.userId} />
          }
        </div>
      </Fragment>
    );
  }
}

const connectedBidManagementPage = connect(null)(BidManagementContainer);
export { connectedBidManagementPage as BidManagementContainer };
