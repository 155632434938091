import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Button,
} from 'reactstrap';
import { LocationDescriptorObject } from 'history';
import queryString from 'query-string';
import { staticConstants, CSS_ClASSES } from '../../../utils';
import { Sidebar } from '../../shared/sidebar';
import OpenFieldTypes from 'OpenFieldTypes';
import { UserListingContainer } from './listings/listingContainer';
import { CreateUser } from './createUser/createUser';
import { ICreateUser } from '../../../interfaces';
import { userAction } from './redux/actions';
import { history } from '../../../helpers';

interface IProps {
  isOpenModal: boolean;
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  location: LocationDescriptorObject;
  user: any;
  role: string;
  status: object;
}

class UserManagementContainer extends React.Component<IProps> {

  public static getDerivedStateFromProps(props: IProps) {
    const state = {};
    const { location: { search }, status } = props;
    if (status['successForCreateUser']) {
      state['isOpenModal'] = false;
    }
    if (props.location['state']) {
      state['activeTab'] = props.location['state']['activeTab'];
    } else if (search) {
      state['activeTab'] = queryString.parse(search).tab || staticConstants.USER_MANAGEMENT_TAB.ORG_ADMIN;
      state['filterTab'] = queryString.parse(search).filter || null;
    } else history.replace({ search: `?page=1&tab=${staticConstants.USER_MANAGEMENT_TAB.ORG_ADMIN}` });
    return state;
  }

  public state = {
    activeTab: staticConstants.USER_MANAGEMENT_TAB.ORG_ADMIN,
    filterTab: null,
    isOpenModal: false,
    role: undefined,
    modalTitle: '',
  };

  public openCreateUserModal = (role: string, modalTitle: string) => {
    this.props.dispatch(userAction.resetCreateUserStatus());
    this.setState({
      isOpenModal: true,
      role,
      modalTitle,
    });
  }

  public addUser = (reqObj: ICreateUser) => {
    this.props.dispatch(userAction.createUser(reqObj));
  }

  public handleActiveTabs = (activeTab: string) => {
    history.push({ search: `?page=1&tab=${activeTab}` });
    this.setState({
      activeTab,
      role: staticConstants.ROLE[activeTab],
    });
  }

  /**
   * @description
   * render is used to render the html.
   */
  public render() {
    const { activeTab, isOpenModal, modalTitle, role, filterTab } = this.state;
    return (
      <React.Fragment>
        <Sidebar {...this.props} />
        <div className="dashboard-wrapper">
          <h2 className="heading heading-sm roboto-medium text-uppercase">User Management</h2>
          <div className="dashboard-content system-admin-user-management">
            <div className="tab-btn-group">
              <Button
                color="secondary"
                className={`btn btn-25 h-50 tab-btn text-uppercase ${activeTab === staticConstants.USER_MANAGEMENT_TAB.ORG_ADMIN ? CSS_ClASSES.btnActive : ''}`}
                onClick={() => this.handleActiveTabs(staticConstants.USER_MANAGEMENT_TAB.ORG_ADMIN)}
              >
                Team Admins
                  </Button>
              <Button
                color="secondary"
                className={`btn btn-25 h-50 tab-btn text-uppercase ${activeTab === staticConstants.USER_MANAGEMENT_TAB.PROFESSIONAL ? CSS_ClASSES.btnActive : ''}`}
                onClick={() => this.handleActiveTabs(staticConstants.USER_MANAGEMENT_TAB.PROFESSIONAL)}
              >
                Individuals
                  </Button>
              <Button
                color="secondary"
                className={`btn btn-25 h-50 tab-btn text-uppercase ${activeTab === staticConstants.USER_MANAGEMENT_TAB.EXPERTS ? CSS_ClASSES.btnActive : ''}`}
                onClick={() => this.handleActiveTabs(staticConstants.USER_MANAGEMENT_TAB.EXPERTS)}
              >
                Experts
                  </Button>
              <Button
                color="secondary"
                className={`btn btn-25 h-50 tab-btn text-uppercase ${activeTab === staticConstants.USER_MANAGEMENT_TAB.FLAG_PROFILES ? CSS_ClASSES.btnActive : ''}`}
                onClick={() => this.handleActiveTabs(staticConstants.USER_MANAGEMENT_TAB.FLAG_PROFILES)}
              >
                Flagged Profiles
                  </Button>
            </div>
          { role &&
            <CreateUser
              userType={role}
              modalTitle={modalTitle}
              isOpenModal={isOpenModal}
              hideModal={() => this.setState({ isOpenModal: false })}
              onClickAdd={this.addUser}
            />
          }
            <UserListingContainer {...this.props} activeTab={activeTab} filterTab={filterTab} openCreateUserModal={this.openCreateUserModal} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user,
    role: user.role,
    status: state.users.status,
  };
}

const connectedUserManagementContainer = connect(mapStateToProps)(UserManagementContainer);
export { connectedUserManagementContainer as UserManagementContainer };
