import React, { Fragment } from 'react';
import { ResetPasswordForm } from './ResetPasswordForm';

class ResetPasswordContainer extends React.Component {

  public render() {
      const props = this.props;
      return (
          <div className="form-container d-flex">
          <Fragment>
             <ResetPasswordForm {...props}/>
          </Fragment>
      </div>
        );
    }
}

export { ResetPasswordContainer };
