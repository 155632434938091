import gql from 'graphql-tag';

const MAKE_BID_MUTATION = gql`
mutation
    addBid(
      $proposedDate: Date!,
      $proposedTime: Date!,
      $bidDetails: String!,
      $goalId: String!
    ) {
        addBid(proposedDate: $proposedDate,
            proposedTime: $proposedTime,
            bidDetails: $bidDetails,
            goalId: $goalId){
                message,
                data  {
                  statusTimeline{
                    status,
                    createdAt,
                  },
                  proposedDate,
                  proposedTime,
                  bidDetails,
                  status,
              }
        }
    }
`;

const ADD_FAVOURITE = gql`
  mutation favourite( $goalId: String, $isFavourite: Boolean ) {
    favourite( goalId: $goalId, isFavourite: $isFavourite) {
        message
        data {
          isFavourite
        }
      }
}`;

const ACCEPT_BID = gql`
  mutation acceptBid( $bidId: String) {
    acceptBid( bidId: $bidId) {
        message
        data {
          statusTimeline{
            status,
            createdAt,
          },
          bidDetails,
          status,
          _id,
        }
      }
}
`;

const SHORTLIST_BID = gql`
  mutation shortlistBid( $bidId: String!, $isShortlisted: Boolean!) {
    shortlistBid( bidId: $bidId, isShortlisted: $isShortlisted) {
        message
        data {
          isShortlisted,
          _id
        }
      }
}
`;

const REJECT_BID = gql`
  mutation rejectBid( $bidId: String!) {
    rejectBid( bidId: $bidId) {
        message
      }
}
`;

const CANCEL_BID = gql`
  mutation cancelBid( $bidId: String!) {
    cancelBid( bidId: $bidId) {
        message
      }
}
`;

const RESCHEDULE_STATUS = gql`
  mutation acceptRejectReschedule( $bidId: ID!, $status: RescheduleStatus!, $toTimeRequested: Date) {
    acceptRejectReschedule( bidId: $bidId, status: $status,  toTimeRequested: $toTimeRequested) {
        message
      }
}
`;

export {
  ADD_FAVOURITE,
  MAKE_BID_MUTATION,
  ACCEPT_BID,
  SHORTLIST_BID,
  REJECT_BID,
  CANCEL_BID,
  RESCHEDULE_STATUS,
};
