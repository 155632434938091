import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import OpenFieldTypes from 'OpenFieldTypes';
import { Sidebar } from '../sidebar';
import { ListingContainer } from './listings/listing';
interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
}

class LogsManagementContainer extends React.Component<IProps> {

  public render() {
    return (
      <div>
        <Sidebar {...this.props} />
        <ListingContainer {...this.props} />
      </div >
    );
  }
}

const connectedManagementPage = connect(null)(LogsManagementContainer);
export { connectedManagementPage as LogsManagementContainer };
