import React from 'react';

function AdminPaymentPortalView({ bankInfo }) {

  const maskAccountName = acctNum => acctNum;
  return (
    <div className="profile-details-wrapper">
      <div className="form-details">
        <h2 className="heading heading-rg roboto-medium">Bank Account Information</h2>
        <div className="form-data">
          <div className="row">
            <div className="col-12 col-md-3">
              <span>Bank Name</span>
              <p>{bankInfo?.bankAccount?.bankName}</p>
            </div>
            <div className="col-12 col-md-3">
              <span>Account Number</span>
              <p>{maskAccountName(bankInfo?.bankAccount?.accountNumber)}</p>
            </div>
            <div className="col-12 col-md-3">
              <span>Account Holder Name</span>
            <p>{bankInfo?.bankAccount?.accountHolderName}</p>
            </div>
            <div className="col-12 col-md-3">
              <span>Routing No.</span>
              <p>{bankInfo?.bankAccount?.routingNumber}</p>
            </div>
            <div className="col-12 col-md-3">
              <span>Branch Code</span>
              <p>{bankInfo?.bankAccount?.branchCode}</p>
            </div>
            <div className="col-12 col-md-3">
              <span>Swift Code</span>
              <p>{bankInfo?.bankAccount?.swiftNumber}</p>
            </div>
            <div className="col-12 col-md-3">
              <span>Intermediary Bank (Optional)</span>
              <p>{bankInfo?.bankAccount?.other}</p>
            </div>
          </div>
        </div>
        <h2 className="heading heading-rg roboto-medium">Account Holder Address Information</h2>
        <div className="form-data">
          <div className="row">
            <div className="col-12 col-md-3">
              <span>Street 1</span>
              <p>{bankInfo?.accountHolderAddress?.street1}</p>
            </div>
            <div className="col-12 col-md-3">
              <span>Street 2</span>
              <p>{bankInfo?.accountHolderAddress?.street2}</p>
            </div>
            <div className="col-12 col-md-3">
              <span>City</span>
              <p>{bankInfo?.accountHolderAddress?.city}</p>
            </div>
            <div className="col-12 col-md-3">
              <span>State</span>
              <p>{bankInfo?.accountHolderAddress?.state}</p>
            </div>
            <div className="col-12 col-md-3">
              <span>Zip Code</span>
              <p>{bankInfo?.accountHolderAddress?.zipcode}</p>
            </div>
            <div className="col-12 col-md-3">
              <span>Country</span>
              <p>{bankInfo?.accountHolderAddress?.country.label}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AdminPaymentPortalView;
