import { actionTypes } from './types';

interface IAction {
  type: string;
  data: object;
}

const initialState = {
  status: {},
};

export function subscriptionPlan(state = initialState, action: IAction) {
  switch (action.type) {
    case actionTypes.GET_SUBSCRIPTION_PRODUCT_REQUEST:
      return { ...state, requestingSubscriptionProduct: true };
    case actionTypes.GET_SUBSCRIPTION_PRODUCT_SUCCESS:
      return { ...state, getSubscriptionProductSuccess: true, subscriptionProduct: action.data['data'] };
    case actionTypes.GET_SUBSCRIPTION_PRODUCT_FAILURE:
      return { ...state, getSubscriptionProductSuccess: false };
    default:
      return state;
  }
}
