export const actionTypes = {
  CARDS_LISTING_REQUEST: 'CARDS_LISTING_REQUEST',
  CARDS_LISTING_SUCCESS: 'CARDS_LISTING_SUCCESS',
  CARDS_LISTING_FAILURE: 'CARDS_LISTING_FAILURE',
  ADD_PAYMENT_CARD_REQUEST: 'ADD_PAYMENT_CARD_REQUEST',
  ADD_PAYMENT_CARD_SUCCESS: 'ADD_PAYMENT_CARD_SUCCESS',
  ADD_PAYMENT_CARD_FAILURE: 'ADD_PAYMENT_CARD_FAILURE',
  UPDATE_PAYMENT_CARD_REQUEST: 'UPDATE_PAYMENT_CARD_REQUEST',
  UPDATE_PAYMENT_CARD_SUCCESS: 'UPDATE_PAYMENT_CARD_SUCCESS',
  UPDATE_PAYMENT_CARD_FAILURE: 'UPDATE_PAYMENT_CARD_FAILURE',
  DELETE_PAYMENT_CARD_REQUEST: 'DELETE_PAYMENT_CARD_REQUEST',
  DELETE_PAYMENT_CARD_SUCCESS: 'DELETE_PAYMENT_CARD_SUCCESS',
  DELETE_PAYMENT_CARD_FAILURE: 'DELETE_PAYMENT_CARD_FAILURE',
  RESET_STATE: 'RESET_STATE',
};
