import React, { Fragment, Dispatch } from 'react';
import { connect } from 'react-redux';
import { LocationDescriptorObject } from 'history';
import { learnerAction } from './redux/actions';
import { ListingContainer } from './listings/listing';
import { CreateLearner } from './createLearner/createLearner';
import OpenFieldTypes from 'OpenFieldTypes';
import {
  Modal,
  ModalBody,
  Media,
  Button,
} from 'reactstrap';
import { subscribeMoreLicence, pageRoutes } from '../../../utils/';
import { AssignUnassignLicenceModalPopup } from '../modals/assignUnassignSuccessPopup';
import { LicenceModalPopup } from '../modals/licenceModalPopup';
import { history } from '../../../helpers';
interface IProps {
  isOpenModal: boolean;
  isLearnerCreationSuccessModalOpen: boolean;
  email: any;
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  displayCongratulationPopup: boolean;
  isLicenced: boolean;
  fullLearnerName: string;
  isLearnerDelete: boolean;
  isAssignMoreLiecence: boolean;
  location?: LocationDescriptorObject;
}

interface IState {
  isOpenModal: boolean;
  email: any;
  isLearnerCreationSuccessModalOpen: boolean;
  fetchReqParams: any;
  displayCongratulationPopup: boolean;
  isLicenced: boolean;
  fullLearnerName: string;
  displaySubscribePopup: boolean;
  modalPopupObj: object;
}

class ManagementContainer extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      isOpenModal: false,
      isLearnerCreationSuccessModalOpen: false,
      email: '',
      fetchReqParams: {},
      fullLearnerName: '',
      isLicenced: false,
      displayCongratulationPopup: false,
      displaySubscribePopup: false,
      modalPopupObj: {},
    };
    this.openCreateLearnerModal = this.openCreateLearnerModal.bind(this);
  }

  public componentWillReceiveProps(props: any, nextProps: any) {
    if (props.hasOwnProperty('isOpenModal') || props.successForAssignUnassignLearner || props.isLearnerDelete || props.isAssignMoreLiecence) {
      this.setState({
        isOpenModal: props.isOpenModal,
        isLearnerCreationSuccessModalOpen: props.isLearnerCreationSuccessModalOpen,
        email: props.email,
        displayCongratulationPopup: props.displayCongratulationPopup,
        fullLearnerName: props.learnerFullName,
        isLicenced: props.isLicenced,
        displaySubscribePopup: props.isAssignMoreLiecence,
      });
      this.props.dispatch(learnerAction.getLearner(this.state.fetchReqParams));
    }
  }

  public openCreateLearnerModal(fetchReqParams: any) {
    this.setState({
      isOpenModal: true,
      fetchReqParams,
    });
  }

  /**
   * @description
   * subscribe more licence is called when someone click on the subscribe button
   */
  public onSubmitSubscribeMoreLicence = () => {
    history.push({
      pathname: `${pageRoutes.PAYMENT.PATH}${pageRoutes.PAYMENT.SUBSCRIPTION}`,
      state: {
        redirectUri: this.props.location['pathname'],
        action: 'update-plan',
        originUri: this.props.location['pathname'],
        originStateVars: this.props.location['state'],
        showSidebarNav: true,
        breadcrumb: [{ title: 'USER INFORMATION', link: this.props.location['pathname'] },
        { title: 'Change Plan', link: null }],
      },
    });
  }

  public render() {
    const { displayCongratulationPopup, fullLearnerName, isLicenced, displaySubscribePopup } = this.state;
    return (
      <div className="form-container d-flex">
        <Fragment>
          <ListingContainer
            {...this.props}
            openCreateLearnerModal={this.openCreateLearnerModal}
          />
          <CreateLearner
            isOpenModal={this.state.isOpenModal}
            hideModal={() => this.setState({ isOpenModal: false })}
            assignLicence={this.state.fetchReqParams['learners'] && this.state.fetchReqParams['learners']['assignLicence']}
          />
          <Modal
            isOpen={this.state.isLearnerCreationSuccessModalOpen}
            className="subscription-request add-on-modal"
            onClosed={() =>
              this.setState({ isLearnerCreationSuccessModalOpen: false })
            }
          >
            <span
              className="close-btn icon icon-cross"
              onClick={() =>
                this.setState({ isLearnerCreationSuccessModalOpen: false })
              }
            />
            <ModalBody>
              <div className="form-group-wrapper">
                <Media>
                  <Media top className="icon" />
                  <Media body>
                    <Media
                      heading
                      className="heading heading-lg oswald-bold mb-3"
                    >
                      Invitation Sent!
                    </Media>
                    <Media className="subheading">
                      <div>
                        An invitation has been sent by email to{' '}
                        <span className="oswald-bold">
                          {' '}
                          {this.state.email}{' '}
                        </span>{' '}
                      </div>
                    </Media>
                    <Button
                      color="primary"
                      className="btn btn-rg"
                      onClick={() => this.setState({ isLearnerCreationSuccessModalOpen: false })}
                    >
                      Done
                    </Button>
                  </Media>
                </Media>
              </div>
            </ModalBody>
          </Modal>
          {displayCongratulationPopup && (
            <AssignUnassignLicenceModalPopup
              isOpenModal={displayCongratulationPopup}
              fullLearnerName={fullLearnerName}
              isLicenced={isLicenced}
              hideModal={() =>
                this.setState({ displayCongratulationPopup: false })
              }
            />
          )}

          {displaySubscribePopup && (
            <LicenceModalPopup
              isOpenModal={displaySubscribePopup}
              modalPopupObj={subscribeMoreLicence}
              hideModal={() => this.setState({ displaySubscribePopup: false })}
              onClickYes={this.onSubmitSubscribeMoreLicence}
            />
          )}
        </Fragment>
      </div>
    );
  }
}

function mapStateToProps(state: { learner: any }) {
  const {
    successForCreateLearner,
    email,
    successForAssignUnassignLearner,
    learnerData,
    deleteLearner,
    assignMoreLiecence,
  } = state.learner;
  let displayCongratulationPopup = false;
  let learnerFullName = '';
  let isLicenced = false;
  let isLearnerDelete = false;
  let isLearnerCreationSuccess = false;
  let isAssignMoreLiecence = false;
  if (successForAssignUnassignLearner) {
    displayCongratulationPopup = true;
    learnerFullName = `${learnerData['firstName']} ${learnerData['lastName']}`;
    isLicenced = learnerData['isLicenced'];
  }

  if (successForCreateLearner) {
    isLearnerCreationSuccess = true;
  }

  if (deleteLearner) {
    isLearnerDelete = true;
  }
  if (assignMoreLiecence) {
    isAssignMoreLiecence = assignMoreLiecence;
  }

  if (successForCreateLearner || successForAssignUnassignLearner || deleteLearner || assignMoreLiecence) {
    return {
      isOpenModal: false,
      email,
      isLearnerCreationSuccessModalOpen: isLearnerCreationSuccess,
      successForAssignUnassignLearner,
      displayCongratulationPopup,
      learnerFullName,
      isLicenced,
      isLearnerDelete,
      isAssignMoreLiecence,
    };
  }
  return {};
}

const connectedManagementPage = connect(mapStateToProps)(ManagementContainer);
export { connectedManagementPage as ManagementContainer };
