import React from 'react';

import userImage from '../../assets/images/profile.png';
import { capitalizeEveryWord } from '../../utils';
import { FeaturedExpertPopup } from '../shared/modals/FeaturedExpertPopup';

interface IState {
  showExpertPopup: boolean;
}

interface IProps {
  user?: any;
  key?: any;
  modal?: any;
}

class FeaturedExpertCard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showExpertPopup: false,
    };
  }

  public render() {
    const { user, key, modal } = this.props;
    const { showExpertPopup } = this.state;
    const { firstName, lastName, address, location, tags, profileImage, languages } = user;
    return (
      <div className="item" key={key}>
        <div className="carouselCard">
          <div className="carouselCard-head">
            <div className="carouselCard-image">
              <img src={profileImage || userImage} alt="image" />
            </div>
            <div className="carouselCard-imageTitle">
              <h4>
                {capitalizeEveryWord(firstName)} {capitalizeEveryWord(lastName)}
              </h4>
              <p>
                {capitalizeEveryWord(address)}
                {address && location && ','} {capitalizeEveryWord(location)}
              </p>
            </div>
            {languages.length > 0 &&
            languages.map(language => <div className="carouselCard-imageLanguage"><div className="carouselCard-language">{language.language}</div></div>)}
          </div>
          <div className="carouselCard-content" id="hel">
            {tags.map((tag, index) => {
              if (index < 3 && modal) {
                return <span key={index}>{tag.name}</span>;
              } else if (!modal) {
                return <span key={index}>{tag.name}</span>;
              } else return null;
            })}
          </div>
          {modal && tags.length > 3 && (
            <div
              className="showMore-btn"
              onClick={() => this.setState({ showExpertPopup: true })}
            >
              show More tags
            </div>
          )}
        </div>
        {this.state.showExpertPopup && (
          <FeaturedExpertPopup
            user={user}
            isOpenModal={showExpertPopup}
            hideModal={() => this.setState({ showExpertPopup: false })}
          />
        )}
      </div>
    );
  }
}

export { FeaturedExpertCard };
