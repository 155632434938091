import React, { Dispatch } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import ReeValidate from 'ree-validate';
import {
  Col,
  Form,
  Button,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import OpenFieldTypes from 'OpenFieldTypes';
import { validator } from './../../../helpers';
import { reeValidators, staticConstants, MAGIC_NUMBER } from './../../../utils/staticConstants';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { messages } from '../../../utils/';
import deleteImage from './../../../assets/images/delete_icon.png';
validator(ReeValidate);

interface IProps {
  references?: any;
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  successValidateSubmit: any;
  handleResumeSubmit: any
}

interface IState {
  references?: any;
  isSubmitted: boolean;
  errors?: any;
  resume: any;
  resumeUpload: any
}

class ReferenceForm extends React.Component<IProps, IState> {

  public selectedProfeciencies: string[] = [];
  public selectedAvailabilities: string[] = [];
  public validator: any;

  /*
   * @description
   * constructor is used to define the initial state and property
   * @param fields {Object} props
  * */
  constructor(props: IProps) {
    super(props);
    this.validator = new ReeValidate({
      'name-0': null,
      'email-0': reeValidators.email_not_required,
      'phone-0': 'min:5|max:15',
      'relation-0': null,
      resume: 'required'
    });
    this.state = {
      references: [{
        'name-0': '',
        'email-0': '',
        'phone-0': '',
        'relation-0': '',
      }],
      resume: null,
      errors: this.validator.errors,
      isSubmitted: false,
      resumeUpload: null,
    };
  }

  /*
   * @description
   * addReference is called when someone click on the addReference button
  * */
  public addReference = () => {
    const { references } = this.state;
    const referencesLength = references.length; // Set index again
    references.push({
      [`name-${referencesLength}`]: '',
      [`email-${referencesLength}`]: '',
      [`phone-${referencesLength}`]: '',
      [`relation-${referencesLength}`]: '',
    });
    this.setState({
      references,
    });
    this.reIntializeValidation();
  }

  /*
   * @description
   * removeReference is called when someone click on the remove button
  * */
  public removeReference = (index: any) => {
    const { references } = this.state;
    if (references.indexOf(MAGIC_NUMBER.FIVE)) {
      references.splice(index, MAGIC_NUMBER.ONE);
    }
    this.setState({
      references,
    });
    this.reIntializeValidation();
  }

  /*
   * @description
   * reIntializeValidation is used to add validation again
  * */
  public reIntializeValidation = () => {
    const { references } = this.state;
    const referencesLength = references.length; // Set index again
    const validateObj = {};
    for (let i = 0; i < referencesLength; i++) {
      validateObj[`name-${i}`] = null;
      validateObj[`email-${i}`] = reeValidators.email_not_required;
      validateObj[`phone-${i}`] = 'required|min:5|max:15';
      validateObj[`relation-${i}`] = null;
    }
    this.validator = new ReeValidate(validateObj);
  }

  /*
   * @description
   * handleChange is used to set the value on state from the only input/textarea boxes.
   * @param fields {Object} event
   * */
  public handleChange = (index: number, event: any, key?: string) => {
    const { value, name } = event.target;
    const { references } = this.state;
    references[index][name] = value;
    this.setState({
      references
    });
    const { errors } = this.validator;
    errors.remove(name);
    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors });
      });
  }

  /*
   * @description
   * handlePhoneChange is used to set the value on state from the phone.
   * @param fields {Object} num
   * */
  public handlePhoneChange = (index: any, num: { formattedValue: string }) => {
    const { references } = this.state;
    const name = `phone-${index}`;
    references[index][name] = num.formattedValue.replace('-', '');
    this.setState({
      references,
    });
    const { errors } = this.validator;
    errors.remove(name);
    this.validator.validate(name, references[index][name])
      .then(() => {
        this.setState({ errors });
      });
  }

  /*
   * @description
   * validateAndSubmit is used to validate the form by clicking on the next button.
   * @param fields {Object} event
   * */
  public validateAndSubmit = () => {
    const { errors } = this.validator;
    const { references, resume } = this.state;
    const referencesLength = references.length; // Set index again
    const validateObj = { resume };
    for (let i = 0; i < referencesLength; i++) {
      const reference = references[i];
      validateObj[`name-${i}`] = reference[`name-${i}`];
      validateObj[`email-${i}`] = reference[`email-${i}`];
      validateObj[`phone-${i}`] = reference[`phone-${i}`];
      validateObj[`relation-${i}`] = reference[`relation-${i}`];
    }
    this.validator.validateAll(validateObj)
      .then((success: boolean) => {
        if (success) {
          this.props.successValidateSubmit();
        } else {
          this.setState({ errors });
        }
      });
  }

  /*
   * @description
   * renderReferenceForm is used to render the reference form
  * */
  public renderReferenceForm() {

    const { errors } = this.state;
    const getErrClass = (field: string) => {
      return errors.has(field) ? ' has-error' : '';
    };
    return _.map(this.state.references, (item: any, index: number) => {
      return (
        <Row key={index} className={`${index > MAGIC_NUMBER.ZERO ? 'reference-row' : 'first-row'}`}>
          <Col xs="12" className="Experience_tital">
            <h5>Reference {index + 1} (Add up to 3)</h5>
          </Col>
          <Col xs="12">
            <div className="Experience_section">
              <Col xs="12" sm="6">
                <FormGroup className={`floating-label ${getErrClass('name')}}`}>
                  <Input
                    type="text"
                    className="form-control"
                    name={`name-${index}`}
                    id="name"
                    value={item[`name-${index}`]}
                    onChange={(e) => this.handleChange(index, e)}
                    placeholder="Name"
                  />
                  <Label for="name">Name</Label>
                  {errors.has(`name-${index}`) &&
                    <div className="error-text">The name field is required.</div>
                  }
                </FormGroup>
              </Col>
              <Col xs="12" sm="6">
                <FormGroup className="floating-label">
                  <Input
                    type="email"
                    className="form-control"
                    name={`email-${index}`}
                    id="email"
                    value={item[`email-${index}`]}
                    onChange={(e) => this.handleChange(index, e)}
                    placeholder="Email"
                  />
                  <Label for="email">Email</Label>
                  {errors.has(`email-${index}`) &&
                    <div className="error-text">The valid email field is required.</div>
                  }
                </FormGroup>
              </Col>
              <Col xs="12" sm="6">
                <FormGroup className="floating-label">
                  <NumberFormat
                    prefix={'+'}
                    className="form-control"
                    name="phoneNumber"
                    mask=""
                    placeholder="Phone Number"
                    maxLength={MAGIC_NUMBER.FIFTEEN}
                    onValueChange={e => this.handlePhoneChange(index, e)}
                    value={item[`phone-${index}`]}
                  />
                  <Label for="phoneNumber">Phone Number</Label>
                  {errors.has(`phone-${index}`) &&
                    <div className="error-text">
                      {messages.invalidPhoneNumber}
                    </div>
                  }
                </FormGroup>
              </Col>
              <Col xs="12" sm="6">
                <FormGroup className="floating-label">
                  <Input
                    type="text"
                    className="form-control"
                    name={`relation-${index}`}
                    id="relation"
                    value={item[`relation-${index}`]}
                    onChange={e => this.handleChange(index, e)}
                    placeholder="Relationship"
                  />
                  <Label for="relation">Relationship</Label>
                  {errors.has(`relation-${index}`) &&
                    <div className="error-text">The Relationship field is required.</div>
                  }
                </FormGroup>
              </Col>
            </div>
          </Col>
          { index > MAGIC_NUMBER.ZERO ?
            <Col xs="12" className="d-flex justify-content-end add-button-group">
              <Button color="add-new" className="cta_btn service_btn remove" onClick={() => this.removeReference(index)}><img src={deleteImage} alt="delete" /> Remove</Button>
            </Col>
            : ''}
        </Row>
      );
    });
  }

  /*
   * @description
   * render method is used to render the form
  * */
  public render() {
    return (
      <div className="form-wrapper">
        <Form autoComplete="off">
          {this.renderReferenceForm()}
          {this.state.references.length < staticConstants.MAX_LENGTH.EXPERIENCE &&
            <>
              <Row>
                <Col xs="12" className="d-flex justify-content-end add-button-group">
                  <Button color="add-new" className="cta_btn service_btn" onClick={this.addReference}><em>+</em>Add New</Button>
                </Col>
              </Row>
              <Row>
                <div className='uploadResume'> <h2 className="heading heading-rg roboto-medium mb-3">Upload Resume</h2>
                  <div className='file-input'>
                    <span className='button'>
                      <input type='file'
                        name="resume"
                        accept="application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        onChange={(e) => {
                          this.setState({ resume: e.currentTarget.files[0] })
                          this.validator.errors.remove('resume');
                          this.validator.validate('resume', e.currentTarget.files[0])
                            .then(() => {
                              this.setState({ errors: this.validator.errors });
                            });
                            const fileInfo = e.currentTarget.files[0];
                            this.setState({ resumeUpload: fileInfo.name });
                          this.props.handleResumeSubmit(e)
                        }
                        }
                      />
                  Choose
                </span>
                {
                  this.state.resumeUpload && (
                    <label className='label'>{ this.state.resumeUpload }</label>
                  )
                }
                {
                  !(this.state.resumeUpload) && (
                    <label className='label'>No file selected</label>
                  )
                }
                  </div>
                  {this.state.errors.has('resume') &&
                    <div className="error-text">Resume is required.</div>
                  }
                </div>
              </Row>
            </>
          }
          <div className="signup_term_con mt-4 text-center w-100">
            <p>
              By clicking “Done” I agree to Overlap’s{' '}
              <Link to="/termsofuse" target="_blank">
                Terms of Use
                </Link>{' '}
                and{' '}
              <Link to="/privacyPolicy" target="_blank">
                Privacy Policy.
                </Link>
            </p>
          </div>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { registering } = state.registeration;
  return {
    registering,
  };
}

const connectedReferenceFormPage = connect(mapStateToProps, null, null, { forwardRef: true })(ReferenceForm);
export { connectedReferenceFormPage as ReferenceForm };
