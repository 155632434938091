import React, { Dispatch } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';
import {
  FormGroup,
  Label,
  Button,
  Collapse,
  NavbarToggler,
  Nav,
  NavItem,
} from 'reactstrap';
import logoImage from './../../assets/images/logo-white.png';
import searchImage from './../../assets/images/search.svg';
import searchIconImg from './../../assets/images/search_icon.png';
import matchImage from './../../assets/images/match.svg';
import engageImage from './../../assets/images/engage.svg';
import airPolImage from './../../assets/images/1.svg';
import biodiversityImg from './../../assets/images/2.svg';
import carouselImage from './../../assets/images/about_banner.png';
import energyImage from './../../assets/images/3.svg';
import cargonImage from './../../assets/images/4.svg';
import transportImage from './../../assets/images/5.svg';
import waterImage from './../../assets/images/6.svg';
import { capitalizeEveryWord, customSelectStyles, handleResponse } from '../../utils/common';
import { tagAction } from './../shared/tags/redux/actions';
import { history } from '../../helpers';
import { LandingFooter } from './landingFooter';
import { getLocalUserData } from './../../utils/common';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import {
  getUpdatedOptions,
  getSelectedValue,
  reactSelectFilters,
  MAGIC_NUMBER,
  getNavToDashboard,
} from '../../utils';
import Select, { createFilter } from 'react-select';
import OpenFieldTypes from 'OpenFieldTypes';
import { SearchTags } from '../shared/searchTags/searchTags';
import { dashboardServices } from '../../services';
import { FeaturedExpertCard } from './FeaturedExpertCard';

const carouselOptions = {
  responsiveClass: true,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1100: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  },
};

const videoMp4 = 'https://openfield-qa.s3.amazonaws.com/video.mp4';
interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  domainOptions: any;
  subDomainOptions: any;
  categoryOptions: any;
  subCategoryOptions: any;
  topicOptions: any;
  activityOptions: any;
  isloggedIn: any;
}

interface IState {
  nav1: any;
  nav2: any;
  selectedDomain: any;
  selectedSubDomain: any;
  selectedCategory: any;
  selectedSubCategory: any;
  selectedTopic: any;
  selectedActivity: any;
  activeTab: any;
  videoUrl: string;
  isOpen: any;
  allTags: any;
  featuredExperts: any;
}

class HomePage extends React.Component<IProps, IState, any> {

  public slider1: any;
  public slider2: any;
  constructor(props: any) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      selectedDomain: null,
      selectedSubDomain: null,
      selectedCategory: null,
      selectedSubCategory: null,
      selectedTopic: null,
      selectedActivity: null,
      activeTab: '1',
      videoUrl: videoMp4,
      isOpen: false,
      allTags: [],
      featuredExperts: [],
    };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  public toggleMenu() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  public toggle = (tab: any) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  public getFeaturedExpertsList = async () => {
    const response = await dashboardServices.getFeaturedExperts();
    this.setState({ featuredExperts: response });
  }

  public componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
    this.props.dispatch(tagAction.getDomains());
    this.props.dispatch(tagAction.getActivities());

    this.getFeaturedExpertsList();
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'hs-script-loader';
    script.src = '//js.hs-scripts.com/7903349.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  /**
   * @description componentWillUnmount is called when component destroyed
   * called tagAction.resetAllTags()
   */
  public componentWillUnmount() {
    this.props.dispatch(tagAction.resetAllTags());
  }

  /**
   * @description
   * handleDomain is used to set the domain.
   */
  public handleDomain = (domain: any) => {
    this.setState({
      selectedDomain: domain,
      selectedSubDomain: null,
      selectedCategory: null,
      selectedSubCategory: null,
      selectedTopic: null,
    },            () => {
      const dropdownValues = this.getTagsValue();
      this.props.dispatch(tagAction.getSubDomains(domain, dropdownValues));
    });
  }

  /**
   * @description
   * getSelectedValue is used to get all selected dropdown value.
   */
  public getTagsValue = () => {
    return {
      domain: getSelectedValue(this.state.selectedDomain),
      subDomain: getSelectedValue(this.state.selectedSubDomain),
      category: getSelectedValue(this.state.selectedCategory),
      subCategory: getSelectedValue(this.state.selectedSubCategory),
      topic: getSelectedValue(this.state.selectedTopic),
    };
  }

  /**
   * @description
   * handleSubDomain is used to set the subDomain.
   */
  public handleSubDomain = (subDomain: any) => {
    this.setState({ selectedSubDomain: subDomain }, () => {
      const dropdownValues = this.getTagsValue();
      this.props.dispatch(tagAction.getCategories(subDomain, dropdownValues));
    });
  }

  /**
   * @description
   * handleCategory is used to set the category.
   */
  public handleCategory = (category: any) => {
    this.setState({ selectedCategory: category }, () => {
      const dropdownValues = this.getTagsValue();
      this.props.dispatch(tagAction.getSubCategories(category, dropdownValues));
    });
  }

  /**
   * @description
   * handleSubCategory is used to set the subCategory.
   */
  public handleSubCategory = (subCategory: any) => {
    this.setState({ selectedSubCategory: subCategory }, () => {
      const dropdownValues = this.getTagsValue();
      this.props.dispatch(tagAction.getTopics(subCategory, dropdownValues));
    });
  }

  /**
   * @description
   * handleTopic is used to set the topic.
   */
  public handleTopic = (topic: any) => {
    this.setState({ selectedTopic: topic }, () => {
      const dropdownValues = this.getTagsValue();
      this.props.dispatch(tagAction.getActivities(topic, dropdownValues));
    });
  }

  /**
   * @description
   * moveToOtherPage is used to move other pages
   */
  public moveToOtherPage = (page: any) => {
    const data = {
      selectedDomain: this.state.selectedDomain,
      selectedSubDomain: this.state.selectedSubDomain,
      selectedCategory: this.state.selectedCategory,
      selectedSubCategory: this.state.selectedSubCategory,
      selectedTopic: this.state.selectedTopic,
      selectedActivity: this.state.selectedActivity,
    };
    const goal = JSON.parse(localStorage.getItem('goal')) || {};
    localStorage.setItem('goal', JSON.stringify({ ...goal, ...data }));
    history.push({
      pathname: page,
    });
  }

  public handleTagAdd = (tag: any) => {
    const formValues = {
      selectedDomain: null,
      selectedSubDomain: null,
      selectedCategory: null,
      selectedSubCategory: null,
      selectedTopic: null,
    };

    switch (tag.type) {
      case 'type1':
        formValues['selectedDomain'] = { value: tag._id, label: tag.name };
        this.setState(formValues, () => {
          const dropdownValues = this.getTagsValue();
          this.props.dispatch(tagAction.getSubDomains('', dropdownValues));
        });
        break;
      case 'type2':
        formValues['selectedDomain'] = { value: tag.connectingId._id, label: tag.connectingId.name };
        formValues['selectedSubDomain'] = { value: tag._id, label: tag.name };
        this.setState(formValues, () => {
          const dropdownValues = this.getTagsValue();
          this.props.dispatch(tagAction.getDomains());
          this.props.dispatch(tagAction.getSubDomains(tag.name, dropdownValues));
          this.props.dispatch(tagAction.getCategories('', dropdownValues));
        });
        break;
      case 'type3':
        formValues['selectedDomain'] = { value: tag.domainId._id, label: tag.domainId.name };
        formValues['selectedSubDomain'] = { value: tag.connectingId._id, label: tag.connectingId.name };
        formValues['selectedCategory'] = { value: tag._id, label: tag.name };
        this.setState(formValues, () => {
          const dropdownValues = this.getTagsValue();
          this.props.dispatch(tagAction.getDomains());
          this.props.dispatch(tagAction.getSubDomains(tag.connectingId.name, dropdownValues));
          this.props.dispatch(tagAction.getCategories(tag.name, dropdownValues));
          this.props.dispatch(tagAction.getSubCategories('', dropdownValues));
        });
        break;
      case 'type4':
        formValues['selectedDomain'] = { value: tag.domainId._id, label: tag.domainId.name };
        formValues['selectedSubDomain'] = { value: tag.subDomainId._id, label: tag.subDomainId.name };
        formValues['selectedCategory'] = { value: tag.connectingId._id, label: tag.connectingId.name };
        formValues['selectedSubCategory'] = { value: tag._id, label: tag.name };
        this.setState(formValues, () => {
          const dropdownValues = this.getTagsValue();
          this.props.dispatch(tagAction.getDomains());
          this.props.dispatch(tagAction.getSubDomains(tag.subDomainId.name, dropdownValues));
          this.props.dispatch(tagAction.getCategories(tag.connectingId.name, dropdownValues));
          this.props.dispatch(tagAction.getSubCategories(tag.name, dropdownValues));
          this.props.dispatch(tagAction.getTopics('', dropdownValues));
        });
        break;
      case 'type5':
        formValues['selectedDomain'] = { value: tag.domainId._id, label: tag.domainId.name };
        formValues['selectedSubDomain'] = { value: tag.subDomainId._id, label: tag.subDomainId.name };
        formValues['selectedCategory'] = { value: tag.categoryId._id, label: tag.categoryId.name };
        formValues['selectedSubCategory'] = { value: tag.connectingId._id, label: tag.connectingId.name };
        formValues['selectedTopic'] = { value: tag._id, label: tag.name };

        this.setState(formValues, () => {
          const dropdownValues = this.getTagsValue();
          this.props.dispatch(tagAction.getDomains());
          this.props.dispatch(tagAction.getSubDomains(tag.subDomainId.name, dropdownValues));
          this.props.dispatch(tagAction.getCategories(tag.categoryId.name, dropdownValues));
          this.props.dispatch(tagAction.getSubCategories(tag.connectingId.name, dropdownValues));
          this.props.dispatch(tagAction.getTopics(tag.name, dropdownValues));
        });
        break;
    }
  }

  public render() {
    const {
      domainOptions,
      subDomainOptions,
      categoryOptions,
      subCategoryOptions,
      topicOptions,
      activityOptions,
      isloggedIn,
    } = this.props;

    if (isloggedIn === true) {
      var { email, role, firstName = null, lastName = null, isFirstTime } = getLocalUserData();
    }
    else {
      var email = null,role = null, firstName = null, lastName = null, isFirstTime = null;
    }

    const { selectedCategory, selectedSubCategory, allTags,
      selectedTopic, selectedDomain, selectedSubDomain, selectedActivity, videoUrl, featuredExperts } = this.state;
    const commonClass = 'disabled-select';
    
    return (
      <div >
        <div className="home_banner">
          <div className="video_bg">
            <ReactPlayer
              url={videoUrl} playing loop
              height="100vh"
              width="100%"
              className="iframe-style"
            />
          </div>
          <div className="my_nav">
            <div className="container">
              <nav className="navbar navbar-expand-lg">
                <Link to="/" className="navbar-brand" >
                  <img src={logoImage} alt="logo" />
                </Link>
                <NavbarToggler onClick={this.toggleMenu} />
                <Collapse isOpen={this.state.isOpen} navbar>
                  <Nav className="ml-auto" navbar>
                    <NavItem>
                      <Link to="/pricing" className="nav-link" >Pricing</Link>
                    </NavItem>
                    <NavItem>
                      <Link to="/register/expert" className="nav-link" >Share Your Expertise</Link>
                    </NavItem>
                    {isloggedIn ? <>
                      <NavItem>
                        <Link to={getNavToDashboard()} className="nav-link" >{firstName ? `${firstName} ${lastName}` : email}</Link>
                      </NavItem>
                    </> :
                      <>
                        <NavItem>
                          <Link to="/login" className="nav-link" >Log In</Link>
                        </NavItem>
                        <NavItem>
                          <Link to="/usertype" className="nav-link" >Sign Up</Link>
                        </NavItem>
                      </>
                    }
                  </Nav>
                </Collapse>
              </nav>
            </div>
          </div>
          <div className="container">
            <div className="banner_text">
              <div>
                <h2>Sustainability people for any project.</h2>
                <p>Overlap provides easy access to specialists in sustainability and ESG.</p>
                <Link to="/howItWork" className="get_start_btn cta_btn d-inline-block"> HOW IT WORKS </Link>
              </div>
            </div>
          </div>
        </div>
        {!!featuredExperts.length && <div className="home-featured-carousel">
          <h3>Our Featured Experts</h3>
          <OwlCarousel className="owl-theme" responsive={true} loop={false} margin={30} nav dots={false} {...carouselOptions}>
            {featuredExperts.map((user, index) => {
              return <FeaturedExpertCard user ={user} key ={index} modal/>
            })}
          </OwlCarousel>
        </div>}
        <div className="common_padding">
          <div className="container bg_images">
            <div className="row">
              <div className="col-12 find_match text-center mb-3">
                <h2 className="common_heading">What you can do with Overlap</h2>
              </div>
              <div className="col-lg-4 col-md-12 text-center">
                <div className="fins_match_text">
                  <img src={searchImage} alt="image" className="mb-1" />
                  <h3>Search</h3>
                  <p>More than 5000 green economy topics
                    to find the help you need.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 text-center">
                <div className="fins_match_text">
                  <img src={matchImage} alt="image" className="mb-1" />
                  <h3>Match</h3>
                  <p>Qualified experts will find you when your request
                    matches their profile.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 text-center">
                <div className="fins_match_text">
                  <img src={engageImage} alt="image" className="mb-1" />
                  <h3>Engage</h3>
                  <p>Connect directly on Zoom.</p>
                </div>
              </div>
              <div className="col-12 text-center mt-3">
                <Link to="/usertype" className="get_start_btn cta_btn d-inline-block" >SIGN UP FOR FREE</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="common_padding bg_blue">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center mb-lg-5 mb-3">
                <h2 className="common_heading clr_white">Explore Sustainability</h2>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 col-6 text-center">
                <div className="category_icon">
                  <div>
                    <img src={airPolImage} alt="Icon" />
                  </div>
                  <p>Air Pollution</p>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 col-6 text-center">
                <div className="category_icon">
                  <div>
                    <img src={biodiversityImg} alt="Icon" />
                  </div>
                  <p>Biodiversity</p>
                </div>
              </div>

              <div className="col-lg-2 col-md-4 col-sm-6 col-6 text-center">
                <div className="category_icon">
                  <div>
                    <img src={energyImage} className="img2" alt="Icon" />
                  </div>
                  <p>Energy</p>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 col-6 text-center">
                <div className="category_icon">
                  <div>
                    <img src={cargonImage} alt="Icon" />
                  </div>
                  <p>Carbon Finance</p>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 col-6 text-center">
                <div className="category_icon">
                  <div>
                    <img src={transportImage} alt="Icon" />
                  </div>
                  <p>Transport</p>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 col-6 text-center">
                <div className="category_icon">
                  <div>
                    <img src={waterImage} className="img2" alt="Icon" />
                  </div>
                  <p>Water</p>
                </div>
              </div>
              <div className="col-12 text-center mt-lg-5 mt-4">
                <a target="_blank" className="get_start_btn cta_btn d-inline-block white_btn" href="https://www.howtooverlap.app">Learn more</a>
              </div>
            </div>
          </div>
        </div>
        <div className="common_padding bg_qulify dot_image">
          <div className="container">
            <div className="col-12 text-center">
              <h2 className="common_heading ">Find Your Match</h2>
            </div>
            <div className="col-12 mt-5">
              <div className="learning_goal">
                <div className="row">
                  <div className="col-12 mb-3">
                    <div className="input-group search_input search-suggestion">
                      <div className="input-group-prepend">
                        <span className="input-group-text"><img src={searchIconImg} alt="search" /></span>
                      </div>
                      {/* <input type="text" className="form-control" placeholder="Search here" /> */}
                      <SearchTags selectedTags={allTags}
                        onAdd={this.handleTagAdd}
                        limitTags={MAGIC_NUMBER.TEN}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg-4 col-md-12">
                    <FormGroup className="floating-label disabled-input">
                      <Select
                        value={selectedDomain}
                        onChange={this.handleDomain}
                        styles={customSelectStyles}
                        options={domainOptions}
                        name="domain"
                        placeholder="Select Domain"
                        className="react-select-box select-box-group"
                        filterOption={createFilter(reactSelectFilters)}
                      />
                      <Label
                        for="SelectDomain"
                        className={selectedDomain ? 'selected' : ''}
                      >
                        Select Domain
                          </Label>
                    </FormGroup>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <FormGroup className="floating-label disabled-input">
                      <Select
                        value={selectedSubDomain}
                        onChange={this.handleSubDomain}
                        styles={customSelectStyles}
                        options={subDomainOptions}
                        isDisabled={subDomainOptions.length === MAGIC_NUMBER.ZERO}
                        name="subDomain"
                        placeholder="Select Sub-Domain"
                        className={`react-select-box select-box-group ${subDomainOptions.length === MAGIC_NUMBER.ZERO ? commonClass : ''
                          }`}
                        filterOption={createFilter(reactSelectFilters)}
                      />
                      <Label
                        for="SelectSub-Domain"
                        className={selectedSubDomain ? 'selected' : ''}
                      >
                        Select Sub-Domain
                        </Label>
                    </FormGroup>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <FormGroup className="floating-label disabled-input">
                      <Select
                        value={selectedCategory}
                        onChange={this.handleCategory}
                        options={categoryOptions}
                        styles={customSelectStyles}
                        isDisabled={categoryOptions.length === MAGIC_NUMBER.ZERO}
                        name="category"
                        placeholder="Select Category"
                        filterOption={createFilter(reactSelectFilters)}
                        className={`react-select-box select-box-group ${categoryOptions.length === MAGIC_NUMBER.ZERO ? commonClass : ''
                          }`}
                      />
                      <Label
                        for="SelectCategory"
                        className={selectedCategory ? 'selected' : ''}
                      >
                        Select Category
                        </Label>
                    </FormGroup>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <FormGroup className="floating-label disabled-input">
                      <Select
                        value={selectedSubCategory}
                        onChange={this.handleSubCategory}
                        options={subCategoryOptions}
                        isDisabled={subCategoryOptions.length === MAGIC_NUMBER.ZERO}
                        styles={customSelectStyles}
                        name="subCategory"
                        placeholder="Select Sub-Category"
                        filterOption={createFilter(reactSelectFilters)}
                        className={`react-select-box select-box-group ${subCategoryOptions.length === MAGIC_NUMBER.ZERO ? commonClass : ''
                          }`}
                      />
                      <Label
                        for="SelectSubCategory"
                        className={selectedSubCategory ? 'selected' : ''}
                      >
                        Select Sub-Category
                        </Label>
                    </FormGroup>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <FormGroup className="floating-label disabled-input">
                      <Select
                        value={selectedTopic}
                        onChange={this.handleTopic}
                        options={topicOptions}
                        isDisabled={topicOptions.length === MAGIC_NUMBER.ZERO}
                        styles={customSelectStyles}
                        name="topic"
                        placeholder="Select Topic"
                        filterOption={createFilter(reactSelectFilters)}
                        className={`react-select-box select-box-group ${topicOptions.length === MAGIC_NUMBER.ZERO ? commonClass : ''
                          }`}
                      />
                      <Label
                        for="SelectTopic"
                        className={selectedTopic ? 'selected' : ''}
                      >
                        Select Topic
                        </Label>
                    </FormGroup>
                  </div>
                  <div className="col-12 text-center mt-3">
                    <FormGroup className="mb-0">
                      <Button
                        color="d-inline-block"
                        className="get_start_btn cta_btn d-inline-block"
                        onClick={() => this.moveToOtherPage('/usertype')}
                        disabled={
                          !selectedDomain ||
                          !selectedSubDomain
                        }
                      >
                        Get Started
                        </Button>
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LandingFooter />
      </div>
    );
  }
}

function mapStateToProps(state: { tags: any; authentication: any }) {
  const { tags: { domains, subDomains, categories, subCategories, topics, activities }, authentication } = state;
  return {
    domainOptions: getUpdatedOptions(domains, 'name'),
    subDomainOptions: getUpdatedOptions(subDomains, 'name'),
    categoryOptions: getUpdatedOptions(categories, 'name'),
    subCategoryOptions: getUpdatedOptions(subCategories, 'name'),
    topicOptions: getUpdatedOptions(topics, 'name'),
    activityOptions: getUpdatedOptions(activities, 'name'),
    isloggedIn: authentication.loggedIn,
  };
}

const connectedHomePage = connect(mapStateToProps)(HomePage);
export { connectedHomePage as HomePage };
