import React, { Fragment } from 'react';
import { ForgotPasswordForm } from './forgotPasswordForm';

class ForgotPasswordContainer extends React.Component {

    public render() {
        return (
            <Fragment>
                <ForgotPasswordForm />
            </Fragment>
        );
    }
}

export { ForgotPasswordContainer };
