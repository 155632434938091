
import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Button,
} from 'reactstrap';
import { Listing } from './listings/listing';
import OpenFieldTypes from 'OpenFieldTypes';
import { Sidebar } from '../sidebar';
import { staticConstants, CSS_ClASSES } from '../../../utils/staticConstants';
import { history } from '../../../helpers';
import queryString from 'query-string';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  role: string;
  location: any;
}

class EventManagementContainer extends React.Component<IProps> {

  public state = {
    activeTab: staticConstants.EVENT_MANAGEMENT_TAB.UPCOMING_EVENTS,
  };

  public componentDidMount() {
    const { location: { search } } = this.props;
    if (!this.props.location.search) {
      history.push({ search: `?tab=${staticConstants.EVENT_MANAGEMENT_TAB.UPCOMING_EVENTS}` });
    } else if (queryString.parse(search).tab) {
      this.setState({ activeTab: queryString.parse(search).tab });
      history.push({ search: `?tab=${queryString.parse(search).tab}` });
    }
  }

  public handleActiveTabs = (activeTab: string) => {
    history.replace({ search: `?tab=${activeTab}` });
    this.setState({ activeTab });
  }

  /**
   * @description
   * render is used to render the html.
   */
  public render() {
    const { activeTab } = this.state;
    const { role } = this.props;
    return (
      <React.Fragment>
        <Sidebar {...this.props} />
        <div className="dashboard-wrapper">
          <h2 className="heading heading-sm roboto-medium text-uppercase">Events</h2>
          <div className="dashboard-content">
            <div className="tab-btn-group white-space-normal">
              <Button
                color="secondary"
                className={`btn btn-50 h-50 tab-btn text-uppercase ${activeTab === staticConstants.EVENT_MANAGEMENT_TAB.UPCOMING_EVENTS ? CSS_ClASSES.btnActive : ''}`}
                onClick={() => this.handleActiveTabs(staticConstants.EVENT_MANAGEMENT_TAB.UPCOMING_EVENTS)}
          >
            Upcoming Events
            </Button>
              <Button
                color="secondary"
                className={`btn btn-50 h-50 tab-btn text-uppercase ${activeTab === staticConstants.EVENT_MANAGEMENT_TAB.PAST_EVENTS ? CSS_ClASSES.btnActive : ''}`}
                onClick={() => this.handleActiveTabs(staticConstants.EVENT_MANAGEMENT_TAB.PAST_EVENTS)}
              >
                Past Events
            </Button>
            </div>
            <Listing activeTab={activeTab} role={role} />
          </div>
        </div>
      </React.Fragment >
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user,
    role: user.role,
  };
}

const connectedEventManagementPage = connect(mapStateToProps)(EventManagementContainer);
export { connectedEventManagementPage as EventManagementContainer };
