import React, { createRef, Dispatch } from 'react';
import { LocationDescriptorObject } from 'history';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import OpenFieldTypes from 'OpenFieldTypes';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import ReeValidate from 'ree-validate';
import { history } from '../../../helpers';
import {
  educations, pageRoutes, messages, reeValidators, locations,
  reactSelectFilters, nameTitle, MAGIC_NUMBER,
  mapNameTitleFromApi, customSelectStyles, staticConstants, getNavToDashboard,
} from '../../../utils/';
import { Header } from '../../shared/header';
import profileImage from './../../../assets/images/profile.png';
import { validator } from './../../../helpers';
import { profileAction } from './redux/actions';
import { IProfile, IUpdateProfileReq, IValidator, IUploadImgaeReq, ILocalUser } from './../../../interfaces';
import { ImageCropperPopup } from '../../shared/modals/imageCropperPopup';
import moment from 'moment';
import Select, { createFilter } from 'react-select';
import * as _ from 'lodash';
import { Sidebar } from '../../shared/sidebar';
import { BreadcrumbRender } from '../../shared/breadcrumbRender/BreadcrumbRender';

validator(ReeValidate);

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  updateProfile: any;
  industries: [];
  location?: LocationDescriptorObject;
  user: ILocalUser;
}

interface IState {
  formData: IProfile;
  profileImageUpload: File;
  errors: any;
  // tagsSuggestions: Array<{ value: string; label: string }>;
  randomString: string;
  isOpenCropModal: boolean;
  image: any;
}

class UpdateProfile extends React.Component<IProps, IState> {

  public validator: IValidator;
  private readonly fileInput = createRef<HTMLInputElement>();

  constructor(props: IProps) {
    super(props);
    let fields = {
      firstName: reeValidators.required_min_1_max_100,
      lastName: reeValidators.required_min_1_max_100,
      education: reeValidators.required_min_3_max_100,
      dateOfBirth: 'required',
      phoneNumber: 'required|min:5|max:15',
      occupation: reeValidators.required_min_3_max_100,
      position: reeValidators.min_3_max_100,
      location: reeValidators.required_min_3_max_100,
      // tags: 'required',
      profileImageUpload: 'verify_profile_image|verify_profile_image_size',
      title: 'required',
    };
    if (props.user.role === staticConstants.ROLE.ORGANIZATION_ADMIN) {
      fields = {
        ...fields, ...{
          address: reeValidators.min_3_max_100,
        },
      };
    }
    this.validator = new ReeValidate(fields);

    this.state = {
      randomString: Math.random().toString(36),
      formData: {
        firstName: '',
        lastName: '',
        education: '',
        dateOfBirth: null,
        phoneNumber: '',
        sector: '',
        occupation: '',
        position: '',
        location: '',
        address: '',
        industry: '',
        title: null,
        sectorId: '',
        industryId: '',
        selectedLocation: null,
        selectedEducation: null,
        selectedSector: null,
        selectedIndustry: null,
        publishLink: '',
      },
      // tagsSuggestions: [],
      profileImageUpload: null,
      errors: this.validator.errors,
      isOpenCropModal: false,
      image: null,
    };
    this.hideModal = this.hideModal.bind(this);
  }

  // public handleTagAdd = (tag: { value: string; label: string }) => {
  //   const ind = this.state.formData.tags.findIndex((t: { [x: string]: string }) => t['value'] === tag['value']);
  //   const tags = [].concat(this.state.formData.tags, tag);
  //   this.setState({ formData: { ...this.state.formData, tags } }, () => {
  //     this.removeErrorAndReValidate('tags');
  //     if (ind > -1) {
  //       const lind = _.findLastIndex(tags, o => o.value === tag.value);
  //       this.handleTagDelete(lind);
  //     }
  //   });
  // }

  // public handleTagDelete = (i: number) => {
  //   const tags = this.state.formData.tags.slice(0);
  //   tags.splice(i, 1);
  //   this.setState({ formData: { ...this.state.formData, tags } }, () => {
  //     this.removeErrorAndReValidate('tags');
  //   });
  // }

  public componentDidMount = () => {
    const { dispatch } = this.props;
    dispatch(profileAction.getProfile());
    dispatch(profileAction.getIndustries());
    dispatch(profileAction.getCategories());
  }

  public componentWillReceiveProps(nextProps: {
    updateProfile: {
      profile: IProfile;
      updateProfile: any;
      uploadingImage: boolean;
    };
  }) {
    const newProfile = nextProps.updateProfile.profile;
    if (newProfile && !nextProps.updateProfile.uploadingImage &&
      nextProps.updateProfile.updateProfile) {
      const formData = {
        firstName: newProfile.firstName,
        lastName: newProfile.lastName,
        education: newProfile.education,
        dateOfBirth: newProfile.dateOfBirth ? new Date(newProfile.dateOfBirth) : null,
        phoneNumber: newProfile.phoneNumber,
        sector: newProfile.sector,
        occupation: newProfile.occupation,
        position: newProfile.position,
        location: newProfile.location,
        address: newProfile.address,
        industry: newProfile.industry,
        publishLink: newProfile.publishLink,
        // tags: newProfile.tags ? mapTagsFromAPIRequest(newProfile.tags) : [],
        title: mapNameTitleFromApi(newProfile.title) || null,
        sectorId: newProfile.sectorId,
        industryId: newProfile.industryId,
        selectedLocation: newProfile.location ?
          { label: newProfile.location, value: newProfile.location } : null,
        selectedEducation: newProfile.education ?
          { label: newProfile.education, value: newProfile.education } : null,
        selectedSector: newProfile.sector ?
          { label: newProfile.sector, value: newProfile.sector } : null,
        selectedIndustry: newProfile.industry ?  { label: newProfile.industry, value: newProfile.industry } : null,
      };

      this.setState({ formData });
    }
    this.hideModal();
  }

  public handleChange = (event: any, type: string = null) => {
    if (type) {
      switch (type) {
        case 'eventLocation':
          this.setState(
            {
              formData: {
                ...this.state.formData,
                location: event['value'],
                selectedLocation: {
                  value: event['value'],
                  label: event['label'],
                },
              },
            },
            () => {
              this.removeErrorAndReValidate('location');
            },
          );
          return;
        case 'eventEducation':
          this.setState(
            {
              formData: {
                ...this.state.formData,
                education: event['value'],
                selectedEducation: {
                  value: event['value'],
                  label: event['label'],
                },
              },
            },
            () => {
              this.removeErrorAndReValidate('education');
            },
          );
          return;
        case 'eventSector':
          this.setState(
            {
              formData: {
                ...this.state.formData,
                sector: event['label'],
                selectedSector: {
                  value: event['value'],
                  label: event['label'],
                },
              },
            },
            () => {
              this.removeErrorAndReValidate('sector');
            },
          );
          return;
        case 'eventIndustry':
          this.setState(
            {
              formData: {
                ...this.state.formData,
                industry: event['label'],
                selectedIndustry: {
                  value: event['value'],
                  label: event['label'],
                },
              },
            },
            () => {
              this.removeErrorAndReValidate('industry');
            },
            );
          return;
      }
    }
    const { name, value } = event.target;
    this.setState({ formData: { ...this.state.formData, [name]: value } }, () => {
      name !== 'publishLink' && this.removeErrorAndReValidate(name);
    });

  }

  public handleTitleChange = (selectedOption) => {
    const { formData } = this.state;
    formData.title = selectedOption;
    this.setState({ formData }, () => this.removeErrorAndReValidate('title'));
  }

  public handleDOBChange = (date: Date) => {
    Object.assign(this.state, { formData: { ...this.state.formData, dateOfBirth: date } });
    this.removeErrorAndReValidate('dateOfBirth');
  }
  public handlePhoneChange = (num: { formattedValue: string }) => {
    this.setState({
      formData: {
        ...this.state.formData,
        phoneNumber: num.formattedValue.replace('-', ''),
      },
    },            () => {
      this.removeErrorAndReValidate('phoneNumber');
    });

  }

  public moveToDashboardPage = () => {
    history.push(`${pageRoutes.ORGADMIN.PATH}${pageRoutes.ORGADMIN.DASHBOARD}`);
  }

  // public handleTimezoneChange = (timeZone: string) => {
  //   Object.assign(this.state, { formData: { ...this.state.formData, timeZone } });
  //   this.removeErrorAndReValidate('timeZone');
  // }

  public removeErrorAndReValidate = (name: string) => {
    const { errors } = this.validator;
    errors.remove(name);
    this.validator.validate(name, this.state.formData[name])
      .then(() => {
        this.setState({ errors });
      });
  }

  public validateAndSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const { errors } = this.validator;
    this.validator.validateAll(this.state.formData)
      .then((success: boolean) => {
        if (success) {
          this.handleSubmit();
        } else {
          this.setState({ errors });
        }
      });
  }

  public onKeyPress = (event) => {
    if (event.which === MAGIC_NUMBER.THIRTEEN) {
      event.preventDefault();
    }
  }

  public handleSubmit = () => {

    const { dispatch, user } = this.props;
    const formData = this.state.formData;
    let data: IUpdateProfileReq = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      education: formData.selectedEducation.value,
      dateOfBirth: formData.dateOfBirth.toDateString(),
      occupation: formData.occupation,
      position: formData.position,
      location: formData.selectedLocation.value,
      title: formData.title.value,
      phoneNumber: formData.phoneNumber,
    };

    if (user.role === staticConstants.ROLE.ORGANIZATION_ADMIN) {
      data = {
        ...data, ...{
          address: formData.address,
          publishLink: formData.publishLink,
        },
      };
    }
    const redirectURI = this.props.location && this.props.location.state ? this.props.location.state['redirectUri'] : null;
    dispatch(profileAction.updateProfile(data, redirectURI));
  }
  public handleUploadImageSubmit = (e) => {
    e.preventDefault();
    const profileImageUpload = this.fileInput.current.files[0];
    const { errors } = this.validator;
    Object.assign(this.state, { profileImageUpload });
    errors.remove('profileImageUpload');
    this.validator.validate('profileImageUpload', this.state.profileImageUpload)
      .then((success: boolean) => {
        if (success) {
          this.setState({
            isOpenCropModal: true,
            image: profileImageUpload,
          });
        } else {
          this.setState({ errors });
        }
      });
  }

  public savedCroppedImage = (data) => {
    const up: IUploadImgaeReq = { type: 'image/png', upload: data };
    const { dispatch } = this.props;
    dispatch(profileAction.uploadProfileImage(up));
  }

  public hideModal() {
    this.setState({ isOpenCropModal: false, randomString: Math.random().toString(36) });
  }

  public render() {
    const { formData, errors, isOpenCropModal, image } = this.state;
    const { updateProfile, location: { state }, user: { role } } = this.props;
    const getErrClass = (field: string) => {
      return errors.has(field) ? ' has-error' : '';
    };
    return (
      <>
        <Header />
        {state && state['showSidebarNav'] && <Sidebar {...this.props} />}
        <div className="dashboard-wrapper">
          <div className="create-profile-wrapper p-0 w-100">
            {state && state['breadcrumb'] ?

              <BreadcrumbRender breadcrumb={state['breadcrumb']} /> :
              <h2 className="heading heading-sm text-uppercase roboto-bold mb-4">
                Complete Profile</h2>}
            <div className="profile-wrapper dashboard-content">
              <Form noValidate className="profile-image-wrapper flex-wrap">
                <span className="image-upload">
                  <img
                    src={updateProfile && updateProfile.profile &&
                      updateProfile.profile.profileImage ?
                      updateProfile.profile.profileImage : profileImage}
                    className="img-cover"
                    alt="profile"
                  />
                  <label className="file-icon icon-edit">
                    <input
                      key={this.state.randomString}
                      ref={this.fileInput}
                      type="file"
                      name="profileImageUpload"
                      onChange={this.handleUploadImageSubmit}
                    />
                  </label>
                </span>

                {role !== staticConstants.ROLE.PROFESSIONAL &&
                  <FormGroup className="floating-label disabled-input disabled">
                    <label>Team Name</label>
                    <p>{updateProfile && updateProfile.profile &&
                      updateProfile.profile.organizationName}</p>
                  </FormGroup>}
                <div className={`hint-text ${role === staticConstants.ROLE.PROFESSIONAL ? 'prof-hint-text' : ''}`}>
                  <p>Image size should be maximum 5mb. <br />File format: jpeg, png.</p>
                </div>
                {
                  <div className="error-text">{errors.first('profileImageUpload')}</div>
                }
              </Form>
              <div className="basic-details">
                <h2>
                  {role === staticConstants.ROLE.PROFESSIONAL ? 'Personal Information' : 'Team Admin Details'}
                </h2>
                <Form onSubmit={this.validateAndSubmit} onKeyPress={this.onKeyPress}>
                  <div className="form-wrapper">
                    <Row>
                      <Col xs="12" sm="6">
                        <Row>
                          <Col xs="4">
                            <FormGroup className={`floating-label disabled-input ${getErrClass('title')}`}>

                              <Select
                                name="title"
                                styles={customSelectStyles}
                                value={formData.title}
                                onChange={this.handleTitleChange}
                                options={nameTitle}
                                id="title"
                                placeholder="Title"
                                className="react-select-box select-box-group"
                              />
                              <Label for="eventType" className={formData.title ? 'selected' : ''}>Title</Label>
                              {errors.has('title') &&
                                <div className="error-text">
                                  {
                                    errors.first('title')}
                                </div>
                              }
                            </FormGroup>
                          </Col>
                          <Col xs="8">
                            <FormGroup className={`floating-label ${getErrClass('firstName')}`}>
                              <Input
                                type="text"
                                className="form-control"
                                name="firstName"
                                id="firstName"
                                placeholder="First Name"
                                value={formData.firstName}
                                onChange={this.handleChange}
                              />
                              <Label for="firstName">First Name</Label>
                              {errors.has('firstName') &&
                                <div className="error-text">
                                  {
                                    errors.first('firstName').replace('firstName', 'first name')}
                                </div>
                              }
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs="12" sm="6">
                        <FormGroup className={`floating-label ${getErrClass('lastName')}`}>
                          <Input
                            type="text"
                            className="form-control"
                            name="lastName"
                            id="lastName"
                            placeholder="Last Name"
                            value={formData.lastName}
                            onChange={this.handleChange}
                          />
                          <Label for="lastname">Last Name</Label>
                          {errors.has('lastName') &&
                            <div className="error-text">
                              {
                                errors.first('lastName').replace('lastName', 'last name')}</div>
                          }
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="6">
                        <FormGroup className={`floating-label disabled-input ${getErrClass('education')}`}>
                          <Select
                            value={formData.selectedEducation}
                            onChange={e => this.handleChange(e, 'eventEducation')}
                            options={educations}
                            styles={customSelectStyles}
                            placeholder="Highest Level of Education"
                            className="react-select-box select-box-group"
                            filterOption={createFilter(reactSelectFilters)}
                          />
                          <Label for="education" className={formData.selectedEducation ? 'selected' : ''}>Highest Level of Education</Label>
                          {errors.has('education') &&
                            <div className="error-text">{errors.first('education')}</div>
                          }
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="6">
                        <FormGroup className={`floating-label disabled-input ${getErrClass('dateOfBirth')}`}>
                          <DatePicker
                            placeholderText="Date of Birth"
                            className="form-control"
                            dateFormat="MMM dd, yyyy"
                            onChange={this.handleDOBChange}
                            selected={formData.dateOfBirth}
                            maxDate={moment().toDate()}
                            dropdownMode="select"
                            showYearDropdown
                            showMonthDropdown
                          />

                          <Label for="dateOfBirth" className={formData.dateOfBirth ? 'selected' : ''}>Date of Birth</Label>
                          <span className="icon icon-calendar" />
                          {errors.has('dateOfBirth') &&
                            <div className="error-text">{
                              errors.first('dateOfBirth').replace('dateOfBirth', 'date of birth')}
                            </div>
                          }
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="6">
                        <FormGroup className="floating-label disabled-input disabled">
                          <label>Email</label>
                          <p>{updateProfile && updateProfile.profile &&
                            updateProfile.profile.email}</p>
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="6">
                        <FormGroup className={`floating-label ${getErrClass('phoneNumber')}`}>
                          <NumberFormat
                            prefix={'+'}
                            className="form-control"
                            name="phoneNumber"
                            mask=""
                            maxLength={MAGIC_NUMBER.FIFTEEN}
                            placeholder="Phone Number"
                            onValueChange={this.handlePhoneChange}
                            value={formData.phoneNumber}
                          />
                          <Label for="phoneNumber">Phone Number</Label>
                          {errors.has('phoneNumber') &&
                            <div className="error-text">
                              {messages.invalidPhoneNumber}
                            </div>
                          }
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="6">
                        <FormGroup className={`floating-label ${getErrClass('occupation')}`}>
                          <Input
                            type="text"
                            className="form-control"
                            name="occupation"
                            id="occupation"
                            placeholder="Occupation"
                            onChange={this.handleChange}
                            value={formData.occupation}
                          />
                          <Label for="occupation">Occupation</Label>
                          {errors.has('occupation') &&
                            <div className="error-text">{errors.first('occupation')}</div>
                          }
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="6">
                        <FormGroup className={`floating-label ${getErrClass('position')}`}>
                          <Input
                            type="text"
                            className="form-control"
                            name="position"
                            id="position"
                            placeholder="Position (Optional)"
                            onChange={this.handleChange}
                            value={formData.position}
                          />
                          <Label for="position">Position (Optional)</Label>
                          {errors.has('position') &&
                            <div className="error-text">{errors.first('position')}</div>
                          }
                        </FormGroup>
                      </Col>
                      {role === staticConstants.ROLE.ORGANIZATION_ADMIN &&
                        <Col xs="12" sm="6">
                          <FormGroup className="floating-label disabled-input disabled">
                            <label>Number of Licences</label>
                            <p>{updateProfile && updateProfile.profile &&
                              updateProfile.profile.licence}</p>
                          </FormGroup>
                        </Col>}
                      <Col xs="12" sm="6">
                        <FormGroup className={`floating-label disabled-input ${getErrClass('location')}`}>
                          <Select
                            value={formData.selectedLocation}
                            onChange={e => this.handleChange(e, 'eventLocation')}
                            options={locations}
                            placeholder="Location"
                            isSearchable
                            styles={customSelectStyles}
                            filterOption={createFilter(reactSelectFilters)}
                            className="react-select-box select-box-group"
                          />
                          <Label for="location" className={formData.selectedLocation ? 'selected' : ''}>Location</Label>
                          {errors.has('location') &&
                            <div className="error-text">{errors.first('location')}</div>
                          }
                        </FormGroup>
                      </Col>
                      {role === staticConstants.ROLE.ORGANIZATION_ADMIN &&
                        <Col xs="12" sm="6">
                          <FormGroup className="floating-label disabled-input">
                            <Input
                              type="text"
                              className="form-control"
                              name="address"
                              id="address"
                              placeholder="Address"
                              onChange={this.handleChange}
                              value={formData.address}
                            />
                            <Label for="address">Address</Label>
                            {errors.has('address') &&
                              <div className="error-text">{errors.first('address')}</div>
                            }
                          </FormGroup>
                        </Col>
                      }
                      {role === staticConstants.ROLE.ORGANIZATION_ADMIN &&
                        <Col xs="12" sm="6">
                          <FormGroup className="floating-label">
                            <Input
                              type="text"
                              className="form-control"
                              name="publishLink"
                              id="publishLink"
                              placeholder="Website URL (Optional)"
                              onChange={this.handleChange}
                              value={formData.publishLink}
                            />
                            <Label for="work"> Website URL (Optional)</Label>
                          </FormGroup>
                        </Col>
                      }
                    </Row>
                  </div>
                  {state && state['breadcrumb'] ?
                    <div className="card-footer d-flex align-items-center justify-content-end">
                      <Button className="btn btn-regular mr-2" onClick={() => history.goBack()}>Cancel</Button>
                      <Button color="primary" className="btn btn-rg">Update</Button>
                    </div>
                    :
                    <div className="card-footer d-flex align-items-center">
                      <Link
                        to={getNavToDashboard()}
                        className="skip-btn mr-auto"
                      >
                        Skip
                                            </Link>
                      <Button color="primary" className="btn btn-rg">Save</Button>
                    </div>
                  }
                </Form>
              </div>
            </div>
          </div>
          <ImageCropperPopup
            isOpenModal={isOpenCropModal}
            image={image}
            savedCroppedImage={this.savedCroppedImage}
            hideModal={this.hideModal}
          />
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const { user } = state.authentication;
  const { updateProfile } = state;
  return {
    updateProfile,
    user,
  };

}
const connectedCreatePasswordForm = connect(mapStateToProps)(UpdateProfile);
export { connectedCreatePasswordForm as UpdateProfile };
