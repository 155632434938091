import OpenFieldTypes from 'OpenFieldTypes';
import { userServices } from './../../../../../services';
import { alertActions } from './../../../../alert/redux/actions';
import { actionTypes } from './types';

export const sendFeedbackAction = {
  sendFeedback,
};

function sendFeedback(feedback: string) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request());

    userServices.sendFeedback(feedback)
      .then(
        (user) => {
          dispatch(success(user));
          localStorage.setItem('feedback', 'true');
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request() { return { type: actionTypes.SEND_FEEDBACK }; }
  function success(data: string) { return { data, type: actionTypes.SEND_FEEDBACK_SUCCESS }; }
  function failure(error: string) { return { error, type: actionTypes.SEND_FEEDBACK_FAILURE }; }
}
