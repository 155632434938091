import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Pagination from 'react-js-pagination';
import { FilterComponent } from '../../../shared/filter/filterComponent';
import { appPlanAction } from '../redux/action';
import OpenFieldTypes from 'OpenFieldTypes';
import {
  deletePlan, MAGIC_NUMBER
} from './../../../../utils';
import { ConfirmatioPopup } from '../../../shared/modals/confirmationPopup';
import { PlanModal } from '../addPlanModal';
import { Plans } from './plans';
import {
    Button,
  } from 'reactstrap';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  customizedPlan: object;
}

class ListingContainer extends React.Component<IProps> {

  /**
   * @description getDerivedStateFromProps is called when there in change in property
   * @param fields {Object} props
   */
  public static getDerivedStateFromProps(props: IProps) {
    if (props.customizedPlan && props.customizedPlan['list']) {
      return {
        customizedPlan: props.customizedPlan,
      };
    }
    return null;
  }

  public state = {
    items: MAGIC_NUMBER.TEN,
    activePage: 1,
    sort: '',
    sortBy: 0,
    customizedPlan: {
      count: 0,
      list: [],
      planListSuccess: false,
    },
    displayDeletePopup: false,
    modalPopupObj: {},
    productId: '',
    reqObj: {},
    planModalOpen: false,
    editObj: {},
    title: '',
  };

  /**
   * @description componentDidMount is called when component is loaded
   * call appPlanAction.getPlanListing to fetch plan listings
   */
  public componentDidMount() {
    const { items, activePage } = this.state;
    this.props.dispatch(appPlanAction.getPlanListing({
      page: Number(activePage),
      limit: items,
      isPagination: true,
    }));
  }

  /**
   * @description fetchAppPlanData is used on initial load
   */
  public fetchAppPlanData(pageno: number) {
    const { sort, sortBy } = this.state;
    let reqObj = {
      page: pageno,
      limit: this.state.items,
      isPagination: true,
      key: this.state.title,
    };
    if (sort) {
      reqObj = { ...reqObj, ...{ sort, sortBy } };
    }
    this.props.dispatch(appPlanAction.getPlanListing(reqObj));
  }

  /**
   * @description
   * sortData is used for sorting purpose.
   * @param fields {Number} sort
   * @param fields {String} sortBy
   */
  public sortData(sort: any, sortBy: any) {
    this.setState({ ...this.state, sort, sortBy },
                  () => this.fetchAppPlanData(this.state.activePage || 1));
  }

  /**
   * @description
   * handleChange is used to set the value on state from the input.
   * @param fields {Object} e
   * @param fields {String} key
   */
  public handleChange = (value, key: string) => {
    this.setState({ ...this.state, [key]: value },
                  () => { this.fetchAppPlanData(this.state.activePage || 1); });
  }

  /**
   * @description
   * handlePageChange is called someone click on the pagination.
   * @param fields {Number} pageno
   */
  public handlePageChange = (pageno: number) => {
    this.setState({
      activePage: pageno,
    },            () => this.fetchAppPlanData(pageno));
  }

  /**
   * @description
   * showDeletePopup is used show confirmation popup.
   * @param fields {String} id
   */
  public showDeletePopup = (id: string) => {
    const { sort, sortBy } = this.state;
    const modalPopupObj = deletePlan;
    let reqObj = {
      page: Number(this.state.activePage),
      limit: this.state.items,
      key: this.state.title,
    };
    if (sort) {
      reqObj = { ...reqObj, ...{ sort, sortBy } };
    }
    this.setState({
      displayReplyPopup: false,
      displayDeletePopup: true,
      productId: id,
      reqObj,
      modalPopupObj,
    });
  }

  /**
   * @description
   * showEditPopup is used to show edit popup.
   * @param fields {String} id
   */
  public showEditPopup = (plan: object) => {
    this.setState({
      planModalOpen: true,
      editObj: plan,
    });
  }

  /**
   * @description
   * deleteRequest is called when someone click on the ok button
   */
  public deleteRequest = () => {
    const { productId, items, activePage } = this.state;
    const reqObj = {
      page: Number(activePage),
      limit: items,
      isPagination: true,
    };
    this.props.dispatch(appPlanAction.deletePlan(productId, reqObj));
    this.setState({
      displayDeletePopup: false,
      modalPopupObj: {},
      productId: '',
    });
  }

  /**
   * @description
   * editPlanRequest is called when someone click on the edit button
   */
  public editPlanRequest = (reqObj: object) => {
    const { items, activePage } = this.state;
    const listReqObj = {
      page: Number(activePage),
      limit: items,
      isPagination: true,
    };
    this.props.dispatch(appPlanAction.editPlan(reqObj, listReqObj));
    this.setState({
      planModalOpen: false,
      editObj: {},
      productId: '',
    });
  }

  /**
   * @description open the plan modal on the click of add plan button
   */
  public addPlanPopup = (faqId: any) => {
    this.setState({
      planModalOpen: true,
      editObj: {},
    });
  }

  public addPlan = (reqObj: any) => {
    const { items, activePage } = this.state;
    const listReqObj = {
      page: Number(activePage),
      limit: items,
      isPagination: true,
    };
    this.props.dispatch(appPlanAction.addPlan(reqObj, listReqObj));
    this.setState({
      planModalOpen: false,
      editObj: {},
    });
  }

  /**
   * @description
   * renderRequestListing is called to render the request listings
   */
  public renderRequestListing = () => {
    const { list } = this.state.customizedPlan;
    return _.map(list, (item) => {
      return (
        <Plans planList={item} onDelete={this.showDeletePopup} onEdit={this.showEditPopup} />
      );
    });
  }

  /**
   * @description
   * render is used to render the html.
   */
  public render() {
    const { sort, sortBy, customizedPlan, items, activePage, displayDeletePopup, modalPopupObj, editObj, planModalOpen } = this.state;
    const filterContent = ['search'];
    let minDisplay;
    let maxDisplay;
    if (customizedPlan.count === MAGIC_NUMBER.ZERO) {
      maxDisplay = 0;
      minDisplay = 0;
    } else if (customizedPlan.count <= items) {
      maxDisplay = customizedPlan.count;
      minDisplay = 1;
    } else {
      maxDisplay = (Number(activePage) * Number(items)) < customizedPlan.count
        ? (Number(activePage) * Number(items)) : customizedPlan.count;
      minDisplay = ((Number(activePage) - 1) * Number(items)) + 1;
    }
    return (
      <React.Fragment>
        <div className="filter-wrapper">
          <FilterComponent
            filterContent={filterContent}
            handleFilter={this.handleChange}
          />
          <Button type="button" color="Primary" className="btn btn-primary round-circle add-filter-btn ml-auto add-faq-btn" onClick={this.addPlanPopup}>
               Add Plan
          </Button>
          <PlanModal editPlan={editObj} onEdit={this.editPlanRequest} isOpenModal={planModalOpen} addPlan={this.addPlan} hideModal={() => {
            this.setState({
              planModalOpen: false,
              editObj: {},
            });
          }} />
        </div>
        <div className="table-wrapper">
          <div className="flex-table">
            <div className="table-head" >
              <div className="table-row">
                <div className="table-col table-col-20">
                  <span className="sorting-icon">Plan Name
                      <strong>
                      <em
                        className={(sort === 'productName' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                        onClick={() => { this.sortData('productName', MAGIC_NUMBER.ONE); }}
                      />
                      <em
                        className={(sort === 'productName' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                        onClick={() => { this.sortData('productName', MAGIC_NUMBER.FOUND_INDEX); }}
                      />
                    </strong>
                  </span>
                </div>
                <div className="table-col table-col-15">
                  <span className="sorting-icon">Billing Cycle Period</span>
                </div>
                <div className="table-col table-col-15">
                  <span className="sorting-icon">Add On Amount</span>
                </div>
                <div className="table-col table-col-15">
                  <span className="sorting-icon">Licence Amount</span>
                </div>
                <div className="table-col table-col-15">
                  <span className="sorting-icon">Member Amount</span>
                </div>
                <div className="table-col table-col-20 text-center">
                  <span>Action</span>
                </div>
              </div>
            </div>
            <div className="table-body">
              {this.renderRequestListing()}
              {customizedPlan && customizedPlan.planListSuccess && customizedPlan.count === MAGIC_NUMBER.ZERO ?
                <div className="table-row table-no-data">
                  No Results Found.
                    </div>
                : ''}
            </div>
            {
              (customizedPlan && customizedPlan.count > MAGIC_NUMBER.TEN) &&
              <div className="table-footer">
                <React.Fragment>
                  <p>Showing {minDisplay} - {maxDisplay} of {customizedPlan.count}</p>
                  <div className="pagination-wrapper">
                    <Pagination
                      hideFirstLastPages
                      activePage={activePage}
                      itemsCountPerPage={items}
                      totalItemsCount={customizedPlan.count}
                      pageRangeDisplayed={MAGIC_NUMBER.FIVE}
                      onChange={this.handlePageChange}
                    />
                  </div>
                </React.Fragment>
              </div>
            }

          </div>
        </div>
        {displayDeletePopup &&
          <ConfirmatioPopup
            isOpenModal={displayDeletePopup}
            modalPopupObj={modalPopupObj}
            hideModal={() => this.setState({ displayDeletePopup: false })}
            onClickYes={this.deleteRequest}
          />
        }
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    customizedPlan: state.customizedPlan,
  };
}

const connectedListingContainer = connect(mapStateToProps)(ListingContainer);
export { connectedListingContainer as ListingContainer };
