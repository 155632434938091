import { bidServices } from './../../../../services';
import { endLoading, startLoading } from './../../../loader/redux/actions';
import { actionTypes } from './types';
import OpenFieldTypes from 'OpenFieldTypes';
import { IMakeBid, IReferBid, IRescheduleStatus } from './../../../../interfaces';
import { handleError } from './../../../../utils';
import { alertActions } from './../../../alert/redux/actions';
import { profileAction } from '../../../shared/updateProfile/redux/actions';
import { history } from '../../../../helpers';
import { GOAL_BID_COUNT_STATUS } from '../../../../utils';

export const bidAction = {
  getBids,
  makeBid,
  referBid,
  addToFavourite,
  getBidDetail,
  cancelBid,
  generateReport,
  resetGenerateReport,
  resetBidList,
  rescheduleRequest,
};

/**
 * @param isLoader handle condition (show and hider loader)
 */
function getBids(bid: any, isLoader = false) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    !isLoader && dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request(bid));
    bidServices.getBids(bid)
      .then(
        (bids) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(bids, bid.isPagination));
        },
        (error) => {
          handleError(dispatch, failure, error.toString);
        },
      );
  };

  function request(data: string) { return { type: actionTypes.BID_LIST_REQUEST, data }; }
  function success(data: any, isPagination: boolean) { return { type: actionTypes.BID_LIST_SUCCESS, data, isPagination }; }
  function failure(error: any) { return { type: actionTypes.BID_LIST_FAILURE, error }; }
}

function generateReport(report: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request(report));
    bidServices.getBids(report)
      .then(
        (result) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(result));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request(data: string) { return { type: actionTypes.GENERATE_REPORT_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.GENERATE_REPORT_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.GENERATE_REPORT_FAILURE, error }; }
}

function resetGenerateReport() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch({ type: actionTypes.RESET_GENERATE_REPORT });
  };
}

function makeBid(bid: IMakeBid, reqObj: any = null, isFromDetail = false) {
  const user = JSON.parse(localStorage.getItem('user'));
  const queryObj = { userId: user._id, attribute: GOAL_BID_COUNT_STATUS.BIDS };
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request(bid));
    bidServices.makeBid(bid)
      .then(
        (bids) => {
          dispatch(endLoading('MAIN_LOADER'));
          if (isFromDetail) {
            dispatch(successDetail(bids));
          } else {
            dispatch(success(bids));
          }
          dispatch(alertActions.successAlert(bids.message));
          if (reqObj) {
            dispatch(getBids(reqObj));
          }
          dispatch(profileAction.getCountForFeedBack(queryObj));
        },
        (error) => {
          handleError(dispatch, failure, error);
        },
      );
    function request(data: IMakeBid) { return { type: actionTypes.MAKE_BID_REQUEST, data }; }
    function success(data: any) { return { type: actionTypes.MAKE_BID_SUCCESS, data }; }
    function successDetail(data: any) { return { type: actionTypes.MAKE_BID_SUCCESS_DETAIL, data }; }
    function failure(error: string) { return { type: actionTypes.MAKE_BID_FAILURE, error }; }
  };
}

function addToFavourite(favourite: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request(favourite.bidId));
    const isFromDetailPage = favourite.isFromDetailPage;
    delete favourite.isFromDetailPage;
    bidServices.addToFavourite(favourite)
      .then(
        (fav) => {
          dispatch(endLoading('MAIN_LOADER'));
          if (fav) {
            fav['bidId'] = favourite['bidId'];
          }
          if (!isFromDetailPage) {
            fav['activeTab'] = favourite['activeTab'];
            dispatch(success(fav));
          } else {
            dispatch(successDetail(fav));
          }
        },
        (error) => {
          handleError(dispatch, failure, error.toString);
        },
      );
  };

  function request(data: string) { return { type: actionTypes.ADD_FAVOURITE_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.ADD_FAVOURITE_SUCCESS, data }; }
  function successDetail(data: any) { return { type: actionTypes.ADD_FAVOURITE_SUCCESS_DETAIL, data }; }
  function failure(error: any) { return { type: actionTypes.ADD_FAVOURITE_FAILURE, error }; }
}

/**
 * @description function to get bid detail according to bid id
 * @param {object} bid contain selected bid detail
 */
function getBidDetail(bid: { id: string }) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    bidServices.getBidDetail(bid)
      .then(
        (bids) => {
          dispatch(endLoading('MAIN_LOADER'));
          if (bids && bids['data']) {
            bids['data']['_id'] = bid['id'];
          }
          dispatch(success(bids));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };
  function success(data: object) { return { type: actionTypes.BID_DETAILS_SUCCESS, data }; }
  function failure(error: object) { return { type: actionTypes.BID_DETAILS_FAILURE, error }; }
}

function cancelBid(id: object, reqObj: any = null) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    bidServices.cancelBid(id)
      .then(
        (data) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(alertActions.successAlert(data['message']));
          if (reqObj) dispatch(getBids(reqObj));
          else history.goBack();
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };
}

function resetBidList() {
  return ({ type: actionTypes.RESET_BID_LIST });
}

function referBid(referData: IReferBid) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request(referData));
    bidServices.referBid(referData)
      .then(
        (data) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(data));
          dispatch(alertActions.successAlert(data.message));
        },
        (error) => {
          handleError(dispatch, failure, error);
        },
      );
    function request(data: IReferBid) { return { type: actionTypes.REFER_BID_REQUEST, data }; }
    function success(data: any) { return { type: actionTypes.REFER_BID_SUCCESS, data }; }
    function failure(error: string) { return { type: actionTypes.REFER_BID_FAILURE, error }; }
  };
}

function rescheduleRequest(bidData: IRescheduleStatus) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    bidServices.rescheduleRequest(bidData)
      .then(
        (data) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(getBidDetail({ id: String(bidData['bidId']) }));
          dispatch(alertActions.successAlert(data.message));
        },
        (error) => {
          handleError(dispatch, failure, error);
        },
      );
    function failure(error: string) { return { type: actionTypes.RESCHEDULE_FAILURE, error }; }
  };
}
