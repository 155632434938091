import React from 'react';

class Footer extends React.Component {

  public render() {
    return (
       <footer className="page-footer">©2021 Overlap Group Inc. All Rights Reserved.</footer>
    );
  }
}

export { Footer };
