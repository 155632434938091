import React, { Component, Dispatch } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import OpenFieldTypes from 'OpenFieldTypes';
import { history } from '../../../helpers';
import paymentIcon from './../../../assets/images/icons/payment.svg';
import { expertPaymentAction } from './redux/actions';
import { manualPaymentActions } from './../manulaPayments/redux/action';
import { ErrorPopup } from '../../shared/modals/errorPopup';
import { IBankInfo, ILocalUser, IViewProfile } from './../../../interfaces';
import { formatCurrency } from '../../../utils/common';
import { MAGIC_NUMBER, withdrawalProcessed } from '../../../utils';
import { ConfirmatioPopup } from '../../shared/modals/confirmationPopup';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  expertIncome: {
    totalReceieved: number;
    totalPending: number;
    totalRequested: number;
    totalProcessed: number;
  };
  user: ILocalUser;
  viewProfile: IViewProfile;
  bankInfo: IBankInfo;
}

interface IState {
  displayErrorPopup: boolean;
  displayWithdrawalPopup: boolean;
  modalPopupObj: {};
}

class WithdrawStripeRegion extends Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = { displayErrorPopup: false, displayWithdrawalPopup: false, modalPopupObj: {} };
  }
  public componentDidMount() {
    this.props.dispatch(expertPaymentAction.getExpertIncome());
    this.props.dispatch(manualPaymentActions.getBankInfo());
  }

  public withdrawAmount = () => {
    const { dispatch, user, bankInfo } = this.props;
    if (!Boolean(user.isStripeAccount) && !bankInfo['region']) {
      this.setState({ displayErrorPopup: true });
    } else {
      user.isStripeAccount && dispatch(expertPaymentAction.withdrawAmount());
      bankInfo['region'] && dispatch(expertPaymentAction.withdrawNonStripe());
      this.setState({ displayWithdrawalPopup: false, modalPopupObj: {} });
    }
  }

  public renderWithDrawText = () => {
    const { user, bankInfo } = this.props;
    if (user.isStripeAccount) {
      return <p className="withdraw-text">
    Funds become available at the completion of an event. You’ll be
    able to withdraw as soon as you reach a minimum threshold of $100. Contact
    <a href="mailto:systemadmin@ovlp.app"> systemadmin@ovlp.app</a> if you have any questions.
  </p>;
    }
    return <><p className="withdraw-text">
   You'll be able to withdraw as soon as you reach the minimum threshold which is $100
</p>
<p className="withdraw-text">
   <strong>Note:</strong> If withdrawal is requested before 20th of each month, the amount will be included in the current month. If requested with 48hrs or after the 20th, they
   will be included in the next month's pay out.
</p>
</>;
  }

  /**
   * @description
   * showWithdrawalPopup is used show withdrawal popup.
   */
  public showWithdrawalPopup = () => {
    const modalPopupObj = withdrawalProcessed;
    this.setState({
      displayWithdrawalPopup: true,
      modalPopupObj,
    });
  }

  public render() {
    const { expertIncome: { totalReceieved, totalPending, totalRequested, totalProcessed }, user, viewProfile, bankInfo } = this.props;
    const { displayWithdrawalPopup, modalPopupObj } = this.state;
    const getAvailableIncome = () => +(totalReceieved - totalRequested - totalProcessed).toFixed(MAGIC_NUMBER.TWO);
    return (
      <>
        <div >
          <div className="dashboard-content shadow">
            <div className="withdraw-content withdraw-content-col-2">
              <div  className="withdraw-box">
                <div className="view-profile-box flex-wrap p-0">
                  <div className="user-profile-details">
                    <h4>Total Balance</h4>
                    <span className="profile-picture-view-only payment-card-icon">
                      <img src={paymentIcon}
                        alt="payment icon" />
                    </span>
                    <div className="profile-email-view-only text-truncate">
                      <h3 className="text-truncate text-lg">
                        <em>$</em>
                        {formatCurrency(+totalPending.toFixed(MAGIC_NUMBER.TWO))}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div  className="withdraw-box">
                <div className="view-profile-box flex-wrap p-0">
                  <div className="user-profile-details">
                    <h4>Available today</h4>
                    <span className="profile-picture-view-only payment-card-icon">
                      <img src={paymentIcon}
                        alt="payment icon" />
                    </span>
                    <div className="profile-email-view-only text-truncate">
                      <h3 className="text-truncate text-lg">
                        <em>$</em>
                        {formatCurrency(getAvailableIncome())}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
             {this.renderWithDrawText()}
            </div>
            <div className="card-footer d-flex mt-0">
              <Button color="icon" className="icon danger ml-2" onClick={() => history.goBack()}>
                <em className="icon icon-cross danger" />
                Cancel
            </Button>
            <Button color="primary" className="btn ml-auto" onClick={this.showWithdrawalPopup}>
                Withdraw
              </Button>
            </div>
          </div>
        </div>

        { this.state.displayErrorPopup &&
              <ErrorPopup
                isOpenModal={true}
                hideModal={() => this.setState({ displayErrorPopup: false })}
                connectStripe={() => {
                  this.setState({ displayErrorPopup: false });
                  history.push(`/${user.role.toLowerCase()}/viewProfile/#3`);
                }}
              />
            }
          {
            displayWithdrawalPopup ?
            <ConfirmatioPopup
            hideModal={() => this.setState({ displayWithdrawalPopup: false, modalPopupObj: {} })}
            isOpenModal={displayWithdrawalPopup} modalPopupObj={modalPopupObj} onClickYes={this.withdrawAmount}
            />
            : ''
          }
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const { expertPayments: { expertIncome }, authentication: { user } , updateProfile, bankDetails } = state;
  return { expertIncome, user,  viewProfile: updateProfile['profile'], bankInfo: bankDetails['bankInfo'] };
}

const connectedWithdrawAmount = connect(mapStateToProps)(WithdrawStripeRegion);

export default connectedWithdrawAmount;
