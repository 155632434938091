import { IPayment } from '../../../../interfaces';
import { history } from '../../../../helpers';
import { pageRoutes, staticConstants } from '../../../../utils';
import { paymentService } from './../../../../services/paymentServices';
import { alertActions } from './../../../alert/redux/actions';
import { endLoading, startLoading } from './../../../loader/redux/actions';
import { actionTypes } from './types';
import OpenFieldTypes from 'OpenFieldTypes';
export const cardAction = {
  getCards,
  addCard,
  resetState,
  updateCard,
  deleteCard,
};

function addCard(card: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(card));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    const role = card.role;
    delete card.role;
    paymentService.addCard(card)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          if (role === staticConstants.ROLE.ORGANIZATION_ADMIN) {
            history.push(`${pageRoutes.ORGADMIN.PATH}${pageRoutes.ORGADMIN.VIEW_PROFILE.PATH}#4`);
          }
          if (role === staticConstants.ROLE.EXPERT) {
            history.push(`${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.VIEW_PROFILE.PATH}`);
          }
          if (role === staticConstants.ROLE.PROFESSIONAL) {
            history.push(`${pageRoutes.PROFESSIONAL.PATH}${pageRoutes.PROFESSIONAL.VIEW_PROFILE.PATH}#4`);
          }
          dispatch(success(res));
          dispatch(alertActions.successAlert(res.message));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };
  function success(res: IPayment) { return { type: actionTypes.ADD_PAYMENT_CARD_SUCCESS, res }; }
  function request(res: IPayment) { return { type: actionTypes.ADD_PAYMENT_CARD_REQUEST, res }; }
  function failure(error: string) { return { type: actionTypes.ADD_PAYMENT_CARD_FAILURE, error }; }
}

function updateCard(card: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(card));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    paymentService.updateCard(card)
      .then(
        (res) => {
          res['cardId'] = card.cardId;
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(res));
          dispatch(alertActions.successAlert(res.message));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };
  function success(data: IPayment) { return { type: actionTypes.UPDATE_PAYMENT_CARD_SUCCESS, data }; }
  function request(res: IPayment) { return { type: actionTypes.UPDATE_PAYMENT_CARD_REQUEST, res }; }
  function failure(error: string) { return { type: actionTypes.UPDATE_PAYMENT_CARD_FAILURE, error }; }
}

function deleteCard(card: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(card));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    paymentService.deleteCard(card)
      .then(
        (res) => {
          res['cardId'] = card.cardId;
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(res));
          dispatch(alertActions.successAlert(res.message));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };
  function success(data: IPayment) { return { type: actionTypes.DELETE_PAYMENT_CARD_SUCCESS, data }; }
  function request(res: IPayment) { return { type: actionTypes.DELETE_PAYMENT_CARD_REQUEST, res }; }
  function failure(error: string) { return { type: actionTypes.DELETE_PAYMENT_CARD_FAILURE, error }; }
}

function getCards() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request());
    paymentService.getCards()
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(res));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };
  function success(data: IPayment) { return { type: actionTypes.CARDS_LISTING_SUCCESS, data }; }
  function request() { return { type: actionTypes.CARDS_LISTING_REQUEST }; }
  function failure(error: string) { return { type: actionTypes.CARDS_LISTING_FAILURE, error }; }
}

function resetState() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch({ type: actionTypes.RESET_STATE });
  };
}
