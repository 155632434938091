import * as firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyBQTsQUiPywiT2VXuZzCsCpzsOi0icqTbg',
  authDomain: 'openfield-27ed5.firebaseapp.com',
  databaseURL: 'https://openfield-27ed5.firebaseio.com',
  projectId: 'openfield-27ed5',
  storageBucket: 'openfield-27ed5.appspot.com',
  messagingSenderId: '511073528435',
  appId: '1:511073528435:web:8f263c30b45bf56a45d806',
};

firebase.initializeApp(firebaseConfig);

export const myFirebase = firebase;
export const myFirestore = firebase.firestore();
