import React, { Component, Dispatch, createRef } from 'react';
import { isEqual } from 'lodash';
import OpenFieldTypes from 'OpenFieldTypes';
import user_avatar from '../../../../assets/images/profile.png';
import { myFirestore } from '../../../../config/firebase';
import { IParams, IInboxUser, IAllInboxUsers, LastMessage, IAllMessages } from '../../../../interfaces';
import { getMyUserId, staticConstants, MAGIC_NUMBER } from '../../../../utils';
import { firestoreQueryConstants } from '../../../../utils/firestoreQueryConstants';
import { chatAction } from '../redux/actions';
import { userAction } from '../../../systemAdmin/userManagement/redux/actions';

const { MESSAGE_BOARD } = firestoreQueryConstants;
interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  currentSelectedUser?: IInboxUser;
  allInboxUsers?: IAllInboxUsers;
  allMessages?: IAllMessages;
  profile?: { [key: string]: string };
  match: {
    params: {
      [key: string]: IParams;
    };
  };
  role?: string;
  fetchingAllMessages?: boolean;
}

interface IState {
  allMessages: IAllMessages;
  newMessage: string;
}

export default class ChatDetails extends Component<IProps, IState> {
  public static defaultProps = {
    currentSelectedUser: {},
    allMessages: [],
  };

  public chatList: React.RefObject<HTMLDivElement>;
  public isSending: boolean;

  constructor(props: IProps) {
    super(props);
    this.state = {
      allMessages: [],
      newMessage: '',
    };
    this.chatList = createRef();
    this.isSending = false;
  }

  public componentDidMount() {
    this.getAllMessages();
  }

  public componentDidUpdate(prevProps: IProps) {
    if (!isEqual(prevProps.currentSelectedUser, this.props.currentSelectedUser)) {
      this.getAllMessages();
    }
    if (!isEqual(prevProps.allMessages, this.props.allMessages)) {
      this.scrollToBottom();
    }
  }

  public getAllMessages = async () => {
    const { currentSelectedUser: { connectionId, isSystemAdmin }, dispatch } = this.props;
    if (connectionId) {
      dispatch(chatAction.getAllMessage(connectionId, isSystemAdmin));
    }
  }

  public scrollToBottom = () => {
    const { scrollHeight, clientHeight } = this.chatList.current;
    const maxScrollTop = scrollHeight - clientHeight;
    this.chatList.current.scrollTop = maxScrollTop > MAGIC_NUMBER.ZERO ? maxScrollTop : MAGIC_NUMBER.ZERO;
  }

  public sendMessage = async () => {
    this.isSending = true;
    const {
      currentSelectedUser: {
        connectionId,
        userId,
        target = '',
        targetName = '',
        members = [],
      },
      profile: { firstName = '', lastName = '' },
      role,
      dispatch,
    } = this.props;
    if (this.state.newMessage.trim() && userId) {
      const timeStamp = Date.now();
      const sendingTo: any = role === staticConstants.ROLE.SYSTEM_ADMIN ? connectionId : userId;
      const msgObj = {
        timeStamp,
        content: this.state.newMessage,
        from: getMyUserId(),
        to: sendingTo,
        roomId: connectionId,
        isRead: false,
        name: `${firstName} ${lastName}`,
      };
      if (role === staticConstants.ROLE.SYSTEM_ADMIN) {
        msgObj['isBroadcast'] = true;
        msgObj['target'] = target;
        msgObj['targetName'] = targetName;
      }
      if (target === staticConstants.MESSAGE_BOARD_TAB.ORGANIZATIONS) {
        const orgId = sendingTo.split('**')[0];
        this.getOrgLearner(orgId, (learners: any) => {
          dispatch(chatAction.sendMessage(msgObj, [sendingTo, orgId, ...learners], () => {
            this.isSending = false;
            this.setState({ newMessage: '' });
          }));
        });
      } else {
        dispatch(chatAction.sendMessage(msgObj, [sendingTo, ...members], () => {
          this.isSending = false;
          this.setState({ newMessage: '' });
        }));
      }
    }
  }

  public getOrgLearner = (orgId: string, cb: (learner: Array<[string]>) => void) => {
    const reqObj = {
      isPagination: false,
      userId: orgId,
    };
    this.props.dispatch(userAction.getUsers(reqObj, (learners) => {
      if (learners) {
        const allLearners = learners.data.map(learner => learner._id);
        cb(allLearners);
      }
    }));
  }

  public updateLastMessage = (msgObj, connectionId, userId) => {
    const members = [getMyUserId(), userId];
    myFirestore.doc(`${MESSAGE_BOARD}/${connectionId}`).set(
      { lastMessage: msgObj, members },
      { merge: true },
    );
  }

  public onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      !this.isSending && this.sendMessage();
    }
  }

  public removeCss = () => {
    const chatContainer = document.querySelector('.chat-container');
    if (chatContainer) {
      chatContainer.classList.remove('detail-page-show');
    }
  }

  public render() {
    const { newMessage } = this.state;
    const {
      currentSelectedUser: {
        firstName = '',
        lastName = '',
        profileImage,
        isSystemAdmin = false,
        lastMessage = new LastMessage(),
        targetName = '',
        target = '',
        targetProfile = '',
      },
      allMessages,
      fetchingAllMessages,
    } = this.props;
    const displayName = targetName ? targetName : `${firstName} ${lastName}`;
    const displayPicture = target ? targetProfile : profileImage;
    return (
      <div className="chat-view-wrapper">
        <div className="chat-view-head">
          <h3 className="heading heading-sm text-truncate">
            <span>
              <img src={displayPicture || user_avatar} alt="user" className="img-cover" />
            </span>
            {displayName}
          </h3>
          <span onClick={this.removeCss}
            className="icon-back-arrow"
          />
        </div>
        <div className="chat-view" ref={this.chatList}>
          {allMessages.length ?
            allMessages.map((msgObj: { content: string; receiptType: string; time: string }, index: number) => {
              const { content, receiptType, time } = msgObj;
              return (
                <div key={index} className="chat-tooltip-row">
                  <div className={`chat-tooltip ${receiptType}-chat-tooltip`}>
                    <p>{content}
                      <span>{time}</span>
                    </p>
                  </div>
                </div>
              );
            }) : (
              <span className="d-flex justify-content-center w-100">{fetchingAllMessages ? 'Loading...' : 'No messages found'}</span>
            )
          }

        </div>
        {(lastMessage.from === getMyUserId() || !isSystemAdmin) &&
          <div className="chat-input-box">
            <div className="search-bar">
              <input
                type="text"
                placeholder="Type a message here"
                value={newMessage}
                onChange={event => this.setState({ newMessage: event.target.value })}
                onKeyPress={this.onKeyPress}
              />
              <button type="button" className="send-btn" onClick={this.sendMessage} />
            </div>
          </div>
        }
      </div>
    );
  }
}
