export const actionTypes = {
  RFP_LIST_REQUEST: 'RFP_LIST_REQUEST',
  RFP_LIST_SUCCESS: 'RFP_LIST_SUCCESS',
  RFP_LIST_FAILURE: 'RFP_LIST_FAILURE',

  RFP_DETAIL_REQUEST: 'RFP_DETAIL_REQUEST',
  RFP_DETAIL_SUCCESS: 'RFP_DETAIL_SUCCESS',
  RFP_DETAIL_FAILURE: 'RFP_DETAIL_FAILURE',

  BID_ACCEPT_SUCCESS: 'BID_ACCEPT_SUCCESS',
  BID_ACCEPT_FAILURE: 'BID_ACCEPT_FAILURE',
  BID_ACCEPT_REQUEST: 'BID_ACCEPT_REQUEST',

  BID_SHORTLIST_SUCCESS: 'BID_SHORTLIST_SUCCESS',
  BID_SHORTLIST_FAILURE: 'BID_SHORTLIST_FAILURE',
  UPDATE_RFP_SUCCESS: 'UPDATE_RFP_SUCCESS',
  UPDATE_RFP_FAILED: 'UPDATE_RFP_FAILED',

  BID_REJECT_SUCCESS: 'BID_REJECT_SUCCESS',
  BID_REJECT_FAILURE: 'BID_REJECT_FAILURE',

  GENERATE_REPORT_REQUEST: 'GENERATE_REPORT_REQUEST',
  GENERATE_REPORT_SUCCESS: 'GENERATE_REPORT_SUCCESS',
  GENERATE_REPORT_FAILURE: 'GENERATE_REPORT_FAILURE',
  RESET_GENERATE_REPORT: 'RESET_GENERATE_REPORT',

  GET_SESSION_FEE_SUCCESS: 'GET_SESSION_FEE_SUCCESS',
  GET_SESSION_FEE_FAILURE: 'GET_SESSION_FEE_FAILURE',

  UPDATE_RESCHEDULE_FAILURE: 'UPDATE_RESCHEDULE_FAILURE',
};
