import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import OpenFieldTypes from 'OpenFieldTypes';
import { Sidebar } from '../../shared/sidebar';
import { TagsPage } from './tagsPage';
interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
}

class TagManagementContainer extends React.Component<IProps> {

  public render() {
    return (
      <div>
        <Sidebar {...this.props} />
          <div className="dashboard-content">
            <TagsPage {...this.props} />
          </div>
      </div>
    );
  }
}

const connectedTagManagementContainer = connect(null)(TagManagementContainer);
export { connectedTagManagementContainer as TagManagementContainer };
