import { userServices } from './../../../../services';
import { alertActions } from './../../../alert/redux/actions';
import { endLoading, startLoading } from './../../../loader/redux/actions';
import { actionTypes } from './types';
import OpenFieldTypes from 'OpenFieldTypes';

export const systemAdminAction = {
  updateProfile,
  getSystemAdminProfile,
};

function getSystemAdminProfile() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(null));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.getProfile()
      .then(
        (data) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(data));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request(data: any) { return { type: actionTypes.SYSTEM_ADMIN_DETAIL_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.SYSTEM_ADMIN_DETAIL_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.SYSTEM_ADMIN_DETAIL_FAILURE, error }; }
}

function updateProfile(user: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.updateSystemAdminProfile(user)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(res));
          dispatch(successProfile(res.data));
          dispatch(alertActions.successAlert(res.message));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };
  function success(data: any) { return { type: actionTypes.UPDTAE_SYSTEM_ADMIN_STATUS_SUCCESS, data }; }
  function successProfile(data: any) { return { type: actionTypes.GET_PROFILE_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.UPDTAE_SYSTEM_ADMIN_STATUS_FAILURE, error }; }
}
