import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import OpenFieldTypes from 'OpenFieldTypes';
import { v4 } from 'uuid';
import _ from 'lodash';
import Pagination from 'react-js-pagination';
import { tableItemsCount, staticConstants, MAGIC_NUMBER } from '../../../utils';
import { InvoiceItem } from './InvoiceItem';
import { profileAction } from './redux/actions';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  user: any;
  role: string;
  updateProfile: any;
  userId?: string;
}

interface IState {
  activePage: number;
  items: any;
  tableLimit: number;
  sort: string;
  sortBy: number;
}

class InvoiceLists extends React.Component<IProps, IState> {

  public static getDerivedStateFromProps(props: IProps) {
    const newState = {};
    if (props.updateProfile && props.updateProfile.count && props.updateProfile.count > MAGIC_NUMBER.ZERO) {
      newState['items'] = props.updateProfile.list;
    }
    return newState;
  }

  constructor(props: IProps) {
    super(props);

    this.state = {
      activePage: 1,
      items: [],
      tableLimit: tableItemsCount,
      sort: '',
      sortBy: 0,
    };
  }

  public componentDidMount() {
    const { tableLimit, activePage } = this.state;
    const reqObj = {
      page: Number(activePage),
      limit: tableLimit,
    };
    const updateReqObj = this.updateReqObj(reqObj);
    this.props.dispatch(profileAction.getPaymentsByType(updateReqObj));
  }

  public getReqObj(pageno: number) {
    const { sort, sortBy, tableLimit } = this.state;
    let reqObj = {
      page: Number(pageno),
      limit: tableLimit,
    };
    if (sort) {
      reqObj = { ...reqObj, ...{ sort, sortBy } };
    }
    return this.updateReqObj(reqObj);
  }

    /**
     * @description updateReqObj to update object before sending to the server
     */
  public updateReqObj(reqObj: any) {
    if (this.props.userId) {
      reqObj.userId = this.props.userId;
    }
    return reqObj;
  }

    /**
     * @description fetchPayments is used on initial load
     */
  public fetchPayments(pageno: number) {
    this.props.dispatch(profileAction.getPaymentsByType(this.getReqObj(pageno)));
  }

    /**
     * @description
     * handlePageChange is called someone click on the pagination.
     * @param fields {Number} pageno
     */
  public handlePageChange = (pageno: number) => {
    this.setState({ activePage: pageno }, () => this.fetchPayments(pageno));
  }

/**
 * @description
 * sortData is used for sorting purpose.
 * @param fields {Number} sort
 * @param fields {String} sortBy
 */
  public sortData(sort: any, sortBy: any) {
    const { activePage } = this.state;
    this.setState({ ...this.state, sort, sortBy },
                  () => this.fetchPayments(activePage));
  }

    /**
   * @description renderRequestListing is called to render the request listings
   */
  public renderRequestListing = () => {
    const { items } = this.state;
    return _.map(items, (item) => {
      return (
                <InvoiceItem key={v4()} invoice={item} role={this.props.role} />
      );
    });
  }

    /**
     * @description
     * render is used to render the html.
     */
  public render() {
    const { sort, sortBy, items, activePage, tableLimit } = this.state;
    const { count } = this.props['updateProfile'];
    let minDisplay: number;
    let maxDisplay: number;
    if (count === MAGIC_NUMBER.ZERO) {
      maxDisplay = MAGIC_NUMBER.ZERO;
      minDisplay = MAGIC_NUMBER.ZERO;
    } else if (count <= items.length) {
      maxDisplay = count;
      minDisplay = MAGIC_NUMBER.ONE;
    } else {
      maxDisplay = (Number(activePage) * Number(items.length)) < count
                ? (Number(activePage) * Number(items.length)) : count;
      minDisplay = ((Number(activePage) - 1) * Number(items.length)) + 1;
    }
    return (
          <div className="table-wrapper">
            <div className="flex-table">
              <div className={`table-head`}>
                <div className="table-row">
                  <div className="table-col table-col-13">
                    <span className="sorting-icon">
                      Title
                      <strong>
                        <em
                          className={
                            sort === 'goalData.title' &&
                            sortBy === MAGIC_NUMBER.ONE
                              ? 'active'
                              : ''
                          }
                          onClick={() => {
                            this.sortData('goalData.title', MAGIC_NUMBER.ONE);
                          }}
                        />
                        <em
                          className={
                            sort === 'goalData.title' &&
                            sortBy === MAGIC_NUMBER.FOUND_INDEX
                              ? 'active'
                              : ''
                          }
                          onClick={() => {
                            this.sortData(
                              'goalData.title',
                              MAGIC_NUMBER.FOUND_INDEX,
                            );
                          }}
                        />
                      </strong>
                    </span>
                  </div>
                  <div className="table-col table-col-20">
                    <span className="sorting-icon">
                      Expert Name
                      <strong>
                        <em
                          className={
                            sort === 'expertName' && sortBy === MAGIC_NUMBER.ONE
                              ? 'active'
                              : ''
                          }
                          onClick={() => {
                            this.sortData(
                              'receiverData.name',
                              MAGIC_NUMBER.ONE,
                            );
                          }}
                        />
                        <em
                          className={
                            sort === 'expertName' &&
                            sortBy === MAGIC_NUMBER.FOUND_INDEX
                              ? 'active'
                              : ''
                          }
                          onClick={() => {
                            this.sortData(
                              'receiverData.name',
                              MAGIC_NUMBER.FOUND_INDEX,
                            );
                          }}
                        />
                      </strong>
                    </span>
                  </div>
                  {this.props.role ===
                    staticConstants.ROLE.ORGANIZATION_ADMIN && (
                    <div className="table-col table-col-13">
                      <span className="sorting-icon">
                        No Participants
                        <strong>
                          <em
                            className={
                              sort === 'goalData.title' &&
                              sortBy === MAGIC_NUMBER.ONE
                                ? 'active'
                                : ''
                            }
                            onClick={() => {
                              this.sortData('participants', MAGIC_NUMBER.ONE);
                            }}
                          />
                          <em
                            className={
                              sort === 'goalData.title' &&
                              sortBy === MAGIC_NUMBER.FOUND_INDEX
                                ? 'active'
                                : ''
                            }
                            onClick={() => {
                              this.sortData(
                                'participants',
                                MAGIC_NUMBER.FOUND_INDEX,
                              );
                            }}
                          />
                        </strong>
                      </span>
                    </div>
                  )}
                  <div className="table-col table-col-15">
                    <span className="sorting-icon">
                      Session Fee
                      <strong>
                        <em
                          className={
                            sort === 'sessionFee' && sortBy === MAGIC_NUMBER.ONE
                              ? 'active'
                              : ''
                          }
                          onClick={() => {
                            this.sortData('sessionFee', MAGIC_NUMBER.ONE);
                          }}
                        />
                        <em
                          className={
                            sort === 'sessionFee' &&
                            sortBy === MAGIC_NUMBER.FOUND_INDEX
                              ? 'active'
                              : ''
                          }
                          onClick={() => {
                            this.sortData(
                              'sessionFee',
                              MAGIC_NUMBER.FOUND_INDEX,
                            );
                          }}
                        />
                      </strong>
                    </span>
                  </div>
                  <div className="table-col table-col-15">
                    <span className="sorting-icon">
                      Payment Processed
                      <strong>
                        <em
                          className={
                            sort === 'createdAt' && sortBy === MAGIC_NUMBER.ONE
                              ? 'active'
                              : ''
                          }
                          onClick={() => {
                            this.sortData('createdAt', MAGIC_NUMBER.ONE);
                          }}
                        />
                        <em
                          className={
                            sort === 'createdAt' &&
                            sortBy === MAGIC_NUMBER.FOUND_INDEX
                              ? 'active'
                              : ''
                          }
                          onClick={() => {
                            this.sortData(
                              'createdAt',
                              MAGIC_NUMBER.FOUND_INDEX,
                            );
                          }}
                        />
                      </strong>
                    </span>
                  </div>
                  <div className="table-col table-col-20 text-center">
                    <span className="sorting-icon">
                      Event Date
                      <strong>
                        <em
                          className={
                            sort === 'goalData.endedAt' &&
                            sortBy === MAGIC_NUMBER.ONE
                              ? 'active'
                              : ''
                          }
                          onClick={() => {
                            this.sortData('goalData.endedAt', MAGIC_NUMBER.ONE);
                          }}
                        />
                        <em
                          className={
                            sort === 'goalData.endedAt' &&
                            sortBy === MAGIC_NUMBER.FOUND_INDEX
                              ? 'active'
                              : ''
                          }
                          onClick={() => {
                            this.sortData(
                              'goalData.endedAt',
                              MAGIC_NUMBER.FOUND_INDEX,
                            );
                          }}
                        />
                      </strong>
                    </span>
                  </div>
                  <div className="table-col table-col-15">
                    <span className="sorting-icon">
                      Status
                      <strong>
                        <em
                          className={
                            sort === 'goalData.status' &&
                            sortBy === MAGIC_NUMBER.ONE
                              ? 'active'
                              : ''
                          }
                          onClick={() => {
                            this.sortData('status', MAGIC_NUMBER.ONE);
                          }}
                        />
                        <em
                          className={
                            sort === 'goalData.status' &&
                            sortBy === MAGIC_NUMBER.FOUND_INDEX
                              ? 'active'
                              : ''
                          }
                          onClick={() => {
                            this.sortData('status', MAGIC_NUMBER.FOUND_INDEX);
                          }}
                        />
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
              <div className="table-body">
                {this.renderRequestListing()}
                {items.length === MAGIC_NUMBER.ZERO ? (
                  <div className="table-row table-no-data">
                    No Results Found.
                  </div>
                ) : (
                  ''
                )}
              </div>
              {count > MAGIC_NUMBER.TEN && (
                <div className="table-footer">
                  <React.Fragment>
                    <p>
                      Showing {minDisplay} - {maxDisplay} of {count}
                    </p>
                    <div className="pagination-wrapper">
                      <Pagination
                        hideFirstLastPages
                        activePage={activePage}
                        itemsCountPerPage={tableLimit}
                        totalItemsCount={count}
                        pageRangeDisplayed={MAGIC_NUMBER.FIVE}
                        onChange={this.handlePageChange}
                      />
                    </div>
                  </React.Fragment>
                </div>
              )}
            </div>
          </div>
    );
  }
}

function mapStateToProps(state: { authentication: { user: any }; updateProfile }) {
  const { user } = state.authentication;
  const { updateProfile } = state;
  return { user, updateProfile };
}

const invoiceListsPage = connect(mapStateToProps)(InvoiceLists);
export { invoiceListsPage as InvoiceLists };
