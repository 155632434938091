import { actionTypes } from './types';
interface IAction {
  type: string;
  learners: {};
  data: {};
  error: string;
  licenceRequestToAdmin: false;
}

export function learner(state = {}, action: IAction) {
  switch (action.type) {
    case actionTypes.CREATE_LEARNER_SUCCESS:
      return {
        successForCreateLearner: true,
        email: action.data['email'],
      };
    case actionTypes.CREATE_LEARNER_REQUEST:
      return {
        ...state,
        requestForCreateLearner: true,
      };
    case actionTypes.ASSIGN_LICENCE_SUCCESS:
      const learner = state['list'];
      for (let index = 0; index < learner && learner.length; index++) {
        if (learner[index]['_id'] === action.data['userId']) {
          learner[index]['isLicenced'] = action.data['type'] === 'assign';
          break;
        }
      }
      return {
        ...state,
        list: learner,
        learnerData: action.data['learnerData'],
        successForAssignUnassignLearner: true,
      };
    case actionTypes.LEARNER_LIST_SUCCESS:
      const assignedLicence = action.learners && action.learners['totalLicenceAssign'];
      const totalLicence = action.learners && action.learners['totalLicence'];
      const isAssign = (assignedLicence < totalLicence);
      return {
        successForGettingLearner: true,
        list: action.learners ? action.learners['data'] : [],
        count: action.learners ? action.learners['count'] : 0,
        assignLicence: isAssign,
      };
    case actionTypes.LEARNER_LIST_REQUEST:
      return { assignLicence: state['assignLicence'], requestForGettingLearner: true };
    case actionTypes.DELETE_LEARNER_SUCCESS:
      return {
        ...state,
        deleteLearner: true,
      };
    case actionTypes.ASSIGN_LICENCE_FAILURE:
      const errorData = action.error;
      let assignMoreLiecence = false;
      // Note:- this need to be handled by error code
      if (errorData === 'Error: GraphQL error: You have already assigned all the licence and please contact System admin') {
        assignMoreLiecence = true;
      }
      return {
        assignMoreLiecence,
      };
    case actionTypes.LICENCE_ASSIGN_REQUEST:
      return { assignLicence: state['assignLicence'], licenceRequestToAdmin: !state['licenceRequestToAdmin'] };
    case actionTypes.ALLREADY_ASSIGN_GOAL_ERROR:
      return { alreadyAssignToGoalError: true };
    case actionTypes.RESET_ALLREADY_ASSIGN_GOAL_ERROR:
      return { alreadyAssignToGoalError: false };
    case actionTypes.LEARNER_LIST_FAILURE:
    case actionTypes.CREATE_LEARNER_FAILURE:
      return { ...state };
    case actionTypes.DELETE_LEARNER_FAILURE:
      return { error: action.error };
    default:
      return state;
  }
}
