import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import ReeValidate from 'ree-validate';
import OpenFieldTypes from 'OpenFieldTypes';
import Select, { createFilter } from 'react-select';
import { IValidator } from './../../../../interfaces';
import { staticConstants, reactSelectFilters, faqTypes, MAGIC_NUMBER } from '../../../../utils';
import { customSelectStyles } from '../../../../utils/common';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  isOpenModal: boolean;
  hideModal: any;
  addFaq: any;
  editFaq: object;
}

interface IState {
  submitted: boolean;
  question: string;
  type: any;
  answer: string;
  errors?: any;
  modal: boolean;
}

class FaqModal extends React.Component<IProps, IState> {

  public static getDerivedStateFromProps(props: IProps, state: any) {
    const updateState = {};
    updateState['modal'] = props.isOpenModal;
    if (props.editFaq && !state.submitted) {
      updateState['question'] = props.editFaq['question'];
      updateState['answer'] = props.editFaq['answer'];
      const filterType = faqTypes.filter(item => item.value ===  props.editFaq['faqType']);
      if(filterType.length) {
        updateState['type'] = filterType[0];
      }
    }
    return updateState;
  }

  public validator: IValidator;
  constructor(props: IProps) {
    super(props);

    // reset login status
    this.validator = new ReeValidate({
      type: 'required',
      question: 'required',
      answer: 'required',
    });

    this.state = {
      question: '',
      type: null,
      answer: '',
      submitted: false,
      errors: this.validator.errors,
      modal: this.props.isOpenModal,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.validateAndSubmit = this.validateAndSubmit.bind(this);
  }

  public handleChange = (e: any) => {
    const { name, value } = e.target;
    const { errors } = this.validator;
    this.setState({
      submitted: true,
      [name]: value,
    } as any);
    errors.remove(name);
    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors });
      });
  }

  public validateAndSubmit(e: any) {
    e.preventDefault();
    const { question, answer, type } = this.state;
    const { errors } = this.validator;
    this.validator.validateAll({ question, answer, type })
    .then((success: any) => {
      if (success) {
        this.handleSubmit();
      } else {
        this.setState({ errors });
      }
    });
  }

  public handleSubmit() {
    const { question, answer, type} = this.state;
    const { editFaq } = this.props;
    let obj;
    if (question && answer) {
      editFaq ? obj = {
        type: staticConstants.CONTENT_MANAGEMENT_TAB.FAQ,
        question,
        answer,
        faqId: editFaq['faqId'],
        faqType: type.value
      } : obj = {
        type: staticConstants.CONTENT_MANAGEMENT_TAB.FAQ,
        question,
        answer,
        faqType: type.value
      };
      this.props.addFaq(obj);
      this.setState({ submitted: false });
    }
  }

  public hideModal() {
    this.setState({
      modal: false,
      question: '',
      answer: '',
      type: null
    });
    const { errors } = this.validator;
    errors.clear();
    this.props.hideModal();
  }

  public handleTypeChange = (event: any) => {
    const { errors } = this.validator;
    const faqTypeFld = 'type';
    const filterType = faqTypes.filter(item => item.value === event.value);
    if (filterType.length) {
      this.setState({ submitted: true, type: filterType[0] });
      errors.remove(faqTypeFld);
      this.validator.validate(faqTypeFld, event.value)
        .then(() => {
          this.setState({ errors });
        });
    }
  };

  public render() {
    
    const { modal, question, answer, type, errors } = this.state;
    const { editFaq } = this.props;
    return (
      <Modal isOpen={modal} className="add-faq-modal" onClosed={this.hideModal}>
        <ModalHeader toggle={this.hideModal}>{editFaq && editFaq['isEdit'] ? 'Update FAQ' : 'Add FAQs'}</ModalHeader>
        <ModalBody>
          <Form onSubmit={this.validateAndSubmit} autoComplete="off" noValidate>
          <FormGroup
                className="floating-label disabled-input"
              >
                <Select
                  name="type"
                  value={type}
                  styles={customSelectStyles}
                  onChange={this.handleTypeChange}
                  options={faqTypes}
                  placeholder="FAQs Type"
                  filterOption={createFilter(reactSelectFilters)}
                  className="react-select-box select-box-group"
                />
                <Label for="type" >FAQs Type</Label>
                {errors.has('type') &&
                <div className="error-text">{errors.first('type')}</div>
                }
              </FormGroup>
            <FormGroup
                className={`floating-label ${
                  errors.has('question') ? ' has-error' : ''
                  }`}>
                <Input
                    type="text"
                    className="form-control"
                    value={question}
                    onChange={this.handleChange}
                    name="question"
                    id="question"
                    placeholder="Question"
                />
                <Label for="question" className={question ? 'selected' : ''}>Question</Label>
                {errors.has('question') &&
                <div className="error-text">{errors.first('question')}</div>
                }
            </FormGroup>
            <FormGroup
                className={`floating-label disabled-input textarea-label answer-textarea mb-4 ${
                  errors.has('answer') ? ' has-error' : ''
                  }`}>
                <textarea
                    className="form-control textarea-lg"
                    id="answer"
                    value={answer}
                    onChange={this.handleChange}
                    name="answer"
                    placeholder="Answer"
                    maxLength={MAGIC_NUMBER.THOUSANT}
                />
                <Label for="answer" className={answer ? 'selected' : ''}>Answer</Label>
                <span className="text-count">{(answer && answer.length) || 0}/1000</span>
                {errors.has('answer') &&
                <div className="error-text">{errors.first('answer')}</div>
                }
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-regular ml-0" onClick={this.hideModal}>Cancel</Button>{' '}
          <Button color="primary" className="mr-2" onClick={this.validateAndSubmit}>{editFaq && editFaq['isEdit'] ? 'Update' : 'Add'}</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

function mapStateToProps(state: any) {
  return {
  };
}

const connectedFaqModalPage = connect(mapStateToProps)(FaqModal);
export { connectedFaqModalPage as FaqModal };
