import _ from 'lodash';
import OpenFieldTypes from 'OpenFieldTypes';
import queryString from 'query-string';
import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import Select, { createFilter } from 'react-select';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import ReeValidate from 'ree-validate';
import {
  allAvailableDays, alllanguageProficiencies, availableDays, educations,
  getUpdatedOptions, languageProficiencies, languages, reactSelectFilters,
  selectAllCheckboxes, staticConstants, unSelectAllCheckboxes, MAGIC_NUMBER,
} from '../../../utils/';
import { customSelectStyles, mapEducationFromAPIRequest, mapTagsFromAPIRequest } from '../../../utils/common';
import { SelectTags } from '../../shared/selectTags/selectTags';
import { validator } from './../../../helpers';
validator(ReeValidate);

interface IProps {
  education?: any;
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  successValidateSubmit: any;
  tagsSuggestions: any;
  profile?: any;
  props?: any;
  tagList?: any;
}

interface IState {
  education?: any;
  selectedEducation: any;
  selectedLanguage: any;
  isSubmitted: boolean;
  isAddedLanguageSubmitted: boolean;
  isErrorOnProfeciency: boolean;
  isErrorOnAlreadyAddedLanguage: boolean;
  isErrorOnAvailability: boolean;
  errors?: any;
  isDisAbledAutoCompleted: boolean;
}

class UpdateEducationForm extends React.Component<IProps, IState> {

  public selectedProfeciencies: string[] = [];
  public selectedAvailabilities: string[] = [];
  public validator: any;
  public ref: any;

  constructor(props: IProps) {
    super(props);
    this.ref = React.createRef();
    this.validator = new ReeValidate({
      tags: 'required',
    });
    this.state = {
      selectedEducation: null,
      selectedLanguage: null,
      education: {
        tags: [],
        modeOfDelivery: 'both',
        languages: [],
      },
      isSubmitted: false,
      isAddedLanguageSubmitted: false,
      isErrorOnProfeciency: false,
      isErrorOnAlreadyAddedLanguage: false,
      isErrorOnAvailability: false,
      errors: this.validator.errors,
      isDisAbledAutoCompleted: false,
    };
  }

  public componentDidUpdate(prevProps) {
    const { profile } = this.props;
    if (!_.isEqual(prevProps.profile, profile) && profile) {
        profile.tags && profile.tags.map((tag) => delete tag.__typename);
        profile.languages && profile.languages.map((language) => delete language.__typename);
        const { education } = this.state;
        education.tags = mapTagsFromAPIRequest(profile.tags) || [];
        education.languages = profile.languages || [];
        education.modeOfDelivery = profile.modeOfDelivery;
        this.selectedAvailabilities = profile.availableDays || [];
        this.handleAvailabilitiesOnEditProfile();

        this.setState({
          education,
          selectedEducation: mapEducationFromAPIRequest(profile.education),
        });
    }
  }

  public handleAvailabilitiesOnEditProfile = () => {
    if (this.selectedAvailabilities.length === availableDays.length) {
      selectAllCheckboxes('allAvailableDay');
    }
  }

  /**
   * @description
   * handleChange is used to set the value on state from the only input/textarea boxes.
   * @param fields {Object} event
   */
  public handleChange = (event: any) => {
    const { name, value } = event.target;
    this.setState({ education: { ...this.state.education, [name]: value } });
  }

  /**
   * @description
   * handleEducation is used to set the value on state from education select box
   * @param fields {Object} education
   */
  public handleEducation = (education: any) => {
    this.setState({ selectedEducation: education });
  }

  /**
   * @description
   * handleLanguageSelection is used to set the value on state from education select box
   * @param fields {Object} language
   */
  public handleLanguageSelection = (language: any) => {
    this.setState({ selectedLanguage: language });
  }

  /**
   * @description
   * isLanguageAlreadyAdded is used to check language is already added or not from the addlanguage button
   * @param fields {String} selectedLanguage
   */
  public isLanguageAlreadyAdded(selectedLanguage: any) {
    const filterLanguage = this.state.education.languages.filter((language: any) => {
      return language.language === selectedLanguage;
    });
    return filterLanguage.length > 0;
  }

  /**
   * @description
   * addLanguage is used to call when someone click on the addLanguage button
   * once all condition matched then it push the desire language object in addedLanguages
   */
  public addLanguage = () => {
    this.setState({
      isAddedLanguageSubmitted: true,
      isErrorOnAlreadyAddedLanguage: false,
    });
    const selectedLanguage = this.state.selectedLanguage;
    if (selectedLanguage && selectedLanguage.value !== '') {
      if (this.isLanguageAlreadyAdded(selectedLanguage.value)) {
        this.setState({
          isErrorOnAlreadyAddedLanguage: true,
        });
      } else {
        if (this.selectedProfeciencies.length === MAGIC_NUMBER.ZERO) {
          this.setState({
            isErrorOnProfeciency: true,
          });
        } else {
          const education = this.state.education;
          const addedLanguages = education.languages;
          addedLanguages.push({
            language: selectedLanguage.value,
            profeciencies: this.selectedProfeciencies,
          });
          education.languages = addedLanguages;
          this.setState({
            education,
            isAddedLanguageSubmitted: false,
            selectedLanguage: null,
          });
          this.unselectProfeciencies();
        }
      }
    }
  }

  /**
   * @description
   * unselectProfeciencies is used to clear the checkboxed of Profeciencies after adding
   * the language.
   */
  public unselectProfeciencies() {
    this.selectedProfeciencies = [];
    unSelectAllCheckboxes('profeciency');
    unSelectAllCheckboxes('allProfeciency');
  }

  /**
   * @description handleLanguageProfeciency is used when someone check/uncheck the Profeciency
   * @param fields {Object} event
   */
  public handleLanguageProfeciency = (event: any, selectTagName: any) => {
    const { value, checked } = event.target;
    if (!checked) {
      unSelectAllCheckboxes(selectTagName);
    }
    this.setState({
      isErrorOnProfeciency: false,
    });
    if (!checked) {
      const findIndex = this.selectedProfeciencies.findIndex((val) => val === value);
      this.selectedProfeciencies.splice(findIndex, 1);
    } else {
      this.selectedProfeciencies.push(value);
    }
    if (this.selectedProfeciencies.length === languageProficiencies.length) {
      selectAllCheckboxes(selectTagName);
    }
  }

  /**
   * @description handleAvailability is used when someone check/uncheck the Days of availablity
   * @param fields {Object} event
   */
  public handleAvailability = (event: any, selectTagName: any) => {
    const { value, checked } = event.target;
    if (!checked) {
      unSelectAllCheckboxes(selectTagName);
    }
    this.setState({
      isErrorOnAvailability: false,
    });
    if (!checked) {
      const findIndex = this.selectedAvailabilities.findIndex((val) => val === value);
      this.selectedAvailabilities.splice(findIndex, 1);
    } else {
      this.selectedAvailabilities.push(value);
    }
    if (this.selectedAvailabilities.length === availableDays.length) {
      selectAllCheckboxes(selectTagName);
    }
  }

  /**
   * @description selectAllLanguageProfeciency is used when someone check/uncheck on all
   * @param fields {Object} event
   * @param fields {selectTagName} string
   * @param fields {selectChildTagName} child checkbox name
   */
  public selectAllLanguageProfeciency(event: any, selectTagName: any, selectChildTagName: any) {
    const { checked } = event.target;
    if (checked) {
      selectAllCheckboxes(selectChildTagName);
      const result = _.map(languageProficiencies, (item: any) => {
        return item.value;
      });
      this.selectedProfeciencies = result;
      this.setState({
        isErrorOnProfeciency: false,
      });
    } else {
      unSelectAllCheckboxes(selectChildTagName);
    }
  }

  /**
   * @description
   * renderLanguageProfeciency is used to render the proficieny checkbox
   */
  public renderLanguageProfeciency() {
    const languageProficienciesWithAll = alllanguageProficiencies.concat(languageProficiencies);
    return _.map(languageProficienciesWithAll, (item: any, index: any) => {
      if (item.value === 'all') {
        return (
          <Col xs="6" sm="3" key={`profeciency-${index}`}>
            <label className="control control--checkbox">
              <input
                type="checkbox"
                value={item.value}
                onChange={(e) => this.selectAllLanguageProfeciency(e, 'allProfeciency', 'profeciency')}
                name="allProfeciency"
              /> {item.label}
              <div className="control__indicator"/>
            </label>
          </Col>
        );
      } else if(item.value !== 'langulage') {
        return (
          <Col xs="6" sm="3" key={`profeciency-${index}`}>
            <label className="control control--checkbox">
              <input
                type="checkbox"
                value={item.value}
                onChange={(e) => this.handleLanguageProfeciency(e, 'allProfeciency')}
                name="profeciency"
              />
              {item.label}
              <div className="control__indicator"/>
            </label>
          </Col>
        );
      }
      return null;
    });
  }

  /**
   * @description
   * selectAllAvailability is used when someone check/uncheck on all
   * @param fields {Object} event
   * @param fields {selectTagName} string
   * @param fields {selectChildTagName} child checkbox name
   */
  public selectAllAvailability(event: any, selectTagName: any, selectChildTagName: any) {
    const { checked } = event.target;
    if (checked) {
      selectAllCheckboxes(selectChildTagName);
      const result = _.map(availableDays, (item: any) => {
        return item.value;
      });
      this.selectedAvailabilities = result;
      this.setState({
        isErrorOnAvailability: false,
      });
    } else {
      unSelectAllCheckboxes(selectChildTagName);
      this.selectedAvailabilities = [];
    }
  }

  /**
   * @description
   * renderAvailability is used to render the days of availbility checkbox
   */
  public renderAvailability() {

    const availableDaysWithAll = allAvailableDays.concat(availableDays);
    return _.map(availableDaysWithAll, (item: any, index: any) => {
      if (item.value === 'allDays') {
        return (
          <Col xs="6" sm="3" key={`availableDay-${index}`}>
            <label className="control control--checkbox">
              <input
                type="checkbox"
                value={item.value}
                onChange={(e) => this.selectAllAvailability(e, 'allAvailableDay', 'availableDay')}
                name="allAvailableDay"
              />
              {item.label}
              <div className="control__indicator"/>
            </label>
          </Col>
        );
      } 
        return (
          <Col xs="6" sm="3" key={`availableDay-${index}`}>
            <label className="control control--checkbox">
              <input
                type="checkbox"
                value={item.value}
                checked={this.selectedAvailabilities.includes(item.value)}
                onChange={(e) => this.handleAvailability(e, 'allAvailableDay')}
                name="availableDay"
              />
              {item.label}
              <div className="control__indicator"/>
            </label>
          </Col>
        );
      
    });
  }

  /**
   * @description
   * renderAddedLanguages is used to render the added langauage with Profeciency
   * call renderAddedProfeciency to show the profeciency
   */
  public renderAddedLanguages() {

    return _.map(this.state.education.languages, (item: any, index: any) => {
      return (
        <>
          <div className="language-data" key={`addedLanguage-${index}`}>
            <span>{item.language}</span>
            {this.renderAddedProfeciencyCheck(item.profeciencies)}
            <span><em className="icon icon-delete" onClick={() => this.removeAddedLanguage(index)}/></span>
          </div>
        </>
      );
    });
  }

  public removeAddedLanguage(langauageIndex: any) {
    const education = this.state.education;
    const selectedLanguages = education.languages;
    selectedLanguages.splice(langauageIndex, 1);
    education.languages = selectedLanguages;
    this.setState({ education });
  }

  /**
   * @description
   * renderAddedProfeciency is used to show the profeciency
   */
  public renderAddedProfeciencyCheck(selectedProfeciencies: any) {
    return _.map(languageProficiencies, (item: any, index: any) => {
      if (item.value !== 'all' && item.value !== 'langulage' ) {
        let checkClass = 'icon-cross';
        if (selectedProfeciencies.includes(item.value)) {
          checkClass = 'icon-accept';
        }
        return (
          <span key={`addedProfeciencyCheck-${index}`}><em className={`icon ${checkClass}`}/></span>
        );
      }
    });
  }

  public removeErrorAndReValidate = (name: string) => {
    const { errors } = this.validator;
    errors.remove(name);
    this.validator.validate(name, this.state.education[name])
      .then(() => {
        this.setState({ errors });
      });
  }

  public handleTagAdd = (tag: { value: string; label: string }) => {
    const ind = this.state.education.tags.findIndex((t: { [x: string]: string }) => t['value'] === tag['value']);
    const tags = [].concat(this.state.education.tags, tag);
    this.setState({ education: { ...this.state.education, tags } }, () => {
      this.removeErrorAndReValidate('tags');
      if (ind > -1) {
        const lind = _.findLastIndex(tags, (o) => o.value === tag.value);
        this.handleTagDelete(lind);
      }
    });
  }

  public handleTagDelete = (i) => {
    const tags = this.state.education.tags.slice(0);
    tags.splice(i, 1);
    this.setState({ education: { ...this.state.education, tags } }, () => {
      this.removeErrorAndReValidate('tags');
    });
  }

  /**
   * @description
   * validateAndSubmit is used to validate the form by clicking on the next button.
   */
  public validateAndSubmit = () => {
    this.setState({ isSubmitted: true });
    if (this.selectedAvailabilities.length === MAGIC_NUMBER.ZERO) {
      this.setState({
        isErrorOnAvailability: true,
      });
    } else {
      const {
        selectedEducation,
        isErrorOnAvailability,
        education: { tags, languages },
      } = this.state;
      if (selectedEducation && !isErrorOnAvailability && languages.length > 0) {
        const { errors } = this.validator;
        this.validator.validateAll({
          tags,
        })
          .then((success: boolean) => {
            if (success) {
              this.props.successValidateSubmit();
            } else {
              this.setState({ errors });
            }
          });
      }
    }
  }

  public validateOnEditProfile = () => {
    const {
      isErrorOnAvailability,
      education: { tags, languages },
      selectedEducation,
    } = this.state;
    if (!languages.length || selectedEducation && selectedEducation.value === '') {
      this.setState({ isSubmitted: true });
    } else if (!this.selectedAvailabilities.length) {
      this.setState({ isErrorOnAvailability: true });
    } else {
      if (!isErrorOnAvailability && selectedEducation) {
        const { errors } = this.validator;
        this.validator.validateAll({
          tags,
        })
          .then((success: boolean) => {
            if (success) {
              this.props.successValidateSubmit();
            } else {
              this.setState({ errors });
            }
          });
      }
    }
  }
  public scrollToMyRef = () => {
    window.scrollTo(0, this.ref.current.offsetTop); }
  /**
   * @description
   * render method is used to render the form
   */
  public render() {
    const {
      selectedEducation,
      selectedLanguage,
      isSubmitted,
      education,
      education: { modeOfDelivery, tags },
      isAddedLanguageSubmitted,
      isErrorOnAlreadyAddedLanguage,
      isErrorOnProfeciency,
      isErrorOnAvailability,
      errors,
    } = this.state;

    const { props, tagList } = this.props;
    let params = {};
    if (props && props.location) {
      params = queryString.parse(props.location.search);
    }

    return (
      <div className="form-wrapper">
        <Form>
          <Row>
            <Col xs="12" sm="6">
              <FormGroup className="floating-label disabled-input">
                <Select
                  name="education"
                  styles={customSelectStyles}
                  value={selectedEducation}
                  onChange={this.handleEducation}
                  options={educations}
                  id="education"
                  placeholder="Highest Level of Education"
                  className="react-select-box select-box-group"
                  filterOption={createFilter(reactSelectFilters)}
                />
                <Label for="education" className={selectedEducation ? 'selected' : ''}>Highest Level of Education</Label>
                {isSubmitted && selectedEducation && selectedEducation.value === '' &&
                  <div className="error-text">The education type field is required.</div>
                }
              </FormGroup>
            </Col>
            <Col xs="12" sm="6">
              <FormGroup
                className="floating-label disabled-input"
              >
                <Select
                  name="language"
                  value={selectedLanguage}
                  styles={customSelectStyles}
                  onChange={this.handleLanguageSelection}
                  options={languages}
                  id="education"
                  placeholder="Language"
                  className="react-select-box select-box-group"
                  filterOption={createFilter(reactSelectFilters)}
                />
                <Label for="education" className={selectedLanguage ? 'selected' : ''}>Language</Label>
                {isAddedLanguageSubmitted && isErrorOnAlreadyAddedLanguage &&
                  <div className="error-text">This language is already added.</div>
                }
                {((isAddedLanguageSubmitted && !selectedLanguage) || (isSubmitted && education.languages.length === MAGIC_NUMBER.ZERO)) &&
                  <div className="error-text">The language field with proficiency is required.</div>
                }
              </FormGroup>
            </Col>
            <Col xs="12">
              <p className="form-small-text mt-0">Language Proficiency</p>
              <Row className="add-language-options">
                {this.renderLanguageProfeciency()}
              </Row>
              {isAddedLanguageSubmitted && isErrorOnProfeciency &&
                <div className="error-text timeline-error">The profeciency field is required.</div>
              }
              {this.state.education.languages.length < staticConstants.MAX_LENGTH.LANGUAGE &&
                <Row>
                  <Col xs="12" className="d-flex justify-content-end add-button-group">
                    <Button className="btn btn-icon" onClick={this.addLanguage}><em>+</em>Add Language</Button>
                  </Col>
                </Row>
              }
              {this.state.education.languages.length > 0 ?
                <Row>
                  <Col xs="12">
                    <div className="selected-language">
                      <div className="language-head">
                        <span>Language</span>
                        <span>Read</span>
                        <span>Write</span>
                        <span>Speak</span>
                        <span>Action</span>
                      </div>
                      {this.renderAddedLanguages()}
                    </div>
                  </Col>
                </Row>
                : ''}
            </Col>
            <Col xs="12">
              <p className="form-small-text">Mode of Delivery</p>
              <Row className="add-mode-options">
                <Col xs="4" sm="3">
                  <label className="control control--radio">
                    <Input
                      type="radio"
                      name="modeOfDelivery"
                      value="both"
                      checked={modeOfDelivery === 'both'}
                      onChange={this.handleChange}
                    />
                    Both
                      <div className="control__indicator"/>
                  </label>
                </Col>
                <Col xs="4" sm="3">
                  <label className="control control--radio">
                    <Input
                      type="radio"
                      name="modeOfDelivery"
                      value="audio"
                      checked={modeOfDelivery === 'audio'}
                      onChange={this.handleChange}
                    />
                    Audio
                      <div className="control__indicator"/>
                  </label>
                </Col>
                <Col xs="4" sm="3">
                  <label className="control control--radio">
                    <Input
                      type="radio"
                      name="modeOfDelivery"
                      value="video"
                      checked={modeOfDelivery === 'video'}
                      onChange={this.handleChange}
                    />
                    Video
                      <div className="control__indicator"/>
                  </label>
                </Col>
              </Row>
            </Col>
            <Col xs="12">
              <p className="form-small-text">Days of Availability </p>
              <Row className="add-day-options">
                {this.renderAvailability()}
              </Row>
              {isErrorOnAvailability &&
                <div className="error-text timeline-error">Days of Availability field is required.</div>
              }
              <Row className="mt-3" ref={this.ref}>
                <Col>
                  <FormGroup className="autocomplete-wrapper">
                    <Label for="address">What sector-based categories best describe your work? (Start typing to see your tags)</Label>
                    <SelectTags
                      selectedTags={tags}
                      onAdd={this.handleTagAdd}
                      limitTags={MAGIC_NUMBER.TEN}
                    />
                    {errors.has('tags') &&
                      <div className="error-text">{errors.first('tags')}</div>
                    }
                    <em>Maximum 10 Tags</em>
                  </FormGroup>
                </Col>
              </Row>
              {params && params['isOpenRequest'] && params['bidId'] &&
                <Row className="mt-3">
                  <Col>
                    <Label for="address" className="heading heading-sm mt-3 roboto-bold">
                      Add one or more of the following tags to your profile to match with this Request.
                    </Label>
                    <div className="w-100">
                      {tagList.map((tag: any) =>
                        tag && <span key={tag['value']} className="tags-view-only">{tag['name']}</span>)}
                    </div>
                  </Col>
                </Row>
              }
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { searchTags } = state.tags;
  return {
    tagsSuggestions: getUpdatedOptions(searchTags, 'name'),
  };
}

const connectedUpdateEducationFormPage = connect(mapStateToProps, null, null, { forwardRef: true })(UpdateEducationForm);
export { connectedUpdateEducationFormPage as UpdateEducationForm };
