import { history } from '../../../../helpers';
import { IProfile } from '../../../../interfaces';
import { pageRoutes } from '../../../../utils/routeConstants';
import { userServices } from './../../../../services';
import { alertActions } from './../../../alert/redux/actions';
import { endLoading, startLoading } from './../../../loader/redux/actions';
import { actionTypes } from './types';
import OpenFieldTypes from 'OpenFieldTypes';

export const registerAction = {
  register,
  checkExistingExpert,
};

function register(user: IProfile | any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(user));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.expertRegister(user)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(res));
          history.push(`${pageRoutes.EXPERTREGISTER.SUCCESS}`);
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request(res: IProfile) { return { type: actionTypes.EXPERT_REGISTER_REQUEST, res }; }
  function success(res: IProfile) { return { type: actionTypes.EXPERT_REGISTER_SUCCESS, res }; }
  function failure(error: string) { return { type: actionTypes.EXPERT_REGISTER_FAILURE, error }; }
}

function checkExistingExpert(email: string, cb: (status: boolean) => void) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.checkExistingExpert(email)
      .then(
        () => {
          dispatch(endLoading('MAIN_LOADER'));
          cb(true);
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          cb(false);
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };
}
