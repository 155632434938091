import _ from 'lodash';
import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { LocationDescriptorObject } from 'history';
import Pagination from 'react-js-pagination';
import { v4 } from 'uuid';
import {
  tableItemsCount, MAGIC_NUMBER,
} from '../../../utils';
import { ListItem } from './listItem';
import InfiniteScroll from 'react-infinite-scroll-component';
interface IProps {
  user?: any;
  tableContent: any;
  listData: any;
  tableHeaderClasses?: any;
  updateStatus?: any;
  updateHourlyRate?: any;
  updateFlaggedComment?: any;
  updateUserRequest?: any;
  updateLicenceMovementRequest?: any;
  deleteItem?: any;
  publishTopic?: any;
  filterObj?: {
    activePage?: number;
    sort: string;
    sortBy: number;
  };
  location: LocationDescriptorObject;
  selectedOrganization?: any;
  activeSubTab?: string;
  activeTab?: string;
  selectedUsers?: Array<[]>;
  parentTab?: string;
  isFromTags?: boolean;
  tagCount?: number;
  tableClass?: string;
  handlePageChange?: (pageno: number) => void;
  handleSorting?: (sort: any, sortBy: any) => void;
  reVerifyUser?: (userId: string) => void;
  fetchMoreData?: () => void;
  editTags?: (type: string, itemData: object) => void;
  resolveEventConflict?: (bidId: string) => void;
  usersRequestPayload?: any;
}

class ListingContainer extends Component<IProps & RouteComponentProps> {

  /**
   * @description getDerivedStateFromProps is called when there in change in property
   * @param fields {Object} props
   */
  public static getDerivedStateFromProps(props: IProps) {
    const updateState = {};
    if (props.listData && props.listData.list) {
      updateState['listData'] = props.listData;
    }
    if (props.tableContent) {
      updateState['tableContent'] = props.tableContent;
    }
    if (props.filterObj) {
      updateState['filterObj'] = props.filterObj;
    }
    return updateState;
  }

  public state = {
    items: tableItemsCount,
    filterObj: {
      activePage: 1,
      sort: '',
      sortBy: 0,
    },
    listData: {
      count: 0,
      list: [],
      fetchedListing: false,
      fetchedEventListing: false,
    },
    fetchGoal: false,
    goalId: '',
    activeTab: null,
    tableContent: this.props.tableContent,
    allSelected: false
  };

  componentDidUpdate(prevProps) {
    const { activeTab } = this.props;
    if (activeTab && activeTab !== prevProps.activeTab) {
      this.setState({ allSelected: false });
    }
    if (prevProps?.listData?.list?.length !== this.props?.listData?.list?.length ||
      prevProps?.listData?.list?.[0]?._id !== this.props?.listData?.list?.[0]?._id
      ) {
      let allCheck = false
      for (let i = 0; i < this.props.listData.list.length; i++) {
        if (this.props?.selectedUsers?.includes(this.state?.listData?.list[i]?._id)) {
          allCheck =true
        } else {
          allCheck = false
          break;
        }
      }
      this.setState({ allSelected: allCheck });
    }
  }

    /**
   * @description
   * handlePageChange is called someone click on the pagination.
   * @param fields {Number} pageno
   */
     public isAllChecked = (itemChecked: any) => {
       let allCheck = false
       if (this.props.selectedUsers.includes(itemChecked?._id)) {
       for (let i = 0; i < this.state.listData.list.length; i++) {
         if (itemChecked?._id === this.state.listData.list[i]._id) {
          this.setState({  allSelected: false });
           break;
         }
       }
       } else {
         let count =0
         const updatedArray = [...this.props.selectedUsers]
         updatedArray.push(itemChecked?._id)
         let status = true
        for (let i = 0; i < this.state.listData.list.length; i++) {
          if (!(updatedArray.includes(this.state.listData.list[i]._id))) {
            status = false
            break
          }
       }
       allCheck =status;
       this.setState({  allSelected: allCheck });
    }
  }

  /**
   * @description
   * handlePageChange is called someone click on the pagination.
   * @param fields {Number} pageno
   */
   public handlePageChange = (pageno: number) => {
    this.setState({
      activePage: pageno,
    });
    this.props.handlePageChange(pageno);
  }

  /**
   * @description
   * sortData is used for sorting purpose.
   * @param fields {Number} sort
   * @param fields {String} sortBy
   */
  public sortData(sort: any, sortBy: any) {
    let sortData = '';
    switch (sort) {
      case 'licenceMovementRequestDate':
        sortData = 'licenceMovement.date';
        break;
      case 'licenceCount':
        sortData = 'licenceMovement.count';
        break;
      default:
        sortData = sort;
    }
    this.props.handleSorting(sortData, sortBy);
  }

  /**
   * @description
   * renderListData is called to render the table data
   */
  public renderListData() {
    const { list } = this.state.listData;
    const { tableContent } = this.props;
    return _.map(list, (item) => {
      return (
        <ListItem
          key={v4()}
          tableContent={tableContent}
          item={item}
          deleteItem={this.props.deleteItem}
          updateUserRequest={this.props.updateUserRequest}
          resolveEventConflict={this.props.resolveEventConflict}
          updateLicenceMovementRequest={this.props.updateLicenceMovementRequest}
          updateHourlyRate={this.props.updateHourlyRate}
          updateFlaggedComment={this.props.updateFlaggedComment}
          updateStatus={this.props.updateStatus}
          selectedUsers={this.props.selectedUsers}
          selectedOrganization={this.props.selectedOrganization}
          reVerifyUser={this.props.reVerifyUser}
          activeSubTab={this.props.activeSubTab}
          activeTab={this.props.activeTab}
          parentTab={this.props.parentTab}
          publishTopic={this.props.publishTopic}
          editTags={this.props.editTags}
          user={this.props.user}
          isAllSelected={this.isAllChecked}
          usersRequestPayload={this.props.usersRequestPayload}
        />
      );
    });
  }

  public handleSelectAll = (clickAction: (isSelectedAll: boolean) => {}) => {
    if (typeof clickAction === 'function') {
      this.setState({ allSelected: !this.state.allSelected }, () => clickAction(this.state.allSelected));
    }
  }

  /**
   * @description
   * renderTableHeader is called to render the table header
   */
  public renderTableHeader() {
    const { tableContent } = this.props;
    const { sort, sortBy } = this.state.filterObj;
    const { allSelected } = this.state;
    const htmlContent = [];
    for (const key in tableContent) {
      htmlContent.push(
        <div className={`table-col ${tableContent[key]['class']}`} key={key} onClick={() => this.handleSelectAll(tableContent[key].onClick)}>
          <span className="sorting-icon">{tableContent[key]['name']}<strong>
            {tableContent[key]['sort'] ?
              <React.Fragment>
                <em className={(sort === `${key}` && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                  onClick={() => { this.sortData(`${key}`, MAGIC_NUMBER.ONE); }} />
                <em className={(sort === `${key}` && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                  onClick={() => { this.sortData(`${key}`, MAGIC_NUMBER.FOUND_INDEX); }} />
              </React.Fragment>
              : ''}
            {tableContent[key]['onClick'] ?
              <React.Fragment>
                <input type="checkbox" checked={allSelected} onChange={() => this.setState({ allSelected: !allSelected })} />
              </React.Fragment>
              : ''}
          </strong>
          </span>
        </div>,
      );
    }
    return htmlContent;
  }

  /**
   * @description
   * render is used to render HTML
   */
  public render() {
    const { filterObj: { activePage }, items, listData } = this.state;
    let minDisplay;
    let maxDisplay;
    if (listData.count === MAGIC_NUMBER.ZERO) {
      maxDisplay = MAGIC_NUMBER.ZERO;
      minDisplay = MAGIC_NUMBER.ZERO;
    } else if (listData.count <= items) {
      maxDisplay = listData.count;
      minDisplay = MAGIC_NUMBER.ONE;
    } else {
      maxDisplay = (Number(activePage) * Number(items)) < listData.count
        ? (Number(activePage) * Number(items)) : listData.count;
      minDisplay = ((Number(activePage) - 1) * Number(items)) + 1;
    }
    let tableHeaderClasses = this.props.tableHeaderClasses;
    if (!tableHeaderClasses) {
      tableHeaderClasses = 'table-head';
    }

    return (
      <React.Fragment>
        <div className={`table-wrapper ${this.props.tableClass}`}>
          <div className="flex-table">
            <div className={tableHeaderClasses}>
              <div className="table-row">
                {this.renderTableHeader()}
              </div>
            </div>
            <div className="table-body">
              {this.props.isFromTags && listData && listData['list']
                ?
                <InfiniteScroll
                  dataLength={listData['list'].length}
                  next={this.props.fetchMoreData}
                  hasMore={true}
                >

                  {this.renderListData()}
                  {listData && this.props.tagCount === MAGIC_NUMBER.ZERO ?
                    <div className="table-row table-no-data">
                      No Results Found.
                    </div>
                    : ''}
                </InfiniteScroll>
                :
                <React.Fragment>
                  {this.renderListData()}
                  {listData && listData['list'] && (!listData['list'].length || listData.count === MAGIC_NUMBER.ZERO) ?
                    <div className="table-row table-no-data">
                      No Results Found.
                    </div>
                    : ''}
                </React.Fragment>
              }
            </div>
            {
              (listData && listData.count > MAGIC_NUMBER.TEN) &&
              <div className="table-footer">
                <React.Fragment>
                  <p>Showing {minDisplay} - {maxDisplay} of {listData.count}</p>
                  <div className="pagination-wrapper">
                    <Pagination
                      hideFirstLastPages
                      activePage={activePage}
                      itemsCountPerPage={items}
                      totalItemsCount={listData.count}
                      pageRangeDisplayed={MAGIC_NUMBER.FIVE}
                      onChange={this.handlePageChange}
                    />
                  </div>
                </React.Fragment>
              </div>
            }
          </div>
        </div>
      </React.Fragment >
    );
  }
}

const listingContainerWithRouter = withRouter(ListingContainer);

export { listingContainerWithRouter as ListingContainer };
