import { IResetPasswordReq } from '../../../../interfaces';
import { history } from './../../../../helpers';
import { userServices } from './../../../../services/userServices';
import { alertActions } from './../../../alert/redux/actions';
import { endLoading, startLoading } from './../../../loader/redux/actions';
import { actionTypes } from './types';
import OpenFieldTypes from 'OpenFieldTypes';

export const resetPasswordAction = {
  resetPassword,
  verifyToken,
};

function resetPassword(data: IResetPasswordReq) {
  return (dispatch: OpenFieldTypes.RootAction) => {
      dispatch(request(data));
      dispatch(startLoading('MAIN_LOADER', 'Loading'));
      userServices.resetPassword(data)
            .then(
                (res) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(success(res));
                  dispatch(alertActions.successAlert(res));
                  history.push('/login');
                },
                (error) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.errorAlert(error.toString()));
                },
            );
    };

  function request(res: IResetPasswordReq) { return { type: actionTypes.RESET_PASSWORD_REQUEST, res }; }
  function success(res: any) { return { type: actionTypes.RESET_PASSWORD_SUCCESS, res }; }
  function failure(error: string) { return { type: actionTypes.RESET_PASSWORD_FAILURE, error }; }
}

function verifyToken(token: string) {
  return (dispatch: OpenFieldTypes.RootAction) => {
      dispatch(request(token));
      dispatch(startLoading('MAIN_LOADER', 'Loading'));
      userServices.verifyForgotPasswordToken(token)
            .then(
                (res) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(success(res));
                },
                (error) => {
                  dispatch(failure(error.toString()));
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(alertActions.errorAlert(error.toString()));
                },
            );
    };

  function request(data: string) { return { type: actionTypes.VERIFY_RESET_PASSWORD_TOKEN_REQUEST, data }; }
  function success(data: string) { return { type: actionTypes.VERIFY_RESET_PASSWORD_TOKEN_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.VERIFY_RESET_PASSWORD_TOKEN_FAILURE, error }; }
}
