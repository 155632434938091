import { actionTypes } from './types';

interface IAction {
  type: string;
  error: string;
}
export function sendFeedback(state = {}, action: IAction) {
  switch (action.type) {
    case actionTypes.SEND_FEEDBACK:
      return { requestingSendFeedback: true };
    case actionTypes.SEND_FEEDBACK_SUCCESS:
      return { requestedSendFeedback: false };
    case actionTypes.SEND_FEEDBACK_FAILURE:
      return { requestedSendFeedback: false , error: action.error };
    default:
      return state;
  }
}
