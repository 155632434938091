import React, { Dispatch } from "react";
import OpenFieldTypes from "OpenFieldTypes";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Form, FormGroup, Input, Label, NavLink } from "reactstrap";
import ReeValidate from "ree-validate";
import logoImage from "./../../assets/images/logo.svg";
import facebookImg from "./../../assets/images/Facebook.png";
import linkedinImg from "./../../assets/images/linkedin.png";
import twitterImg from "./../../assets/images/Twitter.png";
import emailIcon from "./../../assets/images/email_icon.png";
import passwordIcon from "./../../assets/images/lock.png";
import { validator } from "./../../helpers";
import { IValidator } from "./../../interfaces";
import { pageRoutes } from "./../../utils/routeConstants";
import { reeValidators } from "./../../utils/staticConstants";
import SocialButton from "../shared/socialLogin/socialButton";
import TwitterLogin from "react-twitter-auth/lib/react-twitter-auth-component.js";
import { LinkedIn } from "react-linkedin-login-oauth2";
import { authAction } from "./redux/actions";
import { registerFbAccountWithEmail, restrictSpaceEnter } from "../../utils";
import { ConfirmatioPopup } from "../shared/modals/confirmationPopup";
import { staticConstants } from "../../utils/staticConstants";
import { alertActions } from "./../alert/redux/actions";

validator(ReeValidate);

interface IProps {
  isOrgCodeModalOpen?: boolean;
  email?: object;
  password?: boolean;
  isEmailRequired?: boolean;
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
}

interface IState {
  email: string;
  password: string;
  submitted: boolean;
  errors?: any;
  formData?: {};
  emailPopUp: boolean;
  facebookObj: any;
  passwordVisible: any;
}

class LoginForm extends React.Component<IProps, IState> {
  public validator: IValidator;
  constructor(props: IProps) {
    super(props);
    // reset login status
    this.validator = new ReeValidate({
      email: reeValidators.email,
      password: reeValidators.pass,
    });
    this.state = {
      email: "",
      password: "",
      submitted: false,
      errors: this.validator.errors,
      emailPopUp: false,
      facebookObj: {},
      passwordVisible: false,
    };
    this.onTwitterLogin = this.onTwitterLogin.bind(this);
  }

  public handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const { errors } = this.validator;
    this.setState({
      [name]: value,
    } as any);
    errors.remove(name);
    this.setState({ formData: { ...this.state, [name]: value } });

    this.validator.validate(name, value).then(() => {
      this.setState({ errors });
    });
  };
  public validateAndSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const { email, password } = this.state;
    const { errors } = this.validator;

    this.validator.validateAll({ email, password }).then((success: boolean) => {
      if (success) {
        this.handleSubmit();
      } else {
        this.setState({ errors });
      }
    });
  };

  public handleSubmit = () => {
    this.setState({ submitted: true });
    const dispatch = this.props.dispatch;
    const { email, password } = this.state;
    if (email && password) {
      dispatch(authAction.login(email, password));
    }
  };

  public handleSocialLogin = (user) => {
    const dispatch = this.props.dispatch;
    const reqObj = {
      facebook: {
        firstName: user._profile.firstName,
        lastName: user._profile.lastName,
        email: user._profile.email ? user._profile.email : "",
        facebookId: user._profile.id,
        token: user._token.accessToken,
      },
    };
    this.setState({ facebookObj: reqObj });
    dispatch(authAction.socialLogin(reqObj));
    if (!user._profile.email) {
      this.setState({ emailPopUp: true });
    }
  };

  public handleLoginFailure = (err) => {};
  public passwordVisible = () => {
    this.setState({
      passwordVisible: !this.state.passwordVisible,
    });
  };
  public handleLinkedinLogin = (res) => {
    const dispatch = this.props.dispatch;
    const reqObj = {
      linkedin: {
        code: res.code,
      },
    };
    dispatch(authAction.socialLogin(reqObj));
  };

  public onTwitterLogin(response) {
    response
      .json()
      .then((body: { data: { role: string } }) => {
        if (body.data) {
          this.setDataToLocalStorage(
            body.data,
            body.data.role !== staticConstants.ROLE.ORGANIZATION_ADMIN &&
              body.data.role !== staticConstants.ROLE.ORGANIZATION_LEARNER
          );
        }
      })
      .catch(function (err) {
        this.props.dispatch(alertActions.errorAlert(err));
      });
  }

  public setDataToLocalStorage = (userData, condiion) => {
    const token = userData.token;
    const dispatch = this.props.dispatch;
    if (condiion) {
      localStorage.setItem("user", JSON.stringify(userData));
      localStorage.setItem("token", token);
    }
    dispatch(authAction.twitterLoginSuccess(userData));
  };

  public render() {
    const { email, password, errors, passwordVisible } = this.state;
    const { isEmailRequired } = this.props;
    return (
      <>
        {isEmailRequired && this.state.emailPopUp ? (
          <ConfirmatioPopup
            isOpenModal={isEmailRequired && this.state.emailPopUp}
            hideModal={() => this.setState({ emailPopUp: false })}
            modalPopupObj={registerFbAccountWithEmail}
            onClickYes={() => this.setState({ emailPopUp: false })}
          />
        ) : (
          ""
        )}
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-12 login_bg order-md-2">
              <div className="login_banner_text">
                <p>
                  Everyone is going to need to understand [climate change] the
                  same way you’d assume everyone in business needs to have some
                  fluency in social media today.
                </p>
                <h5>Andrew Winston</h5>
                <p className="text-right">Author</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="login_form">
                <div className="row">
                  <div className="col-12">
                    <div className="signup_account">
                      <span>Don’t have an account?</span>
                      <Link to="/usertype" className="signup_btn">
                        Sign Up
                      </Link>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="login_details">
                      <NavLink href="/" className="remove-left">
                        <img
                          src={logoImage}
                          alt="Logo"
                          className="img-fluid mb-4"
                        />
                      </NavLink>
                      <h2 className="common_heading mt-2">Welcome Back!</h2>
                      <p>Login by filling in your email and password below.</p>
                      <Form onSubmit={this.validateAndSubmit} noValidate>
                        <FormGroup
                          className={`floating-label with_icon ${
                            errors.has("email") ? " has-error" : ""
                          }`}
                        >
                          <span className="left_icon">
                            <img src={emailIcon} alt="icon" />{" "}
                          </span>
                          <div className="common_input">
                            <Input
                              type="email"
                              className="form-control"
                              name="email"
                              id="email"
                              value={email}
                              disabled={this.props.isOrgCodeModalOpen}
                              onChange={this.handleChange}
                              placeholder="Email*"
                            />
                            <Label for="email">Email*</Label>
                          </div>
                          {errors.has("email") && (
                            <div className="error-text">
                              {errors.first("email")}
                            </div>
                          )}
                        </FormGroup>
                        <FormGroup
                          className={`floating-label with_icon with-action ${
                            errors.has("password") ? " has-error" : ""
                          }`}
                        >
                          <span className="left_icon">
                            <img src={passwordIcon} alt="icon" />{" "}
                          </span>
                          <div className="common_input">
                            <Input
                              // type="password"
                              type={passwordVisible ? "text" : "password"}
                              className="form-control"
                              name="password"
                              id="password"
                              value={password}
                              onChange={this.handleChange}
                              placeholder="Password*"
                            />
                            <Label for="Password">Password*</Label>
                          </div>
                          {errors.has("password") && (
                            <div className="error-text">
                              {errors.first("password")}
                            </div>
                          )}
                          <span
                            onClick={this.passwordVisible}
                            color="light"
                            className={`link-help password-icon ${
                              passwordVisible ? "show" : ""
                            }`}
                          />
                          <div className="text-right forget_pass mt-1">
                            <Link to={pageRoutes.FORGOT_PASSWORD.PATH}>
                              Forgot Password?
                            </Link>
                          </div>
                        </FormGroup>
                        <div className="login_button mt-3">
                          <Button
                            onKeyDown={restrictSpaceEnter}
                            color="get_start_btn"
                            className="get_start_btn cta_btn d-block text-center w-100"
                          >
                            Log In
                          </Button>
                        </div>
                      </Form>
                      <div className="or_with_line mt-4 mb-4">
                        <span>OR</span>
                      </div>
                      <div className="social_login">
                        <p>
                          If you are a 'Individual', you can also use the social
                          signin options.
                        </p>
                        <div className="social_login_icon pt-2">
                          <SocialButton
                            provider="facebook"
                            appId="2539671542927553"
                            onLoginSuccess={this.handleSocialLogin}
                            onLoginFailure={this.handleLoginFailure}
                          >
                            <img src={facebookImg} alt="Facebook" />
                          </SocialButton>
                          <TwitterLogin
                            loginUrl={process.env.REACT_APP_TWITTER_LOGIN_URL}
                            onFailure={this.handleLoginFailure}
                            onSuccess={this.onTwitterLogin}
                            requestTokenUrl={
                              process.env.REACT_APP_TWITTER_REQUEST_TOKEN_URL
                            }
                            showIcon={false}
                            forceLogin={true}
                          >
                            <img src={twitterImg} alt="Twitter" />
                          </TwitterLogin>
                          <LinkedIn
                            clientId="774r7e52y837a7"
                            redirectUri={`${window.location.origin}/linkedin`}
                            onFailure={this.handleLoginFailure}
                            onSuccess={this.handleLinkedinLogin}
                            scope="r_emailaddress"
                          >
                            {" "}
                            <img src={linkedinImg} alt="linkedin" />
                          </LinkedIn>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state: {
  authentication: { loggingIn: any; user: any; isEmailRequired: boolean };
}) {
  const { loggingIn, isEmailRequired } = state.authentication;
  return {
    loggingIn,
    isEmailRequired,
  };
}

const connectedLoginPage = connect(mapStateToProps)(LoginForm);
export { connectedLoginPage as LoginForm };
