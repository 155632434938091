import OpenFieldTypes from 'OpenFieldTypes';
import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import ReeValidate from 'ree-validate';
import { reeValidators } from '../../../utils/staticConstants';
import { validator } from '../../../helpers';

validator(ReeValidate);

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  successValidateSubmit: any;
  props?: any;
}

interface IState {
  referred: any;
  isSubmitted: boolean;
  errors?: any;
}

class ReferredForm extends React.Component<IProps, IState> {

  public validator: any;
  public ref: any;

  constructor(props: IProps) {
    super(props);
    this.ref = React.createRef();
    this.validator = new ReeValidate({
      referredBy: reeValidators.email
    });
    this.state = {
      referred: {
        isReferred: true,
        referredBy: ''
      },
      isSubmitted: false,
      errors: this.validator.errors,
    };
  }

  public componentDidUpdate(prevProps) {

  }

  public removeErrorAndReValidate = (name: string) => {
    const { errors } = this.validator;
    errors.remove(name);
    this.validator.validate(name, this.state.referred.referredBy)
      .then(() => {
        this.setState({ errors });
      });
  }

  /**
   * @description
   * handleChange is used to set the value on state from the only input/textarea boxes.
   * @param fields {Object} event
   */
  public handleChange = (event: any) => {
    const { name, value } = event.target;
    this.setState({ referred: { ...this.state.referred, [name]: value } }, () => {
      if (name === 'referredBy' && (this.state.referred.isReferred === 'true' || this.state.referred.isReferred === true)) {
        this.removeErrorAndReValidate(name);
      }
    });
    if (name === 'isReferred' && (value === false || value === 'false')) {
      const { errors } = this.validator;
      errors.remove('referredBy');
    }

  }


  /**
   * @description
   * validateAndSubmit is used to validate the form by clicking on the next button.
   */
  public validateAndSubmit = () => {
    const emailField = 'referredBy';
    const { referred } = this.state;
    const { errors } = this.validator;
    if (referred.isReferred === 'true' || referred.isReferred === true) {
      this.validator.validate(emailField, referred.referredBy).then(() => {
        this.setState({ errors }, () => {
          if (!errors.has(emailField)) {
            this.props.successValidateSubmit();
          }
        });
      });

    } else {
      if (errors.has(emailField)) {
        errors.remove(emailField);
      }
      this.props.successValidateSubmit();
    }
  }

  public scrollToMyRef = () => {
    window.scrollTo(0, this.ref.current.offsetTop);
  }
  /**
   * @description
   * render method is used to render the form
   */
  public render() {
    const { errors, referred } = this.state;

    return (
      <Form autoComplete="off">
        <Row>
          <Col xs="12">
            <p className="new_que mb-3">A) Were you referred to Overlap?</p>
            <div className="option-wrapper">
              <label className="control control-btn">
                <Input
                  type="radio"
                  name="isReferred"
                  value="true"
                  onChange={this.handleChange}
                  checked={referred.isReferred === true || referred.isReferred === 'true'}
                />
                  <span>Yes</span>
              </label>
              <label className="control control-btn">
                <Input
                  type="radio"
                  name="isReferred"
                  value="false"
                  onChange={this.handleChange}
                  checked={referred.isReferred === false || referred.isReferred === 'false'}
                />
                  <span>No</span>
              </label>
            </div>
          </Col>
          <Col xs="12" sm="5">
            <p className="new_que mb-3">B) By whom were you referred?</p>
            <FormGroup className={`floating-label ${
                  errors.has('referredBy') ? ' has-error' : ''
                  }`}>
              <Input
                type="text"
                className="form-control"
                name="referredBy"
                id="referredBy"
                value={referred.referredBy}
                onChange={this.handleChange}
                placeholder="Enter email address"
              />
              <Label for="referredBy">Enter email address</Label>
              {errors.has('referredBy') &&
                <div className="error-text">{errors.first('referredBy').replace('referredBy', 'referred by')}</div>
              }
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  }
}

function mapStateToProps(state: any) {
  const { registering } = state.registeration;
  return {
    registering,
  };
}

const connectedReferredFormPage = connect(mapStateToProps, null, null, { forwardRef: true })(ReferredForm);
export { connectedReferredFormPage as ReferredForm };
