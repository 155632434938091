import { actionTypes } from './types';
import { staticConstants } from '../../../../utils';
interface IAction {
  type: string;
  data: {};
  error: string;
  userData: Array<[]>;
}

const initialState = {
  status: {},
};

export function user(state = initialState, action: IAction) {
  switch (action.type) {
    case actionTypes.USERS_LIST_SUCCESS:
      let dashboardData;
      if (action.userData['type'] === staticConstants.USER_MANAGEMENT_TAB.FLAG_PROFILES) {
        dashboardData = action.data && action.data['data'];
      }
      return {
        ...state,
        count: action.data && action.data['count'],
        list: action.data && action.data['data'],
        dashboardList: dashboardData,
        fetchedUsersListing: true,
        sendComment: false,
      };
    case actionTypes.CREATE_USER_SUCCESS:
      return {
        ...state,
        status: {
          successForCreateUser: true,
          failureForCreateUser: false,
        },
        email: action.data['email'],
      };
    case actionTypes.CREATE_USER_REQUEST:
      return {
        ...state,
        status: {
          requestForCreateUser: true,
          successForCreateUser: false,
          failureForCreateUser: false,
        },
      };
    case actionTypes.USERS_LIST_REQUEST:
      return {
        ...state,
        fetchingUsersListing: true,
        sendComment: false,
      };
    case actionTypes.USER_DETAIL_SUCCESS:
      return {
        ...state,
        detail: action.data,
      };
    case actionTypes.UPDTAE_USER_STATUS_SUCCESS:
      const findIndex = state['list'].findIndex(val => val['_id'] === action.data['userId']);
      state['list'][findIndex].isActive = (action.data['action'] === 'active');
      return {
        ...state,
        successForUpdateUserStatus: true,
      };
    case actionTypes.DELETE_USER_SUCCESS:
      state['list'].splice(state['list'].findIndex(val => val['_id'] === action.data['userId']), 1);
      return {
        ...state,
        successForDeleteUser: true,
      };
    case actionTypes.CHANGE_RATE_SUCCESS:
      state['list'].splice(state['list'].findIndex(val => val['_id'] === action.data['userId']), 1);
      return {
        ...state,
        successForChangeRate: true,
      };
    case actionTypes.UPDTAE_USER_REQUEST_STATUS_SUCCESS:
      state['list'].splice(state['list'].findIndex(val => val['_id'] === action.data['userId']), 1);
      return {
        ...state,
        successForUserRequestStatus: true,
      };
    case actionTypes.UPDTAE_USER_REQUEST_STATUS_SUCCESS_DETAIL:
      return {
        ...state,
        successForUserRequestStatus: true,
      };
    case actionTypes.UPDTAE_USER_STATUS_SUCCESS_FROM_DETAIL:
      state['detail'].isActive = (action.data['action'] === 'active');
      let list = state['list'];
      if (action.data['action'] === 'inactive') {
        list = list.map((l: any) => {
          return {
            ...l,
            isActive: false,
          };
        });
      }
      state['list'] = list;
      return {
        ...state,
        successForUpdateUserStatus: true,
      };
    case actionTypes.RESET_POPUP_STATUS:
      return {
        ...state,
        successForUpdateUserStatus: false,
        successForDeleteUser: false,
        successForChangeRate: false,
        successForUserRequestStatus: false,
      };
    case actionTypes.GENERATE_REPORT_SUCCESS:
      return {
        ...state,
        downloadUrl: action.data['url'],
        downloadFileName: action.data['reportTitle'],
        successDownloadReport: true,
      };
    case actionTypes.RESET_GENERATE_REPORT:
      return {
        ...state,
        downloadUrl: undefined,
        successDownloadReport: false,
      };
    case actionTypes.RESET_CREATE_USER_STATUS:
      return {
        ...state,
        status: {
          requestForCreateUser: false,
          successForCreateUser: false,
          failureForCreateUser: false,
        },
      };
    case actionTypes.CREATE_USER_FAILURE:
      return {
        ...state,
        status: {
          requestForCreateUser: false,
          successForCreateUser: false,
          failureForCreateUser: true,
        },
      };
    case actionTypes.RE_VERIFY_USER_SUCCESS:
      return {
        ...state,
        successForReVerifyUser: true,
      };
    case actionTypes.RE_VERIFY_USER_FAILURE:
      return {
        ...state,
        errorForReVerifyUser: true,
      };
    case actionTypes.USERS_LIST_FAILURE:
    case actionTypes.COMMENT_REQUEST:
      return {
        ...state,
      };
    case actionTypes.COMMENT_SUCCESS:
      return {
        ...state,
        sendComment: true,
      };
    default:
      return state;
  }
}
