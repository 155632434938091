import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import ReeValidate from 'ree-validate';
import OpenFieldTypes from 'OpenFieldTypes';
import { IViewProfile } from '../../../../interfaces/index';
import { sendFeedbackAction } from './redux/actions';
import { profileAction } from '../../../shared/updateProfile/redux/actions';
import { IValidator } from './../../../../interfaces';
import { MAGIC_NUMBER } from '../../../../utils';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  isOpenModal: boolean;
  hideModal: any;
  viewProfile: IViewProfile;
}

interface IState {
  submitted: boolean;
  feedback: string;
  errors?: any;
  modal: boolean;
}

class FeedbackForm extends React.Component<IProps, IState> {

  public validator: IValidator;
  constructor(props: IProps) {
    super(props);

    // reset login status
    this.validator = new ReeValidate({
      feedback: 'required',
    });

    this.state = {
      feedback: '',
      submitted: false,
      errors: this.validator.errors,
      modal: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.validateAndSubmit = this.validateAndSubmit.bind(this);
  }

  public componentDidMount = () => {
    const { dispatch } = this.props;
    dispatch(profileAction.getProfile());
  }

  public componentWillReceiveProps(props: IProps, nextProp: IProps) {
    this.setState({
      modal: props.isOpenModal,
    });
  }

  public handleChange(e: React.ChangeEvent<HTMLInputElement>
    | React.ChangeEvent<HTMLTextAreaElement>) {
    const { name, value } = e.target;
    const { errors } = this.validator;
    this.setState({
      [name]: value,
    } as any);
    errors.remove(name);
    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors });
      });
  }

  public validateAndSubmit(e: any) {
    e.preventDefault();
    const { feedback } = this.state;
    const { errors } = this.validator;
    this.validator.validateAll({ feedback })
    .then((success: any) => {
      if (success) {
        this.handleSubmit();
      } else {
        this.setState({ errors });
      }
    });
  }

  public handleSubmit() {
    this.setState({ submitted: true });
    const { feedback } = this.state;
    if (feedback) {
      this.props.dispatch(sendFeedbackAction.sendFeedback(feedback));
      this.props.hideModal();
    }
  }

  public hideModal() {
    this.setState({
      modal: false,
      feedback: '',
    });
    const { errors } = this.validator;
    errors.clear();
    this.props.hideModal();
  }

  public render() {
    const { feedback, errors, modal } = this.state;
    const { viewProfile } = this.props;

    return (
      <Modal isOpen={modal} className="organization-code-modal" onClosed={this.hideModal}>
        <ModalHeader toggle={this.hideModal}>Feedback Form</ModalHeader>
        <ModalBody>
          <Form onSubmit={this.validateAndSubmit} autoComplete="off" noValidate>
            {viewProfile && viewProfile.firstName ? <FormGroup
              className={`floating-label`}
            >
              <Input
                className="form-control"
                id="firstName"
                name="firstName"
                placeholder="Name"
                value={`${viewProfile && viewProfile.firstName} ${viewProfile && viewProfile.lastName}`}
              />
              <Label for="firstName">Name</Label>
            </FormGroup> : ''}
            {viewProfile && viewProfile.phoneNumber ? <FormGroup
              className={`floating-label`}
            >
              <Input
                className="form-control"
                id="phoneNumber"
                name="phoneNumber"
                placeholder="Phone Number"
                value={viewProfile && viewProfile.phoneNumber}
              />
              <Label for="phoneNumber">Phone Number</Label>
            </FormGroup> : ''}
            {viewProfile && viewProfile.email ? <FormGroup
              className={`floating-label`}
            >
              <Input
                className="form-control"
                id="email"
                name="email"
                placeholder="Email"
                value={viewProfile && viewProfile.email}
              />
              <Label for="email">Email</Label>
            </FormGroup> : ''}
            <FormGroup
              className={`floating-label disabled-input textarea-label mb-3 ${
                errors.has('feedback') ? ' has-error' : ''
                }`}
            >
                <textarea
                    className="form-control textarea-md"
                    name="feedback"
                    id="feedback"
                    value={feedback}
                    onChange={this.handleChange}
                    maxLength={MAGIC_NUMBER.CHAR_LENGTH}
                    placeholder="Tell us what you think of Overlap."
                />
                <span className="text-count">{feedback.length}/500</span>
                <Label for="summary" className={feedback ? 'selected' : ''}>
                    Write Feedback...
                </Label>
                {errors.has('feedback') &&
                    <div className="error-text">
                        {errors.first('feedback').replace('feedback', '')}
                    </div>
                }
              </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-regular ml-0" onClick={this.hideModal}>Cancel</Button>{' '}
          <Button color="primary" className="mr-2" onClick={this.validateAndSubmit}>Submit</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

function mapStateToProps(state: any) {
  const { updateProfile } = state;
  return {
    viewProfile: updateProfile['profile'],
  };
}

const connectedFeedbackFormFormPage = connect(mapStateToProps)(FeedbackForm);
export { connectedFeedbackFormFormPage as FeedbackForm };
