import { actionTypes } from './types';

export function applicationFeedBack(state = {}, action: any) {
  switch (action.type) {
      case actionTypes.APPLICATION_FEEDBACK_LIST_REQUEST:
        return {
              applicationFeebackListRequest: true,
            };
      case actionTypes.APPLICATION_FEEDBACK_LIST_SUCCESS:
        return {
              count: action.data ? action.data['count'] : 0,
              list: action.data ? action.data['data'] : [],
              applicationFeebackListSuccess: true,
            };
      case actionTypes.SEND_REPLY_FEEDBACK_SUCCESS:
        return {
              ...state,
              replyFeebackSuccess: true,
            };
      case actionTypes.DELETE_FEEDBACK_SUCCESS:
        return {
              ...state,
              deleteFeedbackFeebackSuccess: true,
            };
      case actionTypes.APPLICATION_FEEDBACK_LIST_FAILURE:
      case actionTypes.SEND_REPLY_FEEDBACK_FAILURE:
      case actionTypes.DELETE_FEEDBACK_FAILURE:
        return {
              error: action.error,
              replyFeebackSuccess: false,
              deleteFeedbackFeebackSuccess: false,
            };
      default:
        return state;
    }
}
