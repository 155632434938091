import {
  GET_CONTENT_QUERY,
} from '../graphql-client/queries/contentManagementQuery';
import {
  UPDATE_CONTENT_MUTATION,
  DELETE_FAQ_MUTATION,
} from '../graphql-client/mutations/contentManagementMutations';
import { client } from '../index';
import { handleResponse } from '../utils/common';

export const contentManagementService = {
  getContent,
  addFaq,
  updateContent,
  deleteFaq,
};

async function getContent(reqObj: any) {
  const response = await client.query({
    query: GET_CONTENT_QUERY,
    variables: reqObj,
  });
  const resp = await handleResponse(response);
  return resp.getContent.data;
}

async function updateContent(reqObj: any) {
  const response = await client.mutate({
    mutation: UPDATE_CONTENT_MUTATION,
    variables: reqObj,
  });
  const resp = await handleResponse(response);
  return resp.updateContent.message;
}

async function addFaq(reqObj: any) {
  const response = await client.query({
    query: GET_CONTENT_QUERY,
    variables: reqObj,
  });
  const resp = await handleResponse(response);
  return resp.dashboard.data;
}

async function deleteFaq(id: any) {
  const response = await client.mutate({
    mutation: DELETE_FAQ_MUTATION,
    variables: id,
  });
  const resp = await handleResponse(response);
  return resp.deleteFaq.message;
}
