import React from 'react';
import OpenFieldTypes from 'OpenFieldTypes';
import { Sidebar } from '../sidebar';
import {
  Col,
  Row,
  Button,
} from 'reactstrap';
import { pageRoutes, MAGIC_NUMBER, staticConstants, languageProficiencies, getLocalUserData, checkAddOn, expertAddOnMsg } from './../../../utils';
import { IBidDetail, IParams, IViewProfile } from './../../../interfaces';
import { connect } from 'react-redux';
import { viewProfileAction } from './redux/action';
import moment from 'moment';
import { map } from 'lodash';
import { UnlockProfilePopup } from '../modals/unlockProfilePopup';
import { history } from '../../../helpers';
import { Back } from '../back/back';
import StarRatings from 'react-star-ratings';

interface IProps {
  dispatch: OpenFieldTypes.RootAction;
  profile: IViewProfile;
  bidDetail: IBidDetail;
  location: any;
  match: {
    params: {
      [key: string]: IParams;
    };
  };
}

interface IState {
  displayUnlockProfilePopup: boolean;
}

class ViewShortProfile extends React.Component<IProps, IState>  {

  constructor(props: IProps) {
    super(props);
    this.state = {
      displayUnlockProfilePopup: false,
    };
  }

  /**
   * @description componentDidMount is called when component is loaded
   * call goalAction.getrfpDetail to fetch goal detail
   */
  public componentDidMount() {
    const { match: { params } } = this.props;
    if (params.id) {
      this.props.dispatch(viewProfileAction.getShortProfile(String(params.id)));
    }
    if (params.bidId) {
      this.props.dispatch(viewProfileAction.getBidDetail(String(params.bidId)));
    }
    const locaUserData = getLocalUserData();
    if (locaUserData['isAddOn'] === true) {
      history.push({
        pathname: `${pageRoutes.VIEW_PROFILE_EXPERT.PATH}/${String(params.id)}/${String(params.bidId)}/complete`,
        state: this.props.location['state'],
      });
    }
  }

  public purchaseAddon = () => {
    const { match: { params } } = this.props;
    this.setState({ displayUnlockProfilePopup: false });
    history.push({
      pathname: `${pageRoutes.PAYMENT.PATH}${pageRoutes.PAYMENT.SUBSCRIPTION}`,
      state: {
        redirectUri: `${pageRoutes.VIEW_PROFILE_EXPERT.PATH}/${String(params.id)}/${String(params.bidId)}/complete`,
        action: 'purchase-addon',
        originUri: this.props.location['pathname'],
        originStateVars: this.props.location['state'],
      },
    });
  }
  public showUnclockProfileModal = () => {
    const locaUserData = getLocalUserData();
    const { match: { params } } = this.props;
    if (locaUserData['isAddOn'] === true) {
      history.push({
        pathname: `${pageRoutes.VIEW_PROFILE_EXPERT.PATH}/${String(params.id)}/${String(params.bidId)}/complete`,
        state: this.props.location['state'],
      });
    } else {
      this.setState({ displayUnlockProfilePopup: true });
    }
  }
  /**
   * @description
   * renderAddedProfeciency is used to show the profeciency
   */
  public renderAddedProfeciencyCheck(selectedProfeciencies: any) {
    return map(languageProficiencies, (item: any, index: any) => {
      if (item.value !== 'all') {
        let checkClass = 'icon-cross';
        if (selectedProfeciencies.includes(item.value)) {
          checkClass = 'icon-accept';
        }
        return (
          <span key={`addedProfeciencyCheck-${index}`}><em className={`icon ${checkClass}`}/></span>
        );
      }
    });
  }
  public renderAddedLanguages() {
    const { profile } = this.props;
    if (profile && profile.languages) {
      return map(profile.languages, (item: any, index: any) => {
        return (
          <>
            <div className="language-data" key={`addedLanguage-${index}`}>
              <span>{item.language}</span>
              {this.renderAddedProfeciencyCheck(item.profeciencies)}
            </div>
          </>
        );
      });
    }
    return '';
  }

  public render() {
    const { profile, bidDetail, location: { state: { activeTab, currentIndex } } } = this.props;
    const { displayUnlockProfilePopup } = this.state;
    let averageProfileRating = MAGIC_NUMBER.ZERO;
    let expertSessionRating = MAGIC_NUMBER.ZERO;
    if (profile && profile.averageRating && profile.averageSessionRating) {
      profile.averageRating = profile.averageRating || MAGIC_NUMBER.ZERO;
      profile.averageSessionRating = profile.averageSessionRating || MAGIC_NUMBER.ZERO;
      averageProfileRating = Number.isInteger(profile.averageRating)
        ? profile.averageRating
        : Number(profile.averageRating.toFixed(MAGIC_NUMBER.ONE));
      expertSessionRating = Number.isInteger(profile.averageSessionRating)
        ? profile.averageSessionRating
        : Number(profile.averageSessionRating.toFixed(MAGIC_NUMBER.ONE));
    }
    return (
      <React.Fragment>
        <Sidebar {...this.props} />
        {profile && bidDetail && <div className="dashboard-wrapper">
          <Back {...this.props} />
          <div className="dashboard-content shadow">
            <div className="profile-details-wrapper border-0">
              <div className="form-details basic-details">
                <h2 className="heading heading-lg roboto-bold mb-2">
                  {checkAddOn() ? `${profile.firstName} ${profile.lastName}` : `Bidder ${parseInt(currentIndex) + 1}`}
                </h2>
                <p className="sub-heading mb-4">{bidDetail.bidDetails}</p>
                <Row className="mb-4">
                  <Col xs="12" md="4" className="rating-count-2">
                    <div className="rating-view-only">
                      <p>Profile Ratings ({averageProfileRating}/5)</p>
                      <StarRatings
                        rating={averageProfileRating}
                        starHoverColor="#4be59e"
                        starEmptyColor="#dcdcdc"
                        starRatedColor="#4be59e"
                        starDimension="20px"
                        starSpacing="2px"
                        numberOfStars={MAGIC_NUMBER.FIVE}
                        name="averageRating"
                      />
                    </div>
                    <div className="rating-view-only">
                      <p>Session Ratings ({expertSessionRating}/5)</p>
                      <StarRatings
                        rating={expertSessionRating}
                        starHoverColor="#4be59e"
                        starEmptyColor="#dcdcdc"
                        starRatedColor="#4be59e"
                        starDimension="20px"
                        starSpacing="2px"
                        numberOfStars={MAGIC_NUMBER.FIVE}
                        name="averageRating"
                      />
                    </div>
                  </Col>
                </Row>
                <div className="form-data">
                  <Row>
                    <Col xs="12" md="3">
                      <span>Occupation</span>
                      <p>{profile.occupation}</p>
                    </Col>
                    <Col xs="12" md="3">
                      <span>Highest Level of Education</span>
                      <p>{profile.education}</p>
                    </Col>
                    <Col xs="12">
                      <span>Language & Proficiency</span>
                      <Row>
                        <Col xs="12">
                          <div className="selected-language language-view-mode mt-2">
                            <div className="language-head">
                              <span>Language</span>
                              <span>Read</span>
                              <span>Write</span>
                              <span>Speak</span>
                            </div>
                            {this.renderAddedLanguages()}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs="12" md="3">
                      <span>Proposed Date</span>
                      <p>{moment(bidDetail.proposedDate).format(staticConstants.DATE_FORMAT)}</p>
                    </Col>
                    <Col xs="12" md="3">
                      <span>Proposed Time</span>
                      <p>{moment(bidDetail.proposedTime).format(staticConstants.TIME_FORMAT)}</p>
                    </Col>
                    <Col xs="12">
                      <span>Tags</span>
                      <div className="w-100">
                        {profile.tags.map((tag) => {
                          return <em key={tag['value']} className="tags-view-only">{tag['name']}</em>;
                        })}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="card-footer d-flex align-items-center justify-content-end">
                {activeTab !== 'upcomingEvents' &&
                  <Button
                    color="primary"
                    className="btn btn-rg"
                    onClick={() => this.showUnclockProfileModal()}
                  >
                    See full Details
                  </Button>
                }
              </div>
            </div>
          </div>
        </div>}
        {displayUnlockProfilePopup &&
          <UnlockProfilePopup
            isOpenModal={displayUnlockProfilePopup}
            onClickYes={this.purchaseAddon}
            modalPopupObj={expertAddOnMsg}
            hideModal={() => this.setState({ displayUnlockProfilePopup: false })}
          />
        }
      </React.Fragment>
    );
  }
}
function mapStateToProps(state: any) {
  const { viewProfile } = state;
  return {
    profile: viewProfile['profile'],
    bidDetail: viewProfile['bidDetail'],
  };
}

const viewProfileConnected = connect(mapStateToProps)(ViewShortProfile);
export { viewProfileConnected as ViewShortProfile };
