import React, { useState, useEffect } from 'react';
import { staticConstants, MAGIC_NUMBER, CSS_ClASSES, pageRoutes } from '../../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { systemAdminPaymentAction } from '../redux/actions';
import { ListingContainer } from '../../../shared/listingManagement/listingContainer';
import { LocationDescriptorObject } from 'history';
import queryString from 'query-string';
import { history } from '../../../../helpers';
import { FilterComponent } from '../../../shared/filter/filterComponent';

interface IProps {
  location?: LocationDescriptorObject;
}
const fiveColumnClass = 'table-col-20';
const fourColumnClass = 'table-col-25';

function PaymentListingContainer(props: IProps) {
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(() => {
    const { location: { search } } = props;
    const { page = MAGIC_NUMBER.ZERO } = queryString.parse(search);
    return Number(page);
  });
  const [sort, setSort] = useState(null);
  const [searchKey, setSearchKey] = useState('');
  const [sortBy, setSortBy] = useState(1);
  const [filterTab, setFilterTab] = useState(staticConstants.PAYMENT_REQUEST_SUB_TABS.CURRENT);
  const paymentRequestList: any = useSelector((state: any) => state.systemAdminPayment?.paymentRequestList?.list.map(el => {
    return { name: el.user.name, amount: el.amount, userType: 'Expert', dateRequested: el.createdAt, payOutDate: el.payOutDate, userId: el.user._id, _id: el._id };
  }));

  const paymentRequestListCount: any = useSelector((state: any) => state.systemAdminPayment?.paymentRequestList?.count);

  const downloadFilePath = useSelector((state: any) => state.systemAdminPayment?.paymentRequestList?.downloadUrl);
  const successDownloadReport = useSelector((state: any) => state.systemAdminPayment?.successDownloadReport);

  const getReqObj = () => {

    let reqObj = {
      page: Number(activePage),
      limit: MAGIC_NUMBER.TEN,
      status: filterTab,
      searchKey,
      sort: sort && sort.replace('paymentRequestList.', ''),
    };
    if (sort) {
      reqObj = { ...reqObj, ...{ sort, sortBy } };
    }
    return reqObj;
  };

  const fetchPayments = () => {
    dispatch(systemAdminPaymentAction.withdrawList(getReqObj()));
  };

  useEffect(() => {
    fetchPayments();
  },        [activePage, sort, sortBy, filterTab, searchKey]);

  const downloadReport = () => {
    dispatch(systemAdminPaymentAction.resetGenerateReport());
    const filePath = downloadFilePath;
    if (filePath) {
      const a = document.createElement('a');
      a.setAttribute('href', `${filePath}`);
      a.setAttribute('download', 'withdrawlist');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  useEffect(() => {
    successDownloadReport && downloadReport();
  },        [successDownloadReport]);

  const generateReport = (title: string) => {
    dispatch(systemAdminPaymentAction.generateWithdrawReport({
      status: filterTab,
      isCsvDownload: true,
    }));
  };

  const filterObj = {
    activePage,
    sort,
    sortBy,
  };

  const classVarArr = ['table-head'];
  const classes = classVarArr.join(' ');

  const handlePageChange = (pageno: number) => {
    const { location: { search } } = props;
    setActivePage(pageno);
    const query = queryString.parse(search);
    query['page'] = String(pageno) || '1';
    history.replace({ search: queryString.stringify(query) });
  };
  const sortData = (argSort: string, argSortBy: number) => {
    setSort(argSort);
    setSortBy(argSortBy);
  };

  const handleChange = (value: any, key: string) => {
    const { location: { search } } = props;
    const query = queryString.parse(search);
    setActivePage(MAGIC_NUMBER.ONE);
    setSearchKey(value);
    query['searchKey'] = value;
    history.replace({ search: queryString.stringify(query) });
  };

  const tabClass = () => {
    return filterTab !== staticConstants.PAYMENT_REQUEST_SUB_TABS.PROCESSED ? fourColumnClass : fiveColumnClass;
  };

  const paymentRequestHeaderContent = {
    'paymentRequestList.name': { name: 'Name', dataLabel: 'Name', class: tabClass(), sort: true,
      path: `${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.SYSTEM_ADMIN.PAYMENTS.PATH}${pageRoutes.SYSTEM_ADMIN.PAYMENTS.DETAIL}`, activeTab: filterTab },
    'paymentRequestList.userType': { name: 'User Type', dataLabel: 'User Type', class: tabClass(), sort: true },
    'paymentRequestList.amount': { name: 'Amount', dataLabel: 'Amount', class: tabClass(), sort: true },
    'paymentRequestList.dateRequested': { name: 'Date Requested', dataLabel: 'Date Requested', class: tabClass(), sort: true },
  };
  if (filterTab === staticConstants.PAYMENT_REQUEST_SUB_TABS.PROCESSED) {
    paymentRequestHeaderContent[`paymentRequestList.payOutDate`] = { name: 'Pay out due', dataLabel: 'Pay out due', class: tabClass(), sort: true };
  }

  return (
        <>
        <div className="filter-wrapper">
        <FilterComponent
            filterContent={['search']}
            handleFilter={handleChange}
            placeholder="Search by Keywords, Name"
          />
            <Button className={`btn btn-primary round-circle generate-report-btn ml-auto`}
              onClick={() => generateReport('withDrawRequests')}>
              Generate Report
            </Button>
            </div>
            <div className={`tab-btn-group multi-tab-buttons`}>
                <Button
                    color="secondary"
                    className={`btn h-50 tab-btn text-uppercase ${filterTab === staticConstants.PAYMENT_REQUEST_SUB_TABS.CURRENT ? CSS_ClASSES.btnActive : ''}`}
                    onClick={() => setFilterTab(staticConstants.PAYMENT_REQUEST_SUB_TABS.CURRENT)}
                >
                    Current
            </Button>
                <Button
                    color="secondary"
                    className={`btn h-50 tab-btn text-uppercase ${filterTab === staticConstants.PAYMENT_REQUEST_SUB_TABS.UPCOMING ? CSS_ClASSES.btnActive : ''}`}
                    onClick={() => setFilterTab(staticConstants.PAYMENT_REQUEST_SUB_TABS.UPCOMING)}
                >
                    Upcoming
            </Button>

                <Button
                    color="secondary"
                    className={`btn h-50 tab-btn text-uppercase ${filterTab === staticConstants.PAYMENT_REQUEST_SUB_TABS.PROCESSED ? CSS_ClASSES.btnActive : ''}`}
                    onClick={() => setFilterTab(staticConstants.PAYMENT_REQUEST_SUB_TABS.PROCESSED)}
                >
                    Processed
            </Button>
            </div>

            <ListingContainer
                tableContent={paymentRequestHeaderContent}
                listData={{ list:paymentRequestList, count: paymentRequestListCount }}
                filterObj={filterObj}
                tableHeaderClasses={classes}
                handlePageChange={handlePageChange}
                handleSorting={sortData}
                parentTab={staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.PAYMENT_REQUEST}
            />
        </>
  );
}

export default PaymentListingContainer;
