import { alertActions } from './../../../alert/redux/actions';
import { endLoading, startLoading } from './../../../loader/redux/actions';
import { actionTypes } from './types';
import OpenFieldTypes from 'OpenFieldTypes';
import { userServices } from '../../../../services';
export const subscriptionPlanAction = {
  getSubscriptionProduct,
};

function getSubscriptionProduct(product: {productId: string}) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(product));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.getSubscriptionProduct(product)
            .then(
                (res) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(success(res));
                },
                (error) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.errorAlert(error.toString()));
                },
            );
  };
  function success(data: any) { return { type: actionTypes.GET_SUBSCRIPTION_PRODUCT_SUCCESS, data }; }
  function request(res: {productId: string}) { return { type: actionTypes.GET_SUBSCRIPTION_PRODUCT_REQUEST, res }; }
  function failure(error: string) { return { type: actionTypes.GET_SUBSCRIPTION_PRODUCT_FAILURE, error }; }
}
