import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify-redux';
import 'react-toastify/dist/ReactToastify.css';
import { alertActions } from './app/alert/redux/actions';
import { Footer } from './app/shared/footer';
import { Header } from './app/shared/header';
import { history } from './helpers';
import { PrivateRoutes, PublicRoutes } from './routes';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ScrollToTop from 'react-router-scroll-top';

class Main extends React.Component<any> {
  constructor(props: any) {
    super(props);
    const { dispatch } = this.props;
    history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });
  }

  public render() {
    const { pathname } = history.location;
    const { loggedIn } = this.props;
    return (
      <LoadingOverlay active={this.props.isLoading} spinner text="">
        <div className="page-wrapper">
          <ToastContainer />
          <div>
            <Router history={history}>
              {loggedIn &&
              pathname !== '/login' &&
              pathname !== '/' &&
              pathname !== '/pricing' &&
              pathname !== '/faq' &&
              pathname !== '/privacyPolicy' &&
              !pathname.includes('/verifyEmail') ? (
                <Header />
              ) : (
                ''
              )}
              <ScrollToTop>
                <Switch>
                  <Route path="/orgadmin" component={PrivateRoutes} />
                  <Route path="/learner" component={PrivateRoutes} />
                  <Route path="/expert" component={PrivateRoutes} />
                  <Route path="/professional" component={PrivateRoutes} />
                  <Route path="/systemAdmin" component={PrivateRoutes} />
                  <Route path="/goalManagement" component={PrivateRoutes} />
                  <Route path="/learnerManagement" component={PrivateRoutes} />
                  <Route path="/requestManagement" component={PrivateRoutes} />
                  <Route path="/eventManagement" component={PrivateRoutes} />
                  <Route path="/payment" component={PrivateRoutes} />
                  <Route path="/viewExpertProfile" component={PrivateRoutes} />
                  <Route path="/notifications" component={PrivateRoutes} />
                  <Route path="/addCard" component={PrivateRoutes} />
                  <Route path="/messageBoard" component={PrivateRoutes} />
                  <Route
                    path="/viewOrgAdminProfile"
                    component={PrivateRoutes}
                  />
                  <Route path="/viewLearnerProfile" component={PrivateRoutes} />
                  <Route
                    path="/viewProfessionalProfile"
                    component={PrivateRoutes}
                  />
                  <Route exact path="/linkedin" component={LinkedInPopUp} />
                  <Route path="/logs" component={PrivateRoutes} />
                  <Route
                    path="/"
                    render={({ match, ...props }) => (
                      <PublicRoutes
                        match={match}
                        loggedIn={loggedIn}
                        {...props}
                      />
                    )}
                  />
                </Switch>
              </ScrollToTop>
              {this.props.loggedIn && !pathname.includes('/verifyEmail') ? (
                <Footer />
              ) : (
                ''
              )}
            </Router>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

function mapStateToProps(state: {
  alert: any;
  loader: any;
  authentication: any;
  chatMessages: any;
}) {
  const {
    alert,
    authentication,
    chatMessages: { currentSelectedUser },
  } = state;
  const { loggedIn, openScreenLimitModal } = authentication;
  const isLoading =
    state.loader &&
    state.loader.MAIN_LOADER &&
    state.loader.MAIN_LOADER.isLoading;
  return {
    alert,
    loggedIn,
    isLoading,
    openScreenLimitModal,
    currentSelectedUser,
  };
}

const connectedApp = connect(mapStateToProps)(Main);
export { connectedApp as Main };
