export const actionTypes = {
  BID_LIST_REQUEST: 'BID_LIST_REQUEST',
  BID_LIST_SUCCESS: 'BID_LIST_SUCCESS',
  BID_LIST_FAILURE: 'BID_LIST_FAILURE',
  MAKE_BID_REQUEST: 'MAKE_BID_REQUEST',
  MAKE_BID_SUCCESS: 'MAKE_BID_SUCCESS',
  MAKE_BID_FAILURE: 'MAKE_BID_FAILURE',
  ADD_FAVOURITE_REQUEST: 'ADD_FAVOURITE_REQUEST',
  ADD_FAVOURITE_SUCCESS: 'ADD_FAVOURITE_SUCCESS',
  ADD_FAVOURITE_SUCCESS_DETAIL: 'ADD_FAVOURITE_SUCCESS_DETAIL',
  ADD_FAVOURITE_FAILURE: 'ADD_FAVOURITE_FAIULRE',
  BID_DETAILS_SUCCESS: 'BID_DETAILS_SUCCESS',
  BID_DETAILS_FAILURE: 'BID_DETAILS_FAILURE',
  MAKE_BID_SUCCESS_DETAIL: 'MAKE_BID_SUCCESS_DETAIL',
  GENERATE_REPORT_REQUEST: 'GENERATE_REPORT_REQUEST',
  GENERATE_REPORT_SUCCESS: 'GENERATE_REPORT_SUCCESS',
  GENERATE_REPORT_FAILURE: 'GENERATE_REPORT_FAILURE',
  RESET_GENERATE_REPORT: 'RESET_GENERATE_REPORT',
  RESET_BID_LIST: 'RESET_BID_LIST',
  REFER_BID_REQUEST: 'REFER_BID_REQUEST',
  REFER_BID_SUCCESS: 'REFER_BID_SUCCESS',
  REFER_BID_FAILURE: 'REFER_BID_FAILURE',
  RESCHEDULE_FAILURE: 'RESCHEDULE_FAILURE',
  RESCHEDULE_SUCCESS: 'RESCHEDULE_SUCCESS'
};
