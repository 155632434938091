import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  ModalHeader,

} from 'reactstrap';
import OpenFieldTypes from 'OpenFieldTypes';
import { extendEventStatus, staticConstants, MAGIC_NUMBER } from './../../../utils';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  isOpenModal: boolean;
  acceptedBidId?: string;
  role: string;
  duration: number;
  extendSessionFee?: {amount: number; commission: number};
  getExtendSessionFee: (bidId: string, duration: number) => void;
  hideModal(): void;
  handleDurationChange(duration: number): void;
  onClickYes(arg: any): void;
}

interface IState {
  modal: boolean;
  duration: number;
}

class ExtendEventModal extends React.Component<IProps, IState> {

  public static getDerivedStateFromProps(props: IProps) {
    return { duration: props.duration };
  }
  constructor(props: IProps) {
    super(props);

    this.state = {
      modal: props.isOpenModal,
      duration: null,
    };
    this.hideModal = this.hideModal.bind(this);
  }
  /**
   * @description
   * handleDurationChange is used to set the value from duration
   */
  public handleDurationChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    this.setState({
      duration: Number(value),
    });
    this.props.handleDurationChange(Number(value));
    this.props.getExtendSessionFee(this.props.acceptedBidId, Number(value));
  }
  public hideModal() {
    this.setState({
      modal: false,
    });
    this.props.hideModal();
  }

  public onClickYes = () => {
    this.props.onClickYes(extendEventStatus.REQUEST);
    this.hideModal();
  }

  public componentDidMount() {
    const {  duration, acceptedBidId } = this.props;
    this.props.getExtendSessionFee(acceptedBidId, duration);
  }

  public render() {
    const { modal, duration } = this.state;
    const { role, extendSessionFee } = this.props;
    return (
            <Modal isOpen={modal} className="organization-code-modal" onClosed={() => this.hideModal()}>
              <ModalHeader toggle={() => this.hideModal()}>Extends Duration</ModalHeader>
              <ModalBody>
                <Form autoComplete="off" noValidate>
                  <FormGroup
                    className={`learning-goal-form`}>
                    <h2 className="heading heading-rg roboto-medium mb-3">Select Duration</h2>
                    <div className="d-flex">
                      <label className="control control--radio">
                        <input type="radio" id="audio" name="duration" value={MAGIC_NUMBER.ONE}
                          defaultChecked={duration === MAGIC_NUMBER.ONE} onChange={e => this.handleDurationChange(e)} /> 1 hr.
                        <div className="control__indicator" />
                      </label>
                      <label className="control control--radio">
                        <input type="radio" id="video" name="duration" value={MAGIC_NUMBER.TWO} defaultChecked={duration === MAGIC_NUMBER.TWO}
                          onChange={e => this.handleDurationChange(e)} />2 hrs.
                        <div className="control__indicator" />
                      </label>
                    </div>
                  </FormGroup>
                  {role !== staticConstants.ROLE.EXPERT &&
                    <FormGroup className={`floating-label session-fee-box`}>
                      <h2 className="heading heading-rg roboto-medium mb-3">Session Fee</h2>
                     <b>{extendSessionFee && extendSessionFee.amount}</b>
                    </FormGroup>}
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button className="btn btn-regular ml-0" onClick={() => this.hideModal()}>Cancel</Button>{' '}
                <Button color="primary" className="mr-2" onClick={() => this.onClickYes()}>Extend</Button>
              </ModalFooter>
            </Modal>
    );
  }
}

const connectedExtendEventModal = connect(null)(ExtendEventModal);
export { connectedExtendEventModal as ExtendEventModal };
