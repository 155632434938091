import { actionTypes } from './types';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};

interface IAction {
  type: string;
  user: {};
  openScreenLimitModal: false;
  res: {};
  isEmailRequired: false;
}

export function authentication(state = initialState, action: IAction) {
  switch (action.type) {
    case actionTypes.LOGIN_REQUEST:
      return {
        loggingIn: false,
        user: {},
      };
    case actionTypes.LOGIN_SUCCESS:
    case actionTypes.VERIFY_CODE_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
        openScreenLimitModal: action.openScreenLimitModal,
      };
    case actionTypes.LOGIN_FAILURE:
    case actionTypes.SOCIAL_LOGIN_SUCCESS:
    case actionTypes.LOGOUT_REQUEST_SUCCESS:
      return {
        loggedIn: false,
        user: {},
        openScreenLimitModal: false,
      };
    case actionTypes.SOCIAL_LOGIN_ERROR:
      return {
        isEmailRequired: true,
      };
    case actionTypes.SOCIAL_LOGIN_REQUEST:
      return {
        user: action.user,
      };
    case actionTypes.RESET_USER_DATA:
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('feedback');
      return {
        loggedIn: false,
        user: {},
        openScreenLimitModal: false,
      };
    default:
      return state;
  }
}
