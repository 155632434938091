import { logServices } from '../../../../services';
import { alertActions } from '../../../alert/redux/actions';
import { endLoading, startLoading } from '../../../loader/redux/actions';
import { actionTypes } from './types';
import OpenFieldTypes from 'OpenFieldTypes';

export const logAction = {
  getLogs,
  clearLogs,
};

function getLogs(reqObj: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request());
    logServices.getLogs(reqObj)
      .then(
        (logs) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(logs));

        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request() { return { type: actionTypes.LOG_LIST_REQUEST }; }
  function success(logs: string) { return { type: actionTypes.LOG_LIST_SUCCESS, logs }; }
  function failure(error: string) { return { type: actionTypes.LOG_LIST_FAILURE, error }; }
}

function clearLogs() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(clearLogs());
    function clearLogs() { return { type: actionTypes.LOG_LIST_CLEAR }; }
  };
}
