import React, { Dispatch } from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Sidebar } from '../../shared/sidebar';
import {
  Button,
} from 'reactstrap';
import { staticConstants, CSS_ClASSES } from '../../../utils/staticConstants';
import OpenFieldTypes from 'OpenFieldTypes';
import { LearningGoal } from './listings/learningGoal';
import { history } from '../../../helpers';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  location: any;
}

class AdminGoalManagementContainer extends React.Component<IProps> {

  public state = {
    activeTab: staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.ADMIN_LEARNING_GOALS,
  };

  public componentDidMount() {
    const { location: { search } } = this.props;
    if (!this.props.location.search) {
      history.push({ search: `?tab=${staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.ADMIN_LEARNING_GOALS}` });
    } else if (queryString.parse(search).tab) {
      this.setState({ activeTab: queryString.parse(search).tab });
      history.push({ search: `?tab=${queryString.parse(search).tab}` });
    }
  }

  public handleActiveTabs = (activeTab: string) => {
    history.push({ search: `?tab=${activeTab}` });
    this.setState({ activeTab });
  }

  /**
   * @description
   * render is used to render the html.
   */
  public render() {
    const { activeTab } = this.state;
    return (
      <React.Fragment>
        <Sidebar {...this.props} />
        <div className="dashboard-wrapper">
          <h2 className="heading heading-sm roboto-medium text-uppercase">Goal Management</h2>
          <div className="dashboard-content">
            <div className="tab-btn-group">
              <Button
                color="secondary"
                className={'btn w-33 h-50 tab-btn text-uppercase ' + (activeTab === staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.ADMIN_LEARNING_GOALS ? CSS_ClASSES.btnActive : '')}
                onClick={() => this.handleActiveTabs(staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.ADMIN_LEARNING_GOALS)}
              >
                Team Admin Goals
              </Button>
              <Button
                color="secondary"
                className={'btn w-33 h-50 tab-btn text-uppercase ' + (activeTab === staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.LERNER_GOAL_REQUESTS ? CSS_ClASSES.btnActive : '')}
                onClick={() => this.handleActiveTabs(staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.LERNER_GOAL_REQUESTS)}
              >
                Team Member Goal Requests
              </Button>
              <Button
                color="secondary"
                className={'btn w-33 h-50 tab-btn text-uppercase ' + (activeTab === staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.PROFESSIONSAL_LEARNING_GOALS ? CSS_ClASSES.btnActive : '')}
                onClick={() => this.handleActiveTabs(staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.PROFESSIONSAL_LEARNING_GOALS)}
              >
                Individual Goals
              </Button>
            </div>
            <LearningGoal activeTab={activeTab} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const connectedAdminGoalManagementContainer = connect(null)(AdminGoalManagementContainer);
export { connectedAdminGoalManagementContainer as AdminGoalManagementContainer };
