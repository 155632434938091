import { actionTypes } from './types';

export function customizedPlan(state = {}, action: any) {
  switch (action.type) {
    case actionTypes.PLAN_LIST_REQUEST:
      return {
        planListRequest: true,
      };
    case actionTypes.PLAN_LIST_SUCCESS:
      return {
        count: action.data ? action.data['count'] : 0,
        list: action.data ? action.data['data'] : [],
        planListSuccess: true,
      };
    case actionTypes.EDIT_PLAN_SUCCESS:
      return {
        ...state,
        editSuccess: true,
      };
    case actionTypes.DELETE_PLAN_SUCCESS:
      return {
        ...state,
        deletePlanSuccess: true,
      };
    case actionTypes.PLAN_LIST_FAILURE:
    case actionTypes.EDIT_PLAN_FAILURE:
    case actionTypes.DELETE_PLAN_FAILURE:
      return {
        error: action.error,
        editPlanSuccess: false,
        deletePlanSuccess: false,
      };
    default:
      return state;
  }
}
