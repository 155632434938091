import {
  GET_ACTIVITIES_LIST_QUERY,
  GET_CATEGORY_LIST_QUERY,
  GET_DOMAIN_LIST_QUERY,
  GET_SUB_CATEGORY_LIST_QUERY,
  GET_SUB_DOMAIN_LIST_QUERY,
  GET_TOPICS_LIST_QUERY,
  GET_TAGS,
  GET_TOTAL_EXPERT,
  SEARCH_TAGS
} from '../graphql-client/queries/tagsQuery';
import { client } from '../index';
import { handleResponse } from '../utils/common';
import { ADD_TAGS, REMOVE_TAGS, PUBLISH_TAGS, EDIT_TAGS } from '../graphql-client/mutations';

export const tagServices = {
  getCategories,
  getSubCategories,
  getDomains,
  getSubDomains,
  getTopics,
  getActivities,
  getTags,
  getExpert,
  createTag,
  removeTag,
  publishTag,
  editTag,
  searchTags
};

async function getCategories(selectedTags: object) {
  const response = await client.query({
    query: GET_CATEGORY_LIST_QUERY,
    variables: selectedTags,
  });
  const resp = await handleResponse(response);
  return resp.category;
}

async function getSubCategories(selectedTags: object) {
  const response = await client.query({
    query: GET_SUB_CATEGORY_LIST_QUERY,
    variables: selectedTags,
  });
  const resp = await handleResponse(response);
  return resp.subCategory;
}

async function getDomains(requestObj?: object) {
  const response = await client.query({
    query: GET_DOMAIN_LIST_QUERY,
    variables: requestObj,
  });
  const resp = await handleResponse(response);
  return resp.domain;
}

async function getSubDomains(selectedTags: object) {
  const response = await client.query({
    query: GET_SUB_DOMAIN_LIST_QUERY,
    variables: selectedTags,
  });
  const resp = await handleResponse(response);
  return resp.subDomain;
}

async function getTopics(selectedTags: object) {
  const response = await client.query({
    query: GET_TOPICS_LIST_QUERY,
    variables: selectedTags,
  });
  const resp = await handleResponse(response);
  return resp.topic;
}

async function getActivities(selectedTags: object) {
  const response = await client.query({
    query: GET_ACTIVITIES_LIST_QUERY,
    variables: selectedTags,
  });
  const resp = await handleResponse(response);
  return resp.activities;
}

async function getTags() {
  const response = await client.query({
    query: GET_TAGS,
  });
  const resp = await handleResponse(response);
  return resp.tags.data;
}

async function searchTags(key: string) {
  const response = await client.query({
    query: SEARCH_TAGS,
    variables: { key },
  });
  const resp = await handleResponse(response);
  return resp.allLinktag.data;
}

async function getExpert(selectedTags: object) {
  const response = await client.query({
    query: GET_TOTAL_EXPERT,
    variables: selectedTags,
  });
  const resp = await handleResponse(response);
  return resp.totalExpert;
}

async function createTag(selectedTags: object) {
  const response = await client.query({
    query: ADD_TAGS,
    variables: selectedTags,
  });
  const resp = await handleResponse(response);
  return resp.createTag;
}

async function removeTag(removeTagData: object) {
  const response = await client.mutate({
    mutation: REMOVE_TAGS,
    variables: removeTagData,
  });
  const resp = await handleResponse(response);
  return resp.removeTag;
}

async function publishTag(tagId: object) {
  const response = await client.mutate({
    mutation: PUBLISH_TAGS,
    variables: tagId,
  });
  const resp = await handleResponse(response);
  return resp.publish;
}

async function editTag(selectedTags: object) {
  const response = await client.query({
    query: EDIT_TAGS,
    variables: selectedTags,
  });
  const resp = await handleResponse(response);
  return resp.editTag;
}
