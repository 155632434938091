import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import OpenFieldTypes from 'OpenFieldTypes';
import { UncontrolledTooltip } from 'reactstrap';
import { formatCurrency } from './../../../../utils';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  planList: any;
  onDelete(id: string): void;
  onEdit(plan: object): void;
}

class Plans extends React.Component<IProps> {

    /**
     * @description
     * render is used to render the html.
     */
  public render() {
    const { planList } = this.props;
    return (
        planList && (
                <div className={`table-row`}>
                    <div className="table-col table-col-20" data-label="Name">
                        <span className={`cursor-pointer`}>{planList.productName}</span>
                    </div>
                    <div className="table-col table-col-15" data-label="Billing Cycle">
                        <span>Monthly</span>
                    </div>
                    <div className="table-col table-col-15" data-label="Add-On Amount">
                    <span>{`$${formatCurrency(planList.addonAmount)}`}</span>
                    </div>
                    <div className="table-col table-col-15" data-label="Licence Amount">
                    <span>{`$${formatCurrency(planList.licenceAmount)}`}</span>
                    </div>
                    <div className="table-col table-col-15" data-label="Member Amount">
                    <span>{`$${formatCurrency(planList.learnerAmount)}`}</span>
                    </div>
                    <div className="table-col table-col-20 text-center p-0 action-col">
                        {
                            <><em className="icon icon-edit" id={`tooltip_edit_${planList['_id']}`} onClick={() => this.props.onEdit(planList)} />
                            <UncontrolledTooltip placement="top" target={`tooltip_edit_${planList['_id']}`} >Edit</UncontrolledTooltip></>
                        }
                        <em className="icon icon-delete" id={`tooltip_delete_${planList['_id']}`} onClick={() => this.props.onDelete(planList._id)} />
                        <UncontrolledTooltip placement="top" target={`tooltip_delete_${planList['_id']}`} >Delete</UncontrolledTooltip>
                    </div>
                </div>
            )
    );
  }
}

function mapStateToProps(state: any) {
  return {
  };
}

const plansPage = connect(mapStateToProps)(Plans);
export { plansPage as Plans };
