import { toast } from 'react-toastify';
import { dismiss, error, success } from 'react-toastify-redux';
import { getRandomString } from './../../../utils/common';
import OpenFieldTypes from 'OpenFieldTypes';

const DISMISS_TIME_OUT = 5000;
const RANDOM_STRING_LEN = 10;

export const alertActions = {
  successAlert,
  errorAlert,
  clear,
};

function setDismiss(id: string) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    setTimeout(() => {
      dispatch(dismiss(id));
    },         DISMISS_TIME_OUT);
  };
}

function successAlert(message: string) {
  const id = getRandomString(RANDOM_STRING_LEN);
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(success(message, {
      position: 'top-center',
      id,
    }));
    dispatch(setDismiss(id));
  };
}

function errorAlert(errorMsg: any) {
  let updatErrArr = [];
  if (typeof errorMsg === 'string') {
    updatErrArr = errorMsg.split('error:') || [];
  } else if (errorMsg && errorMsg.message) {
    updatErrArr.push(errorMsg.message.replace('GraphQL error:', ''));
  }
  const id = getRandomString(RANDOM_STRING_LEN);
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(error(updatErrArr[1] || updatErrArr[0], {
      position: 'top-center',
      id,
    }));
    dispatch(setDismiss(id));
  };
}

function clear() {
  return () => {
    toast.dismiss(); // Hook Because React Toastify is not working
  };
}
