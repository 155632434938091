import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import OpenFieldTypes from 'OpenFieldTypes';
import moment from 'moment';
import { formatCurrency, getIncomeLogStatus, staticConstants, pageRoutes } from '../../../utils';
import { history } from '../../../helpers';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  invoice: any;
  user: any;
  role: string;
}

class InvoiceItem extends React.Component<IProps> {

  constructor(props: IProps) {
    super(props);
  }

  public redirectEventDetail (event: any) {
    if ([staticConstants.ROLE.ORGANIZATION_ADMIN, staticConstants.ROLE.PROFESSIONAL].indexOf(this.props.user.role) !== -1) {
      history.push({
        pathname: `${pageRoutes.EVENTMANAGEMENT.PATH}${pageRoutes.EVENTMANAGEMENT.DETAIL}/${event._id}`,
        state: {},
      });
    }
  }

  /**
   * @description
   * render is used to render the html.
   */
  public render() {
    const { invoice } = this.props;
    return invoice && (
      <div className={`table-row`} onClick={(event) => this.redirectEventDetail(invoice.bidData)} >
        
        <div className="table-col table-col-13" data-label="Title">
          <span className="roboto-medium link-btn text-truncate" >{invoice.goalData.title}</span>
        </div>
        <div className="table-col table-col-20" data-label="Expert Name">
          <span>{invoice.receiverData.firstName } {invoice.receiverData.lastName }</span>
        </div>
        { this.props.role === staticConstants.ROLE.ORGANIZATION_ADMIN && <div className="table-col table-col-13" data-label="No Participants">
          <span>{invoice.bidData.participants.length }</span>
        </div>}
        <div className="table-col table-col-15" data-label="Session Fee">
          <span>${formatCurrency(invoice.sessionFee)}</span>
        </div>
        <div className="table-col table-col-15" data-label="Payment Processed">
          <span>{moment(invoice['createdAt']).format(staticConstants.DATE_FORMAT)}</span>
        </div>
        <div className="table-col table-col-20 text-center" data-label="Event Date">
          <span>{moment(invoice['bidData']['proposedDate']).format(staticConstants.DATE_FORMAT)}</span>
        </div>
        <div className="table-col table-col-15" data-label="Status">
        <span>{getIncomeLogStatus(invoice.status)}</span>
        </div>
      </div >
    );
  }
}

function mapStateToProps(state: { authentication: { user: any } }) {
  const { user } = state.authentication;
  return { user };
}

const invoiceItemPage = connect(mapStateToProps)(InvoiceItem);
export { invoiceItemPage as InvoiceItem };
