import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { FilterComponent } from '../../../shared/filter/filterComponent';
import {
  staticConstants,
  getUpdatedOptions,
  goalStatusTypes,
  getSelectedValue,
  MAGIC_NUMBER
} from '../../../../utils';
import OpenFieldTypes from 'OpenFieldTypes';
import { ListingContainer } from '../../../shared/listingManagement/listingContainer';
import { pageRoutes } from '../../../../utils/routeConstants';
import { bidAction } from '../../../expert/bidManagement/redux/actions';
import { tagAction } from '../../../shared/tags/redux/actions';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  filterTab?: any;
  bids: any;
  userId?: any;
  tableContent?: any;
  getBids: (data: object) => void;
  getDomains: (data: object) => void;
  resetAllTags: () => void;
}

const newRequest = {
  title: { name: 'Title', class: 'table-col-20', sort: true, path: `/${staticConstants.ROLE.SYSTEM_ADMIN}${pageRoutes.EXPERT.BID_MANAGEMENT.PATH}${pageRoutes.EXPERT.BID_MANAGEMENT.DETAIL}` },
  eventType: { name: 'Event Type', class: 'table-col-20', sort: true },
  domain: { name: 'Domain', class: 'table-col-15', sort: true },
  subDomain: { name: 'Sub-Domain', class: 'table-col-15', sort: true },
  createdAt: { name: 'Created', class: 'table-col-15', sort: true },
  requestedBy: { name: 'Requested By', class: 'table-col-15' },
};

const sentBid = {
  title: { name: 'Title', class: 'table-col-17', sort: true, path: `/${staticConstants.ROLE.SYSTEM_ADMIN}${pageRoutes.EXPERT.BID_MANAGEMENT.PATH}${pageRoutes.EXPERT.BID_MANAGEMENT.DETAIL}` },
  eventType: { name: 'Event Type', class: 'table-col-10', sort: true },
  domain: { name: 'Domain', class: 'table-col-15', sort: true },
  subDomain: { name: 'Sub-Domain', class: 'table-col-15', sort: true },
  createdAt: { name: 'Created', class: 'table-col-15', sort: true },
  proposedDate: { name: 'Proposed', class: 'table-col-15', sort: true },
  requestedBy: { name: 'Sent To', class: 'table-col-13' },
};

class BidListings extends React.Component<IProps> {

  /**
   * @description getDerivedStateFromProps is called when there in change in property
   * @param fields {Object} props
   */
  public static getDerivedStateFromProps(props: any, state: any) {
    const updateState = {};
    updateState['fetchGenerateReport'] = false;
    if (props.filterTab !== state.filterTab) {
      const tableContent = props.filterTab === staticConstants.BID_MANAGEMENT_TAB.NEW_REQUEST
        ? newRequest : sentBid;
      updateState['filterTab'] = props.filterTab;
      updateState['tableContent'] = tableContent;
      updateState['fetchBids'] = true;
      updateState['activePage'] = 1;
      updateState['sort'] = '';
      updateState['sortBy'] = 0;
    }
    if (props.bids && props.bids.list) {
      updateState['bids'] = props.bids;
    }
    if (props.bids && props.bids.successDownloadReport) {
      updateState['downloadUrl'] = props.users.downloadUrl;
      updateState['downloadFileName'] = props.users.downloadFileName;
      updateState['fetchGenerateReport'] = true;
    }
    return updateState;
  }

  public state = {
    activePage: 1,
    items: MAGIC_NUMBER.TEN,
    bids: {
      count: 0,
      list: [],
      fetchedListing: false,
    },
    tableContent: (this.props && this.props.tableContent) ? this.props.tableContent : newRequest,
    filterTab: this.props.filterTab,
    sort: '',
    sortBy: 0,
    title: '',
    eventType: null,
    category: null,
    status: null,
    fetchBids: false,
    downloadUrl: '',
    downloadFileName: '',
    fetchGenerateReport: false,
  };

  /**
   * @description componentDidMount is called when component is loaded
   * call goalAction.getGoals to fetch goal listings
   * call goalAction.getGoals to fetch goal categories
   */
  public componentDidMount() {
    const { items, activePage } = this.state;
    const reqObj = {
      page: Number(activePage),
      limit: items,
      rfpType: this.state.filterTab,
      userId: this.props.userId,
      isUserManagement: true,
    };
    this.props.getDomains({});
    this.props.getBids(reqObj);
  }

  /**
   * @description componentDidUpdate is called when we need to fetch listing again.
   * call goalAction.getGoals to fetch goal listings
   */
  public componentDidUpdate() {
    if (this.state.fetchBids) {
      this.fetchBidsData(this.state.activePage);
      this.setState({ fetchBids: false });
    }
    if (this.state.fetchGenerateReport) {
      this.downloadReport();
    }
  }
  /**
   * @description componentWillUnmount is used when component destroyed
   * call tagAction.resetAllTags() to reset the tags
   */
  public componentWillUnmount() {
    this.props.resetAllTags();
  }

  /**
   * @description fetchBidsData is used on initial load
   */
  public fetchBidsData(pageno: number) {
    const { sort, sortBy } = this.state;
    let reqObj = {
      page: pageno,
      limit: this.state.items,
      eventType: getSelectedValue(this.state.eventType),
      category: getSelectedValue(this.state.category),
      status: getSelectedValue(this.state.status),
      title: this.state.title,
      rfpType: this.state.filterTab,
      userId: this.props.userId,
      isUserManagement: true,
    };
    if (sort) {
      reqObj = { ...reqObj, ...{ sort, sortBy } };
    }
    this.props.getBids(reqObj);
  }

  /**
   * @description
   * handleChange is used to set the value on state from the input.
   * @param fields {Object} || {String} value
   * @param fields {String} key
   */
  public handleChange = (value: any, key: string) => {
    this.setState({ ...this.state, [key]: value },
                  () => { this.fetchBidsData(1); });
  }

  /**
   * @description
   * sortData is used for sorting purpose.
   * @param fields {Number} sort
   * @param fields {String} sortBy
   */
  public sortData = (sort: any, sortBy: any) => {
    const { activePage } = this.state;
    this.setState({ ...this.state, sort, sortBy },
                  () => this.fetchBidsData(activePage));
  }

  /**
   * @description
   * handlePageChange is called someone click on the pagination.
   * @param fields {Number} pageno
   */
  public handlePageChange = (pageno: number) => {
    this.setState({
      activePage: pageno,
    },            () => {
      this.fetchBidsData(pageno);
    });
  }

  /**
   * @description
   * generateReport is used to get the generate report
   */
  public generateReport = (title: string) => {
    const tableContent = {
      ...this.state.tableContent,
    };
    delete tableContent['isDelete'];
    const columnHeaders = [];
    const columnKeys = [];
    for (const [key, value] of Object.entries(tableContent)) {
      columnHeaders.push(value['name']);
      columnKeys.push(key);
    }
    this.props.dispatch(bidAction.generateReport({
      columnHeaders,
      columnKeys,
      reportTitle: `${title}.csv`,
      rfpType: this.props.filterTab,
      userId: this.props.userId,
      isGenerateReport: true,
    }));
  }

  public downloadReport = () => {
    this.props.dispatch(bidAction.resetGenerateReport());
    const filePath = this.state.downloadUrl;
    const a = document.createElement('a');
    a.setAttribute('href', `${filePath}`);
    a.setAttribute('download', this.state.downloadFileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  /**
   * @description
   * render is used to render the html.
   */
  public render() {
    const filterContent = ['search', 'status', 'eventType', 'domain'];
    const { filterTab } = this.props;
    const { bids, tableContent, activePage, sort, sortBy } = this.state;
    const filterObj = {
      activePage,
      sort,
      sortBy,
    };
    return (
      <React.Fragment>
        <div className="filter-wrapper">
          <FilterComponent filterContent={filterContent} handleFilter={this.handleChange}
            status={goalStatusTypes} filterTab={filterTab} />
            { filterTab === staticConstants.BID_MANAGEMENT_TAB.NEW_REQUEST &&
              <span className="btn btn-primary round-circle generate-report-btn" onClick={() => this.generateReport(staticConstants.BID_MANAGEMENT_TAB.NEW_REQUEST)}>
                    Generate Report
              </span>
            }
        </div>
        <ListingContainer tableContent={tableContent} listData={bids} filterObj={filterObj}
          handlePageChange={this.handlePageChange} handleSorting={this.sortData} />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  const { tags: { categories } } = state;
  return {
    bids: state.bids,
    categoryOptions: getUpdatedOptions(categories, 'name'),
  };
}

const connectedBids = connect(mapStateToProps, {
  getDomains: tagAction.getDomains,
  getBids: bidAction.getBids,
  resetAllTags: tagAction.resetAllTags,
})(BidListings);
export { connectedBids as BidListings };
