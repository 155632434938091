export const actionTypes = {
  USERS_LIST_REQUEST: 'USERS_LIST_REQUEST',
  USERS_LIST_SUCCESS: 'USERS_LIST_SUCCESS',
  USERS_LIST_FAILURE: 'USERS_LIST_FAILURE',
  USER_DETAIL_REQUEST: 'USER_DETAIL_REQUEST',
  USER_DETAIL_SUCCESS: 'USER_DETAIL_SUCCESS',
  USER_DETAIL_FAILURE: 'USER_DETAIL_FAILURE',
  CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',
  RESET_CREATE_USER_STATUS: 'RESET_CREATE_USER_STATUS',
  UPDTAE_USER_STATUS_REQUEST: 'UPDTAE_USER_STATUS_REQUEST',
  UPDTAE_USER_STATUS_SUCCESS: 'UPDTAE_USER_STATUS_SUCCESS',
  UPDTAE_USER_STATUS_FAILURE: 'UPDTAE_USER_STATUS_FAILURE',
  UPDTAE_USER_STATUS_SUCCESS_FROM_DETAIL: 'UPDTAE_USER_STATUS_SUCCESS_FROM_DETAIL',
  GENERATE_REPORT_REQUEST: 'GENERATE_REPORT_REQUEST',
  GENERATE_REPORT_SUCCESS: 'GENERATE_REPORT_SUCCESS',
  GENERATE_REPORT_FAILURE: 'GENERATE_REPORT_FAILURE',
  RESET_GENERATE_REPORT: 'RESET_GENERATE_REPORT',
  RESET_USER_STATUS_REQUEST: 'RESET_USER_STATUS_REQUEST',
  RESET_POPUP_STATUS: 'RESET_POPUP_STATUS',
  DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',
  CHANGE_RATE_REQUEST: 'CHANGE_RATE_REQUEST',
  CHANGE_RATE_SUCCESS: 'CHANGE_RATE_SUCCESS',
  CHANGE_RATE_FAILURE: 'CHANGE_RATE_FAILURE',
  UPDTAE_USER_REQUEST_STATUS_REQUEST: 'UPDTAE_USER_REQUEST_STATUS_REQUEST',
  UPDTAE_USER_REQUEST_STATUS_SUCCESS: 'UPDTAE_USER_REQUEST_STATUS_SUCCESS',
  UPDTAE_USER_REQUEST_STATUS_SUCCESS_DETAIL: 'UPDTAE_USER_REQUEST_STATUS_SUCCESS_DETAIL',
  UPDTAE_USER_REQUEST_STATUS_FAILURE: 'UPDTAE_USER_REQUEST_STATUS_FAILURE',
  RE_VERIFY_USER_SUCCESS: 'RE_VERIFY_USER_SUCCESS',
  RE_VERIFY_USER_FAILURE: 'RE_VERIFY_USER_FAILURE',
  COMMENT_REQUEST: 'COMMENT_REQUEST',
  COMMENT_SUCCESS: 'COMMENT_SUCCESS',
  COMMENT_FAILURE: 'COMMENT_FAILURE',
  ADD_FEATURE_REQUEST: 'ADD_FEATURE_REQUEST',
  ADD_FEATURE_REQUEST_SUCCESS: 'ADD_FEATURE_REQUEST_SUCCESS',
  ADD_FEATURE_REQUEST_FAILURE: 'ADD_FEATURE_REQUEST_FAILURE',
};
