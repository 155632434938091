export const actionTypes = {
  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
  LOGOUT_REQUEST: 'USERS_LOGOUT',
  LOGOUT_REQUEST_SUCCESS: 'USERS_LOGOUT_SUCCESS',
  LOGOUT_REQUEST_FAILURE: 'USERS_LOGOUT_FAILURE',
  VERIFY_CODE_REQUEST: 'VERIFY_CODE_REQUEST',
  VERIFY_CODE_SUCCESS: 'VERIFY_CODE_SUCCESS',
  VERIFY_CODE_FAILURE: 'VERIFY_CODE_FAILURE',
  SOCIAL_LOGIN_REQUEST: 'SOCIAL_LOGIN_REQUEST',
  SOCIAL_LOGIN_SUCCESS: 'SOCIAL_LOGIN_SUCCESS',
  SOCIAL_LOGIN_ERROR: 'SOCIAL_LOGIN_ERROR',
  RESET_USER_DATA: 'RESET_USER_DATA',
};
