import { actionTypes } from './types';

interface IAction {
  type: string;
  data: {};
  error: string;
}

const initialState = {
  status: {},
};

export function rfpRequest(state = initialState, action: IAction) {
  switch (action.type) {
    case actionTypes.RFP_LIST_SUCCESS:
      return {
        count: action.data ? action.data['count'] : 0,
        list: action.data ? action.data['data'] : [],
        fetchedListing: true,
      };
    case actionTypes.RFP_LIST_REQUEST:
      return {
        fetchingRfpListing: true,
      };
    case actionTypes.RFP_LIST_FAILURE:
    case actionTypes.UPDATE_RFP_FAILED:
      return { error: action.error };

    case actionTypes.RFP_DETAIL_SUCCESS:

      const bidDetail = action.data['data'];
      return {
        ...state,
        detail: bidDetail,
        status: { fetchedRFPDetaill: true },
      };
    case actionTypes.RFP_DETAIL_REQUEST:
      return {
        ...state,
        status: { fetchingRFPDetaill: true },
      };
    case actionTypes.RFP_DETAIL_FAILURE:
      return {
        ...state,
        error: action.error,
        status: { fetchedRFPDetaill: false },
      };
    case actionTypes.BID_ACCEPT_SUCCESS:
      return {
        ...state,
        status: { bidAccepted: true , bidAcceptRequest: false },
      };
    case actionTypes.BID_ACCEPT_FAILURE:
      return {
        ...state,
        error: action.error,
        status: { bidAccepted: false, bidAcceptRequest: true  },
      };
    case actionTypes.BID_ACCEPT_REQUEST:
      return {
        ...state,
        status: { bidAcceptRequest: true },
      };
    case actionTypes.BID_SHORTLIST_SUCCESS:
      const sdetails = state['detail'];
      const sdata = action.data['data'];
      if (sdetails.bid) {
        sdetails['bid'] = sdetails.bid.map((bid) => {
          if (bid['_id'] === sdata['_id']) {
            bid['isShortlisted'] = sdata['isShortlisted'];
            return bid;
          }
          return bid;
        });
      }
      return {
        ...state,
        detail: sdetails,
        status: { bidShortlisted: true },
      };
    case actionTypes.BID_SHORTLIST_FAILURE:
      return {
        ...state,
        error: action.error,
        status: { bidShortlisted: false },
      };
    case actionTypes.GENERATE_REPORT_SUCCESS:
      return {
        ...state,
        downloadUrl: action.data['url'],
        downloadFileName: action.data['reportTitle'],
        successDownloadReport: true,
      };
    case actionTypes.RESET_GENERATE_REPORT:
      return {
        ...state,
        downloadUrl: undefined,
        successDownloadReport: false,
      };
    case actionTypes.BID_REJECT_SUCCESS:
      const bdetails = state['detail'];
      const bdata = action.data;
      if (bdetails.bid) {
        bdetails['bid'] = bdetails.bid.filter((bid) =>
                    bid['_id'] !== bdata['bidId'],
                );
      }
      return {
        ...state,
        detail: bdetails,

        status: { bidRejected: true },
      };

    case actionTypes.BID_REJECT_FAILURE:
      return {
        ...state,
        error: action.error,
        status: { bidRejected: false },
      };
    case actionTypes.GET_SESSION_FEE_SUCCESS:
      return {
        ...state,
        sessionFee: action.data['data'],
        sessionFeeBidId: action.data['bidId'],
        status: { sessionFeeSuccess: true },
      };
    case actionTypes.GET_SESSION_FEE_FAILURE:
      return {
        ...state,
        status: { sessionFeeSuccess: false },
      };
    case actionTypes.UPDATE_RESCHEDULE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }

}
