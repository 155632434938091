import { actionTypes } from './types';
interface IAction {
  type: string;
  res: any;
  error: any;
}

const initialState = {
  bankInfo: {},
};

export function bankDetails(state = initialState, action: IAction) {
  switch (action.type) {
    case actionTypes.ACCOUNT_DETAIL_SAVE_SUCCESS:
      return {
        ...state,
        acountData: action.res,
      };
    case actionTypes.ACCOUNT_DETAIL_SAVE_FAILURE:
    case actionTypes.ACCOUNT_DETAIL_FETCH_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.ACCOUNT_DETAIL_FETCH_SUCCESS:
      return {
        ...state,
        bankInfo: action.res,
      };
    default:
      return state;
  }
}
