import React, { Dispatch } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Breadcrumb, BreadcrumbItem, Button } from 'reactstrap';
import { LocationDescriptorObject } from 'history';
import OpenFieldTypes from 'OpenFieldTypes';
import { Sidebar } from '../../shared/sidebar';
import { pageRoutes } from './../../../utils/routeConstants';
import ledIcon from './../../../assets/images/icons/led.svg';
import { ChangeRatePerHourPopUp } from './../../shared/modals/changeRateRequestPopup';
import { expertPaymentAction } from './redux/actions';
import { IViewProfile } from '../../../interfaces';
import { PaymentListingContainer } from './listing/listingContainer';
import { formatCurrency } from '../../../utils/common';
import { MAGIC_NUMBER } from '../../../utils';

interface IProps {
  dispatch?: Dispatch<OpenFieldTypes.RootAction>;
  profile: IViewProfile;
  location: LocationDescriptorObject;
  expertFeedback: any;
  expertIncome: any;
}
interface IState {
  displayChangeRatePopup: boolean;
}
class PaymentPage extends React.Component<IProps, IState> {
  /**
   * @description getDerivedStateFromProps is called when there in change in property
   * @param fields {Object} props
   */
  public static getDerivedStateFromProps(props: IProps, state: any) {
    const updateState = {};
    if (props.expertFeedback && props.expertFeedback.successChangeRateRequest) {
      updateState['displayChangeRatePopup'] = false;
    }
    return updateState;
  }
  constructor(props: IProps) {
    super(props);
    this.state = {
      displayChangeRatePopup: false,
    };
  }

  public componentDidMount() {
    this.props.dispatch(expertPaymentAction.getExpertIncome());
  }

  /**
   * @description showChangeRequestPopup is used show assign licence popup.
   * @param fields {String} id
   */
  public showChangeRequestPopup = () => {
    this.setState({
      displayChangeRatePopup: true,
    });
  }

  /**
   * @description submit change request
   */
  public onSubmitChangeRequest = (obj) => {
    this.props.dispatch(expertPaymentAction.changeRequestRatePerHour(obj));
  }

  public hideModal() {
    this.setState({ displayChangeRatePopup: false });
  }
  public render() {
    const { displayChangeRatePopup } = this.state;
    const { profile, expertIncome: { totalReceieved, totalPending } } = this.props;
    const totalPendingReceived = (parseFloat(totalPending) + parseFloat(totalReceieved));
    return (
      <React.Fragment>
        <Sidebar {...this.props} />
        <div className="dashboard-wrapper">
          <div className="connect-header">
            <Breadcrumb className="position-relative">
              <BreadcrumbItem className="heading heading-sm text-uppercase roboto-bold">Payments</BreadcrumbItem>
            </Breadcrumb>
            <Link to={`${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.PAYMENT.CONNECT}`} className="btn btn-primary">Need Help?</Link>
          </div>
          <div className="view-profile-box box-shadow white-bg mt-4 mb-0">
            <div className="user-profile-details">
              <span className="profile-picture-view-only payment-card-icon">
                <img src={ledIcon}
                  alt="payment icon" />
              </span>
              <div className="profile-email-view-only text-truncate">
                <h3 className="text-truncate text-lg">
                  <em>$</em>
                  {profile && profile.hourlyRate && profile.hourlyRate.current ? profile.hourlyRate.current : 0}
                </h3>
                <p>Rate per hour</p>
              </div>
            </div>
            <Button type="button" className="btn btn-assign btn-rg" onClick={this.showChangeRequestPopup}>Change My Rate</Button>
          </div>
          <div className="dashboard-cards mt-4">
            <div className="card card-col-3">
              <div className="card-data">
                <h2><em>$</em>{formatCurrency(totalPendingReceived)}</h2>
                <p>Total Income</p>
              </div>
              <span className="icon icon-bill" />
            </div>
            <div className="card card-col-3">
              <div className="card-data">
                <h2><em>$</em>{formatCurrency(totalReceieved.toFixed(MAGIC_NUMBER.TWO))}</h2>
                <p>Payment Received</p>
              </div>
              <span className="icon icon-payment" />
            </div>
            <div className="card card-col-3">
              <div className="card-data">
                <h2><em>$</em>{formatCurrency(totalPending.toFixed(MAGIC_NUMBER.TWO))}</h2>
                <p>Payment Pending</p>
              </div>
              <span className="icon icon-payment icon-payment-blue" />
            </div>
          </div>

          <PaymentListingContainer {...this.props} />
        </div>
        {
          displayChangeRatePopup ?
            <ChangeRatePerHourPopUp isOpenModal={displayChangeRatePopup}
              ratePerHour={profile.hourlyRate && profile.hourlyRate.current ? profile.hourlyRate.current : 0}
              hideModal={() => this.setState({ displayChangeRatePopup: false })} onClickYes={this.onSubmitChangeRequest} />
            : ''
        }
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  const { expertFeedback, expertPayments: { expertIncome, paymentLogs }, updateProfile: { profile } } = state;
  return {
    expertFeedback,
    expertIncome,
    paymentLogs,
    profile,
  };
}

const connectedPaymentPage = connect(mapStateToProps)(PaymentPage);
export { connectedPaymentPage as PaymentPage };
