import React, { Component, Dispatch } from 'react';
import { connect } from 'react-redux';
import OpenFieldTypes from 'OpenFieldTypes';
import { LocationDescriptorObject } from 'history';
import { isEqual } from 'lodash';
import user_avatar from '../../../../assets/images/profile.png';
import { chatAction } from '../redux/actions';
import { IParams, IInboxUser, InitInboxUser, IAllInboxUsers, LastMessage } from '../../../../interfaces';
import { myFirestore } from '../../../../config/firebase';
import { firestoreQueryConstants } from '../../../../utils/firestoreQueryConstants';
import { getMyUserId, pageRoutes, MAGIC_NUMBER } from '../../../../utils';
import { history } from '../../../../helpers';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  allInboxUsers?: IAllInboxUsers;
  currentSelectedUser?: IInboxUser;
  match: {
    params: {
      [key: string]: IParams;
    };
  };
  location: LocationDescriptorObject;
  userType?: string;
  fetchingAllInboxUser?: boolean;
}

interface IState {
  allInboxUsers: IAllInboxUsers;
  currentSelectedUser: IInboxUser;
  filteredUser: IAllInboxUsers;
  searchKey: string;
}

export default class ChatUserList extends Component<IProps, IState> {

  public static defaultProps = {
    allInboxUsers: [],
  };

  public static getDerivedStateFromProps(props: IProps) {
    let newState = null;
    if (props.allInboxUsers && props.allInboxUsers.length) {
      newState = {
        allInboxUsers: props.allInboxUsers,
        currentSelectedUser: props.currentSelectedUser || {},
      };
    }
    return newState;
  }

  constructor(props: IProps) {
    super(props);
    this.state = {
      allInboxUsers: [],
      currentSelectedUser: new InitInboxUser(),
      filteredUser: [],
      searchKey: '',
    };
  }

  public componentDidMount() {
    const { userType, allInboxUsers } = this.props;
    this.getInboxUsers(true, userType);   // @param {loading}
    getMyUserId() && this.onNewBroadcast();
    if (allInboxUsers.length) {
      this.selectUser();
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    const { allInboxUsers, location: { state } } = this.props;
    if (!isEqual(prevProps.allInboxUsers, allInboxUsers) && allInboxUsers.length) {
      if (state && state['userId']) {
        const index = allInboxUsers.findIndex(user => user._id === state['userId']);
        this.selectUser(index);
        history.replace({
          state: {},
        });
      } else {
        this.selectUser();
      }
    }
  }

  public onNewMessage = () => {
    myFirestore
      .collection(firestoreQueryConstants.MESSAGE_BOARD)
      .where('members', 'array-contains', getMyUserId())
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const updatedUser = change.doc.data().lastMessage.to;
          if (updatedUser === getMyUserId()) {
            this.props.location.pathname.includes(pageRoutes.MESSAGE_BOARD.PATH) && this.getAllMessages();
            this.props.dispatch(chatAction.getTotalUnreadCount());
          }
          this.props.location.pathname.includes(pageRoutes.MESSAGE_BOARD.PATH) && this.getInboxUsers();
        });
      });
  }

  public onNewBroadcast = () => {
    myFirestore
      .collection(firestoreQueryConstants.BROADCAST)
      .where('members', 'array-contains', getMyUserId())
      .onSnapshot((snapshot) => {
        this.getInboxUsers(false, this.props.userType);
        snapshot.docChanges().forEach((change) => {
          const updatedData = change.doc.data();
          if (updatedData.members.includes(getMyUserId())
          && this.props.currentSelectedUser._id === updatedData.userInfo._id) {
              this.getAllMessages();
          }
        });
      });
  }

  public getAllMessages = () => {
    const { currentSelectedUser: { connectionId, isSystemAdmin }, dispatch } = this.props;
    if (connectionId) {
      dispatch(chatAction.getAllMessage(connectionId, isSystemAdmin));
    }
  }

  public getInboxUsers = (loading = false, userType?: string) => {
    this.props.dispatch(chatAction.getAllConnections(loading, userType));
  }

  public handleCss = () => {
    const chatContainer = document.querySelector('.chat-container');
    if (chatContainer) {
      chatContainer.classList.add('detail-page-show');
    }
  }

  public selectUser = async (index?: number) => {
    const { dispatch, allInboxUsers, currentSelectedUser } = this.props;
    this.handleCss();
    if ((!index || index === MAGIC_NUMBER.FOUND_INDEX) && !Object.keys(currentSelectedUser).length) {
      const user = allInboxUsers[0];
      dispatch(chatAction.setCurrentSelectedUser(user));
    } else {
      const user = allInboxUsers[index];
      if (user && (user._id !== currentSelectedUser._id)) {
        dispatch(chatAction.setCurrentSelectedUser(user));
      }
    }
  }

  public activeClass = (user: IInboxUser) => {
    const { currentSelectedUser } = this.state;
    if (currentSelectedUser.hasOwnProperty('_id') && user._id === currentSelectedUser._id) {
      return 'active';
    }
    return '';
  }

  public searchUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { allInboxUsers } = this.props;
    const value = event.target.value;
    this.setState({ searchKey: value }, () => {
      if (allInboxUsers.length) {
        const filteredUser = allInboxUsers.filter((user) => {
          const { targetName = '' } = user;
          const fullName = `${user.firstName} ${user.lastName}`;
          const searchData = targetName ? targetName : fullName;
          return searchData.toUpperCase().indexOf(value.trim().toUpperCase()) !== -1;
        });
        this.setState({ filteredUser });
      }
    });

  }

  public render() {
    const { allInboxUsers, fetchingAllInboxUser } = this.props;
    const { searchKey, filteredUser } = this.state;
    let allUsers = (searchKey ? filteredUser : allInboxUsers) || [];
    allUsers = allUsers.filter(x => x._id !== getMyUserId() || !x.roomId);
    return (
      <div className="chat-list" >
        <div className="search-wrapper">
          <div className="search-bar">
            <input type="text" placeholder="Search" value={searchKey} onChange={this.searchUser} />
            <span className="icon icon-search-icon" />
          </div>
        </div>
        <div className="list-wrapper">
          {allUsers.length ?
            allUsers.map((user, index) => {
              const {
                profileImage,
                firstName,
                lastName,
                _id,
                unreadCount,
                lastMessage = new LastMessage(),
                targetName = '',
                target = '',
                targetProfile = '',
              } = user;
              const displayName = targetName ? targetName : `${firstName} ${lastName}`;
              const displayPicture = target ? targetProfile : profileImage;
              if (_id === getMyUserId()) return null;
              return (
                <div
                  key={_id}
                  className={`list-row ${this.activeClass(user)}`}
                  onClick={() => this.selectUser(index)}
                >
                  <div className="chat-avatar-col">
                    <span className="avatar-image"><img src={displayPicture || user_avatar} alt="user" /></span>
                  </div>
                  <div className="chat-detail-col">
                    <span className="name text-truncate">{displayName}</span>
                    {lastMessage && <span className="text text-truncate">{lastMessage.content}</span>}
                  </div>
                  <div className="chat-time-col">
                    <span className="time-count">{lastMessage.timeAgo}</span>
                    {Boolean(unreadCount) && lastMessage.from !== getMyUserId() &&
                      <span className="message-count text-truncate">
                        {unreadCount > MAGIC_NUMBER.NINE ? '9+' : unreadCount}
                      </span>
                    }
                  </div>
                </div>
              );
            },
            ) : (
              <span className="d-flex justify-content-center w-100">{fetchingAllInboxUser ? 'Loading...' : 'No users found'}</span>
            )
          }
        </div>
      </div>
    );
  }
}

const chatUserListContainer = connect(null)(ChatUserList);
export { chatUserListContainer as ChatUserList };
