import { actionTypes } from './types';

interface IAction {
  type: string;
  data: {};
  error: string;
}

const initialState = {
  status: {},
};

export function viewProfile(state = initialState, action: IAction) {
  switch (action.type) {
      case actionTypes.SHORT_PROFILE_SUCCESS:
        return {
              ...state,
              profile: action.data,
              status: { fetchedProfile: true },
            };
      case actionTypes.SHORT_PROFILE_FAILURE:
        return {
              status: { fetchedProfile: false },
            };

      case actionTypes.BID_DETAIL_SUCCESS:
        return {
              ...state,
              bidDetail: action.data,
              status: { fetchedBIdDetail: true },
            };
      case actionTypes.BID_DETAIL_FAILURE:
        return {
              status: { fetchedBIdDetail: false },
            };
      default:
        return state;
    }

}
