import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { authAction } from './redux/actions';
import { IValidator } from './../../interfaces/index';
import OpenFieldTypes from 'OpenFieldTypes';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  isLimitScreenModal: boolean;
}
interface IState {
  isSubmitted: boolean;
  formData?: any;
  modal: boolean;
}
class LimitScreenPopUp extends React.Component<IProps, IState> {
  public validator: IValidator;
  constructor(props: IProps) {
    super(props);

    this.state = {
      isSubmitted: false,
      modal: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  public componentDidMount() {
    this.setState({
      modal: this.props.isLimitScreenModal,
    });
  }

  public componentWillReceiveProps(props: IProps) {
    this.setState({
      modal: props.isLimitScreenModal,
    });
  }

  public handleSubmit() {
    this.setState({ isSubmitted: true });
    const { dispatch } = this.props;
    dispatch(authAction.logout(true));
  }

  public hideModal() {
    this.setState({ modal: false }, () => {
      const { dispatch } = this.props;
      dispatch(authAction.resetUserData());
    });
  }

  public render() {
    return (
      <Modal isOpen={this.state.modal} className="organization-code-modal">
          <ModalBody className="text-center">
            <Form>
            <Label >
                You are already logged in on 3 screens. Please log out from them and then continue
            </Label>
          </Form>
          </ModalBody>
        <ModalFooter>
            <Button className="btn btn-regular ml-0" onClick={this.hideModal}>Cancel</Button>
            <Button color="primary" className="mr-2 get_start_btn cta_btn" style={{background: '#03af84'}} onClick={this.handleSubmit}>Log out</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const connectedLimitScreenPopUp = connect(null)(LimitScreenPopUp);
export { connectedLimitScreenPopUp as LimitScreenPopUp };
