import { actionTypes } from './types';
import { staticConstants } from '../../../../utils';

interface IAction {
  type: string;
  data: {};
  error: string;
}

const initialState = {
  status: {},
};

export function scheduledEvent(state = initialState, action: IAction) {
  switch (action.type) {
    case actionTypes.SCHEDULE_LIST_SUCCESS:
      return {
        count: action.data ? action.data['count'] : 0,
        list: action.data ? action.data['data'] : [],
        systemDate: action.data && action.data['date'],
        fetchedEventListing: true,
      };
    case actionTypes.EVENT_DETAIL_REQUEST:
    case actionTypes.SCHEDULE_LIST_REQUEST:
      return {
        fetchingEventListing: true,
      };
    case actionTypes.UPDATE_SCHEDULE_REQUEST:
      return {
        ...state,
        status: { updateScheduleRequested: true },
      };
    case actionTypes.UPDATE_EXTENT_EVENT_STATUS_SUCCESS:
      const reqEventDetails = state['eventDetails'];
      let activeBid = reqEventDetails['bid'][0];
      activeBid = { ...activeBid, ...{ extendMeeting: action.data['data']['extendMeeting'] } };
      reqEventDetails['bid'][0] = activeBid;
      return {
        ...state,
        eventDetails: reqEventDetails,
        status: { updateScheduleRequested: true },
      };
    case actionTypes.SCHEDULE_LIST_FAILURE:
    case actionTypes.UPDATE_SCHEDULE_FAILED:
      return { error: action.error };
    case actionTypes.EVENT_DETAIL_SUCCESS:
      return {
        ...state,
        eventDetails: action.data['data'][0],
        isCreater: action.data && action.data['isCreater'],
        fetchedEventListing: true,
      };
    case actionTypes.END_MEETING_SUCCESS:
    case actionTypes.START_MEETING_SUCCESS:
    case actionTypes.EXTEND_MEETING_SUCCESS:
      const eventDetails = state['eventDetails'];
      const data = action['data'];
      const stat = {};
      if (data['bidData']) {
        eventDetails['bid'][0]['statusTimeline'] = data['bidData']['statusTimeline'];
        eventDetails['bid'][0]['status'] = data['bidData']['status'];
        eventDetails['bid'][0]['zoomEvent'] = data['bidData']['zoomEvent'];
        eventDetails['bid'][0]['duration'] = data['bidData']['duration'];
      }
      if (actionTypes.START_MEETING_SUCCESS) {
        stat['start_meeting_success'] = true;
      } else if (actionTypes.END_MEETING_SUCCESS) {
        stat['end_meeting_success'] = true;
      } else if (actionTypes.EXTEND_MEETING_FAILURE) {
        stat['extend_meeting_success'] = false;
      }
      return {
        ...state,
        eventDetails,
        status: stat,
      };
    case actionTypes.UPDATE_SCHEDULE_STATUS:
      const list = state['list'];
      const eventIds = action['eventIds'];
      for (const i = 0; i < list.length; i + 1) {
        const id = list[i]._id;
        if (eventIds.includes(id)) {
          list[i].isJoin = true;
        }
      }
      return {
        ...state,
        list,
        updateEventStatus: true,
      };
    case actionTypes.RESET_SCHEDULE_STATUS:
      return {
        ...state,
        updateEventStatus: false,
      };
    case actionTypes.FLAG_USER_SUCCESS:
      let flag = false;
      for (const participantsRaw of state['eventDetails']['bid'][0]['participantsRaw']) {
        if (participantsRaw['userId'] === action.data['userId']) {
          participantsRaw['isFlagged'] = true;
          participantsRaw['comment'] = action.data['comment'];
          flag = true;
          break;
        }
      }
      if (!flag) {
        state['eventDetails']['bid'][0]['flag'].push({
          comment: action.data['comment'],
          userId: action.data['flaggedBy'],
        });
      }
      return {
        ...state,
      };
    case actionTypes.VALIDATE_ATTENDANCE_SUCCESS:
      const attEventDetails = state['eventDetails'];
      const attData = action.data;
      if (attData['userRole'] === staticConstants.ROLE.EXPERT) {
        attEventDetails.bid[0]['expertAttendence'] = {
          comment: attData['comment'], isParticipantsAttended: attData['isParticipantsAttended'],
          isAbleToDeliver: attData['isAbleToDeliver'],
        };
      } else {
        attEventDetails.bid[0]['adminAttendence'] = {
          comment: attData['comment'], isExpertAttended: attData['isExpertAttended'],
          isExpertDeliver: attData['isExpertDeliver'],
        };
      }
      return {
        ...state,
        eventDetails: attEventDetails,
      };
    case actionTypes.RATING_GOAL_SUCCESS:
      const rateEventDetails = state['eventDetails'];
      const rateData = action.data;
      if (rateData['userRole'] === staticConstants.ROLE.EXPERT) {
        rateEventDetails.bid[0]['expertRatingGoal'] = {
          comment: rateData['comment'], overAllQuality: rateData['overAllQuality'],
          participantsEngagement: rateData['participantsEngagement'], createdAt: rateData['createdAt'],
        };
      } else {
        rateEventDetails.bid[0]['adminRatingGoal'] = {
          comment: rateData['comment'], sessionRating: rateData['sessionRating'],
          expertRating: rateData['expertRating'], createdAt: rateData['createdAt'],
        };
      }
      return {
        ...state,
        eventDetails: rateEventDetails,
      };
    case actionTypes.UPDATE_EVENT_PARTICIPANTS_SUCCESS:
      const gpalParticipantsDetail = state['eventDetails'];
      gpalParticipantsDetail['participantsRaw'] = action.data['participants'];
      gpalParticipantsDetail['isMe'] = action.data['isMe'];
      return {
        ...state,
        eventDetails: gpalParticipantsDetail,
      };
    case actionTypes.CHANGE_EVENT_PARTICIPANTS_SUCCESS:
      return {
        ...state,
      };
    case actionTypes.GET_EXTEND_SESSION_FEE_SUCCESS:
      return {
        ...state,
        extendSessionFee: action.data['data'],
        status: { getExtendSessionFeeSuccess: true },
      };
    case actionTypes.GET_EXTEND_SESSION_FEE_FAILURE:
      return {
        ...state,
        status: { getExtendSessionFeeFailure: true },
      };
    case actionTypes.GET_EXTEND_SESSION_FEE_REQUEST:
      return {
        ...state,
        status: { getExtendSessionFeeRequest: true },
      };

    case actionTypes.UPDATE_EXTENT_EVENT_STATUS_REQUEST:
      return {
        ...state,
        status: { updateExtendEventStatusRequested: true },
      };
    case actionTypes.CANCEL_EVENT_SUCCESS:
      return {
      };
    case actionTypes.DOWNLOAD_SESSION_SUCCESS:
      return {
        ...state,
        sessionRecording: action.data,
        status: { downloadSessionSuccess: true },
      };
    case actionTypes.RESOLVE_EVENT_CONFLICT_SUCCESS:
      return {
        ...state,
        status: { resolveEventConflictSucess: true },
      };
    case actionTypes.RESOLVE_EVENT_CONFLICT_FAILURE:
      return {
        ...state,
        status: { resolveEventConflictSucess: false },
      };
    default:
      return state;
  }
}
