import moment from "moment-timezone";
import { history } from "../../../helpers";
import {
  pageRoutes,
  getNavToDashboard,
  checkLocalStorageGoal,
  MAGIC_NUMBER,
} from "../../../utils";
import { staticConstants } from "../../../utils/staticConstants";
import { userServices } from "./../../../services";
import { alertActions } from "./../../alert/redux/actions";
import { endLoading, startLoading } from "./../../loader/redux/actions";
import { actionTypes } from "./types";
import OpenFieldTypes from "OpenFieldTypes";
import { subscribeOnLogin, disConnectSocket } from "./../../../socket";
import { goalAction } from "./../../shared/goalManagement/redux/actions";

export const authAction = {
  login,
  logout,
  socialLogin,
  twitterLoginSuccess,
  resetUserData,
};

function login(email: string, password: string) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading("MAIN_LOADER", "Loading"));
    dispatch(request());
    const timezone = moment.tz.guess();
    userServices.login(email, password, timezone).then(
      (user) => {
        dispatch(endLoading("MAIN_LOADER"));
        if (
          (user.role !== staticConstants.ROLE.SYSTEM_ADMIN &&
            user.deviceCount === MAGIC_NUMBER.THREE &&
            (user.role === staticConstants.ROLE.ORGANIZATION_LEARNER ||
              user.role === staticConstants.ROLE.ORGANIZATION_ADMIN)) ||
          user.deviceCount >= MAGIC_NUMBER.FOUR
        ) {
          dispatch(success({ user, openScreenLimitModal: true }));
        } else {
          subscribeOnLogin(user.email);
          localStorage.setItem("token", user.token);

          if (
            user.role === staticConstants.ROLE.ORGANIZATION_ADMIN ||
            user.role === staticConstants.ROLE.ORGANIZATION_LEARNER
          ) {
            dispatch(success({ user, openScreenLimitModal: false }));
            if (user.role === staticConstants.ROLE.ORGANIZATION_ADMIN) {
              history.push(
                `${pageRoutes.ORGADMIN.PATH}${pageRoutes.ORGADMIN.DASHBOARD}`
              );
              checkLocalStorageGoal(dispatch, goalAction.createGoal);
            } else if (
              user.role === staticConstants.ROLE.ORGANIZATION_LEARNER
            ) {
              history.push(getNavToDashboard());
            }
          } else {
            dispatch(success({ user, openScreenLimitModal: false })); // Remaining Part For Redirection
            if (user.role === staticConstants.ROLE.EXPERT) {
              if (user.isFirstTime) {
                history.push(
                  `${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.EDIT_PROFILE.PATH}`
                ); // Todo: delete
              } else {
                history.push(
                  `${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.DASHBOARD}`
                );
              }
            } else if (user.role === staticConstants.ROLE.PROFESSIONAL) {
              history.push(
                `${pageRoutes.PROFESSIONAL.PATH}${pageRoutes.PROFESSIONAL.DASHBOARD}`
              );
            } else if (user.role === staticConstants.ROLE.SYSTEM_ADMIN) {
              history.push(
                `${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.SYSTEM_ADMIN.DASHBOARD}`
              );
            }
          }
        }
      },
      (error) => {
        dispatch(alertActions.errorAlert(error.toString()));
        dispatch(endLoading("MAIN_LOADER"));
      }
    );
  };

  function request() {
    return { type: actionTypes.LOGIN_REQUEST };
  }
  function success(user: any) {
    return { type: actionTypes.LOGIN_SUCCESS, ...user };
  }
}

function logout(isAll = false) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request());
    userServices.logout(isAll).then(
      () => {
        dispatch(success());
        disConnectSocket();
        dispatch(resetUserData());
        history.push(`${pageRoutes.LOGIN}`);
      },
      (error) => {
        dispatch(alertActions.errorAlert(error.toString()));
        dispatch(errors({ error }));
      }
    );
  };

  function request() {
    return { type: actionTypes.LOGOUT_REQUEST };
  }
  function success() {
    return { type: actionTypes.LOGOUT_REQUEST_SUCCESS };
  }
  function errors(error) {
    return { type: actionTypes.LOGOUT_REQUEST_FAILURE };
  }
}

function socialLogin(reqObj) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading("MAIN_LOADER", "Loading"));
    dispatch(request());
    userServices.socialLogin(reqObj).then(
      (user) => {
        dispatch(endLoading("MAIN_LOADER"));
        if (
          user.role !== staticConstants.ROLE.SYSTEM_ADMIN &&
          user.deviceCount >= MAGIC_NUMBER.FOUR
        ) {
          dispatch(success({ user, openScreenLimitModal: true }));
          dispatch(endLoading("MAIN_LOADER"));
        } else {
          dispatch(success({ user })); // Remaining Part For Redirection
        }
      },
      (error) => {
        dispatch(alertActions.errorAlert(error.toString()));
        dispatch(
          errors({
            error,
          })
        );
        dispatch(endLoading("MAIN_LOADER"));
      }
    );
  };

  function request() {
    return { type: actionTypes.SOCIAL_LOGIN_REQUEST };
  }
  function success(user: any) {
    return { type: actionTypes.SOCIAL_LOGIN_SUCCESS, ...user };
  }
  function errors(error) {
    return { type: actionTypes.SOCIAL_LOGIN_ERROR };
  }
}

function twitterLoginSuccess(user) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading("MAIN_LOADER", "Loading"));
    dispatch(request());
    if (
      user.role !== staticConstants.ROLE.SYSTEM_ADMIN &&
      user.deviceCount >= MAGIC_NUMBER.FOUR
    ) {
      dispatch(success({ user, openScreenLimitModal: true }));
      dispatch(endLoading("MAIN_LOADER"));
    } else {
      dispatch(success({ user }));
    }
    function request() {
      return { type: actionTypes.SOCIAL_LOGIN_REQUEST };
    }
    function success(data: any) {
      return { type: actionTypes.SOCIAL_LOGIN_SUCCESS, ...data };
    }
  };
}

function resetUserData() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch({ type: actionTypes.RESET_USER_DATA });
  };
}
