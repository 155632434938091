import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import OpenFieldTypes from 'OpenFieldTypes';
import _ from 'lodash';
import { LogItem } from './logItem';
import { logAction } from '../redux/actions';
import { getLocalUserData } from '../../../../utils/common';
import { Back } from '../../back/back';
import { MAGIC_NUMBER } from '../../../../utils';
interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  user: any;
  match?: any;
  userInfo: any;
}
class ListingContainer extends React.Component<IProps> {

  public static getDerivedStateFromProps(props, state) {
    if (props.logs && props.logs.list && props.logs.list.length && state.logsFetching) {
      const logs = props.logs.list.length ? state.logs.concat(props.logs.list)
        : state.logs;
      return {
        logs,
        count: props.logs.count,
        logsFetching: false,
      };
    }
    return null;
  }

  public state = {
    logs: [],
    page: 1,
    limit: 20,
    count: 0,
    logsFetching: false,
  };

 /**
  * @description componentDidMount is called when component is loaded
  * called logAction.getLogs
  */
  public componentDidMount() {
    const { id } = this.props.match.params;
    if (id !== undefined) {
      this.props.dispatch(logAction.getLogs({
        page: this.state.page,
        limit: this.state.limit,
        userId: id,
      }));
    } else {
      this.props.dispatch(logAction.getLogs({
        page: this.state.page,
        limit: this.state.limit,
      }));
    }
    this.setState({ page: this.state.page + 1, logsFetching: true });
  }

  public componentWillUnmount() {
    this.props.dispatch(logAction.clearLogs());
  }

  /**
   * fetch logs data on scroll
   */
  public fetchMoreData = () => {
    const { id } = this.props.match.params;
    if (this.state && this.state.count > (this.state.limit * this.state.page)) {
      if (id !== undefined) {
        this.props.dispatch(logAction.getLogs({
          page: this.state.page,
          limit: this.state.limit,
          userId: id,
        }));
      } else {
        this.props.dispatch(logAction.getLogs({
          page: this.state.page,
          limit: this.state.limit,
        }));
      }
      this.setState({
        page: this.state.page + 1,
        logsFetching: true,
      });
    }
  }

  /**
   * function to display logs
   */
  public renderLogs() {
    const { logs } = this.state;
    return _.map(logs, (log, index) => {
      return (
        <LogItem key={index} log={log} user={this.props.user} />
      );
    });
  }

  public render() {
    const { count, logs, logsFetching } = this.state;
    const { id } = this.props.match.params;
    return (
      <div className="dashboard-wrapper">
        {id === undefined && <div className="notification-breadcrumb">
          <h2 className="heading heading-sm text-uppercase roboto-medium">Logs</h2>
          </div> }
          <Back {...this.props} />
        <div className="dashboard-content">
          <div className="table-wrapper">
            <div className="w-100">
              <div className="table-body">
                <InfiniteScroll
                  dataLength={logs.length}
                  next={this.fetchMoreData}
                  hasMore={true}
                // loader={<h4>Loading..</h4>}
                >
                  {this.renderLogs()}
                  {count === MAGIC_NUMBER.ZERO && !logsFetching ?
                    <div className="table-row table-no-data">
                      No Logs Found.
                        </div>
                    : ''}
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: { logs: any; users: any }) {
  const localUser = getLocalUserData();
  const { users : { detail } } = state;
  return {
    logs: state.logs,
    user: localUser,
    userInfo: detail,
  };
}

const connectedNotificationListingContainer = connect(mapStateToProps)(ListingContainer);
export { connectedNotificationListingContainer as ListingContainer };
