export const actionTypes = {
  GET_CONTENT_REQUEST: 'GET_CONTENT_REQUEST',
  GET_CONTENT_SUCCESS: 'GET_CONTENT_SUCCESS',
  GET_CONTENT_FAILURE: 'GET_CONTENT_FAILURE',
  DELETE_FAQ_REQUEST: 'DELETE_FAQ_REQUEST',
  DELETE_FAQ_SUCCESS: 'DELETE_FAQ_SUCCESS',
  DELETE_FAQ_FAILURE: 'DELETE_FAQ_FAILURE',
  UPDATE_FAQ_REQUEST: 'UPDATE_FAQ_REQUEST',
  UPDATE_FAQ_SUCCESS: 'UPDATE_FAQ_SUCCESS',
  UPDATE_FAQ_FAILURE: 'UPDATE_FAQ_FAILURE',
  UPDATE_CONTENT_REQUEST: 'UPDATE_CONTENT_REQUEST',
  UPDATE_CONTENT_SUCCESS: 'UPDATE_CONTENT_SUCCESS',
  UPDATE_CONTENT_FAILURE: 'UPDATE_CONTENT_FAILURE',
  CONTENT_LIST_CLEAR: 'CONTENT_LIST_CLEAR',
};
