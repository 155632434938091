import { actionTypes } from './types';

const initialState = {
  domains: [],
  subDomains: [],
  categories: [],
  subCategories: [],
  topics: [],
  activities: [],
  tags: [],
  totalExpert: undefined,
};

interface IAction {
  type: string;
  data: {
    expert: number;
  };
  error: string;
  domain: {
    data: [];
    expert: number;
  };
  subDomain: {
    data: [];
    expert: number;
  };
  category: {
    data: [];
    expert: number;
  };
  subCategory: {
    data: [];
    expert: number;
  };
  topic: {
    data: [];
    expert: number;
  };
  activities: {
    data: [];
    expert: number;
  };
  tags: [];
}

export function tag(state = initialState, action: IAction) {
  switch (action.type) {
    case actionTypes.CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        categories: action.category.data,
        totalExpert: action.category.expert,
        count: action.category['count'],
      };
    case actionTypes.SUB_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        subCategories: action.subCategory.data,
        totalExpert: action.subCategory.expert,
        count: action.subCategory['count'],
      };
    case actionTypes.DOMAIN_LIST_SUCCESS:
      return {
        ...state,
        domains: action.domain.data,
        totalExpert: action.domain.expert,
        count: action.domain['count'],
      };
    case actionTypes.SUB_DOMAIN_LIST_SUCCESS:
      return {
        ...state,
        subDomains: action.subDomain.data,
        totalExpert: action.subDomain.expert,
        count: action.subDomain['count'],
      };
    case actionTypes.TOPIC_LIST_SUCCESS:
      return {
        ...state,
        topics: action.topic.data,
        totalExpert: action.topic.expert,
        count: action.topic['count'],
      };
    case actionTypes.ACTIVITY_LIST_SUCCESS:
      return {
        ...state,
        activities: action.activities.data,
        totalExpert: action.activities.expert,
        count: action.activities['count'],
      };
    case actionTypes.TAG_LIST_SUCCESS:
      return {
        ...state,
        searchTags: action.tags,
      };
    case actionTypes.EXPERT_COUNT_SUCCESS:
      return {
        ...state,
        totalExpert: action.data.expert,
      };
    case actionTypes.TAG_SET_STATUS:
      return {
        ...state,
        successForEditFlag: true,
      };
    case actionTypes.TAG_RESET_STATUS:
      return {
        ...state,
        successForEditFlag: false,
      };
    case actionTypes.EXPERT_COUNT_FAILURE:
    case actionTypes.CATEGORY_LIST_FAILURE:
    case actionTypes.SUB_CATEGORY_LIST_FAILURE:
    case actionTypes.DOMAIN_LIST_FAILURE:
    case actionTypes.SUB_DOMAIN_LIST_FAILURE:
    case actionTypes.TOPIC_LIST_FAILURE:
    case actionTypes.ACTIVITY_LIST_FAILURE:
    case actionTypes.TAG_LIST_FAILURE:
    case actionTypes.CREATE_TAG_FAILURE:
    case actionTypes.REMOVE_TAG_FAILURE:
    case actionTypes.PUBLISH_TAG_FAILURE:
      return { error: action.error };
    case actionTypes.RESET_ALL_TAGS:
      return {
        ...state,
        subDomains: [],
        categories: [],
        subCategories: [],
        topics: [],
        activities: [],
        tags: [],
      };
    default:
      return state;
  }
}
