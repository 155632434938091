import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { learnerAction } from '../redux/actions';
import ReeValidate from 'ree-validate';
import OpenFieldTypes from 'OpenFieldTypes';
import { IValidator } from './../../../../interfaces';
import { restrictSpaceEnter } from '../../../../utils/common';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  isOpenModal: boolean;
  hideModal: any;
  assignLicence: any;
}

interface IState {
  submitted: boolean;
  firstName: string;
  lastName: string;
  email: string;
  errors?: any;
  formData?: any;
  modal: boolean;
}
class CreateLearner extends React.Component<IProps, IState> {

  public validator: IValidator;
  constructor(props: IProps) {
    super(props);

    // reset login status
    this.validator = new ReeValidate({
      firstName: 'required',
      email: 'required|email',
      lastName: 'required',
    });

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      submitted: false,
      errors: this.validator.errors,
      modal: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.validateAndSubmit = this.validateAndSubmit.bind(this);

  }

  public componentWillReceiveProps(props: IProps, nextProp: IProps) {
    this.setState({
      modal: props.isOpenModal,
    });
  }

  public handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    const { errors } = this.validator;
    this.setState({
      [name]: value,
    } as any);
    errors.remove(name);
    this.setState({ formData: { ...this.state, [name]: value } });

    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors });
      });
  }

  public validateAndSubmit(e: any) {
    e.preventDefault();
    const { firstName, lastName, email } = this.state;
    const { errors } = this.validator;

    this.validator.validateAll({ firstName, lastName, email })
      .then((success: any) => {
        if (success) {
          this.handleSubmit();
        } else {
          this.setState({ errors });
        }
      });

  }

  public handleSubmit() {
    this.setState({ submitted: true });
    const dispatch = this.props.dispatch;
    const { firstName, lastName, email } = this.state;
    if (firstName && lastName && email) {
      dispatch(learnerAction.createLearner({
        firstName,
        lastName,
        email,
      }));
    }
  }

  public hideModal() {
    this.setState({
      modal: false,
      firstName: '',
      lastName: '',
      email: '',
    });
    const { errors } = this.validator;
    errors.clear();
    this.props.hideModal();
  }

  public render() {
    const { firstName, lastName, email, errors, modal } = this.state;
    const { assignLicence } = this.props;

    return (
      <Modal isOpen={modal} className="organization-code-modal" onClosed={this.hideModal}>
        <ModalHeader toggle={this.hideModal}>Add User</ModalHeader>
        <ModalBody>
          <Form onSubmit={this.validateAndSubmit} autoComplete="off" noValidate>
            <FormGroup
              className={`floating-label ${
                errors.has('firstName') ? ' has-error' : ''
                }`}
            >
              <Input
                className="form-control"
                id="firstName"
                name="firstName"
                placeholder="First Name"
                value={firstName}
                onChange={this.handleChange}
              />
              <Label for="firstName">First Name</Label>
              {errors.has('firstName') &&
                <div className="error-text">{errors.first('firstName').replace('firstName', 'first name')}</div>
              }
            </FormGroup>
            <FormGroup
              className={`floating-label ${
                errors.has('lastName') ? ' has-error' : ''
                }`}
            >
              <Input
                className="form-control"
                id="lastName"
                name="lastName"
                placeholder="Last Name"
                value={lastName}
                onChange={this.handleChange}
              />
              <Label for="lastName">Last Name</Label>
              {errors.has('lastName') &&
                <div className="error-text">{errors.first('lastName').replace('lastName', 'last name')}</div>
              }
            </FormGroup>
            <FormGroup
              className={`floating-label ${
                errors.has('email') ? ' has-error' : ''
                }`}
            >
              <Input
                className="form-control"
                id="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={this.handleChange}
              />
              <Label for="email">Email</Label>
              {errors.has('email') &&
                <div className="error-text">{errors.first('email')}</div>
              }
            </FormGroup>
            <FormGroup className="mb-0">
              <label className="control control--checkbox mb-2 pl-4">
               <Input
                  type="checkbox"
                  checked={assignLicence}
                  name="Assign Licence"
                />{'Assign Licence'}
                 <div className="control__indicator" />
                </label>
              <p className="error-input-text mb-0">{(!assignLicence) && 'Please purchase more licence to add a new Member.'}</p>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-regular ml-0" onClick={this.hideModal}>Cancel</Button>{' '}
          <Button color="primary" className="mr-2" onKeyDown={restrictSpaceEnter} disabled={!assignLicence} onClick={this.validateAndSubmit}>Add</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

function mapStateToProps(state: { learner: any }) {
  const { loggingIn } = state.learner;
  return {
    loggingIn,
  };
}

const connectedCreateLearnerFormPage = connect(mapStateToProps)(CreateLearner);
export { connectedCreateLearnerFormPage as CreateLearner };
