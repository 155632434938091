import { actionTypes } from './types';

export interface Action {type: string; message: string; }

export function alert(state = {}, action: Action= { type: '', message: '' }) {
  switch (action.type) {
    case actionTypes.SUCCESS:
      return {
        type: 'alert-success',
        message: action.message,
      };
    case actionTypes.ERROR:
      return {
        type: 'alert-danger',
        message: action.message,
      };
    case actionTypes.CLEAR:
      return {};
    default:
      return state;
  }
}
