import { history } from '../../../../helpers';
import { IOrgAdmin, IProfessional } from '../../../../interfaces';
import { pageRoutes, handleError } from '../../../../utils/';
import { userServices } from './../../../../services';
import { endLoading, startLoading } from './../../../loader/redux/actions';
import { actionTypes } from './types';
import OpenFieldTypes from 'OpenFieldTypes';

export const registerAction = {
  register,
  registerProfessional,
};

function register(user: IOrgAdmin) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(user));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.register(user)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(res));
          history.push(`${pageRoutes.REGISTER.SUCCESS}`);
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };

  function request(res: IOrgAdmin) { return { type: actionTypes.REGISTER_REQUEST, res }; }
  function success(res: IOrgAdmin) { return { type: actionTypes.REGISTER_SUCCESS, res }; }
  function failure(error: string) { return { type: actionTypes.REGISTER_FAILURE, error }; }
}

function registerProfessional(user: IProfessional) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(user));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.professionalRegister(user)
      .then(
        (res) => {
          dispatch(success(res));
          history.push(`${pageRoutes.REGISTER.SUCCESS}`);
          dispatch(endLoading('MAIN_LOADER'));
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };

  function request(res: IProfessional) { return { type: actionTypes.REGISTER_REQUEST, res }; }
  function success(res: IProfessional) { return { type: actionTypes.REGISTER_SUCCESS, res }; }
  function failure(error: string) { return { type: actionTypes.REGISTER_FAILURE, error }; }
}
