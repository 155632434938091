import { actionTypes } from './types';
interface IAction {
  type: string;
}
export function registeration(state = {}, action: IAction) {
  switch (action.type) {
      case actionTypes.REGISTER_REQUEST:
        return { registering: true };
      case actionTypes.REGISTER_SUCCESS:
        return {};
      case actionTypes.REGISTER_FAILURE:
        return {};
      default:
        return state;
    }
}
