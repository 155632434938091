import gql from 'graphql-tag';

const ADD_TAGS = gql`
  mutation createTag( $name: String, $type: String, $domainId: String, $subDomainId: String,
     $categoryId: String, $subCategoryId: String ) {
    createTag( name: $name, type: $type, domainId: $domainId, subDomainId: $subDomainId,
         categoryId: $categoryId, subCategoryId: $subCategoryId) {
        message
    }
}`;

const REMOVE_TAGS = gql`
  mutation removeTag( $tagId: String, $type: String ) {
    removeTag( tagId: $tagId, type: $type) {
        message
    }
}`;

const PUBLISH_TAGS = gql`
  mutation publish( $tagId: String) {
    publish( tagId: $tagId) {
        message
    }
}`;

const EDIT_TAGS = gql`
  mutation editTag( $name: String, $type: String, $domainId: String, $subDomainId: String,
     $categoryId: String, $subCategoryId: String, $tagId: String ) {
   editTag( name: $name, type: $type, domainId: $domainId, subDomainId: $subDomainId,
         categoryId: $categoryId, subCategoryId: $subCategoryId, tagId: $tagId) {
        message
    }
}`;

export { ADD_TAGS, REMOVE_TAGS, PUBLISH_TAGS, EDIT_TAGS };
