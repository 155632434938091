import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import OpenFieldTypes from 'OpenFieldTypes';
import { cardAction } from '../redux/actions';
import { Card, CardText, CardTitle } from 'reactstrap';
import { ConfirmatioPopup } from '../../modals/confirmationPopup';
import { deleteSavedCard } from './../../../../utils';
interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  card: any;
  isDefaultCard: boolean;
}

interface IState {
  displayDeleteCardPopup: boolean;
  modalPopupObj: object;
  cardId: string;
}

class CardItem extends React.Component<IProps, IState> {

  public cardBrandToPfClass = {
    visa: 'pf-visa',
    mastercard: 'pf-mastercard',
    amex: 'pf-american-express',
    discover: 'pf-discover',
    diners: 'pf-diners',
    jcb: 'pf-jcb',
    unknown: 'pf-credit-card',
  };

  /**
   * @description
   * constructor is used to define the initial state and property
   * @param fields {Object} props
   */
  constructor(props: IProps) {
    super(props);
    this.state = {
      displayDeleteCardPopup: false,
      modalPopupObj: {},
      cardId: '',
    };
  }

  /**
   * @description
   * handleChange is used to set the value on state from the only input/textarea boxes.
   * also remove the form validation. Once form validated the forward the control to parenet component
   * @param fields {Object} event
   */
  public handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    this.props.dispatch(cardAction.updateCard({
      cardId: value,
    }));
  }

  /**
   * @description
   * deleteCard is used to delete the card from the listing
   */
  public deleteCard = () => {
    const { cardId } = this.state;
    this.props.dispatch(cardAction.deleteCard({
      cardId,
    }));
    this.setState({
      displayDeleteCardPopup: false,
      modalPopupObj: {},
      cardId: '',
    });
  }

  /**
   * @description
   * showDeletePopup is used show delete popup.
   */
  public showDeletePopup(cardId: string) {
    const modalPopupObj = deleteSavedCard;
    this.setState({
      displayDeleteCardPopup: true,
      modalPopupObj,
      cardId,
    });
  }

  /**
   * @description
   * render is used to display html page
   */
  public render() {
    const { card } = this.props;
    const { displayDeleteCardPopup, modalPopupObj } = this.state;
    let pfClass = 'pf-credit-card';
    const brand = card.brand.toLowerCase();
    if (brand in this.cardBrandToPfClass) {
      pfClass = this.cardBrandToPfClass[brand];
    }
    return (
      <React.Fragment>
        <Card>
          <label className="control control--radio">
            <input
              type="radio"
              defaultChecked={this.props.isDefaultCard}
              id="audio"
              name="modeOfDevivery"
              value={card.id}
              onChange={this.handleChange}
            />
            <div className="control__indicator" />
          </label>
          <CardTitle className="text-truncate">{card.name}</CardTitle>
          <span className="brand">
            <i className={`pf ` + pfClass} />
          </span>
          <CardText>XXXXXXXXXXXX{card.last4}</CardText>
          <CardText>
            Expiry: {card.exp_month}/{card.exp_year}{' '}
            {this.props.isDefaultCard && <span className="cursor-default">Default</span>}
            {!this.props.isDefaultCard && (
              <span onClick={() => this.showDeletePopup(card.id)}>Delete</span>
            )}
          </CardText>
        </Card>
        {displayDeleteCardPopup ? (
          <ConfirmatioPopup
            isOpenModal={displayDeleteCardPopup}
            modalPopupObj={modalPopupObj}
            hideModal={() => this.setState({ displayDeleteCardPopup: false })}
            onClickYes={this.deleteCard}
          />
        ) : (
            ''
          )}
      </React.Fragment>
    );
  }
}

const cardPage = connect(null)(CardItem);
export { cardPage as CardItem };
