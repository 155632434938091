import React, { Component, Dispatch } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import OpenFieldTypes from 'OpenFieldTypes';
import { Sidebar } from '../../shared';
import { history } from '../../../helpers';
import { Back } from '../../shared/back/back';
import paymentIcon from './../../../assets/images/icons/payment.svg';
import { expertPaymentAction } from './redux/actions';
import { ErrorPopup } from '../../shared/modals/errorPopup';
import { ILocalUser, IViewProfile } from './../../../interfaces';
import { formatCurrency } from '../../../utils/common';
import { MAGIC_NUMBER } from '../../../utils';
import WithdrawStripeRegion from './WithdrawStripeRegion';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  expertIncome: { totalReceieved: number, totalPending: number };
  user: ILocalUser;
  viewProfile: IViewProfile;
}

interface IState {
  displayErrorPopup: boolean;
}

class WithdrawAmount extends Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = { displayErrorPopup: false };
  }
  public componentDidMount() {
    this.props.dispatch(expertPaymentAction.getExpertIncome());
  }

  public withdrawAmount = () => {
    const { dispatch, user } = this.props;
    if (!Boolean(user.isStripeAccount)) {
      this.setState({ displayErrorPopup: true });
    } else {
      dispatch(expertPaymentAction.withdrawAmount());
    }
  }

  public render() {
    const { expertIncome: { totalReceieved, totalPending }, user } = this.props;
    return (
      <>
        <Sidebar {...this.props} />
        <div className="dashboard-wrapper">
          <Back {...this.props} />
          <WithdrawStripeRegion {...this.props}  />
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const { expertPayments: { expertIncome }, authentication: { user } } = state;
  return { expertIncome, user };
}

const connectedWithdrawAmount = connect(mapStateToProps)(WithdrawAmount);

export default connectedWithdrawAmount;
