import React, { Dispatch, Fragment } from 'react';
import { connect } from 'react-redux';
import { staticConstants } from './../../utils/staticConstants';
import { LoginForm } from './LoginForm';
import { LimitScreenPopUp } from './LimitScreenPopUp';
import OpenFieldTypes from 'OpenFieldTypes';
interface IProps {
  isOpenModal: boolean;
  isLimitScreenModal: boolean;
  token: string;
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
}

class LoginContainer extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isOpenModal: false,
      isLimitScreenModal: false,
    };
  }

  public componentDidMount() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'hs-script-loader';
    script.src = '//js.hs-scripts.com/7903349.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  public render() {
    const { isOpenModal, isLimitScreenModal } = this.props;
    return (
      <Fragment>
        <LoginForm isOrgCodeModalOpen={isOpenModal}/>
        {isLimitScreenModal && <LimitScreenPopUp isLimitScreenModal={isLimitScreenModal} />}
      </Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  const { user, openScreenLimitModal  } = state.authentication;
  let isOpenModal = false;
  let token: string;
  if (user && (user.role === staticConstants.ROLE.ORGANIZATION_ADMIN ||
    user.role === staticConstants.ROLE.ORGANIZATION_LEARNER)) {
    isOpenModal = true;
    token = user.token;
  }
  return {
    isOpenModal,
    token,
    isLimitScreenModal: openScreenLimitModal,
  };
}

const connectedLoginPage = connect(mapStateToProps)(LoginContainer);
export { connectedLoginPage as LoginContainer };
