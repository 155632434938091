import { actionTypes } from './types';

export function logs(state = {}, action: any) {
  switch (action.type) {
    case actionTypes.LOG_LIST_REQUEST:
      return {
        logListRequest: true,
      };
    case actionTypes.LOG_LIST_SUCCESS:
      return {
        list: action.logs.data,
        count: action.logs.count,
      };
    case actionTypes.LOG_LIST_FAILURE:
      return {
        logListError: true,
      };
    case actionTypes.LOG_LIST_CLEAR:
      return {};
    default:
      return state;
  }
}
