import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { pageRoutes } from './../../../../utils/routeConstants';
import OpenFieldTypes from 'OpenFieldTypes';
import { UncontrolledTooltip } from 'reactstrap';
import { history } from '../../../../helpers';
import { ACTION_CLASSES, MAGIC_NUMBER } from './../../../../utils';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  learner: any;
  reVerifyUser?: (userId: string) => void;
  assignLicence(learnerData: object): void;
  unAssignLicence(learnerData: object): void;
  deleteLearner(id: string): void;
}

class LearnerItem extends React.Component<IProps> {

  public handleChange = (e: any, key: string) => {
    this.setState({
      ...this.state, [key]: e.target,
    });
  }

  public redirectUserDetail(event: any, learner: any) {
    if (ACTION_CLASSES.indexOf(event.target.className) === MAGIC_NUMBER.FOUND_INDEX) {
      history.push({
        pathname: `${pageRoutes.LEARNERMANAGEMENT.PATH}${pageRoutes.LEARNERMANAGEMENT.DETAIL}/${learner['_id']}`,
        state: {},
      });
    }
  }

  public render() {
    const { learner, assignLicence, unAssignLicence, deleteLearner, reVerifyUser } = this.props;
    const status = learner['isLicenced'] ? 'Assigned' : 'Unassigned';
    return (
      <div className="table-row" onClick={(event) => this.redirectUserDetail(event, learner)} >
        <div className="table-col table-col-40" data-label="Name">
          <span className="roboto-medium link-btn text-truncate" >
            {`${learner['firstName']} ${learner['lastName']}`}
          </span>
        </div>
        <div className="table-col table-col-30" data-label="Email">
          <span>{learner['email']}</span>
        </div>
        <div className="table-col table-col-17" data-label="Status">
          {!learner['isVerified']
            ? <span >{`${'Request Sent'}`}</span>
            : <span className={`${!learner['isLicenced'] ? 'text-alert' : ''}`}>{status}</span>
          }

        </div>

        <div className="table-col table-col-13 text-center p-0 action-col">
          {(!learner['isVerified']) && <>
          <em className="icon icon-resend" id={`tooltip_resend_${learner['_id']}`} onClick={() => reVerifyUser(learner['_id'])}/>
          <UncontrolledTooltip placement="top" target={`tooltip_resend_${learner['_id']}`} >Resend</UncontrolledTooltip>
          </>}
          { learner['isLicenced']
            ? <>
            <em className="icon icon-baseline-group-fwd unassigned" id={`tooltip_unassign_${learner['_id']}`} onClick={() => unAssignLicence(learner)}/>
            <UncontrolledTooltip placement="top" target={`tooltip_unassign_${learner['_id']}`} >Unassign</UncontrolledTooltip>
            </>
            : <>
            <em className="icon icon-baseline-group-fwd" id={`tooltip_assign_${learner['_id']}`} onClick={() => assignLicence(learner)}/>
            <UncontrolledTooltip placement="top" target={`tooltip_assign_${learner['_id']}`} >Assign</UncontrolledTooltip>
            </>
          }
          {
            <><em className="icon icon-delete"  id={`tooltip_delete_${learner['_id']}`} onClick={() => deleteLearner(learner['_id'])} />
            <UncontrolledTooltip placement="top" target={`tooltip_delete_${learner['_id']}`} >Delete</UncontrolledTooltip></>
          }
        </div>
      </div>
    );
  }
}
const learnerItemPage = connect(null)(LearnerItem);
export { learnerItemPage as LearnerItem };
