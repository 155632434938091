import React from 'react';
import SocialLogin from 'react-social-login';

interface IProps {
  triggerLogin?: any;
}

class SocialButton extends React.Component<IProps> {

  public render() {
      return (
            <button onClick={this.props.triggerLogin} {...this.props}>
              {this.props.children}
            </button>
        );
    }
}

export default SocialLogin(SocialButton);
