import _ from 'lodash';
import { v4 } from 'uuid';
import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import OpenFieldTypes from 'OpenFieldTypes';
import { IValidator } from '../../../../interfaces';
import userProfile from '../../../../assets/images/profile.png';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  isOpenModal: boolean;
  flags: any;
  modalTitle: string;
  hideModal(): void;
}

interface IState {
  modal: boolean;
}

class FlaggedRemarks extends React.Component<IProps, IState> {
  public validator: IValidator;
  constructor(props: IProps) {
    super(props);
    this.state = {
      modal: this.props.isOpenModal,
    };
  }

  public hideModal = () => {
    this.setState({
      modal: false,
    });
    this.props.hideModal();
  }

  public componentWillReceiveProps(props: IProps, nextProp: IProps) {
    this.setState({
      modal: props.isOpenModal,
    });
  }

  public renderFlagListings() {
    const { flags } = this.props;
    return _.map(flags, (item) => {
      const user = item.userId;
      return (
        <div className="flagged-wrapper">
          <div className="flagged-row" key={v4()}>
            <div className="profile-row">
              <div className="flagged-profile-icon">
                {user && user.profileImage && user.profileImage !== '' ?
                  <img
                    src={user.profileImage}
                    alt="user profile"
                    className="img-cover"
                  />
                  : <img
                    src={userProfile}
                    alt="user profile"
                    className="img-cover"
                  />}
              </div>
              <div className="profile-email text-truncate">
                <p className="text-truncate"> {user && user.firstName} {user && user.lastName} </p>
                <em className="text-truncate">{user && user.email}</em>
              </div>
            </div>
            <p className="profile-data">
              {item.comment}
            </p>
          </div>
        </div>
      );
    });
  }

  public render() {
    const { modal } = this.state;
    const { modalTitle } = this.props;
    return (
      <Modal isOpen={modal} className="organization-code-modal flagged-modal" onClosed={this.hideModal}>
        <ModalHeader toggle={this.hideModal}>{modalTitle}</ModalHeader>
        <ModalBody className="p-0">
          {this.renderFlagListings()}
        </ModalBody>
      </Modal>
    );
  }
}

const connectedFlaggedRemarks = connect()(FlaggedRemarks);
export { connectedFlaggedRemarks as FlaggedRemarks };
