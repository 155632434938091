import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import csc from 'country-state-city';
import { Controller } from 'react-hook-form';
import {customSelectStyles } from '../../../utils/';
import {
  Label,
} from 'reactstrap';
interface IProps {
  control: any;
  setValue: any
}
export default function Country(props: IProps) {
  const { control, setValue } = props;
  const countryOptions = csc.getAllCountries();
  const updateCountryOptions = countryOptions.map(country => ({
    label: country.name,
    value: country.id,
  }));

  return (
    <Controller
      control={control}
      name="country"
      defaultValue={null}
      render={({ onChange, value }) => (
        <>
          <Select
            placeholder="Select country"
            className="react-select-box select-box-group"
            isDisabled={false}
            name="country"
            styles={customSelectStyles}
            onChange={(e) => {
              onChange(e);
              setValue('state', null);
              setValue('city', null);
            }}
            options={updateCountryOptions}
            value={value}
          />
          <Label for="country" className={value ? 'selected' : ''}>Country</Label>
        </>
      )}
      rules={{ required: true }}
    />
  );
}
