import React, { Fragment, Component, Dispatch } from 'react';
import { connect } from 'react-redux';
import OpenFieldTypes from 'OpenFieldTypes';
import { DashBoardPage } from './DashBoardPage';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
}

class DashBoardContainer extends Component<IProps> {

  public render() {
    const props = this.props;
    return (
      <div className="form-container d-flex">
        <Fragment>
          <DashBoardPage {...props}/>
        </Fragment>
      </div>
    );
  }
}

const connectedDashBoard = connect(null)(DashBoardContainer);

export { connectedDashBoard as DashBoardContainer };
