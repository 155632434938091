import _ from 'lodash';
import moment from 'moment';
import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Col,
  Row,
} from 'reactstrap';
import { Header } from '../../header';
import { Sidebar } from '../../sidebar';
import { goalAction } from '../redux/actions';
import profileImage from './../../../../assets/images/profile.png';
import { IGoalDetail, IParams, ILocalUser } from './../../../../interfaces';
import {
  staticConstants, deleteGoalMsg, pageRoutes,
  sendRFPModalContent, goalStatus, sendForApprovalModalContent, getGoalStatusText, goalStatusLearnerTimeline,
  goalStatusOrgAdminTimeline, getGoalStatusTextTitle, MAGIC_NUMBER
} from './../../../../utils';
import OpenFieldTypes from 'OpenFieldTypes';
import { history } from '../../../../helpers';
import { ConfirmatioPopup } from '../../modals/confirmationPopup';
import { RejectReasonPopup } from '../../modals/rejectReasonPopup';
import { ReqSendErrorPopup } from '../../modals/reqSendErrorPopup';
import { GoalAdminPopup } from '../../modals/goalAdminPopup';
import { Back } from '../../back/back';

interface IProps {
  isOpenModal: boolean;
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  match: {
    params: {
      [key: string]: IParams;
    };
  };
  goal: object;
  goals: any;
  goalDetails: IGoalDetail;
  user: ILocalUser;
}

interface IState {
  modal: boolean;
  goalDetails: IGoalDetail;
  displayDeletePopup: boolean;
  goalId: string;
  reqObj: object;
  modalPopupObj: object;
  displayRFPPopup: boolean;
  displayApprovalPopup: boolean;
  displayRejectReasonModal: boolean;
  openModalToAddAdmin: boolean;
  displaySendErrorPopup: boolean,
}

class GoalDetailContainer extends React.Component<IProps, IState> {

  /**
   * @description getDerivedStateFromProps is called when there in change in property
   * @param fields {Object} props
   */
  public static getDerivedStateFromProps(props: IProps) {

    if (props.goals && props.goals.goalDetails) {
      return {
        goalDetails: props.goals.goalDetails,
      };
    }
    return null;
  }

  /**
   * @description
   * constructor is used to define the initial state and property
   * @param fields {Object} props
   */
  constructor(props: IProps) {
    super(props);
    this.state = {
      modal: false,
      goalDetails: {
        participants: [],
        createdAt: '',
        fromDate: '',
        toDate: '',
        rejectedReason: '',
        fromTime: '',
        toTime: '',
        title: '',
        eventType: '',
        modeOfDevivery: '',
        description: '',
        topic: {
          name: '',
          id: '',
        },
        domain: {
          name: '',
          id: '',
        },
        subDomain: {
          name: '',
          id: '',
        },
        category: {
          name: '',
          id: '',
        },
        subCategory: {
          name: '',
          id: '',
        },
        // timeZone: '',
        duration: '',
        isMe: false,
        isReviewTags: false,
        status: '',
      },
      displayDeletePopup: false,
      displayRFPPopup: false,
      displayApprovalPopup: false,
      goalId: '',
      reqObj: {
        redirect: true,
      },
      modalPopupObj: {},
      displayRejectReasonModal: false,
      openModalToAddAdmin: false,
      displaySendErrorPopup: false,
    };
  }

  /**
   * @description componentDidMount is called when component is loaded
   * call goalAction.getGoalDetails to fetch goal detail
   */
  public componentDidMount() {
    const { match: { params } } = this.props;
    if (params.id) {
      this.props.dispatch(goalAction.getGoalDetails({ id: params.id }));
    }
  }

  /**
   * @description
   * showDeletePopup is used show delete popup.
   */
  public showDeletePopup() {
    const { match: { params } } = this.props;
    const modalPopupObj = deleteGoalMsg;
    this.setState({
      displayDeletePopup: true,
      goalId: String(params.id),
      modalPopupObj,
    });
  }

  /**
   * @description
   * deleteGoal is used show delete popup.
   */
  public deleteGoal = () => {
    const { goalId, reqObj } = this.state;
    this.props.dispatch(goalAction.deleteGoal({ goalId }, reqObj));
    this.setState({
      displayDeletePopup: false,
      modalPopupObj: {},
      goalId: '',
    });
  }

  /**
   * @description
   * showSendRFPPopup is used show rfp popup.
   */
  public showSendRFPPopup() {
    const { match: { params } } = this.props;
    if ((moment(this.state.goalDetails.toDate).diff(moment(), 'days') > 90)) {
      this.setState({
        displaySendErrorPopup: true
      });
    } else {
      const modalPopupObj = sendRFPModalContent;
      this.setState({
        displayRFPPopup: true,
        goalId: String(params.id),
        modalPopupObj,
      });
    }
  }

  /**
   * @description
   * isAdmin is used to check participant is added as
   */
  public isAdmin(participants: any, participantId: any) {
    let isAdmin = false;
    for (const participant of participants) {
      if (participantId === participant['userId'] && participant.isAdmin) {
        isAdmin = true;
        break;
      }
    }
    return isAdmin;
  }

  /**
   * @description
   * sendRFP is used send rfp.
   */
  public sendRFP = () => {
    const { goalId } = this.state;
    this.props.dispatch(goalAction.updateGoalStatus({
      goalId,
      isFromDetailPage: true,
      status: goalStatus.RFP_REQUEST,
    }));
    this.setState({
      displayRFPPopup: false,
      modalPopupObj: {},
      goalId: '',
    });
  }

  /**
   * @description
   * showSendApprovalPopup is used show approval popup.
   * @param fields {String} id
   */
  public showSendApprovalPopup = () => {
    const modalPopupObj = sendForApprovalModalContent;
    this.setState({
      displayApprovalPopup: true,
      modalPopupObj,
    });
  }

  /**
   * @description
   * sendForApproval is called when someone click on the ok button
   */
  public sendForApproval = () => {
    const { match: { params } } = this.props;
    this.props.dispatch(goalAction.updateGoalStatus({
      goalId: String(params.id),
      isFromDetailPage: true,
      status: goalStatus.REQUEST_SEND_BY_LERNER,
    }));
    this.setState({
      displayApprovalPopup: false,
      modalPopupObj: {},
    });
  }

  /**
   * @description
   * renderParticipantsList is used to render partipant list.
   */
  public renderParticipantsList = () => {
    const { participants, participantsRaw } = this.state.goalDetails;
    const participantsDisplayList = _.map(participants, (item: any) => {
      const isAdmin = this.isAdmin(participantsRaw, item._id);
      return { isAdmin, firstName: item.firstName, lastName: item.lastName, _id: item._id, profileImage: item.profileImage };
    });
    participantsDisplayList.sort((a, b) => a.isAdmin ? -1 : 1);
    return _.map(participantsDisplayList, (item: any) => {
      const fetchedProfileImage = item && item.profileImage !== '' ?
        item.profileImage : profileImage;
      const name = (item.firstName || item.lastName) ? `${item.firstName} ${item.lastName}`
        : (item.isAdmin ? 'Team Admin' : 'Team Member');
      return item && (
        <div className={'tag-item ' + ((item.isAdmin) ? 'green-bg' : 'gray-out')}
          key={`tag- ${item._id}`}>
          <span>
            <img className="img-cover" src={fetchedProfileImage} alt="" />
          </span>
          {name}
        </div>
      );
    });
  }

  /**
   * @description
   * updateGoalStatus is called when to update the status
   */
  public updateGoalStatus = (id: string, status: string) => {
    if (status === goalStatus.REJECT) {
      this.showRejectReasonModal({ goalId: id });
    } else {
      this.props.dispatch(goalAction.updateGoalStatus({
        goalId: id,
        isFromDetailPage: true,
        status,
      }));
    }
  }

  public showRejectReasonModal = (obj: { goalId: string }) => {
    this.setState({
      displayRejectReasonModal: true,
      modalPopupObj: obj,
    });
  }
  public hideRejectReasonModal = () => {
    this.setState({
      displayRejectReasonModal: false,
      modalPopupObj: null,
    });
  }
  public handleRejectReasonSubmit = (obj: { goalId: string; message: string }) => {
    this.props.dispatch(goalAction.updateGoalStatus({
      goalId: obj.goalId,
      isFromDetailPage: true,
      message: obj.message,
      status: goalStatus.REJECT,
    }));
    this.hideRejectReasonModal();
  }

  public hideAddAdminModal = () => {
    this.setState({
      openModalToAddAdmin: false,
    });
  }
  public handleAddAdminSubmit = (data: { participants: Array<{ isAdmin: boolean; userId: string }>; isMe: boolean }) => {
    this.props.dispatch(goalAction.updateGoalParticipants({ ...data, goalId: this.state.goalDetails['_id'] }));
  }

  /**
   * @description function to render tag list
   */
  public renderTagList = () => {
    const { goalDetails } = this.state;
    const keys = ['domain', 'subDomain', 'category', 'subCategory', 'topic'];
    return keys.map(key => {
      return goalDetails[key] && goalDetails[key].id && <span className="tags-view-only" key={goalDetails[key].id}>{goalDetails[key].name}</span>;
    });
  }

  /**
   * @description
   * openModalToAddAdmin is used to hide addAdminModal
   */
  public openModalToAddAdmin = () => {
    this.setState({
      openModalToAddAdmin: true,
    });
  }
  /**
   * @description
   * render is used to render html
   */
  public render() {
    const { match: { params } } = this.props;
    const { role } = this.props.user;
    const { goalDetails, displayDeletePopup, modalPopupObj, displayRFPPopup, displaySendErrorPopup,
      displayApprovalPopup, displayRejectReasonModal, openModalToAddAdmin } = this.state;
    const fromDate = moment(goalDetails.fromDate).format(staticConstants.DATE_FORMAT);
    const toDate = moment(goalDetails.toDate).format(staticConstants.DATE_FORMAT);
    const fromTime = moment(goalDetails.fromTime).format(staticConstants.TIME_FORMAT);
    const toTime = moment(goalDetails.toTime).format(staticConstants.TIME_FORMAT);
    const isSystemAdminRole = role === staticConstants.ROLE.SYSTEM_ADMIN;

    const getRequestDate = (status) => {
      if (!goalDetails.statusTimeline) { return; }
      const timeLine = goalDetails.statusTimeline.find(el => el.status === status);
      return timeLine ? moment(timeLine.createdAt).format(staticConstants.DATE_FORMAT) : null;
    };

    const renderTimeLine = () => {
      return <div className="timeline-view selected">
        <h2 className={`heading roboto-medium ${goalDetails.status === goalStatus.REJECT || goalDetails.status === goalStatus.DELETED
          ? 'reject' : goalDetails.status !== goalStatus.CREATED ? 'success' : ''}
          ${goalDetails.status === goalStatus.RFP_REQUEST ? 'selected' : ''}`
        }>
          {getGoalStatusTextTitle(goalDetails.status)}</h2>
        <div className={`timeline-row created-row`}>
          <small>{getGoalStatusText(goalStatus.CREATED)}</small>
          <p>{getRequestDate(goalStatus.CREATED)}</p>
          <span className="icon time-icon" />
        </div>
        {goalDetails.orgId ? renderLearnerTimeLine(goalStatusLearnerTimeline) :
          renderLearnerTimeLine(goalStatusOrgAdminTimeline)}
      </div>;
    };
    /**
     * @description renders timeline
     * @param arr time line array
     */

    const renderLearnerTimeLine = (arr: string[]) => {
      const html = [];
      let acceptedrejected = false;
      if (goalDetails.orgId && goalDetails.status === goalStatus.ACCEPT) {
        const pos = arr.indexOf(goalStatus.REJECT);
        if (pos >= 0) { arr.splice(pos, 1, goalStatus.ACCEPT); }
      } else if (goalDetails.orgId && goalDetails.status === goalStatus.REJECT) {
        const pos = arr.indexOf(goalStatus.ACCEPT);
        if (pos >= 0) { arr.splice(pos, 1, goalStatus.REJECT); }
      }
      const indexValue = arr.indexOf(goalStatus.EVENT_COMPLETED);
      (indexValue > -1) && arr.splice(indexValue, 1);

      arr.forEach((element, index) => {
        const currStatus = _.find(goalDetails.statusTimeline, (timeline) => {
          return timeline.status === element;
        });
        let selectedClass = null;
        if (currStatus) {
          selectedClass = 'active';
        }
        const goalStatusText = getGoalStatusText(element);
        if (currStatus && [goalStatus.REJECT, goalStatus.DELETED].indexOf(currStatus['status']) > -1) {
          selectedClass = `${selectedClass} rejected`;
        }
        if (currStatus && currStatus['status'] === goalStatus.RFP_REQUEST) {
          selectedClass = 'active selected';
        }
        if (currStatus && currStatus['status'] === goalStatus.ACCEPT) {
          selectedClass = 'active';
        }
        if (currStatus && currStatus['status'] === goalStatus.ACCEPT) {
          selectedClass = 'active';
        }
        if (goalDetails.status !== goalStatus.DELETED && element === goalStatus.DELETED) {
          return;
        }
        if (goalDetails.orgId && (element === goalStatus.ACCEPT || element === goalStatus.REJECT)) {
          if (!acceptedrejected) {
            html.push(<div key={index} className={`timeline-row ${selectedClass}`}>
              <small>
                {currStatus && currStatus['status'] ?
                  <React.Fragment>
                    {currStatus['status'] === goalStatus.ACCEPT ?
                      'Accepted'
                      : 'Rejected'
                    }
                  </React.Fragment>
                  : 'Accepted / Rejected'}
              </small>
              <p>{getRequestDate(element)}</p>
              <span className="icon check-icon" />
            </div>);
          }
          acceptedrejected = true;
        } else if (!goalDetails.orgId ||
          (goalDetails.orgId && (element !== goalStatus.ACCEPT && element !== goalStatus.REJECT))) {
          html.push(
            <div key={index} className={`timeline-row ${selectedClass}`}>
              <small>{goalStatusText}</small>
              <p>{getRequestDate(element)}</p>
              <span className="icon check-icon" />
            </div>,
          );
        }
      });
      return html;
    };

    return goalDetails && (
      <React.Fragment>
        <Header />
        <Sidebar {...this.props} />
        <div className="dashboard-wrapper">
          <Back {...this.props} />
          <div className="dashboard-content shadow">
            <div className="learning-goal-wrapper">
              <h2 className="heading heading-rg roboto-medium d-inline-block heading-border">
                {goalDetails.eventType}
              </h2>
              <div className="goal-view-wrapper">
                <h2 className="heading heading-lg roboto-medium">{goalDetails.title} <span>
                  {goalDetails.modeOfDevivery}</span>
                </h2>
                { goalDetails.topic && <em>({goalDetails.topic.name})</em>}
                <p>{goalDetails.description}</p>
                <div className="form-details">
                  <Row>
                    <Col md="12" lg="9">
                      <div className="form-data">
                        <Row>
                          <Col xs="12" md="4">
                            <span>Domain</span>
                            <p>{goalDetails.domain.name}</p>
                          </Col>
                          <Col xs="12" md="4">
                            <span>Sub-Domain</span>
                            <p>{goalDetails.subDomain.name}</p>
                          </Col>
                          { goalDetails.category && <Col xs="12" md="4">
                            <span>Category</span>
                            <p>{goalDetails.category.name}</p>
                          </Col>}
                          {goalDetails.subCategory && <Col xs="12" md="4">
                            <span>Sub-Category</span>
                            <p>{goalDetails.subCategory.name}</p>
                          </Col>}
                          </Row>
                          <Row>
                          <Col xs="12" md="4">
                            <span>Duration</span>
                            <p>{goalDetails.duration} {Number(goalDetails.duration) > MAGIC_NUMBER.ONE ?
                              `hrs` : `hr`}</p>
                          </Col>
                          <Col xs="12" md="4">
                            <span>Date Availability</span>
                            <p>{fromDate} - {toDate}</p>
                          </Col>
                          <Col xs="12" md="4">
                            <span>Time Availability</span>
                            <p>{fromTime} - {toTime}</p>
                          </Col>
                          <Col xs="12">
                            <span>Tags</span>
                            <div className="tags-wrapper">
                              {this.renderTagList()}
                            </div>
                          </Col>
                          {goalDetails && goalDetails.rejectedReason &&
                          <Col xs="12" md="4">
                            <span>Rejected Reason</span>
                            <p>{`${goalDetails.rejectedReason}`}</p>
                          </Col>
                          }
                          {isSystemAdminRole && goalDetails && !!goalDetails.orgId && goalDetails['goalAdmin'].length > MAGIC_NUMBER.ZERO &&
                            < Col xs="12" md="4">
                              <span>Team Name</span>
                              <p>{`${goalDetails.goalAdmin[0]['organizationName']}`}</p>
                            </Col>
                          }
                          {isSystemAdminRole && goalDetails && goalDetails['userId'] &&
                            < Col xs="12" md="4">
                              <span>Created By</span>
                              <p>{`${goalDetails.userId.firstName} ${goalDetails.userId.lastName}`}</p>
                            </Col>
                          }
                          {isSystemAdminRole && goalDetails && !!goalDetails.orgId && (goalDetails.status === 'rfpRequest' || goalDetails.status === 'accepted') && goalDetails['goalAdmin'] &&
                            < Col xs="12" md="4">
                              <span>Accepted By</span>
                              <p>{`${goalDetails.goalAdmin[0]['firstName']} ${goalDetails.goalAdmin[0]['lastName']}`}</p>
                            </Col>
                          }
                          {isSystemAdminRole && goalDetails && !!goalDetails.orgId && goalDetails.status === 'rejected' && goalDetails['goalAdmin'] &&
                            < Col xs="12" md="4">
                              <span>Rejected By</span>
                              <p>{`${goalDetails.goalAdmin[0]['firstName']} ${goalDetails.goalAdmin[0]['lastName']}`}</p>
                            </Col>
                          }
                        </Row>
                      </div>
                    </Col>
                    <Col md="12" lg="3">
                      {renderTimeLine()}
                    </Col>
                  </Row>
                  <div className="participant-wrapper">
                    <p>Participants</p>
                    <div className="d-flex flex-wrap">
                      {this.renderParticipantsList()}
                    </div>
                  </div>
                  <div className="add-admin-footer justify-content-end">
                    {role === staticConstants.ROLE.ORGANIZATION_ADMIN &&
                      <span className="add-admin-btn" onClick={this.openModalToAddAdmin}>Select Event Lead</span>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div
              className={'card-footer d-flex mt-0 ' + ((role === staticConstants.ROLE.ORGANIZATION_LEARNER && goalDetails.status !== goalStatus.CREATED) ||
                (role === staticConstants.ROLE.ORGANIZATION_ADMIN && goalDetails.status === goalStatus.RFP_REQUEST) || isSystemAdminRole ? 'd-none' : '')}
            >
              {role === staticConstants.ROLE.ORGANIZATION_ADMIN && // for orgadmin only if status is rejected
                goalDetails.status === goalStatus.REJECT &&
                <Button className="btn btn-icon delete mr-auto"
                  onClick={() => this.showDeletePopup()}>
                  <span className="icon icon-delete" /> Delete</Button>
              }

              {role === staticConstants.ROLE.ORGANIZATION_LEARNER && // for learner only if status is created
                goalDetails.status === goalStatus.CREATED && goalDetails.orgId &&
                <>
                  <Button className="btn btn-icon delete mr-auto"
                    onClick={() => this.showDeletePopup()}>
                    <span className="icon icon-delete" /> Delete</Button>
                  <Button className="btn btn-icon mr-2" onClick={() =>
                    history.push(`${pageRoutes.GOALMANAGEMENT.PATH}${pageRoutes.GOALMANAGEMENT.EDIT}/${params.id}`)}>
                    <span className="icon icon-edit" /> Edit</Button>
                  {goalDetails.participants && goalDetails.participants.length > MAGIC_NUMBER.ZERO ?
                    <Button className="btn btn-icon ml-2" onClick={() => this.showSendApprovalPopup()}><span className="icon icon-send"
                    /> Send for Approval</Button> : ''}
                </>

              }
              {// for orgadmin goals
                ((role === staticConstants.ROLE.ORGANIZATION_ADMIN &&
                  goalDetails.status === goalStatus.CREATED && !goalDetails.orgId)
                  || (role === staticConstants.ROLE.PROFESSIONAL && goalDetails.status === goalStatus.CREATED)) &&
                <React.Fragment>
                  <Button className="btn btn-icon delete mr-auto"
                    onClick={() => this.showDeletePopup()}>
                    <span className="icon icon-delete" /> Delete</Button>
                  <Button className="btn btn-icon" onClick={() =>
                    history.push(`${pageRoutes.GOALMANAGEMENT.PATH}${pageRoutes.GOALMANAGEMENT.EDIT}/${params.id}`)}>
                    <span className="icon icon-edit" /> Edit</Button>
                  {goalDetails.participants && goalDetails.participants.length > MAGIC_NUMBER.ZERO ?
                    <Button className="btn btn-icon ml-2" onClick={() => this.showSendRFPPopup()}>
                      <span className="icon icon-send" /> Send Request</Button> : ''}
                </React.Fragment>
              }

              {// for learner goals whenstatus is newRequest
                role === staticConstants.ROLE.ORGANIZATION_ADMIN && goalDetails.orgId &&
                goalDetails.status === goalStatus.REQUEST_SEND_BY_LERNER &&
                <>
                  <Button className="btn btn-icon delete mr-auto"
                    onClick={() => this.showRejectReasonModal({ goalId: String(params.id) })}>
                    <span className="icon icon-cross" /> Reject</Button>

                  <Button className="btn btn-icon" onClick={() =>
                    history.push(`${pageRoutes.GOALMANAGEMENT.PATH}${pageRoutes.GOALMANAGEMENT.EDIT}/${params.id}`)}>
                    <span className="icon icon-edit" /> Edit</Button>

                  <Button className="btn btn-icon accept ml-2" onClick={() =>
                    this.updateGoalStatus(String(params.id), goalStatus.ACCEPT)}>
                    <span className="icon icon-accept" /> Accept</Button>
                </>
              }

              {role === staticConstants.ROLE.ORGANIZATION_ADMIN && goalDetails.orgId &&
                goalDetails.status === goalStatus.ACCEPT &&
                <Button className="btn btn-icon ml-auto" onClick={() => this.showSendRFPPopup()}><span className="icon icon-send"
                /> Send Request</Button>
              }

            </div>
          </div>
        </div>
        {
          displayDeletePopup ?
            <ConfirmatioPopup isOpenModal={displayDeletePopup} modalPopupObj={modalPopupObj}
              hideModal={() => this.setState({ displayDeletePopup: false })} onClickYes={this.deleteGoal} />
            : ''
        }

        {
          displayRFPPopup ?
            <ConfirmatioPopup isOpenModal={displayRFPPopup} modalPopupObj={modalPopupObj}
              hideModal={() => this.setState({ displayRFPPopup: false })} onClickYes={this.sendRFP} />
            : ''
        }
        {
          displayApprovalPopup ?
            <ConfirmatioPopup isOpenModal={displayApprovalPopup} modalPopupObj={modalPopupObj}
              hideModal={() => this.setState({ displayApprovalPopup: false })} onClickYes={this.sendForApproval} />
            : ''
        }
        {
          displayDeletePopup ?
            <ConfirmatioPopup isOpenModal={displayDeletePopup} modalPopupObj={modalPopupObj}
              hideModal={() => this.setState({ displayDeletePopup: false })} onClickYes={this.deleteGoal} />
            : ''
        }
        {
          displayRejectReasonModal ?
            <RejectReasonPopup isOpenModal={displayRejectReasonModal}
              modalPopupObj={modalPopupObj}
              hideModal={this.hideRejectReasonModal}
              onClickYes={this.handleRejectReasonSubmit} />
            : ''
        }

        {
         displaySendErrorPopup ?
          <ReqSendErrorPopup
            isOpenModal={true}
            hideModal={() => this.setState({ displaySendErrorPopup: false })}
          /> : ''
        }

        <GoalAdminPopup
          isOpenModal={openModalToAddAdmin}
          goalDetail={goalDetails}
          hideModal={this.hideAddAdminModal}
          onClickYes={this.handleAddAdminSubmit} />
      </React.Fragment >
    );
  }
}

function mapStateToProps(state: { authentication: { user: any  }; goals: any }) {
  const { user } = state.authentication;
  return {
    goals: state.goals,
    user,
  };
}

const mapGoalDetailContainer = connect(mapStateToProps)(GoalDetailContainer);
export { mapGoalDetailContainer as GoalDetailContainer };
