export const actionTypes = {
  DASHBOARD_REQUEST: 'DASHBOARD_REQUEST',
  DASHBOARD_SUCCESS: 'DASHBOARD_SUCCESS',
  DASHBOARD_FAILURE: 'DASHBOARD_FAILURE',
  DASHBOARD_SUCCESS_TYPE: 'DASHBOARD_SUCCESS_TYPE',
  DASHBOARD_UPCOMING_EVENTS_REQUEST: 'DASHBOARD_UPCOMING_EVENTS_REQUEST',
  DASHBOARD_UPCOMING_EVENTS_SUCCESS: 'DASHBOARD_UPCOMING_EVENTS_SUCCESS',
  DASHBOARD_UPCOMING_EVENTS_FAILURE: 'DASHBOARD_UPCOMING_EVENTS_FAILURE',
  DASHBOARD_REVIEWS_REQUEST: 'DASHBOARD_REVIEWS_REQUEST',
  DASHBOARD_REVIEWS_SUCCESS: 'DASHBOARD_REVIEWS_SUCCESS',
  DASHBOARD_REVIEWS_FAILURE: 'DASHBOARD_REVIEWS_FAILURE',
};
