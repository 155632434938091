import { ILastMessage } from '../messageBoard';

export class LastMessage implements ILastMessage {
  public content = '';
  public from = '';
  public isBroadcast = false;
  public isRead = false;
  public name = '';
  public roomId = '';
  public target = '';
  public targetName = '';
  public timeStamp = 0;
  public to = '';
  public receiptType = '';
  public date = '';
  public time = '';
  public timeAgo = '';
}
