import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardSubtitle,
  CardText,
  CardTitle,
  Input,
  Jumbotron,
} from 'reactstrap';
import { LocationDescriptorObject } from 'history';
import { history } from '../../../helpers';
import cancelCard from './../../../assets/images/icons/card-cancel.svg';
import cardImage from './../../../assets/images/icons/card.svg';
import verifyCard from './../../../assets/images/icons/verify-card.svg';
import { profileAction } from './../../shared/updateProfile/redux/actions';
import { subscriptionPlanAction } from './../../shared/subscriptionPlan/redux/actions';
import OpenFieldTypes from 'OpenFieldTypes';
import { Sidebar } from '../sidebar';
import { BreadcrumbRender } from '../breadcrumbRender/BreadcrumbRender';
import { IValidator, ILocalUser } from './../../../interfaces';
import {
  staticConstants,
  getLocalUserData,
  MAGIC_NUMBER,
  subscriptionProcessed,
  pageRoutes,
  checkAddOn,
} from './../../../utils';
import ReeValidate from 'ree-validate';
import { RequestMessagePopup } from '../modals/requestMessagePopUp';
import { paymentService } from '../../../services';
import { ConfirmatioPopup } from '../modals/confirmationPopup';
import { appFeedbackAction } from '../../systemAdmin/applicationFeedback/redux/actions';

interface IProps {
  dispatch: OpenFieldTypes.RootAction;
  location?: LocationDescriptorObject;
  user: ILocalUser;
  licence?: number;
  isAddOn?: boolean;
  subscriptionProduct: {
    addOnAmount: number;
    licenceAmount: number;
    learnerAmount: number;
  };
  subscriptionId?: any;
  profile?: any;
}

interface IState {
  licence: any;
  isAddOn: boolean;
  displayRequestPopup: boolean;
  errors?: any;
  requestBody?: string;
  isSubscriptionConfirmation: boolean;
  modalPopupObj: any;
  defaultLicence: any;
}

export const requestPopUp = {
  title: 'CONTACT SALES',
  yesBtnText: 'Send',
  noBtnText: 'Cancel',
  yesBtnClass: 'btn-primary',
  noBtnClass: '',
};

class SubscriptionPlan extends React.Component<IProps, IState> {
  public validator: IValidator;
  constructor(props: IProps) {
    super(props);
    this.validator = new ReeValidate({
      licence:
        this.props &&
        this.props['location'] &&
        this.props['location']['state'] &&
        this.props['location']['state']['action'] &&
        this.props['location']['state']['action'] === 'expert-purchase-addon'
          ? ''
          : 'required|min_value:0|max_value:14000',
    });
    this.state = {
      licence: 0,
      defaultLicence: 0,
      isAddOn: false,
      errors: this.validator.errors,
      displayRequestPopup: false,
      requestBody: '',
      isSubscriptionConfirmation: false,
      modalPopupObj: {},
    };
    this.moveToPaymentPage = this.moveToPaymentPage.bind(this);
  }

  public componentDidMount() {
    const localData: ILocalUser = getLocalUserData();
    this.props.dispatch(
      subscriptionPlanAction.getSubscriptionProduct(
        localData.subscribedProductId
          ? { productId: localData.subscribedProductId }
          : null
      )
    );
    this.props.dispatch(profileAction.getProfile());
    if (localData.role === staticConstants.ROLE.EXPERT) {
      this.validator = new ReeValidate({});
    }
  }

  public componentWillReceiveProps(props: IProps, nextProps: any) {
    const newState = {
      licence: props.licence,
      defaultLicence: props.licence - 1,
      isAddOn: props.isAddOn,
    };
    const {
      location: { state },
      user,
    } = this.props;
    if (
      state &&
      (state['action'] === 'purchase-addon' ||
        state['action'] === 'expert-purchase-addon')
    ) {
      newState['isAddOn'] = true;
    }
    if (user && user.role === staticConstants.ROLE.PROFESSIONAL) {
      newState['licence'] = 1;
    }
    if (user && user.role === staticConstants.ROLE.ORGANIZATION_ADMIN) {
      newState['licence'] =
        props.licence > MAGIC_NUMBER.ZERO
          ? props.licence - 1
          : MAGIC_NUMBER.ZERO;
    }
    this.setState(newState);
  }

  public handleChange = (event: any) => {
    const { name, value } = event.target;
    const { errors } = this.validator;
    this.setState({ [name]: value } as any);
    errors.remove(name);
    this.setState({ ...this.state, [name]: value });
    this.validator.validate(name, value).then(() => {
      this.setState({ errors });
    });
  };

  public handleChangeRequest = (event: any) => {
    this.setState({ requestBody: event.target.value });
  };

  public submitRequest = (requestBody) => {
    this.props.dispatch(appFeedbackAction.messageRequest(requestBody));
    this.setState({ displayRequestPopup: false });
  };

  public validateAndSubmit = (e: any) => {
    e.preventDefault();

    const { licence } = this.state;
    const { errors } = this.validator;

    this.validator.validateAll({ licence }).then((success: any) => {
      if (success) {
        this.moveToPaymentPage('1');
      } else {
        this.setState({ errors });
      }
    });
  };

  public moveToPaymentPage(hashValue = '') {
    const {
      location: { state, hash: _hash = 'null' },
      subscriptionProduct,
      user: { role },
    } = this.props;
    const hash = hashValue || _hash;
    const { licence, isAddOn } = this.state;
    let stateVars = { licence, isAddOn, subscriptionProduct };
    if (state && state['originStateVars']) {
      delete state['originStateVars']['isAddOn'];
    }
    if (state && state['redirectUri']) {
      stateVars['redirectUri'] = state['redirectUri'];
    }
    if (state && state['action']) {
      stateVars['action'] = state['action'];
      stateVars['activeTab'] =
        state['action'] === 'expert-purchase-addon' && state['activeTab'];
    }
    if (state && state['originStateVars']) {
      stateVars = { ...state['originStateVars'], ...stateVars };
    }
    if (role === staticConstants.ROLE.ORGANIZATION_ADMIN) {
      stateVars['licence'] = Number(stateVars['licence']) + 1;
    }
    if (role === staticConstants.ROLE.EXPERT) {
      stateVars['isAddOn'] = true;
    }
    history.push({
      pathname: `/payment/checkout/`,
      state: stateVars,
      hash,
    });
  }

  /**
   * @description
   * hideModal is called when someone click on the cancel button
   */
  public hideModal = () => {
    this.setState({
      displayRequestPopup: false,
    });
  };

  /**
   * @description
   * showModal is called when someone click on the Request button
   */
  public showModal = () => {
    this.setState({
      displayRequestPopup: true,
    });
  };

  public cancelAddOnPurchase = () => {
    const {
      location: { state },
    } = this.props;
    // TODO:: state var error while moving to profile(originUri) page use API call instead for goal title
    if (state && state['originUri']) {
      history.goBack();
    }
  };

  public handleSubscriptionClick = (e) => {
    const modalPopupObj = subscriptionProcessed;
    e.preventDefault();
    const {
      location: { state },
      profile: { dateOfBirth } = { dateOfBirth: null },
      user: { role, _id },
    } = this.props;
    const { defaultLicence, licence, isAddOn } = this.state;
    const isProfessional = role === staticConstants.ROLE.PROFESSIONAL;
    const isOrgAdmin = role === staticConstants.ROLE.ORGANIZATION_ADMIN;
    const userRole = isProfessional
      ? pageRoutes.PROFESSIONAL
      : pageRoutes.ORGADMIN;
    /** show old vs new plan page if licenses are not equal to defaultLicence */
    if (Number(licence) !== defaultLicence && !isAddOn && isOrgAdmin) {
      this.moveToPaymentPage('3');
    } else {
      isOrgAdmin &&
        paymentService.updateLicence(_id, Number(licence));
      if ((isProfessional || isOrgAdmin) && !isAddOn) {
        if (dateOfBirth) {
          history.push(`${userRole.PATH}${userRole.DASHBOARD}`);
        } else {
          history.push(
            `${userRole.PATH}${pageRoutes.ORGADMIN.EDIT_PROFILE.PATH}`
          );
        }
      } else {
        if (!state && isAddOn) {
          this.setState({ isSubscriptionConfirmation: true, modalPopupObj });
        } else {
          this.validateAndSubmit(e);
        }
      }
    }
  };

  public render() {
    const {
      licence,
      displayRequestPopup,
      requestBody,
      modalPopupObj,
      isSubscriptionConfirmation,
      isAddOn: isAddOnFromState,
    } = this.state;
    const {
      location: { state },
      user: { role },
      subscriptionProduct,
    } = this.props;
    const getPaymentBtnLabel = () => {
      if (!state) {
        return 'Update Plan';
      }
      switch (state['action']) {
        case 'purchase-addon':
          return 'Purchase';
        case 'update-plan':
          return 'Update Plan';
        default:
          return 'Subscribe';
      }
    };

    return (
      <div
        className={
          state && state['showSidebarNav']
            ? 'dashboard-wrapper pt-0 addon-purchase'
            : 'w-100'
        }
      >
        <Sidebar {...this.props} />
        <div className="subscriptionPlan-wrapper">
          {state && state['breadcrumb'] && (
            <div className="mb-4">
              <BreadcrumbRender breadcrumb={state['breadcrumb']} />
            </div>
          )}
          {state &&
          (state['action'] === 'expert-purchase-addon' ||
            role === staticConstants.ROLE.EXPERT) ? (
            <div className="plan-wrapper dashboard-content mb-0">
              <div className="crad-wrapper pb-0">
                <div className="price-card d-flex flex-wrap">
                  <Card>
                    <CardBody>
                      <div className="card-data">
                        <CardTitle>Monthly</CardTitle>
                        <CardSubtitle>
                          <em>$</em>
                          {subscriptionProduct &&
                            subscriptionProduct.addOnAmount}
                        </CardSubtitle>
                        <CardText>Add Ons</CardText>
                      </div>
                    </CardBody>
                  </Card>
                  <div className="price-text">
                    <Jumbotron>
                      <p>
                        Overlap limits access to user profile information to
                        reduce selection bias. This add-on allows full
                        visibility of user profiles.
                      </p>
                    </Jumbotron>
                  </div>
                </div>
              </div>
              <div className="card-footer d-flex">
                {state && (
                  <Button
                    className="btn btn-icon danger"
                    onClick={this.cancelAddOnPurchase}
                  >
                    <span className="icon icon-cross" />
                    Cancel
                  </Button>
                )}
                <Button
                  color="primary"
                  className="btn btn-rg ml-auto"
                  onClick={this.handleSubscriptionClick}
                >
                  {getPaymentBtnLabel()}
                </Button>
              </div>
            </div>
          ) : (
            <div className="plan-wrapper">
              <div className="crad-wrapper pb-0">
                <div className="price-card d-flex flex-wrap">
                  <div className="price-card-box">
                    {/* { role === staticConstants.ROLE.ORGANIZATION_ADMIN &&
                  <Card>
                      <CardBody>
                        <div className="card-data">
                          <CardTitle>Monthly</CardTitle>
                          <CardSubtitle><em>$</em>{subscriptionProduct && subscriptionProduct.licenceAmount}</CardSubtitle>
                          { role === staticConstants.ROLE.ORGANIZATION_ADMIN &&
                          <>
                          <CardText>Per Licence</CardText>
                          <div className="licence-count d-flex align-items-center justify-content-between">
                            <span>Team Admin</span>
                            <Label for="value" className="heading heading-sm roboto-bold">1</Label>
                          </div>
                          </>
                          }
                        </div>
                      </CardBody>
                        </Card> } */}
                    <Card>
                      <CardBody>
                        <div className="card-data">
                          <CardTitle>Monthly</CardTitle>
                          <CardSubtitle>
                            <em>$</em> 0
                          </CardSubtitle>
                          {role === staticConstants.ROLE.ORGANIZATION_ADMIN && (
                            <>
                              <CardText>Per Licence</CardText>
                              <>
                                <div
                                  className="licence-count d-flex align-items-center justify-content-between"
                                  style={{ flexDirection: 'column' }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                      width: '100%',
                                      paddingBottom: '4px',
                                    }}
                                  >
                                    <span>Team Admin</span>
                                    <Input
                                      name="licence"
                                      className="focus-none"
                                      type="number"
                                      value={1}
                                      disabled
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                      width: '100%',
                                      paddingTop: '4px',
                                    }}
                                  >
                                    <span>Team Member</span>
                                    <Input
                                      name="licence"
                                      onChange={this.handleChange}
                                      className="focus-none"
                                      type="number"
                                      value={licence}
                                    />
                                  </div>
                                  {this.state.errors.has('licence') && (
                                    <div className="error-text">
                                      {this.state.errors.first('licence')}
                                    </div>
                                  )}
                                </div>
                              </>
                            </>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  <div className="price-text">
                    <Jumbotron>
                      {(role === staticConstants.ROLE.ORGANIZATION_ADMIN ||
                        role === staticConstants.ROLE.PROFESSIONAL) && (
                        <p>{staticConstants.ORG_ADMIN_SUB_TEXT}</p>
                      )}
                      <ul>
                        <li>Access our network</li>
                        <li>Book calls with experts</li>
                        <li>Track your activity</li>
                      </ul>
                      <p>
                        Note Users are required to pay for each call they book
                        on Overlap. Session fees are calculated by (hourly rate)
                        x (duration) x (number of participants).
                      </p>
                    </Jumbotron>
                  </div>
                </div>
                <div className="addon-wrapper">
                  <Jumbotron>
                    <label className="control control--checkbox">
                      <Input
                        type="checkbox"
                        checked={this.state.isAddOn}
                        name="isAddOn"
                        disabled={this.props.isAddOn}
                        onChange={(e) =>
                          this.setState({ isAddOn: e.target.checked })
                        }
                      />{' '}
                      <div className="control__indicator" />
                    </label>
                    <div className="addon-price">
                      <em>$</em>
                      <span>
                        {subscriptionProduct && subscriptionProduct.addOnAmount}
                      </span>
                      Add On
                    </div>
                    <p>
                      {' '}
                      Overlap limits access to user profile information to
                      reduce selection bias. This add-on allows full visibility
                      of user profiles.
                    </p>
                  </Jumbotron>
                </div>
              </div>
              <div className="card-footer d-flex">
                {state &&
                  (state['action'] === 'purchase-addon' ||
                    state['action'] === 'update-plan') && (
                    <Button
                      className="btn btn-icon danger"
                      onClick={this.cancelAddOnPurchase}
                    >
                      <span className="icon icon-cross" />
                      Cancel
                    </Button>
                  )}
                <Button
                  color="primary"
                  className="btn btn-rg ml-auto"
                  onClick={this.handleSubscriptionClick}
                  // disabled={isAddOn}
                >
                  {getPaymentBtnLabel()}
                </Button>
              </div>
            </div>
          )}
          {/* <div className="addFeatureRequest-card">
            <h4>Enterprise</h4>
            <h4 className="addFeatureRequest-card-head">Custom</h4>
            <p>Built for businesses with sophisticated operations and advanced compliance requirements.</p>
            <h4 className="addFeatureRequest-card-head">Comprehensive. Secure. Flexible. Unlimited</h4>
            <p>Manage complex sustainability programs for teams with advanced workflows.
              No limits on the number users. White glove support from set up to implementation and beyond.
            </p>
            <a onClick={() => this.showModal()} className="btn btn-primary">
              Request
            </a>
          </div> */}
          {(!state || state['action'] !== 'purchase-addon') && (
            <div className="payment-option-wrapper">
              <h2>Easy and Secure Payment</h2>
              <p>100% Satisfaction guarantee policy</p>
              <Card>
                <CardImg top src={cardImage} alt="Card image" />
                <CardBody>
                  <CardTitle>Trusted payment methods</CardTitle>
                  <CardText>Pay with credit card</CardText>
                </CardBody>
              </Card>
              <Card>
                <CardImg top src={verifyCard} alt="Card image" />
                <CardBody>
                  <CardTitle>Secure payment</CardTitle>
                  <CardText>
                    Your details will remain 100% private and secure.
                  </CardText>
                </CardBody>
              </Card>
              <Card>
                <CardImg top src={cancelCard} alt="Card image" />
                <CardBody>
                  <CardTitle>Cancel any time</CardTitle>
                  <CardText>
                    Cancelling your subscription is quick and simple.
                  </CardText>
                </CardBody>
              </Card>
            </div>
          )}
        </div>
        {displayRequestPopup && (
          <RequestMessagePopup
            isOpenModal={displayRequestPopup}
            hideModal={this.hideModal}
            modalPopupObj={requestPopUp}
            handleChange={this.handleChangeRequest}
            requestBody={requestBody}
            onClickYes={() => this.submitRequest(requestBody)}
          />
        )}
        {isSubscriptionConfirmation && (
          <ConfirmatioPopup
            hideModal={() =>
              this.setState({
                isSubscriptionConfirmation: false,
                modalPopupObj: {},
              })
            }
            isOpenModal={isSubscriptionConfirmation}
            modalPopupObj={modalPopupObj}
            onClickYes={this.validateAndSubmit}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state: {
  updateProfile: any;
  subscriptionPlan: any;
  authentication: any;
}) {
  const { user } = state.authentication;
  const { updateProfile, subscriptionPlan } = state;
  const licence =
    updateProfile.profile && updateProfile.profile.licence
      ? updateProfile.profile.licence
      : 0;
  const isAddOn =
    updateProfile.profile && updateProfile.profile.isAddOn
      ? updateProfile.profile.isAddOn
      : false;
  return {
    licence,
    isAddOn,
    user,
    subscriptionProduct: subscriptionPlan['subscriptionProduct'],
    profile: updateProfile.profile,
  };
}

const connectedSubscriptionPlan = connect(mapStateToProps)(SubscriptionPlan);
export { connectedSubscriptionPlan as SubscriptionPlan };
