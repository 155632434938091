import { getLocalUserData } from '../utils/common';
import { pageRoutes } from '../utils/routeConstants';
import { staticConstants } from '../utils/staticConstants';

export function getNavToProfile() {
  const { role } = getLocalUserData();
  switch (role) {
    case staticConstants.ROLE.ORGANIZATION_LEARNER:
      return `${pageRoutes.LEARNER.PATH}${pageRoutes.LEARNER.VIEW_PROFILE.PATH}`;
    case staticConstants.ROLE.ORGANIZATION_ADMIN:
      return `${pageRoutes.ORGADMIN.PATH}${pageRoutes.LEARNER.VIEW_PROFILE.PATH}`;
    case staticConstants.ROLE.EXPERT:
      return `${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.VIEW_PROFILE.PATH}`;
    case staticConstants.ROLE.PROFESSIONAL:
      return `${pageRoutes.PROFESSIONAL.PATH}${pageRoutes.PROFESSIONAL.VIEW_PROFILE.PATH}`;
    case staticConstants.ROLE.SYSTEM_ADMIN:
      return `${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.SYSTEM_ADMIN.VIEW_PROFILE.PATH}`;
  }
  return '';
}

export function getNavToEditProfile() {
  const { role } = getLocalUserData();
  switch (role) {
    case staticConstants.ROLE.ORGANIZATION_LEARNER:
      return `${pageRoutes.LEARNER.PATH}${pageRoutes.LEARNER.EDIT_PROFILE.PATH}`;
    case staticConstants.ROLE.ORGANIZATION_ADMIN:
      return `${pageRoutes.ORGADMIN.PATH}${pageRoutes.ORGADMIN.PROFILE.PATH}`;
    case staticConstants.ROLE.EXPERT:
      return `${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.EDIT_PROFILE.PATH}`;
    case staticConstants.ROLE.PROFESSIONAL:
      return `${pageRoutes.PROFESSIONAL.PATH}${pageRoutes.PROFESSIONAL.EDIT_PROFILE.PATH}`;
  }
  return '';
}

export function getNavToDashboard() {
  const { role } = getLocalUserData();
  switch (role) {
    case staticConstants.ROLE.ORGANIZATION_LEARNER:
      return `${pageRoutes.LEARNER.PATH}${pageRoutes.LEARNER.DASHBOARD}`;
    case staticConstants.ROLE.ORGANIZATION_ADMIN:
      return `${pageRoutes.ORGADMIN.PATH}${pageRoutes.ORGADMIN.DASHBOARD}`;
    case staticConstants.ROLE.PROFESSIONAL:
      return `${pageRoutes.PROFESSIONAL.PATH}${pageRoutes.PROFESSIONAL.DASHBOARD}`;
    case staticConstants.ROLE.SYSTEM_ADMIN:
      return `${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.SYSTEM_ADMIN.DASHBOARD}`;
    case staticConstants.ROLE.EXPERT:
      return `${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.DASHBOARD}`;
  }
  return '';
}
