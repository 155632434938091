import React from "react";
import { SuccessMessageContainer } from "../successMessage/SuccessMessageContainer";

class RegisterSuccessMessageContainer extends React.Component {

  render() {
    let success = {};
    if (this.props.location.pathname === "/expertRegisterSuccess") {
      success = {
        title: "Sign-up Successful!",
        message: "Check your inbox for information on next steps.",
        action: "/login",
        actionLabel: "Back to Log in",
      };
    } else {
      success = {
        title: "Email Confirmation!",
        message: "Check your inbox and click on the link to confirm your account.",
        action: "/login",
        actionLabel: "Back to Log in",
      };
    }
    return (
      <SuccessMessageContainer success={success} ></SuccessMessageContainer>
    );
  }
}

export { RegisterSuccessMessageContainer };
