import _ from 'lodash';
import React, { Dispatch } from 'react';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import { v4 } from 'uuid';
import { Link } from 'react-router-dom';
import {
  goalStatusTypes, tableItemsCount,
  goalStatus, staticConstants,
} from '../../../../utils/staticConstants';
import { goalAction } from '../redux/actions';
import {
  getSelectedValue, getUpdatedOptions,
  sendRFPModalContent, MAGIC_NUMBER,
  pageRoutes, deleteGoalMsg
} from './../../../../utils';
import { tagAction } from './../../tags/redux/actions';
import { GoalItem } from './goalItem';
import OpenFieldTypes from 'OpenFieldTypes';
import { ConfirmatioPopup } from '../../modals/confirmationPopup';
import { RequestTypeTab } from './requestTypeTab';
import { RejectReasonPopup } from '../../modals/rejectReasonPopup';
import { FilterComponent } from '../../filter/filterComponent';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  domainOptions: any;
  user: any;
  role: any;
  goals: any;
  match?: {
    params: {
      [key: string]: any;
    };
  };
  from?: string;
}

class GoalRequestListing extends React.Component<IProps> {

  /**
   * @description getDerivedStateFromProps is called when there in change in property
   * @param fields {Object} props
   */
  public static getDerivedStateFromProps(props: any) {
    if (props.goals && props.goals.list) {
      return {
        goals: props.goals,
      };
    }
    if (props.goals && props.goals.successForCreateGoal) {
      return {
        fetchGoal: true,
      };
    }
    return null;
  }

  public state = {
    selectedOption: null,
    tooltipOpen: false,
    activePage: 1,
    items: tableItemsCount,
    title: '',
    eventType: null,
    domain: null,
    status: null,
    sort: '',
    sortBy: 0,
    goals: {
      count: 0,
      list: [],
      fetchedListing: false,
    },
    fetchGoal: false,
    displayDeletePopup: false,
    goalId: '',
    reqObj: {},
    modalPopupObj: {},
    displayApprovalPopup: false,
    displayRFPPopup: false,
    displayRejectReasonModal: false,
    activeTab: goalStatus.NEW_REQUEST,
    openFilter: false,
    rejectReasonModalObj: null,
  };

  /**
   * @description componentDidMount is called when component is loaded
   * call goalAction.getGoals to fetch goal listings
   * call goalAction.getGoals to fetch goal categories
   */
  public componentDidMount() {
    const { items, activePage, activeTab } = this.state;
    const reqObj = {
      page: Number(activePage),
      limit: items,
      goalStatus: activeTab,
    };
    if (this.props.match) {
      const { match: { params } } = this.props;
      const pId = params['id'].split('?');
      reqObj['userId'] = pId[0];
    }
    this.props.dispatch(tagAction.getDomains());
    this.props.dispatch(goalAction.getGoals(reqObj));
  }

  /**
   * @description componentDidUpdate is called when we need to fetch listing again.
   * call goalAction.getGoals to fetch goal listings
   */
  public componentDidUpdate() {
    if (this.state.fetchGoal) {
      const reqObj = {
        page: Number(this.state.activePage),
        limit: this.state.items,
        goalStatus: this.state.activeTab,
      };
      if (this.props.match) {
        const { match: { params } } = this.props;
        const pId = params['id'].split('?');
        reqObj['userId'] = pId[0];
      }
      this.props.dispatch(goalAction.getGoals(reqObj));
      this.setState({ fetchGoal: false });
    }
  }

  /**
   * @description componentWillUnmount is used when component destroyed
   * call tagAction.resetAllTags() to reset the tags
   */
  public componentWillUnmount() {
    this.props.dispatch(tagAction.resetAllTags());
  }

  /**
   * @description fetchGoalsData is used on initial load
   */
  public fetchGoalsData(pageno: number) {
    const { sort, sortBy } = this.state;
    let reqObj = {
      page: pageno,
      limit: this.state.items,
      eventType: getSelectedValue(this.state.eventType),
      domain: getSelectedValue(this.state.domain),
      status: getSelectedValue(this.state.status),
      title: this.state.title,
      goalStatus: this.state.activeTab,
    };
    if (this.props.match) {
      const { match: { params } } = this.props;
      const pId = params['id'].split('?');
      reqObj['userId'] = pId[0];
    }
    if (sort) {
      reqObj = { ...reqObj,  ...{ sort, sortBy } };
    }
    this.props.dispatch(goalAction.getGoals(reqObj));
  }

  /**
   * @description
   * handleChange is used to set the value on state from the input.
   * @param fields {Object} || {String} value
   * @param fields {String} key
   */
  public handleChange = (value: any, key: string) => {
    this.setState({ ...this.state, [key]: value },
                  () => { this.fetchGoalsData(1); });
  }

  /** filter toggle for responsive ui */
  public filterToggle = () => {
    this.setState({
      openFilter: true,
    });
  }
  public filterRemove = () => {
    this.setState({
      openFilter: false,
    });
  }
  /**
   * @description
   * handlePageChange is called someone click on the pagination.
   * @param fields {Number} pageno
   */
  public handlePageChange = (pageno: number) => {
    this.setState({
      activePage: pageno,
    },            () => this.fetchGoalsData(pageno));
  }

  /**
   * @description  sortData is used for sorting purpose.
   * @param fields {Number} sort
   * @param fields {String} sortBy
   */
  public sortData(sort: any, sortBy: any) {
    const { activePage } = this.state;
    this.setState({ ...this.state, sort, sortBy },
                  () => this.fetchGoalsData(activePage));
  }

  /**
   * @description
   * showDeletePopup is used show delete popup.
   * @param fields {String} id
   */
  public showDeletePopup = (id: string) => {
    const { sort, sortBy } = this.state;
    const modalPopupObj = deleteGoalMsg;
    let reqObj = {
      page: Number(this.state.activePage),
      limit: this.state.items,
      eventType: getSelectedValue(this.state.eventType),
      domain: getSelectedValue(this.state.domain),
      status: getSelectedValue(this.state.status),
      goalStatus: this.state.activeTab,
      title: this.state.title,
    };
    if (this.props.match) {
      const { match: { params } } = this.props;
      const pId = params['id'].split('?');
      reqObj['userId'] = pId[0];
    }
    if (sort) {
      reqObj = { ...reqObj,  ...{ sort, sortBy } };
    }
    this.setState({
      displayDeletePopup: true,
      goalId: id,
      reqObj,
      modalPopupObj,
    });
  }

  /**
   * @description
   * showSendRFPPopup is used show send rfp popup.
   * @param fields {String} id
   */
  public showSendRFPPopup = (id: string) => {
    const { sort, sortBy } = this.state;
    const modalPopupObj = sendRFPModalContent;
    let reqObj = {
      page: Number(this.state.activePage),
      limit: this.state.items,
      eventType: getSelectedValue(this.state.eventType),
      domain: getSelectedValue(this.state.domain),
      status: getSelectedValue(this.state.status),
      title: this.state.title,
    };
    if (this.props.match) {
      const { match: { params } } = this.props;
      const pId = params['id'].split('?');
      reqObj['userId'] = pId[0];
    }
    if (sort) {
      reqObj = { ...reqObj, ...{ sort, sortBy } };
    }
    this.setState({
      displayRFPPopup: true,
      goalId: id,
      reqObj,
      modalPopupObj,
    });
  }

  /**
   * @description
   * sendRFP is called when someone click on the ok button
   */
  public sendRFP = () => {
    const { goalId } = this.state;
    this.props.dispatch(goalAction.updateGoalStatus({
      goalId,
      isFromDetailPage: false,
      status: goalStatus.RFP_REQUEST,
    }));
    this.setState({
      displayRFPPopup: false,
      modalPopupObj: {},
      goalId: '',
    });
  }

  /**
   * @description
   * updateGoalStatus is called when to update the status
   */
  public updateGoalStatus = (id: string, status: string) => {
    if (status === goalStatus.REJECT) {
      this.showRejectReasonModal({ goalId: id });
    } else {
      this.props.dispatch(goalAction.updateGoalStatus({
        goalId: id,
        isFromDetailPage: false,
        status,
      }));
    }
  }

  public handleRejectReasonSubmit = (obj: { goalId: string; message: string }) => {
    this.props.dispatch(goalAction.updateGoalStatus({
      goalId: obj.goalId,
      isFromDetailPage: false,
      message: obj.message,
      status: goalStatus.REJECT,
    }));
    this.hideRejectReasonModal();
  }

  public showRejectReasonModal = (obj: { goalId: string }) => {
    this.setState({
      displayRejectReasonModal: true,
      rejectReasonModalObj: obj,
    });
  }
  public hideRejectReasonModal = () => {
    this.setState({
      displayRejectReasonModal: false,
      rejectReasonModalObj: null,
    });
  }
  /**
   * @description
   * showDeletePopup is used show delete popup.
   * @param fields {String} id
   */
  public deleteGoal = () => {
    const { goalId, reqObj } = this.state;
    this.props.dispatch(goalAction.deleteGoal({ goalId }, reqObj));
    this.setState({
      displayDeletePopup: false,
      modalPopupObj: {},
      goalId: '',
    });
  }

  /**
   * @description
   * changeRequestType is called when some click on the child tab.
   */
  public changeRequestType = (activeTab: any) => {
    this.setState({
      activeTab,
    },            () => {
      this.fetchGoalsData(1);
    });
  }

  /**
   * @description
   * renderGoalListing is called to render the goal listings
   */
  public renderGoalListing() {
    const { list, fetchedListing } = this.state.goals;
    if (fetchedListing) {
      return _.map(list, (item) => {
        return (
          <GoalItem key={v4()} goal={item} isFromRequestListPage={true} activeTab={this.state.activeTab}
            deleteGoal={this.showDeletePopup} sendRFP={this.showSendRFPPopup}
            updateGoalStatus={this.updateGoalStatus} from={this.props.from} />
        );
      });
    }
    return '';
  }

  /**
   * @description
   * render is used to render html.
   */
  public render() {
    const { activePage, items, goals, sort, sortBy, activeTab, displayDeletePopup,
      modalPopupObj, displayRFPPopup, displayRejectReasonModal, rejectReasonModalObj } = this.state;
    const role = this.props.role;
    let minDisplay;
    let maxDisplay;
    if (goals.count === MAGIC_NUMBER.ZERO) {
      maxDisplay = MAGIC_NUMBER.ZERO;
      minDisplay = MAGIC_NUMBER.ZERO;
    } else if (goals.count <= items) {
      maxDisplay = goals.count;
      minDisplay = MAGIC_NUMBER.ONE;
    } else {
      maxDisplay = (Number(activePage) * Number(items)) < goals.count
        ? (Number(activePage) * Number(items)) : goals.count;
      minDisplay = ((Number(activePage) - 1) * Number(items)) + 1;
    }

    const classVarArr = ['table-head'];
    if (activeTab === goalStatus.ACCEPT && role === staticConstants.ROLE.ORGANIZATION_ADMIN) {
      classVarArr.push('org-table-head-accept-org');
    }
    if (activeTab === goalStatus.REJECT && role === staticConstants.ROLE.ORGANIZATION_ADMIN) {
      classVarArr.push('org-table-head-reject-org');
    }
    if (this.props.from === 'learnerManagement') {
      switch (activeTab) {
        case goalStatus.REJECT:
          classVarArr.push('table-head-accept');
          break;
        case goalStatus.NEW_REQUEST:
          classVarArr.push('table-head-reject');
          break;
        case goalStatus.ACCEPT:
          classVarArr.splice(1, 1);
          break;
      }
    }
    const classes = classVarArr.join(' ');
    const filterContent = ['search', 'eventType', 'domain'];

    return (
      <React.Fragment>
        <div className="filter-wrapper">
          <FilterComponent filterContent={filterContent} handleFilter={this.handleChange.bind(this)}
            status={goalStatusTypes} />
          {role === staticConstants.ROLE.ORGANIZATION_LEARNER ?
            <Link to={`${pageRoutes.GOALMANAGEMENT.PATH}${pageRoutes.GOALMANAGEMENT.CREATE_GOAL}`}
              className="btn btn-primary round-circle add-filter-btn">
              Add Goal
            </Link>
            : ''}
        </div>
        <RequestTypeTab requestType={activeTab} role={role} changeRequestType={this.changeRequestType}/>

        <div className="table-wrapper">
          <div className="flex-table">
            <div className={classes}>
              <div className="table-row">
                <div className="table-col table-col-17">
                  <span className="sorting-icon">Title
                      <strong>
                      <em className={(sort === 'title' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                        onClick={() => { this.sortData('title', MAGIC_NUMBER.ONE); }}/>
                      <em className={(sort === 'title' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                        onClick={() => { this.sortData('title', MAGIC_NUMBER.FOUND_INDEX); }}/>
                    </strong>
                  </span>
                </div>
                <div className="table-col table-col-10">
                  <span className="sorting-icon">Event Type
                      <strong>
                      <em className={(sort === 'eventType' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                        onClick={() => { this.sortData('eventType', MAGIC_NUMBER.ONE); }}/>
                      <em className={(sort === 'eventType' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                        onClick={() => { this.sortData('eventType', MAGIC_NUMBER.FOUND_INDEX); }}/>
                    </strong>
                  </span>
                </div>
                <div className="table-col table-col-15">
                  <span className="sorting-icon">Domain
                      <strong>
                      <em className={(sort === 'domain' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                        onClick={() => { this.sortData('domain', MAGIC_NUMBER.ONE); }}/>
                      <em className={(sort === 'domain' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                        onClick={() => { this.sortData('domain', MAGIC_NUMBER.FOUND_INDEX); }}/>
                    </strong>
                  </span>
                </div>
                <div className="table-col table-col-13">
                  <span className="sorting-icon">Sub-Domain
                      <strong>
                      <em className={(sort === 'subDomain' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                        onClick={() => { this.sortData('subDomain', MAGIC_NUMBER.ONE); }}/>
                      <em className={(sort === 'subDomain' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                        onClick={() => { this.sortData('subDomain', MAGIC_NUMBER.FOUND_INDEX); }}/>
                    </strong>
                  </span>
                </div>
                {activeTab === goalStatus.NEW_REQUEST &&
                  <div className="table-col table-col-12">
                    <span className="sorting-icon">Goal Created
                        <strong>
                        <em className={(sort === 'createdAt' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                          onClick={() => { this.sortData('createdAt', MAGIC_NUMBER.ONE); }}/>
                        <em className={(sort === 'createdAt' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                          onClick={() => { this.sortData('createdAt', MAGIC_NUMBER.FOUND_INDEX); }}/>
                      </strong>
                    </span>
                  </div>
                }
                {activeTab === goalStatus.ACCEPT &&
                  <div className="table-col table-col-12">
                    <span className="sorting-icon">Goal Accepted
                        <strong>
                        <em className={(sort === 'acceptedAt' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                          onClick={() => { this.sortData('acceptedAt', MAGIC_NUMBER.ONE); }}/>
                        <em className={(sort === 'acceptedAt' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                          onClick={() => { this.sortData('acceptedAt', MAGIC_NUMBER.FOUND_INDEX); }}/>
                      </strong>
                    </span>
                  </div>
                }
                {activeTab === goalStatus.REJECT &&
                  <div className="table-col table-col-12">
                    <span className="sorting-icon">Goal Rejected
                        <strong>
                        <em className={(sort === 'rejectedAt' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                          onClick={() => { this.sortData('rejectedAt', MAGIC_NUMBER.ONE); }}/>
                        <em className={(sort === 'rejectedAt' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                          onClick={() => { this.sortData('rejectedAt', MAGIC_NUMBER.FOUND_INDEX); }}/>
                      </strong>
                    </span>
                  </div>
                }
                {this.props.from !== 'learnerManagement' &&
                  <div className="table-col table-col-10">
                    <span className="sorting-icon">Created By
                      <strong>
                        <em className={(sort === 'createdBy' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                          onClick={() => { this.sortData('createdBy', MAGIC_NUMBER.ONE); }}/>
                        <em className={(sort === 'createdBy' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                          onClick={() => { this.sortData('createdBy', MAGIC_NUMBER.FOUND_INDEX); }}/>
                      </strong>
                    </span>
                  </div>
                }
                <div className="table-col table-col-13">
                  <span className="sorting-icon justify-content-center">Participants
                      <strong>
                      <em className={(sort === 'totalParticipants' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                        onClick={() => { this.sortData('totalParticipants', MAGIC_NUMBER.ONE); }}/>
                      <em className={(sort === 'totalParticipants' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                        onClick={() => { this.sortData('totalParticipants', MAGIC_NUMBER.FOUND_INDEX); }}/>
                    </strong>
                  </span>
                </div>
                {activeTab === goalStatus.ACCEPT &&
                  <div className="table-col table-col-10">
                    <span className="sorting-icon">Status</span>
                  </div>
                }
                <div className="table-col table-col-10 text-center">
                  <span>Actions</span>
                </div>
              </div>
            </div>
            <div className="table-body">
              {this.renderGoalListing()}
              {goals && goals.fetchedListing && goals.count === MAGIC_NUMBER.ZERO ?
                <div className="table-row table-no-data">
                  No Results Found.
                    </div>
                : ''}
            </div>
            {(goals && goals.count > MAGIC_NUMBER.TEN) &&
              <div className="table-footer">
                  <React.Fragment>
                    <p>Showing {minDisplay} - {maxDisplay} of {goals.count}</p>
                    <div className="pagination-wrapper">
                      <Pagination
                        hideFirstLastPages
                        activePage={activePage}
                        itemsCountPerPage={items}
                        totalItemsCount={goals.count}
                        pageRangeDisplayed={MAGIC_NUMBER.FIVE}
                        onChange={this.handlePageChange}
                      />
                    </div>
                  </React.Fragment>
              </div>
            }
          </div>
        </div>
        {
          displayDeletePopup ?
            <ConfirmatioPopup isOpenModal={displayDeletePopup} modalPopupObj={modalPopupObj}
              hideModal={() => this.setState({ displayDeletePopup: false })} onClickYes={this.deleteGoal} />
            : ''
        }
        {displayRFPPopup ?
          <ConfirmatioPopup isOpenModal={displayRFPPopup} modalPopupObj={modalPopupObj}
            hideModal={() => this.setState({ displayRFPPopup: false })} onClickYes={this.sendRFP} />
          : ''}
        {displayRejectReasonModal ?
          <RejectReasonPopup isOpenModal={displayRejectReasonModal}
            modalPopupObj={rejectReasonModalObj}
            hideModal={this.hideRejectReasonModal}
            onClickYes={this.handleRejectReasonSubmit}/>
          : ''}
      </React.Fragment >
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication, tags: { domains } } = state;
  const { user } = authentication;
  return {
    user,
    role: user.role,
    goals: state.goals,
    domainOptions: getUpdatedOptions(domains, 'name'),
  };
}

const connectedGoalRequestListing = connect(mapStateToProps)(GoalRequestListing);
export { connectedGoalRequestListing as GoalRequestListing };
