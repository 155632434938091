import {
  CREATE_GOAL_MUTATION,
  UPDATE_GOAL_MUTATION,
  DELETE_GOAL_MUTATION,
  UPDATE_GOAL_STATUS_MUTATION,
  UPDATE_EXTEND_EVENT_STATUS_MUTATION,
  FLAG_USER,
  VALIDATE_ATTENDANCE,
  RATING_GOAL,
  UPDATE_GOAL_PARTICIPATION_MUTATION,
  CHANGE_GOAL_PARTICIPATION_MUTATION,
  CANCEL_EVENT_MUTATION,
  UPDATE_COMPLETE_REQUEST_MUTATION,
  RESSHEDULE_BID,
  RESOLVE_MEETING_CONFLICTS,
} from '../graphql-client/mutations/goalMutations';
import {
  GET_GOAL_DETAILS_QUERY,
  GET_GOAL_LIST_QUERY,
  GET_SESSION_FEE_QUERY,
  GET_SESSION_DOWNLOAD_URL,
  GET_SCHEDULED_GOAL_LIST_QUERY,
} from '../graphql-client/queries/goalsQuery';
import { client } from '../index';
import moment from 'moment-timezone';
import { handleResponse } from '../utils/common';

export const goalServices = {
  getGoals,
  createGoal,
  getGoalDetails,
  updateGoal,
  deleteGoal,
  updateGoalStatus,
  updateExtentEventStatus,
  getEventDetails,
  flagUser,
  validateAttendance,
  ratingGoal,
  updateGoalParticipants,
  getSessionFee,
  changeGoalParticipants,
  cancelEvent,
  downloadSession,
  getScheduledGoals,
  completeRequest,
  rescheduleRequest,
  resolveMeetingConflicts,
};

async function getGoals(goal) {
  const response = await client.query({
    query: GET_GOAL_LIST_QUERY,
    variables: goal,
  });
  const resp = await handleResponse(response);
  return resp.goals;
}

async function getScheduledGoals(goal) {
  const response = await client.query({
    query: GET_SCHEDULED_GOAL_LIST_QUERY,
    variables: goal,
  });
  const resp = await handleResponse(response);
  return resp.scheduleGoals;
}

async function createGoal(goal) {
  const response = await client.mutate({
    mutation: CREATE_GOAL_MUTATION,
    variables: goal,
  });
  const resp = await handleResponse(response);
  return resp.addGoal;
}

async function getGoalDetails(goal) {
  const response = await client.query({
    query: GET_GOAL_DETAILS_QUERY,
    variables: goal,
  });
  const resp = await handleResponse(response);
  return resp.goal;
}

async function getEventDetails(goal) {
  const response = await client.query({
    query: GET_SCHEDULED_GOAL_LIST_QUERY,
    variables: goal,
  });
  const resp = await handleResponse(response);
  return resp.scheduleGoals;
}

async function updateGoal(goal) {
  const response = await client.mutate({
    mutation: UPDATE_GOAL_MUTATION,
    variables: goal,
  });
  const resp = await handleResponse(response);
  return resp.editGoal;
}

async function deleteGoal(goal) {
  const response = await client.mutate({
    mutation: DELETE_GOAL_MUTATION,
    variables: goal,
  });
  const resp = await handleResponse(response);
  return resp.removeGoal;
}

async function updateGoalStatus(goal: any) {
  const response = await client.mutate({
    mutation: UPDATE_GOAL_STATUS_MUTATION,
    variables: goal,
  });
  const resp = await handleResponse(response);
  resp.updateGoalStatus['goalId'] = goal.goalId;
  return resp.updateGoalStatus;
}

async function updateExtentEventStatus(event: any) {
  const response = await client.mutate({
    mutation: UPDATE_EXTEND_EVENT_STATUS_MUTATION,
    variables: event,
  });
  const resp = await handleResponse(response);
  return resp.updateExtendEventStatus;
}

async function flagUser(goal: any) {
  const response = await client.mutate({
    mutation: FLAG_USER,
    variables: goal,
  });
  const resp = await handleResponse(response);
  return resp.flagUser;
}

async function validateAttendance(goal: any) {
  const response = await client.mutate({
    mutation: VALIDATE_ATTENDANCE,
    variables: goal,
  });
  const resp = await handleResponse(response);
  return resp.attendence;
}
async function ratingGoal(goal: any) {
  const response = await client.mutate({
    mutation: RATING_GOAL,
    variables: goal,
  });
  const resp = await handleResponse(response);
  return resp.rating;
}

async function updateGoalParticipants(goal: { goalId: string; participants: Array<{ isAdmin: boolean; userId: string }>; isMe: boolean }) {
  const response = await client.mutate({
    mutation: UPDATE_GOAL_PARTICIPATION_MUTATION,
    variables: goal,
  });
  const resp = await handleResponse(response);
  return resp.updateGoalParticipants;
}

async function getSessionFee(bidId: string, type: string = null, duration = 0) {
  const response = await client.query({
    query: GET_SESSION_FEE_QUERY,
    variables: { bidId, type, duration },
  });
  const resp = await handleResponse(response);
  resp.getSessionFee.bidId = bidId;
  return resp.getSessionFee;
}

async function changeGoalParticipants(goal: { goalId: string; participants: Array<{ isAdmin: boolean; userId: string }> }) {
  const response = await client.mutate({
    mutation: CHANGE_GOAL_PARTICIPATION_MUTATION,
    variables: goal,
  });
  const resp = await handleResponse(response);
  return resp.updateParticipants;
}

async function cancelEvent(goal: object) {
  const response = await client.mutate({
    mutation: CANCEL_EVENT_MUTATION,
    variables: goal,
  });
  const resp = await handleResponse(response);
  return resp.cancelEvent.message;
}

async function downloadSession(bidId: string) {
  const response = await client.query({
    query: GET_SESSION_DOWNLOAD_URL,
    variables: { bidId },
  });
  const resp = await handleResponse(response);
  return resp.downloadSession.data;
}

async function completeRequest(goalId: string, saveGoal: boolean) {
  const response = await client.mutate({
    mutation: UPDATE_COMPLETE_REQUEST_MUTATION,
    variables: { goalId, saveGoal },
  });
  return handleResponse(response);
}

async function rescheduleRequest(formData: any) {
  const response = await client.mutate({
    mutation: RESSHEDULE_BID,
    variables: {
      toDateRequested: formData['proposedDate'],
      fromTime: formData['fromTime'],
      toTimeRequested: formData['proposedTime'] ,
      bidId: formData['bidId'],
      timezone: moment.tz.guess()
    },
  });
  const resp = await handleResponse(response);
  return resp.rescheduleBid;
}

async function resolveMeetingConflicts(bidId: string) {
  const response = await client.mutate({
    mutation: RESOLVE_MEETING_CONFLICTS,
    variables: { bidId },
  });
  const resp = await handleResponse(response);
  return resp.resolveMeetingConflicts;

}
