import { actionTypes } from './types';
import { staticConstants } from '../../../../utils/staticConstants';

interface IAction {
  type: string;
  data: {};
  isPagination?: boolean;
  error: string;
  displayRSBPopup: boolean;
}
export function bid(state = {}, action: IAction) {
  switch (action.type) {
    case actionTypes.BID_LIST_SUCCESS:
      return {
        ...state,
        count: action.data['count'],
        list: !action.isPagination ? action.data['data'] : state['list'],
        fetchedBidListing: true,
      };
    case actionTypes.BID_LIST_REQUEST:
      return {
        ...state,
        fetchingBidListing: true,
      };
    case actionTypes.BID_LIST_FAILURE:
      return {
        BidListingFailed: true,
      };
    case actionTypes.MAKE_BID_REQUEST:
      return {
        ...state,
        makeBidRequest: true,
      };
    case actionTypes.MAKE_BID_SUCCESS:
      return {
        ...state,
        makeBidRequest: false,
        makeBidSuccess: true,
      };
    case actionTypes.MAKE_BID_SUCCESS_DETAIL:
      const bidDetail = state['bidDetails'];
      bidDetail['bid'] = [action.data['data']];
      return {
        ...state,
        bidDetail,
        makeBidRequest: false,
        makeBidSuccess: true,
      };
    case actionTypes.MAKE_BID_FAILURE:
      return {
        ...state,
        makeBidRequest: false,
        makeBidSuccess: false,
        error: action.error,
      };
    case actionTypes.ADD_FAVOURITE_SUCCESS:
      let newList: any;
      let count: number;
      if (action.data['activeTab'] === staticConstants.BID_MANAGEMENT_TAB.SAVED_REQUEST) {
        const findIndex = state['list'].findIndex((val) => val['_id'] === action.data['bidId']);
        state['list'].splice(findIndex, 1);
        newList = state['list'];
        count = state['count'] - 1;
      } else {
        newList = state['list'].map(el => {
          if (el['_id'] === action.data['bidId']) {
            el['isFavourite'] = action.data['isFavourite'];
          }
          return el;
        });
        count = state['count'];
      }
      return {
        ...state,
        list: newList,
        count,
      };
    case actionTypes.BID_DETAILS_SUCCESS:
      return {
        ...state,
        bidDetails: action.data['data'],
        successForBidDetails: true,
        displayRSBPopup: false
      };
    case actionTypes.GENERATE_REPORT_SUCCESS:
      return {
        ...state,
        downloadUrl: action.data['url'],
        downloadFileName: action.data['reportTitle'],
        successDownloadReport: true,
      };
    case actionTypes.RESET_GENERATE_REPORT:
      return {
        ...state,
        downloadUrl: undefined,
        successDownloadReport: false,
      };
    case actionTypes.ADD_FAVOURITE_SUCCESS_DETAIL:
      const bidDetails = state['bidDetails'];
      bidDetails['isFavourite'] = action.data['isFavourite'];
      return {
        ...state,
        bidDetails,
      };
    case actionTypes.RESET_BID_LIST:
      return {
        count: null,
        list: [],
        fetchedBidListing: false,
      };
    case actionTypes.REFER_BID_SUCCESS:
    case actionTypes.REFER_BID_REQUEST:
      return {
        ...state,
        isOpenReferModal: false,
      };
    case actionTypes.REFER_BID_FAILURE:
      return {
        ...state,
        isOpenReferModal: false,
        error: action.error,
      };
    case actionTypes.RESCHEDULE_FAILURE:
      return {
        ...state,
        displayRSBPopup: true,
        error: action.error,
      };
    default:
      return state;
  }

}
