import gql from 'graphql-tag';

const GET_BID_LIST_QUERY = gql`
  query rfp($page: Int, $limit: Int, $title: String, $eventType: String,
    $category: String, $domain: String, $sort: String, $sortBy: Int, $rfpType: String, $userId: String,
    $columnHeaders:[String], $columnKeys: [String], $isGenerateReport: Boolean, $isPagination: Boolean, $reportTitle: String,
    $isUserManagement: Boolean,
    ) {
      rfp (
      page: $page,
      limit: $limit,
      title: $title,
      eventType: $eventType,
      category: $category,
      domain: $domain,
      sort: $sort,
      sortBy: $sortBy,
      rfpType: $rfpType
      userId: $userId,
      columnHeaders: $columnHeaders,
      columnKeys: $columnKeys,
      isGenerateReport: $isGenerateReport,
      reportTitle: $reportTitle,
      isUserManagement: $isUserManagement,
      isPagination: $isPagination
    ) {
        message,
        count,
        url,
        reportTitle,
        data {
          _id,
          rehireId,
          title,
          modeOfDevivery,
          eventType,
          status,
          domain{
            name,
            id
          },
          subDomain{
            name,
            id
          },
          category{
            name,
            id
          },
          subCategory{
            name,
            id
          },
          topic{
            name,
            id
          },
          duration,
          fromDate,
          toDate,
          toTime,
          fromTime,
          description,
          participants{
            firstName
            lastName
            profileImage
            _id
            organizationId,
            role,
          },
          isMe,
          isFavourite,
          favourite{
            createdAt
          }
          createdAt,
          requestedAdmin {
            firstName
            lastName
            role
          },
          requestedBy {
            firstName
            lastName
            role
          },
          statusTimeline{
            status,
            createdAt,
          },
          isBid,
          isAcceptBidClosed,
          bid{
            toDateRequested,
            toTimeRequested,
            status,
            rescheduleRequestStatus,
            statusTimeline{
              status,
              createdAt,
            },
            rejectedAt,
            proposedDate,
            proposedTime,
            bidDetails,
            _id,
            rejectedAt
          }
        }
    }
}`;

const GET_BID_DETAILS_QUERY = gql`
  query rfpDetail($id: String!) {
    rfpDetail(rfpId: $id) {
        message,
        data {
          title,
          modeOfDevivery,
          eventType,
          domain{
            name,
            id
          },
          subDomain{
            name,
            id
          },
          category{
            name,
            id
          },
          subCategory{
            name,
            id
          },
          topic{
            name,
            id
          },
          duration,
          fromDate,
          toDate,
          toTime,
          fromTime,
          description,
          participantsRaw {
            userId,
            isAdmin,
            comment
            isFlagged
          },
          participants{
            firstName
            lastName
            profileImage
            _id
            organizationId
            occupation
            email,
            tags {
              _id,
              type
              name
            },
            role,
          },
          isMe,
          createdAt,
          statusTimeline{
            status,
            createdAt,
          },
          status,
          isFavourite,
          bid{
            fromTime,
            toDateRequested,
            toTimeRequested,
            rescheduleRequestStatus,
            statusTimeline{
              status,
              createdAt,
            },
            proposedDate,
            proposedTime,
            bidDetails,
            isShortlisted,
            status,
            _id
          },
          rehireId
        },
    }
}
`;

const GET_BID_QUERY = gql`
  query bidDetail($bidId: String!) {
    bidDetail(bidId: $bidId) {
        message,
        data {
            statusTimeline{
              status,
              createdAt,
            },
            proposedDate,
            proposedTime,
            bidDetails,
            status,
            _id
        },
    }
}
`;

const REFER_BID_QUERY = gql`
  query referExpert( $name: String!, $recepient: String!, $goalId: ID! ) {
    referExpert(name: $name, recepient: $recepient, goalId: $goalId) {
      isReferred
      message
    }
}
`;

export {
  GET_BID_LIST_QUERY, GET_BID_DETAILS_QUERY, GET_BID_QUERY, REFER_BID_QUERY,
};
