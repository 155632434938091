import React, { Dispatch } from 'react';
import { map } from 'lodash';
import {
  Button,
  TabContent,
  TabPane,
  Nav,
} from 'reactstrap';
import classnames from 'classnames';
import { history } from './../../../helpers/';
import { Sidebar } from '../../shared/sidebar';
import lockIcon from './../../../assets/images/icons/key.svg';
import { getNavToEditProfile } from '../../../utils/handleNavigation';
import { learnerProfileAction } from './redux/actions';
import OpenFieldTypes from 'OpenFieldTypes';
import { connect } from 'react-redux';
import { IViewProfile } from '../../../interfaces/index';
import { ViewLearnerProfile } from './ViewLearnerProfile';
import { LocationDescriptorObject } from 'history';
import { ProfileSettingPage } from '../../shared/viewProfile/profileSettingPage';
import { ChangePasswordForm } from '../../shared/updateProfile/ChangePassword';
import { Back } from '../../shared/back/back';
import { staticConstants, MAGIC_NUMBER } from '../../../utils/';
import { profileAction } from '../../shared/updateProfile/redux/actions';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  viewProfile: IViewProfile;
  location?: LocationDescriptorObject;
  history: any;
  user: any;
  match?: any;
}

interface IState {
  activeTab: string;
  arrowDown: boolean;
  clearPasswordData: boolean;
}

enum tabs {
  PROFILE = '1',
  PASSWORD = '2',
  SETTINGS = '3',
}

class ViewProfilePage extends React.Component<IProps, IState> {
  public myPassword: any;
  constructor(props: IProps) {
    super(props);

    this.toggle = this.toggle.bind(this);
    const hash = this.props.location.hash;
    let activeTab = String(tabs.PROFILE);
    if (hash) {
      const num = Number(hash.replace('#', ''));
      if (num && num > MAGIC_NUMBER.ZERO && num < MAGIC_NUMBER.FOUR) {
        activeTab = String(num);
      }
    }
    this.state = {
      activeTab,
      arrowDown: false,
      clearPasswordData: false,
    };
  }

  public componentDidMount = () => {
    const { dispatch, match, user } = this.props;
    if (match && match.params['id'] && user.role === staticConstants.ROLE.SYSTEM_ADMIN) {
      const pId = match.params['id'].split('?');
      dispatch(profileAction.getProfile(pId[0]));
    } else {
      dispatch(learnerProfileAction.getProfile());
    }
  }

  public toggle(tab: string) {
    if (this.state.activeTab !== tab) {
      this.props.history.replace(`#${tab}`);
      this.setState({
        activeTab: tab,
        clearPasswordData: true,
      });
    }
  }

  public arrowDown = () => {
    this.setState({ arrowDown: !this.state.arrowDown });
  }
  public navToEditProfile() {
    history.push({
      pathname: getNavToEditProfile(),
      state: {
        showSidebarNav: true,
        breadcrumb: [{ title: 'Edit Profile', link: null }],
      },
    });
  }
  /**
   * @description function to render tag list
   */
  public renderTagList = () => {
    const { viewProfile } = this.props;
    if (viewProfile && viewProfile.tags) {
      return map(viewProfile.tags, (tag: any) => {
        return tag.id && (
          <em className="tags-view-only">{tag.name}</em>
        );
      });
    }
    return '';
  }
  public render() {
    const { viewProfile, user } = this.props;
    return (
      <>
        <Sidebar />
        <div className="dashboard-wrapper">
          { user.role === staticConstants.ROLE.SYSTEM_ADMIN ?
            <Back {...this.props} />
            :
            <h2 className="heading heading-sm roboto-medium text-uppercase">User Information</h2>
          }
           <div className="dashboard-content">
           { user.role === staticConstants.ROLE.ORGANIZATION_LEARNER &&
            <div className="tab-btn-group">
              <Nav tabs className="border-0">
                <Button
                  color="secondary"
                  className={`btn w-33 h-50 tab-btn text-uppercase ${classnames({ 'btn-active': this.state.activeTab === '1' })}`}
                  onClick={() => { this.toggle('1'); }}
                >
                  Profile
                </Button>
                <Button
                  color="secondary"
                  className={`btn w-33 h-50 tab-btn text-uppercase ${classnames({ 'btn-active': this.state.activeTab === '2' })}`}
                  onClick={() => { this.toggle('2'); }}
                >
                  Password
                </Button>
                <Button
                  color="secondary"
                  className={`btn w-33 h-50 tab-btn text-uppercase ${classnames({ 'btn-active': this.state.activeTab === '4' })}`}
                  onClick={() => { this.toggle('4'); }}
                >Settings
                </Button>
              </Nav>
            </div>
           }
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <div className="dashboard-content shadow white-bg p-0 m-0">
                  <ViewLearnerProfile {...this.props} viewProfile={viewProfile} />
                  { user.role === staticConstants.ROLE.ORGANIZATION_LEARNER &&
                    <div className="card-footer d-flex align-items-center justify-content-end">
                      <Button onClick={this.navToEditProfile} className="btn btn-primary btn-rg">Edit</Button>
                    </div>
                  }
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="tab-panel-wrapper white-bg box-shadow">
                  <div className="profile-password-wrapper">
                    <h2 className="heading heading-rg roboto-medium">
                      Change Password
                            </h2>
                    <ChangePasswordForm {...this.props} toggle={this.toggle} clearData={this.state.clearPasswordData} />
                    <div className="lock-box">
                      <div className="key-circle">
                        <img src={lockIcon} alt="key" />
                        <p>It's a good idea to use a strong password that you don't use elsewhere.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="4">
                <ProfileSettingPage viewProfile={viewProfile} />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state: any) {
  const { updateProfile } = state;
  return {
    user: state.authentication.user,
    viewProfile: updateProfile['profile'],
  };

}
const connectedViewProfilePageDetail = connect(mapStateToProps)(ViewProfilePage);
export { connectedViewProfilePageDetail as ViewProfilePage };
