import { history } from '../../../../helpers';
import { pageRoutes } from '../../../../utils/routeConstants';
import { userServices } from './../../../../services';
import { alertActions } from './../../../alert/redux/actions';
import { actionTypes } from './types';
import OpenFieldTypes from 'OpenFieldTypes';

export const forgotPasswordAction = {
  forgotPassword,
};

function forgotPassword(email: string) {
  return (dispatch: OpenFieldTypes.RootAction) => {
      dispatch(request(email));

      userServices.forgotPassword(email)
            .then(
                (user) => {
                  dispatch(success(email));
                  history.push({pathname: `${pageRoutes.FORGOT_PASSWORD.SUCCESS}`,
                      state: {
                      email,
                    }});
                },
                (error) => {
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.errorAlert(error.toString()));
                },
            );
    };

  function request(data: string) { return { type: actionTypes.FORGOT_PASSWORD_REQUEST, data }; }
  function success(data: string) { return { type: actionTypes.FORGOT_PASSWORD_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.FORGOT_PASSWORD_FAILURE, error }; }
}
