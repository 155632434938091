import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { LoginContainer } from "./../app/auth";
import { RegisterContainer } from "./../app/shared/register";
import { UserTypeContainer } from "./../app/userType";
import { HomePage } from "./../app/home";
import { AboutUs } from "./../app/home/pages/about/index.ts";
import { Contact } from "./../app/home/pages/contact/index.ts";
import { Pricing } from "./../app/home/pages/pricing/index.ts";
import { HowItWork } from "./../app/home/pages/howItWork/index.ts";
import { Faq } from "./../app/home/pages/faqs/index.ts";
import { PrivacyPolicy } from "./../app/home/pages/privacyPolicy/index.ts";
import { TermsConditions} from "./../app/home/pages/ termsConditions/index.ts";
import { CreateGoalContainer } from "../app/shared/goalManagement";
import { getRedirectPathAfterLogin } from "./../utils/common";
import { NotFoundContainer } from "./../app/404";
import { RegisterContainer as ExpertRegisterContainer } from "./../app/expert/register";
import {
  RegisterSuccessMessageContainer,
  ForgotPasswordSuccessMessageContainer,
  CreatePasswordContainer,
  ForgotPasswordContainer,
  ResetPasswordContainer,
} from "./../app/shared";
import { staticConstants } from "../utils";
import LoadingOverlay from 'react-loading-overlay';
import { VerifyEmailContainer } from './../app/shared/updateProfile/VerifyEmailContainer';

const PublicTheme = React.lazy(() => import('./PublicTheme'));
const PrivateTheme = React.lazy(() => import('./PrivateTheme'));

const PublicRoutes = ({ match, loggedIn, ...props }) => {
  const { pathname } = props.location;
  if (pathname === `${match.path}about` || pathname === `${match.path}contact`
  || pathname === `${match.path}faq` || pathname === `${match.path}PrivacyPolicy` || pathname === `${match.path}termsofuse`
  || pathname === `${match.path}login` || pathname === `${match.path}forgotPassword` || pathname === `${match.path}createGoal`) {
    let userData = JSON.parse(localStorage.getItem("user"));
    if (pathname !== `${match.path}faq` && pathname !== `${match.path}PrivacyPolicy` && userData && (userData.role !== staticConstants.ROLE.ORGANIZATION_ADMIN
      || userData.isPaid) && (userData.role === staticConstants.ROLE.SYSTEM_ADMIN || userData.deviceCount < 4)) {
      const path = getRedirectPathAfterLogin(userData);
      return (
        <Redirect to={path} />
      );
    }
  }
  return (<div className="overlap-container" >
    <React.Suspense fallback={<LoadingOverlay
        active={true}
        spinner
        text=""
        className="white-loader"
      ></LoadingOverlay>}>
      { pathname === `${match.path}createGoal` ? <PrivateTheme /> : <PublicTheme />}
    </React.Suspense>
    <Switch>
      <Route
        exact
        path={`${match.path}`}
        component={HomePage}
      />
      <Route
        exact
        path={`${match.path}about`}
        component={AboutUs}
      />
      <Route
        exact
        path={`${match.path}contactus`}
        component={Contact}
      />
      <Route
        exact
        path={`${match.path}faq`}
        component={Faq}
      />
      <Route
        exact
        path={`${match.path}privacyPolicy`}
        component={PrivacyPolicy}
      />
      <Route
        exact
        path={`${match.path}termsofuse`}
        component={TermsConditions}
      />
      <Route
        exact
        path={`${match.path}forgotPassword`}
        component={ForgotPasswordContainer}
      />
      <Route
        exact
        path={`${match.path}resetPassword/:token`}
        component={ResetPasswordContainer}
      />
      <Route
        exact
        path={`${match.path}login/:code`}
        component={LoginContainer}
      />
      <Route
        path={`${match.path}forgotPasswordSuccess`}
        component={ForgotPasswordSuccessMessageContainer}
      />
      <Route
        exact
        path={`${match.path}register/orgadmin`}
        component={RegisterContainer}
      />
      <Route
        exact
        path={`${match.path}register/professional`}
        component={RegisterContainer}
      />
      <Route
        path={`${match.path}login`}
        exact component={LoginContainer}
      />
      <Route
        path={`${match.path}userType`}
        exact component={UserTypeContainer}
      />

      <Route
        path={`${match.path}registerSuccess`}
        exact component={RegisterSuccessMessageContainer}
      />
       <Route
        path={`${match.path}expertRegisterSuccess`}
        exact component={RegisterSuccessMessageContainer}
      />
      <Route
        path={`${match.path}createPassword/:token`}
        exact component={CreatePasswordContainer}
      />
      <Route
        path={`${match.path}register/expert`}
        exact component={ExpertRegisterContainer}
      />
      <Route
        path={`${match.path}pricing`}
        render={({ match, ...props }) => (
          <Pricing
            match={match}
            loggedIn={loggedIn}
            {...props}
          />
        )}
        exact 
      />
      <Route
        path={`${match.path}howItWork`}
        exact component={HowItWork}
      />
       <Route
        path={`${match.path}createGoal`}
        exact component={CreateGoalContainer}
      />
      <Route
        path={`${match.path}verifyEmail/:token`}
        exact component={VerifyEmailContainer}
      />

      <Route component={NotFoundContainer} />
    </Switch>
  </div>);
};

export { PublicRoutes };
