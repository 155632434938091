import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { injectStripe } from 'react-stripe-elements';
import { Button, Media, Modal, ModalBody } from 'reactstrap';
import ReeValidate from 'ree-validate';
import { history, validator } from '../../../helpers';
import {
  staticConstants,
  getNavToEditProfile,
  sessionFeePaymenTSuccess,
  EVENT_PAYMENT_TYPES,
  MAGIC_NUMBER,
  getNavToDashboard,
} from '../../../utils';
import { cardAction } from '../paymentCard/redux/actions';
import { alertActions } from './../../alert/redux/actions';
import { paymentAction } from './redux/actions';
import OpenFieldTypes from 'OpenFieldTypes';
import {
  IValidator,
  ILocalUser,
  IViewProfile,
  IInvoiceLog,
} from '../../../interfaces';
import { SuccessPopup } from './../modals/successPopup';
import CardDetailForm from './CardDetailForm';
import PurchaseDetail from './PurchaseDetail';
import SubscriptionUpgrade from './SubscriptionUpgrade';
validator(ReeValidate);

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  user?: ILocalUser;
  location?: any;
  stripe?: any;
  isPaymentSuccessModalOpen: boolean;
  cards: any;
  defaultSource: string;
  profile?: IViewProfile;
  subscriptionProrationDate?: string;
  proRatedAmount?: number;
  amountDue?: number;
  subscriptionProduct?: { addOnAmount: number; licenceAmount: number };
  taxRate?: number;
  upcomingPeriodInvoiceLogs: IInvoiceLog[];
  currentPeriodInvoiceLogs: IInvoiceLog[];
  invoiceTaxRate: number;
  invoiceTaxAmount: number;
  invoiceSubtotal: number;
  invoiceTotal: number;
  canAvailTrialPeriod: boolean;
}

interface IState {
  isPaymentButtonDisable: boolean;
  isPaymentSuccessModalOpen: false;
  isNewCard: boolean;
  cards: Array<{
    id: string;
    brand: string;
    exp_month: number;
    exp_year: number;
    name: string;
    last4: string;
    country: string;
    address_zip: string;
    address_country: string;
  }>;
  cardId: any;
  cardHolderName: any;
  subscriptionProrationDate?: string;
  proRatedAmount?: number;
  postalCode: string;
  proceedToPay: boolean;
  paymentObj: {};
}

class PaymentForm extends React.Component<IProps, IState> {
  /**
   * @description getDerivedStateFromProps is called when there in change in property
   * @param fields {Object} props
   */
  public static getDerivedStateFromProps(props: IProps) {
    if (props.cards) {
      return {
        cards: props.cards.list,
        cardId: props.defaultSource,
      };
    }
    return [];
  }
  public validator: IValidator;

  constructor(props: IProps) {
    super(props);
    this.state = {
      isPaymentButtonDisable: true,
      isPaymentSuccessModalOpen: false,
      cards: [],
      isNewCard: false,
      cardId: undefined,
      cardHolderName: '',
      postalCode: '',
      proceedToPay: false,
      paymentObj: {},
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public componentDidMount() {
    const {
      user: { isPaid },
      dispatch,
      location: {
        state: { licence, isAddOn, amount, action },
      },
    } = this.props;
    if (
      [
        EVENT_PAYMENT_TYPES.SESSION_FEE,
        EVENT_PAYMENT_TYPES.EXTEND_EVENT_FEE,
      ].indexOf(action) === MAGIC_NUMBER.FOUND_INDEX
    ) {
      dispatch(
        paymentAction.getProratedInvoiceAmount({ quantity: +licence, isAddOn })
      );
    }
    if (licence && (isAddOn || amount)) {
      this.setState({
        isPaymentButtonDisable: false,
      });
    }
    if (isPaid) {
      dispatch(cardAction.getCards());
    }
  }
  public fetchTaxRate = (value: string, countryCode: string) => {
    this.props.dispatch(paymentAction.getTaxRate(value, countryCode));
  };
  public componentWillUnmount() {
    this.props.dispatch(paymentAction.resetState());
  }

  public upgradeSubscription = () => {
    const {
      state: { licence, isAddOn },
    } = this.props.location;
    const { dispatch, proRatedAmount, subscriptionProrationDate } = this.props;
    const reqObj = {
      quantity: Number(licence),
      isAddOn,
      isNewCard: false,
      amount: proRatedAmount,
      subscriptionProrationDate,
    };
    dispatch(paymentAction.payment(reqObj));
  };

  public resetTaxData = () => {
    const { dispatch } = this.props;
    dispatch(paymentAction.resetTaxRate());
  };

  public async handleSubmit(
    activeTab: string,
    cardHolderName: string,
    postalCode: string,
    country: string,
    cardId: string = null
  ) {
    const {
      state: { licence, isAddOn, action, bidId = null, extendEventId = null },
    } = this.props.location;
    const {
      dispatch,
      user: { isPaid },
      profile,
      proRatedAmount,
      subscriptionProrationDate,
      taxRate,
    } = this.props;
    if (
      activeTab === staticConstants.PAYMENT_CARD_TAB.NEW_CARD &&
      (!profile.subscriptionId ||
        [
          EVENT_PAYMENT_TYPES.SESSION_FEE,
          EVENT_PAYMENT_TYPES.EXTEND_EVENT_FEE,
        ].indexOf(action) > -1)
    ) {
      let isNewCard = false;
      const res = await this.props.stripe.createToken({
        name: cardHolderName,
        address_zip: postalCode,
        country,
        address_country: country,
      });
      if (res.error) {
        const message = res.error.message;
        dispatch(alertActions.errorAlert(`error:${message}`));
      } else {
        const token = res.token;
        if (isPaid) {
          isNewCard = true;
        }
        // +1 to include orgadmin licence
        let reqObj = { token: token.id, isNewCard };
        if (action === EVENT_PAYMENT_TYPES.SESSION_FEE) {
          const taxRateVal =
            taxRate > MAGIC_NUMBER.ZERO
              ? (this.props.location.state.amount * taxRate) /
                MAGIC_NUMBER.HUNDRED
              : MAGIC_NUMBER.ZERO;
          const amountWithTax =
            parseFloat(this.props.location.state.amount) + taxRateVal;
          reqObj = {
            ...reqObj,
            ...{
              bidId,
              type: EVENT_PAYMENT_TYPES.SESSION_FEE,
              amount: amountWithTax,
            },
          };
        } else if (action === EVENT_PAYMENT_TYPES.EXTEND_EVENT_FEE) {
          const amountWithTax =
            parseFloat(this.props.location.state.amount) +
            (taxRate > MAGIC_NUMBER.ZERO
              ? (this.props.location.state.amount * taxRate) /
                MAGIC_NUMBER.HUNDRED
              : MAGIC_NUMBER.ZERO);
          reqObj = {
            ...reqObj,
            ...{
              bidId,
              type: EVENT_PAYMENT_TYPES.EXTEND_EVENT_FEE,
              extendEventId,
              amount: amountWithTax,
            },
          };
        } else {
          reqObj = {
            ...reqObj,
            ...{
              quantity: Number(licence),
              isAddOn,
              amount: proRatedAmount,
              subscriptionProrationDate,
            },
          };
        }
        dispatch(paymentAction.payment(reqObj));
      }
    } else {
      let reqObj = { cardId, isNewCard: false };
      if (action === EVENT_PAYMENT_TYPES.SESSION_FEE) {
        const amountWithTax =
          parseFloat(this.props.location.state.amount) +
          (taxRate > MAGIC_NUMBER.ZERO
            ? (this.props.location.state.amount * taxRate) /
              MAGIC_NUMBER.HUNDRED
            : MAGIC_NUMBER.ZERO);
        reqObj = {
          ...reqObj,
          ...{
            bidId,
            type: EVENT_PAYMENT_TYPES.SESSION_FEE,
            amount: amountWithTax,
          },
        };
      } else if (action === EVENT_PAYMENT_TYPES.EXTEND_EVENT_FEE) {
        const amountWithTax =
          parseFloat(this.props.location.state.amount) +
          (taxRate > MAGIC_NUMBER.ZERO
            ? (this.props.location.state.amount * taxRate) /
              MAGIC_NUMBER.HUNDRED
            : MAGIC_NUMBER.ZERO);
        reqObj = {
          ...reqObj,
          ...{
            bidId,
            type: EVENT_PAYMENT_TYPES.EXTEND_EVENT_FEE,
            extendEventId,
            amount: amountWithTax,
          },
        };
      } else {
        reqObj = {
          ...reqObj,
          ...{
            quantity: Number(licence),
            isAddOn,
            amount: proRatedAmount,
            subscriptionProrationDate,
          },
        };
      }
      dispatch(paymentAction.payment(reqObj));
    }
  }

  public moveToDashBoardPage = () => {
    const {
      state: { redirectUri },
      hash = null,
    } = this.props.location;
    const { profile: { dateOfBirth } = { dateOfBirth: null } } = this.props;
    const path = dateOfBirth ? getNavToDashboard() : getNavToEditProfile();
    history.push({
      pathname: path,
      state: this.props.location['state'],
      hash,
    });
  };

  public render() {
    let {
      state: { licence, isAddOn, isPaymentButtonDisable, action },
      hash,
    } = this.props.location;
    const {
      user,
      profile,
      amountDue,
      taxRate,
      defaultSource,
      upcomingPeriodInvoiceLogs,
      currentPeriodInvoiceLogs,
      invoiceSubtotal,
      invoiceTaxAmount,
      invoiceTaxRate,
      invoiceTotal,
    } = this.props;
    const isExpertPurchaseAddon = action && action === 'expert-purchase-addon';
    if (!isAddOn) {
      action = 'update-plan'
    }
    const amount =
      [
        EVENT_PAYMENT_TYPES.SESSION_FEE,
        EVENT_PAYMENT_TYPES.EXTEND_EVENT_FEE,
      ].indexOf(action) > -1
        ? Number(this.props.location.state.amount)
        : Number(this.props.proRatedAmount);
    return (
      <div className="payment-section">
        <div className="container">
          {profile && hash === '#3' ? (
            <SubscriptionUpgrade
              profile={profile}
              upgradeSubscription={this.upgradeSubscription}
              amount={amount}
              amountDue={amountDue}
              licence={licence}
              isAddOn={isAddOn}
              invoiceSubtotal={invoiceSubtotal}
              invoiceTaxAmount={invoiceTaxAmount}
              invoiceTaxRate={invoiceTaxRate}
              invoiceTotal={invoiceTotal}
              upcomingPeriodInvoiceLogs={upcomingPeriodInvoiceLogs}
              currentPeriodInvoiceLogs={currentPeriodInvoiceLogs}
            />
          ) : (
            <>
              <CardDetailForm
                canAvailTrialPeriod={this.props.canAvailTrialPeriod}
                cards={this.state.cards}
                dispatch={this.props.dispatch}
                handleSubmit={this.handleSubmit}
                resetTaxData={this.resetTaxData}
                profile={profile}
                action={action}
                user={user}
                defaultSource={defaultSource}
                fetchTaxRate={this.fetchTaxRate}
                isExpertPurchaseAddon={isExpertPurchaseAddon}
                isAddOn={isAddOn}
                isPaymentButtonDisable={isPaymentButtonDisable}
                taxRate={taxRate}
                licence={licence}
                amount={amount}
                amountDue={amountDue}
              />

              <PurchaseDetail
                role={user.role}
                isExpertPurchaseAddon={isExpertPurchaseAddon}
                isAddOn={isAddOn}
                action={action}
                licence={licence}
                amount={amount}
                taxRate={this.props.taxRate}
                canAvailTrialPeriod={this.props.canAvailTrialPeriod}
              />
            </>
          )}
          {[
            EVENT_PAYMENT_TYPES.SESSION_FEE,
            EVENT_PAYMENT_TYPES.EXTEND_EVENT_FEE,
          ].indexOf(action) > -1 && this.props.isPaymentSuccessModalOpen ? (
            <SuccessPopup
              isOpenModal={this.props.isPaymentSuccessModalOpen}
              onClickOk={this.moveToDashBoardPage}
              modalPopupObj={sessionFeePaymenTSuccess}
            />
          ) : (
            <Modal
              isOpen={this.props.isPaymentSuccessModalOpen}
              className="subscription-request"
            >
              <ModalBody>
                <div className="form-group-wrapper">
                  <Media>
                    <Media top className="icon" />
                    <Media body>
                      <Media
                        heading
                        className="heading heading-lg oswald-bold text-uppercase mb-3"
                      >
                        Congratulations!
                      </Media>
                      <Media className="subheading">
                        {action ? (
                          action === 'expert-purchase-addon' ? (
                            <div>
                              You now have full visibility of all user profiles.
                            </div>
                          ) : (
                            <div>Your plan has been successfully changed.</div>
                          )
                        ) : (
                          <div>You are now subscribed to Overlap</div>
                        )}
                      </Media>
                      <Button
                        color="primary"
                        onClick={this.moveToDashBoardPage}
                        className="btn btn-rg"
                      >
                        Done
                      </Button>
                    </Media>
                  </Media>
                </div>
              </ModalBody>
            </Modal>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: {
  payment: any;
  card: any;
  authentication: { user: any };
  updateProfile: { profile: IViewProfile };
}) {
  const {
    payment,
    updateProfile: { profile },
  } = state;
  const { user } = state.authentication;
  let isPaymentSuccessModalOpen = false;
  if (payment.requestedPaymentSuccess) {
    isPaymentSuccessModalOpen = true;
  }
  return {
    isPaymentSuccessModalOpen,
    cards: state.card,
    user,
    profile,
    defaultSource: state.card.default_source,
    proRatedAmount: payment.proRatedAmount,
    amountDue: payment.amountDue,
    currentPeriodInvoiceLogs: payment.currentPeriodInvoiceLogs,
    upcomingPeriodInvoiceLogs: payment.upcomingPeriodInvoiceLogs,
    subscriptionProrationDate: payment.subscriptionProrationDate,
    invoiceTaxRate: payment.invoiceTaxRate,
    invoiceTaxAmount: payment.invoiceTaxAmount,
    invoiceSubtotal: payment.invoiceSubtotal,
    invoiceTotal: payment.invoiceTotal,
    taxRate: payment.taxRate,
    canAvailTrialPeriod: payment.canAvailTrialPeriod,
  };
}

const connectedPaymentForm = connect(mapStateToProps)(
  injectStripe(PaymentForm)
);
export { connectedPaymentForm as PaymentForm };
