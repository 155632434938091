import { userServices } from './../../../../services';
import { alertActions } from './../../../alert/redux/actions';
import { endLoading, startLoading } from './../../../loader/redux/actions';
import { actionTypes } from './types';
import OpenFieldTypes from 'OpenFieldTypes';
import { ICreateUser } from '../../../../interfaces';

export const userAction = {
  getUsers,
  createUser,
  getUserProfile,
  generateReport,
  updateUserStatus,
  updateChangeRequestStatus,
  updateUserRequestStatus,
  deleteUser,
  resetGenerateReport,
  resetPopUpStatus,
  resetCreateUserStatus,
  updateLicenceMovementStatus,
  reVerifyUserEmail,
  sendComment,
  addFeaturedExpert,
};

function generateReport(report: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request(report));
    userServices.getUsers(report)
      .then(
        (resReport) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(resReport));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request(data: string) { return { type: actionTypes.GENERATE_REPORT_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.GENERATE_REPORT_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.GENERATE_REPORT_FAILURE, error }; }
}

function resetGenerateReport() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch({ type: actionTypes.RESET_GENERATE_REPORT });
  };
}

function resetPopUpStatus() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch({ type: actionTypes.RESET_POPUP_STATUS });
  };
}

function getUserProfile(userId: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request(userId));
    userServices.getShortProfile(userId, true)
      .then(
        (user) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(user));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request(data: string) { return { type: actionTypes.USER_DETAIL_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.USER_DETAIL_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.USER_DETAIL_FAILURE, error }; }

}

function getUsers(user: any, cb?: (learners) => void) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request(user));
    userServices.getUsers(user)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          cb ? cb(res) : dispatch(success(res, user));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request(data: string) { return { type: actionTypes.USERS_LIST_REQUEST, data }; }
  function success(data: any, userData: Array<[]>) { return { type: actionTypes.USERS_LIST_SUCCESS, data, userData }; }
  function failure(error: any) { return { type: actionTypes.USERS_LIST_FAILURE, error }; }
}

function createUser(user: ICreateUser) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request());
    userServices.systemAdminCreateUser(user)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(user));
          dispatch(alertActions.successAlert(res.message));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request() { return { type: actionTypes.CREATE_USER_REQUEST }; }
  function success(data: any) { return { type: actionTypes.CREATE_USER_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.CREATE_USER_FAILURE, error }; }
}

function updateUserStatus(user: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request());
    const { isFromDetailPage } = user;
    delete user.isFromDetailPage;
    userServices.updateUserStatus(user)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          if (isFromDetailPage) {
            dispatch(successDetail(user));
          } else {
            dispatch(success(user));
          }
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request() { return { type: actionTypes.UPDTAE_USER_STATUS_REQUEST }; }
  function success(data: any) { return { type: actionTypes.UPDTAE_USER_STATUS_SUCCESS, data }; }
  function successDetail(data: any) { return { type: actionTypes.UPDTAE_USER_STATUS_SUCCESS_FROM_DETAIL, data }; }
  function failure(error: string) { return { type: actionTypes.UPDTAE_USER_STATUS_FAILURE, error }; }
}

function updateChangeRequestStatus(rate: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request());
    userServices.updateChangeRateStatus(rate)
      .then(
        (res) => {
          dispatch(alertActions.successAlert(res.message));
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(rate));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request() { return { type: actionTypes.CHANGE_RATE_REQUEST }; }
  function success(data: any) { return { type: actionTypes.CHANGE_RATE_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.CHANGE_RATE_FAILURE, error }; }
}

function updateUserRequestStatus(user: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request());
    const { isFromDetailPage } = user;
    delete user.isFromDetailPage;
    userServices.updateUserRequestStatus(user)
      .then(
        (res) => {
          dispatch(alertActions.successAlert(res.message));
          dispatch(endLoading('MAIN_LOADER'));
          if (isFromDetailPage) {
            dispatch(successDetail(user));
          } else {
            dispatch(success(user));
          }
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request() { return { type: actionTypes.UPDTAE_USER_REQUEST_STATUS_REQUEST }; }
  function success(data: any) { return { type: actionTypes.UPDTAE_USER_REQUEST_STATUS_SUCCESS, data }; }
  function successDetail(data: any) { return { type: actionTypes.UPDTAE_USER_REQUEST_STATUS_SUCCESS_DETAIL, data }; }
  function failure(error: string) { return { type: actionTypes.UPDTAE_USER_REQUEST_STATUS_FAILURE, error }; }
}

function deleteUser(user: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request());
    userServices.deleteUser(user)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(user));
          dispatch(alertActions.successAlert(res.message.toString()));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request() { return { type: actionTypes.DELETE_USER_REQUEST }; }
  function success(data: any) { return { type: actionTypes.DELETE_USER_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.DELETE_USER_FAILURE, error }; }
}
function reVerifyUserEmail(user: object) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.reVerifyUser(user)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(user));
          dispatch(alertActions.successAlert(res.message));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };
  function success(data: any) { return { type: actionTypes.RE_VERIFY_USER_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.RE_VERIFY_USER_FAILURE, error }; }
}

function resetCreateUserStatus() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch({ type: actionTypes.RESET_CREATE_USER_STATUS });
  };
}

function updateLicenceMovementStatus(user: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request());
    userServices.updateLicenceMovementStatus(user)
      .then(
        (res) => {
          dispatch(alertActions.successAlert(res.message));
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(user));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request() { return { type: actionTypes.UPDTAE_USER_REQUEST_STATUS_REQUEST }; }
  function success(data: any) { return { type: actionTypes.UPDTAE_USER_REQUEST_STATUS_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.UPDTAE_USER_REQUEST_STATUS_FAILURE, error }; }
}

function sendComment(reqObj: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request());
    userServices.sendComment(reqObj)
      .then(
        (res) => {
          dispatch(alertActions.successAlert(res.message));
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success());
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request() { return { type: actionTypes.COMMENT_REQUEST }; }
  function success() { return { type: actionTypes.COMMENT_SUCCESS }; }
  function failure(error: string) { return { type: actionTypes.COMMENT_FAILURE, error }; }
}

function addFeaturedExpert(user: any, usersRequestPayload?:any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request());
    userServices.addFeatureExpert(user)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(user));
          dispatch(alertActions.successAlert(res.addingFeatureExpert.message));
          dispatch(getUsers(usersRequestPayload));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request() { return { type: actionTypes.ADD_FEATURE_REQUEST }; }
  function success(data: any) { return { type: actionTypes.ADD_FEATURE_REQUEST_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.ADD_FEATURE_REQUEST_FAILURE, error }; }
}
