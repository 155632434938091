import React, { Dispatch } from 'react';
import {
  Row,
  Col,
} from 'reactstrap';
import { LocationDescriptorObject } from 'history';
import { IViewProfile, ILocalUser } from '../../../interfaces/index';
import { staticConstants, MAGIC_NUMBER } from './../../../utils';
import OpenFieldTypes from 'OpenFieldTypes';
import moment from 'moment';
import { map } from 'lodash';
import userImage from './../../../assets/images/profile.png';
import facebook from './../../../assets/images/icons/fb-link.svg';
import twitter from './../../../assets/images/icons/twitter-link.svg';
import linkedin from './../../../assets/images/icons/linkedin-link.svg';
import StarRatings from 'react-star-ratings';

interface IProps {
  viewProfile: IViewProfile;
  dispatch?: Dispatch<OpenFieldTypes.RootAction>;
  location?: LocationDescriptorObject;
  userRole: string;
  user?: ILocalUser;
}

interface IState {
  activeTab: any;
}

class ViewOrgAdminProfile extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      activeTab: '1',
    };
  }

  /**
   * @description function to render tag list
   */
  public renderTagList = (tags: any) => {
    return map(tags, (tag: any) => {
      return tag['id'] && (
        <em key={tag['id']} className="tags-view-only">{tag['name']}</em>
      );
    });
  }

  public render() {
    const { viewProfile, userRole } = this.props;
    let averageProfileRating = 0;
    if (viewProfile && viewProfile.averageRating) {
      viewProfile.averageRating = viewProfile.averageRating || 0;
      averageProfileRating = Number.isInteger(viewProfile.averageRating)
        ? viewProfile.averageRating
        : Number(viewProfile.averageRating.toFixed(MAGIC_NUMBER.ONE));
    }
    return (
      viewProfile ? <>
        <div className="profile-bar">
          <div className="user-profile-details">
            <Row>
              <Col xs="12" md="5" className={`d-flex ${(viewProfile.role || userRole) === staticConstants.ROLE.PROFESSIONAL ? 'professional-profile' : ''}`} >
                <span className="profile-picture-view-only">
                  <img
                    src={viewProfile && viewProfile.profileImage
                      ? viewProfile.profileImage : userImage}
                    alt="Profile"
                    className="img-cover"
                  />
                </span>
                <div className="profile-email-view-only text-truncate ">
                  <h3 className="text-truncate">{viewProfile && viewProfile.organizationName}</h3>
                  {userRole === staticConstants.ROLE.EXPERT && <p>{viewProfile && viewProfile.email}</p>}
                  {((viewProfile.role || userRole) === staticConstants.ROLE.ORGANIZATION_ADMIN) && <p>{viewProfile && viewProfile.email}</p>}
                </div>
              </Col>
              <Col xs="12" md="4">
                <div className="rating-view-only">
                  <h2>
                    {viewProfile && viewProfile.title ? `${viewProfile.title}. ` : ''}
                    {viewProfile && `${viewProfile.firstName} `}
                    {viewProfile && viewProfile.lastName}
                    <small>{(viewProfile && viewProfile.position) && ` (${viewProfile.position})`}</small>
                  </h2>
                  <p>Profile Ratings ({averageProfileRating}/5)</p>
                  <StarRatings
                    rating={averageProfileRating}
                    starHoverColor="#4be59e"
                    starEmptyColor="#dcdcdc"
                    starRatedColor="#4be59e"
                    starDimension="20px"
                    starSpacing="2px"
                    numberOfStars={MAGIC_NUMBER.FIVE}
                    name="averageRating"
                  />
                </div>
              </Col>
              <Col xs="12" md="3">
                <div className="social-links">
                  <ul>
                    {viewProfile.facebook &&
                      <li><a href={viewProfile.facebook} target="_blank" rel="noopener noreferrer"><img src={facebook} alt="facebook" /></a></li>
                    }
                    {viewProfile.twitter &&
                      <li><a href={viewProfile.twitter} target="_blank" rel="noopener noreferrer"><img src={twitter} alt="twitter" /></a></li>
                    }
                    {viewProfile.linkedIn &&
                      <li><a href={viewProfile.linkedIn} target="_blank" rel="noopener noreferrer"><img src={linkedin} alt="linkedin" /></a></li>
                    }
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="profile-details-wrapper">
          <div className="form-details">
            <h2 className="heading heading-rg roboto-medium">
              {viewProfile.role === staticConstants.ROLE.PROFESSIONAL ? 'Personal Details' : 'Team Admin Details'}
            </h2>
            <div className="form-data">
              <Row>
                <Col xs="12" md="3">
                  <span>Email</span>
                  <p> {viewProfile && viewProfile.email}</p>
                </Col>
                {
                  (viewProfile && viewProfile.education) &&
                  <Col xs="12" md="3">
                    <span>Highest Level of Education</span>
                    <p> {viewProfile.education}</p>
                  </Col>
                }

                {
                  (viewProfile && viewProfile.dateOfBirth) &&
                  <Col xs="12" md="3">
                    <span>Date of Birth</span>
                    <p>
                      {moment(viewProfile.dateOfBirth).format(staticConstants.DATE_FORMAT)}
                    </p>
                  </Col>
                }

                {
                  (viewProfile && viewProfile.phoneNumber) &&
                  <Col xs="12" md="3">
                    <span>Phone</span>
                    <p> {viewProfile.phoneNumber}</p>
                  </Col>
                }
                {
                  (viewProfile && viewProfile.occupation) &&
                  <Col xs="12" md="3">
                    <span>Occupation</span>
                    <p> {viewProfile.occupation}</p>
                  </Col>
                }

                {
                  (viewProfile && viewProfile.position) &&
                  <Col xs="12" md="3">
                    <span>Position</span>
                    <p>{viewProfile.position}</p>
                  </Col>
                }

                {
                  viewProfile && viewProfile.licence && viewProfile.licence > MAGIC_NUMBER.ZERO && [staticConstants.ROLE.EXPERT, staticConstants.ROLE.PROFESSIONAL].indexOf(viewProfile.role) === MAGIC_NUMBER.FOUND_INDEX ?
                  <Col xs="12" md="3">
                    <span>Number of Licences</span>
                    <p>{viewProfile.licence}</p>
                  </Col> : null
                }

                {
                  (viewProfile && viewProfile.location) &&
                  <Col xs="12" md="3">
                    <span>Location</span>
                    <p>{viewProfile.location}</p>
                  </Col>
                }

                {
                  (viewProfile && viewProfile.address) &&
                  <Col xs="12" md="6">
                    <span>Address</span>
                    <p>{viewProfile.address}</p>
                  </Col>
                }

                {
                  (viewProfile && viewProfile.publishLink) &&
                  <Col xs="12" md="6">
                    <span>Website URL</span>
                    <p>{viewProfile.publishLink}</p>
                  </Col>
                }

                {
                  (viewProfile && viewProfile.tags && viewProfile.tags.length > MAGIC_NUMBER.ZERO) &&
                  <Col xs="12">
                    <span>Tags</span>
                    <div className="tags-wrapper">
                      {this.renderTagList(viewProfile.tags)}
                    </div>
                  </Col>
                }
              </Row>
            </div>
          </div>
        </div>
      </> : null
    );
  }
}

export { ViewOrgAdminProfile };
