import React from 'react';
import logoImage from './../../assets/images/logo.svg';
import fbImage from './../../assets/images/fb.png';
import twImage from './../../assets/images/tw.svg';
import linkdImage from './../../assets/images/lnkd.svg';
import { Link } from 'react-router-dom';

interface IProps {
  activeTab?: string;
}

class LandingFooter extends React.Component<IProps> {

  public activeClass = (tab) => {
    const { activeTab } = this.props;
    if (tab === activeTab) { return 'active'; }
    return '';
  }

  public render() {
    return (
      <footer>
        <div className="container">
          <div className="footer_div">
            <nav className="navbar navbar-expand-lg order-xl-2">
              <div className="social_link mb-4 pb-3 d-lg-block d-none">
              <a href="https://www.facebook.com/ovlpapp/" target="_blank" ><img src={fbImage} alt="Facebook" /></a>
              <a href="https://twitter.com/Overlap_app_" target="_blank" ><img src={twImage} alt="Twitter" /></a>
              <a href="https://www.linkedin.com/company/overlap-incorporated" target="_blank" ><img src={linkdImage} alt="Linkedin" /></a>
              </div>
              <ul className="navbar-nav">
                  <li className="nav-item"><Link to="/" className="nav-link" >Home</Link></li>
                  <li className="nav-item"><Link to="/howItWork" className="nav-link" >How It Works</Link></li>
                  <li className="nav-item"><Link to="/pricing" className="nav-link" >Pricing</Link></li>
                  <li className="nav-item"><Link to="/faq" className="nav-link" >FAQs</Link></li>
                  <li className="nav-item"><Link to="/termsofuse" className="nav-link" target="_blank" >Terms of Use</Link></li>
                  <li className="nav-item"><Link to="/privacyPolicy" className="nav-link" target="_blank">Privacy Policy</Link></li>
                  <li className="nav-item"><Link to="/contactus" className="nav-link" >Contact Us</Link></li>
                  <li className="nav-item"><a target="_blank" className="nav-link" href="https://www.howtooverlap.app">User Guide</a></li>
              </ul>
            </nav>
            <div className="social_link mb-3 d-lg-none d-md-block">
              <a href="https://www.facebook.com/ovlpapp/" target="_blank" ><img src={fbImage} alt="Facebook" /></a>
              <a href="https://twitter.com/Overlap_app_" target="_blank" ><img src={twImage} alt="Twitter" /></a>
              <a href="https://www.linkedin.com/company/overlap-incorporated" target="_blank" ><img src={linkdImage} alt="Linkedin" /></a>
            </div>
            <div className="footer_logo">
              <Link to="/" ><img src={logoImage} alt="logo" /></Link>
              <p>©2021 Overlap Group Inc. All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export { LandingFooter };
