import React, { Fragment } from 'react';
import { Elements } from 'react-stripe-elements';
import { AddCardForm } from './addCardForm';

class CardContainer extends React.Component {

  public render() {
      const props = this.props;

      return (
          <Fragment>
            <Elements>
                <AddCardForm {...props}/>
            </Elements>
          </Fragment>
        );
    }
}

export { CardContainer };
