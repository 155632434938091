import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import OpenFieldTypes from 'OpenFieldTypes';
import { IValidator } from '../../../../interfaces';
import ReeValidate from 'ree-validate';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  isOpenModal: any;
  onClickAccept: any;
  modalTitle: string;
  userType?: string;
  hideModal(): void;
}

interface IState {
  modal: boolean;
  errors?: any;
  hourlyRate: number;
}

class HourlyRate extends React.Component<IProps, IState> {
  public validator: IValidator;
  constructor(props: IProps) {
    super(props);
    const validateObj = {
      hourlyRate: 'required',
    };
    this.validator = new ReeValidate(validateObj);
    this.state = {
      modal: this.props.isOpenModal,
      hourlyRate: 0,
      errors: this.validator.errors,
    };
  }

  public hideModal = () => {
    this.setState({
      modal: false,
      hourlyRate: 0,
    });
    const { errors } = this.validator;
    errors.clear();
    this.props.hideModal();
  }

  public componentWillReceiveProps(props: IProps, nextProp: IProps) {
    this.setState({
      modal: props.isOpenModal,
    });
  }

  public handleChange = (event: any) => {
    const { name, value } = event.target;
    const { errors } = this.validator;
    this.setState({ hourlyRate: value });
    errors.remove('hourlyRate');
    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors });
      });
  }

  public validateAndSubmit = (e) => {
    e.preventDefault();
    const { hourlyRate } = this.state;
    const { errors } = this.validator;
    this.validator.validateAll({ hourlyRate })
      .then((success: boolean) => {
        if (success) {
          this.props.onClickAccept(hourlyRate);
        } else {
          this.setState({ errors });
        }
      });
  }

  public render() {
    const { modal, hourlyRate, errors } = this.state;
    const { modalTitle } = this.props;
    return (
      <Modal isOpen={modal} className="organization-code-modal create-user-modal" onClosed={this.hideModal}>
        <ModalHeader toggle={this.hideModal}>{modalTitle}</ModalHeader>
        <ModalBody>
          <Form onSubmit={this.validateAndSubmit}>
              <FormGroup
                className={`floating-label disabled-input price-placeholder ${
                  errors.has('hourlyRate') ? ' has-error' : ''
                  }`}>
                <Input
                  type="number"
                  className="form-control"
                  name="hourlyRate"
                  id="hourlyRate"
                  value={hourlyRate}
                  placeholder="Set Hourly Rate"
                  onChange={this.handleChange}
                />
                <em>$</em>
                <Label for="hourlyRate" className="selected">Set Hourly Rate</Label>
                {errors.has('hourlyRate') &&
                  <div className="error-text">{errors.first('hourlyRate').replace('hourlyRate', 'set hourly rate')}</div>
                }
              </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-regular mr-2" onClick={this.hideModal}>Cancel</Button>{' '}
          <Button color="primary" onClick={this.validateAndSubmit}>Accept</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const connectedHourlyRate = connect()(HourlyRate);
export { connectedHourlyRate as HourlyRate };
