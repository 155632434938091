export const actionTypes = {
  PLAN_LIST_REQUEST: 'PLAN_LIST_REQUEST',
  PLAN_LIST_SUCCESS: 'PLAN_LIST_SUCCESS',
  PLAN_LIST_FAILURE: 'PLAN_LIST_FAILURE',
  DELETE_PLAN_SUCCESS: 'DELETE_PLAN_SUCCESS',
  DELETE_PLAN_FAILURE: 'DELETE_PLAN_FAILURE',
  ADD_PLAN_SUCCESS: 'ADD_PLAN_SUCCESS',
  ADD_PLAN_FAILURE: 'ADD_PLAN_FAILURE',
  EDIT_PLAN_SUCCESS: 'EDIT_PLAN_SUCCESS',
  EDIT_PLAN_FAILURE: 'EDIT_PLAN_FAILURE',
};
