import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader
} from 'reactstrap';
import OpenFieldTypes from 'OpenFieldTypes';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  isOpenModal: boolean;
  modalPopupObj: any;
  hideModal(): void;
  cancelPopupHide(): void;
  onClickYes(arg: any): void;
}

interface IState {
  modal: boolean;
}

class CloseBidPopup extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      modal: props.isOpenModal,
    };
    this.hideModal = this.hideModal.bind(this);
    this.cancelModal = this.cancelModal.bind(this);
  }

  public hideModal() {
    this.setState({
      modal: false,
    });
    this.props.hideModal();
  }

  public cancelModal() {
    this.setState({
      modal: false,
    });
    this.props.cancelPopupHide();
  }

  public render() {
    const { modal } = this.state;
    const { modalPopupObj } = this.props;
    function createMarkup() { return { __html: `${modalPopupObj.body}<br /> ${modalPopupObj.areYouSure}` }; }
    return (
      <Modal isOpen={modal} className="delete-modal close-request">
        <ModalHeader toggle={this.cancelModal}></ModalHeader>
        <ModalBody>
          <h2 className="heading heading-lg roboto-bold text-center">{modalPopupObj.title}</h2>
          <p dangerouslySetInnerHTML={createMarkup()}/>
          <div className="button-group">
            {modalPopupObj.noBtnText ?
              <Button className="btn btn-regular mx-1" onClick={this.hideModal}>{modalPopupObj.noBtnText}</Button>
              : ''}
            {modalPopupObj.yesBtnText ?
              <Button className={`btn mx-2 ${modalPopupObj.yesBtnClass ? modalPopupObj.yesBtnClass : 'btn-delete'}`}
                onClick={this.props.onClickYes}>{modalPopupObj.yesBtnText}</Button>
              : ''}
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const connectedCloseBidPopupPage = connect()(CloseBidPopup);
export { connectedCloseBidPopupPage as CloseBidPopup };
