import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { LocationDescriptorObject } from 'history';
import OpenFieldTypes from 'OpenFieldTypes';
import { pageRoutes } from './../../../utils/routeConstants';
import { staticConstants, MAGIC_NUMBER } from './../../../utils/staticConstants';
import { Scrollbars } from 'react-custom-scrollbars';
interface IProps {
  user: any;
  location?: LocationDescriptorObject;
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  totalUnreadChats?: number;
}

interface IState {
  user?: any;
}

class Sidebar extends React.Component<IProps, IState> {

  public render() {

    const { user, location, totalUnreadChats } = this.props;
    let pathname = '';
    if (location) {
      pathname = location.pathname;
    }
    if (!user) return null;
    return user && (
        <div className="sidebar-wrapper">
          <Scrollbars
            autoHide
            className="vertical-scroll"
          >
            <ul>
              {user.role === staticConstants.ROLE.ORGANIZATION_ADMIN &&
                <li>
                  <Link
                    to={`${pageRoutes.ORGADMIN.PATH}${pageRoutes.ORGADMIN.DASHBOARD}`}
                    className={pageRoutes.ORGADMIN.PATH + pageRoutes.ORGADMIN.DASHBOARD === pathname ? 'active' : ''}
                  >
                    <span className="icon dashboard-icon" />
                    <span className="list-text">Dashboard</span>
                  </Link>
                </li>
              }
              {user.role === staticConstants.ROLE.ORGANIZATION_LEARNER &&
                <li>
                  <Link
                    to={`${pageRoutes.LEARNER.PATH}${pageRoutes.LEARNER.DASHBOARD}`}
                    className={pageRoutes.LEARNER.PATH + pageRoutes.LEARNER.DASHBOARD === pathname ? 'active' : ''}
                  >
                    <span className="icon dashboard-icon" />
                    <span className="list-text">Dashboard</span>
                  </Link>
                </li>
              }
              {user.role === staticConstants.ROLE.EXPERT &&
                <>
                  <li>
                    <Link
                      to={`${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.DASHBOARD}`}
                      className={pageRoutes.EXPERT.PATH + pageRoutes.EXPERT.DASHBOARD === pathname ? 'active' : ''}
                    >
                      <span className="icon dashboard-icon" />
                      <span className="list-text">Dashboard</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={`${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.BID_MANAGEMENT.PATH}`}
                      className={pageRoutes.EXPERT.PATH + pageRoutes.EXPERT.BID_MANAGEMENT.PATH === pathname ? 'active' : ''}
                    >
                      <span className="icon bid-icon" />
                      <span className="list-text">Bid Management</span>
                    </Link>
                  </li>
                </>
              }
              {user.role === staticConstants.ROLE.PROFESSIONAL &&
                <li>
                  <Link
                    to={`${pageRoutes.PROFESSIONAL.PATH}${pageRoutes.PROFESSIONAL.DASHBOARD}`}
                    className={pageRoutes.PROFESSIONAL.PATH + pageRoutes.PROFESSIONAL.DASHBOARD === pathname ? 'active' : ''}
                  >
                    <span className="icon dashboard-icon" />
                    <span className="list-text">Dashboard</span>
                  </Link>
                </li>
              }
              {user.role === staticConstants.ROLE.SYSTEM_ADMIN &&
                <li>
                  <Link
                    to={`${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.SYSTEM_ADMIN.DASHBOARD}`}
                    className={pageRoutes.SYSTEM_ADMIN.PATH + pageRoutes.SYSTEM_ADMIN.DASHBOARD === pathname ? 'active' : ''}
                  >
                    <span className="icon dashboard-icon" />
                    <span className="list-text">Dashboard</span>
                  </Link>
                </li>
              }
              {
              [staticConstants.ROLE.ORGANIZATION_ADMIN,
                staticConstants.ROLE.ORGANIZATION_LEARNER, staticConstants.ROLE.PROFESSIONAL, staticConstants.ROLE.SYSTEM_ADMIN].indexOf(user.role) > -1 &&
                <li>
                  <Link
                    to={`${pageRoutes.GOALMANAGEMENT.PATH}`}
                    className={pageRoutes.GOALMANAGEMENT.PATH === pathname ? 'active' : ''}
                  >
                    <span className="icon goal-icon" />
                    <span className="list-text">Goals</span>
                  </Link>
                </li>
              }
              {[staticConstants.ROLE.ORGANIZATION_ADMIN, staticConstants.ROLE.PROFESSIONAL, staticConstants.ROLE.SYSTEM_ADMIN].indexOf(user.role) > -1
               &&
                <li>
                  <Link
                    to={`${pageRoutes.REQUESTMANAGEMENT.PATH}`}
                    className={pageRoutes.REQUESTMANAGEMENT.PATH === pathname ? 'active' : ''}
                  >
                    <span className="icon rfp-icon" />
                    <span className="list-text">Requests</span>
                  </Link>
                </li>
              }
              <li>
                <Link
                  to={`${pageRoutes.EVENTMANAGEMENT.PATH}`}
                  className={pageRoutes.EVENTMANAGEMENT.PATH === pathname ? 'active' : ''}
                >
                  <span className="icon event-icon" />
                  <span className="list-text">
                    {user.role === staticConstants.ROLE.EXPERT ?
                      `Scheduled Events` : `Events`}
                  </span>
                </Link>
              </li>
              {user.role === staticConstants.ROLE.EXPERT &&
                <li>
                  <Link
                    to={`${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.PAYMENT.PATH}`}
                    className={pageRoutes.EXPERT.PATH + pageRoutes.EXPERT.PAYMENT.PATH === pathname ? 'active' : ''}
                  >
                    <span className="icon payment-icon" />
                    <span className="list-text">Payments</span>
                  </Link>
                </li>
              }
              {user.role === staticConstants.ROLE.ORGANIZATION_ADMIN &&
                <li>
                  <Link
                    to={`${pageRoutes.LEARNERMANAGEMENT.PATH}`}
                    className={pageRoutes.LEARNERMANAGEMENT.PATH === pathname ? 'active' : ''}
                  >
                    <span className="icon users-icon" />
                    <span className="list-text">Users</span>
                  </Link>
                </li>
              }

              {user.role === staticConstants.ROLE.SYSTEM_ADMIN &&
                  <li>
                    <Link
                      to={`${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.PAYMENT.PATH}`}
                      className={pageRoutes.SYSTEM_ADMIN.PATH + pageRoutes.PAYMENT.PATH === pathname ? 'active' : ''}
                    >
                      <span className="icon payment-icon" />
                      <span className="list-text">Payments</span>
                    </Link>
                  </li>
              }

              {user.role === staticConstants.ROLE.SYSTEM_ADMIN &&
                <li>
                  <Link
                    to={`${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.PATH}`}
                    className={`${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.PATH}` === pathname ? 'active' : ''}>
                    <span className="icon users-icon" />
                    <span className="list-text">User Management</span>
                  </Link>
                </li>
              }

                <li>
                  <Link
                    to={`${pageRoutes.MESSAGE_BOARD.PATH}`}
                    className={pageRoutes.MESSAGE_BOARD.PATH === pathname ? 'active' : ''}
                  >
                    <span className="icon message-icon">
                      {totalUnreadChats > MAGIC_NUMBER.ZERO && pageRoutes.MESSAGE_BOARD.PATH !== pathname && <em> {totalUnreadChats}</em>}
                    </span>
                    <span className="list-text">Messages</span>
                  </Link>
                </li>
              {user.role !== staticConstants.ROLE.SYSTEM_ADMIN &&
                <li>
                  <Link to={`${pageRoutes.LOGS.PATH}`}>
                    <span className="icon log-icon" />
                    <span className="list-text">Log</span>
                  </Link>
                </li>
              }

              {user.role === staticConstants.ROLE.SYSTEM_ADMIN &&
                <li>
                  <Link
                    to={`${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.SYSTEM_ADMIN.CONTENT_MANAGEMENT.PATH}`}
                    className={`${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.SYSTEM_ADMIN.CONTENT_MANAGEMENT.PATH}` === pathname ? 'active' : ''}>
                    <span className="icon content-icon" />
                    <span className="list-text">Content Management</span>
                  </Link>
                </li>
              }

              {user.role === staticConstants.ROLE.SYSTEM_ADMIN &&
                <li>
                  <Link
                    to={`${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.SYSTEM_ADMIN.APPLICATION_FEEDBACK}`}
                    className={pageRoutes.SYSTEM_ADMIN.PATH + pageRoutes.SYSTEM_ADMIN.APPLICATION_FEEDBACK === pathname ? 'active' : ''}
                  >
                    <span className="icon feedback-icon" />
                    <span className="list-text">Application Feedback</span>
                  </Link>
                </li>
              }
               {user.role === staticConstants.ROLE.SYSTEM_ADMIN &&
                <li>
                  <Link
                    to={`${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.SYSTEM_ADMIN.PLANS.PATH}`}
                    className={pageRoutes.SYSTEM_ADMIN.PATH + pageRoutes.SYSTEM_ADMIN.PLANS.PATH === pathname ? 'active' : ''}
                  >
                    <span className="icon plan-icon" />
                    <span className="list-text">Plans</span>
                  </Link>
                </li>
              }
            {user.role === staticConstants.ROLE.SYSTEM_ADMIN &&
              <li>
                <Link
                to={`${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.SYSTEM_ADMIN.TAGS}`}
                  className={pageRoutes.SYSTEM_ADMIN.PATH + pageRoutes.SYSTEM_ADMIN.TAGS === pathname ? 'active' : ''}
                >
                  <span className="icon tag-icon" />
                  <span className="list-text">Tags</span>
                </Link>
              </li>
            }
            </ul>
          </Scrollbars>
        </div>
    );
  }
}

function mapStateToProps(state: { authentication: any; chatMessages: any }) {
  const { authentication, chatMessages: { totalUnreadChats } } = state;
  const { user } = authentication;
  return {
    user,
    totalUnreadChats,
  };
}

const connectedSideBarPage = connect(mapStateToProps)(Sidebar);
export { connectedSideBarPage as Sidebar };
