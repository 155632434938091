import { history } from '../../../../helpers';
import {
  ICreatePasswordReq,
  IUpdateProfileReq,
  IUploadImgaeReq,
  IUpdateLearnerProfileReq,
} from '../../../../interfaces';
import { messages } from '../../../../utils/messages';
import { pageRoutes } from '../../../../utils/routeConstants';
import { getNavToDashboard } from '../../../../utils/handleNavigation';
import { userServices } from './../../../../services/userServices';
import { alertActions } from './../../../alert/redux/actions';
import { endLoading, startLoading } from './../../../loader/redux/actions';
import { actionTypes } from './types';
import OpenFieldTypes from 'OpenFieldTypes';

export const learnerProfileAction = {
  getProfile,
  uploadProfileImage,
  getIndustries,
  getCategories,
  updateProfile,
  updateLearnerProfile,
};

function getProfile() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(null));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.getProfile()
      .then(
        (data) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(data));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request(data: ICreatePasswordReq) { return { type: actionTypes.GET_PROFILE_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.GET_PROFILE_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.GET_PROFILE_FAILURE, error }; }
}

function uploadProfileImage(reqData: IUploadImgaeReq) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(null));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.updateProfileImage(reqData)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(res));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request(data: any) { return { type: actionTypes.UPDATE_PROFILE_IMAGE_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.UPDATE_PROFILE_IMAGE_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.UPDATE_PROFILE_IMAGE_FAILURE, error }; }
}

function getIndustries() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(null));

    userServices.getIndustries()
      .then(
        (res) => {
          dispatch(success(res));
        },
        (error) => {
          dispatch(alertActions.errorAlert(error.toString()));
          dispatch(failure(error.toString()));
        },
      );
  };

  function request(data: any) { return { type: actionTypes.FETCH_INDUSTRY_SUGGESTIONS_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.FETCH_INDUSTRY_SUGGESTIONS_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.FETCH_INDUSTRY_SUGGESTIONS_FAILURE, error }; }
}

function getCategories() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(null));

    userServices.getCategories()
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          dispatch(alertActions.errorAlert(error.toString()));
          dispatch(failure(error.toString()));
        },
      );
  };

  function request(data: ICreatePasswordReq) { return { type: actionTypes.FETCH_SECTOR_SUGGESTIONS_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.FETCH_SECTOR_SUGGESTIONS_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.FETCH_SECTOR_SUGGESTIONS_FAILURE, error }; }
}

function updateProfile(reqData: IUpdateProfileReq) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(null));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.updateProfile(reqData)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(res));
          history.push(`${pageRoutes.ORGADMIN.PATH}${pageRoutes.ORGADMIN.DASHBOARD}`);
          dispatch(alertActions.successAlert(messages.profileUpdated));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(alertActions.errorAlert(error.toString()));
          dispatch(failure(error.toString()));
        },
      );
  };

  function request(data: IUpdateProfileReq) { return { type: actionTypes.UPDATE_PROFILE_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.UPDATE_PROFILE_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.UPDATE_PROFILE_FAILURE, error }; }
}

function updateLearnerProfile(reqData: IUpdateLearnerProfileReq) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(null));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.updateLearnerProfile(reqData)
      .then(
        (res) => {
          history.push(getNavToDashboard());
          dispatch(alertActions.successAlert(messages.profileUpdated));
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(res));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(alertActions.errorAlert(error.toString()));
          dispatch(failure(error.toString()));
        },
      );
  };

  function request(data: IUpdateProfileReq) { return { type: actionTypes.UPDATE_PROFILE_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.UPDATE_PROFILE_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.UPDATE_PROFILE_FAILURE, error }; }
}
