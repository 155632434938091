import { userServices } from '../../../../services';
import { alertActions } from '../../../alert/redux/actions';
import { endLoading, startLoading } from '../../../loader/redux/actions';
import { actionTypes } from './types';
import OpenFieldTypes from 'OpenFieldTypes';
import { staticConstants } from '../../../../utils';

export const learnerAction = {
  getLearner,
  createLearner,
  assignLicence,
  deleteLearner,
  cancelRequest,
  raiseRequest,
};

function getLearner(learner: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request());
    userServices.getUsers(learner)
      .then(
        (learners) => {
          dispatch(success(learners));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request() { return { type: actionTypes.LEARNER_LIST_REQUEST }; }
  function success(learners: string) { return { type: actionTypes.LEARNER_LIST_SUCCESS, learners }; }
  function failure(error: string) { return { type: actionTypes.LEARNER_LIST_FAILURE, error }; }
}

function createLearner(learner: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request());
    userServices.createUser(learner)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(learner));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request() { return { type: actionTypes.CREATE_LEARNER_REQUEST }; }
  function success(data: any) { return { type: actionTypes.CREATE_LEARNER_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.CREATE_LEARNER_FAILURE, error }; }
}

function assignLicence(data: object) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.assignLicence(data)
      .then(
        (res) => {
          res['userId'] = data['userId'];
          res['type'] = data['type'];
          res['learnerData'] = data['learnerData'];
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(res));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          if (JSON.parse(localStorage.getItem('errorCodeLearner')) === staticConstants.LEARNER_ERROR_CODE) {
            dispatch(openModalOnError());
          } else if (error.toString() === staticConstants.LEARNER_REMOVE_CODE) {
            dispatch(openModalForGoalAssignError());
          } else {
            dispatch(failure(error.toString()));
            dispatch(alertActions.errorAlert(error.toString()));
          }
        },
      );
  };
  function success(datas: object) { return { type: actionTypes.ASSIGN_LICENCE_SUCCESS, data: datas }; }
  function failure(error: object) { return { type: actionTypes.ASSIGN_LICENCE_FAILURE, error }; }
  function openModalOnError() { return { type: actionTypes.LICENCE_ASSIGN_REQUEST }; }
  function openModalForGoalAssignError() { return { type: actionTypes.ALLREADY_ASSIGN_GOAL_ERROR }; }
}

function deleteLearner(data: object) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.deleteLearner(data)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(data));
          dispatch(alertActions.successAlert(res['message']));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          if (error.toString() === staticConstants.LEARNER_REMOVE_CODE) {
            dispatch(openModalForGoalAssignError());
          } else {
            dispatch(failure(error.toString()));
            dispatch(alertActions.errorAlert(error.toString()));
          }

        },
      );
  };
  function success(datas: object) { return { type: actionTypes.DELETE_LEARNER_SUCCESS, data: datas }; }
  function failure(error: object) { return { type: actionTypes.DELETE_LEARNER_FAILURE, error }; }
  function openModalForGoalAssignError() { return { type: actionTypes.ALLREADY_ASSIGN_GOAL_ERROR }; }
}

function cancelRequest() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(openModalOnError());
    dispatch(openModalForGoalAssignError());
  };
  function openModalOnError() { return { type: actionTypes.LICENCE_ASSIGN_REQUEST }; }
  function openModalForGoalAssignError() { return { type: actionTypes.RESET_ALLREADY_ASSIGN_GOAL_ERROR }; }
}

function raiseRequest() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request());
    userServices.raiseLicenceRequest()
      .then(
        (res) => {
          dispatch(success(res));
          dispatch(alertActions.successAlert(res));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request() { return { type: actionTypes.LEARNER_LIST_REQUEST }; }
  function success(learners: string) { return { type: actionTypes.LEARNER_LIST_SUCCESS, learners }; }
  function failure(error: string) { return { type: actionTypes.LEARNER_LIST_FAILURE, error }; }
}
