import gql from 'graphql-tag';
export default gql`
input bankAccountInput {
    bankName: String,
    accountNumber: String,
    accountHolderName: String,
    routingNumber: String,
    branchCode: String,
    swiftNumber: String,
    other: String,
  }

  input commonLabelInput{
    value: String,
    label: String,
}
input accountHolderAddressInput {
    street1: String,
    street2: String,
    city: String,
    state: String,
    zipcode: String,
    country: commonLabelInput,
  }
`;
const LOGIN_MUTATION = gql`
  mutation login($email: String!, $password: String!, $timezone: String) {
    login(email: $email, password: $password, timezone: $timezone) {
      message,
      data {
        deviceCount,
        subscribedProductId,
        subscriptionId,
        email
        role
        isPaid
        token
        profileImage
        isStripeAccount
        isAddOn,
        isFirstTime,
        _id,
        feedback {
          message,
          isFeedback,
          isReply,
          createdAt
        }
        firstName,
        lastName,
      }
    }
}`;

const REGISTER_MUTATION = gql`
  mutation signUp($email: String!, $organizationName: String!, $userType: String!, $licence: Int!) {
    signUp(email: $email, organizationName: $organizationName, userType: $userType, licence: $licence) {
      message
      }
}`;

const PROFESSIONAL_REGISTER_MUTATION = gql`
  mutation addProfessional($email: String!, $firstName: String!, $lastName: String!) {
    addProfessional(email: $email, firstName: $firstName, lastName: $lastName) {
      message
      }
}`;

const EXPERT_REGISTER_MUTATION = gql`
  mutation addExpert(
    $firstName: String!,
    $lastName: String!,
    $email: String!,
    $address: String,
    $location: String,
    $occupation: String!,
    $position: String!,
    $bio: String!,
    $linkedinProfile: String,
    $publishLink: String,
    $phoneNumber: String,
    $education: String,
    $expertEducation: educationSchemaInput,
    $experiences: [experienceSchemaInput],
    $references: [referencesSchemaInput],
    $referred: referredSchemaInput,
    $resume: resumeInput,
    $title: String!) {
      addExpert(
      firstName: $firstName,
      lastName: $lastName,
      email: $email,
      address: $address,
      location: $location,
      occupation: $occupation,
      position: $position,
      bio: $bio,
      linkedinProfile: $linkedinProfile,
      publishLink: $publishLink,
      phoneNumber: $phoneNumber,
      title: $title,
      education: $education,
      expertEducation: $expertEducation,
      experiences: $experiences,
      references: $references,
      referred: $referred,
      resume: $resume,
    ) {
      message
    }
}`;

const CHECK_EXISTING_EXPERT = gql`
  mutation checkExistingEmail($email: String!) {
    checkExistingEmail(email: $email) {
      message
    }
  }
`;

const CREATE_LEARNER_MUTATION = gql`
  mutation addLearner($email: String!, $firstName: String!, $lastName: String!) {
    addLearner(email: $email, firstName: $firstName, lastName: $lastName) {
      message
      }
}`;

const FORGOT_PASSWORD_MUTATION = gql`
  mutation forgetPassword($email: String!) {
    forgetPassword(email: $email) {
      message
    }
}`;

const VERIFY_CREATE_PASSWORD_QUERY = gql`
  query verifyToken($token: String!) {
    verifyToken(token: $token) {
      message,
      data {
        email
      }
    }
}`;

const LOGOUT_USER_MUTATION = gql`
mutation logout($isAll: Boolean) {
  logout(isAll: $isAll) {
      message
    }
}`;

const CREATE_PASSWORD_MUTATION = gql`
  mutation createPassword($token: String!,$email: String!, $password: String!) {
    createPassword(token: $token, email: $email, password: $password) {
      message,
      data {
        firstName,
        lastName,
        email,
        role,
        isPaid,
        token,
        subscribedProductId,
        subscriptionId,
        _id,
        deviceCount,
        isFirstTime,
      }
    }
}`;

const VERIFY_FORGOT_PASSWORD_QUERY = gql`
  query validateForgotPassword($token: String!) {
    validateForgotPassword(token: $token) {
      message,
      data {
        email
      }
    }
}`;

const RESET_PASSWORD_MUTATION = gql`
  mutation updatePassword($token: String!,$email: String!, $password: String!) {
    updatePassword(token: $token, email: $email, password: $password) {
      message
    }
}`;

const GET_PROFILE_QUERY = gql`
  query getProfile{
    getProfile {
      message,
      data {
        inStripeRegion,
        role,
        firstName,
        lastName,
        education,
        dateOfBirth,
        phoneNumber,
        sector,
        occupation,
        position,
        location,
        address,
        industry,
        title,
        profileImage,
        resume,
        sectorId,
        industryId,
        email,
        organizationName,
        employer,
        tags{
          id,
          name
        },
        availableDays,
        languages {
          language,
          profeciencies
        },
        experiences {
          employer,
          position,
          lengthOfServices,
          location,
          summary,
        },
        references {
          name,
          email,
          phone,
          relation,
        },
        bio,
        linkedinProfile,
        publishLink,
        modeOfDelivery,
        stripeAccountId,
        isAddOn
        licence,
        isLicenced,
        amount,
        subscriptionId,
        current_period_end,
        isPaid,
        notifications,
        linkedIn,
        facebook,
        twitter,
        averageSessionRating,
        averageRating,
        commission{
          professional,
          organization,
        }
        hourlyRate {
          current,
          requested,
          comment
      },
      totalLicenceAssign,
      }
    }
}`;

const UPDATE_EXPERT_MUTATION = gql`
  mutation updateUser($firstName: String!,$lastName: String!, $expertEducation: educationSchemaInput, $phoneNumber: String!,
    $sector: String!,
    $occupation: String!, $position: String, $location: String!,$address: String!, $industry: String!, $title: String!,
    $sectorId: String!, $industryId: String!, $dateOfBirth: String!, $bio: String,
    $publishLink: String,
    $experiences: [experienceSchemaInput],
    $tags: [tagsTypeSchemaInput]) {
      updateUser(firstName: $firstName, lastName: $lastName,
         phoneNumber: $phoneNumber,sector: $sector,
         occupation: $occupation, position: $position, location: $location, expertEducation: $expertEducation,
          address: $address, industry: $industry, title: $title,experiences: $experiences, publishLink: $publishLink,
          sectorId: $sectorId, industryId: $industryId, tags: $tags,
         dateOfBirth: $dateOfBirth, bio: $bio,
         ) {
      message,
      data {

        firstName,
        lastName,
        education,
        dateOfBirth,
        phoneNumber,
        sector,
        occupation,
        position,
        location,
        address,
        industry,
        title,
        profileImage,
        sectorId,
        industryId,
        licence,
        bio,
        publishLink,
        availableDays,
        languages {
          language,
          profeciencies
        }
        experiences {
          employer,
          position,
          lengthOfServices,
          location,
          summary,
        },
        tags{
          id,
          name
        },
        modeOfDelivery
      }
    }
}`;

const UPDATE_USER_MUTATION = gql`
  mutation updateUser($firstName: String!,$lastName: String!, $education: String, $phoneNumber: String!,
     $sector: String,
    $occupation: String!, $position: String, $location: String!,$address: String, $industry: String, $title: String!,
    $sectorId: String, $industryId: String, $dateOfBirth: String!, $publishLink: String
    $tags: [tagsTypeSchemaInput]) {
      updateUser(firstName: $firstName, lastName: $lastName, education: $education,
         phoneNumber: $phoneNumber,sector: $sector,
         occupation: $occupation, position: $position, location: $location,
          address: $address, industry: $industry, title: $title,
          sectorId: $sectorId, industryId: $industryId, dateOfBirth: $dateOfBirth, tags: $tags,
          publishLink: $publishLink
         ) {
      message,
      data {
        firstName,
        lastName,
        education,
        dateOfBirth,
        phoneNumber,
        sector,
        occupation,
        position,
        location,
        address,
        industry,
        title,
        profileImage,
        sectorId,
        industryId,
        licence
      }
    }
}`;

const UPDATE_USER_PAYMENT_ACCOUNT_ID_MUTATION = gql`
  mutation updatePaymentAccountId($authorizationCode: String!) {
    updatePaymentAccountId(authorizationCode: $authorizationCode) {
      message,
      data {
        stripeAccountId
      }
    }
}`;

const UPDATE_LEARNER_MUTATION = gql`
  mutation updateLearner($firstName: String!,$lastName: String!, $education: String!,
    $occupation: String!, $phoneNumber: String, $position: String, $location: String!,$title: String!,
    $dateOfBirth: String!,$employer: String!,
    $tags: [tagsTypeSchemaInput]) {
      updateLearner(firstName: $firstName, lastName: $lastName, education: $education, phoneNumber: $phoneNumber,
         occupation: $occupation, position: $position, location: $location, title: $title,
         tags: $tags, dateOfBirth: $dateOfBirth, employer: $employer
         ) {
      message,
      data {
        firstName,
        employer,
        lastName,
        education,
        dateOfBirth,
        occupation,
        position,
        location,
        title,
        profileImage,
        tags{
          id,
          name
        }
      }
    }
}`;

const UPLOAD_PROFILE_IMAGE_MUTATION = gql`
mutation uploadProfile($upload: String!, $type: String!){
  uploadProfile(upload: $upload, type: $type){
      message,
      data {
        path
      }
    }
}`;

const UPLOAD_RESUME_MUTATION = gql`
mutation uploadResume($upload: String!, $type: String!){
  uploadResume(upload: $upload, type: $type){
      message,
      data {
        path
      }
    }
}`;

const GET_INDUSTRY = gql`
query industry{
  industry{
      data {
        name,
        _id
      }
    }
}`;

const GET_CATEGORIES = gql`
query category{
  category{
      data {
        name,
        _id
      }
    }
}`;

const CANCEL_SUBSCRIPTION = gql`
mutation cancelSubscription {
  cancelSubscription {
    message
  }
}`;
const ASSIGN_LICENCE = gql`
    mutation assignLicence( $userId: String!, $type: String!) {
    assignLicence( userId: $userId, type: $type) {
        message,
      }
  }`;

const DELETE_LEARNER = gql`
    mutation deletelearner( $userId: String!) {
    deletelearner( userId: $userId) {
        message,
      }
  }`;

const UPDATE_SETTING = gql`
    mutation updateSettings($notifications: Boolean, $linkedIn: String, $facebook: String, $twitter: String) {
    updateSettings(notifications: $notifications, linkedIn: $linkedIn, facebook: $facebook, twitter: $twitter) {
        message,
        data {
          notifications
          linkedIn
          facebook
          twitter
        }
      }
  }`;

const EXPERT_FEEDBACK = gql`
    mutation connectFeedback( $subject: String, $message: String!, $name: String, $email: String) {
    connectFeedback( subject: $subject, message: $message, name :$name, email: $email) {
        message
      }
  }`;

const SOCIAL_LOGIN_MUTATION = gql`
  mutation socialLogin($firstName: String!, $lastName: String!, $facebookId: String!, $token: String!, $email: String!) {
    socialLogin(facebook: {firstName: $firstName, lastName: $lastName, facebookId: $facebookId, token: $token, email: $email}) {
      message,
      data {
        deviceCount,
        subscribedProductId,
        email
        role
        isPaid
        token
        profileImage
        isFirstTime
        isStripeAccount
        isAddOn,
        _id,
        feedback {
          message,
          isFeedback,
          isReply,
          createdAt
        }
        firstName,
        lastName,
      }
    }
}`;

const LINKEDIN_MUTATION = gql`
  mutation socialLogin($code: String!) {
    socialLogin(linkedin: {code: $code}) {
      message,
      data {
        deviceCount,
        subscribedProductId,
        email
        role
        isPaid
        token
        profileImage
        isFirstTime
        isStripeAccount
        isAddOn,
        _id,
        feedback {
          message,
          isFeedback,
          isReply,
          createdAt
        }
        firstName,
        lastName,
        subscriptionId,
      }
    }
  }`;

const CHANGE_PASSWORD_MUTATION = gql`
  mutation changePassword($oldPassword: String!, $confirmPassword: String!) {
    changePassword(oldPassword: $oldPassword, confirmPassword: $confirmPassword) {
      message
    }
}`;

const UPDATE_USER_STATUS_MUTATION = gql`
  mutation updateUserStatus($userId: String!, $action: String!) {
    updateUserStatus(userId: $userId, action: $action) {
      message
    }
}`;

const UPDATE_USER_REQUEST_STATUS_MUTATION = gql`
  mutation updateExpertRequestStatus($userId: String!, $action: String!, $hourlyRate: Float) {
    updateExpertRequestStatus(userId: $userId, action: $action, hourlyRate: $hourlyRate) {
      message
    }
}`;

const UPDATE_CHANGE_RATE_STATUS_MUTATION = gql`
  mutation updateChangeRateStatus($userId: String!, $action: String!) {
    updateChangeRateStatus(userId: $userId, action: $action) {
      message
    }
}`;

const DELETE_USER_MUTATION = gql`
  mutation deleteUser($userId: String!) {
    deleteUser(userId: $userId) {
      message
    }
}`;

const CHANGE_REQUEST_HOURLY_RATE_MUTATION = gql`
  mutation changeHourlyRateRequest($ratePerHour: Float!, $comment: String!) {
    changeHourlyRateRequest(ratePerHour: $ratePerHour, comment: $comment) {
      message
    }
}`;

const ADD_USER_MUTATION = gql`
  mutation addUser($firstName: String!, $lastName: String!, $email: String!, $organizationName: String!, $userType: String!, $licence: Int! $hourlyRate: Float, $subscribedProductId: String) {
    addUser(firstName: $firstName, lastName: $lastName email: $email, organizationName: $organizationName, userType: $userType, licence: $licence, hourlyRate: $hourlyRate, subscribedProductId: $subscribedProductId) {
      message
      }
}`;

const UPDATE_SYSTEM_ADMIN_PROFILE_MUTATION = gql`
  mutation updateUser($firstName: String, $lastName: String, $phoneNumber: String, $commission: commisionInputSchema) {
    updateUser(firstName: $firstName, lastName: $lastName, commission: $commission, phoneNumber: $phoneNumber) {
      message
      data {
        email,
        profileImage,
        firstName,
        lastName,
        phoneNumber,
        commission{
          professional
          organization
        }
      }
    }
}`;

const LICENCE_MOVEMENT_REQUEST_MUTATION = gql`
  mutation licenceMovementRequest {
    licenceMovementRequest {
      message
    }
}`;

const UPDATE_LICENCE_MOVEMENT_MUTATION = gql`
  mutation updateLicenceMovement($userId: String!, $action: String!) {
    updateLicenceMovement(userId: $userId, action: $action) {
      message
    }
}`;

const RE_VERIFY_USER_MUTATION = gql`
  mutation reVerification($userId: String!) {
    reVerification(userId: $userId) {
      message
    }
}`;

const FLAGGED_COMMENT__MUTATION = gql`
  mutation flagComment($userId: String!, $comment: String!) {
    flagComment(userId: $userId, comment: $comment) {
      message
    }
}`;

const UPDATE_REGION = gql`
mutation updateRegion($bankAccount: bankAccountInput, $accountHolderAddress: accountHolderAddressInput, $region: String!) {
  updateRegion(bankAccount: $bankAccount, accountHolderAddress: $accountHolderAddress, region: $region ) {
      message
    }
}`;

const CHANGE_EMAIL_MUTATION = gql`
  mutation changeEmail($newEmail: String!, $password: String!) {
    changeEmail(email: $newEmail, password: $password) {
      message
    }
}`;

const VERIFY_CHANGE_EMAIL_QUERY = gql`
  query verifyChangeEmail($token: String!) {
    verifyChangeEmail(token: $token) {
      message,
      data {
        email
      }
    }
}`;

const ADD_FEATURE_EXPERT = gql`
mutation addingFeatureExpert($userId: String!, $featureExpert: Boolean) {
    addingFeatureExpert(userId: $userId, featureExpert: $featureExpert) {
      message
    }
}`;

export {
  LOGIN_MUTATION, REGISTER_MUTATION, FORGOT_PASSWORD_MUTATION, VERIFY_CREATE_PASSWORD_QUERY,
  CREATE_PASSWORD_MUTATION, VERIFY_FORGOT_PASSWORD_QUERY, RESET_PASSWORD_MUTATION,
  GET_PROFILE_QUERY, UPDATE_USER_MUTATION, CANCEL_SUBSCRIPTION,
  UPLOAD_PROFILE_IMAGE_MUTATION, GET_INDUSTRY, GET_CATEGORIES, CREATE_LEARNER_MUTATION, EXPERT_REGISTER_MUTATION,
  UPDATE_LEARNER_MUTATION, UPDATE_USER_PAYMENT_ACCOUNT_ID_MUTATION, UPDATE_EXPERT_MUTATION, ASSIGN_LICENCE, DELETE_LEARNER,
  UPDATE_SETTING, EXPERT_FEEDBACK, PROFESSIONAL_REGISTER_MUTATION, SOCIAL_LOGIN_MUTATION, LINKEDIN_MUTATION, CHANGE_PASSWORD_MUTATION,
  UPDATE_USER_STATUS_MUTATION,
  UPDATE_USER_REQUEST_STATUS_MUTATION,
  UPDATE_CHANGE_RATE_STATUS_MUTATION,
  CHANGE_REQUEST_HOURLY_RATE_MUTATION,
  DELETE_USER_MUTATION,
  ADD_USER_MUTATION,
  UPDATE_SYSTEM_ADMIN_PROFILE_MUTATION,
  LICENCE_MOVEMENT_REQUEST_MUTATION,
  UPDATE_LICENCE_MOVEMENT_MUTATION,
  CHECK_EXISTING_EXPERT,
  RE_VERIFY_USER_MUTATION,
  FLAGGED_COMMENT__MUTATION,
  LOGOUT_USER_MUTATION,
  UPDATE_REGION,
  CHANGE_EMAIL_MUTATION,
  VERIFY_CHANGE_EMAIL_QUERY,
  UPLOAD_RESUME_MUTATION,
  ADD_FEATURE_EXPERT,
};
