import {
  goalStatus,
  CSS_ClASSES
} from '../../../../utils/staticConstants';
import React from 'react';
import { Button } from 'reactstrap';

interface IProps {
  requestType: any;
  changeRequestType: any;
  role?: any;
}

class RequestTypeTab extends React.Component<IProps> {

  /**
   * @description
   * render is used to render the html.
   */
  public render() {

    const requestType = this.props.requestType;
    return (
      <div className="tab-btn-group">
        <Button
          color="secondary"
          className={'btn tab-btn btn-rg h-50 ' +
            (requestType === goalStatus.NEW_REQUEST ? CSS_ClASSES.btnActive : '')}
          onClick={() => this.props.changeRequestType(goalStatus.NEW_REQUEST)}
        >
          New
          </Button>
        <Button
          color="secondary"
          className={'btn tab-btn btn-rg h-50 ' +
            (requestType === goalStatus.ACCEPT ? CSS_ClASSES.btnActive : '')}
          onClick={() => this.props.changeRequestType(goalStatus.ACCEPT)}
        >
          Accepted
          </Button>
        <Button
          color="secondary"
          className={'btn tab-btn btn-rg h-50 ' +
            (requestType === goalStatus.REJECT ? 'btn-active' : '')}
          onClick={() => this.props.changeRequestType(goalStatus.REJECT)} > Rejected
          </Button>
      </div>
    );
  }
}

export { RequestTypeTab };
