import { actionTypes } from './types';

interface IAction {
  type: string;
  data: {};
  error: string;
}
export function forgotPassword(state = {}, action: IAction) {
  switch (action.type) {
      case actionTypes.FORGOT_PASSWORD_REQUEST:
        return { requestingForgetPassword: true };
      case actionTypes.FORGOT_PASSWORD_SUCCESS:
        return { requestingForgetPassword: false };
      case actionTypes.FORGOT_PASSWORD_FAILURE:
        return { requestingForgetPassword: false , error: action.error };
      default:
        return state;
    }
}
