import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import OpenFieldTypes from 'OpenFieldTypes';
import { UncontrolledTooltip } from 'reactstrap';
import { history } from '../../../../helpers';
import { staticConstants, pageRoutes, formatCurrency, bidStatus } from './../../../../utils';
import { IBidDetail } from './../../../../interfaces';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  request: any;
  user: any;
  activeTab: string;
  onCancel(id: string, bidId: string): void;
}

class EventItem extends React.Component<IProps> {

  public startZoomEvent = () => {
    const { request, user, activeTab } = this.props;
    if (user['role'] === staticConstants.ROLE.EXPERT
      && activeTab === staticConstants.EVENT_MANAGEMENT_TAB.UPCOMING_EVENTS) {
      const bid: IBidDetail = request['bid'][0];
      !bid.zoomEvent.isEnded && window.open(bid.zoomEvent.startUrl, '_blank');
    }
    if ([staticConstants.ROLE.ORGANIZATION_LEARNER, staticConstants.ROLE.ORGANIZATION_ADMIN,
      staticConstants.ROLE.PROFESSIONAL].indexOf(user['role']) > -1
      && activeTab === staticConstants.EVENT_MANAGEMENT_TAB.UPCOMING_EVENTS) {
      const bid: IBidDetail = request['bid'] && request['bid'][0];
      !bid.zoomEvent.isEnded && window.open(bid.zoomEvent.joinUrl, '_blank');
    }
  }

  public redirectEventDetail (event: any, bid: any) {
    if(event.target.className !== 'icon icon-cross danger') {
      history.push({
        pathname: `${pageRoutes.EVENTMANAGEMENT.PATH}${pageRoutes.EVENTMANAGEMENT.DETAIL}/${bid._id}`,
        state: { activeTab: this.props.activeTab },
      });
    }
  }

  /**
   * @description
   * render is used to render the html.
   */
  public render() {
    const { request, user } = this.props;
    const bid: IBidDetail = request['bid'] && request['bid'][0];
    let participants = 0, dateAvailability, eventTime;
    if (request) {
      participants = request.participants.length;
      if (bid['endedAt'] && this.props.activeTab === staticConstants.EVENT_MANAGEMENT_TAB.PAST_EVENTS) {
        dateAvailability = moment(bid['endedAt']).format(staticConstants.DATE_FORMAT);
        eventTime = moment(bid['endedAt']).format(staticConstants.TIME_FORMAT);
      } else {
        dateAvailability = moment(bid.proposedDate).format(staticConstants.DATE_FORMAT);
        eventTime = moment(bid.proposedTime).format(staticConstants.TIME_FORMAT);
      }
    }

    request['isEventStarted'] = request['statusTimeline'].filter((el: { status: string }) => el.status === bidStatus.EVENT_STARTED);
    return request && (
      <div className={`table-row click-pointer ${request.isDispute ? 'red' : ''}`} onClick={(event) => this.redirectEventDetail(event, request.bid[0])}>
        <div className="table-col table-col-17" data-label="Title">
          <span className="roboto-medium link-btn text-truncate" >{request.title}</span>
        </div>
        <div className="table-col table-col-10" data-label="Event Type">
          <span>{request.eventType}</span>
        </div>
        <div className="table-col table-col-15" data-label="Domain">
          <span>{request.domain.name}</span>
        </div>
        <div className="table-col table-col-13" data-label="Sub-Domain">
          <span>{request.subDomain.name}</span>
        </div>
        <div className="table-col table-col-13 sm-50 text-center" data-label="Participants">
          <span>{participants}</span>
        </div>
        {this.props.activeTab === staticConstants.EVENT_MANAGEMENT_TAB.UPCOMING_EVENTS &&
          <React.Fragment>
            <div className="table-col table-col-12 sm-50" data-label="Scheduled For">
              <span>{dateAvailability}</span>
            </div>
            <div className="table-col table-col-10 sm-50" data-label="Scheduled Time">
              <span>{eventTime}</span>
            </div>
          {request['isEventStarted'].length < 1 && (user['role'] === staticConstants.ROLE.ORGANIZATION_ADMIN ||  user['role'] === staticConstants.ROLE.PROFESSIONAL) ?
              <div className="table-col table-col-10 p-0 text-center action-col cursor-pointer rejected" onClick={() => this.props.onCancel(request._id, request.bid[0]._id)}>
                <em id={`tooltip_${request._id}`} className="icon icon-cross danger" />
                <UncontrolledTooltip placement="top" target={`tooltip_${request._id}`} >Cancel Event</UncontrolledTooltip>
              </div> : ''}
          </React.Fragment>
        }
        {this.props.activeTab === staticConstants.EVENT_MANAGEMENT_TAB.PAST_EVENTS &&
          <React.Fragment>
            <div className="table-col table-col-12 sm-50" data-label="Event Completed">
              <span>{dateAvailability}</span>
            </div>
            <div className="table-col table-col-10 sm-50" data-label="Event Time">
              <span>{eventTime}</span>
            </div>
            <div className="table-col table-col-10 text-center p-0" data-label="Session Fee">
              {bid.sessionFee
                ? <span>{`$${formatCurrency(bid.sessionFee)}`}</span>
              : <span>{`$0`}</span>}
            </div>
          </React.Fragment>
        }
      </div >
    );
  }
}

function mapStateToProps(state: { authentication: { user: any } }) {
  const { user } = state.authentication;
  return {
    user,
  };

}

const eventItemPage = connect(mapStateToProps)(EventItem);
export { eventItemPage as EventItem };
