import { customizedPlanService } from '../../../../services';
import { alertActions } from '../../../alert/redux/actions';
import { endLoading, startLoading } from '../../../loader/redux/actions';
import { actionTypes } from './types';
import OpenFieldTypes from 'OpenFieldTypes';
import { handleError } from './../../../../utils';

export const appPlanAction = {
  getPlanListing,
  editPlan,
  deletePlan,
  addPlan,
};

function getPlanListing(reqObj: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request(reqObj));
    customizedPlanService.getPlansData(reqObj)
            .then(
                (response) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(success(response));
                },
                (error) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.errorAlert(error.toString()));
                },
            );
  };

  function request(data: string) { return { type: actionTypes.PLAN_LIST_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.PLAN_LIST_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.PLAN_LIST_FAILURE, error }; }
}

function addPlan(reqObj: object, listReqObj: object) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    customizedPlanService.addPlan(reqObj)
            .then(
                (response) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(success(response));
                  dispatch(getPlanListing(listReqObj));
                  dispatch(alertActions.successAlert(response.message));
                },
                (error) => {
                  handleError(dispatch, failure, error.toString());
                },
            );
  };
  function success(data: any) { return { type: actionTypes.ADD_PLAN_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.ADD_PLAN_FAILURE, error }; }
}

function editPlan(reqObj: object, listReqObj: object) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    customizedPlanService.editPlan(reqObj)
            .then(
                (response) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(success(response));
                  dispatch(getPlanListing(listReqObj));
                  dispatch(alertActions.successAlert(response));
                },
                (error) => {
                  handleError(dispatch, failure, error.toString());
                },
            );
  };
  function success(data: any) { return { type: actionTypes.EDIT_PLAN_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.EDIT_PLAN_FAILURE, error }; }
}

function deletePlan(productId: string, reqObj: object) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    customizedPlanService.deletePlan({ productId })
            .then(
                (data) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(success(productId));
                  dispatch(getPlanListing(reqObj));
                  dispatch(alertActions.successAlert(data));
                },
                (error) => {
                  handleError(dispatch, failure, error.toString());
                },
            );
  };
  function success(data: any) { return { type: actionTypes.DELETE_PLAN_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.DELETE_PLAN_FAILURE, error }; }
}
