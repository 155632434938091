import _ from 'lodash';
import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { cardAction } from '../redux/actions';
import { CardItem } from './card';
import OpenFieldTypes from 'OpenFieldTypes';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  cards: any;
  defaultSource: any;
}

interface IState {
  cards: any;
}

class ListingContainer extends React.Component<IProps, IState> {

  /**
   * @description getDerivedStateFromProps is called when there in change in property
   * @param fields {Object} props
   */
  public static getDerivedStateFromProps(props: IProps) {
    if (props.cards) {
      return {
        cards: props.cards.list,
      };
    }
    return [];
  }

  /**
   * @description componentDidMount is called when component is loaded
   * call cardAction.getCards to fetch card listings
   */
  public componentDidMount() {
    this.props.dispatch(cardAction.getCards());
  }

  /**
   * @description renderCardListing is called to fetch card listing
   */
  public renderCardListing() {
    const list = this.state.cards;
    return list && _.map(list, (item, index) => {
      let isDefaultCard = false;
      if (this.props.defaultSource === item.id) {
        isDefaultCard = true;
      }
      return (
        <CardItem card={item} isDefaultCard={isDefaultCard} key={item.id} />
      );
    });
  }

  public render() {
    return (
      <React.Fragment>
        <div className="table-body">
          <div className="collapse-body add-card-body">
            {this.renderCardListing()}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: { card: any }) {
  return {
    cards: state.card,
    defaultSource: state.card.default_source,
  };
}

const connectedCardListingContainer = connect(mapStateToProps)(ListingContainer);
export { connectedCardListingContainer as ListingContainer };
