import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import OpenFieldTypes from 'OpenFieldTypes';
import { UncontrolledTooltip } from 'reactstrap';
import { staticConstants } from '../../../../utils';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  request: any;
  onDelete(id: string): void;
  onSendReply(userDetail: object): void;
}

class FeedbackItem extends React.Component<IProps> {

    /**
     * @description
     * render is used to render the html.
     */
  public render() {
      const { request } = this.props;
      let recivedDate;
      if (request && request.feedback && request.feedback.createdAt) {
        recivedDate = `${moment(request['feedback']['createdAt']).format(staticConstants.DATE_FORMAT)}`;
      } else {
        recivedDate = `${moment(request['createdAt']).format(staticConstants.DATE_FORMAT)}`;
      }
      return (
            request && (
                <div className={`table-row ${!request.feedback.isReply ? ' green' : ''}`}>
                    <div className="table-col table-col-25" data-label="Name">
                        <span className={`cursor-pointer ${!request.feedback.isReply ? ' roboto-medium' : ''}`} onClick={() => this.props.onSendReply(request)}> {`${request.firstName} ${request.lastName}`}</span>
                    </div>
                    <div className="table-col table-col-20" data-label="Email">
                        <span>{request.email}</span>
                    </div>
                    <div className="table-col table-col-15" data-label="Phone Number">
                        <span>{request.phoneNumber}</span>
                    </div>
                    <div className="table-col table-col-15" data-label="Feedback">
                        <span>{request.feedback.message}</span>
                    </div>
                    <div
                        className="table-col table-col-15"
                        data-label="Received Date"
                    >
                        <span>{recivedDate}</span>
                    </div>
                    <div className="table-col table-col-10 text-center p-0 action-col">
                        {
                            !request.feedback.isReply && (<>
                            <em className="icon icon-reply" id={`tooltip_reply_${request._id}`} onClick={() => this.props.onSendReply(request)} />
                            <UncontrolledTooltip placement="top" target={`tooltip_reply_${request._id}`} >Send Reply</UncontrolledTooltip>
                            </>)
                        }
                        <em className="icon icon-delete" id={`tooltip_delete_${request._id}`} onClick={() => this.props.onDelete(request._id)} />
                        <UncontrolledTooltip placement="top" target={`tooltip_delete_${request._id}`} >Delete</UncontrolledTooltip>
                    </div>
                </div>
            )
        );
    }
}

function mapStateToProps(state: { authentication: { user: any } }) {
  const { user } = state.authentication;
  return {
      user,
    };

}

const FeedbackItemPage = connect(mapStateToProps)(FeedbackItem);
export { FeedbackItemPage as FeedbackItem };
