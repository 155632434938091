import { contentManagementService, userServices } from '../../../services';
import { alertActions } from '../../alert/redux/actions';
import { endLoading, startLoading } from '../../loader/redux/actions';
import { actionTypes } from './types';
import OpenFieldTypes from 'OpenFieldTypes';

function getContent(reqObj: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request(reqObj));
    contentManagementService.getContent(reqObj)
            .then(
                (response) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(success(response));
                },
                (error) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.errorAlert(error.toString()));
                },
            );
  };

  function request(data: string) { return { type: actionTypes.GET_CONTENT_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.GET_CONTENT_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.GET_CONTENT_FAILURE, error }; }
}

function contactUs(name: string, email: string, message: string) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request());
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.sendExpertFeedback({ name, email, message })
            .then(
                (res) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(success(res));
                  dispatch(alertActions.successAlert(res.message));
                },
                (error) => {
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.errorAlert(error.toString()));
                },
            );
  };

  function request() { return { type: actionTypes.CONNECT_US }; }
  function success(data: string) { return { data, type: actionTypes.CONNECT_US_SUCCESS }; }
  function failure(error: string) { return { error, type: actionTypes.CONNECT_US_FAILURE }; }
}

export const contentManagementAction = {
  getContent,
  contactUs,
};
