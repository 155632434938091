import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  staticConstants,
  deleteFaq,
} from './../../../../utils';
import { Button } from 'reactstrap';
import OpenFieldTypes from 'OpenFieldTypes';
import { LocationDescriptorObject } from 'history';
import { contentManagementAction } from '../redux/actions';
import { IUpdateContent } from '../../../../interfaces';
import { ConfirmatioPopup } from '../../../shared/modals/confirmationPopup';
import { FilterComponent } from '../../../shared/filter/filterComponent';
import queryString from 'query-string';
import { FaqContainer } from '../faq/faq';
import { TextEditorContainer } from '../textEditor/textEditor';
import { FaqModal } from '../faq/addFaqModal';
import { history } from '../../../../helpers';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  activeTab?: string;
  content: Array<[]>;
  updateContent: boolean;
  location: LocationDescriptorObject;
}

class ContentListingContainer extends React.Component<IProps> {

  /**
   * @description getDerivedStateFromProps is called when there in change in property
   * @param fields {Object} props
   */
  public static getDerivedStateFromProps(props: IProps, state: any) {
    const updateState = {};
    if (props.activeTab !== state.activeTab || props.updateContent) {
      updateState['activeTab'] = props.activeTab;
      updateState['fetchContent'] = true;
    }
    return updateState;
  }

  public state = {
    reqObj: {},
    modalPopupObj: {},
    activeTab: staticConstants.CONTENT_MANAGEMENT_TAB.ABOUT_US,
    fetchContent: false,
    faqModalOpen: false,
    displayDeletePopup: false,
    faqId: '',
    editFaq: {},
    searchKey: '',
  };

  /**
   * @description componentDidMount is called when component is loaded
   * call userAction.getUsers to fetch goal listings
   * call userAction.getUsers to fetch goal categories
   */
  public componentDidMount() {
    this.fetchContent();
  }

  public componentDidUpdate() {
    if (this.state.fetchContent) {
      this.fetchContent();
      this.setState({ fetchContent: false });
    }
  }

  /**
   * @description open the faq modal on the click of add faq button
   */
  public openFaqModal = (faqId: any) => {
    this.setState({
      faqModalOpen: true,
      editFaq: {},
    });
    if (typeof faqId === 'string') {
      const faqToEdit = this.props.content.filter(data => data['_id'] === faqId);
      this.setState({
        editFaq: {
          isEdit: true,
          question: faqToEdit[0]['question'],
          answer: faqToEdit[0]['answer'],
          faqType:faqToEdit[0]['faqType'],
          faqId,
        },
      });
    }
  }

  public fetchContent() {
    const { searchKey } = this.state;
    this.props.dispatch(contentManagementAction.getContent({
      type: this.props.activeTab,
      searchKey,
    }));
  }

  public addFaq = (reqObj: IUpdateContent) => {
    this.props.dispatch(contentManagementAction.updateContent(reqObj));
    this.setState({
      faqModalOpen: false,
    });
  }

  public deletePopup = (id) => {
    const modalPopupObj = deleteFaq;
    this.setState({
      displayDeletePopup: true,
      faqId: id,
      modalPopupObj,
    });
  }

   /**
    * @description
    * deleteFaq is used show delete popup.
    */
  public deleteFaq = () => {
    const { faqId } = this.state;
    const reqObj = {
      faqId,
    };
    this.props.dispatch(contentManagementAction.deleteFaq(reqObj));
    this.setState({
      displayDeletePopup: false,
      modalPopupObj: {},
      faqId: '',
    });
  }

  /**
   * @description
   * handleChange is used to set the value on state from the input.
   * @param fields {Object} || {String} value
   * @param fields {String} key
   */
  public handleChange = (value: any, key: string) => {
    const { location: { search } }  = this.props;
    const query = queryString.parse(search);
    query['page'] = '1';
    history.replace({ search: queryString.stringify(query) });
    this.setState({ ...this.state, searchKey: value }, () => this.fetchContent());
  }

  /**
   * @description
   * render is used to render HTML
   */
  public render() {
    const { activeTab, faqModalOpen, displayDeletePopup, modalPopupObj, editFaq } = this.state;
    const { content } = this.props;
    const filterContent = ['search'];
    return (
      <React.Fragment>
        <FaqModal editFaq={editFaq} isOpenModal={faqModalOpen} addFaq={this.addFaq} hideModal={() => {
          this.setState({
            faqModalOpen: false,
          });
        }}/>
          {activeTab === staticConstants.CONTENT_MANAGEMENT_TAB.FAQ &&
            <>
            <div className="filter-wrapper">
                <FilterComponent filterContent={filterContent} handleFilter={this.handleChange} placeholder="Search by Keywords" activeTab={activeTab} />
                <Button type="button" color="Primary" className="btn btn-primary round-circle add-filter-btn ml-auto add-faq-btn" onClick={this.openFaqModal}>
                    Add FAQs
                </Button>
              </div>
              <FaqContainer activeTab={activeTab} content={content} openModal={this.openFaqModal}
                openPopup={this.deletePopup} onEdit={this.openFaqModal} />
            </>
          }
          {content && activeTab !== staticConstants.CONTENT_MANAGEMENT_TAB.FAQ &&
            <TextEditorContainer activeTab={activeTab} content={content}/>
          }
          {
            displayDeletePopup ?
              <ConfirmatioPopup isOpenModal={displayDeletePopup} modalPopupObj={modalPopupObj}
                hideModal={() => this.setState({ displayDeletePopup: false })} onClickYes={this.deleteFaq} />
              : ''
          }
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: { contentManagement: { content: Array<[]>; updateContent: boolean }}) {
  const { content, updateContent } = state.contentManagement;
  return {
    content ,
    updateContent,
  };
}

const connectedContentListingContainer = connect(mapStateToProps)(ContentListingContainer);
export { connectedContentListingContainer as ContentListingContainer };
