import gql from 'graphql-tag';

const ADD_PLAN_MUTATION = gql`
  mutation createPlan($productName: String!, $licenceAmount: Float!, $learnerAmount: Float!) {
    createPlan(productName: $productName, licenceAmount: $licenceAmount, learnerAmount: $learnerAmount) {
        message
      }
}
`;

const UPDATE_PLAN_MUTATION = gql`
  mutation updatePlan($productId: String!, $productName: String!) {
    updatePlan(productId: $productId, productName: $productName) {
        message
      }
}
`;

const DELETE_PLAN_MUTATION = gql`
  mutation removePlan($productId: String!) {
    removePlan( productId: $productId ) {
        message
      }
}
`;

export {
    UPDATE_PLAN_MUTATION,
    DELETE_PLAN_MUTATION,
    ADD_PLAN_MUTATION,
};
