import { paymentService } from './../../../../services/paymentServices';
import { alertActions } from './../../../alert/redux/actions';
import { endLoading, startLoading } from './../../../loader/redux/actions';
import { actionTypes } from './types';
import OpenFieldTypes from 'OpenFieldTypes';
import { history } from '../../../../helpers';
import { handleError, pageRoutes } from '../../../../utils';

export const systemAdminPaymentAction = {
  getTotalPayments,
  getPaymentsByType,
  updateDisputeStatus,
  generateReport,
  resetGenerateReport,
  withdrawList,
  generateWithdrawReport,
  updateWithdrawnRequest,
  withdrawnHistoryList,
};

function getTotalPayments() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request());
    paymentService.getTotalPayments()
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(res));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request() { return { type: actionTypes.TOTAL_PAYMENTS_REQUEST }; }
  function success(data) { return { type: actionTypes.TOTAL_PAYMENTS_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.TOTAL_PAYMENTS_FAILURE, error }; }
}

function getPaymentsByType(reqObj: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request(reqObj));
    paymentService.getPayments(reqObj)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(res, reqObj));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request(data: string) { return { type: actionTypes.PAYMENTS_LIST_REQUEST, data }; }
  function success(data: any, userData: Array<[]>) { return { type: actionTypes.PAYMENTS_LIST_SUCCESS, data, userData }; }
  function failure(error: any) { return { type: actionTypes.PAYMENTS_LIST_FAILURE, error }; }
}

function updateDisputeStatus(reqObj: any, reFetchObj: any = null, redirectUri: string = null) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request());
    paymentService.updateDisputeStatus(reqObj)
      .then(
        (res) => {

          dispatch(endLoading('MAIN_LOADER'));
          dispatch(alertActions.successAlert(res));
          dispatch(success());
          if (reFetchObj) {
            dispatch(getPaymentsByType(reFetchObj));
          } else if (redirectUri) {
            history.push(redirectUri);
          }
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request() { return { type: actionTypes.PAYMENTS_LIST_REQUEST }; }
  function success() { return { type: actionTypes.UPDATE_DISPUTE_SUCCESS }; }
  function failure(error: any) { return { type: actionTypes.PAYMENTS_LIST_FAILURE, error }; }
}

function generateReport(report: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request(report));
    paymentService.getPayments(report)
      .then(
        (resReport) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(resReport));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request(data: string) { return { type: actionTypes.GENERATE_REPORT_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.GENERATE_REPORT_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.GENERATE_REPORT_FAILURE, error }; }
}

function withdrawList(reqObj: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    paymentService.withdrawList(reqObj).then(
        (requestList) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(requestList));
        },
        error => handleError(dispatch, failure, String(error)),
      );
  };

  function success(data: any) { return { type: actionTypes.WITHDRAW_LIST_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.WITHDRAW_LIST_FAILURE, error }; }
}

function generateWithdrawReport(withdrawReport: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    paymentService.withdrawList(withdrawReport)
      .then(
        (withdrawResReport) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(withdrawResReport));
        },
        error => handleError(dispatch, failure, String(error)),
      );
  };

  function success(data: any) { return { type: actionTypes.WITHDRAW_REPORT_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.WITHDRAW_REPORT_FAILURE, error }; }
}

function resetGenerateReport() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch({ type: actionTypes.RESET_GENERATE_REPORT });
  };
}

function updateWithdrawnRequest(reqObj: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    paymentService.updateWithdrawnRequest(reqObj).then(
        (response) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(response));
          dispatch(alertActions.successAlert(response.message));
          history.push(`${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.PAYMENT.PATH}?tab=paymentRequest&page=1`);
        },
        error => handleError(dispatch, failure, String(error)),
      );
  };

  function success(data: any) { return { type: actionTypes.UPDATE_WITHDRAWN_REQUEST_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.UPDATE_WITHDRAWN_REQUEST_FAILURE, error }; }
}

function withdrawnHistoryList(reqObj: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    paymentService.withdrawnHistoryList(reqObj).then(
        (requestList) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(requestList));
        },
        error => handleError(dispatch, failure, String(error)),
      );
  };

  function success(data: any) { return { type: actionTypes.WITHDRAWN_HISTORY_LIST_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.WITHDRAWN_HISTORY_LIST_FAILURE, error }; }
}
