import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Media,
  Modal,
  ModalBody,
} from 'reactstrap';

interface IProps {
  isOpenModal: boolean;
  fullLearnerName: string;
  isLicenced: boolean;
  hideModal(): void;
}

interface IState {
  modal: boolean;
}

class AssignUnassignLicenceModalPopup extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      modal: props.isOpenModal,
    };
    this.hideModal = this.hideModal.bind(this);
  }

  public hideModal() {
    this.setState({
      modal: false,
    });
    this.props.hideModal();
  }
  public render() {
    const { modal } = this.state;
    const { fullLearnerName, isLicenced } = this.props;
    return (
      <Modal isOpen={modal} className="assigned-licence-modal">
        <span className="close-btn icon icon-cross" onClick={() => this.hideModal()}/>
        <ModalBody>
          <div className="form-group-wrapper">
            <Media>
              <Media top className="icon"/>
              <Media>
                <Media heading className="heading heading-lg roboto-bold mb-4">
                  {fullLearnerName && !isLicenced
                    ? 'Licence Assigned Successfully!'
                    : 'Licence Unassign Successfully!'
                  }
                </Media>
                <Media className="subheading">
                  {fullLearnerName && !isLicenced
                    ? <div>Licence has been assigned to <strong>{fullLearnerName}</strong> </div>
                    : <div>Licence has been unassign from <strong>{fullLearnerName}</strong></div>
                  }
                </Media>
              </Media>
            </Media>
          </div>
        </ModalBody>
        <div className="footer-button-group">
          <Button color="primary" onClick={() => this.hideModal()} className="btn btn-rg">Done</Button>
        </div>
      </Modal>
    );
  }
}

const connectedAssignUnassignLicenceModalPopup = connect()(AssignUnassignLicenceModalPopup);
export { connectedAssignUnassignLicenceModalPopup as AssignUnassignLicenceModalPopup };
