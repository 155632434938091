import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalHeader,
} from 'reactstrap';
import OpenFieldTypes from 'OpenFieldTypes';
import { IValidator } from './../../../interfaces';
import { reeValidators, staticConstants } from '../../../utils';
import ReeValidate from 'ree-validate';
import { AdminAttendancePopup } from './adminAttendancePopup';
import { ExpertAttendancePopup } from './expertAttendancePopup';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  isOpenModal: boolean;
  modalPopupObj: {};
  onClickSubmit: any;
  hideModal(): void;
}

interface IState {
  modal: boolean;
  errors?: any;
  formData: {
      comment: string;
      isExpertAttended: string;
      isExpertDeliver: string;
      isParticipantsAttended: string;
      isAbleToDeliver: string;
    };
}

class AttendanceContainerPopup extends React.Component<IProps, IState> {
  public validator: IValidator;
  constructor(props: IProps) {
      super(props);
      this.validator = new ReeValidate({
          comment: reeValidators.required_min_3_max_500,
          isExpertAttended: 'required',
          isExpertDeliver: 'required',
          isParticipantsAttended: 'required',
          isAbleToDeliver: 'required',
        });

      this.state = {
          modal: props.isOpenModal,
          formData: {
              comment: '',
              isExpertDeliver: 'true',
              isExpertAttended: 'true',
              isParticipantsAttended: 'true',
              isAbleToDeliver: 'true',
            },
          errors: this.validator.errors,
        };
    }

  public hideModal = () => {
      this.setState({
          modal: false,
        });
      this.props.hideModal();
    }
  public handleSubmit = (formData: any) => {
      const obj = this.props.modalPopupObj;
      if (obj['userRole'] === staticConstants.ROLE.EXPERT) {
          obj['comment'] = formData.comment;
          obj['isParticipantsAttended'] = JSON.parse(formData.isParticipantsAttended);
          obj['isAbleToDeliver'] = JSON.parse(formData.isAbleToDeliver);
        } else {
          obj['comment'] = formData.comment;
          obj['isExpertAttended'] = JSON.parse(formData.isExpertAttended);
          obj['isExpertDeliver'] = JSON.parse(formData.isExpertDeliver);
        }
      this.props.onClickSubmit(obj);
    }

  public render() {
      const { modal } = this.state;
      const modalData = this.props.modalPopupObj;
      return (
            <Modal isOpen={modal} className="organization-code-modal">
                {modalData && (modalData['userRole'] === staticConstants.ROLE.EXPERT)
                    ?
                    <ModalHeader>Attendance for Participants</ModalHeader> :
                    <ModalHeader>Attendance for Expert</ModalHeader>
                }
                <ModalBody className="p-0">
                    {
                        (modalData['userRole'] === staticConstants.ROLE.EXPERT)
                            ? <ExpertAttendancePopup handleSubmit={this.handleSubmit} />
                            : <AdminAttendancePopup handleSubmit={this.handleSubmit} />
                    }
                </ModalBody>
            </Modal>
        );
    }
}

const connectedAttendanceContainerPopup = connect()(AttendanceContainerPopup);
export { connectedAttendanceContainerPopup as AttendanceContainerPopup };
