import React, { Component, Dispatch } from 'react';
import { connect } from 'react-redux';
import OpenFieldTypes from 'OpenFieldTypes';
import { LocationDescriptorObject } from 'history';
import {  Button } from 'reactstrap';
import { ListingContainer } from '../../listingManagement/listingContainer';
import { userAction } from '../../../systemAdmin/userManagement/redux/actions';
import { tableItemsCount, staticConstants, CSS_ClASSES } from '../../../../utils';
import { FilterComponent } from '../../filter/filterComponent';
import { ILocalUser } from '../../../../interfaces';

const getUsersRequestTypes = {
  expert: 'expert',
  organization: 'organizationAdmin',
  professional: 'professional',
};

const organizationConfigObj = {
  organizationList: { name: 'Name', dataLabel: 'Title', class: 'table-col-40', sort: true },
  email: { name: 'Email', dataLabel: 'Email', class: 'table-col-40', sort: true },
  action: { name: 'Action', class: 'table-col-20 text-center', actionType: 'radio' },
};

const usersConfigObj = {
  userName: { name: 'Name', dataLabel: 'Title', class: 'table-col-40', sort: true },
  email: { name: 'Email', dataLabel: 'Email', class: 'table-col-40', sort: true },
  action: { name: '', class: 'table-col-20 text-center checkbox-column', actionType: 'checkbox', onClick: (allSelected?: boolean) => {} },
};

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  activeTab?: string;
  users?: any;
  userType: string;
  addedGroup?: boolean;
  selectedTeam?: any;
  location: LocationDescriptorObject;
  updateGroupMembers: (members: Array<[string]>) => void;
  updateSelectedOrganization: (organization: string) => void;
}

interface IState {
  activePage: number;
  activeTab: string;
  items: number;
  tableContent: typeof usersConfigObj | typeof organizationConfigObj;
  title: string;
  users: {
    count: number;
    list: Array<[]>;
    fetchedUsersListing: boolean;
  };
  sort: string;
  sortBy: number;
}

export default class Listing extends Component<IProps, IState> {
  public static getDerivedStateFromProps(props: IProps, state: IState) {
    const newState = {};
    if (props.userType === staticConstants.MESSAGE_BOARD_TAB.ORGANIZATIONS) {
      newState['activeTab'] = staticConstants.MESSAGE_BOARD_TAB.ORGANIZATIONS;
      newState['tableContent'] = organizationConfigObj;
    } else if (props.userType !== staticConstants.MESSAGE_BOARD_TAB.ORGANIZATIONS) {
      newState['activeTab'] = state.activeTab !== staticConstants.MESSAGE_BOARD_TAB.ORGANIZATIONS ? state.activeTab : staticConstants.MESSAGE_BOARD_TAB.USERS.EXPERT;
      newState['tableContent'] = usersConfigObj;
    }
    return newState;
  }

  public selectedOrganization = null;
  public selectedGroupMembers = [];

  constructor(props: IProps) {
    super(props);
    this.state = {
      activePage: 1,
      activeTab: staticConstants.MESSAGE_BOARD_TAB.ORGANIZATIONS,
      items: tableItemsCount,
      tableContent: organizationConfigObj,
      title: '',
      users: {
        count: 0,
        list: [],
        fetchedUsersListing: false,
      },
      sort: '',
      sortBy: 0,
    };
  }

  public componentDidMount() {
    const { items, activePage } = this.state;
    const reqObj = {
      page: Number(activePage),
      limit: items,
    };
    const updateReqObj = this.updateReqObj(reqObj);
    this.props.dispatch(userAction.getUsers(updateReqObj));
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.userType !== this.props.userType) {
      this.setState({ title: '', sort: '', sortBy: 0, activePage: 1 });
      this.fetchUsersData(1);
      this.selectedGroupMembers = [];
      this.selectedOrganization = null;
    }
    if (this.selectedOrganization !== null && prevProps.selectedTeam === null) {
      this.selectedGroupMembers = [];
      this.selectedOrganization = null;
    }
    if (prevState.activeTab !== this.state.activeTab) {
      this.setState({ title: '', sort: '', sortBy: 0, activePage: 1 });
      this.fetchUsersData(1);
    }
    if (prevProps.addedGroup !== this.props.addedGroup && this.props.addedGroup) this.selectedGroupMembers = [];
  }

  /**
   * @description updateReqObj to update object before sending to the server
   */
  public updateReqObj(reqObj: any) {
    reqObj.type = getUsersRequestTypes[this.state.activeTab];
    reqObj.isUserManagment = this.state.activeTab !== staticConstants.MESSAGE_BOARD_TAB.USERS.ORGANIZATION_LEARNER;
    reqObj.searchKey = this.state.title;
    reqObj.status = 'active';
    reqObj.isPagination = true;
    return reqObj;
  }

  /**
   * @description fetchUsersData is used on initial load
   */
  public fetchUsersData(pageno: number) {
    const { sort, sortBy } = this.state;
    let reqObj = {
      page: Number(pageno),
      limit: this.state.items,
    };
    if (sort) {
      reqObj = { ...reqObj, ...{ sort, sortBy } };
    }
    const updateReqObj = this.updateReqObj(reqObj);
    this.props.dispatch(userAction.getUsers(updateReqObj));
  }

  /**
   * @description
   * handleFilterChange is used to set the value on state from the input.
   * @param fields {Object} || {String} value
   * @param fields {String} key
   */
  public handleFilterChange = (value: any, key: string) => {
    this.setState({ ...this.state, [key]: value, activePage: 1 }, () => this.fetchUsersData(1));
  }

  /**
   * @description
   * handlePageChange is called someone click on the pagination.
   * @param fields {Number} pageno
   */
  public handlePageChange = (pageno: number) => {
    this.setState({ activePage: pageno }, () => this.fetchUsersData(pageno));
  }

  /**
   * @description
   * sortData is used for sorting purpose.
   * @param fields {Number} sort
   * @param fields {String} sortBy
   */
  public sortData = (sort: string, sortBy: number) => {
    const { activePage } = this.state;
    this.setState({ ...this.state, ...{ sort, sortBy } }, () => this.fetchUsersData(activePage));
  }

  /**
   * @description
   * updateStatus is used to update use status
   * @param fields {Object} user
   * @param fields {Object} event
   */
  public updateActiveChatRoom = (user: ILocalUser, event) => {
    const isChecked = event.target.checked;
    const { activeTab } = this.state;
    if (activeTab === staticConstants.MESSAGE_BOARD_TAB.ORGANIZATIONS) {
      this.selectedOrganization = isChecked ? user : null;
      this.props.updateSelectedOrganization(this.selectedOrganization);
    } else this.updateSelectGroupMembers(user, isChecked);
  }

  /**
   * @description
   * updateSelectGroupMembers is used to select users(expert, professional, org learners) for a group
   * @param {object} user : containing details of current selected user
   * @param {boolean} isChecked status of current selected user
   */
  public updateSelectGroupMembers = (user: ILocalUser, isChecked: boolean) => {
    this.selectedGroupMembers = this.selectedGroupMembers.filter(member => member !== user._id);
    if (isChecked) this.selectedGroupMembers.push(user._id);
    this.props.updateGroupMembers(this.selectedGroupMembers);
  }

  /**
   * @description
   * selectAllMembersForGroup is used to select all users(expert, professional, org learners) for a group
   * @param {boolean} allSelected
   */
  public selectAllMembersForGroup = (allSelected: boolean) => {
    if (allSelected) {
      for (let i = 0; i < this.props.users.list.length; i++) {
        if (!(this.selectedGroupMembers.includes(this.props.users.list[i]._id))) {
          this.selectedGroupMembers.push(this.props.users.list[i]._id)
        }
      }
    } else {
      for (let i = 0; i < this.props.users.list.length; i++) {
        const index = this.selectedGroupMembers.indexOf(this.props.users.list[i]._id);
        if (index > -1) {
          this.selectedGroupMembers.splice(index, 1);
        }
      }
    }
    this.props.updateGroupMembers(this.selectedGroupMembers);
  }

  public render() {
    const { userType } = this.props;
    const { activeTab, activePage, sort, sortBy, tableContent } = this.state;
    const filterObj = { activePage, sort, sortBy };
    let tableHeaders = tableContent as typeof usersConfigObj;
    if(activeTab !== staticConstants.MESSAGE_BOARD_TAB.ORGANIZATIONS){
      tableHeaders.action.onClick = this.selectAllMembersForGroup;
    }
    return (
      <>
        <div className="filter-wrapper">
          <FilterComponent
            filterContent={['search']}
            handleFilter={this.handleFilterChange}
            placeholder="Search by Names"
          />
        </div>
        {userType !== staticConstants.MESSAGE_BOARD_TAB.ORGANIZATIONS &&
          <div className="tab-btn-group multi-tab-buttons">
            <Button
              color="secondary"
              className={`btn h-50 tab-btn text-uppercase ${activeTab === staticConstants.MESSAGE_BOARD_TAB.USERS.EXPERT ? CSS_ClASSES.btnActive : ''}`}
              onClick={() => this.setState({ activeTab: staticConstants.MESSAGE_BOARD_TAB.USERS.EXPERT, tableContent: usersConfigObj, sort: '', sortBy: 0 })}>
              Experts
            </Button>
            <Button
              color="secondary"
              className={`btn h-50 tab-btn text-uppercase ${activeTab === staticConstants.MESSAGE_BOARD_TAB.USERS.PROFESSIONAL ? CSS_ClASSES.btnActive : ''}`}
              onClick={() => this.setState({ activeTab: staticConstants.MESSAGE_BOARD_TAB.USERS.PROFESSIONAL, tableContent: usersConfigObj, sort: '', sortBy: 0 })}
            >
              Individual
            </Button>
            <Button
              color="secondary"
              className={`btn h-50 tab-btn text-uppercase ${activeTab === staticConstants.MESSAGE_BOARD_TAB.USERS.ORGANIZATION_LEARNER ? CSS_ClASSES.btnActive : ''}`}
              onClick={() => this.setState({ activeTab: staticConstants.MESSAGE_BOARD_TAB.USERS.ORGANIZATION_LEARNER, tableContent: usersConfigObj, sort: '', sortBy: 0 })}
            >
              Team Member
            </Button>
          </div>
        }

        <ListingContainer
          tableContent={tableHeaders}
          listData={this.props.users}
          tableHeaderClasses={'table-head'}
          handlePageChange={this.handlePageChange}
          handleSorting={this.sortData}
          filterObj={filterObj}
          activeTab={activeTab}
          updateStatus={this.updateActiveChatRoom}
          selectedUsers={this.selectedGroupMembers}
          selectedOrganization={this.selectedOrganization}
        />
      </>
    );
  }
}

function mapStateToProps(state: { users: any; chatMessages: { addedGroup: boolean } }) {
  const { users, chatMessages: { addedGroup } } = state;
  return {
    users,
    addedGroup,
  };
}

const listingContainer = connect(mapStateToProps)(Listing);
export { listingContainer as UserListingContainer };
