import React, { Dispatch } from 'react';
import { map } from 'lodash';
import queryString from 'query-string';
import {
  Button,
  TabContent,
  TabPane,
  Nav,
} from 'reactstrap';
import classnames from 'classnames';
import { pageRoutes } from '../../../utils/routeConstants';
import { history } from './../../../helpers/';
import { Sidebar } from '../../shared/sidebar';
import lockIcon from './../../../assets/images/icons/key.svg';
import emailNotify from './../../../assets/images/icons/icon-email-notify.svg';
import { getNavToEditProfile } from '../../../utils/handleNavigation';
import { profileAction } from './redux/actions';
import OpenFieldTypes from 'OpenFieldTypes';
import { connect } from 'react-redux';
import { IViewProfile, ISubscriptionInfo, ILocalUser } from '../../../interfaces/index';
import { BillingInfo } from './BillingInfo';
import { ListingContainer } from '../../shared/paymentCard/listings/listing';
import { learnerAction } from '../../shared/learnerManagement/redux/actions';
import { ViewOrgAdminProfile } from './ViewOrgAdminProfile';
import { ProfileSettingPage } from '../../shared/viewProfile/profileSettingPage';
import { ChangePasswordForm } from './ChangePassword';
import { ChangeEmailForm } from './ChangeEmail';
import { LocationDescriptorObject } from 'history';
import { staticConstants, MAGIC_NUMBER } from '../../../utils';
import { Back } from '../../shared/back/back';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  viewProfile: IViewProfile;
  location: LocationDescriptorObject;
  history: any;
  loginLink: string;
  subscriptionHistory: ISubscriptionInfo[];
  learnerList: any;
  user: ILocalUser;
  match?: any;
}

interface IState {
  activeTab: string;
  isStripeConnection: boolean;
  isAssignedLicences: boolean;
  isPlanHistory: boolean;
  clearPasswordData: boolean;
  clearEmailData: boolean;
}

enum tabs {
  PROFILE = '1',
  PASSWORD = '2',
  BILLING = '3',
  SETTINGS = '4',
}

class ViewProfilePage extends React.Component<IProps, IState> {

  /**
   * @description getDerivedStateFromProps is called when there in change in property
   * @param fields {Object} props
   */
  public static getDerivedStateFromProps(props: IProps) {
    if (props.loginLink) {
      window.open(props.loginLink);
      props.dispatch(profileAction.resetPaymentLoginLink());
    }
    return null;
  }

  constructor(props: IProps) {
    super(props);
    this.toggle = this.toggle.bind(this);
    const hash = this.props.location.hash;
    let activeTab = String(tabs.PROFILE);
    if (hash) {
      const num = Number(hash.replace('#', ''));
      if (num && num > MAGIC_NUMBER.ZERO && num < MAGIC_NUMBER.FIVE) {
        activeTab = String(num);
      }
    }
    this.state = {
      activeTab,
      isStripeConnection: false,
      isAssignedLicences: false,
      isPlanHistory: false,
      clearPasswordData: false,
      clearEmailData: false,
    };
  }

  public componentDidMount = () => {
    const { dispatch, match, user, location } = this.props;
    const params = queryString.parse(location.search);
    const reqObj = {
      userId: match && match.params['id'],
    };
    if (match && match.params['id'] && user.role === staticConstants.ROLE.SYSTEM_ADMIN) {
      dispatch(profileAction.getProfile(match.params['id']));
      dispatch(profileAction.getSubscriptionHistory(reqObj));
      dispatch(learnerAction.getLearner(reqObj));
    } else {
      dispatch(profileAction.getProfile());
      dispatch(profileAction.getSubscriptionHistory(reqObj));
      dispatch(learnerAction.getLearner({
        isPagination: false,
      }));
    }

    if (params.code) {
      setTimeout(() => {
        dispatch(profileAction.updatePaymentAccountID({
          authorizationCode: params.code,
        }));
        this.props.history.replace(`/${this.props.user.role}/viewProfile`);
      },         0);
    }

  }

  public toggle(tab: string) {
    if (this.state.activeTab !== tab) {
      this.props.history.replace(`#${tab}`);
      this.setState({
        activeTab: tab,
        clearPasswordData: true,
        clearEmailData: true,
      });
    }
  }

  /**
   * @description function to render tag list
   */
  public renderTagList = () => {
    const { viewProfile } = this.props;
    if (viewProfile && viewProfile.tags) {
      return map(viewProfile.tags, (tag: { id: string; name: string }) => {
        return tag.id && (
          <em key={tag.id} className="tags-view-only">{tag.name}</em>
        );
      });
    }
    return '';
  }

  public navToEditProfile = () => {
    history.push({
      pathname: getNavToEditProfile(),
      state: {
        redirectUri: this.props.location['pathname'],
        showSidebarNav: true,
        breadcrumb: [{ title: 'Edit Profile', link: null }],
      },
    });
  }

  /**
   * @description moveToAddCardPage used to move add new card page
   */
  public moveToAddCardPage = () => {
    history.push(`${pageRoutes.PAYMENT.ADD_CARD}`);
  }

  public render() {
    const { viewProfile, subscriptionHistory, learnerList, user, match } = this.props;
    const userId = match && match.params['id'];
    return (
      <>
        <Sidebar />
        <div className="dashboard-wrapper">
          {user.role === staticConstants.ROLE.SYSTEM_ADMIN ?
            <Back {...this.props} />
            :
            <h2 className="heading heading-sm roboto-medium text-uppercase">User Information</h2>
          }
          <div className="dashboard-content">
            <div className="tab-btn-group">
              <Nav tabs className="border-0">
                <Button
                  color="secondary"
                  className={`btn h-50 tab-btn text-uppercase ${classnames({ 'btn-active': this.state.activeTab === '1' })} ${(user.role === staticConstants.ROLE.SYSTEM_ADMIN) ? 'btn-50' : 'btn-20'}`}
                  onClick={() => { this.toggle('1'); }}
                >
                  Profile
                </Button>

                {user.role !== staticConstants.ROLE.SYSTEM_ADMIN &&
                  <Button
                    color="secondary"
                    className={`btn btn-20 h-50 tab-btn text-uppercase ${classnames({ 'btn-active': this.state.activeTab === '2' })}`}
                    onClick={() => { this.toggle('2'); }}
                  >
                    Password
                </Button>
                }
                {user.role !== staticConstants.ROLE.SYSTEM_ADMIN &&
                  <Button
                    color="secondary"
                    className={`btn btn-20 h-50 tab-btn text-uppercase ${classnames({ 'btn-active': this.state.activeTab === '5' })}`}
                    onClick={() => { this.toggle('5'); }}
                  >
                    Email
                </Button>
                }
                <Button
                  color="secondary"
                  className={`btn h-50 tab-btn text-uppercase ${classnames({ 'btn-active': this.state.activeTab === '3' })} ${(user.role === staticConstants.ROLE.SYSTEM_ADMIN) ? 'btn-50' : 'btn-20'}`}
                  onClick={() => { this.toggle('3'); }}
                >
                  Billing
                </Button>
                {user.role !== staticConstants.ROLE.SYSTEM_ADMIN &&
                  <Button
                    color="secondary"
                    className={`btn btn-20 h-50 tab-btn text-uppercase ${classnames({ 'btn-active': this.state.activeTab === '4' })}`}
                    onClick={() => { this.toggle('4'); }}
                  >Settings
                </Button>
                }
              </Nav>
            </div>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <div className="dashboard-content shadow white-bg p-0 m-0">
                  <ViewOrgAdminProfile {...this.props} userRole={this.props.user.role} viewProfile={viewProfile} />
                  {
                    user.role !== staticConstants.ROLE.SYSTEM_ADMIN &&
                    <div className="tab-panel-wrapper white-bg">
                      <div className="card-footer d-flex align-items-center justify-content-end">

                        <Button onClick={this.navToEditProfile} className="btn btn-primary btn-rg">
                          Edit
                        </Button>
                      </div>
                    </div>}
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="tab-panel-wrapper white-bg box-shadow">
                  <div className="profile-password-wrapper">
                    <h2 className="heading heading-rg roboto-medium">
                      Change Password
                    </h2>
                    <ChangePasswordForm {...this.props} toggle={this.toggle}
                      clearData={this.state.clearPasswordData} />
                    <div className="lock-box">
                      <div className="key-circle">
                        <img src={lockIcon} alt="key" />
                        <p>It's a good idea to use a strong password that you don't use elsewhere.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="5">
                <div className="tab-panel-wrapper white-bg box-shadow">
                  <div className="profile-password-wrapper">
                    <h2 className="heading heading-rg roboto-medium">
                      Change Email
                    </h2>
                    {<ChangeEmailForm {...this.props} toggle={this.toggle} clearData={this.state.clearEmailData}/>}
                    <div className="lock-box">
                      <div className="key-circle">
                        <img src={emailNotify} alt="key" />
                        <p>Email is used to notify user of all relevant account and billing activity.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="3">
                {viewProfile ?
                  <BillingInfo
                    {...this.props}
                    viewProfile={viewProfile}
                    subscriptionHistory={subscriptionHistory}
                    learnerList={learnerList}
                    role={viewProfile.role}
                    loggedInUserRole={this.props.user.role}
                    userId={userId}
                  /> :
                  <p className="text-center table-no-data pb-3 pt-3">
                    No Results Found.
                  </p>
                }
              </TabPane>
              <TabPane tabId="4">
                <ProfileSettingPage viewProfile={viewProfile} />
                <div className="tab-panel-wrapper white-bg box-shadow mt-3">
                  <div className="card-data">
                    <h2 className="heading heading-rg roboto-medium collapse-header">Saved Cards</h2>
                    <ListingContainer />
                    <div className="card-footer d-flex align-items-center justify-content-end">
                      <Button color="primary" className="btn btn-rg" onClick={this.moveToAddCardPage}>Add New Card</Button>
                    </div>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state: any) {
  const { updateProfile, learner, authentication: { user } } = state;
  return {
    viewProfile: updateProfile['profile'],
    loginLink: updateProfile['loginLink'],
    subscriptionHistory: updateProfile['subscriptionHistory'],
    learnerList: learner.list,
    user,
  };

}
const connectedViewProfilePageDetail = connect(mapStateToProps)(ViewProfilePage);
export { connectedViewProfilePageDetail as ViewProfilePage };
