import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Button,
} from 'reactstrap';
import { LocationDescriptorObject } from 'history';
import queryString from 'query-string';
import { staticConstants, CSS_ClASSES } from '../../../utils';
import { Sidebar } from '../../shared/sidebar';
import OpenFieldTypes from 'OpenFieldTypes';
import { ContentListingContainer } from './listings/listingContainer';
import { history } from '../../../helpers';

interface IProps {
  isOpenModal: boolean;
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  location: LocationDescriptorObject;
  role: string;
}

class ContentManagementContainer extends React.Component<IProps> {

  public static getDerivedStateFromProps(props: IProps) {
    const state = {};
    const { location: { search } } = props;
    if (search) state['activeTab'] = queryString.parse(search).tab || staticConstants.CONTENT_MANAGEMENT_TAB.ABOUT_US;
    else history.replace({ search: `?tab=${staticConstants.CONTENT_MANAGEMENT_TAB.ABOUT_US}` });
    return state;
  }

  public state = {
    activeTab: staticConstants.CONTENT_MANAGEMENT_TAB.ABOUT_US,
    isOpenModal: false,
    role: staticConstants.ROLE.SYSTEM_ADMIN,
    modalTitle: '',
  };

  public handleActiveTabs = (activeTab: string) => {
    history.push({ search: `?tab=${activeTab}` });
    this.setState({
      activeTab,
      role: staticConstants.ROLE[activeTab],
    });
  }

  /**
   * @description
   * render is used to render the html.
   */
  public render() {
    const { activeTab } = this.state;
    return (
      <React.Fragment>
        <Sidebar {...this.props} />
        <div className="dashboard-wrapper">
          <h2 className="heading heading-sm roboto-medium text-uppercase">Content Management</h2>
          <div className="dashboard-content system-admin-user-management">
            <div className="tab-btn-group">
              <Button
                color="secondary"
                className={`btn btn-25 h-50 tab-btn text-uppercase ${activeTab === staticConstants.CONTENT_MANAGEMENT_TAB.ABOUT_US ? CSS_ClASSES.btnActive : ''}`}
                onClick={() => this.handleActiveTabs(staticConstants.CONTENT_MANAGEMENT_TAB.ABOUT_US)}
              >
                About Us
                  </Button>
              <Button
                color="secondary"
                className={`btn btn-25 h-50 tab-btn ${activeTab === staticConstants.CONTENT_MANAGEMENT_TAB.FAQ ? CSS_ClASSES.btnActive : ''}`}
                onClick={() => this.handleActiveTabs(staticConstants.CONTENT_MANAGEMENT_TAB.FAQ)}
              >
                FAQs
                  </Button>
              <Button
                color="secondary"
                className={`btn btn-25 h-50 tab-btn text-uppercase ${activeTab === staticConstants.CONTENT_MANAGEMENT_TAB.TERMS_AND_CONDITIONS ? CSS_ClASSES.btnActive : ''}`}
                onClick={() => this.handleActiveTabs(staticConstants.CONTENT_MANAGEMENT_TAB.TERMS_AND_CONDITIONS)}
              >
                Terms of Use
                  </Button>
              <Button
                color="secondary"
                className={`btn btn-25 h-50 tab-btn text-uppercase ${activeTab === staticConstants.CONTENT_MANAGEMENT_TAB.PRIVACY_POLICY ? CSS_ClASSES.btnActive : ''}`}
                onClick={() => this.handleActiveTabs(staticConstants.CONTENT_MANAGEMENT_TAB.PRIVACY_POLICY)}
              >
                Privacy Policy
                  </Button>
            </div>
            <ContentListingContainer {...this.props} activeTab={activeTab} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  return {};
}

const connectedContentManagementContainer = connect(mapStateToProps)(ContentManagementContainer);
export { connectedContentManagementContainer as ContentManagementContainer };
