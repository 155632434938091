import moment from 'moment';
import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { pageRoutes } from './../../../../utils/routeConstants';
import OpenFieldTypes from 'OpenFieldTypes';
import { UncontrolledTooltip } from 'reactstrap';
import { IGoalDetailWithId } from './../../../../interfaces';
import { history } from '../../../../helpers';
import { getGoalStatusText, MAGIC_NUMBER, ACTION_CLASSES } from './../../../../utils';
import { staticConstants, goalStatus } from '../../../../utils/staticConstants';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  goal: IGoalDetailWithId;
  sendRFP?: any;
  sendForApproval?: any;
  user?: any;
  isFromRequestListPage?: any;
  updateGoalStatus?: any;
  activeTab?: any;
  isGoalsCreatedMyLerner?: any;
  from?: string;
  deleteGoal(id: string): void;
}

class GoalItem extends React.Component<IProps> {

  public renderDate(val: boolean) {
    const { statusTimeline, status, createdAt } = this.props.goal;
    const { user: { role }, isFromRequestListPage, activeTab } = this.props;
    let date = '', filterDates = [];
    if ((role === staticConstants.ROLE.ORGANIZATION_ADMIN && !isFromRequestListPage)
      || role === staticConstants.ROLE.PROFESSIONAL) {
      date = moment(createdAt).format(staticConstants.DATE_FORMAT);
    } else {
      if (activeTab === goalStatus.NEW_REQUEST) {
        date = moment(createdAt).format(staticConstants.DATE_FORMAT);
      } else {
        if (statusTimeline) {
          filterDates = statusTimeline.filter((timeline: any) => {
            return timeline['status'] === status;
          });
        }
        if (filterDates.length > MAGIC_NUMBER.ZERO) {
          date = moment(filterDates[0]['createdAt']).format(staticConstants.DATE_FORMAT);
        }
      }
    }
    return date;
  }

  public redirectEventDetail(event: any, goal: any, role: string) {
    if (ACTION_CLASSES.indexOf(event.target.className) === MAGIC_NUMBER.FOUND_INDEX && [staticConstants.ROLE.PROFESSIONAL, staticConstants.ROLE.ORGANIZATION_ADMIN,
    staticConstants.ROLE.PROFESSIONAL, staticConstants.ROLE.ORGANIZATION_LEARNER].indexOf(role) !== -1) {
      history.push({
        pathname: `${pageRoutes.GOALMANAGEMENT.PATH}${pageRoutes.GOALMANAGEMENT.DETAIL}/${goal._id}`,
        state: {},
      });
    }
  }

  /**
   * @description
   * render is used to render the html.
   * */
  public render() {
    const { goal, deleteGoal, sendRFP, updateGoalStatus, sendForApproval, activeTab } = this.props;
    const { role } = this.props.user;
    const participants = goal.participants.length;
    const dataDataLabel = activeTab === goalStatus.ACCEPT ? 'Goal Accepted'
      : (activeTab === goalStatus.REJECT ? 'Goal Rejected' : 'Goal Created');
    return (
      <div className='table-row' onClick={(event) => this.redirectEventDetail(event, goal, role)} >
        {role === staticConstants.ROLE.PROFESSIONAL &&
          <React.Fragment>
            <div className="table-col table-col-17" data-label="Title">
              <span className="roboto-medium link-btn text-truncate">
                {goal.title}
              </span>
            </div>
            <div className="table-col table-col-10" data-label="Event Type">
              <span>{goal.eventType}</span>
            </div>
            <div className="table-col table-col-15" data-label="Domain">
              <span>{goal.domain.name}</span>
            </div>
            <div className="table-col table-col-13" data-label="Sub-Domain">
              <span>{goal.subDomain.name}</span>
            </div>
            <div className="table-col table-col-12 sm-50" data-label="Goal Created">
              <span>{this.renderDate(false)}</span>
            </div>

            <div className="table-col table-col-10 sm-50" data-label="Status">
              <span>{getGoalStatusText(goal.status)}</span>
            </div>
            {role === staticConstants.ROLE.PROFESSIONAL && (
              <div className="table-col table-col-10 text-center p-0 action-col">
                {goal.status === goalStatus.CREATED &&
                  <React.Fragment>
                    <em className="icon icon-send" id={`tooltip_send_${goal._id}`} onClick={() => sendRFP(goal._id)} />
                    <UncontrolledTooltip placement="top" target={`tooltip_send_${goal._id}`} >Send</UncontrolledTooltip>
                    <Link to={`${pageRoutes.GOALMANAGEMENT.PATH}${pageRoutes.GOALMANAGEMENT.EDIT}/${goal._id}`}>
                      <em className="icon icon-edit" id={`tooltip_edit_${goal._id}`} />
                      <UncontrolledTooltip placement="top" target={`tooltip_edit_${goal._id}`} >Edit</UncontrolledTooltip>
                    </Link>
                  </React.Fragment>
                }
                <em className="icon icon-delete" id={`tooltip_delete_${goal._id}`} onClick={() => deleteGoal(goal._id)} />
                <UncontrolledTooltip placement="top" target={`tooltip_delete_${goal._id}`} >Delete</UncontrolledTooltip>
              </div>
            )}
          </React.Fragment>
        }
        {
          role === staticConstants.ROLE.ORGANIZATION_ADMIN &&
          <React.Fragment>
            <div className="table-col table-col-17" data-label="Title">
              <span className="roboto-medium link-btn text-truncate">
                {goal.title}
              </span>
            </div>
            <div className="table-col table-col-10" data-label="Event Type">
              <span>{goal.eventType}</span>
            </div>
            <div className="table-col table-col-15" data-label="Domain">
              <span>{goal.domain.name}</span>
            </div>
            <div className="table-col table-col-13" data-label="Sub-Domain">
              <span>{goal.subDomain.name}</span>
            </div>
            {!this.props.isFromRequestListPage &&
              <div className="table-col table-col-12 sm-50" data-label="Goal Created">
                <span>{this.renderDate(this.props.isFromRequestListPage)}</span>
              </div>
            }
            {this.props.isFromRequestListPage &&
              <div className="table-col table-col-12 sm-50" data-label={dataDataLabel}>
                <span>{this.renderDate(this.props.isFromRequestListPage)}</span>
              </div>
            }
            {this.props.isFromRequestListPage && this.props.from !== 'learnerManagement' &&
              <>
                <div className="table-col table-col-10 sm-50" data-label="Created By">
                  <span>{goal.userId && goal.userId.firstName} {goal.userId && goal.userId.lastName} </span>
                </div>
              </>
            }
            <div className="table-col table-col-13 sm-50 text-center" data-label="Participants">
              <span>{participants}</span>
            </div>
            {!this.props.isFromRequestListPage &&
              <div className="table-col table-col-10 sm-50" data-label="Status">
                <span>{getGoalStatusText(goal.status)}</span>
              </div>
            }
            {this.props.isFromRequestListPage && activeTab === goalStatus.ACCEPT &&
              <div className="table-col table-col-10 sm-50" data-label="Requests Status">
                <span>{getGoalStatusText(goal.status)}</span>
              </div>
            }
            {role === staticConstants.ROLE.ORGANIZATION_ADMIN && !this.props.isFromRequestListPage && (
              <div className="table-col table-col-10 text-center p-0 action-col">
                {goal.status === goalStatus.CREATED && participants > MAGIC_NUMBER.ZERO &&
                  <>
                    <em className="icon icon-send" onClick={() => sendRFP(goal._id)} id={`tooltip_send_${goal._id}`} />
                    <UncontrolledTooltip placement="top" target={`tooltip_send_${goal._id}`}>Send</UncontrolledTooltip>
                    <Link to={`${pageRoutes.GOALMANAGEMENT.PATH}${pageRoutes.GOALMANAGEMENT.EDIT}/${goal._id}`}>
                      <em className="icon icon-edit" id={`tooltip_edit_${goal._id}`} />
                      <UncontrolledTooltip placement="top" target={`tooltip_edit_${goal._id}`}>Edit</UncontrolledTooltip>
                    </Link>
                  </>
                }
                {(!goal.bid || goal.status === goalStatus.CREATED) &&
                  <><em className="icon icon-delete" id={`tooltip_delete_${goal._id}`} onClick={() => deleteGoal(goal._id)} />
                    <UncontrolledTooltip placement="top" target={`tooltip_delete_${goal._id}`}>Delete</UncontrolledTooltip>
                  </>
                }

              </div >
            )}
            {role === staticConstants.ROLE.ORGANIZATION_ADMIN && this.props.isFromRequestListPage && (
              <div className="table-col table-col-10 text-center p-0 action-col">
                {goal.status === goalStatus.REQUEST_SEND_BY_LERNER &&
                  <>
                    <Link to={`${pageRoutes.GOALMANAGEMENT.PATH}${pageRoutes.GOALMANAGEMENT.EDIT}/${goal._id}`}>
                      <em className="icon icon-edit" />
                    </Link>
                    <em className="icon icon-accept success" id={`tooltip_accept_${goal._id}`} onClick={() => updateGoalStatus(goal._id, goalStatus.ACCEPT)} />
                    <UncontrolledTooltip placement="top" target={`tooltip_accept_${goal._id}`} >Accept</UncontrolledTooltip>
                    <em className="icon icon-cross danger" id={`tooltip_reject_${goal._id}`} onClick={() => updateGoalStatus(goal._id, goalStatus.REJECT)} />
                    <UncontrolledTooltip placement="top" target={`tooltip_reject_${goal._id}`} >Reject</UncontrolledTooltip>
                  </>
                }
                {goal.status === goalStatus.RFP_REQUEST &&
                  <><em className="icon icon-delete" id={`tooltip_delete_${goal._id}`} onClick={() => deleteGoal(goal._id)} />
                    <UncontrolledTooltip placement="top" target={`tooltip_delete_${goal._id}`} >Delete</UncontrolledTooltip></>
                }
                {goal.status === goalStatus.ACCEPT &&
                  <>
                    <em className="icon icon-send" id={`tooltip_send_${goal._id}`} onClick={() => sendRFP(goal._id)} />
                    <UncontrolledTooltip placement="top" target={`tooltip_send_${goal._id}`} >Send</UncontrolledTooltip>
                    <em id={`tooltip_delete_${goal._id}`} className="icon icon-delete" onClick={() => deleteGoal(goal._id)} />
                    <UncontrolledTooltip placement="top" target={`tooltip_delete_${goal._id}`} >Delete</UncontrolledTooltip>
                  </>
                }
                {goal.status === goalStatus.REJECT &&
                  <>
                    <em className="icon icon-accept success" id={`tooltip_accept_${goal._id}`} onClick={() => updateGoalStatus(goal._id, goalStatus.ACCEPT)} />
                    <UncontrolledTooltip placement="top" target={`tooltip_accept_${goal._id}`} >Accept</UncontrolledTooltip>
                    <em className="icon icon-delete" id={`tooltip_delete_${goal._id}`} onClick={() => deleteGoal(goal._id)} />
                    <UncontrolledTooltip placement="top" target={`tooltip_delete_${goal._id}`} >Delete</UncontrolledTooltip>
                  </>
                }
              </div >
            )}
          </React.Fragment>
        }
        {
          role === staticConstants.ROLE.ORGANIZATION_LEARNER &&
          <>
            <div className="table-col table-col-17" data-label="Title">
              <span className="roboto-medium link-btn text-truncate">
                {goal.title}
              </span>
            </div>
            <div className="table-col table-col-10" data-label="Event Type">
              <span>{goal.eventType}</span>
            </div>
            <div className="table-col table-col-15" data-label="Domain">
              <span>{goal.domain.name}</span>
            </div>
            <div className="table-col table-col-13" data-label="Sub-Domain">
              <span>{goal.subDomain.name}</span>
            </div>
            <div className="table-col table-col-12 sm-50" data-label="Goal Created">
              <span>{this.renderDate(false)}</span>
            </div>
            <div className="table-col table-col-13 sm-50 text-center" data-label="Participants">
              <span>{participants}</span>
            </div>

            {activeTab !== goalStatus.REJECT &&
              <div className="table-col table-col-10 sm-50" data-label="Requests Status">
                <span>{getGoalStatusText(goal.status)}</span>
              </div>
            }

            {this.props.isGoalsCreatedMyLerner &&
              <>
                {activeTab === goalStatus.NEW_REQUEST &&
                  <div className="table-col table-col-10 text-center p-0 action-col">
                    {goal.status === goalStatus.CREATED &&
                      <>
                        {participants > MAGIC_NUMBER.ZERO ? (<>
                          <em className="icon icon-send" id={`tooltip_sendApp_${goal._id}`} onClick={() => sendForApproval(goal._id)} />
                          <UncontrolledTooltip placement="top" target={`tooltip_sendApp_${goal._id}`} >Send</UncontrolledTooltip>
                        </>) : ''}
                        <Link to={`${pageRoutes.GOALMANAGEMENT.PATH}${pageRoutes.GOALMANAGEMENT.EDIT}/${goal._id}`}>
                          <em className="icon icon-edit" id={`tooltip_edit_${goal._id}`} />
                          <UncontrolledTooltip placement="top" target={`tooltip_edit_${goal._id}`} >Edit</UncontrolledTooltip>
                        </Link>
                      </>
                    }
                    {(goal.status === goalStatus.CREATED) &&
                      <><em className="icon icon-delete" id={`tooltip_delete_${goal._id}`} onClick={() => deleteGoal(goal._id)} />
                        <UncontrolledTooltip placement="top" target={`tooltip_delete_${goal._id}`} >Delete</UncontrolledTooltip></>
                    }
                  </div >
                }
                {activeTab === goalStatus.REJECT &&
                  <div className="table-col table-col-10 text-center p-0 action-col">
                    {goal.status === goalStatus.CREATED &&
                      <>
                        {participants > MAGIC_NUMBER.ZERO ? <>
                          <em className="icon icon-send" id={`tooltip_send_${goal._id}`} onClick={() => sendForApproval(goal._id)} />
                          <UncontrolledTooltip placement="top" target={`tooltip_send_${goal._id}`} >Send</UncontrolledTooltip>
                        </> : ''}
                        <Link to={`${pageRoutes.GOALMANAGEMENT.PATH}${pageRoutes.GOALMANAGEMENT.EDIT}/${goal._id}`}>
                          <em className="icon icon-edit" id={`tooltip_edit_${goal._id}`} />
                          <UncontrolledTooltip placement="top" target={`tooltip_edit_${goal._id}`} >Edit</UncontrolledTooltip>
                        </Link>
                      </>
                    }
                    {goal.status !== goalStatus.ACCEPT &&
                      <><em className="icon icon-delete" id={`tooltip_delete_${goal._id}`} onClick={() => deleteGoal(goal._id)} />
                        <UncontrolledTooltip placement="top" target={`tooltip_delete_${goal._id}`} >Delete</UncontrolledTooltip>
                      </>
                    }
                  </div >
                }
              </>
            }
          </>
        }
      </div >
    );
  }
}

function mapStateToProps(state: { authentication: { user: any } }) {
  const { user } = state.authentication;
  return {
    user,
  };

}

const goalItemPage = connect(mapStateToProps)(GoalItem);
export { goalItemPage as GoalItem };
