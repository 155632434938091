import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Modal,
  ModalBody,
} from 'reactstrap';
import OpenFieldTypes from 'OpenFieldTypes';
import addon_Image from '../../../assets/images/add-on.png';

interface IProps {
  dispatch?: Dispatch<OpenFieldTypes.RootAction>;
  isOpenModal: boolean;
  modalPopupObj: any;
  hideModal(): void;
  onClickYes(): void;
}

interface IState {
  modal: boolean;
}

class UnlockProfilePopup extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      modal: props.isOpenModal,
    };
    this.hideModal = this.hideModal.bind(this);
  }

  public hideModal() {
    this.setState({
      modal: false,
    });
    this.props.hideModal();
  }

  public render() {
    const { modal } = this.state;
    const { modalPopupObj } = this.props;
    function createMarkup() { return { __html: `${modalPopupObj.body}` }; }
    return (
      <Modal isOpen={modal} className="add-on-modal">
        <span className="close-btn icon icon-cross" onClick={this.hideModal}/>
        <ModalBody>
          <img src={addon_Image} alt="Add-on"/>
          <h2>{modalPopupObj.title}</h2>
          <p dangerouslySetInnerHTML={createMarkup()}/>
        </ModalBody>
        <div className="button-group">
          <Button color="primary" className={'btn mx-2 btn-primary'}
            onClick={this.props.onClickYes}>{modalPopupObj.btnText}</Button>
        </div>
      </Modal>
    );
  }
}

const connectedUnlockProfilePopupPage = connect()(UnlockProfilePopup);
export { connectedUnlockProfilePopupPage as UnlockProfilePopup };
