export let startLoading = (id: string, text: string) => {
  return {
    type: 'START_LOADING',
    id,
    text,
  };
};

export let endLoading = (id: string) => {
  return {
    type: 'END_LOADING',
    id,
  };
};

export const loaderAction = {
  startLoading,
  endLoading,
};
