import { BreadcrumbRender, Sidebar } from '../../shared';
import React, { createRef, Dispatch } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import OpenFieldTypes from 'OpenFieldTypes';
// tslint:disable-next-line:no-import-side-effect
import 'react-datepicker/dist/react-datepicker.css';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import ReeValidate from 'ree-validate';
import { history } from '../../../helpers';
import NumberFormat from 'react-number-format';
import {
  getNavToDashboard, reeValidators, locations, educations,
  reactSelectFilters, nameTitle, messages, MAGIC_NUMBER,
} from '../../../utils';
import { customSelectStyles, mapNameTitleFromApi } from './../../../utils/common';
import { pageRoutes } from '../../../utils/routeConstants';
import { Header } from '../../shared/header';
import profileImage from './../../../assets/images/profile.png';
import { validator } from './../../../helpers';
import { IUploadImgaeReq, IValidator, ILearnerProfile, IUpdateLearnerProfileReq } from './../../../interfaces';
import { learnerProfileAction } from './redux/actions';
import moment from 'moment';
import Select, { createFilter } from 'react-select';
import { ImageCropperPopup } from '../../shared/modals/imageCropperPopup';

validator(ReeValidate);

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  updateProfile: any;
  industries: [];
  location?: any;
}

interface IState {
  formData: ILearnerProfile;
  profileImageUpload: File;
  errors: any;
  isOpenCropModal: boolean;
  image: any;
}

class UpdateProfile extends React.Component<IProps, IState> {

  public validator: IValidator;
  private readonly fileInput = createRef<HTMLInputElement>();
  constructor(props: IProps) {
    super(props);
    this.validator = new ReeValidate({
      firstName: reeValidators.required_min_3_max_100,
      lastName: reeValidators.required_min_3_max_100,
      education: reeValidators.required_min_3_max_100,
      dateOfBirth: 'required',
      phoneNumber: 'required|min:5|max:15',
      occupation: reeValidators.required_min_3_max_100,
      position: reeValidators.min_3_max_100,
      location: 'required',
      employer: 'required',
      profileImageUpload: 'verify_profile_image|verify_profile_image_size',
      title: 'required',
    });

    this.state = {
      formData: {
        firstName: '',
        lastName: '',
        education: '',
        dateOfBirth: null,
        phoneNumber: '',
        occupation: '',
        position: '',
        location: null,
        address: '',
        title: null,
        employer: '',
        selectedLocation: null,
        selectedEducation: null,
      },
      profileImageUpload: null,
      errors: this.validator.errors,
      isOpenCropModal: false,
      image: null,
    };
  }

  public componentDidMount = () => {
    const { dispatch } = this.props;
    dispatch(learnerProfileAction.getProfile());
  }

  public componentWillReceiveProps(nextProps: {
    updateProfile: {
      profile: ILearnerProfile;
      updateProfile: boolean;
      uploadingImage: boolean;
    };
  }) {

    const newProfile = nextProps.updateProfile.profile;
    if (newProfile && !nextProps.updateProfile.uploadingImage && nextProps.updateProfile.updateProfile) {
      const formData = {
        firstName: newProfile.firstName,
        lastName: newProfile.lastName,
        education: newProfile.education,
        dateOfBirth: newProfile.dateOfBirth ? new Date(newProfile.dateOfBirth) : null,
        phoneNumber: newProfile.phoneNumber,
        occupation: newProfile.occupation,
        position: newProfile.position,
        location: newProfile.location,
        address: newProfile.address,
        // tags: mapTagsFromAPIRequest(newProfile.tags),
        title: mapNameTitleFromApi(newProfile.title) || null,
        // timeZone: newProfile.timeZone,
        employer: newProfile.employer,
        selectedLocation: newProfile.location ?
          { label: newProfile.location, value: newProfile.location } : null,
        selectedEducation: newProfile.education ?
          { label: newProfile.education, value: newProfile.education } : null,
      };
      this.setState({ formData });
    }
    this.hideModal();
  }

  public handleChange = (event: any, type: string = null) => {
    if (type) {
      switch (type) {
        case 'eventLocation':
          this.setState(
            {
              formData: {
                ...this.state.formData,
                location: event['value'],
                selectedLocation: {
                  value: event['value'],
                  label: event['label'],
                },
              },
            },
            () => {
              this.removeErrorAndReValidate('location');
            },
          );
          return;
        case 'eventEducation':
          this.setState(
            {
              formData: {
                ...this.state.formData,
                education: event['value'],
                selectedEducation: {
                  value: event['value'],
                  label: event['label'],
                },
              },
            },
            () => {
              this.removeErrorAndReValidate('education');
            },
          );
          return;
      }
    }
    const { name, value } = event.target;
    this.setState({ formData: { ...this.state.formData, [name]: value } }, () => {
      this.removeErrorAndReValidate(name);
    });

  }

  public handleTitleChange = (selectedOption) => {
    const { formData } = this.state;
    formData.title = selectedOption;
    this.setState({ formData }, () => this.removeErrorAndReValidate('title'));
  }

  public handleDOBChange = (date: Date) => {
    Object.assign(this.state, { formData: { ...this.state.formData, dateOfBirth: date } });
    this.removeErrorAndReValidate('dateOfBirth');
  }

  public handlePhoneChange = (num: { formattedValue: string }) => {
    this.setState(
      {
        formData: {
          ...this.state.formData,
          phoneNumber: num.formattedValue.replace('-', ''),
        },
      },
      () => {
        this.removeErrorAndReValidate('phoneNumber');
      },
    );
  }

  public moveToDashboardPage = () => {
    history.push(`${pageRoutes.ORGADMIN.PATH}${pageRoutes.ORGADMIN.DASHBOARD}`);
  }

  // public handleTimezoneChange = (timeZone: string) => {
  //   Object.assign(this.state, { formData: { ...this.state.formData, timeZone } });
  //   this.removeErrorAndReValidate('timeZone');
  // }

  public removeErrorAndReValidate = (name: string) => {
    const { errors } = this.validator;
    errors.remove(name);
    this.validator.validate(name, this.state.formData[name])
      .then(() => {
        this.setState({ errors });
      });
  }

  public validateAndSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const { errors } = this.validator;
    this.validator.validateAll(this.state.formData)
      .then((success: boolean) => {
        if (success) {
          this.handleSubmit();
        } else {
          this.setState({ errors });
        }
      });
  }

  public handleSubmit = () => {

    const { dispatch } = this.props;
    const formData = this.state.formData;
    const data: IUpdateLearnerProfileReq = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      education: formData.selectedEducation.value,
      dateOfBirth: formData.dateOfBirth.toDateString(),
      phoneNumber: formData.phoneNumber,
      occupation: formData.occupation,
      position: formData.position,
      location: formData.selectedLocation.value,
      address: formData.address,
      title: formData.title.value,
      employer: formData.employer,
    };
    dispatch(learnerProfileAction.updateLearnerProfile(data));
  }

  /* industry autosuggestions */
  public fetchIndustrySuggestions = () => {
    const { dispatch } = this.props;
    dispatch(learnerProfileAction.getIndustries());
  }

  public getIndustrySuggestionsValue = (suggestion: any) => {
    return suggestion;
  }

  public handleIndustryChange = (event, { newValue }) => {
    const formData = this.state.formData;
    formData['industry'] = newValue.subDomain || newValue;
    formData['industryId'] = newValue._id || '';
    this.setState({ formData });
    this.removeErrorAndReValidate('industry');
    this.removeErrorAndReValidate('industryId');

  }
  public renderSuggestion = suggestion => (
    <div>
      {suggestion.subDomain}
    </div>
  )

  public clearIndustrySuggestions = () => { };

  public fetchSectorSuggestions = () => {
    const { dispatch } = this.props;
    dispatch(learnerProfileAction.getCategories());
  }
  public getSectorSuggestionsValue = (suggestion: any) => {
    return suggestion;
  }
  public handleSectorChange = (event, { newValue }) => {
    const newValue1 = newValue.category || newValue;
    const formData = this.state.formData;
    formData['sector'] = newValue1;
    formData['sectorId'] = newValue._id || '';
    this.setState({ formData });
    this.removeErrorAndReValidate('sector');
    this.removeErrorAndReValidate('sectorId');
  }
  public renderSectorSuggestion = suggestion => (
    <div>
      {suggestion.category}
    </div>
  )

  public handleUploadImageSubmit = (e) => {
    e.preventDefault();
    const profileImageUpload = this.fileInput.current.files[0];
    const { errors } = this.validator;
    Object.assign(this.state, { profileImageUpload });
    errors.remove('profileImageUpload');
    this.validator.validate('profileImageUpload', this.state.profileImageUpload)
      .then((success: boolean) => {
        if (success) {
          this.setState({
            isOpenCropModal: true,
            image: profileImageUpload,
          });
        } else {
          this.setState({ errors });
        }
      });
  }

  public savedCroppedImage = (data) => {
    const up: IUploadImgaeReq = { type: 'image/png', upload: data };
    const { dispatch } = this.props;
    dispatch(learnerProfileAction.uploadProfileImage(up));
  }
  public hideModal = () => {
    this.setState({ isOpenCropModal: false });
  }
  public render() {
    const { formData, errors, isOpenCropModal, image } = this.state;
    const { updateProfile, location: { state } } = this.props;
    const getErrClass = (field: string) => {
      return errors.has(field) ? ' has-error' : '';
    };

    return (
      <>
        <Header />
        {state && state['showSidebarNav'] && <Sidebar {...this.props} />}
        <div className="dashboard-wrapper">
          <div className="create-profile-wrapper p-0 w-100">
            {state && state['breadcrumb'] ?

              <BreadcrumbRender breadcrumb={state['breadcrumb']} /> :
              <h2 className="heading heading-sm text-uppercase roboto-bold mb-4">
                Complete Profile</h2>}
            <div className="profile-wrapper dashboard-content">
              <Form noValidate className="profile-image-wrapper learner-profile-image">
                <span className="image-upload">
                  <img
                    src={updateProfile && updateProfile.profile &&
                      updateProfile.profile.profileImage ?
                      updateProfile.profile.profileImage : profileImage}
                    className="img-cover"
                    alt="profile"
                  />
                  <label className="file-icon icon-edit">
                    <input
                      ref={this.fileInput}
                      type="file"
                      name="profileImageUpload"
                      onChange={this.handleUploadImageSubmit}
                    />
                  </label>
                </span>
                <div className="hint-text">
                  <p>Image size should be maximum 5mb. <br />File format: jpeg, png.</p>
                </div>
                {
                  <div className="error-text">{errors.first('profileImageUpload')}</div>
                }

              </Form>
              <div className="basic-details">
                <h2>Team Member Details</h2>
                <Form onSubmit={this.validateAndSubmit}>
                  <div className="form-wrapper">
                    <Row>
                      <Col xs="12" sm="6">
                        <Row>
                          <Col xs="4">
                            <FormGroup className={`floating-label disabled-input ${getErrClass('title')}`}>
                              <Select
                                name="title"
                                styles={customSelectStyles}
                                value={formData.title}
                                onChange={this.handleTitleChange}
                                options={nameTitle}
                                id="title"
                                placeholder="Title"
                                className="react-select-box select-box-group"
                              />
                              <Label for="eventType" className={formData.title ? 'selected' : ''}>Title</Label>
                              {errors.has('title') &&
                                <div className="error-text">
                                  {
                                    errors.first('title')}
                                </div>
                              }
                            </FormGroup>
                          </Col>
                          <Col xs="8">
                            <FormGroup className={`floating-label ${getErrClass('firstName')}`}>
                              <Input
                                type="text"
                                className="form-control"
                                name="firstName"
                                id="firstName"
                                placeholder="First Name"
                                value={formData.firstName}
                                onChange={this.handleChange}
                              />
                              <Label for="firstName">First Name</Label>
                              {errors.has('firstName') &&
                                <div className="error-text">
                                  {
                                    errors.first('firstName').replace('firstName', 'first name')}
                                </div>
                              }
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs="12" sm="6">
                        <FormGroup className={`floating-label ${getErrClass('lastName')}`}>
                          <Input
                            type="text"
                            className="form-control"
                            name="lastName"
                            id="lastName"
                            placeholder="Last Name"
                            value={formData.lastName}
                            onChange={this.handleChange}
                          />
                          <Label for="lastname">Last Name</Label>
                          {errors.has('lastName') &&
                            <div className="error-text">
                              {
                                errors.first('lastName').replace('lastName', 'last name')}</div>
                          }
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="6">
                        <FormGroup className={`floating-label disabled-input ${getErrClass('education')}`}>
                          <Select
                            value={formData.selectedEducation}
                            onChange={e => this.handleChange(e, 'eventEducation')}
                            options={educations}
                            styles={customSelectStyles}
                            placeholder="Highest Level of Education"
                            className="react-select-box select-box-group"
                            filterOption={createFilter(reactSelectFilters)}
                          />

                          <Label for="education" className={formData.selectedEducation ? 'selected' : ''}>Highest Level of Education</Label>
                          {errors.has('education') &&
                            <div className="error-text">{errors.first('education')}</div>
                          }
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="6">
                        <FormGroup className={`floating-label disabled-input ${getErrClass('dateOfBirth')}`}>
                          <DatePicker
                            placeholderText="Date of Birth"
                            className="form-control"
                            dateFormat="MMM dd, yyyy"
                            onChange={this.handleDOBChange}
                            selected={formData.dateOfBirth}
                            maxDate={moment().toDate()}
                            dropdownMode="select"
                            showYearDropdown
                            showMonthDropdown
                            strictParsing
                          />

                          <Label for="dateOfBirth" className={formData.dateOfBirth ? 'selected' : ''}>Date of Birth</Label>
                          <span className="icon icon-calendar" />
                          {errors.has('dateOfBirth') &&
                            <div className="error-text">{
                              errors.first('dateOfBirth').replace('dateOfBirth', 'date of birth')}
                            </div>
                          }
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="6">
                        <FormGroup className="floating-label disabled-input disabled">
                          <label>Email</label>
                          <p>{updateProfile && updateProfile.profile &&
                            updateProfile.profile.email}</p>
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="6">
                        <FormGroup className={`floating-label ${getErrClass('phoneNumber')}`}>
                          <NumberFormat
                            prefix={'+'}
                            className="form-control"
                            name="phoneNumber"
                            mask=""
                            maxLength={MAGIC_NUMBER.FIFTEEN}
                            placeholder="Phone Number"
                            onValueChange={this.handlePhoneChange}
                            value={formData.phoneNumber}
                          />
                          <Label for="phoneNumber">Phone Number</Label>
                          {errors.has('phoneNumber') &&
                            <div className="error-text">
                              {messages.invalidPhoneNumber}
                            </div>
                          }
                        </FormGroup>
                      </Col>

                      <Col xs="12" sm="6">
                        <FormGroup className={`floating-label ${getErrClass('occupation')}`}>
                          <Input
                            type="text"
                            className="form-control"
                            name="occupation"
                            id="occupation"
                            placeholder="Occupation"
                            onChange={this.handleChange}
                            value={formData.occupation}
                          />
                          <Label for="occupation">Occupation</Label>
                          {errors.has('occupation') &&
                            <div className="error-text">{errors.first('occupation')}</div>
                          }
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="6">
                        <FormGroup className={`floating-label ${getErrClass('position')}`}>
                          <Input
                            type="text"
                            className="form-control"
                            name="position"
                            id="position"
                            placeholder="Position (Optional)"
                            onChange={this.handleChange}
                            value={formData.position}
                          />
                          <Label for="position">Position (Optional)</Label>
                          {errors.has('position') &&
                            <div className="error-text">{errors.first('position')}</div>
                          }
                        </FormGroup>
                      </Col>

                      <Col xs="12" sm="6">
                        <FormGroup className={`floating-label ${getErrClass('employer')}`}>
                          <Input
                            type="text"
                            className="form-control"
                            name="employer"
                            id="employer"
                            placeholder="Employer"
                            onChange={this.handleChange}
                            value={formData.employer}
                          />
                          <Label for="employer">Employer</Label>
                          {errors.has('employer') &&
                            <div className="error-text">{errors.first('employer')}</div>
                          }
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="6">
                        <FormGroup className={`floating-label disabled-input ${getErrClass('location')}`}>
                          <Select
                            value={formData.selectedLocation}
                            onChange={e => this.handleChange(e, 'eventLocation')}
                            options={locations}
                            styles={customSelectStyles}
                            placeholder="Location"
                            className="react-select-box select-box-group"
                            name="location"
                            filterOption={createFilter(reactSelectFilters)}
                          />

                          <Label for="location" className={formData.selectedLocation ? 'selected' : ''}>
                            Location</Label>
                          {errors.has('location') &&
                            <div className="error-text">{errors.first('location')}</div>
                          }
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  {state && state['breadcrumb'] ?
                    <div className="card-footer d-flex align-items-center justify-content-end">
                      <Button className="btn btn-regular mr-2" onClick={() => history.goBack()}>Cancel</Button>
                      <Button color="primary" className="btn btn-rg">Update</Button>
                    </div>
                    :
                    <div className="card-footer d-flex align-items-center">
                      <Link to={getNavToDashboard()} className="skip-btn mr-auto">
                        Skip
                                            </Link>
                      <Button color="primary" className="btn btn-rg">Save</Button>
                    </div>
                  }
                </Form>
              </div>
            </div>
          </div>
          <ImageCropperPopup
            isOpenModal={isOpenCropModal}
            image={image}
            savedCroppedImage={this.savedCroppedImage}
            hideModal={this.hideModal}
          />
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const { updateProfile } = state;
  return {
    updateProfile,
  };

}
const connectedCreatePasswordForm = connect(mapStateToProps)(UpdateProfile);
export { connectedCreatePasswordForm as UpdateProfile };
