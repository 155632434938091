import React, { Dispatch } from 'react';
import { map } from 'lodash';
import queryString from 'query-string';
import {
  Button,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
} from 'reactstrap';
import classnames from 'classnames';
import { Sidebar } from '../../shared/sidebar';
import { Link } from 'react-router-dom';
import userImage from './../../../assets/images/profile.png';
import { languageProficiencies, renderDaysOfAvailability, staticConstants, MAGIC_NUMBER, rejectUserRequestMsg, getModeOfDeliveryText } from '../../../utils/';
import facebook from './../../../assets/images/icons/fb-link.svg';
import twitter from './../../../assets/images/icons/twitter-link.svg';
import linkedin from './../../../assets/images/icons/linkedin-link.svg';
import lockIcon from './../../../assets/images/icons/key.svg';
import emailNotify from './../../../assets/images/icons/icon-email-notify.svg';
import { Back } from '../../shared/back/back';
import { getNavToEditProfile } from '../../../utils/handleNavigation';
import { learnerProfileAction } from './redux/actions';
import OpenFieldTypes from 'OpenFieldTypes';
import { connect } from 'react-redux';
import { ListingContainer } from '../../shared/paymentCard/listings/listing';
import { history } from './../../../helpers/';
import { pageRoutes } from '../../../utils/routeConstants';
import { BillingInfo } from './../../shared/updateProfile/BillingInfo';
import { IViewProfile, ISubscriptionInfo } from '../../../interfaces/index';
import { ProfileSettingPage } from './../../shared/viewProfile/profileSettingPage';
import { ChangePasswordForm } from '../../shared/updateProfile/ChangePassword';
import { ChangeEmailForm } from '../../shared/updateProfile/ChangeEmail';
import { userAction } from '../../systemAdmin/userManagement/redux/actions';
import { ConfirmatioPopup } from '../../shared/modals/confirmationPopup';
import { HourlyRate } from '../../systemAdmin/userManagement/updateHourlyRate/updateHourlyRate';
import StarRatings from 'react-star-ratings';
interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  viewProfile: IViewProfile;
  location: any;
  match?: any;
  history: any;
  loginLink: string;
  subscriptionHistory: ISubscriptionInfo[];
  user: any;
  successForUserRequestStatus: any;
}

interface IState {
  activeTab: any;
  arrowDown: any;
  clearPasswordData: boolean;
  clearEmailData: boolean;
  displayUserRequestPopup: any;
  isOpenHourlyRateModal: any;
  requestAction: any;
  modalPopupObj: any;
}

enum tabs {
  PROFILE = '1',
  PASSWORD = '2',
  BILLING = '3',
  SETTINGS = '4',

}

class ViewProfilePage extends React.Component<IProps, IState> {

  /**
   * @description getDerivedStateFromProps is called when there in change in property
   * @param fields {Object} props
   */
  public static getDerivedStateFromProps(props: IProps) {
    const updateState = {};
    if (props.loginLink) {
      window.open(props.loginLink);
      props.dispatch(learnerProfileAction.resetPaymentLoginLink());
    }
    if (props.successForUserRequestStatus) {
      updateState['displayUserRequestPopup'] = false;
      updateState['isOpenHourlyRateModal'] = false;
      props.dispatch(userAction.resetPopUpStatus());
      const { userId } = props.match.params;
      if (userId) {
        props.dispatch(userAction.getUserProfile(userId));
      }
    }
    return updateState;
  }
  constructor(props: IProps) {
    super(props);

    this.toggle = this.toggle.bind(this);
    const hash = this.props.location.hash;
    let activeTab = String(tabs.PROFILE);
    if (hash) {
      const num = Number(hash.replace('#', ''));
      if (num && num > MAGIC_NUMBER.ZERO && num < MAGIC_NUMBER.FIVE) {
        activeTab = String(num);
      }
    }
    this.state = {
      activeTab,
      arrowDown: false,
      clearPasswordData: false,
      clearEmailData: false,
      displayUserRequestPopup: false,
      isOpenHourlyRateModal: false,
      requestAction: null,
      modalPopupObj: '',
    };
  }

  public componentDidMount = () => {
    const { dispatch, location } = this.props;
    const params = queryString.parse(location.search);
    const timeInterval = 0;
    const { userId } = this.props.match.params;
    const reqObj = {
      userId,
    };
    if (userId) {
      this.props.dispatch(userAction.getUserProfile(userId));
      dispatch(learnerProfileAction.getSubscriptionHistory(reqObj));
    } else {
      dispatch(learnerProfileAction.getProfile());
      dispatch(learnerProfileAction.getSubscriptionHistory(reqObj));
    }
    if (params.code) {
      setTimeout(() => {
        dispatch(learnerProfileAction.updatePaymentAccountID({
          authorizationCode: params.code,
        }));
        this.props.history.replace('/expert/viewProfile');
      }, timeInterval);
    }
  }

  public toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        clearPasswordData: true,
        clearEmailData: true,
      });
    }
  }

  public arrowDown = () => {
    this.setState({
      arrowDown: !this.state.arrowDown,
    });
  }

  /**
   * @description function to render tag list
   */
  public renderTagList = (tags) => {
    return map(tags, (tag: any) => {
      return tag.id && (
        <em className="tags-view-only">{tag.name}</em>
      );
    });
  }

  /**
   * @description
   * updateUserRequest is used to update user request status
   * @param fields {String} action
   */
  public updateUserRequest = (action: any) => {
    if (action === 'reject') {
      this.setState({
        displayUserRequestPopup: true,
        requestAction: action,
        modalPopupObj: rejectUserRequestMsg,
      });
    }
    if (action === 'approve') {
      this.setState({
        isOpenHourlyRateModal: true,
        requestAction: action,
      });
    }
  }

  /**
   * @description
   * validateHourlyRateForm is called from child component
   * @param fields {String} hourlyRate
   */
  public validateHourlyRateForm = (hourlyRate: any) => {
    const { userId } = this.props.match.params;
    const reqObj = {
      action: this.state.requestAction,
      userId,
      hourlyRate: parseFloat(hourlyRate),
      isFromDetailPage: true,
    };
    this.props.dispatch(userAction.updateUserRequestStatus(reqObj));
  }

  /**
   * @description
   * updateUserRequestAction is used to update user request
   */
  public updateUserRequestAction = () => {
    const { userId } = this.props.match.params;
    const reqObj = {
      action: this.state.requestAction,
      userId,
      isFromDetailPage: true,
    };
    this.props.dispatch(userAction.updateUserRequestStatus(reqObj));
  }

  /**
   * @description
   * hideModal is called when someone click on the cancel button
   */
  public hideModal = () => {
    this.setState({
      displayUserRequestPopup: false,
      isOpenHourlyRateModal: false,
    });
  }

  /**
   * @description
   * renderExperiences is used to show the experiences
   */
  public renderExperiences(experiences) {
    return map(experiences, (item: any) => {
      return (
        <Row>
          <Col xs="12" key={Math.random()}>
            <h3 className="heading heading-md roboto-bold">{item.position}</h3>
            <h4>{item.employer}</h4>
            <h4>{item.lengthOfServices}</h4>
            <h4 className="mb-0">{item.location}</h4>
          </Col>
          <Col xs="12">
            <span>Work Experience Summary</span>
            <p>{item.summary}</p>
          </Col>
        </Row>
      );
    });
  }

  /**
   * @description redirectToPaymentGateWay method to redirect the payment gateway
   */
  public redirectToPaymentGateWay = () => {
    const redirectUrl = `${process.env.REACT_APP_WEB_URL}/expert/viewProfile`;
    const clientId = process.env.REACT_APP_STRIPE_CLIENT_ID;
    const pathArr = [
      process.env.REACT_APP_STRIPE_AUTH_URL,
      `redirect_uri=${redirectUrl}`,
      `client_id=${clientId}`,
    ];
    window.location.href = pathArr.join('&');
  }

  /**
   * @description
   * createPaymentGateWayDashBoardLink method is used to create payment gateway dashboard
   */
  public createPaymentGateWayDashBoardLink = () => {
    const { dispatch } = this.props;
    dispatch(learnerProfileAction.getPaymentLoginLink());
  }
  /**
   * @description
   * renderAddedProfeciency is used to show the profeciency
   */
  public renderAddedProfeciencyCheck(selectedProfeciencies: any) {
    return map(languageProficiencies, (item: any, index: any) => {
      if (item.value !== 'all') {
        let checkClass = 'icon-cross';
        if (selectedProfeciencies.includes(item.value)) {
          checkClass = 'icon-accept';
        }
        return (
          <span key={`addedProfeciencyCheck-${index}`}><em className={`icon ${checkClass}`} /></span>
        );
      }
    });
  }

  /**
   * @description
   * renderAddedLanguages is used to render the added langauage with Profeciency
   * call renderAddedProfeciency to show the profeciency
   */
  public renderAddedLanguages() {
    const { viewProfile } = this.props;
    if (viewProfile && viewProfile.languages) {
      return map(viewProfile.languages, (item: any, index: any) => {
        return (
          <>
            <div className="language-data" key={`addedLanguage-${index}`}>
              <span>{item.language}</span>
              {this.renderAddedProfeciencyCheck(item.profeciencies)}
            </div>
          </>
        );
      });
    }
    return '';
  }

  /**
   * @description moveToAddCardPage used to move add new card page
   */
  public moveToAddCardPage = () => {
    history.push(`${pageRoutes.PAYMENT.ADD_CARD}`);
  }

  /**
   * @description
   * render is used to render the html
   */
  public render() {
    const { viewProfile, subscriptionHistory, user, match: { params: { userId } } } = this.props;
    const { isOpenHourlyRateModal, displayUserRequestPopup, modalPopupObj } = this.state;
    let modeOfDelivery = '';
    let averageProfileRating = 0;
    let expertSessionRating = 0;
    if (viewProfile && viewProfile.averageRating && viewProfile.averageSessionRating) {
      viewProfile.averageRating = viewProfile.averageRating || 0;
      viewProfile.averageSessionRating = viewProfile.averageSessionRating || 0;
      averageProfileRating = Number.isInteger(viewProfile.averageRating)
        ? viewProfile.averageRating
        : Number(viewProfile.averageRating.toFixed(MAGIC_NUMBER.ONE));
      expertSessionRating = Number.isInteger(viewProfile.averageSessionRating)
        ? viewProfile.averageSessionRating
        : Number(viewProfile.averageSessionRating.toFixed(MAGIC_NUMBER.ONE));
    }
    if (viewProfile && viewProfile.modeOfDelivery) {
      modeOfDelivery = viewProfile.modeOfDelivery;
    }
    return (
      <>
        <Sidebar />
        <div className="dashboard-wrapper">
          {user.role === staticConstants.ROLE.SYSTEM_ADMIN ?
            <Back {...this.props} />
            :
            <h2 className="heading heading-sm roboto-medium text-uppercase">User Information</h2>
          }
          <div className="dashboard-content">
            <div className="tab-btn-group">
              <Nav tabs className="border-0">
                {user.role !== staticConstants.ROLE.SYSTEM_ADMIN &&
                  <>
                    <Button
                      color="secondary"
                      className={`btn btn-20 h-50 tab-btn text-uppercase ${classnames({ 'btn-active': this.state.activeTab === '1' })}`}
                      onClick={() => { this.toggle('1'); }}
                    >
                      Profile
                </Button>
                    <Button
                      color="secondary"
                      className={`btn btn-20 h-50 tab-btn text-uppercase ${classnames({ 'btn-active': this.state.activeTab === '2' })}`}
                      onClick={() => { this.toggle('2'); }}
                    >
                      Password
                </Button>
                    <Button
                      color="secondary"
                      className={`btn btn-20 h-50 tab-btn text-uppercase ${classnames({ 'btn-active': this.state.activeTab === '5' })}`}
                      onClick={() => { this.toggle('5'); }}
                    >
                      Email
                </Button>
                    <Button
                      color="secondary"
                      className={`btn btn-20 h-50 tab-btn text-uppercase ${classnames({ 'btn-active': this.state.activeTab === '3' })}`}
                      onClick={() => { this.toggle('3'); }}
                    >
                      Billing
                </Button>
                    <Button
                      color="secondary"
                      className={`btn btn-20 h-50 tab-btn text-uppercase ${classnames({ 'btn-active': this.state.activeTab === '4' })}`}
                      onClick={() => { this.toggle('4'); }}
                    >Settings
                </Button>
                  </>
                }
                {user.role === staticConstants.ROLE.SYSTEM_ADMIN && viewProfile && viewProfile.systemAdmin && !viewProfile.systemAdmin.isRequest &&
                  <>
                    <Button
                      color="secondary"
                      className={`btn btn-50 h-50 tab-btn text-uppercase ${classnames({ 'btn-active': this.state.activeTab === '1' })}`}
                      onClick={() => { this.toggle('1'); }}
                    >
                      Profile
                </Button>
                    <Button
                      color="secondary"
                      className={`btn btn-50 h-50 tab-btn text-uppercase ${classnames({ 'btn-active': this.state.activeTab === '3' })}`}
                      onClick={() => { this.toggle('3'); }}
                    >
                      Billing
                </Button>
                  </>
                }
              </Nav>
            </div>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <div className="tab-panel-wrapper white-bg box-shadow">
                  <div className="profile-bar">
                    <div className="user-profile-details">
                      <Row>
                        <Col xs="12" md="4" className="d-flex">
                          <span className="profile-picture-view-only">
                            <img src={viewProfile && viewProfile.profileImage ? viewProfile.profileImage : userImage} alt="Profile" className="img-cover" />
                          </span>
                          <div className="profile-email-view-only text-truncate ">
                            <h3 className="text-truncate">
                              {viewProfile && viewProfile.title ? `${viewProfile.title}.` : ''} {viewProfile && viewProfile.firstName} {viewProfile && viewProfile.lastName}
                            </h3>
                            <p>{viewProfile && viewProfile.email}</p>
                            {
                              user.role === staticConstants.ROLE.EXPERT && viewProfile &&
                              <h4>{viewProfile.position} {viewProfile.organizationName && `-`} {viewProfile.organizationName}</h4>
                            }
                            <div className="rate-tag">
                              <em className="price-placeholder">$</em>
                              <span> {viewProfile && viewProfile.hourlyRate && viewProfile.hourlyRate.current ? viewProfile.hourlyRate.current : 0}</span>
                              <em>Per Hour</em>
                            </div>
                          </div>
                        </Col>
                        <Col xs="12" md="4" className="rating-count-2">
                          <div className="rating-view-only">
                            <p>Profile Ratings ({averageProfileRating}/5)</p>
                            <StarRatings
                              rating={averageProfileRating}
                              starHoverColor="#4be59e"
                              starEmptyColor="#dcdcdc"
                              starRatedColor="#4be59e"
                              starDimension="20px"
                              starSpacing="2px"
                              numberOfStars={MAGIC_NUMBER.FIVE}
                              name="averageRating"
                            />
                          </div>
                          <div className="rating-view-only">
                            <p>Session Ratings ({expertSessionRating}/5)</p>
                            <StarRatings
                              rating={expertSessionRating}
                              starHoverColor="#4be59e"
                              starEmptyColor="#dcdcdc"
                              starRatedColor="#4be59e"
                              starDimension="20px"
                              starSpacing="2px"
                              numberOfStars={MAGIC_NUMBER.FIVE}
                              name="averageRating"
                            />
                          </div>
                        </Col>
                        <Col xs="12" md="4">
                          <div className="social-links">
                            <ul>
                              {viewProfile && viewProfile.facebook &&
                                <li><a href={viewProfile.facebook} target="_blank" rel="noopener noreferrer"><img src={facebook} alt="facebook" /></a></li>
                              }
                              {viewProfile && viewProfile.twitter &&
                                <li><a href={viewProfile.twitter} target="_blank" rel="noopener noreferrer"><img src={twitter} alt="twitter" /></a></li>
                              }
                              {viewProfile && viewProfile.linkedIn &&
                                <li><a href={viewProfile.linkedIn} target="_blank" rel="noopener noreferrer"><img src={linkedin} alt="linkedin" /></a></li>
                              }
                            </ul>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="profile-details-wrapper">
                    <div className="form-details">
                      <h2 className="heading heading-rg roboto-medium">Personal Information</h2>
                      <div className="form-data">
                        <Row>
                          {
                            (viewProfile && viewProfile.education) &&
                            <Col xs="12" md="3">
                              <span>Highest Level of Education</span>
                              <p> {viewProfile.education}</p>
                            </Col>
                          }

                          {
                            (viewProfile && viewProfile.occupation) &&
                            <Col xs="12" md="3">
                              <span>Occupation</span>
                              <p> {viewProfile.occupation}</p>
                            </Col>
                          }

                          {
                            (viewProfile && viewProfile.address) &&
                            <Col xs="12" md="3">
                              <span>City or Town</span>
                              <p>{viewProfile.address}</p>
                            </Col>
                          }

                          {/* {
                            (viewProfile && viewProfile.timeZone) &&
                            <Col xs="12" md="3">
                              <span>Time Zone</span>
                              <p> {viewProfile.timeZone}</p>
                            </Col>
                          } */}

                          {
                            (viewProfile && viewProfile.phoneNumber) &&
                            <Col xs="12" md="3">
                              <span>Phone Number</span>
                              <p> {viewProfile.phoneNumber}</p>
                            </Col>
                          }

                          {
                            (viewProfile && viewProfile.modeOfDelivery) &&
                            <Col xs="12" md="3">
                              <span>Mode of delivery</span>
                              <p>{getModeOfDeliveryText(modeOfDelivery)}</p>
                            </Col>
                          }

                          {
                            (viewProfile && viewProfile.availableDays) &&
                            <Col xs="12" md="3">
                              <span>Days of Availability</span>
                              <p>{renderDaysOfAvailability(viewProfile.availableDays)}</p>
                            </Col>
                          }

                          {
                            (user.role === staticConstants.ROLE.SYSTEM_ADMIN && viewProfile && viewProfile.referred && viewProfile.referred.isReferred) &&
                            <Col xs="12" md="3">
                              <span>Referred By</span>
                              <p>{viewProfile.referred.referredBy}</p>
                            </Col>
                          }

                          {
                            (viewProfile && viewProfile.location) &&
                            <Col xs="12" md="3">
                              <span>Country</span>
                              <p>{viewProfile.location}</p>
                            </Col>
                          }

                          {
                            (viewProfile && viewProfile.publishLink) &&
                            <Col xs="12">
                              <span>Links to Publish Works</span>
                              <p>{viewProfile.publishLink}</p>
                            </Col>
                          }



                          {
                            (viewProfile && viewProfile.languages && viewProfile.languages.length > MAGIC_NUMBER.ZERO)
                            &&
                            <Col xs="12">
                              <div className="selected-language language-view-mode">
                                <div className="language-head">
                                  <span>Language</span>
                                  <span>Read</span>
                                  <span>Write</span>
                                  <span>Speak</span>
                                </div>
                                {this.renderAddedLanguages()}
                              </div>
                            </Col>
                          }

                          {
                            (viewProfile && viewProfile.bio) &&
                            <Col xs="12">
                              <span>Bio</span>
                              <p>{viewProfile.bio}</p>
                            </Col>
                          }

                          {
                            (viewProfile && viewProfile.tags && viewProfile.tags.length > MAGIC_NUMBER.ZERO) &&
                            <Col xs="12">
                              <span>Tags</span>
                              <div className="tags-wrapper">
                                {this.renderTagList(viewProfile.tags)}
                              </div>
                            </Col>
                          }
                        </Row>
                      </div>
                    </div>
                  </div>
                  <div className="profile-details-wrapper">
                    {
                      (viewProfile && viewProfile?.experiences) &&
                      <div className="form-details">
                        <h2 className="heading heading-rg roboto-medium">Work Experience</h2>
                        <div className="form-data work-experience">
                          {this.renderExperiences(viewProfile.experiences)}
                        </div>
                      </div>
                    }
                    {(user.role === staticConstants.ROLE.EXPERT || user.role === staticConstants.ROLE.SYSTEM_ADMIN) &&
                      viewProfile?.resume &&
                      <div className='pl-4 mb-3'>
                        <Button
                          color="secondary"
                          className='btn h-50 tab-btn text-uppercase'
                          onClick={() => window.open(viewProfile.resume, '_blank')}
                        >View Resume</Button>
                      </div>
                    }
                    {(user.role === staticConstants.ROLE.EXPERT || user.role === staticConstants.ROLE.SYSTEM_ADMIN) &&
                      !(viewProfile?.resume) &&
                      <div className='pl-4 mb-3'>
                        <Button
                          color="secondary"
                          className='btn h-50 tab-btn text-uppercase'
                        >View Resume</Button>
                        <p className='label'>No resume uploaded yet</p>
                      </div>
                    }
                    {user.role === staticConstants.ROLE.EXPERT &&
                      <div className="card-footer d-flex align-items-center justify-content-end">
                        <Link to={getNavToEditProfile()} className="btn btn-primary btn-rg">Edit</Link>
                      </div>
                    }
                    {user.role === staticConstants.ROLE.SYSTEM_ADMIN && viewProfile && viewProfile.systemAdmin && viewProfile.systemAdmin.isRequest &&
                      <>
                        <div className="card-footer d-flex align-items-center justify-content-end">
                          <Button className="btn mx-2 btn-delete btn btn-secondary" onClick={() => this.updateUserRequest('reject')}>Reject</Button>
                          <Button className="btn btn-primary btn-rg" onClick={() => this.updateUserRequest('approve')}>Accept</Button>
                        </div>
                      </>
                    }
                    {
                      displayUserRequestPopup ?
                        <ConfirmatioPopup hideModal={this.hideModal} isOpenModal={displayUserRequestPopup} modalPopupObj={modalPopupObj}
                          onClickYes={this.updateUserRequestAction}
                        />
                        : ''
                    }
                    <HourlyRate
                      modalTitle={'Hourly Rate'}
                      isOpenModal={isOpenHourlyRateModal}
                      hideModal={() => this.setState({ isOpenHourlyRateModal: false })}
                      onClickAccept={this.validateHourlyRateForm}
                    />
                  </div>
                  {user.role === staticConstants.ROLE.SYSTEM_ADMIN && viewProfile && viewProfile.references &&
                    <div className="profile-details-wrapper">
                      <div className="form-details">
                        <h2 className="heading heading-rg roboto-medium">References</h2>
                        <div className="form-data references-wrapper">
                          {viewProfile.references.map((ref: { name: string; email: string; phone: string }) =>
                            <div key={ref.name} className="ref-card">
                              <h3>{ref.name}</h3>
                              <p>{ref.email}</p>
                              <p className="mb-0">{ref.phone}</p>
                            </div>)}
                        </div>
                      </div>
                    </div>}
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="tab-panel-wrapper white-bg box-shadow">
                  <div className="profile-password-wrapper">
                    <h2 className="heading heading-rg roboto-medium">
                      Change Password
                            </h2>
                    <ChangePasswordForm {...this.props} toggle={this.toggle}
                      clearData={this.state.clearPasswordData} />
                    <div className="lock-box">
                      <div className="key-circle">
                        <img src={lockIcon} alt="key" />
                        <p>It's a good idea to use a strong password that you don't use elsewhere.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="5">
                <div className="tab-panel-wrapper white-bg box-shadow">
                  <div className="profile-password-wrapper">
                    <h2 className="heading heading-rg roboto-medium">
                      Change Email
                    </h2>
                    {<ChangeEmailForm {...this.props} toggle={this.toggle} clearData={this.state.clearEmailData} />}
                    <div className="lock-box">
                      <div className="key-circle">
                        <img src={emailNotify} alt="key" />
                        <p>Email is used to notify user of all relevant account and billing activity.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>

              <TabPane tabId="3">
                {viewProfile ?
                  <BillingInfo
                    {...this.props}
                    viewProfile={viewProfile}
                    subscriptionHistory={subscriptionHistory}
                    role={user.role}
                    loggedInUserRole={user.role}
                    userId={userId}
                  /> :
                  <p className="text-center table-no-data pb-3 pt-3">
                    No Results Found.
                  </p>
                }
              </TabPane>

              <TabPane tabId="4">
                <ProfileSettingPage viewProfile={viewProfile} />
                <div className="tab-panel-wrapper white-bg box-shadow mt-3">
                  <div className="card-data">
                    <h2 className="heading heading-rg roboto-medium collapse-header">Saved Cards</h2>
                    <ListingContainer />
                    <div className="card-footer d-flex align-items-center justify-content-end">
                      <Button color="primary" className="btn btn-rg" onClick={this.moveToAddCardPage}>Add New Card</Button>
                    </div>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state: any) {
  const { updateProfile, users: { detail, successForUserRequestStatus } } = state;
  let userInfo = updateProfile['profile'];
  if (state.authentication.user.role === staticConstants.ROLE.SYSTEM_ADMIN) {
    userInfo = detail;
  }
  return {
    user: state.authentication.user,
    viewProfile: userInfo,
    successForUserRequestStatus,
    loginLink: updateProfile['loginLink'],
    subscriptionHistory: updateProfile['subscriptionHistory'],
  };

}
const connectedViewProfilePageDetail = connect(mapStateToProps)(ViewProfilePage);
export { connectedViewProfilePageDetail as ViewProfilePage };
