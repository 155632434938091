import React, { Component, Dispatch } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import OpenFieldTypes from 'OpenFieldTypes';
import { pick } from 'lodash';
import { LocationDescriptorObject } from 'history';
import Select, { createFilter } from 'react-select';
import { Sidebar } from '../sidebar';
import ChatDetails from './chatMessages/ChatDetails';
import ChatUserList from './chatUsersList/ChatUserList';
import { IParams, IAllInboxUsers } from '../../../interfaces';
import { chatAction } from './redux/actions';
import { chatFilterOption, customSelectStyles,
   reactSelectFilters, pageRoutes, staticConstants } from '../../../utils';
interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  match: {
    params: {
      [key: string]: IParams;
    };
    url: string;
    path: string;
  };
  location: LocationDescriptorObject;
  role: string;
  allInboxUsers?: IAllInboxUsers;
  fetchingAllInboxUser?: boolean;
}

class Chat extends Component<IProps> {
  public static getDerivedStateFromProps(props: IProps, state) {
    const newState = {};
    if (props.role && props.role === staticConstants.ROLE.SYSTEM_ADMIN) {
      newState['currentFilter'] = state.currentFilter ? state.currentFilter : 'organization';
    }
    return newState;
  }

  public state = {
    openFilter: false,
    currentFilter: '',
  };

  public componentDidMount() {
    const { dispatch } = this.props;
    dispatch(chatAction.getAllConnections(true, this.state.currentFilter));
    dispatch(chatAction.getTotalUnreadCount());
  }

  public componentWillUnmount() {
    this.props.dispatch({ type: 'CLEAR_CHAT_REDUCERS' });
  }

  /**
   * handleChatFilter
   * @param {object} filter
   */
  public handleChatFilter = (filter: { value: string; label: string }) => {
    this.setState({ currentFilter: filter.value }, () => {
      this.props.dispatch(chatAction.setCurrentSelectedUser({}));
      this.props.dispatch(chatAction.getAllConnections(true, filter.value));
    });
  }

  public render() {
    const { match, role } = this.props;
    const { currentFilter } = this.state;
    return (
      <React.Fragment>
        <Sidebar {...this.props} />
        <div className="dashboard-wrapper">
          <h2 className="heading heading-sm active roboto-bold text-uppercase">
            Message Board
          </h2>
          {role === staticConstants.ROLE.SYSTEM_ADMIN && (
            <div className="filter-wrapper">
              <div className={`filter-box m-0 ${this.state.openFilter ? 'filter-open' : ''}`}>
                <div className="filter-dropdown">
                  <Select
                    placeholder="Message Type"
                    onChange={this.handleChatFilter}
                    options={chatFilterOption}
                    styles={customSelectStyles}
                    className="react-select-box react-select-box-sm"
                    createFilter={createFilter(reactSelectFilters)}
                    defaultValue={chatFilterOption[0]}
                  />
                </div>
              </div>
              <Link
                to={`${match.url}${pageRoutes.MESSAGE_BOARD.ADD_MESSAGE}`}
                className="btn btn-primary filter-sm-device ml-auto"
              >
                Add Message
              </Link>
            </div>
          )}
          <div className="chat-content-wrapper">
            <div className="chat-container">
              <ChatUserList {...this.props} userType={currentFilter} />
              <ChatDetails {...this.props} />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: {
  chatMessages: object;
  updateProfile: { profile: object };
  authentication: { user: { role: string } };
}) {
  const {
    chatMessages,
    updateProfile: { profile },
    authentication: {
      user: { role },
    },
  } = state;
  return {
    ...chatMessages,
    profile: pick(profile, ['firstName', 'lastName', 'role']),
    role,
  };
}

const chatContainer = connect(mapStateToProps)(Chat);
export { chatContainer as ChatContainer };
