import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Header } from '../../header';
import { Sidebar } from '../../sidebar';
import moment from 'moment';
import { map, find, cloneDeep } from 'lodash';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import profilePng from './../../../../assets/images/profile.png';
import { capitalizeEveryWord } from '../../../../utils/common';
import {
  pageRoutes, staticConstants, getGoalStatusText, goalStatusLearnerTimeline,
  goalStatus, extendEventMsg, getMyUserId, goalStatusExpertTimeLine, extendEventStatus,
  goalStatusOrgAdminTimeline, bidStatus, EVENT_PAYMENT_TYPES, goalStatusEventCancelTimeline,
  cancelEvent,
  formatCurrency,
  goalStatusExpertCancelTimeLine, MAGIC_NUMBER,
} from './../../../../utils';
import {
  subscribeMeetingEndEvent, subscribeExtendEventRequest, unsubscribeAllEvent,
  subscribeMeetingStartEvent, subscribeMeetingExtendedEvent, subscribeEventExtendPayment, subscribeRecordingCompleted, subscribeExtendMeetingReminder,
} from './../../../../socket';
import StarRatings from 'react-star-ratings';
import ReactTimeAgo from 'react-time-ago/tooltip';
import { GoalAdminPopup } from '../../modals/goalAdminPopup';
import { extendEventPaymentModal, zoomEventStartModalContent } from '../../../../utils/messages';
import { ChangeParticipantPopup } from '../../modals/changeParticipantPopup';
import { learnerAction } from '../../learnerManagement/redux/actions';
import { systemAdminPaymentAction } from '../../../systemAdmin/payment/redux/actions';

import {
  UncontrolledCollapse,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardText,
  CardColumns,
  CardImg,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  Media,
} from 'reactstrap';
import { IParams, IGoalDetailWithId, IBidDetail, ILocalUser } from './../../../../interfaces';
import { eventAction } from '../redux/actions';
import { ConfirmatioPopup } from '../../modals/confirmationPopup';
import { EventExtendConfirmPopup } from '../../modals/eventExtendConfirmPopup';
import { history } from '../../../../helpers';
import { FlagUserPopUp } from '../../modals/flagUserPopup';
import { SessionRatingPopUp } from '../../modals/sessionRatingContainerPopup';
import { AttendanceContainerPopup } from '../../modals/attendanceContainerPopup';
import { Back } from '../../back/back';
import { ExtendEventModal } from '../extendDurationModal';
import { SuccessPopup } from '../../../shared/modals/successPopup';

interface IProps {
  match: {
    params: {
      [key: string]: IParams;
    };
  };
  bid: object;
  eventData: any;
  isCreater: boolean;
  eventDetails: IGoalDetailWithId;
  activeTab: string;
  location: any;
  user: ILocalUser;
  isGoalAdmin: boolean;
  extendSessionFee: { amount: number; commission: number };
  learnerList: any;
  sessionRecording: { download_url: string; play_url: string; file_size: number; file_type: string };
  endMeeting: (data: object) => void;
  meetingExtended: (data: object) => void;
  startMeeting: (data: object) => void;
  extendMeeting: (data: object) => void;
  validateAttendance: (data: object) => void;
  flagUser: (data: object) => void;
  ratingGoal: (data: object) => void;
  updateEventParticipants: (data: object) => void;
  updateExtentEventStatus: (data: object) => void;
  getEventManagementDetails: (data: object) => void;
  cancelEvent: (data: object) => void;
  getExtendSessionFee: (bidId: string, duration: number, extendEventId?: string) => void;
  status: any;
  getLearner: (data: object) => void;
  changeEventParticipants: (data: object, eventObj: object) => void;
  updateDisputeStatus: (reqObj: object, reFetch: object, redirectUri: string) => void;
  downloadSession: (bidId: string) => void;
  displayError: (messgae: string) => void;
  recordingCompleted: (data: object) => void;
}

interface IState {
  updatingExtendEventStatus: boolean;
  modal: boolean;
  eventDetails: IGoalDetailWithId;
  displayExtendEventPopup: boolean;
  reqObj: object;
  modalPopupObj: object;
  isScheduleManagement: boolean;
  displayFlagUserModal: boolean;
  isEventExtended: boolean;
  arrowDownExpert: boolean;
  arrowDownParticipant: boolean;
  isCreater: boolean;
  displaySessionRatingModal: boolean;
  isShareEventModalOpen: boolean;
  copied: boolean;
  arrowDownOrgAdmin: boolean;
  displayValidateAttendanceModal: boolean;
  isGoalAdmin: boolean;
  goalAdminForExpert: object;
  isdDisplayDurationModal: boolean;
  isDisplayExtendEventRequestPopup: boolean;
  duration: any;
  sessionModalPopupObj: object;
  canExtendEvent: boolean;
  isExtendEventReminded: boolean;
  isEventStarted: boolean;
  activeExtendEventRequest: { _id: string; status: string; duration: number; role: string; isPaymentSuccessful: boolean; isExtendSuccessful: boolean };
  isDisplayedExtendRequestModal: boolean;
  openGoalAdminModal: boolean;
  acceptedBidId: string;
  displayExtendEventPaymentModal: boolean;
  openChangeParticipantModal: boolean;
  arrowDownAttendance: boolean;
  displayCancelPopup: boolean;
  displayCanNotStartEvent: boolean;
}

class EventManagementDetail extends React.Component<IProps, IState> {

  public static getDerivedStateFromProps(props: IProps, state: IState) {
    let newState = null;
    const user = props.user;
    if (props.eventData && props.eventData.eventDetails) {
      const eventDetails: IGoalDetailWithId = props.eventData.eventDetails;
      newState = {
        eventDetails,
        isCreater: props.eventData.isCreater,
      };

      if (props.eventData.eventDetails && props.eventData.eventDetails.bid[0]) {
        const successPayment = props.eventData.eventDetails.bid[0].payment.find(el => el.type === EVENT_PAYMENT_TYPES.SESSION_FEE && el.isPaymentSuccessful && el.bidId);
        if (successPayment) {
          newState['acceptedBidId'] = successPayment['bidId'];
        }
      }
      if (eventDetails.bid[0].participantsRaw) {
        eventDetails.bid[0].participantsRaw.forEach((el) => {
          if (el['userId'] === user['_id'] && el['isAdmin']) {
            newState['isGoalAdmin'] = true;
          }
        });
        newState['goalAdminForExpert'] = eventDetails.participants.filter(o1 =>
          eventDetails.bid[0].participantsRaw.some(el => el['isAdmin'] && o1['_id'] === el['userId']));
      }
      if (eventDetails.bid[0].isEnded) {
        const obj = {
          userId: user._id,
          userRole: user.role,
        };
        const timelineLenth = eventDetails.bid[0].statusTimeline.length;
        const lastStatus = eventDetails.bid[0].statusTimeline[timelineLenth - MAGIC_NUMBER.ONE];
        if ((lastStatus.status !== goalStatus.EVENT_CANCELLED
          && newState['isGoalAdmin'] && !eventDetails.bid[0].adminAttendence.comment)
          ||
          (lastStatus.status !== goalStatus.EVENT_CANCELLED
            && (user['role'] === staticConstants.ROLE.EXPERT &&
              !eventDetails.bid[0].expertAttendence.comment))) {
          newState['displayValidateAttendanceModal'] = true;
          newState['modalPopupObj'] = obj;
        } else {
          newState['displayValidateAttendanceModal'] = false;
        }
      }
      if (eventDetails.bid && eventDetails.bid[0].statusTimeline && !eventDetails.bid[0].isEnded) {
        const ind = eventDetails.bid[0].statusTimeline.findIndex((el: { status: string }) => el.status === bidStatus.EVENT_STARTED);
        if (ind > -1) {
          newState['isEventStarted'] = true;
        }
      }

      if (eventDetails.bid[0].extendMeeting && eventDetails.bid[0].extendMeeting.length > MAGIC_NUMBER.ZERO) {
        let activeExtendRequest = eventDetails.bid[0].extendMeeting.find(el => el.status === extendEventStatus.REQUEST);
        if (activeExtendRequest && activeExtendRequest.role !== user.role && (newState['isGoalAdmin'] ||
          user.role === staticConstants.ROLE.EXPERT)) {
          activeExtendRequest.duration = activeExtendRequest.duration > MAGIC_NUMBER.TWO ? activeExtendRequest.duration / MAGIC_NUMBER.SIXTY : activeExtendRequest.duration;
          newState['activeExtendEventRequest'] = activeExtendRequest;
          newState['isDisplayExtendEventRequestPopup'] = true;
        }
        activeExtendRequest = eventDetails.bid[0].extendMeeting.find(el => el.status === extendEventStatus.ACCEPT);
        if (newState['isGoalAdmin'] && activeExtendRequest) {
          activeExtendRequest.duration = activeExtendRequest.duration > MAGIC_NUMBER.TWO ? activeExtendRequest.duration / MAGIC_NUMBER.SIXTY : activeExtendRequest.duration;
          newState['activeExtendEventRequest'] = activeExtendRequest;
          newState['displayExtendEventPaymentModal'] = true;
        }
      }
    }
    return newState;
  }
  // public intervalId: any;
  constructor(props: IProps) {
    super(props);
    this.state = {
      updatingExtendEventStatus: false,
      displayCanNotStartEvent: false,
      isExtendEventReminded: false,
      acceptedBidId: null,
      modal: false,
      isScheduleManagement: true,
      isEventExtended: false,
      canExtendEvent: false,
      isEventStarted: false,
      isCreater: false,
      isGoalAdmin: false,
      goalAdminForExpert: {},
      eventDetails: {
        _id: '',
        participants: [],
        createdAt: '',
        fromDate: '',
        toDate: '',
        fromTime: '',
        toTime: '',
        title: '',
        eventType: '',
        modeOfDevivery: '',
        description: '',
        topic: {
          name: '',
          id: '',
        },
        domain: {
          name: '',
          id: '',
        },
        subDomain: {
          name: '',
          id: '',
        },
        category: {
          name: '',
          id: '',
        },
        subCategory: {
          name: '',
          id: '',
        },
        duration: '',
        isMe: false,
        status: '',
      },
      displayExtendEventPopup: false,
      displayFlagUserModal: false,
      reqObj: {
        redirect: true,
      },
      modalPopupObj: {},
      sessionModalPopupObj: {},
      arrowDownExpert: false,
      arrowDownParticipant: false,
      displaySessionRatingModal: false,
      arrowDownOrgAdmin: false,
      isShareEventModalOpen: false,
      copied: false,
      displayValidateAttendanceModal: false,
      duration: 1,
      isdDisplayDurationModal: false,
      isDisplayExtendEventRequestPopup: false,
      activeExtendEventRequest: null,
      isDisplayedExtendRequestModal: false,
      openGoalAdminModal: false,
      displayExtendEventPaymentModal: false,
      openChangeParticipantModal: false,
      arrowDownAttendance: false,
      displayCancelPopup: false,
    };
  }

  public canMarkAttendance = (eventDetails, isGoalAdmin: boolean) => {
    const timelineLenth = eventDetails.bid[0].statusTimeline.length;
    const lastStatus = eventDetails.bid[0].statusTimeline[timelineLenth - MAGIC_NUMBER.ONE];
    if ((lastStatus.status !== goalStatus.EVENT_CANCELLED
      && isGoalAdmin && !eventDetails.bid[0].adminAttendence.comment)
      ||
      (lastStatus.status !== goalStatus.EVENT_CANCELLED
        && (this.props.user['role'] === staticConstants.ROLE.EXPERT &&
          !eventDetails.bid[0].expertAttendence.comment))) {
            return true
    } 
    return false;
  }

  /**
   * @description
   * cancelEvent is called when someone click on the ok button
   */
  public cancelEvent = (goal) => {
    const reqObj = {
      goalId: goal._id,
      bidId: goal.bid[0]._id,
    };
    this.props.cancelEvent(reqObj);
    this.setState({
      displayCancelPopup: false,
      modalPopupObj: {},
    });
    history.goBack();
  }

  /**
   * @description function to hide goal admin modal
   *  called getEventmanagementDetail to get the detail of the event
   */
  public hideGoalAdminModal = () => {
    this.setState({
      openGoalAdminModal: false,
    });
  }

  /**
   * @description function to hide goal admin modal
   *  called getEventmanagementDetail to get the detail of the event
   */
  public hideChangeParticipantModal = () => {
    this.setState({
      openChangeParticipantModal: false,
    });
  }

  /**
   * @description
   * openModalToAddAdmin is used to show goal admin modal
   */
  public openGoalAdminModal = () => {
    this.setState({
      openGoalAdminModal: true,
    });
  }

  /**
   * @description
   * openModalToAddAdmin is used to show goal admin modal
   */
  public openChangeParticipantModal = () => {
    this.setState({
      openChangeParticipantModal: true,
    });
  }
  /**
   * @description
   * openModalToAddAdmin is used to handle goal admin submit.
   */
  public handleGoalAdminSubmit = (data: { participants: Array<{ isAdmin: boolean; userId: string }>; isMe: boolean }) => {
    this.props.updateEventParticipants({ ...data, goalId: this.state.eventDetails['_id'] });
  }

  /**
   * @description
   * openModalToAddAdmin is used to handle goal admin submit.
   */
  // TODO: replace with bidID
  public handleChangeParticipantSubmit = (data: { participants: Array<{ isAdmin: boolean; userId: string }>; isMe: boolean }) => {
    const getEventDetailObj = {
      id: this.state.eventDetails['_id'],
      isScheduleManagement: true,
    };
    this.props.changeEventParticipants({ ...data, goalId: this.state.eventDetails['_id'] }, getEventDetailObj);
  }

  /**
   * @description function to start zoom event based role
   *  called getEventmanagementDetail to get the detail of the event
   */
  public startZoomEvent = () => {
    const { user } = this.props;
    const { eventDetails } = this.state;
    const activeBid: IBidDetail = eventDetails.bid && eventDetails.bid[0];
    const proposedTime = new Date(activeBid.proposedTime);
    const scheduledAt = moment(activeBid.proposedDate).hour(proposedTime.getHours()).minute(proposedTime.getMinutes());
   // TODO: uncommnent before production
    // if (moment().isBefore(scheduledAt)) {
    //   this.setState({displayCanNotStartEvent: true,
    //     modalPopupObj: zoomEventStartModalContent(moment(activeBid.proposedDate).format(staticConstants.DATE_FORMAT),
    //                                               moment(activeBid.proposedTime).format(staticConstants.TIME_FORMAT)),
    //   });
    //   return;
    // }

    if (user['role'] === staticConstants.ROLE.EXPERT
      && !eventDetails.bid[0].isEnded
    ) {
      !activeBid.zoomEvent.isEnded && window.open(activeBid.zoomEvent.startUrl, '_blank');
    }
    if ([staticConstants.ROLE.ORGANIZATION_LEARNER, staticConstants.ROLE.ORGANIZATION_ADMIN,
      staticConstants.ROLE.PROFESSIONAL].indexOf(user['role']) > -1
      && !eventDetails.bid[0].isEnded) {
      !activeBid.zoomEvent.isEnded && window.open(activeBid.zoomEvent.joinUrl, '_blank');
    }
  }

  public downloadEventSession = () => {
    const { sessionRecording } = this.props.eventData;
    if (sessionRecording && sessionRecording.download_url) {
      window.location.href = sessionRecording.download_url;
    }
    return false;
  }

  /*
  * @description
  * showCancelPopup is used show confirmation popup.
  * @param fields {String} id
  */
  public showCancelPopup = () => {
    const modalPopupObj = cancelEvent;
    this.setState({
      displayCancelPopup: true,
      modalPopupObj,
    });
  }

  /**
   * @description componentDidMount is called when component load first time.
   *  called getEventmanagementDetail to get the detail of the event
   */
  public componentDidMount() {
    const { match: { params }, user } = this.props;
    const { isScheduleManagement } = this.state;
    const reqObj = {
      isScheduleManagement,
      bidId: String(params.id),
    };
    this.props.getLearner({
      limit: MAGIC_NUMBER.TEN,
      skip: MAGIC_NUMBER.ONE,
    });
    if (params.id) {
      this.props.getEventManagementDetails(reqObj);
      this.props.downloadSession(String(params.id));
      subscribeMeetingEndEvent(params.id, (err: any, data: any) => {
        const { isGoalAdmin } = this.state;
        if (data['bidData']._id === params.id) {
          this.props.endMeeting(data);
          if (isGoalAdmin || user['role'] === staticConstants.ROLE.EXPERT) {
            this.showValidateAttendanceModal();
          }
        }
      });

      subscribeMeetingExtendedEvent(params.id, (err: any, data: any) => {
        if (data.bidData._id === params.id && user['role'] === staticConstants.ROLE.EXPERT) {
          this.props.meetingExtended(data);
        }
      });

      subscribeMeetingStartEvent(params.id, (err: any, data: any) => {
        if (data.bidData._id === params.id) {
          this.props.startMeeting(data);
        }
      });

      subscribeExtendMeetingReminder((err: any, data: any) => {
        const { isGoalAdmin, isExtendEventReminded } = this.state;
        if (data['bidId'] === params.id && !isExtendEventReminded && (isGoalAdmin || user['role'] === staticConstants.ROLE.EXPERT)) {
          this.extendEvent();
          this.setState({ canExtendEvent: true, isExtendEventReminded: true });
        }
      });

      subscribeExtendEventRequest(params.id, (err: any, data: any) => {
        const { isGoalAdmin } = this.state;
        if (data['bidData'] && data['bidData']._id === params.id) {
          this.props.extendMeeting(data);
          if (user['role'] !== data.role && (isGoalAdmin || user['role'] === staticConstants.ROLE.EXPERT)) {
            this.setState({
              activeExtendEventRequest: { ...data, ...{ duration: data.duration > MAGIC_NUMBER.TWO ? data.duration / MAGIC_NUMBER.SIXTY : data.duration } },
              isDisplayExtendEventRequestPopup: true,
            });
          } else {
            this.setState({
              isDisplayExtendEventRequestPopup: false,
            });
          }
        }
      });

      subscribeRecordingCompleted((err: any, data: any) => {
        if (data['goalId'] === params.id) {
          this.props.recordingCompleted(data.sessionRecording);
        }
      });

      subscribeEventExtendPayment(params.id, (err: any, data: any) => {
        if (user.role === staticConstants.ROLE.ORGANIZATION_ADMIN) {
          this.setState({
            activeExtendEventRequest: { ...data, ...{ duration: data.duration > MAGIC_NUMBER.TWO ? data.duration / MAGIC_NUMBER.SIXTY : data.duration } },
            displayExtendEventPaymentModal: true,
          });
        }
      });
    }
  }

  /**
   * @description componentWillUnmount is called when component disloaded
   *  called disConnect to get the detail of the event
   */
  public componentWillUnmount() {
   unsubscribeAllEvent();
  }

  /**
   * @description openShareEventModal is used to open the share event modal
   */
  public openShareEventModal() {
    this.setState({
      isShareEventModalOpen: true,
    });
  }

  /**
   * @description hideShareEventModal is used to hide the share event modal
   */
  public hideShareEventModal() {
    this.setState({
      isShareEventModalOpen: false,
    });
  }

  /**
   * @description hideShareEventModal is used to hide the share event modal
   */
  public cancelExtendEventPayment = () => {
    this.setState({
      displayExtendEventPaymentModal: false,
    });
  }

  public proceedeExtendEventPayment = () => {
    const { activeExtendEventRequest, acceptedBidId } = this.state;
    this.props.getExtendSessionFee(acceptedBidId, activeExtendEventRequest.duration, activeExtendEventRequest._id);
    this.setState({
      displayExtendEventPaymentModal: false,
    });
  }
  /**
   * @description
   * isAdmin is used to check participant is added as
   */
  public isAdmin(participants: any, participantId: string) {
    let isAdmin = false;
    for (const participant of participants) {
      if (participantId === participant['userId'] && participant.isAdmin) {
        isAdmin = true;
        break;
      }
    }
    return isAdmin;
  }
  /**
   * @description function to render participants list for event
   */
  public renderParticipantsList = () => {
    const { _id } = this.state.eventDetails;
    const { isEnded, status, participantsRaw, participants } = this.state.eventDetails.bid[0];
    const { user } = this.props;
    const isParticipant = find(participantsRaw, (item) => {
      return item['userId'] === user['_id'];
    });
    const participantsDisplayList = map(participants, (item: any) => {
      const isAdmin = this.isAdmin(participantsRaw, item._id);
      return {
        isAdmin, firstName: item.firstName, lastName: item.lastName,
        _id: item._id, profileImage: item.profileImage, email: item.email,
        organizationId: item.organizationId, role: item.role,
      };
    });
    participantsDisplayList.sort((a, b) => a.isAdmin ? -1 : 1);
    return map(participantsDisplayList, (item: any) => {
      const fetchedProfileImage = item && item.profileImage !== '' ?
        item.profileImage : profilePng;
      const isParticipantLearner = !(user['role'] === staticConstants.ROLE.ORGANIZATION_LEARNER && !item.organizationId);
      const roleType = item['role'] === staticConstants.ROLE.PROFESSIONAL ? staticConstants.ROLE.PROFESSIONAL
        : item.organizationId ? staticConstants.ROLE.ORGANIZATION_LEARNER : staticConstants.ROLE.ORGANIZATION_ADMIN;
      const participant = find(participantsRaw, us => us['userId'] === item._id);
      return item && isParticipantLearner && (
        <div className={`tag-item footer-add ${(item.isAdmin && item['role'] !== staticConstants.ROLE.PROFESSIONAL) ? 'green-bg' : ''}`}
          key={`tag-${item._id}`}>
          <div className="tag-card-data">
            <h4>{capitalizeEveryWord(item.firstName)} {capitalizeEveryWord(item.lastName)}</h4>
            <p>{`${item.email}`}
            </p>
          </div>
          <span>
            <img className="img-cover" src={fetchedProfileImage} alt="" />
          </span>
          <div className="tag-item-footer">
            {
              user['role'] === staticConstants.ROLE.EXPERT ?
                <React.Fragment>
                  <p className={participant['isFlagged'] ? 'heading-disabled' : ''} onClick={() => this.showFlagUserModal(item._id)}>
                    <span className="icon icon-flag" /> Flag User
                  </p>
                  {
                    isEnded
                      ? <p
                        onClick={() => this.showSessionRatingModal('participantsEngagement', item._id)}>
                        <span className="icon icon-rate" /> Rate Now
                                            </p >
                      : <p onClick={() => this.navigateToChatMessage(item._id)}>
                        <span className="icon icon-message" /> Message
                                           </p>
                  }
                </React.Fragment>
                : ''
            }
            {item.organizationId && user['role'] !== staticConstants.ROLE.EXPERT && item._id !== getMyUserId()
              && !isEnded && status === goalStatus.SCHEDULED
              && isParticipant
              ? <p onClick={() => this.navigateToChatMessage(item._id)}><span className="icon icon-message" /> Message</p>
              : ''}
            <p onClick={() => this.viewProfileDetails(item._id, _id, roleType)}>
              <span className="icon icon-visible" /> View Details
            </p>
          </div>
        </div>
      );
    });
  }

  /**
   * @description showCancelPopup is used show reject bid popup.
   */
  public extendEvent = () => {
    const modalPopupObj = extendEventMsg;
    this.setState({
      modalPopupObj,
      displayExtendEventPopup: true,
    });
  }

  public handleDurationChange = (duration: number) => {
    this.setState({ duration });
  }

  /**
   * @description
   * updateExtendEventStatus is called when someone wants to accept/request for extent event
   */
  public updateExtendEventStatus = (req: any) => {
    const { match: { params }, user: { role } } = this.props;
    const { duration } = this.state;
    this.setState({
      isdDisplayDurationModal: false,
      isDisplayExtendEventRequestPopup: false,
      displayExtendEventPaymentModal: false,
    },            () => {
      this.props.updateExtentEventStatus({
        bidId: params.id,
        status: req,
        duration: Number(duration),
        role,
      });
    });
  }

  /**
   * @description function to render tag list
   */
  public renderTagList = () => {
    const { eventDetails } = this.state;
    const keys = ['domain', 'subDomain', 'category', 'subCategory', 'topic'];
    return keys.map(key => {
      return eventDetails[key] && eventDetails[key].id && <span className="tags-view-only" key={eventDetails[key].id}>{eventDetails[key].name}</span>;
    });
  }

  /**
   * @description function to render tag list
   */
  public isEventStarted = () => {
    const { bid } = this.state.eventDetails;
    if (bid && bid[0].statusTimeline && !bid[0].isEnded) {
      const ind = bid[0].statusTimeline.findIndex((el: { status: string }) => el.status === bidStatus.EVENT_STARTED);
      if (ind > -1) { return true; }
    }
    return false;
  }

  /**
   * @description function to render expert detail
   */
  public renderExpertDetail(item: IBidDetail) {
    if (!item.expertInfo) return null;
    const fetchedProfileImage = item.expertInfo && item.expertInfo.profileImage !== '' ?
      item.expertInfo.profileImage : profilePng;
    const { user } = this.props;
    const { bid, participantsRaw } = this.state.eventDetails;
    const activeBid: IBidDetail = bid[0];
    const { _id, status, isEnded } = activeBid;
    const alreadyFlagged = find(activeBid['flag'], ite => ite.userId === user._id);
    const isParticipant = find(participantsRaw, it => it['userId'] === user['_id']);
    const isSystemAdminRole = user['role'] === staticConstants.ROLE.SYSTEM_ADMIN;
    let averageProfileRating = 0;
    if (item.expertInfo && item.expertInfo.averageRating) {
      item.expertInfo.averageRating = item.expertInfo.averageRating || 0;
      averageProfileRating = Number.isInteger(item.expertInfo.averageRating)
        ? item.expertInfo.averageRating
        : Number(item.expertInfo.averageRating.toFixed(MAGIC_NUMBER.TWO));
    }

    return (
      <Card key={item._id} className="flex-card">
        <CardHeader>
          <CardSubtitle><small>$</small>{item.expertInfo.hourlyRate.current}</CardSubtitle>
        </CardHeader>
        <CardColumns className="rating-view-only d-flex">
          <CardImg src={fetchedProfileImage} alt="Profile Picture" />
          <div className="expert-card-name">
            <h2>{`${item.expertInfo.firstName} ${item.expertInfo.lastName}`}</h2>
            <h4>{item.expertInfo.address}{item.expertInfo.location ? `, ${item.expertInfo.location}` : ''}</h4>
            <StarRatings
              rating={averageProfileRating}
              starHoverColor="#4be59e"
              starEmptyColor="#dcdcdc"
              starRatedColor="#4be59e"
              starDimension="20px"
              starSpacing="2px"
              numberOfStars={MAGIC_NUMBER.FIVE}
              name="sessionRating"
            />
            <p className="star-rating-count">{averageProfileRating}/5</p>
          </div>
        </CardColumns>
        <CardBody>
          <CardText>
            {item.expertInfo.bio}
          </CardText>
        </CardBody>
        <CardFooter>
          {!isSystemAdminRole &&
            <p className={alreadyFlagged ? 'heading-disabled' : ''} onClick={() => this.showFlagUserModal(item.expertInfo._id)}>
              <span className="icon icon-flag" /> Flag User
          </p>}
          {(user['role'] === staticConstants.ROLE.ORGANIZATION_ADMIN ||
            ((user['role'] === staticConstants.ROLE.ORGANIZATION_LEARNER || user['role'] === staticConstants.ROLE.PROFESSIONAL) && isParticipant && isParticipant['isAdmin'])) &&
            <div>
              {status === goalStatus.SCHEDULED && !isEnded && isParticipant ?
                <p onClick={() => this.navigateToChatMessage(item.expertInfo._id)}>
                  <span className="icon icon-message" /> Message
                  </p>
                : ''}
              {
                status === goalStatus.SCHEDULED && isEnded ?
                  <p onClick={() => this.showSessionRatingModal(user['role'] === staticConstants.ROLE.EXPERT ? 'participantsEngagement' : 'expertRating')}>
                    <span className="icon icon-rate" /> Rate Now
                 </p>
                  : ''}
            </div>
          }
          <p onClick={() => this.viewProfileDetails(item.expertInfo._id, _id, staticConstants.ROLE.EXPERT)}>
            <span className="icon icon-visible" /> View Details
          </p>

        </CardFooter>
      </Card>);
  }

  /**
   * @description function to render my rating and review detail
   */
  public renderMyRatingAndReview(item: IBidDetail, isExpertRole: boolean) {
    const { goalAdminForExpert } = this.state;
    const expertProfileImage = isExpertRole && goalAdminForExpert[0]['_id'] && goalAdminForExpert[0]['profileImage'] !== '' ?
      goalAdminForExpert[0]['profileImage'] : profilePng;
    const adminProfileImage = item.expertInfo && item.expertInfo.profileImage !== '' ?
      item.expertInfo.profileImage : profilePng;
    const { adminRatingGoal, expertRatingGoal } = this.state.eventDetails.bid[0];
    const ratingReviewDate = isExpertRole ? adminRatingGoal['createdAt'] : expertRatingGoal['createdAt'];
    return (
      <Media className="review-media gray-bg">
        <Media className="media-image">
          <Media object src={isExpertRole ? expertProfileImage : adminProfileImage} className="img-cover" alt="Generic placeholder image" />
        </Media>
        <Media body>
          <Media heading>
            {isExpertRole ? `${goalAdminForExpert[0]['firstName']} ${goalAdminForExpert[0]['lastName']}` : `${item.expertInfo.firstName} ${item.expertInfo.lastName}`}
          </Media>
          <Media data-text className="date-text">
            {
              ratingReviewDate !== null &&
              <em><><ReactTimeAgo date={new Date(ratingReviewDate)} /></></em>
            }
          </Media>
          <Media header className="rating-header w-100">
            <Media className="star-rating-wrapper">
              <span className="d-block w-100">Session Rating</span>
              <StarRatings
                rating={isExpertRole ? adminRatingGoal['sessionRating'] : expertRatingGoal['overAllQuality']}
                starHoverColor="#4be59e"
                starEmptyColor="#dcdcdc"
                starRatedColor="#4be59e"
                starDimension="12px"
                starSpacing="2px"
                numberOfStars={MAGIC_NUMBER.FIVE}
                name="sessionRating"
              />
            </Media>
            <Media className="star-rating-wrapper">
              <span className="d-block w-100">{isExpertRole ? 'Team Admin Rating' : 'Expert Rating'}</span>
              <StarRatings
                rating={isExpertRole ? adminRatingGoal['expertRating'] : expertRatingGoal['participantsEngagement']}
                starHoverColor="#4be59e"
                starEmptyColor="#dcdcdc"
                starRatedColor="#4be59e"
                starDimension="12px"
                starSpacing="2px"
                numberOfStars={MAGIC_NUMBER.FIVE}
                name="expertRating"
              />
            </Media>
          </Media>
        </Media>
        <Media data-text className="media-text">
          {isExpertRole ? adminRatingGoal['comment'] : expertRatingGoal['comment']}
        </Media>
      </Media>
    );
  }

  /**
   * @description Function to show expert profile
   * @param {string} profileId conatin unique id
   * @param {string} eventId contain unique id
   */
  public viewProfileDetails(profileId: string, eventId: string, role: string) {
    const { user, match: { params: { id } } } = this.props;
    const { participantsRaw } = this.state.eventDetails.bid[0];
    let activeTab = '';
    const isParticipant = find(participantsRaw, (us) => us['userId'] === profileId);
    const isSystemAdminRole = user['role'] === staticConstants.ROLE.SYSTEM_ADMIN;
    const breadCrumbTitle = isSystemAdminRole ? 'Upcoming Events' : 'Events';
    if (isSystemAdminRole) {
      activeTab = this.props['location'] && this.props['state'] && this.props['location']['state']['activeTab'] === staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.ADMIN_LEARNING_GOALS
        ? staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.ADMIN_LEARNING_GOALS
        : staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.PROFESSIONSAL_LEARNING_GOALS;
    } else {
      activeTab = this.state.eventDetails.bid[0].isEnded
        ? staticConstants.EVENT_MANAGEMENT_TAB.PAST_EVENTS
        : staticConstants.EVENT_MANAGEMENT_TAB.UPCOMING_EVENTS;
    }
    let pathName = '';
    let breadcrumb = [];
    switch (role) {
      case staticConstants.ROLE.EXPERT:
        pathName = user.role === staticConstants.ROLE.SYSTEM_ADMIN
          ? `${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.VIEW_PROFILE.PATH}/${profileId}`
          : `${pageRoutes.VIEW_PROFILE_EXPERT.PATH}/${profileId}/${eventId}/complete`;
        breadcrumb = [
          { title: breadCrumbTitle, link: `${pageRoutes.EVENTMANAGEMENT.PATH}` },
          { title: staticConstants.EVENT_MANAGEMENT_ACTIVE_TAB[activeTab], link: `${pageRoutes.EVENTMANAGEMENT.PATH}` },
          { title: this.state.eventDetails.title, link: `${pageRoutes.EVENTMANAGEMENT.PATH}${pageRoutes.EVENTMANAGEMENT.DETAIL}/${this.state.eventDetails._id}`, activeTab },
          { title: 'Expert Profile' },
        ];
        break;
      case staticConstants.ROLE.ORGANIZATION_ADMIN:
        pathName = `${pageRoutes.VIEW_PROFILE_ORGADMIN.PATH}/${profileId}/${eventId}/complete`;
        breadcrumb = [
          { title: breadCrumbTitle, link: `${pageRoutes.VIEW_PROFILE_ORGADMIN.PATH}` },
          { title: staticConstants.EVENT_MANAGEMENT_ACTIVE_TAB[activeTab], link: `${pageRoutes.EVENTMANAGEMENT.PATH}` },
          { title: this.state.eventDetails.title, link: `${pageRoutes.EVENTMANAGEMENT.PATH}${pageRoutes.EVENTMANAGEMENT.DETAIL}/${this.state.eventDetails._id}`, activeTab },
          { title: 'TEAM ADMIN PROFILE' },
        ];
        break;
      case staticConstants.ROLE.ORGANIZATION_LEARNER:
        pathName = `${pageRoutes.VIEW_PROFILE_LEARNER.PATH}/${profileId}/${eventId}/complete`;
        breadcrumb = [
          { title: breadCrumbTitle, link: `${pageRoutes.VIEW_PROFILE_LEARNER.PATH}` },
          { title: staticConstants.EVENT_MANAGEMENT_ACTIVE_TAB[activeTab], link: `${pageRoutes.EVENTMANAGEMENT.PATH}` },
          { title: this.state.eventDetails.title, link: `${pageRoutes.EVENTMANAGEMENT.PATH}${pageRoutes.EVENTMANAGEMENT.DETAIL}/${this.state.eventDetails._id}`, activeTab },
          { title: 'PARTICIPANTS PROFILE' },
        ];
        break;
      case staticConstants.ROLE.PROFESSIONAL:
        pathName = `${pageRoutes.VIEW_PROFILE_ORGADMIN.PATH}/${profileId}/${eventId}/complete`;
        breadcrumb = [
          { title: breadCrumbTitle, link: `${pageRoutes.VIEW_PROFILE_ORGADMIN.PATH}` },
          { title: staticConstants.EVENT_MANAGEMENT_ACTIVE_TAB[activeTab], link: `${pageRoutes.EVENTMANAGEMENT.PATH}` },
          { title: this.state.eventDetails.title, link: `${pageRoutes.EVENTMANAGEMENT.PATH}${pageRoutes.EVENTMANAGEMENT.DETAIL}/${this.state.eventDetails._id}`, activeTab },
          { title: 'PROFESSIONAL PROFILE' },
        ];

    }

    history.push({
      pathname: `${pathName}`,
      state: {
        breadcrumb,
        activeTab,
        isScheduleManagement: user['role'] !== staticConstants.ROLE.EXPERT,
        userRole: role,
        goalId: id,
        isParticipant,
        isExpert: user['role'] === staticConstants.ROLE.EXPERT,
        isSystemAdminRole,
      },
    });
  }

  /**
   * @description Function to show flag user popup
   * @param {string} userId conatin unique id
   */
  public showFlagUserModal = (userId: string) => {
    const obj = {
      userId,
    };
    this.setState({
      displayFlagUserModal: true,
      modalPopupObj: obj,
    });
  }

  /**
   * @description Function to show Validate expert  popup
   * @param {string} userId conatin unique id
   */
  public showValidateAttendanceModal = () => {
    const { user } = this.props;
    const obj = {
      userId: user._id,
      userRole: user['role'],
    };
    this.setState({
      displayValidateAttendanceModal: true,
      modalPopupObj: obj,
    });
  }

  /**
   * @description Function to hide Validate expert popup
   */
  public hideValidateAttendanceModal = () => {
    this.setState(
      {
        displayValidateAttendanceModal: false,
        modalPopupObj: null,
      },
      () => this.showSessionRatingModal('both'),
    );
  }
  /**
   * @description Function to hide Validate expert popup
   */
  public handleValidateAttendanceModal = (obj: object) => {
    const { match: { params } } = this.props;
    obj['bidId'] = params.id;
    this.props.validateAttendance(obj);
    this.hideValidateAttendanceModal();

  }

  /**
   * @description Function to hide flag user popup
   */
  public hideFlagUserModal = () => {
    this.setState({
      displayFlagUserModal: false,
      modalPopupObj: null,
    });
  }

  /**
   * @description Function to show flag user popup
   * @param {string} type conatin unique value
   */
  public showSessionRatingModal = (type: string, userId?: string) => {
    const { eventDetails } = this.state;
    const activeBid = eventDetails.bid[0];
    const { user } = this.props;
    const obj = {};
    obj['type'] = type;
    obj['userRole'] = user['role'];
    if (user['role'] === staticConstants.ROLE.EXPERT) {
      obj['overAllQuality'] = activeBid['expertRatingGoal']['overAllQuality'];
      obj['participantsEngagement'] = activeBid['expertRatingGoal']['participantsEngagement'];
      obj['comment'] = activeBid['expertRatingGoal']['comment'];
      obj['userId'] = userId;

    } else {
      obj['sessionRating'] = activeBid['adminRatingGoal']['sessionRating'];
      obj['expertRating'] = activeBid['adminRatingGoal']['expertRating'];
      obj['comment'] = activeBid['adminRatingGoal']['comment'];
    }

    this.setState({
      displaySessionRatingModal: true,
      sessionModalPopupObj: obj,
    });
  }

  /**
   * @description Function to hide flag user popup
   */
  public hideSessionRatingModal = () => {
    this.setState({
      displaySessionRatingModal: false,
      sessionModalPopupObj: null,
    });
  }

  /**
   * @description Function to handle flag user submit reuest
   */
  public handleFlagUserSubmit = (obj: object) => {
    const { match: { params }, user: { _id } } = this.props;
    obj['bidId'] = params.id;
    obj['flaggedBy'] = _id;
    this.props.flagUser(obj);
    this.hideFlagUserModal();
  }

  /**
   * @description
   * hideExtendDurationModal is used to hide the Extend Duration Modal Window
   */
  public hideExtendDurationModal = () => {
    this.setState({
      isdDisplayDurationModal: false,
    });
  }

  /**
   * @description Function to handle session rating submit reuest
   */
  public handleSessionRatingSubmit = (obj: object) => {
    const { match: { params } } = this.props;
    obj['bidId'] = params.id;
    this.props.ratingGoal(obj);
    this.hideSessionRatingModal();
  }

  public navigateToChatMessage = (userId: string) => {
    history.push({
      pathname: pageRoutes.MESSAGE_BOARD.PATH,
      state: {
        userId,
      },
    });
  }
  public updateDisputeStatus = (status: string, bidId: string) => {
    const reqObj = {
      goalId: this.state.eventDetails._id,
      bidId: [bidId],
      status,
    };
    const redirect = `/${staticConstants.ROLE.SYSTEM_ADMIN}${pageRoutes.PAYMENT.PATH}?tab=${staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.DISPUTE_PAYMENTS}`;
    history.push(redirect);
    this.props.updateDisputeStatus(reqObj, null, redirect);
  }

  public componentDidUpdate(prevProps: IProps) {
    const { eventDetails, isGoalAdmin } = this.state;
    if (eventDetails.bid && eventDetails.bid[0].extendMeeting && eventDetails.bid[0].extendMeeting.length > MAGIC_NUMBER.ZERO && isGoalAdmin && (!this.props.status ||
      !this.props.status['updateExtendEventStatusRequested'])) {
      const paidExtendedEvent = eventDetails.bid[0].extendMeeting.find(el => el.status === extendEventStatus.PAID);
      if (paidExtendedEvent && !this.state.updatingExtendEventStatus) {
        this.updateExtendEventStatus(extendEventStatus.PAID);
        this.setState({ updatingExtendEventStatus: true })
      }
    }
  }

  public render() {
    const { eventDetails, modalPopupObj, displayExtendEventPopup, displayFlagUserModal, arrowDownExpert,
      arrowDownParticipant, displaySessionRatingModal, arrowDownOrgAdmin, isGoalAdmin, duration,
      displayValidateAttendanceModal, sessionModalPopupObj, isdDisplayDurationModal, canExtendEvent, activeExtendEventRequest,
      isDisplayExtendEventRequestPopup, displayCanNotStartEvent, openGoalAdminModal, openChangeParticipantModal, displayExtendEventPaymentModal, arrowDownAttendance,
      displayCancelPopup,
    } = this.state;
    const { bid } = this.state.eventDetails;
    const activeBid: IBidDetail = bid && bid[0];

    const { adminRatingGoal = null, expertRatingGoal = null, isEnded = false, adminAttendence = null, expertAttendence = null } = activeBid || {};
    let ratingCount = 0;
    if (adminRatingGoal && adminRatingGoal['sessionRating'] > MAGIC_NUMBER.ZERO) {
      ratingCount += 1;
    }
    if (adminRatingGoal && adminRatingGoal['expertRating'] > MAGIC_NUMBER.ZERO) {
      ratingCount += 1;
    }
    if (expertRatingGoal && expertRatingGoal['overAllQuality'] > MAGIC_NUMBER.ZERO) {
      ratingCount += 1;
    }
    if (expertRatingGoal && expertRatingGoal['participantsEngagement'] > MAGIC_NUMBER.ZERO) {
      ratingCount += 1;
    }

    const overAllRating = adminRatingGoal && (adminRatingGoal['sessionRating'] + expertRatingGoal['overAllQuality'] +
      adminRatingGoal['expertRating'] + expertRatingGoal['participantsEngagement']) / (ratingCount || 1);

    let activeTab = '';
    const { participants, _id } = this.state.eventDetails;
    const { user, learnerList } = this.props;
    const orgAdminProfile = participants.filter(o1 => o1['organizationId'] === null);
    const { pathname } = this.props.location;
    const shareUrl = `${process.env.REACT_APP_WEB_URL}${pathname}`;
    const isExpertRole = user['role'] === staticConstants.ROLE.EXPERT;
    const eventTimeline = isExpertRole ?
      (activeBid && activeBid.statusTimeline ? activeBid.statusTimeline : []) : this.state.eventDetails && this.state.eventDetails.statusTimeline &&
        [...this.state.eventDetails.statusTimeline,
          ...activeBid.statusTimeline.filter(el => [bidStatus.ACCEPTED, bidStatus.SCHEDULE, bidStatus.EVENT_COMPLETED, bidStatus.EVENT_CANCELLED].indexOf(el.status) > -1)];
    const isSystemAdminRole = user['role'] === staticConstants.ROLE.SYSTEM_ADMIN;
    if (isSystemAdminRole) {
      activeTab = this.props['location'] && this.props['state'] &&
        (this.props['location']['state']['activeTab'] === staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.ADMIN_LEARNING_GOALS ?
          staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.ADMIN_LEARNING_GOALS : staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.PROFESSIONSAL_LEARNING_GOALS);
      if (this.props.location.state && this.props['location']['state']['activeTab'] === 'payments') {
        activeTab = 'payments';
      }
    } else {
      activeTab = isEnded ? staticConstants.EVENT_MANAGEMENT_TAB.PAST_EVENTS : staticConstants.EVENT_MANAGEMENT_TAB.UPCOMING_EVENTS;
    }

    const expertProfileImage = (activeBid && activeBid['userId'] && activeBid['userId']['profileImage'] !== '') ?
      activeBid['userId']['profileImage'] : profilePng;

    const getRequestDate = (status: string) => {
      if (!eventTimeline) { return; }
      const timeLine = eventTimeline.find(el => el.status === status);
      if (timeLine) {
        const time = moment(timeLine.createdAt).format(staticConstants.TIME_FORMAT);
        const date = moment(timeLine.createdAt).format(staticConstants.DATE_FORMAT);
        if (timeLine.status === goalStatus.SCHEDULED || timeLine.status === goalStatus.RESCHEDULED) {
          return `${moment(activeBid.proposedDate).format(staticConstants.DATE_FORMAT)}, at ${moment(activeBid.proposedTime).format(staticConstants.TIME_FORMAT)}`;
        }
        return `${date} ${((timeLine.status === goalStatus.SCHEDULED || timeLine.status === goalStatus.EVENT_COMPLETED ||
          timeLine.status === goalStatus.EVENT_CANCELLED) ? `, at ${time}` : '')}`;
      }
      return null;
    };

    const renderSystemAdminAction = (bid) => {
      return <div className="card-footer d-flex justify-content-end mt-0">
        <Button color="primary" className="btn mr-2"
          onClick={() => this.updateDisputeStatus(staticConstants.PAYMENTS_TYPE.REFUNDED_PAYMENTS, bid._id)}>Payment Refunded</Button>
        <Button color="primary" className="btn mr-2"
          onClick={() => this.updateDisputeStatus(staticConstants.PAYMENTS_TYPE.RELEASED_PAYMENTS, bid._id)}>Payment Released</Button>
        {this.props.eventData.sessionRecording && this.props.eventData.sessionRecording.download_url &&
          <Button color="primary" className="btn mr-2"
            onClick={() => this.downloadEventSession()}>Download Session</Button>}
      </div>;
    };
    const renderTimeLine = () => {
      const bidCurrentStatus = eventDetails.bid[0].status;
      let timeLineHeader = 'Event Scheduled';
      if (activeTab === staticConstants.EVENT_MANAGEMENT_TAB.PAST_EVENTS) {
        timeLineHeader = bidCurrentStatus === goalStatus.EVENT_CANCELLED ? 'Event Cancelled' : 'Event Completed';
      }
      return <div className="timeline-view">
        <h2 className={`heading roboto-medium ${(bidCurrentStatus === goalStatus.REJECT || bidCurrentStatus === goalStatus.EVENT_CANCELLED) ? 'reject' : 'success'}
                    ${bidCurrentStatus === goalStatus.RFP_REQUEST || bidCurrentStatus === goalStatus.SCHEDULED ? 'selected' : ''}`}>
          {timeLineHeader}
        </h2>
        <div className={`timeline-row created-row`}>
          <small>{getGoalStatusText(goalStatus.CREATED, user['role'])}</small>
          <p>{getRequestDate(goalStatus.CREATED)}</p>
          <span className="icon time-icon" />
        </div>
        {isExpertRole ?
          bidCurrentStatus !== goalStatus.EVENT_CANCELLED ? renderLearnerTimeLine(goalStatusExpertTimeLine) :
            renderLearnerTimeLine(goalStatusExpertCancelTimeLine)
          :
          <React.Fragment>
            {eventDetails.orgId ?
              renderLearnerTimeLine(goalStatusLearnerTimeline) : bidCurrentStatus === goalStatus.EVENT_CANCELLED ?
                renderLearnerTimeLine(goalStatusEventCancelTimeline) :
                renderLearnerTimeLine(goalStatusOrgAdminTimeline)}
          </React.Fragment>
        }
      </div>;
    };
    const renderLearnerTimeLine = (listArray: string[]) => {
      const html = [];
      const arr = cloneDeep(listArray);
      let acceptedrejected = false;
      if (arr.includes('deleted')) {
        const pos = arr.indexOf(goalStatus.DELETED);
        // tslint:disable-next-line: no-unused-expression
        if (pos >= 0) arr.splice(pos, 1);
      }
      const lastEventName = eventTimeline && eventTimeline[eventTimeline.length - 1];
      if (lastEventName && lastEventName['status'] === 'schedule') {
        const pos = arr.indexOf(goalStatus.EVENT_COMPLETED);
        // tslint:disable-next-line: no-unused-expression
        if (pos >= 0) arr.splice(pos, 1, goalStatus.SCHEDULED);
      } else if (lastEventName && lastEventName['status'] === 'eventCompleted') {
        const pos = arr.indexOf(goalStatus.SCHEDULED);
        // tslint:disable-next-line: no-unused-expression
        if (pos >= 0) arr.splice(pos, 1, goalStatus.EVENT_COMPLETED);
      }
      arr.forEach((element, index) => {
        const currStatus = find(eventTimeline, (timeline) => {
          return timeline.status === element;
        });
        let selectedClass = null;
        if (currStatus) {
          selectedClass = 'active';
        }
        const goalStatusText = getGoalStatusText(element, user['role']);
        if (currStatus && (currStatus['status'] === goalStatus.REJECT ||
          currStatus['status'] === goalStatus.EVENT_CANCELLED)
          && selectedClass) {
          selectedClass = `${selectedClass} rejected`;
        }
        if (currStatus && (currStatus['status'] === goalStatus.RFP_REQUEST
          || currStatus['status'] === goalStatus.SCHEDULED || currStatus['status'] === goalStatus.PROPOSED
          || currStatus['status'] === goalStatus.EVENT_COMPLETED)) {
          selectedClass = 'active selected';
        }
        if (currStatus && (currStatus['status'] === goalStatus.ACCEPT
          || currStatus['status'] === goalStatus.EXPERT_ACCEPT)) {
          selectedClass = 'active';
        }
        const icon = (currStatus && currStatus['status'] === goalStatus.SCHEDULED) ? 'play-icon' : 'check-icon';

        if (eventDetails.status === goalStatus.REQUEST_SEND_BY_LERNER &&
          (element === goalStatus.ACCEPT || element === goalStatus.REJECT)) {
          if (!acceptedrejected) {
            html.push(<div key={index} className={`timeline-row ${selectedClass}`}>
              <small>Accepted/Rejected</small>
              <p>{getRequestDate(goalStatus.REQUEST_SEND_BY_LERNER)}</p>
              <span className="icon check-icon" />
            </div>);
          }
          acceptedrejected = true;
        } else {
          html.push(
            <div key={index} className={`timeline-row ${selectedClass}`}>
              <small>{goalStatusText}</small>
              <p>{getRequestDate(element)}</p>
              <span className={`icon ${icon}`} />
            </div>,
          );
        }
      });
      return html;
    };
    return eventDetails && (
      <div className="form-container d-flex">
        <Fragment>
          <Header />
          <Sidebar {...this.props} />
          <div className="dashboard-wrapper">
            <Back {...this.props} />
            <div className="dashboard-content shadow mb-0">
              <div className="learning-goal-wrapper">
                <div className="payment-detail-head">
                  <h2 className="heading heading-rg roboto-medium d-inline-block heading-border">
                    {eventDetails.eventType}
                  </h2>
                  <div className="payment-id-row">
                    {isSystemAdminRole && this.props.location && this.props.location.state &&
                      this.props.location.state.activeSubTab === staticConstants.PAYMENTS_TYPE.REFUNDED_PAYMENTS ?
                      <div className="expert-count-text">
                        Payment Refunded
                      </div> : isSystemAdminRole && this.props.location &&
                        this.props.location.state && this.props.location.state.activeSubTab === staticConstants.PAYMENTS_TYPE.RELEASED_PAYMENTS ?
                        <div className="expert-count-text">
                          Payment Released
                      </div> : ''}
                    {isSystemAdminRole && this.props.location && this.props.location.state && this.props.location.state.parentTab === staticConstants.PARENT_PAYMENT_TAB &&
                      <div className="expert-count payment-released-id">
                        {eventDetails._id}
                      </div>}
                  </div>
                </div>
                <div className="goal-view-wrapper">
                  <h2 className="heading heading-lg roboto-medium">{eventDetails.title} <span>
                    {eventDetails.modeOfDevivery}</span>
                  </h2>
                  {eventDetails.topic && <em>({eventDetails.topic.name})</em>}
                  <p>{eventDetails.description}</p>
                  <div className="form-details">
                    <Row>
                      <Col md="12" lg="9">
                        <div className="form-data">
                          <Row>
                            <Col xs="12" md="4">
                              <span>Domain</span>
                              <p>{eventDetails.domain.name}</p>
                            </Col>
                            <Col xs="12" md="4">
                              <span>Sub-Domain</span>
                              <p>{eventDetails.subDomain.name}</p>
                            </Col>
                            {eventDetails.category &&  <Col xs="12" md="4">
                              <span>Category</span>
                              <p>{eventDetails.category.name}</p>
                            </Col>}
                            {eventDetails.subCategory && <Col xs="12" md="4">
                              <span>Sub-Category</span>
                              <p>{eventDetails.subCategory.name}</p>
                            </Col>}
                            <Col xs="12" md="4">
                              <span>No. of Participants</span>
                              <p>{eventDetails.participants.length}</p>
                            </Col>
                            </Row>
                            <Row>
                            {activeBid && <Col xs="12" md="4">
                              <span>Duration</span>
                              <p>{activeBid.duration}
                                {Number(activeBid.duration) > MAGIC_NUMBER.ONE ?
                                  ` hrs` : ` hr`}</p>
                            </Col>}
                            {(activeTab === staticConstants.EVENT_MANAGEMENT_TAB.PAST_EVENTS ||
                              activeTab === staticConstants.EVENT_MANAGEMENT_TAB.UPCOMING_EVENTS) ||
                              (this.props.location && this.props.location.state &&
                                this.props.location['state']['parentTab'] === staticConstants.PARENT_PAYMENT_TAB)
                              ? <Col xs="12" md="4" >
                                <span>Session Fee</span>
                                {activeBid && activeBid.sessionFee
                                  ? <p>{`$${formatCurrency(activeBid.sessionFee)}`}</p>
                                  : <p>{`$0`}</p>}
                              </Col> : ''}
                            {(activeTab === staticConstants.EVENT_MANAGEMENT_TAB.PAST_EVENTS) ||
                              (this.props.location && this.props.location.state && this.props.location['state']['parentTab'] === staticConstants.PARENT_PAYMENT_TAB)
                              ? <Col xs="12" md="4" >
                                <span>Expert Fee</span>
                                {activeBid && activeBid.expertEarn
                                  ? <p>{`$${formatCurrency(activeBid.expertEarn)}`}</p>
                                  : <p>{`$0`}</p>}
                              </Col> : ''}
                            {(activeTab === staticConstants.EVENT_MANAGEMENT_TAB.PAST_EVENTS) ||
                              (this.props.location && this.props.location.state && this.props.location['state']['parentTab'] === staticConstants.PARENT_PAYMENT_TAB)
                              ? <Col xs="12" md="4" >
                                <span>Service Fee (Tax Incl.)</span>
                                {activeBid && activeBid.commission
                                  ? <p>{`$${formatCurrency(activeBid.commission)}`}</p>
                                  : <p>{`$0`}</p>}
                              </Col> : ''}
                            <Col xs="12">
                              <span>Tags</span>
                              <div className="tags-wrapper">
                                {this.renderTagList()}
                              </div>
                            </Col>
                            {(activeTab === staticConstants.EVENT_MANAGEMENT_TAB.PAST_EVENTS && eventDetails.status !== goalStatus.EVENT_CANCELLED) || activeTab === 'payments' ?
                              <Col xs="12">
                                <Row className="star-rating-wrapper">
                                  <Col md="12" lg="4">
                                    <span className="d-block w-100"> Overall Session Rating
                                    </span>
                                    <StarRatings
                                      rating={overAllRating}
                                      starHoverColor="#4be59e"
                                      starEmptyColor="#eaeaea"
                                      starRatedColor="#4be59e"
                                      starDimension="20px"
                                      starSpacing="5px"
                                      numberOfStars={MAGIC_NUMBER.FIVE}
                                      name="sessionRating"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              : ''}
                          </Row>
                        </div>
                      </Col>
                      <Col md="12" lg="3">
                        {bid && bid[0] !== null && renderTimeLine()}
                      </Col>
                    </Row>
                  </div>
                  {bid && bid[0] !== null && activeTab === staticConstants.EVENT_MANAGEMENT_TAB.PAST_EVENTS
                    && ([staticConstants.ROLE.ORGANIZATION_ADMIN, staticConstants.ROLE.PROFESSIONAL].indexOf(user.role) > -1)
                    && <div className="rehire-expert" onClick={() =>
                      history.push(`${pageRoutes.GOALMANAGEMENT.PATH}${pageRoutes.GOALMANAGEMENT.EDIT}/${eventDetails._id}`)} >
                      <div className="rehire-expert-btn">
                        <span className="rehire-expert-profile">
                          <img src={expertProfileImage ? expertProfileImage : profilePng} className="img-cover" alt="profile" />
                        </span>
                        <div className="rehire-expert-title">
                          <span>Book another session</span>
                        </div>
                      </div>
                    </div>}
                </div>
              </div>
              {user.role === staticConstants.ROLE.SYSTEM_ADMIN && eventDetails.isDispute && renderSystemAdminAction(bid[0])}
              {activeTab === staticConstants.EVENT_MANAGEMENT_TAB.UPCOMING_EVENTS
                ? <div className="card-footer d-flex justify-content-end mt-0">

                  <> <Button className="btn btn-icon mr-2" onClick={() => this.openShareEventModal()}><span className="icon icon-share" />Share Event</Button></>
                  
                  { eventDetails?.bid && eventDetails?.bid[0] && activeBid.isPast && this.canMarkAttendance(eventDetails, isGoalAdmin) &&
                    <Button color="primary" onClick={() => this.setState({
                      displayValidateAttendanceModal: true,
                      modalPopupObj: {userId: user._id,
                      userRole: user.role}})} className="btn mr-2">
                     Mark Attendance </Button>
                  }
                  {
                    !isSystemAdminRole && eventDetails.status !== goalStatus.EVENT_CANCELLED &&
                    this.props.eventData.sessionRecording && this.props.eventData.sessionRecording.download_url &&
                    <Button color="primary" onClick={() => this.downloadEventSession()} className="btn">
                      Download Session </Button>
                  }
                  {activeBid && activeBid.zoomEvent
                    && !activeBid.zoomEvent.isEnded &&
                    (canExtendEvent && this.isEventStarted()) && (isGoalAdmin || user.role === staticConstants.ROLE.EXPERT) &&
                    <Button color="primary" className="btn mr-2"
                      onClick={() => { this.extendEvent(); }}> Extend Event
                    </Button>
                  }
                  {activeBid && activeBid.zoomEvent && !activeBid.isPast
                    && !activeBid.zoomEvent.isEnded &&
                    <Button color="primary" className="btn" onClick={() => this.startZoomEvent()}>
                      {isExpertRole ? 'Start Event' : 'Join Event'}
                    </Button>
                  }
                  {!this.isEventStarted() && activeBid && !activeBid.isPast && !eventDetails.isDispute &&
                    (user.role === staticConstants.ROLE.ORGANIZATION_ADMIN || user.role === staticConstants.ROLE.PROFESSIONAL) &&
                    <Button className="btn btn-icon danger btn btn-secondary ml-2" onClick={() => this.showCancelPopup()}>
                      <span className="icon icon-cross"/> Cancel Event
                 </Button>}
                  {displayCancelPopup ?
                    <ConfirmatioPopup isOpenModal={displayCancelPopup} modalPopupObj={modalPopupObj}
                      hideModal={() => this.setState({ displayCancelPopup: false })} onClickYes={() => this.cancelEvent(eventDetails)} />
                    : ''}
                </div>
                : <div className={`card-footer d-flex  justify-content-end mt-0 ${isSystemAdminRole ? 'd-none' : ''}`}>
                  {(isExpertRole || isGoalAdmin) && eventDetails.status !== goalStatus.EVENT_CANCELLED && <Button color="primary" className="btn mr-2"
                    onClick={() => this.showSessionRatingModal(isExpertRole ? 'overAllQuality' : 'sessionRating')}>
                    Session Rating </Button>}
                  {
                    !isSystemAdminRole && eventDetails.status !== goalStatus.EVENT_CANCELLED &&
                    this.props.eventData.sessionRecording && this.props.eventData.sessionRecording.download_url &&
                    <Button color="primary" onClick={() => this.downloadEventSession()} className="btn">
                      Download Session </Button>
                  }
                </div>}
            </div>
            {(isSystemAdminRole || ((user['role'] === staticConstants.ROLE.ORGANIZATION_ADMIN || user['role'] === staticConstants.ROLE.PROFESSIONAL) &&
              activeTab === staticConstants.EVENT_MANAGEMENT_TAB.UPCOMING_EVENTS)) && bid  ?
              <div className="participant-wrapper box-shadow white-bg mt-4 mb-0">
                <h2 className="heading heading-rg roboto-medium collapse-header">
                  Bid Details </h2>
                <div className="participant-card-wrapper proposed-card-wrapper form-details m-0">
                  <Row className="w-100 form-data">
                    <Col xs="12" md="3">
                      <span>Proposed Date</span>
                      <p>{bid[0] && moment(bid[0]['proposedDate']).format(staticConstants.DATE_FORMAT)} </p>
                    </Col>
                    <Col xs="12" md="3">
                      <span>Proposed Time</span>
                      <p>{bid[0] && moment(bid[0]['proposedTime']).format(staticConstants.TIME_FORMAT)}</p>
                    </Col>
                    <Col xs="12" md="3" />
                    <Col xs="12" className="mb-0">
                      <span>Bid Details</span>
                      <p>{bid[0] && bid[0]['bidDetails']}</p>
                    </Col>
                  </Row>
                </div>
              </div>
              : ''}

            {user['role'] === staticConstants.ROLE.SYSTEM_ADMIN && activeTab === 'payments' && (expertAttendence || adminAttendence) &&
              <div className="participant-wrapper box-shadow white-bg mt-4 mb-0">
                <h2 className="heading heading-rg roboto-medium collapse-header  cursor-pointer" id="attendance_toggler"
                  onClick={() => this.setState({ arrowDownAttendance: !arrowDownAttendance })}
                  onTouchStart={() => this.setState({ arrowDownAttendance: !arrowDownAttendance })}
                >
                  Attendances
                  <span className={`icon icon-arrow-down-sign-to-navigate ${arrowDownAttendance ? 'up' : ''}`} />
                </h2>
                <UncontrolledCollapse toggler="#attendance_toggler" isOpen={arrowDownAttendance} >
                  <div className="collapse-body">
                    <div className="participant-card-wrapper attendances-card-wrapper">
                      {adminAttendence && adminAttendence.comment &&
                        <div className="attendances-cards">
                          <h3 className="heading heading-sm roboto-medium mb-3">Attendance for Expert</h3>
                          <div className="card-wrapper">
                            <Row>
                              <Col xs="12" md="6">
                                <span>Expert attend learning event?</span>
                                <p>{adminAttendence.isExpertAttended ? 'Yes' : 'No'}</p>
                              </Col>
                              <Col xs="12" md="6">
                                <span>Expert deliver learning event?</span>
                                <p>{adminAttendence.isExpertDeliver ? 'Yes' : 'No'}</p>
                              </Col>
                              <Col xs="12">
                                <span>Expert deliver  learning event?</span>
                                <p>{adminAttendence.comment}</p>
                              </Col>
                            </Row>
                          </div>
                        </div>}

                      {expertAttendence && expertAttendence.comment &&
                        <div className="attendances-cards">
                          <h3 className="heading heading-sm roboto-medium mb-3">Attendance for Team Admin</h3>
                          <div className="card-wrapper">
                            <Row>
                              <Col xs="12" md="6">
                                <span>Participant attend learning event?</span>
                                <p>{expertAttendence.isParticipantsAttended ? 'Yes' : 'No'}</p>
                              </Col>
                              <Col xs="12" md="6">
                                <span>Expert able to deliver the learning event?</span>
                                <p>{expertAttendence.isAbleToDeliver ? 'Yes' : 'No'}</p>
                              </Col>
                              <Col xs="12">
                                <span>Expert deliver  learning event?</span>
                                <p>{expertAttendence.comment}</p>
                              </Col>
                            </Row>
                          </div>
                        </div>}

                    </div>
                  </div>
                </UncontrolledCollapse>
              </div>
            }

            {bid && user['role'] !== staticConstants.ROLE.EXPERT ?
              <div className="participant-wrapper box-shadow white-bg mt-4 mb-0">
                <h2 className="heading heading-rg roboto-medium collapse-header  cursor-pointer" id="expert_toggler"
                  onClick={() => this.setState({ arrowDownExpert: !arrowDownExpert })}
                  onTouchStart={() => this.setState({ arrowDownExpert: !arrowDownExpert })}
                >
                  Expert
                  <span className={`icon icon-arrow-down-sign-to-navigate ${arrowDownExpert ? 'up' : ''}`} />
                </h2>
                <UncontrolledCollapse toggler="#expert_toggler" isOpen={arrowDownExpert} >
                  <div className="collapse-body">
                    <div className="participant-card-wrapper">
                      {this.renderExpertDetail(bid[0])}
                    </div>
                  </div>
                </UncontrolledCollapse>
              </div>
              : ''}
            {orgAdminProfile.length && user['role'] === staticConstants.ROLE.ORGANIZATION_LEARNER ?
              <div className="participant-wrapper box-shadow white-bg mt-4 mb-0">
                <h2 className="heading heading-rg roboto-medium collapse-header  cursor-pointer" id="expert_toggler"
                  onClick={() => this.setState({ arrowDownOrgAdmin: !arrowDownOrgAdmin })}
                  onTouchStart={() => this.setState({ arrowDownOrgAdmin: !arrowDownOrgAdmin })}
                >
                  Team Admin Profile
                  <span className={`icon icon-arrow-down-sign-to-navigate ${arrowDownOrgAdmin ? 'up' : ''}`} />
                </h2>
                <UncontrolledCollapse toggler="#expert_toggler" isOpen={arrowDownOrgAdmin} >
                  <div className="collapse-body">
                    <div className="participant-card-wrapper">
                      <div className="tag-item footer-add green-bg" key={orgAdminProfile[0]['_id']}>
                        <div className="tag-card-data">
                          <h4>{`${orgAdminProfile[0]['firstName']} ${orgAdminProfile[0]['lastName']}`}</h4>
                          <p>Team Admin</p>
                        </div>
                        <span>
                          <img src={orgAdminProfile[0]['profileImage'] ?
                            orgAdminProfile[0]['profileImage'] : profilePng} alt="" className="img-cover" />
                        </span>
                        <div className="tag-item-footer">
                          <p onClick={() => this.navigateToChatMessage(orgAdminProfile[0]['_id'])}><span className="icon icon-message" /> Message</p>
                          <p onClick={() => this.viewProfileDetails(orgAdminProfile[0]['_id'], _id, staticConstants.ROLE.ORGANIZATION_ADMIN)}>
                            <span className="icon icon-visible" /> View Details
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </UncontrolledCollapse>
              </div>
              : ''}
            {participants.length ?
              <div className="participant-wrapper box-shadow white-bg mt-4 mb-0">
                <h2 className="heading heading-rg roboto-medium collapse-header cursor-pointer" id="Participant_toggler"
                  onClick={() => this.setState({ arrowDownParticipant: !arrowDownParticipant })}
                  onTouchStart={() => this.setState({ arrowDownParticipant: !arrowDownParticipant })}
                >
                  Participants
                  <span className={`icon icon-arrow-down-sign-to-navigate ${arrowDownParticipant ? 'up' : ''}`} />
                </h2>
                <UncontrolledCollapse toggler="#Participant_toggler" isOpen={arrowDownParticipant}>
                  <div className="collapse-body">
                    <div className="participant-card-wrapper">
                      {this.renderParticipantsList()}
                    </div>
                  </div>

                  <div className="add-admin-footer justify-content-end">
                    {participants.length > MAGIC_NUMBER.ONE && user.role === staticConstants.ROLE.ORGANIZATION_ADMIN && !this.isEventStarted() && activeBid && activeBid.isEnded &&
                      <Button className="btn btn-primary mr-4 my-3" onClick={this.openChangeParticipantModal}>Change Participant</Button>
                    }

                    {user.role === staticConstants.ROLE.ORGANIZATION_ADMIN && !this.isEventStarted() && activeBid && activeBid.isEnded &&
                      <Button className="btn btn-primary mr-4 my-3" onClick={this.openGoalAdminModal}>Select Event Lead</Button>
                    }
                  </div>
                </UncontrolledCollapse>
              </div>
              : ''}
            {bid && activeTab === staticConstants.EVENT_MANAGEMENT_TAB.PAST_EVENTS && eventDetails.status !== goalStatus.EVENT_CANCELLED ?
              <div className="participant-wrapper box-shadow white-bg mt-4 mb-0">
                <div className="participant-wrapper box-shadow white-bg mt-4 mb-0">
                  <h2 className="heading heading-rg roboto-medium collapse-header">My Reviews and Ratings</h2>
                  <div className="participant-card-wrapper">
                    {this.renderMyRatingAndReview(bid[0], isExpertRole)}
                  </div>
                </div>
              </div>
              : ''}

          </div>
          {displayExtendEventPopup &&
            <ConfirmatioPopup isOpenModal={displayExtendEventPopup} modalPopupObj={modalPopupObj}
              hideModal={() => this.setState({ displayExtendEventPopup: false })}
              onClickYes={() => this.setState({ displayExtendEventPopup: false, isdDisplayDurationModal: true })} />}

          {displayExtendEventPaymentModal &&
            <ConfirmatioPopup isOpenModal={displayExtendEventPaymentModal}
              modalPopupObj={extendEventPaymentModal}
              //   hideModal={this.cancelExtendEventPayment}
              hideModal={() => {
                this.setState({ displayExtendEventPaymentModal: false }, () => {
                  this.updateExtendEventStatus(extendEventStatus.CANCEL);
                });
              }}
              onClickYes={this.proceedeExtendEventPayment} />}

          {displayFlagUserModal ?
            <FlagUserPopUp isOpenModal={displayFlagUserModal}
              modalPopupObj={modalPopupObj}
              hideModal={this.hideFlagUserModal}
              onClickYes={this.handleFlagUserSubmit} />
            : ''}

          {isDisplayExtendEventRequestPopup &&
            <EventExtendConfirmPopup isOpenModal={isDisplayExtendEventRequestPopup}
              modalPopupObj={{
                title: `Event Extend`,
                body: 'Do you want to continue?',
                areYouSure: '',
                yesBtnText: 'Yes',
                noBtnText: 'No',
                yesBtnClass: 'btn-primary',
                noBtnClass: '',
                duration: activeExtendEventRequest ? activeExtendEventRequest.duration : 0,
              }}
              acceptedBidId={this.state.acceptedBidId}
              role={user.role}
              getExtendSessionFee={this.props.getExtendSessionFee}
              hideModal={() => this.updateExtendEventStatus(extendEventStatus.CANCEL)}
              onClickYes={() => this.updateExtendEventStatus(extendEventStatus.ACCEPT)} />}

          {displaySessionRatingModal ?
            <SessionRatingPopUp isOpenModal={displaySessionRatingModal}
              modalPopupObj={sessionModalPopupObj}
              hideModal={this.hideSessionRatingModal}
              onClickYes={this.handleSessionRatingSubmit} />
            : ''}
          {
            displayValidateAttendanceModal ?
              <AttendanceContainerPopup isOpenModal={displayValidateAttendanceModal}
                modalPopupObj={modalPopupObj} hideModal={this.hideValidateAttendanceModal}
                onClickSubmit={this.handleValidateAttendanceModal} /> : ''
          }

          <Modal isOpen={this.state.isShareEventModalOpen} className="share-modal">
            <ModalHeader toggle={() => this.hideShareEventModal()}>Share Event</ModalHeader>
            <ModalBody>
              <div className="participaint-list">
                <FacebookShareButton url={shareUrl} quote={eventDetails.title}>
                  <FacebookIcon size={MAGIC_NUMBER.THIRTY} round={false} logoFillColor="#4267b2" /> Share With Facebook
                                </FacebookShareButton>
                <TwitterShareButton url={shareUrl} title={eventDetails.title}>
                  <TwitterIcon size={MAGIC_NUMBER.THIRTY} round={false} logoFillColor="#1da1f2" /> Share With Twitter
                                </TwitterShareButton>
                <LinkedinShareButton url={shareUrl} title={eventDetails.title}>
                  <LinkedinIcon size={MAGIC_NUMBER.THIRTY} round={false} logoFillColor="#0077b5" /> Share With LinkedIn
                                </LinkedinShareButton>
              </div>
            </ModalBody>
            <div className="search-bar">
              <span>Copy this URL to share</span>
              <CopyToClipboard text={shareUrl}
                onCopy={() => this.setState({ copied: true })}>
                <em className="copy-icon" />
              </CopyToClipboard>
              <input type="text" name="search" value={shareUrl} />
            </div>
          </Modal>
          {isdDisplayDurationModal &&
            <ExtendEventModal
              extendSessionFee={this.props.extendSessionFee}
              isOpenModal={isdDisplayDurationModal}
              acceptedBidId={this.state.acceptedBidId}
              role={this.props.user.role}
              duration={duration}
              onClickYes={this.updateExtendEventStatus}
              getExtendSessionFee={this.props.getExtendSessionFee}
              handleDurationChange={this.handleDurationChange}
              hideModal={this.hideExtendDurationModal} />
          }
          <GoalAdminPopup
            isOpenModal={openGoalAdminModal}
            goalDetail={eventDetails}
            hideModal={this.hideGoalAdminModal}
            onClickYes={this.handleGoalAdminSubmit} />
          {
            openChangeParticipantModal &&
            <ChangeParticipantPopup
              isOpenModal={openChangeParticipantModal}
              goalDetail={eventDetails}
              hideModal={this.hideChangeParticipantModal}
              onClickYes={this.handleChangeParticipantSubmit}
              learnerList={learnerList} />
          }

          {
            displayCanNotStartEvent &&
            <SuccessPopup
              isOpenModal={displayCanNotStartEvent}
              modalPopupObj={modalPopupObj}
              onClickOk={() => this.setState({ displayCanNotStartEvent: false })}
            />
          }

        </Fragment>
      </div>
    );
  }

}

/**
 * @description function to map data state to props
 * @param {object} state object object value
 */
function mapStateToProps(state: { authentication: { user: ILocalUser }; scheduledEvent: any; learner: any }) {
  return {
    user: state.authentication.user,
    eventData: state.scheduledEvent,
    extendSessionFee: state.scheduledEvent.extendSessionFee,
    status: state.scheduledEvent.status,
    learnerList: state.learner.list,
    sessionRecording: state.scheduledEvent.downloadSession,
  };
}

const mapEventManagementDetail = connect(mapStateToProps, { ...eventAction, ...learnerAction, ...systemAdminPaymentAction })(EventManagementDetail);
export { mapEventManagementDetail as EventManagementDetail };
