import { history } from '../../../../helpers';
import { ICreatePasswordReq, IUpdateProfileReq, IUploadImgaeReq, IUpdateLearnerProfileReq, IUpdateExpertProfileReq,
} from '../../../../interfaces';
import { messages } from '../../../../utils/messages';
import { getNavToDashboard } from '../../../../utils/handleNavigation';
import { userServices } from './../../../../services/userServices';
import { alertActions } from './../../../alert/redux/actions';
import { endLoading, startLoading } from './../../../loader/redux/actions';
import { actionTypes } from './types';
import OpenFieldTypes from 'OpenFieldTypes';
import { pageRoutes, handleError } from './../../../../utils';

export const learnerProfileAction = {
  getProfile,
  uploadProfileImage,
  getIndustries,
  getCategories,
  updateProfile,
  updateExpertProfile,
  updateLearnerProfile,
  resetPaymentLoginLink,
  updatePaymentAccountID,
  getPaymentLoginLink,
  getSubscriptionHistory,
  uploadResume,
};

function getProfile() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(null));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.getProfile()
      .then(
        (data) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(data));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request(data: ICreatePasswordReq) { return { type: actionTypes.GET_PROFILE_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.GET_PROFILE_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.GET_PROFILE_FAILURE, error }; }
}

function uploadProfileImage(reqData: IUploadImgaeReq) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(null));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.updateProfileImage(reqData)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(res));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request(data: any) { return { type: actionTypes.UPDATE_PROFILE_IMAGE_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.UPDATE_PROFILE_IMAGE_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.UPDATE_PROFILE_IMAGE_FAILURE, error }; }
}

function uploadResume(reqData: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(null));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.uploadResume(reqData)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(res));
        },
        (error) => {
          dispatch(failure(error.toString()));
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request(data: any) { return { type: actionTypes.UPDATE_PROFILE_IMAGE_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.UPDATE_PROFILE_IMAGE_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.UPDATE_PROFILE_IMAGE_FAILURE, error }; }
}


function getIndustries() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(null));

    userServices.getIndustries()
      .then(
        (res) => {
          dispatch(success(res));
        },
        (error) => {
          dispatch(alertActions.errorAlert(error.toString()));
          dispatch(failure(error.toString()));
        },
      );
  };

  function request(data: any) { return { type: actionTypes.FETCH_INDUSTRY_SUGGESTIONS_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.FETCH_INDUSTRY_SUGGESTIONS_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.FETCH_INDUSTRY_SUGGESTIONS_FAILURE, error }; }
}

function getCategories() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(null));

    userServices.getCategories()
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          dispatch(alertActions.errorAlert(error.toString()));
          dispatch(failure(error.toString()));
        },
      );
  };

  function request(data: ICreatePasswordReq) { return { type: actionTypes.FETCH_SECTOR_SUGGESTIONS_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.FETCH_SECTOR_SUGGESTIONS_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.FETCH_SECTOR_SUGGESTIONS_FAILURE, error }; }
}

function updateProfile(reqData: IUpdateProfileReq) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(null));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.updateProfile(reqData)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          history.push(`${pageRoutes.ORGADMIN.PATH}${pageRoutes.ORGADMIN.DASHBOARD}`);
          dispatch(success(res));
          dispatch(alertActions.successAlert(messages.profileUpdated));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(alertActions.errorAlert(error.toString()));
          dispatch(failure(error.toString()));
        },
      );
  };

  function request(data: IUpdateProfileReq) { return { type: actionTypes.UPDATE_PROFILE_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.UPDATE_PROFILE_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.UPDATE_PROFILE_FAILURE, error }; }
}

function updateExpertProfile(reqData: IUpdateExpertProfileReq, isOpenRequest: boolean) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(null));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.updateExpertProfile(reqData)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          const url = isOpenRequest ? `${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.BID_MANAGEMENT.PATH}`
            : `${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.VIEW_PROFILE.PATH}`;
          history.push(url);
          dispatch(success(res));
          dispatch(alertActions.successAlert(messages.profileUpdated));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(alertActions.errorAlert(error.toString()));
          dispatch(failure(error.toString()));
        },
      );
  };

  function request(data: IUpdateProfileReq) { return { type: actionTypes.UPDATE_PROFILE_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.UPDATE_PROFILE_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.UPDATE_PROFILE_FAILURE, error }; }
}

function updateLearnerProfile(reqData: IUpdateLearnerProfileReq) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(null));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.updateLearnerProfile(reqData)
      .then(
        (res) => {
          history.push(getNavToDashboard());
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(res));
          dispatch(alertActions.successAlert(messages.profileUpdated));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(alertActions.errorAlert(error.toString()));
          dispatch(failure(error.toString()));
        },
      );
  };

  function request(data: IUpdateProfileReq) { return { type: actionTypes.UPDATE_PROFILE_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.UPDATE_PROFILE_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.UPDATE_PROFILE_FAILURE, error }; }
}

function updatePaymentAccountID(reqData: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(null));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.updatePaymentAccountID(reqData)
      .then(
        (res) => {
          dispatch(alertActions.successAlert(messages.profileUpdated));
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(res));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(alertActions.errorAlert(error.toString()));
          dispatch(failure(error.toString()));
        },
      );
  };

  function request(data: IUpdateProfileReq) { return { type: actionTypes.UPDATE_PAYMENT_ACCOUNT_ID_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.UPDATE_PAYMENT_ACCOUNT_ID_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.UPDATE_PAYMENT_ACCOUNT_ID_FAILURE, error }; }
}

function getPaymentLoginLink() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(null));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.getPaymentLoginLink()
      .then(
        (data) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(data.url));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request(data: any) { return { type: actionTypes.GET_PAYMENT_LOGIN_LINK_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.GET_PAYMENT_LOGIN_LINK_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.GET_PAYMENT_LOGIN_LINK_FAILURE, error }; }
}

function resetPaymentLoginLink() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch({ type: actionTypes.RESET_PAYMENT_LOGIN_LINK });
  };
}

function getSubscriptionHistory(data: object) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.getSubscriptionHistory(data)
      .then(
        (res) => {
          dispatch(success(res));
          dispatch(endLoading('MAIN_LOADER'));
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
          dispatch(endLoading('MAIN_LOADER'));
        },
      );
  };
  function success(resdata: any) { return { type: actionTypes.GET_SUBSCRIPTION_HISTORY_SUCCESS, data: resdata }; }
  function failure(error: string) { return { type: actionTypes.GET_SUBSCRIPTION_HISTORY_FAILURE, error }; }
}
