import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import TinyMCE from 'react-tinymce';
import { Button } from 'reactstrap';
import OpenFieldTypes from 'OpenFieldTypes';
import { contentManagementAction } from '../redux/actions';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  content: Array<[]>;
  activeTab: string;
  contentSuccess?: boolean;
}

interface IState {
  updatedContent: string;
  originalContent: string;
  editorKey: number;
  activeTab: string;
  clearData: boolean;
}

class TextEditorContainer extends React.Component<IProps, IState> {

  public static getDerivedStateFromProps(props: IProps, state: IState) {
    const updatedState = {};
    const { content } = props;
    updatedState['activeTab'] = props.activeTab;
    if (content && content[0] !== undefined && state.originalContent !== content[0]['content']) {
      updatedState['editorKey'] = new Date().getTime();
      updatedState['originalContent'] = content[0]['content'];
    } else if (state.activeTab !== props.activeTab) {
      updatedState['clearData'] = true;
      updatedState['editorKey'] = new Date().getTime();
      content && content[0] !== undefined ?
        updatedState['originalContent'] = content[0]['content'] :
        updatedState['originalContent'] = '';
    }
    return updatedState;
  }
  constructor(props: IProps) {
    super(props);
    this.state = {
      updatedContent: '',
      originalContent: '',
      editorKey: new Date().getTime(),
      activeTab: this.props.activeTab,
      clearData: false,
    };
  }

  public componentDidUpdate() {
    if (this.state.clearData) {
      this.props.dispatch(contentManagementAction.clearContent());
    }
  }

  public handleEditorChange(e) {
    const content = e.target.getContent();
    this.setState({
      updatedContent: content,
    });
  }

  public undoUpdatedContent = () => {
    this.setState({ editorKey: new Date().getTime() });
  }

  public updateContent = () => {
    const { dispatch } = this.props;
    dispatch(contentManagementAction.updateContent({
      type: this.props.activeTab,
      content: this.state.updatedContent,
    }));
  }

  /**
   * @description
   * render is used to render HTML
   */
  public render() {
    const { editorKey, originalContent } = this.state;
    return (
        <div className="text-editor-wrapper">
          {this.props.content ? <TinyMCE
            key={editorKey}
            content={originalContent || ''}
            config={{
              plugins: 'code autolink link image lists print preview fullscreen',
              toolbar: 'undo redo | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist | fullscreen | link | code',
              menubar: false,
              statusbar: false,
              height: 500,
            }}
            onChange={e => this.handleEditorChange(e)}
          />
          : ''}
          <div className="card-footer border-0 mt-3 px-0">
            <Button className="btn btn-regular" onClick={this.undoUpdatedContent}>Cancel</Button>{' '}
            <Button color="primary" className="ml-2" onClick={this.updateContent}>Update</Button>
          </div>
        </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {};
}

const connectedTextEditorContainer = connect(mapStateToProps)(TextEditorContainer);
export { connectedTextEditorContainer as TextEditorContainer };
