import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  UncontrolledCollapse,
  Button,
} from 'reactstrap';
import StarRatings from 'react-star-ratings';
import OpenFieldTypes from 'OpenFieldTypes';
import AdminPaymentPortalView from './../paymentPortalView';
import { ListingContainer } from '../../../shared/listingManagement/listingContainer';
import { ConfirmatioPopup } from '../../../shared/modals/confirmationPopup';
import { Sidebar } from '../../../shared/sidebar';
import { Back } from '../../../shared/back/back';
import { profileAction } from '../../../shared/updateProfile/redux/actions';
import { systemAdminPaymentAction } from './../redux/actions';
import { expertPaymentAction } from './../../../expert/payment/redux/actions';
import {
  tableItemsCount,
  staticConstants,
  MAGIC_NUMBER,
  withdrawalProcessed,
  formatCurrency,
} from './../../../../utils';
import userImage from './../../../../assets/images/profile.png';
import facebook from './../../../../assets/images/icons/fb-link.svg';
import twitter from './../../../../assets/images/icons/twitter-link.svg';
import linkedin from './../../../../assets/images/icons/linkedin-link.svg';
import paymentIcon from './../../../../assets/images/icons/payment.svg';
import { IBankInfo, IViewProfile } from '../../../../interfaces';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  viewProfile: IViewProfile;
  match?: any;
  bankInfo: IBankInfo;
  withdrwanHistoryList: any;
  expertIncome: any;
}
const TABLE_COL_45 = 'table-col-45';
const AMOUNT = 'Amount';
const REQUESTED = 'Requested';
const PAID = 'Paid';

const tableHeaderContent = {
  amount: { name: AMOUNT, dataLabel: AMOUNT, class: TABLE_COL_45, sort: true },
  createdAt: { name: REQUESTED, dataLabel: REQUESTED, class: TABLE_COL_45, sort: true },
  payOutDate: { name: PAID, dataLabel: PAID, class: TABLE_COL_45, sort: true },
};

class PaymentDetailContainer extends React.Component<IProps> {
  public state = {
    title: '',
    items: tableItemsCount,
    isPaymentPortal: false,
    isWidhdrwalHistory: true,
    displayWithdrawalProcessedPopup: false,
    modalPopupObj: {},
    sort: '',
    sortBy: 0,
    activePage: MAGIC_NUMBER.ONE,
    systemAdminPayment: {
      withdrwanHistoryList: {
        count: 0,
        list: [],
      },
    },
    tableContent: tableHeaderContent,
  };

  /**
   * @description componentDidMount is called when component is loaded
   * call userAction.getProfile to fetch user profile
   */
  public componentDidMount () {
    const { userId } = this.props.match.params;
    const { items, activePage } = this.state;
    const { dispatch } = this.props;
    const { activeTab } = this.props['location']['state'];
    if (userId) {
      dispatch(profileAction.getProfile(userId, true));
      dispatch(expertPaymentAction.getExpertIncome({ userId }));
    }

    if (activeTab === staticConstants.PAYMENT_REQUEST_SUB_TABS.PROCESSED) {
      const reqObj = {
        page: Number(activePage),
        limit: items,
        userId,
      };
      dispatch(systemAdminPaymentAction.withdrawnHistoryList(reqObj));
    }
  }

  /**
   * @description
   * hideModal is called when someone click on the cancel button
   */
  public hideModal = () => {
    this.setState({
      displayWithdrawalProcessedPopup: false,
      modalPopupObj: {},
    });
  }

  /**
   * @description
   * withdrawalProcessed is used to processed withdrawal request
   * @param fields {String} id
   */
  public withdrawalProcessed = () => {
    const { requestedId } = this.props['location']['state'];
    const reqObj = {
      requestedId,
    };
    this.props.dispatch(systemAdminPaymentAction.updateWithdrawnRequest(reqObj));
    this.hideModal();
  }

  /**
   * @description
   * showWithdrawalPopup is used show delete popup.
   */
  public showWithdrawalPopup = () => {
    const modalPopupObj = withdrawalProcessed;
    this.setState({
      displayWithdrawalProcessedPopup: true,
      modalPopupObj,
    });
  }

  /**
   * @description fetchWithdrawanHistory is used on initial load
   */
  public fetchWithdrawanHistory(pageNumber: number) {
    const { items, sort, sortBy } = this.state;
    const { userId } = this.props.match.params;
    let reqObj = {
      page: Number(pageNumber),
      limit: items,
      userId,
    };
    if (sort) {
      reqObj = { ...reqObj, ...{ sort, sortBy } };
    }
    if (userId) {
      this.props.dispatch(systemAdminPaymentAction.withdrawnHistoryList(reqObj));
    }
  }

  /**
   * @description
   * handlePageChange is called someone click on the pagination.
   * @param {Number} pageNumber
   */
  public handlePageChange = (pageNumber: number) => this.setState({ activePage: pageNumber }, () => this.fetchWithdrawanHistory(pageNumber));

  /**
   * @description
   * sortData is used for sorting purpose.
   * @param {Number} sort
   * @param {String} sortBy
   */
  public sortData = (sort: string, sortBy: string) => {
    const { activePage } = this.state;
    this.setState({ sort, sortBy }, () =>
      this.fetchWithdrawanHistory(activePage),
    );
  }

  /**
   * @description
   * render is used to render the html.
   */
  public render() {

    const { isPaymentPortal, isWidhdrwalHistory, displayWithdrawalProcessedPopup, modalPopupObj, tableContent, activePage, sort, sortBy } = this.state;
    const { viewProfile, bankInfo, withdrwanHistoryList, expertIncome: { totalReceieved, totalPending } } = this.props;
    const { activeTab } = this.props['location']['state'];
    const filterObj = { activePage, sort, sortBy };
    const classVarArr = ['table-head'];
    const classes = classVarArr.join(' ');

    let averageProfileRating = 0;
    let expertSessionRating = 0;
    if (viewProfile && viewProfile.averageRating && viewProfile.averageSessionRating) {
      viewProfile.averageRating = viewProfile.averageRating || 0;
      viewProfile.averageSessionRating = viewProfile.averageSessionRating || 0;
      averageProfileRating = Number.isInteger(viewProfile.averageRating)
        ? viewProfile.averageRating
        : Number(viewProfile.averageRating.toFixed(MAGIC_NUMBER.ONE));
      expertSessionRating = Number.isInteger(viewProfile.averageSessionRating)
        ? viewProfile.averageSessionRating
        : Number(viewProfile.averageSessionRating.toFixed(MAGIC_NUMBER.ONE));
    }

    return (
      <React.Fragment>
        <Sidebar {...this.props} />
        <div className="dashboard-wrapper">
          <Back {...this.props} />
          <div className="profile-bar box-shadow white-bg mt-4">
            <div className="user-profile-details">
              <Row>
                <Col xs="12" md="4" className="d-flex">
                  <span className="profile-picture-view-only">
                    <img src={viewProfile && viewProfile.profileImage ? viewProfile.profileImage : userImage} alt="Profile" className="img-cover" />
                  </span>
                  <div className="profile-email-view-only text-truncate ">
                    <h3 className="text-truncate">
                      {viewProfile && viewProfile.title ? `${ viewProfile.title }.` : ''} {viewProfile && viewProfile.firstName} {viewProfile && viewProfile.lastName}
                    </h3>
                    <p>{viewProfile && viewProfile.email}</p>
                    { viewProfile && viewProfile.role === staticConstants.ROLE.EXPERT &&
                      <h4>{viewProfile.position} {viewProfile.organizationName && `-`} {viewProfile.organizationName}</h4>
                    }
                    <div className="rate-tag">
                      <em className="price-placeholder">$</em>
                      <span> {viewProfile && viewProfile.hourlyRate && viewProfile.hourlyRate.current ? viewProfile.hourlyRate.current : 0}</span>
                      <em>Per Hour</em>
                    </div>
                  </div>
                </Col>
                <Col xs="12" md="4" className="rating-count-2">
                  <div className="rating-view-only">
                    <p>Profile Ratings ({averageProfileRating}/5)</p>
                    <StarRatings
                      rating={averageProfileRating}
                      starHoverColor="#4be59e"
                      starEmptyColor="#dcdcdc"
                      starRatedColor="#4be59e"
                      starDimension="20px"
                      starSpacing="2px"
                      numberOfStars={MAGIC_NUMBER.FIVE}
                      name="averageRating"
                    />
                  </div>
                  <div className="rating-view-only">
                    <p>Session Ratings ({expertSessionRating}/5)</p>
                    <StarRatings
                      rating={expertSessionRating}
                      starHoverColor="#4be59e"
                      starEmptyColor="#dcdcdc"
                      starRatedColor="#4be59e"
                      starDimension="20px"
                      starSpacing="2px"
                      numberOfStars={MAGIC_NUMBER.FIVE}
                      name="averageRating"
                    />
                  </div>
                </Col>
                <Col xs="12" md="4">
                  <div className="social-links">
                    <ul>
                      { viewProfile && viewProfile.facebook &&
                        <li><a href={viewProfile.facebook} target="_blank" rel="noopener noreferrer"><img src={facebook} alt="facebook" /></a></li>
                      }
                      { viewProfile && viewProfile.twitter &&
                        <li><a href={viewProfile.twitter} target="_blank" rel="noopener noreferrer"><img src={twitter} alt="twitter" /></a></li>
                      }
                      { viewProfile && viewProfile.linkedIn &&
                        <li><a href={viewProfile.linkedIn} target="_blank" rel="noopener noreferrer"><img src={linkedin} alt="linkedin" /></a></li>
                      }
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className="tab-panel-wrapper white-bg box-shadow mt-4">
            <div className="assigned-licence">
              <h2
                className="heading heading-rg roboto-medium collapse-header cursor-pointer"
                id="paymentPortal"
                onClick={() => this.setState({ isPaymentPortal: !isPaymentPortal })}
                onTouchStart={() => this.setState({ isPaymentPortal: !isPaymentPortal })}
              >
                Payment Portal
                <span className={`icon icon-arrow-down-sign-to-navigate ${ isPaymentPortal ? 'up' : '' }`} />
              </h2>
              { Object.keys(bankInfo).length > 0 && <UncontrolledCollapse toggler="#paymentPortal" isOpen={isPaymentPortal}>
                <AdminPaymentPortalView bankInfo={bankInfo} />
              </UncontrolledCollapse> }
            </div>
          </div>

          {activeTab === staticConstants.PAYMENT_REQUEST_SUB_TABS.PROCESSED && <div className="tab-panel-wrapper white-bg box-shadow mt-4">
            <div className="assigned-licence">
              <h2
                className="heading heading-rg roboto-medium collapse-header cursor-pointer"
                id="withdrawalHistoryTab"
                onClick={() => this.setState({ isWidhdrwalHistory: !isWidhdrwalHistory })}
                onTouchStart={() => this.setState({ isWidhdrwalHistory: !isWidhdrwalHistory })}
              >
                Withdrawal History
                <span className={`icon icon-arrow-down-sign-to-navigate ${ isWidhdrwalHistory ? 'up' : '' }`} />
              </h2>
              { <UncontrolledCollapse toggler="#withdrawalHistoryTab" isOpen={isWidhdrwalHistory}>
                <ListingContainer
                  tableContent={tableContent}
                  listData={withdrwanHistoryList}
                  filterObj={filterObj}
                  tableHeaderClasses={classes}
                  handlePageChange={this.handlePageChange}
                  handleSorting={this.sortData}
                  activeTab={activeTab}
                />
              </UncontrolledCollapse> }
            </div>
          </div>}

          {activeTab !== staticConstants.PAYMENT_REQUEST_SUB_TABS.PROCESSED && <div className="dashboard-content shadow mb-0">
            <div className="withdraw-content withdraw-content-col-2">
              <div  className="withdraw-box">
                <div className="view-profile-box flex-wrap p-0">
                  <div className="user-profile-details">
                    <h4>Total Balance</h4>
                    <span className="profile-picture-view-only payment-card-icon">
                      <img src={paymentIcon}
                        alt="payment icon" />
                    </span>
                    <div className="profile-email-view-only text-truncate">
                      <h3 className="text-truncate text-lg">
                        <em>$</em>
                        {formatCurrency(+totalPending.toFixed(MAGIC_NUMBER.TWO))}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div  className="withdraw-box">
                <div className="view-profile-box flex-wrap p-0">
                  <div className="user-profile-details">
                    <h4>Available today</h4>
                    <span className="profile-picture-view-only payment-card-icon">
                      <img src={paymentIcon}
                        alt="payment icon" />
                    </span>
                    <div className="profile-email-view-only text-truncate">
                      <h3 className="text-truncate text-lg">
                        <em>$</em>
                        {formatCurrency(+totalReceieved.toFixed(MAGIC_NUMBER.TWO))}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer d-flex mt-0">
              <Button color="primary" className="btn ml-auto" onClick={() => this.showWithdrawalPopup()}>
                Withdrawal Processed
              </Button>
            </div>
          </div>}
          {
            displayWithdrawalProcessedPopup ?
            <ConfirmatioPopup hideModal={this.hideModal} isOpenModal={displayWithdrawalProcessedPopup} modalPopupObj={modalPopupObj} onClickYes={this.withdrawalProcessed}
            />
            : ''
          }
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  const { updateProfile, systemAdminPayment, expertPayments: { expertIncome } } = state;
  const bankInfoData = {};
  if (updateProfile && updateProfile['profile']) {
    bankInfoData['region'] = updateProfile['profile']['region'];
    bankInfoData['bankAccount'] = updateProfile['profile']['bankAccount'];
    bankInfoData['accountHolderAddress'] = updateProfile['profile']['accountHolderAddress'];
  }
  return {
    viewProfile: updateProfile['profile'],
    bankInfo: bankInfoData,
    withdrwanHistoryList: systemAdminPayment && systemAdminPayment.withdrwanHistoryList,
    expertIncome,
  };
}

const connectedPaymentDetailContainer = connect(mapStateToProps)(PaymentDetailContainer);
export { connectedPaymentDetailContainer as PaymentDetailContainer };
