export const actionTypes = {
  PAYMENT_REQUEST: 'PAYMENT_REQUEST',
  PAYMENT_SUCCESS: 'PAYMENT_SUCCESS',
  PAYMENT_FAILURE: 'PAYMENT_FAILURE',
  GET_PRORATED_AMOUNT_SUCCESS: 'GET_PRORATED_AMOUNT_SUCCESS',
  GET_PRORATED_AMOUNT_REQUEST: 'GET_PRORATED_AMOUNT_REQUEST',
  GET_PRORATED_AMOUNT_FAIULURE: 'GET_PRORATED_AMOUNT_FAIULURE',
  GET_TAX_RATE_SUCCESS: 'GET_TAX_RATE_SUCCESS',
  GET_TAX_RATE_REQUEST: 'GET_TAX_RATE_REQUEST',
  GET_TAX_RATE_FAIULURE: 'GET_TAX_RATE_FAILURE',
  RESET_STATE: 'RESET_STATE',
  RESET_TAX_RATE: 'RESET_TAX_RATE'
};
