import _ from 'lodash';
import React, { Dispatch } from 'react';
import Pagination from 'react-js-pagination';
import { v4 } from 'uuid';
import { connect } from 'react-redux';
import { Sidebar } from '../../sidebar';
import Select from 'react-select';
import { notificationAction } from '../redux/actions';
import { NotificationItem } from './notification';
import OpenFieldTypes from 'OpenFieldTypes';
import { customSelectStyles, notificationFilterOptions, getFilterTypes, MAGIC_NUMBER } from '../../../../utils';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  notifications: any;
  user: any;
}

class ListingContainer extends React.Component<IProps> {

  public static getDerivedStateFromProps(props, state) {
    if (props.notifications && props.notifications.list) {
      return {
        notifications: props.notifications,
      };
    }
    return null;
  }

  public state = {
    selectedOption: null,
    tooltipOpen: false,
    activePage: 1,
    items: MAGIC_NUMBER.TEN,
    title: '',
    sort: '',
    sortBy: 0,
    notifications: {
      count: 0,
      list: [],
      successForGettingNotifications: false,
    },
    filterTypes: undefined,
    isPagination: true,
    openFilter: false,
  };

  public filterToggle = () => {
    this.setState({
      openFilter: true,
    });
  }
  public filterRemove = () => {
    this.setState({
      openFilter: false,
    });
  }

  /**
   * @description componentDidMount is called when component is loaded
   * called notificationAction.getNotifications
   */
  public componentDidMount() {
    const { items, activePage } = this.state;
    this.props.dispatch(notificationAction.getNotifications({
      limit: items,
      page: activePage,
    }));
  }

  /**
   * @description fetchNotificationData is called to fetch notification data
   * @param {pageno} number
   */
  public fetchNotificationData(pageno: number) {
    const reqObj = {
      limit: this.state.items,
      page: pageno,
    };
    if (this.state.filterTypes) {
      reqObj['filterTypes'] = this.state.filterTypes;
    }
    this.props.dispatch(notificationAction.getNotifications(reqObj));
  }
  /**
   * @description
   * handleChange is used to set the value on state from the input.
   * @param fields {Object} || {String} value
   * @param fields {String} key
   */
  public handleChange = (type: any, key: any) => {
    const { role } = this.props.user;
    const filterTypes = getFilterTypes(type.value, role);
    this.setState({
      filterTypes,
    },            () => this.fetchNotificationData(1));
  }

  /**
   * @description
   * toggle is used to toggel the tooltip
   */
  public toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  }

  /**
   * @description handlePageChange is used when user click on the pagination
   * @param fields {number} pageno
   */
  public handlePageChange = (pageno: number) => {
    this.setState({
      activePage: pageno,
    },            () => this.fetchNotificationData(pageno));
  }

  /**
   * @description renderNotificationListing is used to render the listing
   */
  public renderNotificationListing() {
    const { list } = this.state.notifications;
    return _.map(list, (item, index) => {
      return (
        <NotificationItem notification={item} key={v4()} isTopListing={false} />
      );
    });
  }

  /**
   * @description render is used to render the html
   */
  public render() {
    const { activePage, items, notifications } = this.state;
    const { role } = this.props.user;
    let minDisplay: number;
    let maxDisplay: number;
    if (notifications.count === MAGIC_NUMBER.ZERO) {
      maxDisplay = MAGIC_NUMBER.ZERO;
      minDisplay = MAGIC_NUMBER.ZERO;
    } else if (notifications.count <= items) {
      maxDisplay = notifications.count;
      minDisplay = MAGIC_NUMBER.ONE;
    } else {
      maxDisplay = (Number(activePage) * Number(items)) < notifications.count
        ? (Number(activePage) * Number(items)) : notifications.count;
      minDisplay = ((Number(activePage) - 1) * Number(items)) + 1;
    }

    return (
      <React.Fragment>
        <Sidebar {...this.props} />
        <div className="dashboard-wrapper">
          <div className="notification-breadcrumb">
            <h2 className="heading heading-sm text-uppercase roboto-medium">Notifications</h2>
            <div className="filter-wrapper">
                <Select
                    placeholder="All"
                    onChange={e => this.handleChange(e, 'filterType')}
                    options={notificationFilterOptions[role]}
                    styles={customSelectStyles}
                    className="react-select-box"
                  />
            </div>
          </div>
            <div className="dashboard-content">
            <div className="table-wrapper">
              <div className="w-100">
                <div className="table-body">
                  {this.renderNotificationListing()}
                  {notifications && notifications.successForGettingNotifications && notifications.count === MAGIC_NUMBER.ZERO ?
                    <div className="table-row table-no-data">
                      No Results Found.
                      </div>
                    : ''}
                </div>
                  {
                    (notifications && notifications.count > MAGIC_NUMBER.TEN) &&
                    <div className="table-footer border-0 px-0">
                      <React.Fragment>
                        <p>Showing {minDisplay} - {maxDisplay} of {notifications.count}</p>
                        <div className="pagination-wrapper">
                          <Pagination
                            hideFirstLastPages
                            activePage={activePage}
                            itemsCountPerPage={items}
                            totalItemsCount={notifications.count}
                            pageRangeDisplayed={MAGIC_NUMBER.FIVE}
                            onChange={this.handlePageChange}
                          />
                        </div>
                      </React.Fragment>
                    </div>
                  }
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: { notification: any; authentication: { user: any } }) {
  const { user } = state.authentication;
  return {
    notifications: state.notification,
    user,
  };
}

const connectedNotificationListingContainer = connect(mapStateToProps)(ListingContainer);
export { connectedNotificationListingContainer as ListingContainer };
