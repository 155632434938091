import React, { Dispatch } from 'react';
import OpenFieldTypes from 'OpenFieldTypes';
import { connect } from 'react-redux';
import { Link, Element, Events, animateScroll as scroll  } from 'react-scroll';
import { contentManagementAction } from '../../../systemAdmin/contentManagement/redux/actions';
import stepImage1 from './../../../../assets/images/step_1.png';
import stepImage2 from './../../../../assets/images/step_2.png';
import stepImage3 from './../../../../assets/images/step_3.png';
import stepImage4 from './../../../../assets/images/step_4.png';
import { staticConstants, MAGIC_NUMBER } from '../../../../utils';
import { LandingHeader } from '../../landingHeader';
import { LandingFooter } from '../../landingFooter';

interface IProps {
    dispatch: Dispatch<OpenFieldTypes.RootAction>;
    content: Array<[]>;
}

interface IState {
    activeStep: any;
    title: string;
    subtitle: string;
  }
  

class HowItWork extends React.Component<IProps, IState> {

    constructor(props) {
        super(props);
        this.state = {
            activeStep: 1,
            title: staticConstants.LANDING_PAGE_FOOTER_TITLE.HOW_IT_WORK,
            subtitle: staticConstants.LANDING_PAGE_SUB_TITLE.HOW_IT_WORK
        };
        this.scrollToTop = this.scrollToTop.bind(this);
    }

    public componentDidMount() {
        Events.scrollEvent.register('begin', () => {});
        Events.scrollEvent.register('end', () => {});
        this.props.dispatch(contentManagementAction.getContent({
            type: staticConstants.CONTENT_MANAGEMENT_TAB.FAQ,
        }));

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = 'hs-script-loader';
        script.src = '//js.hs-scripts.com/7903349.js';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
    }

    public scrollToTop() {
        scroll.scrollToTop();
    }

    public handleSetActive(indexNo: any) {
        this.setState({ activeStep: indexNo });
    }

    public render() {
        const { title, subtitle, activeStep } = this.state;
        return (
            <>
                <LandingHeader title={title} subTitle={subtitle} banner={'howitwork_banner'} />
                <div className="common_padding">
                    <div className="container">
                        <div className="row sticky">
                            <div className="col-3 text-center position-relative">
                                <Link activeClass="active" onSetActive={() => this.handleSetActive(MAGIC_NUMBER.ONE)} duration={MAGIC_NUMBER.CHAR_LENGTH} delay={MAGIC_NUMBER.FIFTY} smooth={true} offset={MAGIC_NUMBER.MINUS_HUNDRED} className={`step step01 ${activeStep > MAGIC_NUMBER.ONE ? 'active' : '' }`} to="step-1" spy={true}></Link>
                                <div className="gradiant_outline"></div>
                                <p className="step_text_p">Set a Goal</p>
                            </div>
                            <div className="col-3 text-center position-relative">
                                <Link activeClass="active" onSetActive={() => this.handleSetActive(MAGIC_NUMBER.TWO)} duration={MAGIC_NUMBER.CHAR_LENGTH} delay={MAGIC_NUMBER.FIFTY} smooth={true} offset={MAGIC_NUMBER.MINUS_HUNDRED}  className={`step step02 ${activeStep > MAGIC_NUMBER.TWO ? 'active' : '' }`} to="step-2" spy={true}></Link>
                                <div className="gradiant_outline"></div>
                                <div className="step_line"></div>
                                <p className="step_text_p">Send a Request for Expertise</p>
                            </div>
                            <div className="col-3 text-center position-relative">
                                <Link activeClass="active" onSetActive={() => this.handleSetActive(MAGIC_NUMBER.THREE)} duration={MAGIC_NUMBER.CHAR_LENGTH} delay={MAGIC_NUMBER.FIFTY} smooth={true} offset={MAGIC_NUMBER.MINUS_HUNDRED} className={`step step03 ${activeStep > MAGIC_NUMBER.THREE ? 'active' : '' }`} to="step-3" spy={true}></Link>
                                <div className="gradiant_outline"></div>
                                <div className="step_line"></div>
                                <p className="step_text_p">Accept a Bid</p>
                            </div>
                            <div className="col-3 text-center position-relative">
                                <Link activeClass="active" onSetActive={() => this.handleSetActive(MAGIC_NUMBER.FOUR)} duration={MAGIC_NUMBER.CHAR_LENGTH} delay={MAGIC_NUMBER.FIFTY} smooth={true} offset={MAGIC_NUMBER.MINUS_HUNDRED} className="step step04" to="step-4" spy={true}></Link>
                                <div className="gradiant_outline"></div>
                                <div className="step_line"></div>
                                <p className="step_text_p">Attend and Rate your Session</p>
                            </div>
                        </div>
                        <Element className="row mt-5 pt-2" name="step-1">
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <img src={stepImage1} alt="step 1" className="img-fluid" />
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="step_text">
                                    <div>
                                        <div className="number">01</div>
                                        <h3>Set a Goal</h3>
                                        <p>It’s easy! You can set as many as you want and save or edit them to suit your changing needs. Select from a menu of categories and topics to search for experts and update them any time before you generate a request.</p>
                                    </div>
                                </div>
                            </div>
                        </Element>
                        <Element className="row mt-5 pt-2" name="step-2">
                            <div className="col-lg-6 col-md-12 col-sm-12 order-lg-2">
                                <img src={stepImage2} alt="step 1" className="img-fluid" />
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="step_text">
                                    <div>
                                        <div className="number">02</div>
                                        <h3>Send a Request for Expertise</h3>
                                        <p>A request is the thing our experts will receive when you decide you’re ready to go. The information in it makes our matchmaking engine work for you and your goals. You can go it alone or get support to help customize your search for subject matter expertise.</p>
                                    </div>
                                </div>
                            </div>
                        </Element>
                        <Element className="row mt-5 pt-2" name="step-3">
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <img src={stepImage3} alt="step 1" className="img-fluid" />
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="step_text">
                                    <div>
                                        <div className="number">03</div>
                                        <h3>Accept a Bid</h3>
                                        <p>Choosing from a list of pre-vetted knowledge providers is simple. View proposals and profiles, shortlist experts you like and communicate directly once you accept a bid. Your event is now booked.</p>
                                    </div>
                                </div>
                            </div>
                        </Element>
                        <Element className="row mt-5 pt-2" name="step-4">
                            <div className="col-lg-6 col-md-12 col-sm-12 order-lg-2">
                                <img src={stepImage4} alt="step 1" className="img-fluid" />
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="step_text">
                                    <div>
                                        <div className="number">04</div>
                                        <h3>Attend and Rate your Session</h3>
                                        <p>Your customized learning event is a click away and always there to refer back to when you’re done. We use the best remote conferencing technology and a simple 5-star rating system that ensures the quality of our network stays high.</p>
                                    </div>
                                </div>
                            </div>
                        </Element>
                    </div>
                </div>
                <LandingFooter activeTab={staticConstants.CONTENT_MANAGEMENT_TAB.FAQ} />
            </>
        );
    }
}

function mapStateToProps(state: { contentManagement: { content: Array<[]> } }) {
    const { content } = state.contentManagement;
    return {
        content,
    };
}

const connecteHowItWorkContainer = connect(mapStateToProps)(HowItWork);
export { connecteHowItWorkContainer as HowItWork };
