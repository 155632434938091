import React, { Fragment } from 'react';
import { SliderContainer } from '../../shared/slider';
import { CreatePasswordForm } from './CreatePasswordForm';

class CreatePasswordContainer extends React.Component {

  public render() {
    const props = this.props;
    return (
      <div className="form-container d-flex">
        <Fragment>
          <CreatePasswordForm {...props} />
        </Fragment>
      </div>
    );
  }
}

export { CreatePasswordContainer };
