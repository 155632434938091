import gql from 'graphql-tag';

const GET_USERS_LIST_QUERY = gql`
  query getLearners($limit: Int, $page: Int, $searchKey: String, $isPagination: Boolean,
     $sort: String, $sortBy: Int,$userId: String, $status: String) {
    getLearners(limit: $limit, page: $page, searchKey: $searchKey,
      isPagination: $isPagination, sort:$sort, sortBy: $sortBy, userId: $userId, status: $status) {
      message,
      count,
      data {
        email,
        firstName,
        lastName,
        profileImage,
        isActive,
        _id,
        isLicenced,
        isVerified,
      },
      totalLicenceAssign,
      totalLicence,
    }
}`;

const GET_PAYMENT_LOGIN_LINK_QUERY = gql`
  query getPaymentLoginLink{
    getPaymentLoginLink {
      message,
      data {
        url
      }
    }
}`;

const GET_ADMIN_APP_FEEDBACK_LIST_QUERY = gql`
  query feedbackList($page: Int, $limit: Int, $key: String, $sort: String, $sortBy: Int) {
    feedbackList(page: $page, limit: $limit, key: $key, sort: $sort, sortBy: $sortBy) {
        message,
        data {
          firstName,
          lastName,
          email,
          phoneNumber,
          feedback{
            message,
            isReply,
            createdAt
          },
          _id,
        },
        count,
    }
}`;

export { GET_USERS_LIST_QUERY, GET_PAYMENT_LOGIN_LINK_QUERY, GET_ADMIN_APP_FEEDBACK_LIST_QUERY };
