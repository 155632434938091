import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import Autocomplete from '@celebryts/react-autocomplete-tags';
import OpenFieldTypes from 'OpenFieldTypes';
import { selectTagsAction } from './redux/actions';
import { mapTagsFromAPIRequest, MAGIC_NUMBER } from './../../../utils';
interface IProps {
  selectedTags: any;
  tags?: any;
  dispatch?: Dispatch<OpenFieldTypes.RootAction>;
  onAdd: any;
  limitTags: number;
  children?: any;
}

interface IState {
  tags: any;
  suggestedTags: any;
}

class SelectTags extends React.Component<IProps, IState> {

  constructor(props) {
      super(props);
      this.state = {
          tags: [],
          suggestedTags: [],
        };
    }

  public componentWillReceiveProps(props: IProps) {
      if (props.tags) {
          const tags = mapTagsFromAPIRequest(props.tags);
          this.setState({
              tags,
            });
        }
    }

  public componentWillMount() {
      this.props.dispatch(selectTagsAction.getTags());
    }

  public handleTagChange = (value: string) => {
      if (!value) { return; }
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;
      const suggestedTags = inputLength === MAGIC_NUMBER.ZERO ? [] : this.state.tags.filter((tag) =>
            tag.label.toLowerCase().slice(MAGIC_NUMBER.ZERO, inputLength) === inputValue,
        );

      this.setState({ suggestedTags });
    }

  public render() {
      const { selectedTags, onAdd, limitTags = 10 } = this.props;
      const { suggestedTags } = this.state;
      return (
            <Autocomplete
                suggestions={suggestedTags}
                onChange={this.handleTagChange}
                onAdd={onAdd}
                tags={selectedTags}
                limitTags={limitTags}
                allowCreateTag={false}
                className="auto-complete-tag"
            />
        );
    }
}

function mapStateToProps(state: any) {
  const { selectTags } = state;
  return {
      tags: selectTags['tags'],
    };
}

const connectediSelectTags = connect(mapStateToProps)(SelectTags);
export { connectediSelectTags as SelectTags };
