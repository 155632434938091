import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { v4 } from 'uuid';
import Pagination from 'react-js-pagination';
import { FilterComponent } from '../../../shared/filter/filterComponent';
import { appFeedbackAction } from '../redux/actions';
import OpenFieldTypes from 'OpenFieldTypes';
import {
  deleteFeedback, MAGIC_NUMBER
} from './../../../../utils';
import { FeedbackItem } from './feedbackItems';
import { ConfirmatioPopup } from '../../../shared/modals/confirmationPopup';
import { ApplicationFeedbackModalPopUp } from '../../../shared/modals/applicationFeedbackPopup';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  appFeedbackRequest: any;
}

class ListingContainer extends React.Component<IProps> {

  /**
   * @description getDerivedStateFromProps is called when there in change in property
   * @param fields {Object} props
   */
  public static getDerivedStateFromProps(props: IProps) {
    if (props.appFeedbackRequest && props.appFeedbackRequest.list) {
      return {
        appFeedbackRequest: props.appFeedbackRequest,
      };
    }
    return null;
  }

  public state = {
    items: MAGIC_NUMBER.TEN,
    activePage: 1,
    sort: '',
    sortBy: 0,
    appFeedbackRequest: {
      count: 0,
      list: [],
      applicationFeebackListSuccess: false,
    },
    name: '',
    email: '',
    phoneNumber: '',
    feedback: '',
    createdAt: '',
    displayDeletePopup: false,
    modalPopupObj: {},
    userId: '',
    reqObj: {},
    title: '',
    displayReplyPopup: false,
  };

  /**
   * @description componentDidMount is called when component is loaded
   * call goalAction.getGoals to fetch goal listings
   * call goalAction.getGoals to fetch goal categories
   */
  public componentDidMount() {
    const { items, activePage } = this.state;
    this.props.dispatch(appFeedbackAction.getApplicationFeedback({
      page: Number(activePage),
      limit: items,
    }));
  }

  /**
   * @description fetchGoalsData is used on initial load
   */
  public fetchAppFeedbackData(pageno: number) {
    const { sort, sortBy } = this.state;
    let reqObj = {
      page: pageno,
      limit: this.state.items,
      name: this.state.name,
      email: this.state.email,
      phoneNumber: this.state.phoneNumber,
      feedback: this.state.feedback,
      createdAt: this.state.createdAt,
      key: this.state.title,
    };
    if (sort) {
      reqObj = Object.assign(reqObj, { sort, sortBy });
    }
    this.props.dispatch(appFeedbackAction.getApplicationFeedback(reqObj));
  }

  /**
   * @description
   * sortData is used for sorting purpose.
   * @param fields {Number} sort
   * @param fields {String} sortBy
   */
  public sortData(sort: any, sortBy: any) {
    const { activePage } = this.state;
    this.setState({ ...this.state, sort, sortBy },
                  () => this.fetchAppFeedbackData(activePage));
  }

  /**
   * @description
   * handleChange is used to set the value on state from the input.
   * @param fields {Object} e
   * @param fields {String} key
   */
  public handleChange = (value, key: string) => {
    this.setState({ ...this.state, [key]: value },
                  () => { this.fetchAppFeedbackData(1); });
  }

  /**
   * @description
   * handlePageChange is called someone click on the pagination.
   * @param fields {Number} pageno
   */
  public handlePageChange = (pageno: number) => {
    this.setState({
      activePage: pageno,
    },            () => this.fetchAppFeedbackData(pageno));
  }

  /**
   * @description
   * showDeletePopup is used show confirmation popup.
   * @param fields {String} id
   */
  public showDeletePopup = (id: string) => {
    const { sort, sortBy } = this.state;
    const modalPopupObj = deleteFeedback;
    let reqObj = {
      page: Number(this.state.activePage),
      limit: this.state.items,
      name: this.state.name,
      email: this.state.email,
      phoneNumber: this.state.phoneNumber,
      feedback: this.state.feedback,
      createdAt: this.state.createdAt,
      key: this.state.title,
    };
    if (sort) {
      reqObj = Object.assign(reqObj, { sort, sortBy });
    }
    this.setState({
      displayReplyPopup: false,
      displayDeletePopup: true,
      userId: id,
      reqObj,
      modalPopupObj,
    });
  }

  /**
   * @description
   * showDeletePopup is used show confirmation popup.
   * @param fields {String} id
   */
  public showReplyPopup = (userDetail: object) => {
    this.setState({
      displayReplyPopup: true,
      modalPopupObj: userDetail,
    });
  }

  /**
   * @description
   * deleteRequest is called when someone click on the ok button
   */
  public deleteRequest = () => {
    const { userId, items, activePage } = this.state;
    const reqObj = {
      page: Number(activePage),
      limit: items,
    };
    this.props.dispatch(appFeedbackAction.deleteFeedback(userId, reqObj));
    this.setState({
      displayDeletePopup: false,
      modalPopupObj: {},
      userId: '',
    });
  }

  /**
   * @description
   * cancelRequest is called when someone click on the ok button
   */
  public sendReply = (reqObj: object) => {
    const { items, activePage } = this.state;
    const listReqObj = {
      page: Number(activePage),
      limit: items,
    };
    this.props.dispatch(appFeedbackAction.sendFeedbackReply(reqObj, listReqObj));
    this.setState({
      displayReplyPopup: false,
      modalPopupObj: {},
      userId: '',
    });
  }

  /**
   * @description
   * renderRequestListing is called to render the request listings
   */
  public renderRequestListing = () => {
    const { list } = this.state.appFeedbackRequest;
    return _.map(list, (item) => {
      return (
        <FeedbackItem key={v4()} request={item} onDelete={this.showDeletePopup} onSendReply={this.showReplyPopup} />
      );
    });
  }

  /**
   * @description
   * render is used to render the html.
   */
  public render() {
    const { sort, sortBy, appFeedbackRequest, items, activePage, displayDeletePopup, modalPopupObj, displayReplyPopup } = this.state;
    const filterContent = ['search'];
    let minDisplay;
    let maxDisplay;
    if (appFeedbackRequest.count === MAGIC_NUMBER.ZERO) {
      maxDisplay = 0;
      minDisplay = 0;
    } else if (appFeedbackRequest.count <= items) {
      maxDisplay = appFeedbackRequest.count;
      minDisplay = 1;
    } else {
      maxDisplay = (Number(activePage) * Number(items)) < appFeedbackRequest.count
        ? (Number(activePage) * Number(items)) : appFeedbackRequest.count;
      minDisplay = ((Number(activePage) - 1) * Number(items)) + 1;
    }
    return (
      <React.Fragment>
        <div className="filter-wrapper">
          <FilterComponent
            filterContent={filterContent}
            handleFilter={this.handleChange}
          />
        </div>
        <div className="table-wrapper">
          <div className="flex-table">
            <div className="table-head" >
              <div className="table-row">
                <div className="table-col table-col-25">
                  <span className="sorting-icon">Name
                      <strong>
                      <em
                        className={(sort === 'name' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                        onClick={() => { this.sortData('name', MAGIC_NUMBER.ONE); }}
                      />
                      <em
                        className={(sort === 'name' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                        onClick={() => { this.sortData('name', MAGIC_NUMBER.FOUND_INDEX); }}
                      />
                    </strong>
                  </span>
                </div>
                <div className="table-col table-col-20">
                  <span className="sorting-icon">Email
                      <strong>
                      <em
                        className={(sort === 'email' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                        onClick={() => { this.sortData('email', MAGIC_NUMBER.ONE); }}
                      />
                      <em
                        className={(sort === 'email' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                        onClick={() => { this.sortData('email', MAGIC_NUMBER.FOUND_INDEX); }}
                      />
                    </strong>
                  </span>
                </div>
                <div className="table-col table-col-15">
                  <span className="sorting-icon">Phone Number
                      <strong>
                      <em
                        className={(sort === 'phoneNumber' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                        onClick={() => { this.sortData('phoneNumber', MAGIC_NUMBER.ONE); }}
                      />
                      <em
                        className={(sort === 'phoneNumber' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                        onClick={() => { this.sortData('phoneNumber', MAGIC_NUMBER.FOUND_INDEX); }}
                      />
                    </strong>
                  </span>
                </div>
                <div className="table-col table-col-15">
                  <span className="sorting-icon">Feedback
                      <strong>
                      <em
                        className={(sort === 'feedback' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                        onClick={() => { this.sortData('feedback', MAGIC_NUMBER.ONE); }}
                      />
                      <em
                        className={(sort === 'feedback' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                        onClick={() => { this.sortData('feedback', MAGIC_NUMBER.FOUND_INDEX); }}
                      />
                    </strong>
                  </span>
                </div>
                <div className="table-col table-col-15">
                  <span className="sorting-icon">Received Date
                      <strong>
                      <em
                        className={(sort === 'createdAt' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                        onClick={() => { this.sortData('createdAt', MAGIC_NUMBER.ONE); }}
                      />
                      <em
                        className={(sort === 'createdAt' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                        onClick={() => { this.sortData('createdAt', MAGIC_NUMBER.FOUND_INDEX); }}
                      />
                    </strong>
                  </span>
                </div>
                <div className="table-col table-col-10 text-center">
                  <span>Action</span>
                </div>
              </div>
            </div>
            <div className="table-body">
              {this.renderRequestListing()}
              {appFeedbackRequest && appFeedbackRequest.applicationFeebackListSuccess && appFeedbackRequest.count === MAGIC_NUMBER.ZERO ?
                <div className="table-row table-no-data">
                  No Results Found.
                    </div>
                : ''}
            </div>
            {
              (appFeedbackRequest && appFeedbackRequest.count > MAGIC_NUMBER.TEN) &&
              <div className="table-footer">
                <React.Fragment>
                  <p>Showing {minDisplay} - {maxDisplay} of {appFeedbackRequest.count}</p>
                  <div className="pagination-wrapper">
                    <Pagination
                      hideFirstLastPages
                      activePage={activePage}
                      itemsCountPerPage={items}
                      totalItemsCount={appFeedbackRequest.count}
                      pageRangeDisplayed={MAGIC_NUMBER.FIVE}
                      onChange={this.handlePageChange}
                    />
                  </div>
                </React.Fragment>
              </div>
            }

          </div>
        </div>
        {displayDeletePopup &&
          <ConfirmatioPopup
            isOpenModal={displayDeletePopup}
            modalPopupObj={modalPopupObj}
            hideModal={() => this.setState({ displayDeletePopup: false })}
            onClickYes={this.deleteRequest}
          />
        }
        {displayReplyPopup &&
          <ApplicationFeedbackModalPopUp
            isOpenModal={displayReplyPopup}
            userDetail={modalPopupObj}
            hideModal={() => this.setState({ displayReplyPopup: false })}
            onClickDelete={this.showDeletePopup} onClickSend={this.sendReply}
          />
        }
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    appFeedbackRequest: state.applicationFeedBack,
  };
}

const connectedListingContainer = connect(mapStateToProps)(ListingContainer);
export { connectedListingContainer as ListingContainer };
