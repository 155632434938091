import { actionTypes } from './types';
interface IAction {
  type: string;
  notifications: {};
  data: {};
  error: string;
}

const initialState = {
  list: [],
  topList: [],
  count: 0,
  unreadCount: 0,
  successForGettingNotifications: undefined,
  successForGettingTopNotifications: undefined,
};

export function notification(state = initialState, action: IAction) {
  switch (action.type) {
    case actionTypes.NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        successForGettingNotifications: true,
        list: action.notifications ? action.notifications['data'] : [],
        count: action.notifications ? action.notifications['count'] : 0,
        unreadCount: action.notifications ? action.notifications['unreadCount'] : 0,
      };
    case actionTypes.TOP_NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        successForGettingTopNotifications: true,
        topList: action.notifications ? action.notifications['data'] : [],
        count: action.notifications ? action.notifications['count'] : 0,
        unreadCount: action.notifications ? action.notifications['unreadCount'] : 0,
      };
    case actionTypes.UPDATE_NOTIFICATION_STATUS_SUCCESS:
      let unreadCount = state.unreadCount;
      const list = state.list;
      if (action.data['notificationId']) {
          const findIndex = list && list.findIndex((val) => val['_id'] === action.data['notificationId']);
          if (findIndex > -1) {
            list[findIndex]['isRead'] = true;
          }
        } else {
          unreadCount = 0;
        }
      return {
          ...state,
          list,
          unreadCount,
        };
    case actionTypes.NOTIFICATION_LIST_REQUEST:
      return {
        requestForGettingNotifications: true,
      };
    case actionTypes.NOTIFICATION_LIST_FAILURE:
      return { error: action.error };
    default:
      return state;
  }
}
