import { actionTypes } from './types';

const initialState = {
  content: [],
  getContentRequest: false,
};

export function contentManagement(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_CONTENT_REQUEST:
      return {
        getContentRequest: true,
        content: state.content,
      };
    case actionTypes.GET_CONTENT_SUCCESS:
      return {
        content: action.data,
      };
    default:
      return state;
  }
}
