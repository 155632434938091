import {
    GET_PLAN_QUERY,
  } from '../graphql-client/queries/customizedPlanQuery';
import {
    UPDATE_PLAN_MUTATION,
    DELETE_PLAN_MUTATION,
    ADD_PLAN_MUTATION,
  } from '../graphql-client/mutations/customizedPlanMutation';
import { client } from '../index';
import { handleResponse } from '../utils/common';

export const customizedPlanService = {
  getPlansData,
  addPlan,
  editPlan,
  deletePlan,
};

async function getPlansData(reqObj: any) {
  const response = await client.query({
    query: GET_PLAN_QUERY,
    variables: reqObj,
  });
  const resp = await handleResponse(response);
  return resp.getPlans;
}

async function editPlan(reqObj: any) {
  const response = await client.mutate({
    mutation: UPDATE_PLAN_MUTATION,
    variables: reqObj,
  });
  const resp = await handleResponse(response);
  return resp.updatePlan.message;
}

async function addPlan(reqObj: any) {
  const response = await client.query({
    query: ADD_PLAN_MUTATION,
    variables: reqObj,
  });
  const resp = await handleResponse(response);
  return resp.createPlan;
}

async function deletePlan(id: any) {
  const response = await client.mutate({
    mutation: DELETE_PLAN_MUTATION,
    variables: id,
  });
  const resp = await handleResponse(response);
  return resp.removePlan.message;
}
