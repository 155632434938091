import gql from 'graphql-tag';

const GET_PLAN_QUERY = gql`
  query getPlans($page: Int, $limit: Int, $isPagination: Boolean!, $key: String,
     $sort: String, $sortBy: Int) {
    getPlans(page: $page, limit: $limit, isPagination: $isPagination, key: $key,
      sort: $sort, sortBy: $sortBy) {
      message,
      count,
      data {
        productName,
        addonAmount,
        licenceAmount,
        learnerAmount,
        _id,
      }
    }
}`;

export { GET_PLAN_QUERY };
