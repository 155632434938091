import gql from 'graphql-tag';

const PAYMENT_MUTATION = gql`
  mutation payment($token: String, $cardId: String, $quantity: Int, $isAddOn: Boolean, $amount: Float!, $isNewCard: Boolean!, $type: String, $bidId: String, $extendEventId: String, $date: Date ) {
    payment(token: $token,cardId: $cardId, quantity: $quantity, isAddOn: $isAddOn, amount: $amount, isNewCard: $isNewCard, type: $type, bidId: $bidId, extendEventId: $extendEventId, date: $date) {
      message
      }
}`;

const PAYMENT_MUTATION_EXISTING_CARD = gql`
  mutation payment($cardId: String!, $quantity: Int!, $isAddOn: Boolean!, $amount: Float!) {
    payment(cardId: $cardId, quantity: $quantity, isAddOn: $isAddOn, amount: $amount) {
      message
      }
}`;

const ADD_PAYMENT_CARD_MUTATION = gql`
  mutation addCard($token: String!) {
    addCard(token: $token) {
      message
  }
}`;

const UPDATE_PAYMENT_CARD_MUTATION = gql`
  mutation updateCard($cardId: String!) {
    updateCard(cardId: $cardId) {
      message
  }
}`;

const DELETE_PAYMENT_CARD_MUTATION = gql`
  mutation deleteCard($cardId: String!) {
    deleteCard(cardId: $cardId) {
      message
  }
}`;

const WITHDRAW_AMOUNT_MUTATION = gql`
  mutation withdraw {
    withdraw {
      message
  }
}`;

const UPDATE_DISPUTE_MUTATION = gql`
  mutation updateDisputeStatus($bidId: [String], $status: String) {
    updateDisputeStatus(bidId: $bidId, status: $status) {
      message
  }
}`;

const WITHDRAW_REQUEST = gql`
  mutation withdrawnRequest {
    withdrawnRequest{
      message
    }
}`;

const UPDATE_WITHDRAWN_REQUEST = gql`
  mutation updateWithdrawnRequest($requestedId: ID!) {
    updateWithdrawnRequest(requestedId: $requestedId) {
      message
  }
}`;

const UPDATE_LICENCE = gql`
  mutation updateLicence($userId: String, $licence: Int) {
    updateLicence(userId: $userId, licence: $licence) {
      message
  }
}`;

export {
  PAYMENT_MUTATION,
  ADD_PAYMENT_CARD_MUTATION,
  UPDATE_PAYMENT_CARD_MUTATION,
  DELETE_PAYMENT_CARD_MUTATION,
  PAYMENT_MUTATION_EXISTING_CARD,
  WITHDRAW_AMOUNT_MUTATION,
  UPDATE_DISPUTE_MUTATION,
  WITHDRAW_REQUEST,
  UPDATE_WITHDRAWN_REQUEST,
  UPDATE_LICENCE,
};
