import { actionTypes } from './types';

interface IAction {
  type: string;
  data: {};
  error: string;
}
export function createPassword(state = {}, action: IAction) {
  switch (action.type) {
      case actionTypes.VERIFY_TOKEN_REQUEST:
        return { verifyingToken: true };
      case actionTypes.VERIFY_TOKEN_SUCCESS:
        return {
          ...state,
          token: action.data,
          isValidToken: true,
          verifyingToken: false,
        };
      case actionTypes.VERIFY_TOKEN_FAILURE:
        return {
            ...state,
            isValidToken: false,
            verifyingToken: false,
          };

      default:
        return state;
    }
}
