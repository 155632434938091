import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import OpenFieldTypes from 'OpenFieldTypes';
import { Sidebar } from '../../shared/sidebar';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import ReeValidate from 'ree-validate';
import { IViewProfile } from '../../../interfaces/index';
import { pageRoutes } from './../../../utils/routeConstants';
import { profileAction } from '../../shared/updateProfile/redux/actions';
import headphoneIcon from './../../../assets/images/icons/headphone.svg';
import { IValidator } from '../../../interfaces';
import { reeValidators, MAGIC_NUMBER } from '../../../utils';
import { expertPaymentAction } from './redux/actions';
import { Back } from '../../shared/back/back';
interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  viewProfile: IViewProfile;
}

interface IState {
  errors?: any;
  formData: {
    name: string;
    email: string;
    subject: string;
    message: string;
  };
}
class ConnectWithOpenField extends React.Component<IProps, IState>  {

  public validator: IValidator;
  constructor(props: IProps) {
    super(props);

    this.validator = new ReeValidate({
      subject: 'required',
      message: reeValidators.required_min_3_max_500,
    });

    this.state = {
      formData: {
        name: '',
        email: '',
        subject: '',
        message: '',
      },
      errors: this.validator.errors,
    };
  }

  public componentDidMount = () => {
    const { dispatch } = this.props;
    dispatch(profileAction.getProfile());
  }

  public handleChange = (event: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const { errors } = this.validator;
    this.setState({ formData: { ...this.state.formData, [name]: value } });
    errors.remove(name);
    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors });
      });
  }
  public validateAndSubmit = (e) => {
    e.preventDefault();
    const { subject, message } = this.state.formData;
    const { errors } = this.validator;
    this.validator.validateAll({ subject, message })
      .then((success: boolean) => {
        if (success) {
          this.handleSubmit();
        } else {
          this.setState({ errors });
        }
      });
  }

  public handleSubmit() {
    const { message, subject } = this.state.formData;
    const requestObj = {
      subject,
      message,
    };
    this.props.dispatch(expertPaymentAction.expertFeedback(requestObj));
  }

  public render() {
    const { formData, errors } = this.state;
    // tslint:disable-next-line:no-shadowed-variable
    const { viewProfile } = this.props;
    return (
      <>
        <Sidebar {...this.props} />
        <div className="dashboard-wrapper">
          <Back {...this.props} />
          <div className="dashboard-content shadow mb-0 mt-3">
            <div className="profile-password-wrapper">
              <Form noValidate>
                {viewProfile && viewProfile.firstName ? <FormGroup
                  className={`floating-label disabled-input disabled`}
                >
                  <Input
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    placeholder="Name"
                    value={`${viewProfile && viewProfile.firstName} ${viewProfile && viewProfile.lastName}`}
                    readonly
                  />
                  <Label for="firstName">Name</Label>
                </FormGroup> : ''}
                {viewProfile && viewProfile.email ? <FormGroup
                  className={`floating-label disabled-input disabled`}
                >
                  <Input
                    className="form-control"
                    id="email"
                    name="email"
                    placeholder="Email"
                    value={viewProfile && viewProfile.email}
                    readonly
                  />
                  <Label for="email">Email</Label>
                </FormGroup> : ''}
                <FormGroup
                  className={`floating-label ${
                    errors.has('subject') ? ' has-error' : ''
                    }`}>
                  <Input
                    type="text"
                    className="form-control"
                    name="subject"
                    id="subject"
                    placeholder="Subject"
                    onChange={this.handleChange}
                  />
                  <Label for="subject">Subject</Label>
                  {errors.has('subject') &&
                    <div className="error-text">{errors.first('subject')}</div>
                  }
                </FormGroup>
                <FormGroup className={`floating-label ${
                  errors.has('message') ? ' has-error' : ''
                  }`}>
                  <Input
                    type="text"
                    className="form-control"
                    id="message"
                    name="message"
                    placeholder="Message"
                    maxLength={MAGIC_NUMBER.CHAR_LENGTH}
                    value={formData.message}
                    onChange={this.handleChange}
                  />
                  <Label for="message">Message</Label>
                  <span className="input-count">{formData.message.length}/500</span>
                  {errors.has('message') &&
                    <div className="error-text"> {errors.first('message')}</div>
                  }
                  <div className="error-text"/>
                </FormGroup>
                <FormGroup className="login-button-group mb-0 d-flex justify-content-end">
                  <Link to={`${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.PAYMENT.PATH}`} className="btn btn-regular mr-3">Cancel</Link>
                  <Button color="primary" className="" onClick={this.validateAndSubmit}>Send</Button>
                </FormGroup>
              </Form>
              <div className="lock-box payment-lockbox">
                <div className="key-circle">
                  <img src={headphoneIcon} alt="key" />
                  <p>Contact us and we'll get back to you within 2 working days.</p>
                </div>
              </div>
            </div>
          </div></div >
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const { updateProfile } = state;
  return {
    viewProfile: updateProfile['profile'],
  };
}
const connectedConnectWithOpenField = connect(mapStateToProps)(ConnectWithOpenField);
export { connectedConnectWithOpenField as ConnectWithOpenField };
