import gql from 'graphql-tag';

export const CREATE_GROUP = gql`
  mutation createGroup(
    $groupName: String!,
    $users: [String]!,
  ) {
      createGroup(
        groupName: $groupName,
        users: $users
      ) {
        message
      }
    }
`;

export const REMOVE_GROUP = gql`
  mutation removeGroup(
    $groupId: String!,
  ) {
    removeGroup(
        groupId: $groupId,
      ) {
        message
      }
    }
`;

export const GET_CONNECTION = gql`
  mutation getConnection(
    $bidderId: ID,
  ) {
    getConnection(
      bidderId: $bidderId,
      ) {
        _id,
      }
    }
`;

export const BID_MESSAGE_REQUEST = gql`
  mutation bidMessageRequest(
    $message: String, $goalId: String, $expertId: String,
  ) {
    bidMessageRequest(
        message: $message, goalId: $goalId, expertId: $expertId,
      ) {
        message
      }
    }
`;
