import React from 'react';
import OpenFieldTypes from 'OpenFieldTypes';
import { IBidDetail, IParams, IViewProfile } from './../../../interfaces';
import { LocationDescriptorObject } from 'history';
import { ViewShortProfile } from './viewProfile';
import { ViewCompleteProfile } from './viewCompleteProfile';

interface IProps {
  dispatch: OpenFieldTypes.RootAction;
  profile: IViewProfile;
  bidDetail: IBidDetail;
  location: LocationDescriptorObject;
  match: {
    params: {
      [key: string]: IParams;
    };
  };
}

interface IState {
  displayUnlockProfilePopup: boolean;
}

export default class ViewProfileContainer extends React.Component<IProps, IState>  {
  public render() {
    const { match: { params: { complete } } } = this.props;
    return (
      <React.Fragment>
        {complete ?  <ViewCompleteProfile {...this.props}/> :
              <ViewShortProfile {...this.props}/>}
      </React.Fragment>
    );
  }
}
