import gql from 'graphql-tag';

const GET_LOGS_LIST_QUERY = gql`
  query getLogs($limit: Int, $page: Int, $userId: String ) {
    getLogs(limit: $limit, page: $page, userId: $userId) {
      message
      count
      data {
        content
        goalId
        createdAt,
        receiverId{
          profileImage,
          _id
        },
        data{
          date
          time
        }
      },
    }
}`;

export { GET_LOGS_LIST_QUERY };
