import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    Button,
    Form,
    FormGroup,
    Label,
} from 'reactstrap';
import OpenFieldTypes from 'OpenFieldTypes';
import { IValidator } from './../../../interfaces';
import { reeValidators, MAGIC_NUMBER } from '../../../utils';
import ReeValidate from 'ree-validate';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  isOpenModal: boolean;
  modalPopupObj: {};
  onClickYes: any;
  hideModal(): void;
}

interface IState {
  modal: boolean;
  errors?: any;
  formData: {
    reasonForFlagUser: string;
  };
}

class FlagUserPopUp extends React.Component<IProps, IState> {
  public validator: IValidator;
  constructor(props: IProps) {
    super(props);
    this.validator = new ReeValidate({
      reasonForFlagUser: reeValidators.required_min_3_max_100,
    });

    this.state = {
      modal: props.isOpenModal,
      formData: { reasonForFlagUser: '' },
      errors: this.validator.errors,
    };
  }

  public hideModal = () => {
    this.setState({
      modal: false,
    });
    this.props.hideModal();
  }

  public handleChange = (event: any) => {
    const { name, value } = event.target;
    const { errors } = this.validator;
    this.setState({ formData: { ...this.state.formData, [name]: value } });
    errors.remove(name);
    this.validator.validate(name, value)
            .then(() => {
              this.setState({ errors });
            });
  }

  public validateAndSubmit = (e) => {
    e.preventDefault();
    const { reasonForFlagUser } = this.state.formData;
    const { errors } = this.validator;
    this.validator.validateAll({ reasonForFlagUser })
            .then((success: boolean) => {
              if (success) {
                this.handleSubmit();
              } else {
                this.setState({ errors });
              }
            });
  }

  public handleSubmit() {
    const obj = this.props.modalPopupObj;
    obj['comment'] = this.state.formData.reasonForFlagUser;
    this.props.onClickYes(obj);
  }

  public render() {
    const { modal, formData, errors } = this.state;
    return (
            <Modal isOpen={modal} className="organization-code-modal">
                <ModalBody>
                    <h2 className="heading heading-lg roboto-bold text-center">Flag User</h2>
                    <small className="text-center d-block pt-2 pb-4">Are you sure you want to flag the user?</small>
                    <Form onSubmit={this.validateAndSubmit}>
                        <FormGroup
                            className={`floating-label disabled-input textarea-label ${
                                errors.has('reasonForFlagUser') ? ' has-error' : ''
                                }`}>
                            <textarea
                                className="form-control textarea-md"
                                name="reasonForFlagUser"
                                id="reasonForFlagUser"
                                value={formData.reasonForFlagUser}
                                onChange={this.handleChange}
                                maxLength={MAGIC_NUMBER.HUNDRED}
                                placeholder="Comment"
                            />
                            <span className="text-count">Character left {100 - formData.reasonForFlagUser.length}</span>
                            <Label for="summary" className={formData.reasonForFlagUser ? 'selected' : ''}>Comment</Label>
                            {errors.has('reasonForFlagUser') &&
                                <div className="error-text">{`${'Reason must be at least 3 characters long.'}`}</div>
                            }
                        </FormGroup>
                    </Form>
                    <div className="button-group text-center">
                        <Button className="btn btn-regular mr-2" onClick={this.hideModal}>Cancel</Button>{' '}
                        <Button color="primary" onClick={this.validateAndSubmit}>Flag</Button>
                    </div>
                </ModalBody>
            </Modal>
    );
  }
}

const connectedFlagUserPopUp = connect()(FlagUserPopUp);
export { connectedFlagUserPopUp as FlagUserPopUp };
