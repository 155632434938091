export const actionTypes = {
  EXPERT_FEEDBACK_SUCCESS: 'EXPERT_FEEDBACK_SUCCESS',
  EXPERT_FEEDBACK_FAILURE: 'EXPERT_FEEDBACK_FAILURE',
  CHANGE_RATE_PER_HOUR_REQUEST: 'CHANGE_RATE_PER_HOUR_REQUEST',
  CHANGE_RATE_PER_HOUR_SUCCESS: 'CHANGE_RATE_PER_HOUR_SUCCESS',
  CHANGE_RATE_PER_HOUR_FAILURE: 'CHANGE_RATE_PER_HOUR_FAILURE',
  FETCH_TOTAL_PAYMENTS_SUCCESS: 'FETCH_TOTAL_PAYMENTS_SUCCESS',
  FETCH_TOTAL_PAYMENTS_FAILURE: 'FETCH_TOTAL_PAYMENTS_FAILURE',
  GET_PAYMENT_LOGS_REQUEST: 'GET_PAYMENT_LOGS_REQUEST',
  GET_PAYMENT_LOGS_SUCCESS: 'GET_PAYMENT_LOGS_SUCCESS',
  GET_PAYMENT_LOGS_FAILURE: 'GET_PAYMENT_LOGS_FAILURE',
  GET_EXPERT_INCOME_REQUEST: 'GET_EXPERT_INCOME_REQUEST',
  GET_EXPERT_INCOME_SUCCESS: 'GET_EXPERT_INCOME_SUCCESS',
  GET_EXPERT_INCOME_FAILURE: 'GET_EXPERT_INCOME_FAILURE',
  GET_WITHDRAW_HISTORY_REQUEST: 'GET_WITHDRAW_HISTORY_REQUEST',
  GET_WITHDRAW_HISTORY_SUCCESS: 'GET_WITHDRAW_HISTORY_SUCCESS',
  GET_WITHDRAW_HISTORY_FAILURE: 'GET_WITHDRAW_HISTORY_FAILURE',
  GET_WITHDRAW_AMOUNT_REQUEST: 'GET_WITHDRAW_AMOUNT_REQUEST',
  GET_WITHDRAW_AMOUNT_SUCCESS: 'GET_WITHDRAW_AMOUNT_SUCCESS',
  GET_WITHDRAW_AMOUNT_FAILURE: 'GET_WITHDRAW_AMOUNT_FAILURE',
};
