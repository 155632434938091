import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { Listing } from './listings/listing';
import OpenFieldTypes from 'OpenFieldTypes';
import { Sidebar } from '../sidebar';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
}

class RequestManagementContainer extends React.Component<IProps> {

  public render() {
    return (
      <React.Fragment>
        <Sidebar {...this.props} />
        <div className="dashboard-wrapper">
          <h2 className="heading heading-sm roboto-medium text-uppercase">Request Management</h2>
          <div className="dashboard-content">
            <Listing />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user,
    role: user.role,
  };
}

const connectedRequestManagementPage = connect(mapStateToProps)(RequestManagementContainer);
export { connectedRequestManagementPage as RequestManagementContainer };
