import moment from 'moment';
import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { find } from 'lodash';
import OpenFieldTypes from 'OpenFieldTypes';
import { UncontrolledTooltip } from 'reactstrap';
import { IGoalDetailWithId } from './../../../../interfaces';
import { history } from '../../../../helpers';
import { staticConstants, bidStatus, pageRoutes, RESCHEDULE_STATUS, MAGIC_NUMBER } from '../../../../utils';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  bid: IGoalDetailWithId;
  makeBid: any;
  addToFavourite: any;
  activeTab: string;
  cancelBid: any;
}

class BidItem extends React.Component<IProps> {

  public redirectOnBidDetail(event: any, activeTab: any, bid: any) {
    const skipClasees = ['icon icon-unsave','icon icon-save',
    'icon icon-expertise','icon icon-bid', 'icon icon-cross danger'];
    if (skipClasees.indexOf(event.target.className) === MAGIC_NUMBER.FOUND_INDEX &&
      activeTab !== staticConstants.BID_MANAGEMENT_TAB.REJECTED_BIDS) {
      history.push({
        pathname: `${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.BID_MANAGEMENT.PATH}${pageRoutes.EXPERT.BID_MANAGEMENT.DETAIL}/${bid._id}`,
        state: { activeTab },
      });
    }
  }

  /**
   * @description
   * render is used to render the component
   */
  public render() {

    const { bid, makeBid, activeTab } = this.props;
    let createdDate: any;
    if (activeTab === staticConstants.BID_MANAGEMENT_TAB.SAVED_REQUEST) {
      if (bid.favourite && bid.favourite.length > MAGIC_NUMBER.ZERO) {
        createdDate = moment(bid.favourite[0].createdAt).format(staticConstants.DATE_FORMAT);
      }
    } else if (activeTab === staticConstants.BID_MANAGEMENT_TAB.SENT_BIDS) {
      const statusTimeline = bid.bid && bid.bid.length ? bid.bid[0].statusTimeline : [];
      const bidProposedTimelineEvent = find(statusTimeline, { status: 'proposed' });
      if (bid && bid.bid && bid.bid.length > MAGIC_NUMBER.ZERO && bidProposedTimelineEvent) {
        createdDate = moment(bidProposedTimelineEvent.createdAt).format(staticConstants.DATE_FORMAT);
      }
    } else {
      if (bid && bid.bid && bid.bid.length > MAGIC_NUMBER.ZERO) {
        createdDate = (activeTab === staticConstants.BID_MANAGEMENT_TAB.REJECTED_BIDS)
          && moment(bid['bid'][0] && bid['bid'][0]['rejectedAt']).format(staticConstants.DATE_FORMAT);
      } else {
        createdDate = moment(bid.createdAt).format(staticConstants.DATE_FORMAT);
      }
    }
    const participants = bid && bid.participants.length;
    const isFavourite = bid.isFavourite;
    bid['isEventScheduled'] = bid['statusTimeline'].filter((el: { status: string }) => el.status === bidStatus.SCHEDULE);
    const getFavSection = () => {
      if ([staticConstants.BID_MANAGEMENT_TAB.OPEN_REQUEST, staticConstants.BID_MANAGEMENT_TAB.SENT_BIDS]
        .indexOf(activeTab) === MAGIC_NUMBER.FOUND_INDEX) {
        return <>
          {isFavourite ?
            <><em id={`tooltip_unfavoriate_${bid._id}`} className="icon icon-unsave" onClick={() => this.props.addToFavourite(false, bid._id)} />
              <UncontrolledTooltip placement="top" target={`tooltip_unfavoriate_${bid._id}`} >Remove</UncontrolledTooltip>
            </>
            : <><em id={`tooltip_favoriate_${bid._id}`} className="icon icon-save" onClick={() => this.props.addToFavourite(true, bid._id)} />
              <UncontrolledTooltip placement="top" target={`tooltip_favoriate_${bid._id}`} >Save</UncontrolledTooltip>
            </>
          }
          {activeTab === staticConstants.BID_MANAGEMENT_TAB.NEW_REQUEST ||
            activeTab === staticConstants.BID_MANAGEMENT_TAB.SAVED_REQUEST ?
            <>
              {!bid['isBid'] &&
                <><em id={`tooltip_makebid_${bid._id}`} className="icon icon-bid" onClick={() => { makeBid(bid); }}/>
                <UncontrolledTooltip placement="top" target={`tooltip_makebid_${bid._id}`} >Bid</UncontrolledTooltip></>
              }
            </> : ''
          }
        </>;
      }
    };

    const getExpertiseSection = () => {
      const { bid: { _id } } = this.props;
      const url = `${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.EDIT_PROFILE.PATH}?isOpenRequest=true&bidId=${_id}`;
      return <React.Fragment>
        {activeTab === staticConstants.BID_MANAGEMENT_TAB.OPEN_REQUEST ?
          <Link to={url}>
            <em id={`tooltip_tags_${_id}`} className="icon icon-expertise"/>
            <UncontrolledTooltip placement="top" target={`tooltip_tags_${_id}`} >Tag Update</UncontrolledTooltip>
          </Link> : null
        }
      </React.Fragment>;
    };

    const getDeleteSection = (bidData: any) => {
      return <>
        {(activeTab === staticConstants.BID_MANAGEMENT_TAB.SENT_BIDS) && (
        <><em className="icon icon-cross danger" id={`tooltip_${bidData._id}`} onClick={() => this.props.cancelBid(bidData.bid[0]._id)} />
        <UncontrolledTooltip placement="top" target={`tooltip_${bidData._id}`} >Cancel Bids</UncontrolledTooltip></>
        )}
      </>;
    };

    const getBidStatus = (bidData: any, isClosedRequest: boolean) => {
      const denied = isClosedRequest ? 'Closed Bids'
        : bidData.status === 'rejected' ? 'Denied'
        : bidData.rescheduleRequestStatus === 'expired' ? 'Expired' : null;
      return <>
        {denied ? denied : 'Closed Bids'}
      </>;
    };

    return (

      <div className="table-row" onClick={(event) => this.redirectOnBidDetail(event, activeTab, bid)} >
        <div className="table-col table-col-20" data-label="Title">
          {
            <span className="roboto-medium link-btn text-truncate">{bid.title}</span>
          }
        </div>
        <div className="table-col table-col-10" data-label="Event Type">
          <span>{bid.eventType}</span>
        </div>
        <div className="table-col table-col-20" data-label="Domain">
          <span>{bid.domain.name}</span>
        </div>
        <div className="table-col table-col-15" data-label="Sub-Domain">
          <span>{bid.subDomain.name}</span>
        </div>
        {
          bid && bid.bid && bid.bid.length > MAGIC_NUMBER.ZERO && bid.bid[0].rescheduleRequestStatus === RESCHEDULE_STATUS.REQUESTED ? (
            <div className="table-col table-col-10 sm-50" data-label="Created Date">
              <span>Pending</span>
            </div>) : (<div className="table-col table-col-10 sm-50" data-label="Created Date">
              <span> {moment(createdDate).format(staticConstants.DATE_FORMAT)}</span>
            </div>)
        }
        <div className="table-col table-col-15 sm-50" data-label="Participants">
          <span className="text-center">{participants}</span>
        </div>
        {
          activeTab === staticConstants.BID_MANAGEMENT_TAB.REJECTED_BIDS &&
          <div className="table-col table-col-15 sm-50" data-label="Status">
            <span className="text-center">
              {bid && bid.bid && bid.bid.length > MAGIC_NUMBER.ZERO && getBidStatus(bid.bid[0], bid.isAcceptBidClosed)}
              </span>
          </div>
        }
        {
          activeTab !== staticConstants.BID_MANAGEMENT_TAB.REJECTED_BIDS &&
          <div className="table-col table-col-10 text-center p-0 action-col">
            {getFavSection()}
            {getExpertiseSection()}
            {bid['isEventScheduled'].length < 1 && getDeleteSection(bid)}
          </div>
        }
      </div >
    );
  }
}

const bidPage = connect(null)(BidItem);
export { bidPage as BidItem };
