import React, { useEffect, useState } from 'react';
import AccountForm from './AccountForm';
import StripeConnection from './../../shared/updateProfile/StripeConnection';
import {locations,
  reactSelectFilters, customSelectStyles,
} from '../../../utils/';
import PaymentPortalView from './PaymentPortalView';
import {
  FormGroup,
  Label,
  Row,
  Col,
} from 'reactstrap';
import Select, { createFilter } from 'react-select';
import { stripeCountries } from './../../../utils/staticConstants';
import { manualPaymentActions } from './redux/action';
import { useDispatch, useSelector } from 'react-redux';

interface IProps {
  stripeAccountId: string;
  createPaymentGateWayDashBoardLink: any;
  inStripeRegion: boolean;
}

function PaymentPortal({ stripeAccountId, createPaymentGateWayDashBoardLink, inStripeRegion = false }: IProps) {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showAcctForm, setShowAcctForm] = useState(false);
  const [showConnectStripe, setShowConnectStripe] = useState(false);
  const [editBankInfo, setEditBankInfo] = useState(false);
  const [showRegionSelect, setShowRegionSelect] = useState(false);

  const dispatch = useDispatch();
  const bankInfo = useSelector((state: any) => state.bankDetails.bankInfo);
  const role =  useSelector((state: any) => state.updateProfile?.profile?.role);

  useEffect(() => {
    (stripeAccountId && stripeAccountId.length || inStripeRegion) &&  ((() => {
      setShowConnectStripe(true);
      setShowAcctForm(false);
      setShowRegionSelect(false);
    })());

    !inStripeRegion && ((() => {
      setShowConnectStripe(false);
      setShowAcctForm(true);
      dispatch(manualPaymentActions.getBankInfo());
    })());
  },        [inStripeRegion, stripeAccountId]);
  useEffect(() => {
    if (selectedLocation) {
      if (stripeCountries.indexOf(selectedLocation.value) > -1) {
        setShowConnectStripe(true);
        setShowAcctForm(false);
        setEditBankInfo(false);
      } else {
        setShowConnectStripe(false);
        editBankInfo && setShowRegionSelect(true);
        if (bankInfo && bankInfo['region']) {
          if (editBankInfo) {
            setShowAcctForm(false);
            setShowRegionSelect(true);
            setEditBankInfo(true);
          } else {
            setShowAcctForm(true);
            setShowRegionSelect(false);
            setEditBankInfo(false);
            if (selectedLocation.value === bankInfo['region']) {
              setShowAcctForm(true);
              setEditBankInfo(false);
            } else {
              setEditBankInfo(true);
              setShowAcctForm(false);
              setShowRegionSelect(true);
            }
          }
        }
        bankInfo && !bankInfo['region'] && setEditBankInfo(true);
      }
    }
  },        [selectedLocation]);

  useEffect(() => {
    setShowRegionSelect(false);
    bankInfo &&  bankInfo['region'] && setSelectedLocation({ label:bankInfo['region'], value:bankInfo['region'] });
    bankInfo && !bankInfo['region'] && stripeAccountId === '' && setShowRegionSelect(true);
  },        [bankInfo]);

  const setEditBankInfoMode = (showEdit: boolean) => {
    showEdit && (() => {
      setEditBankInfo(true);
      setShowAcctForm(false);
      setShowRegionSelect(true);
    })();

    !showEdit && (() => {
      if (bankInfo && !bankInfo['region']) {
        setShowRegionSelect(true);
        setEditBankInfo(true);
        setShowAcctForm(false);
      } else {
        setShowAcctForm(true);
        setEditBankInfo(false);
        setShowRegionSelect(false);
      }
    })();
  };

  return (
    <>
     {showAcctForm && bankInfo['region'] && <PaymentPortalView setEditBankInfoMode={setEditBankInfoMode} />}
     {showRegionSelect &&  <div className="payment-form-region-wrapper">
        <Row className="w-100">
          <Col xs="12" md="6">
            {<FormGroup className={`floating-label disabled-input`}>
            <Select
                value={selectedLocation}
                onChange={setSelectedLocation}
                options={locations}
                placeholder="Region"
                isSearchable
                styles={customSelectStyles}
                filterOption={createFilter(reactSelectFilters)}
                className="react-select-box select-box-group"
            />
            <Label for="location" className={selectedLocation ? 'selected' : ''}>Region</Label>
            </FormGroup>}
          </Col>
        </Row>
      </div> }
      {(showConnectStripe) && <StripeConnection role={role} createPaymentGateWayDashBoardLink={createPaymentGateWayDashBoardLink} stripeAccountId={stripeAccountId}/>}
      {editBankInfo && selectedLocation?.value &&
      <AccountForm canCancel={Boolean(bankInfo && bankInfo['region'])}
      region={selectedLocation.value}  setEditBankInfoMode={setEditBankInfoMode}/>}
    </>
  );
}

export default PaymentPortal;
