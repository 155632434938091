import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import Select from 'react-select';
import OpenFieldTypes from 'OpenFieldTypes';
import { IValidator } from '../../../../interfaces';
import { reeValidators, staticConstants, customSelectStyles,
  getUpdatedOptions, MAGIC_NUMBER } from '../../../../utils';
import ReeValidate from 'ree-validate';
import { appPlanAction } from '../../plans/redux/action';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  isOpenModal: boolean;
  onClickAdd: any;
  modalTitle: string;
  userType: string;
  planOptions: any;
  user: any;
  hideModal(): void;
}

interface IState {
  modal: boolean;
  errors?: any;
  formData: {
    firstName: string;
    lastName: string;
    email: string;
    organizationName: string;
    hourlyRate: number;
    subscribedProductId: string;
  };
}

class CreateUser extends React.Component<IProps, IState> {
  public validator: IValidator;
  constructor(props: IProps) {
    super(props);
    const validateObj = {
      firstName: reeValidators.required_min_3_max_100,
      lastName: reeValidators.required_min_3_max_100,
      email: 'required',
      organizationName: 'required',
    };
    this.validator = new ReeValidate(validateObj);
    this.state = {
      modal: this.props.isOpenModal,
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        organizationName: '',
        hourlyRate: 0,
        subscribedProductId: '',
      },
      errors: this.validator.errors,
    };
  }

  /**
   * @description
   * reIntializeValidation is used to add validation again
   */
  public reIntializeValidation = () => {
    const { userType } = this.props;
    const validateObj = {
      firstName: reeValidators.required_min_3_max_100,
      lastName: reeValidators.required_min_3_max_100,
      email: 'required',
    };

    if (userType === staticConstants.ROLE.ORGANIZATION_ADMIN) {
      validateObj['organizationName'] = 'required';
      validateObj['subscribedProductId'] = 'required';
      delete validateObj['hourlyRate'];
    }
    if (userType === staticConstants.ROLE.EXPERT) {
      validateObj['hourlyRate'] = 'required';
      delete validateObj['organizationName'];
    }
    if (userType === staticConstants.ROLE.PROFESSIONAL) {
      delete validateObj['hourlyRate'];
      delete validateObj['organizationName'];
      validateObj['subscribedProductId'] = 'required';
    }
    this.validator = new ReeValidate(validateObj);
  }

  public hideModal = () => {
    this.setState({
      modal: false,
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        organizationName: '',
        hourlyRate: 0,
        subscribedProductId: '',
      },
    });
    const { errors } = this.validator;
    errors.clear();
    this.props.hideModal();
  }

  public componentWillReceiveProps(props: IProps, nextProp: IProps) {
    this.setState({
      modal: props.isOpenModal,
    });
    this.reIntializeValidation();
  }

  /**
   * @description componentDidMount is called when component is loaded
   */
  public componentDidMount() {
    this.props.dispatch(appPlanAction.getPlanListing({ isPagination: false }));
    this.reIntializeValidation();
  }

  public handleChange = (event: any) => {
    const { name, value } = event.target;
    const { errors } = this.validator;
    this.setState({ formData: { ...this.state.formData, [name]: value } });
    errors.remove(name);
    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors });
      });
  }

  public handlePlanType = (event: any, key: string) => {
    const { value } = event;
    const { errors } = this.validator;
    this.setState({ formData
      : { ...this.state.formData, [key]: value } });
    errors.remove(key);
    this.validator.validate(key, value)
      .then(() => {
        this.setState({ errors });
      });
  }

  public validateAndSubmit = (e) => {
    e.preventDefault();
    const { firstName, lastName, email, organizationName, hourlyRate } = this.state.formData;
    const { errors } = this.validator;
    this.validator.validateAll({ firstName, lastName, email, organizationName, hourlyRate })
      .then((success: boolean) => {
        if (success) {
          this.handleSubmit();
        } else {
          this.setState({ errors });
        }
      });
  }

  public handleSubmit() {
    const obj = {};
    const { userType } = this.props;
    obj['userType'] = this.props.userType;
    obj['firstName'] = this.state.formData.firstName;
    obj['lastName'] = this.state.formData.lastName;
    obj['email'] = this.state.formData.email;
    obj['organizationName'] = userType === staticConstants.ROLE.ORGANIZATION_ADMIN ? this.state.formData.organizationName : '';
    obj['hourlyRate'] = userType === staticConstants.ROLE.EXPERT ? this.state.formData.hourlyRate : 0;
    obj['licence'] = 0;
    obj['subscribedProductId'] = this.state.formData.subscribedProductId;
    this.props.onClickAdd(obj);
  }

  public render() {
    const { modal, formData, errors } = this.state;
    const { modalTitle, userType, planOptions, user } = this.props;
    return (
      <Modal isOpen={modal} className="organization-code-modal create-user-modal" onClosed={this.hideModal}>
        <ModalHeader toggle={this.hideModal}>{modalTitle}</ModalHeader>
        <ModalBody>
          <Form onSubmit={this.validateAndSubmit}>
          {
              (userType === staticConstants.ROLE.ORGANIZATION_ADMIN || userType === staticConstants.ROLE.PROFESSIONAL) && user.role === staticConstants.ROLE.SYSTEM_ADMIN &&
              <FormGroup className={`floating-label disabled-input`}>
              <Select
                 name="subscribedProductId"
                 styles={customSelectStyles}
                 onChange={e => this.handlePlanType(e, 'subscribedProductId')}
                 options={planOptions}
                 id="subscribedProductId"
                 placeholder="Plan"
                 className="react-select-box select-box-group"
              />
             <Label for="subscribedProductId" className={formData.subscribedProductId ? 'selected' : ''}>Plan</Label>
             </FormGroup>
            }
            <FormGroup
              className={`floating-label ${
                errors.has('firstName') ? ' has-error' : ''
                }`}>
              <Input
                className="form-control"
                name="firstName"
                id="firstName"
                value={formData.firstName}
                onChange={this.handleChange}
                maxLength={MAGIC_NUMBER.HUNDRED}
                placeholder="First Name"
              />
              <Label for="summary" className={formData.firstName ? 'selected' : ''}>First Name</Label>
              {errors.has('firstName') &&
                <div className="error-text">{errors.first('firstName').replace('firstName', 'first name')}</div>
              }
            </FormGroup>
            <FormGroup
              className={`floating-label ${
                errors.has('lastName') ? ' has-error' : ''
                }`}>
              <Input
                className="form-control"
                name="lastName"
                id="lastName"
                value={formData.lastName}
                onChange={this.handleChange}
                maxLength={MAGIC_NUMBER.HUNDRED}
                placeholder="Last Name"
              />
              <Label for="summary" className={formData.lastName ? 'selected' : ''}>Last Name</Label>
              {errors.has('lastName') &&
                <div className="error-text">{errors.first('lastName').replace('lastName', 'last name')}</div>
              }
            </FormGroup>

            {
              userType === staticConstants.ROLE.ORGANIZATION_ADMIN &&
              <FormGroup
                className={`floating-label ${
                  errors.has('organizationName') ? ' has-error' : ''
                  }`}>
                <Input
                  className="form-control"
                  name="organizationName"
                  id="organizationName"
                  value={formData.organizationName}
                  onChange={this.handleChange}
                  maxLength={MAGIC_NUMBER.HUNDRED}
                  placeholder="Team Name"
                />
                <Label for="summary" className={formData.organizationName ? 'selected' : ''}>Team Name</Label>
                {errors.has('organizationName') &&
                  <div className="error-text">{errors.first('organizationName').replace('organizationName', 'team name')}</div>
                }
              </FormGroup>
            }

            <FormGroup
              className={`floating-label ${
                errors.has('email') ? ' has-error' : ''
                }`}>
              <Input
                className="form-control"
                name="email"
                id="email"
                value={formData.email}
                onChange={this.handleChange}
                placeholder="Email"
              />
              <Label for="email">Email</Label>
              {errors.has('email') &&
                <div className="error-text">{errors.first('email')}</div>
              }
            </FormGroup>
            {
              userType === staticConstants.ROLE.EXPERT &&
              <FormGroup
                className={`floating-label disabled-input price-placeholder ${
                  errors.has('hourlyRate') ? ' has-error' : ''
                  }`}>
                <Input
                  type="number"
                  className="form-control"
                  name="hourlyRate"
                  id="hourlyRate"
                  placeholder="0.00"
                  onChange={this.handleChange}
                />
                <em>$</em>
                <Label for="hourlyRate" className="selected">Set Hourly Rate</Label>
                {errors.has('hourlyRate') &&
                  <div className="error-text">{errors.first('hourlyRate').replace('hourlyRate', 'set hourly rate')}</div>
                }
              </FormGroup>
            }
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-regular mr-2" onClick={this.hideModal}>Cancel</Button>{' '}
          <Button color="primary" onClick={this.validateAndSubmit}>Add</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

function mapStateToProps(state: any) {
  const user = state.authentication['user'];
  return {
    user,
    planOptions: getUpdatedOptions(state.customizedPlan['list'], 'productName'),
  };
}

const connectedCreateUser = connect(mapStateToProps)(CreateUser);
export { connectedCreateUser as CreateUser };
