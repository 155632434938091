import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { userAction } from '../redux/actions';
import {
  getSelectedValue,
  updateActiveUserStatusMsg,
  updateInActiveUserStatusMsg,
  acceptRateMsg,
  rejectRateMsg,
  rejectUserRequestMsg,
  rejectLicenceMovement,
  deleteExpertMsg,
  tableItemsCount,
  staticConstants,
  userStatusTypes,
  flagTypes,
  pageRoutes,
  acceptLicenceMovementMsg,
  CSS_ClASSES,
  deleteProfessionalMsg,
  deleteTeamAdminMsg,
  deleteLearnerMsg,
  subscriptionTypes,
} from './../../../../utils';
import OpenFieldTypes from 'OpenFieldTypes';
import { LocationDescriptorObject } from 'history';
import queryString from 'query-string';
import { ListingContainer } from '../../../shared/listingManagement/listingContainer';
import { ConfirmatioPopup } from '../../../shared/modals/confirmationPopup';
import { FilterComponent } from '../../../shared/filter/filterComponent';
import { HourlyRate } from '../updateHourlyRate/updateHourlyRate';
import { history } from '../../../../helpers';
import { FlaggedComment } from '../addCommentPopup';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  activeTab?: string;
  filterTab?: string;
  users?: any;
  location: LocationDescriptorObject;
  successForCreateUser?: boolean;
  openCreateUserModal(role, title): void;
}

const orgAdminHeaderContent = {
  organizationName: {
    name: 'Name',
    dataLabel: 'Title',
    class: 'table-col-7',
    sort: true,
    path: `${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.PATH}${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.DETAIL}/${staticConstants.USER_MANAGEMENT_TAB.ORG_ADMIN}`,
  },
  email: {
    name: 'Email',
    dataLabel: 'Email',
    class: 'table-col-8',
    sort: true,
  },
  checkSubscription: {
    name: 'Subscription',
    dataLabel: 'Subscription',
    class: 'table-col-8',
  },
  subscriptionCreatedAt: {
    name: 'Subscribed',
    dataLabel: 'Subscribed',
    class: 'table-col-13',
  },
  totalLearner: {
    name: 'No. of Users',
    dataLabel: 'No. of Users',
    class: 'table-col-8 text-center',
    sort: true,
  },
  licence: {
    name: 'No. of Licences',
    dataLabel: 'No. of Licences',
    class: 'table-col-8 text-center',
    sort: true,
  },
  requestCount: {
    name: 'No. of Requests',
    dataLabel: 'No. of Requests',
    class: 'table-col-8 text-center',
    sort: true,
  },
  licenceMovement: {
    name: 'Assigned Licences',
    dataLabel: 'Assigned Licences',
    class: 'table-col-8',
    sort: true,
  },
  isActive: {
    name: 'Status',
    dataLabel: 'Status',
    class: 'table-col-8',
    sort: true,
  },
  customPlan: {
    name: 'Custom Plan',
    dataLabel: 'Custom Plan',
    class: 'table-col-8',
  },
  isVerified: {
    name: 'Email Verified',
    dataLabel: 'Email Verified',
    class: 'table-col-8',
  },
  emailVerifiedAt: {
    name: 'Verified',
    dataLabel: 'Verified',
    class: 'table-col-10',
  },
  isDelete: { name: 'Action', dataLabel: 'Action', class: 'table-col-6' },
};

const professionalHeaderContent = {
  name: {
    name: 'Name',
    dataLabel: 'Title',
    class: 'table-col-10',
    sort: true,
    path: `${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.PATH}${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.DETAIL}/${staticConstants.USER_MANAGEMENT_TAB.PROFESSIONAL}`,
  },
  email: {
    name: 'Email',
    dataLabel: 'Email',
    class: 'table-col-10',
    sort: true,
  },
  checkSubscription: {
    name: 'Subscription',
    dataLabel: 'Subscription',
    class: 'table-col-10',
  },
  subscriptionCreatedAt: {
    name: 'Subscribed',
    dataLabel: 'Subscribed',
    class: 'table-col-15',
  },
  isActive: {
    name: 'Status',
    dataLabel: 'Status',
    class: 'table-col-10',
    sort: true,
  },
  customPlan: {
    name: 'Custom Plan',
    dataLabel: 'Custom Plan',
    class: 'table-col-15',
  },
  isVerified: {
    name: 'Email Verified',
    dataLabel: 'Email Verified',
    class: 'table-col-10',
  },
  emailVerifiedAt: {
    name: 'Verified',
    dataLabel: 'Verified',
    class: 'table-col-12',
  },
  isDelete: { name: 'Action', dataLabel: 'Action', class: 'table-col-8' },
};

const expertHeaderContentByAll = {
  name: {
    name: 'Name',
    dataLabel: 'Title',
    class: 'table-col-10',
    sort: true,
    path: `${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.PATH}${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.DETAIL}/${staticConstants.USER_MANAGEMENT_TAB.PROFESSIONAL}`,
  },
  email: {
    name: 'Email',
    dataLabel: 'Email',
    class: 'table-col-10',
    sort: true,
  },
  paymentMode: {
    name: 'Payment Mode',
    dataLabel: 'Payment Mode',
    class: 'table-col-15',
    sort: true,
  },
  checkSubscription: {
    name: 'Subscription',
    dataLabel: 'Subscription',
    class: 'table-col-10',
  },
  subscriptionCreatedAt: {
    name: 'Subscribed',
    dataLabel: 'Subscribed',
    class: 'table-col-15',
  },
  isActive: {
    name: 'Status',
    dataLabel: 'Status',
    class: 'table-col-10',
    sort: true,
  },
  isVerified: {
    name: 'Email Verified',
    dataLabel: 'Email Verified',
    class: 'table-col-10',
  },
  emailVerifiedAt: {
    name: 'Verified',
    dataLabel: 'Verified',
    class: 'table-col-12',
  },
  featuredExperts: {
    name: 'Featured Experts',
    dataLabel: 'Featured Experts',
    sort: true,
    class: 'table-col-12',
  },
  isDelete: { name: 'Action', dataLabel: 'Action', class: 'table-col-8' },
};

const expertHeaderContentRequestedRate = {
  name: { name: 'Name', dataLabel: 'Title', class: 'table-col-25', sort: true, path: `${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.PATH}${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.DETAIL}/${staticConstants.USER_MANAGEMENT_TAB.PROFESSIONAL}` },
  email: { name: 'Email', dataLabel: 'Email', class: 'table-col-25', sort: true },
  currentRate: { name: 'Current Hourly Rate', dataLabel: 'Current Hourly Rate', class: 'table-col-20 text-center', sort: true },
  requestedRate: { name: 'Change Hourly Rate', dataLabel: 'Change Hourly Rate', class: 'table-col-20 text-center', sort: true },
  hourlyRateAction: { name: 'Action', dataLabel: 'Action', class: 'table-col-10' },
};

const expertRegisterRequest = {
  name: { name: 'Name', dataLabel: 'Title', class: 'table-col-45', sort: true, path: `${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.PATH}${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.DETAIL}/${staticConstants.USER_MANAGEMENT_TAB.PROFESSIONAL}` },
  email: { name: 'Email', dataLabel: 'Email', class: 'table-col-45', sort: true },
  registerRequestAction: { name: 'Action', dataLabel: 'Action', class: 'table-col-10' },
};

const flagProfilesHeaderContent = {
  name: { name: 'Name', dataLabel: 'Title', class: 'table-col-25', sort: true },
  role: { name: 'Role', dataLabel: 'Flagged By', class: 'table-col-20', sort: true },
  email: { name: 'Email', dataLabel: 'Email', class: 'table-col-20', sort: true },
  totalFlag: { name: 'No of Flags', dataLabel: 'No of Flags', class: 'table-col-15 text-center', sort: true },
  isActive: { name: 'Status', dataLabel: 'Status', class: 'table-col-10', sort: true },
  commentAction: { name: 'Action', dataLabel: 'Action', class: 'table-col-5' },
};

const licenceMovementRequest = {
  name: { name: 'Name', dataLabel: 'Title', class: 'table-col-25', sort: true },
  email: { name: 'Email', dataLabel: 'Email', class: 'table-col-20', sort: true },
  licenceMovementRequestDate: { name: 'Date', dataLabel: 'Date', class: 'table-col-20', sort: true },
  licenceCount: { name: 'Count(s)', dataLabel: 'Count', class: 'table-col-20', sort: true },
  licenceMovementAction: { name: 'Action', dataLabel: 'Action', class: 'table-col-20', sort: true },
};

class UserListingContainer extends React.Component<IProps> {

  /**
   * @description getDerivedStateFromProps is called when there in change in property
   * @param fields {Object} props
   */
  public static getDerivedStateFromProps(props: IProps, state: any) {
    const updateState = {};
    updateState['fetchGenerateReport'] = false;
    if (props.activeTab !== state.activeTab) {
      let tableContent: any;
      if (props.activeTab === staticConstants.USER_MANAGEMENT_TAB.ORG_ADMIN) {
        tableContent = orgAdminHeaderContent;
      }
      if (props.activeTab === staticConstants.USER_MANAGEMENT_TAB.PROFESSIONAL) {
        tableContent = professionalHeaderContent;
      }
      if (props.activeTab === staticConstants.USER_MANAGEMENT_TAB.FLAG_PROFILES) {
        tableContent = flagProfilesHeaderContent;
      }
      if (props.activeTab === staticConstants.USER_MANAGEMENT_TAB.EXPERTS) {
        tableContent = expertHeaderContentByAll;
      }
      updateState['activeTab'] = props.activeTab;
      updateState['tableContent'] = tableContent;
      updateState['fetchUsers'] = true;
      updateState['flagType'] = null;
      updateState['filterTab'] = props.filterTab || staticConstants.USER_MANAGEMENT_EXPERT_FILTER_TAB.ALL;
      updateState['status'] = null;
      updateState['activePage'] = 1;
      updateState['sort'] = '';
      updateState['sortBy'] = 0;
      updateState['title'] = '';
    }
    if (props.users && props.users.list) {
      updateState['users'] = props.users;
    }
    if (props.users && props.users.successForUpdateUserStatus) {
      updateState['displayUpdateUserStatusPopup'] = false;
      props.dispatch(userAction.resetPopUpStatus());
    }
    if (props.users && props.users.successForChangeRate) {
      updateState['displayChangeRequestPopup'] = false;
      props.dispatch(userAction.resetPopUpStatus());
    }
    if (props.users && props.users.successDownloadReport) {
      updateState['downloadUrl'] = props.users.downloadUrl;
      updateState['downloadFileName'] = props.users.downloadFileName;
      updateState['fetchGenerateReport'] = true;
    }
    if (props.users && props.users.successForDeleteUser) {
      updateState['displayDeleteUserPopup'] = false;
      props.dispatch(userAction.resetPopUpStatus());
    }
    if (props.users && props.users.successForUserRequestStatus) {
      updateState['displayUserRequestPopup'] = false;
      updateState['isOpenHourlyRateModal'] = false;
      props.dispatch(userAction.resetPopUpStatus());
    }
    if (props.location && props.location.search) {
      updateState['activePage'] = Number(queryString.parse(props.location.search).page) || 1;
    }
    if (props.users.sendComment) {
      updateState['fetchUsers'] = true;
    }
    return updateState;
  }

  public state = {
    selectedOption: null,
    tooltipOpen: false,
    activePage: 1,
    title: '',
    items: tableItemsCount,
    status: null,
    flagType: null,
    sort: '',
    sortBy: 0,
    users: {
      count: 0,
      list: [],
      fetchedUsersListing: false,
    },
    fetchUsers: false,
    displayUpdateUserStatusPopup: false,
    displayDeleteUserPopup: false,
    displayChangeRequestPopup: false,
    displayUserRequestPopup: false,
    displayLicenceRequestPopup: false,
    displayAddCommentPopup: false,
    isOpenHourlyRateModal: false,
    userId: '',
    userStatus: null,
    rateAction: null,
    requestAction: null,
    reqObj: {},
    modalPopupObj: {},
    activeTab: staticConstants.USER_MANAGEMENT_TAB.ORG_ADMIN,
    filterTab: staticConstants.USER_MANAGEMENT_EXPERT_FILTER_TAB.ALL,
    openFilter: false,
    tableContent: orgAdminHeaderContent,
    downloadUrl: undefined,
    downloadFileName: undefined,
    fetchGenerateReport: false,
    flaggedComment: '',
    subscription: null,
  };

  /**
   * @description create request payload for users list call
   */
  public createUsersRequestPayload = () => {
    const { items, activePage } = this.state;
    const reqObj = {
      page: Number(activePage),
      limit: items,
    };
    return this.updateReqObj(reqObj);
  }

  /**
   * @description componentDidMount is called when component is loaded
   * call userAction.getUsers to fetch goal listings
   * call userAction.getUsers to fetch goal categories
   */
  public componentDidMount() {
    const updateReqObj = this.createUsersRequestPayload();
    this.props.dispatch(userAction.getUsers(updateReqObj));
  }

  /**
   * @description componentDidUpdate is called when we need to fetch listing again.
   * call goalAction.getGoals to fetch goal listings
   */
  public componentDidUpdate(prevProps: IProps) {
    if (this.state.fetchUsers) {
      const reqObj = {
        page: Number(this.state.activePage),
        limit: this.state.items,
      };
      const updateReqObj = this.updateReqObj(reqObj);
      this.props.dispatch(userAction.getUsers(updateReqObj));
      this.setState({ fetchUsers: false });
    }
    if (this.state.fetchGenerateReport) {
      this.downloadReport();
    }

    if (prevProps.successForCreateUser !== this.props.successForCreateUser) {
      this.fetchUsersData(1);
    }
  }

  /**
   * @description updateReqObj to update object before sending to the server
   */
  public updateReqObj(reqObj: any) {
    reqObj.type = this.state.activeTab;
    reqObj.isUserManagment = true;
    reqObj.searchKey = this.state.title;
    reqObj.status = getSelectedValue(this.state.status);
    reqObj.flagType = getSelectedValue(this.state.flagType);
    reqObj.filter = this.state.filterTab;
    reqObj.subscription = getSelectedValue(this.state.subscription);
    return reqObj;
  }

  /**
   * @description fetchUsersData is used on initial load
   */
  public fetchUsersData(pageno: number) {
    const { sort, sortBy } = this.state;
    let reqObj = {
      page: Number(pageno),
      limit: this.state.items,
    };
    if (sort) {
      reqObj = { ...reqObj, ...{ sort, sortBy } };
    }
    const updateReqObj = this.updateReqObj(reqObj);
    this.props.dispatch(userAction.getUsers(updateReqObj));
  }

  /**
   * @description
   * handleChange is used to set the value on state from the input.
   * @param fields {Object} || {String} value
   * @param fields {String} key
   */
  public handleChange = (value: any, key: string) => {
    const { location: { search } } = this.props;
    const query = queryString.parse(search);
    query['page'] = '1';
    history.replace({ search: queryString.stringify(query) });
    this.setState({ ...this.state, [key]: value, activePage: 1 }, () => this.fetchUsersData(1));
  }

  /**
   * @description
   * handlePageChange is called someone click on the pagination.
   * @param fields {Number} pageno
   */
  public handlePageChange = (pageno: number) => {
    const { location: { search } } = this.props;
    this.setState({ activePage: pageno }, () => this.fetchUsersData(pageno));
    const query = queryString.parse(search);
    query['page'] = String(pageno) || '1';
    history.replace({ search: queryString.stringify(query) });
  }

  /**
   * @description
   * sortData is used for sorting purpose.
   * @param fields {Number} sort
   * @param fields {String} sortBy
   */
  public sortData = (sort: string, sortBy: string) => {
    this.setState({ ...this.state, sort, sortBy }, () =>
      this.fetchUsersData(this.state.activePage),
    );
  }

  /**
   * @description
   * updateUserStatusAction is used to update user status
   * @param fields {String} id
   */
  public updateUserStatusAction = () => {
    const reqObj = {
      action: this.state.userStatus,
      userId: this.state.userId,
    };
    this.props.dispatch(userAction.updateUserStatus(reqObj));
  }

  public addDynamicClassListing = (activeTab, filterTab) => {
    let addClass = ''
    if ((activeTab === staticConstants.USER_MANAGEMENT_TAB.ORG_ADMIN && filterTab ===
      staticConstants.USER_MANAGEMENT_ORG_ADMIN_FILTER_TAB.ALL) || activeTab === staticConstants.USER_MANAGEMENT_TAB.PROFESSIONAL) {
      addClass = 'fixed-table-wrapper'
    }
    return addClass
  }

  /**
   * @description
   * deleteUserAction is used to delete user
   * @param fields {String} id
   */
  public deleteUserAction = () => {
    const reqObj = {
      userId: this.state.userId,
    };
    this.props.dispatch(userAction.deleteUser(reqObj));
  }

  /**
   * @description
   * updateHourlyRateAction is used to update hourly rate popup
   * @param fields {String} userId
   * @param fields {String} action
   */
  public updateHourlyRateAction = () => {
    const reqObj = {
      action: this.state.rateAction,
      userId: this.state.userId,
    };
    this.props.dispatch(userAction.updateChangeRequestStatus(reqObj));
  }

  /**
   * @description
   * generateReport is used to get the generate report
   */
  public generateReport = (title: string) => {
    const tableContent = {
      ...this.state.tableContent,
    };
    delete tableContent['isDelete'];
    const columnHeaders = [];
    const columnKeys = [];
    for (const [key, value] of Object.entries(tableContent)) {
      columnHeaders.push(value.name);
      columnKeys.push(key);
    }
    // pushing hidden keys which are required for report only
    const hiddenKeys = {
      professional: {
        occupation: 'Occupation',
        position: 'Position',
        location: 'Location',
      },
      organizationAdmin: {
        occupation: 'Occupation',
        position: 'Position',
        location: 'Location',
      },
      expert: {
        occupation: 'Occupation',
        location: 'Location',
        languages: 'Languages',
        rate: 'Rate',
        tags: 'Tags',
      },
      flaggedProfile: {},
    };
    for (const [key, value] of Object.entries(hiddenKeys[this.state.activeTab])) {
      columnHeaders.push(value);
      columnKeys.push(key);
    }
    this.props.dispatch(userAction.generateReport({
      columnHeaders,
      columnKeys,
      reportTitle: `${title}.csv`,
      type: this.state.activeTab,
      filter: this.state.filterTab,
      isUserManagment: true,
      isGenerateReport: true,
    }));
  }


  public downloadReport = () => {
    this.props.dispatch(userAction.resetGenerateReport());
    const filePath = this.state.downloadUrl;
    const a = document.createElement('a');
    a.setAttribute('href', `${filePath}`);
    a.setAttribute('download', this.state.downloadFileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  /**
   * @description
   * updateStatus is used to update use status
   * @param fields {Object} user
   */
  public updateUserStatus = (user: any) => {
    const { isActive } = user;
    let modalPopupObj = updateActiveUserStatusMsg;
    let status: any;
    if (isActive) {
      status = 'inactive';
      modalPopupObj = updateInActiveUserStatusMsg;
    } else {
      status = 'active';
    }
    this.setState({
      displayUpdateUserStatusPopup: true,
      userId: user._id,
      userStatus: status,
      modalPopupObj,
    });
  }

  /**
   * @description
   * updateUserRequest is used to update user request status
   * @param fields {String} userId
   * @param fields {String} action
   */
  public updateUserRequest = (userId: any, action: any) => {
    if (action === 'reject') {
      this.setState({
        displayUserRequestPopup: true,
        userId,
        requestAction: action,
        modalPopupObj: rejectUserRequestMsg,
      });
    }
    if (action === 'approve') {
      this.setState({
        isOpenHourlyRateModal: true,
        userId,
        requestAction: action,
      });
    }
  }

  /**
   * @description
   * updateLicenceRequest is used to update licence movement request status
   * @param fields {String} userId
   * @param fields {String} action
   */
  public updateLicenceRequest = (userId: any, action: any) => {
    if (action === 'reject') {
      this.setState({
        displayLicenceRequestPopup: true,
        userId,
        requestAction: action,
        modalPopupObj: rejectLicenceMovement,
      });
    }
    if (action === 'approve') {
      this.setState({
        displayLicenceRequestPopup: true,
        userId,
        requestAction: action,
        modalPopupObj: acceptLicenceMovementMsg,
      });
    }
  }

  /**
   * @description
   * validateHourlyRateForm is called from child component
   * @param fields {String} hourlyRate
   */
  public validateHourlyRateForm = (hourlyRate: any) => {
    const reqObj = {
      action: this.state.requestAction,
      userId: this.state.userId,
      hourlyRate: parseFloat(hourlyRate),
    };
    this.props.dispatch(userAction.updateUserRequestStatus(reqObj));
  }

  /**
   * @description
   * updateUserRequestAction is used to update user request
   */
  public updateUserRequestAction = () => {
    const reqObj = {
      action: this.state.requestAction,
      userId: this.state.userId,
    };
    this.props.dispatch(userAction.updateUserRequestStatus(reqObj));
  }

  /**
   * @description
   * reVerifyUser is used to re send mail to un verified user
   * @param fields {string} userId
   */
  public reVerifyUser = (userId: string) => {
    const reqObj = {
      userId,
    };
    this.props.dispatch(userAction.reVerifyUserEmail(reqObj));
  }

  public sendComment = (reqObj: any) => {
    const obj = {
      comment: reqObj.comment,
      userId: this.state.userId,
    };
    this.props.dispatch(userAction.sendComment(obj));
    this.setState({ displayAddCommentPopup: false });
  }

  /**
   * @description
   * updateLicenceMovementAction is used to update licence movement request
   */
  public updateLicenceMovementAction = () => {
    const reqObj = {
      action: this.state.requestAction,
      userId: this.state.userId,
    };
    this.props.dispatch(userAction.updateLicenceMovementStatus(reqObj));
    this.setState({ displayLicenceRequestPopup: false });
  }

  /**
   * @description
   * updateHourlyRate is used to open hourly rate popup
   * @param fields {Object} user
   */
  public updateHourlyRate = (userId: any, action: any) => {
    let modalPopupObj = acceptRateMsg;
    if (action === 'reject') {
      modalPopupObj = rejectRateMsg;
    }
    this.setState({
      displayChangeRequestPopup: true,
      userId,
      rateAction: action,
      modalPopupObj,
    });
  }

  /*
  * @description
  * updateFlaggedComment is used to open add comment popup
  * @param fields {Object} user
  */
  public updateFlaggedComment = (userId: string) => {
    const comment = this.state.users.list.filter(ele => ele['_id'] === userId);
    const modalPopupObj = acceptRateMsg;
    this.setState({
      displayAddCommentPopup: true,
      userId,
      modalPopupObj,
      flaggedComment: comment && comment[0]['flagComment'],
    });
  }

  /**
   * @description
   * deleteItem is used to delete user
   * @param fields {Object} user
   */
  public deleteUser = (user: any) => {
    let popopObject = {};
    switch (user.role) {
      case staticConstants.ROLE.PROFESSIONAL:
        popopObject = deleteProfessionalMsg;
        break;
      case staticConstants.ROLE.EXPERT:
        popopObject = deleteExpertMsg;
        break;
      case staticConstants.ROLE.ORGANIZATION_LEARNER:
        popopObject = deleteLearnerMsg;
        break;
      default:
        popopObject = deleteTeamAdminMsg;
        break;
    }
    this.setState({
      displayDeleteUserPopup: true,
      userId: user._id,
      modalPopupObj: popopObject,
    });
  }

  /**
   * @description
   * hideModal is called when someone click on the cancel button
   */
  public hideModal = () => {
    this.setState({
      displayUpdateUserStatusPopup: false,
      displayDeleteUserPopup: false,
      displayChangeRequestPopup: false,
      displayUserRequestPopup: false,
      isOpenHourlyRateModal: false,
      displayLicenceRequestPopup: false,
      displayAddCommentPopup: false,
    });
  }

  /**
   * @description
   * changeFilterTab is called when some click on the child tab.
   */
  public changeFilterTab(filterTab: string, tableContent: object) {
    this.setState({ filterTab, tableContent, title: '', sort: '' }, () => {
      this.fetchUsersData(1);
    });
  }

  public openCreateUserModal(role: string, title: string) {
    this.props.openCreateUserModal(role, title);
  }

  /**
   * @description
   * render is used to render HTML
   */
  public render() {
    let filterContent = ['search', 'status', 'subscribed'];
    const filterObj = {
      activePage: this.state.activePage,
      sort: this.state.sort,
      sortBy: this.state.sortBy,
    };
    const { tableContent, users, displayUpdateUserStatusPopup, modalPopupObj, activeTab, filterTab,
      displayDeleteUserPopup, displayChangeRequestPopup, isOpenHourlyRateModal, displayUserRequestPopup,
      displayLicenceRequestPopup, displayAddCommentPopup, flaggedComment } = this.state;
    const classVarArr = ['table-head'];
    const classes = classVarArr.join(' ');
    if (activeTab === staticConstants.USER_MANAGEMENT_TAB.FLAG_PROFILES) {
      filterContent = ['search', 'status', 'flagType'];
    }
    if (activeTab === staticConstants.USER_MANAGEMENT_TAB.EXPERTS && filterTab !== staticConstants.USER_MANAGEMENT_EXPERT_FILTER_TAB.ALL) {
      filterContent = ['search'];
    }
    return (
      <React.Fragment>
        <div className="filter-wrapper">
          {activeTab === staticConstants.USER_MANAGEMENT_TAB.FLAG_PROFILES && (
            <FilterComponent
              filterContent={filterContent}
              handleFilter={this.handleChange}
              placeholder="Search by Keywords, Name"
              flagType={flagTypes}
              status={userStatusTypes}
              activeTab={activeTab}
              tabChange={filterTab}
            />
          )}
          {activeTab !== staticConstants.USER_MANAGEMENT_TAB.FLAG_PROFILES && (
            <FilterComponent
              filterContent={filterContent}
              handleFilter={this.handleChange}
              placeholder="Search by Keywords, Name"
              status={userStatusTypes}
              activeTab={activeTab}
              tabChange={filterTab}
              subscriptionType={subscriptionTypes}
            />
          )}
          {activeTab === staticConstants.USER_MANAGEMENT_TAB.ORG_ADMIN && (
            <>
              <span
                className="btn btn-primary round-circle add-filter-btn"
                onClick={() =>
                  this.openCreateUserModal(
                    staticConstants.ROLE.ORGANIZATION_ADMIN,
                    'Add Team',
                  )
                }
              >
                Add Team
              </span>
              <span
                className="btn btn-primary round-circle generate-report-btn"
                onClick={() =>
                  this.generateReport(
                    staticConstants.USER_MANAGEMENT_REPORT.ORG_ADMIN,
                  )
                }
              >
                Generate Report
              </span>
            </>
          )}
          {activeTab === staticConstants.USER_MANAGEMENT_TAB.PROFESSIONAL && (
            <>
              <span
                className="btn btn-primary round-circle add-filter-btn"
                onClick={() =>
                  this.openCreateUserModal(
                    staticConstants.ROLE.PROFESSIONAL,
                    'Add Individual',
                  )
                }
              >
                Add Individual
              </span>
              <span
                className="btn btn-primary round-circle generate-report-btn"
                onClick={() =>
                  this.generateReport(
                    staticConstants.USER_MANAGEMENT_REPORT.PROFESSIONAL,
                  )
                }
              >
                Generate Report
              </span>
            </>
          )}
          {activeTab === staticConstants.USER_MANAGEMENT_TAB.EXPERTS && (
            <>
              {filterTab ===
                staticConstants.USER_MANAGEMENT_EXPERT_FILTER_TAB.ALL && (
                  <>
                    <span
                      className="btn btn-primary round-circle add-filter-btn"
                      onClick={() =>
                        this.openCreateUserModal(
                          staticConstants.ROLE.EXPERT,
                          'Add Expert',
                        )
                      }
                    >
                      Add Expert
                  </span>
                    <span
                      className="btn btn-primary round-circle generate-report-btn"
                      onClick={() =>
                        this.generateReport(
                          staticConstants.USER_MANAGEMENT_TAB.EXPERTS,
                        )
                      }
                    >
                      Generate Report
                  </span>
                  </>
                )}
            </>
          )}
          {activeTab === staticConstants.USER_MANAGEMENT_TAB.FLAG_PROFILES && (
            <>
              <span
                className="btn btn-primary round-circle generate-report-btn"
                onClick={() =>
                  this.generateReport(
                    staticConstants.USER_MANAGEMENT_TAB.FLAG_PROFILES,
                  )
                }
              >
                Generate Report
              </span>
            </>
          )}
        </div>
        {activeTab === staticConstants.USER_MANAGEMENT_TAB.EXPERTS && (
          <div className={`tab-btn-group multi-tab-buttons`}>
            <Button
              color="secondary"
              className={`btn h-50 tab-btn text-uppercase ${filterTab ===
                staticConstants.USER_MANAGEMENT_EXPERT_FILTER_TAB.ALL
                ? CSS_ClASSES.btnActive
                : ''
                }`}
              onClick={() =>
                this.changeFilterTab(
                  staticConstants.USER_MANAGEMENT_EXPERT_FILTER_TAB.ALL,
                  expertHeaderContentByAll,
                )
              }
            >
              All
            </Button>
            <Button
              color="secondary"
              className={`btn h-50 tab-btn text-uppercase ${filterTab ===
                staticConstants.USER_MANAGEMENT_EXPERT_FILTER_TAB.REQUEST
                ? CSS_ClASSES.btnActive
                : ''
                }`}
              onClick={() =>
                this.changeFilterTab(
                  staticConstants.USER_MANAGEMENT_EXPERT_FILTER_TAB.REQUEST,
                  expertRegisterRequest,
                )
              }
            >
              Requests
            </Button>
            <Button
              color="secondary"
              className={`btn h-50 tab-btn text-uppercase  ${filterTab ===
                staticConstants.USER_MANAGEMENT_EXPERT_FILTER_TAB
                  .HOURLY_CHANGE_REQUEST
                ? CSS_ClASSES.btnActive
                : ''
                }`}
              onClick={() =>
                this.changeFilterTab(
                  staticConstants.USER_MANAGEMENT_EXPERT_FILTER_TAB
                    .HOURLY_CHANGE_REQUEST,
                  expertHeaderContentRequestedRate,
                )
              }
            >
              Hourly Rate Change Requests
            </Button>
          </div>
        )}
        {activeTab === staticConstants.USER_MANAGEMENT_TAB.ORG_ADMIN && (
          <div className={`tab-btn-group multi-tab-buttons`}>
            <Button
              color="secondary"
              className={`btn h-50 tab-btn text-uppercase ${filterTab ===
                staticConstants.USER_MANAGEMENT_ORG_ADMIN_FILTER_TAB.ALL
                ? CSS_ClASSES.btnActive
                : ''
                }`}
              onClick={() =>
                this.changeFilterTab(
                  staticConstants.USER_MANAGEMENT_ORG_ADMIN_FILTER_TAB.ALL,
                  orgAdminHeaderContent,
                )
              }
            >
              All
            </Button>
            <Button
              color="secondary"
              className={`btn h-50 tab-btn text-uppercase ${filterTab ===
                staticConstants.USER_MANAGEMENT_ORG_ADMIN_FILTER_TAB
                  .LICENCE_MOVEMENT_REQUESTS
                ? CSS_ClASSES.btnActive
                : ''
                }`}
              onClick={() =>
                this.changeFilterTab(
                  staticConstants.USER_MANAGEMENT_ORG_ADMIN_FILTER_TAB
                    .LICENCE_MOVEMENT_REQUESTS,
                  licenceMovementRequest,
                )
              }
            >
              Licence Movement Requests
            </Button>
          </div>
        )}

        <ListingContainer
          tableClass={this.addDynamicClassListing(activeTab, filterTab)}
          tableContent={tableContent}
          listData={users}
          filterObj={filterObj}
          tableHeaderClasses={classes}
          updateStatus={this.updateUserStatus}
          updateUserRequest={this.updateUserRequest}
          deleteItem={this.deleteUser}
          updateHourlyRate={this.updateHourlyRate}
          updateLicenceMovementRequest={this.updateLicenceRequest}
          handlePageChange={this.handlePageChange}
          handleSorting={this.sortData}
          reVerifyUser={this.reVerifyUser}
          updateFlaggedComment={this.updateFlaggedComment}
          usersRequestPayload={this.createUsersRequestPayload()}
        />
        {displayUpdateUserStatusPopup ? (
          <ConfirmatioPopup
            hideModal={this.hideModal}
            isOpenModal={displayUpdateUserStatusPopup}
            modalPopupObj={modalPopupObj}
            onClickYes={this.updateUserStatusAction}
          />
        ) : (
            ''
          )}
        {displayDeleteUserPopup ? (
          <ConfirmatioPopup
            hideModal={this.hideModal}
            isOpenModal={displayDeleteUserPopup}
            modalPopupObj={modalPopupObj}
            onClickYes={this.deleteUserAction}
          />
        ) : (
            ''
          )}
        {displayChangeRequestPopup ? (
          <ConfirmatioPopup
            hideModal={this.hideModal}
            isOpenModal={displayChangeRequestPopup}
            modalPopupObj={modalPopupObj}
            onClickYes={this.updateHourlyRateAction}
          />
        ) : (
            ''
          )}
        {displayUserRequestPopup ? (
          <ConfirmatioPopup
            hideModal={this.hideModal}
            isOpenModal={displayUserRequestPopup}
            modalPopupObj={modalPopupObj}
            onClickYes={this.updateUserRequestAction}
          />
        ) : (
            ''
          )}
        {displayLicenceRequestPopup ? (
          <ConfirmatioPopup
            hideModal={this.hideModal}
            isOpenModal={displayLicenceRequestPopup}
            modalPopupObj={modalPopupObj}
            onClickYes={this.updateLicenceMovementAction}
          />
        ) : (
            ''
          )}
        {displayAddCommentPopup ? (
          <FlaggedComment
            hideModal={this.hideModal}
            isOpenModal={displayAddCommentPopup}
            addComment={this.sendComment}
            comment={flaggedComment}
          />
        ) : (
            ''
          )}
        <HourlyRate
          modalTitle={'Hourly Rate'}
          isOpenModal={isOpenHourlyRateModal}
          hideModal={() => this.setState({ isOpenHourlyRateModal: false })}
          onClickAccept={this.validateHourlyRateForm}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  const { users } = state;
  const { successForUpdateUserStatus, status: { successForCreateUser } } = users;
  return {
    users,
    successForUpdateUserStatus,
    successForCreateUser,
  };
}

const connectedGoalListingContainer = connect(mapStateToProps)(UserListingContainer);
export { connectedGoalListingContainer as UserListingContainer };
