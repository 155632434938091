import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalBody,
  Button,
  Form,
  FormGroup,
  Input,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import OpenFieldTypes from 'OpenFieldTypes';
import { IValidator } from './../../../interfaces';
import { reeValidators } from '../../../utils';
import ReeValidate from 'ree-validate';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  isOpenModal: boolean;
  modalPopupObj: {};
  onClickYes: any;
  hideModal(): void;

}

interface IState {
  modal: boolean;
  errors?: any;
  formData: {
    reasonForReject: string; };
}

class RejectReasonPopup extends React.Component<IProps, IState> {
  public validator: IValidator;
  constructor(props: IProps) {
    super(props);
    this.validator = new ReeValidate({
      reasonForReject: reeValidators.required_min_3_max_100,
    });
    this.state = {
      modal: props.isOpenModal,
      formData: { reasonForReject: '' },
      errors: this.validator.errors,
    };
  }

  public hideModal = () => {
    this.setState({
      modal: false,
    });
    this.props.hideModal();
  }

  public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const { errors } = this.validator;
    this.setState({ formData: { ...this.state.formData, [name]: value } });
    errors.remove(name);
    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors });
      });
  }

  public validateAndSubmit = (e) => {
    e.preventDefault();
    const { reasonForReject } = this.state.formData;
    const { errors } = this.validator;
    this.validator.validateAll({ reasonForReject })
      .then((success: boolean) => {
        if (success) {
          this.handleSubmit();
        } else {
          this.setState({ errors });
        }
      });
  }

  public handleSubmit() {
  const obj = this.props.modalPopupObj;
  obj['message'] = this.state.formData.reasonForReject;
  this.props.onClickYes(obj);
  // TODO: handleSubmit
}

  public render() {
    const { modal, formData, errors } = this.state;
    return (
      <Modal isOpen={modal} className="organization-code-modal">
        <ModalHeader toggle={this.hideModal}>Reason Of Reject</ModalHeader>
        <ModalBody>
                <Form onSubmit={this.validateAndSubmit}>
                    <FormGroup
                      className={`floating-label ${
                        errors.has('reasonForReject') ? ' has-error' : ''
                        }`}>
                      <Input
                        type="text"
                        className="form-control"
                        name="reasonForReject"
                        id="reasonForReject"
                        value={formData.reasonForReject}
                        onChange={this.handleChange}
                        placeholder="i'm not interested"
                      />
                      {errors.has('reasonForReject') &&
                        <div className="error-text">{errors.first('reasonForReject').replace('reasonForReject', 'Reason for Reject')}</div>
                      }
                    </FormGroup>
                  </Form>
          </ModalBody>
            <ModalFooter>
                <Button className="btn btn-regular ml-0" onClick={this.hideModal}>Cancel</Button>{' '}
                <Button color="primary" className="mr-2" onClick={this.validateAndSubmit}>Submit</Button>
            </ModalFooter>

      </Modal>
    );
  }
}

const connectedRejectReasonPopup = connect()(RejectReasonPopup);
export { connectedRejectReasonPopup as RejectReasonPopup };
