import { GET_NOTIFICATION_LIST_QUERY } from '../graphql-client/queries/notificationQuery';
import { UPDATE_NOTIFICATION_STATUS_MUTATION } from '../graphql-client/mutations/notificationMutations';
import { client } from '../index';
import { handleResponse } from '../utils/common';

export const notificationServices = {
  getNotification,
  markAsRead,
};

async function getNotification(reqObj: any) {
  const response = await client.query({
    query: GET_NOTIFICATION_LIST_QUERY,
    variables: reqObj,
  });
  const resp = await handleResponse(response);
  return resp.getNotifications;
}

async function markAsRead(notification: any) {
  const response = await client.mutate({
    mutation: UPDATE_NOTIFICATION_STATUS_MUTATION,
    variables: notification,
  });
  const resp = await handleResponse(response);
  resp.readNotification['notificationId'] = notification.notificationId;
  return resp.readNotification;
}
