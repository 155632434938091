import { GET_LOGS_LIST_QUERY } from '../graphql-client/queries/logsQuery';
import { client } from '../index';
import { handleResponse } from '../utils/common';

export const logServices = {
  getLogs,
};

async function getLogs(reqObj: any) {
  const response = await client.query({
      query: GET_LOGS_LIST_QUERY,
      variables: reqObj,
    });
  const resp = await handleResponse(response);
  return resp.getLogs;
}
