import gql from 'graphql-tag';

const UPDATE_CONTENT_MUTATION = gql`
  mutation updateContent($type: String!, $faqId: String, $content: String, $question: String, $answer: String, $faqType: FAQTYPES) {
    updateContent( type: $type, faqId: $faqId, content: $content, question: $question, answer: $answer, faqType: $faqType) {
        message
      }
}
`;

const DELETE_FAQ_MUTATION = gql`
  mutation deleteFaq($faqId: String!) {
    deleteFaq( faqId: $faqId ) {
        message
      }
}
`;

export {
    UPDATE_CONTENT_MUTATION,
    DELETE_FAQ_MUTATION,
};
