import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { FeaturedExpertCard } from '../../home/FeaturedExpertCard';

interface IProps {
  isOpenModal: boolean;
  hideModal(): void;
  user?: any;
}

interface IState {
  modal: boolean;
}

class FeaturedExpertPopup extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      modal: props.isOpenModal,
    };
    this.hideModal = this.hideModal.bind(this);
  }

  public hideModal() {
    this.setState({
      modal: false,
    });
    this.props.hideModal();
  }

  public render() {
    const { modal } = this.state;
    const { user } = this.props;
    return (
      <Modal isOpen={modal} className="make-bid-modal" onClosed={this.hideModal}>
      <ModalHeader>Expert Details <a onClick={this.hideModal}><span>&#x2715;</span></a></ModalHeader>
      <ModalBody className="home-featured-carousel p-0">
      <FeaturedExpertCard user={user} modal={false}/>
      </ModalBody>
    </Modal>
    );
  }
}

const featuredExpertPopupPage = connect()(FeaturedExpertPopup);
export { featuredExpertPopupPage as FeaturedExpertPopup };
