import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import OpenFieldTypes from 'OpenFieldTypes';
import { IValidator } from './../../../interfaces';
import { reeValidators, staticConstants } from '../../../utils';
import ReeValidate from 'ree-validate';
import { AdminRatingPopUp } from './adminRatingPopup';
import { ExpertRatingPopUp } from './expertRatingPopup';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  isOpenModal: boolean;
  modalPopupObj: {};
  onClickYes: any;
  hideModal(): void;
}

interface IState {
  modal: boolean;
  errors?: any;
  formData: {
    comment: string;
  };
  sessionRating: number;
  expertRating: number;
  overAllQuality: number;
  participantsEngagement: number;
}

class SessionRatingPopUp extends React.Component<IProps, IState> {
  public validator: IValidator;
  constructor(props: IProps) {
    super(props);
    this.validator = new ReeValidate({
      comment: (this.props.modalPopupObj['type'] !== 'expertRating') && reeValidators.required_min_3_max_500,
    });

    this.state = {
      modal: props.isOpenModal,
      formData: { comment: this.props.modalPopupObj['comment'] },
      errors: this.validator.errors,
      sessionRating: this.props.modalPopupObj['sessionRating'],
      expertRating: this.props.modalPopupObj['expertRating'],
      overAllQuality: this.props.modalPopupObj['overAllQuality'],
      participantsEngagement: this.props.modalPopupObj['participantsEngagement'],
    };
  }

  public hideModal = () => {
    this.setState({
      modal: false,
    });
    this.props.hideModal();
  }

  public handleSubmit = (stateData: any) => {
    const obj = this.props.modalPopupObj;
    if (obj['userRole'] === staticConstants.ROLE.EXPERT) {
      obj['comment'] = stateData.formData.comment;
      obj['overAllQuality'] = stateData.overAllQuality;
      obj['participantsEngagement'] = stateData.participantsEngagement;
    } else {
      obj['comment'] = stateData.formData.comment;
      obj['sessionRating'] = stateData.sessionRating;
      obj['expertRating'] = stateData.expertRating;
    }
    this.props.onClickYes(obj);
  }

  public render() {
    const { modal } = this.state;
    const modalData = this.props.modalPopupObj;
    return (
            <>
                {
                    (modalData['userRole'] === staticConstants.ROLE.EXPERT)
                        ? <ExpertRatingPopUp isOpenModal={modal} modalPopupObj={modalData} hideModal={this.hideModal} handleSubmit={this.handleSubmit} />
                        : <AdminRatingPopUp isOpenModal={modal} modalPopupObj={modalData} hideModal={this.hideModal} handleSubmit={this.handleSubmit} />
                }
            </>
    );
  }
}

const connectedSessionRatingPopUp = connect()(SessionRatingPopUp);
export { connectedSessionRatingPopUp as SessionRatingPopUp };
