import { actionTypes } from './types';
interface IAction {
  type: string;
  error: string;
  data: {};
}
export function selectTags(state = {}, action: IAction) {
  switch (action.type) {
    case actionTypes.TAG_LIST_REQUEST:
      return {
        successForCreateLearner: true,
      };
    case actionTypes.TAG_LIST_SUCCESS:
      return {
          ...state,
          tags: action.data,
          tagsRequestSuccess: true,
        };
    case actionTypes.TAG_LIST_FAILURE:
      return {
        ...state,
        tagsRequestSuccess: false,
      };
    default:
      return state;
  }
}
