export class InitInboxUser {
  public _id = '';
  public connectionId = '';
  public email = '';
  public firstName = '';
  public isSystemAdmin = false;
  public lastName = '';
  public organizationId = '';
  public profileImage = '';
  public role = '';
  public roomId = '';
  public target = '';
  public targetName = '';
  public userId = '';
  public unreadCount = 0;
  public members = [];
}
