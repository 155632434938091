import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import ReeValidate from 'ree-validate';
import OpenFieldTypes from 'OpenFieldTypes';
import { IValidator } from './../../../interfaces';
import { MAGIC_NUMBER } from '../../../utils';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  isOpenModal: boolean;
  hideModal: any;
  addComment: any;
  comment: string;
}

interface IState {
  submitted: boolean;
  comment: string;
  errors?: any;
  modal: boolean;
}

class FlaggedComment extends React.Component<IProps, IState> {

  public static getDerivedStateFromProps(props: IProps, state: any) {
    const updateState = {};
    updateState['modal'] = props.isOpenModal;
    if (props.comment && !state.submitted) {
      updateState['comment'] = props.comment;
    }
    return updateState;
  }

  public validator: IValidator;
  constructor(props: IProps) {
    super(props);

    // reset login status
    this.validator = new ReeValidate({
      comment: 'required',
    });

    this.state = {
      comment: '',
      submitted: false,
      errors: this.validator.errors,
      modal: this.props.isOpenModal,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.validateAndSubmit = this.validateAndSubmit.bind(this);
  }

  public handleChange = (e: any) => {
    const { name, value } = e.target;
    const { errors } = this.validator;
    this.setState({
      submitted: true,
      [name]: value,
    } as any);
    errors.remove(name);
    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors });
      });
  }

  public validateAndSubmit(e: any) {
    e.preventDefault();
    const { comment } = this.state;
    const { errors } = this.validator;
    this.validator.validateAll({ comment })
    .then((success: any) => {
      if (success) {
        this.handleSubmit();
      } else {
        this.setState({ errors });
      }
    });
  }

  public handleSubmit() {
    const { comment } = this.state;
    let obj;
    if (comment) {
      obj = {
        comment,
      };
      this.props.addComment(obj);
      this.setState({ submitted: false });
    }
  }

  public hideModal() {
    this.setState({
      modal: false,
      comment: '',
    });
    const { errors } = this.validator;
    errors.clear();
    this.props.hideModal();
  }

  public render() {
    const { modal, comment, errors } = this.state;
    return (
      <Modal isOpen={modal} className="make-bid-modal" onClosed={this.hideModal}>
        <ModalHeader toggle={this.hideModal}>{this.props.comment && this.props.comment.length ? 'Edit Comment' : 'Add Comment'}</ModalHeader>
        <ModalBody>
          <Form onSubmit={this.validateAndSubmit} autoComplete="off" noValidate>
            <FormGroup
                className={`floating-label disabled-input textarea-label answer-textarea mb-4 ${
                  errors.has('comment') ? ' has-error' : ''
                  }`}>
                <textarea
                    className="form-control textarea-lg"
                    id="comment"
                    value={comment}
                    onChange={this.handleChange}
                    name="comment"
                    placeholder="Comment"
                    maxLength={MAGIC_NUMBER.THOUSANT}
                />
                <Label for="comment" className={comment ? 'selected' : ''}>Write Comment</Label>
                <span className="text-count">{(comment && comment.length) || 0}/1000</span>
                {errors.has('comment') &&
                <div className="error-text">{errors.first('comment')}</div>
                }
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-regular ml-0" onClick={this.hideModal}>Cancel</Button>{' '}
          <Button color="primary" className="mr-2" onClick={this.validateAndSubmit}>{this.props.comment && this.props.comment.length ? 'Edit' : 'Add'}</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

function mapStateToProps(state: any) {
  return {
  };
}

const connectedFlaggedCommentPage = connect(mapStateToProps)(FlaggedComment);
export { connectedFlaggedCommentPage as FlaggedComment };
