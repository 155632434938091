import React from 'react';
import { MAGIC_NUMBER } from '../../../utils';

interface IProps {
  amount: number;
  licences: number;
  taxRate: number;
  sessionFee?: boolean;
}

function CheckOut(props: IProps) {
  const { amount, taxRate = 0 } = props;
  const salesTax = taxRate > MAGIC_NUMBER.ZERO ? (amount * taxRate / MAGIC_NUMBER.HUNDRED) : MAGIC_NUMBER.ZERO;
  const totalAmount = amount + salesTax;
  return (
    <div className="price-summary-box">
      <h1 style={{ fontSize: '16px', marginBottom: '20px', color: '#2e3543' }} className= "roboto-medium" >Price Summary</h1>
      <p> Amount: <span>${amount}</span> </p>
      <p> Sales Tax ({`${taxRate}%`}): <span>${salesTax.toFixed(MAGIC_NUMBER.TWO)}</span> </p>
      <p className="total-amount  pt-2"> Total: <span>${totalAmount.toFixed(MAGIC_NUMBER.TWO)}</span> </p>
    </div>
  );
}

export default CheckOut;
