export const actionTypes = {
  GET_PROFILE_REQUEST: 'GET_PROFILE_REQUEST',
  GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
  GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',

  UPDATE_PROFILE_REQUEST: 'UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE: 'UPDATE_PROFILE_FAILURE',

  UPDATE_PROFILE_IMAGE_REQUEST: 'UPDATE_PROFILE_IMAGE_REQUEST',
  UPDATE_PROFILE_IMAGE_SUCCESS: 'UPDATE_PROFILE_IMAGE_SUCCESS',
  UPDATE_PROFILE_IMAGE_FAILURE: 'UPDATE_PROFILE_IMAGE_FAILURE',

  FETCH_INDUSTRY_SUGGESTIONS_REQUEST: 'FETCH_INDUSTRY_SUGGESTIONS_REQUEST',
  FETCH_INDUSTRY_SUGGESTIONS_SUCCESS: 'FETCH_INDUSTRY_SUGGESTIONS_SUCCESS',
  FETCH_INDUSTRY_SUGGESTIONS_FAILURE: 'FETCH_INDUSTRY_SUGGESTIONS_FAILURE',

  FETCH_SECTOR_SUGGESTIONS_REQUEST: 'FETCH_INDUSTRY_SECTOR_REQUEST',
  FETCH_SECTOR_SUGGESTIONS_SUCCESS: 'FETCH_INDUSTRY_SECTOR_SUCCESS',
  FETCH_SECTOR_SUGGESTIONS_FAILURE: 'FETCH_INDUSTRY_SECTOR_FAILURE',

  GET_PAYMENT_LOGIN_LINK_REQUEST: 'GET_PAYMENT_LOGIN_LINK_REQUEST',
  GET_PAYMENT_LOGIN_LINK_SUCCESS: 'GET_PAYMENT_LOGIN_LINK_SUCCESS',
  GET_PAYMENT_LOGIN_LINK_FAILURE: 'GET_PAYMENT_LOGIN_LINK_FAILURE',

  RESET_PAYMENT_LOGIN_LINK: 'RESET_PAYMENT_LOGIN_LINK',

  UPDATE_PAYMENT_ACCOUNT_ID_REQUEST: 'UPDATE_PAYMENT_ACCOUNT_ID_REQUEST',
  UPDATE_PAYMENT_ACCOUNT_ID_SUCCESS: 'UPDATE_PAYMENT_ACCOUNT_ID_SUCCESS',
  UPDATE_PAYMENT_ACCOUNT_ID_FAILURE: 'UPDATE_PAYMENT_ACCOUNT_ID_FAILURE',

  GET_SUBSCRIPTION_SUCCESS: 'GET_SUBSCRIPTION_SUCCESS',
  GET_SUBSCRIPTION_FAILURE: 'GET_SUBSCRIPTION_FAILURE',

  GET_SUBSCRIPTION_HISTORY_SUCCESS: 'GET_SUBSCRIPTION_HISTORY_SUCCESS',
  GET_SUBSCRIPTION_HISTORY_FAILURE: 'GET_SUBSCRIPTION_HISTORY_FAILURE',

  CANCEL_SUBSCRIPTION_SUCCESS: 'CANCEL_SUBSCRIPTION_SUCCESS',
  CANCEL_SUBSCRIPTION_FAILURE: 'CANCEL_SUBSCRIPTION_FAILURE',

  UPDATE_SETTING_SUCCESS: 'UPDATE_SETTING_SUCCESS',
  UPDATE_SETTING_FAILURE: 'UPDATE_SETTING_FAILURE',

  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

  SHOW_FEEDBACK_FORM: 'SHOW_FEEDBACK_FORM',
  PAYMENTS_LIST_REQUEST: 'PAYMENTS_LIST_REQUEST',
  PAYMENTS_LIST_SUCCESS: 'PAYMENTS_LIST_SUCCESS',
  PAYMENTS_LIST_FAILURE: 'PAYMENTS_LIST_FAILURE',

  CHANGE_EMAIL_REQUEST: 'CHANGE_EMAIL_REQUEST',
  CHANGE_EMAIL_SUCCESS: 'CHANGE_EMAIL_SUCCESS',
  CHANGE_EMAIL_FAILURE: 'CHANGE_EMAIL_FAILURE',

  VERIFY_CHANGE_EMAIL_TOKEN_REQUEST: 'VERIFY_CHANGE_EMAIL_TOKEN_REQUEST',
  VERIFY_CHANGE_EMAIL_TOKEN_SUCCESS: 'VERIFY_CHANGE_EMAIL_TOKEN_SUCCESS',
  VERIFY_CHANGE_EMAIL_TOKEN_FAILURE: 'VERIFY_CHANGE_EMAIL_TOKEN_FAILURE',
};
