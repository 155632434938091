import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Modal,
  ModalBody,
} from 'reactstrap';
import OpenFieldTypes from 'OpenFieldTypes';

interface IProps {
  dispatch?: Dispatch<OpenFieldTypes.RootAction>;
  isOpenModal: boolean;
  modalPopupObj?: any;
  hideModal(): void;
}

interface IState {
  modal: boolean;
}

class ReqSendErrorPopup extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      modal: props.isOpenModal,
    };
    this.hideModal = this.hideModal.bind(this);
  }

  public hideModal() {
    this.setState({
      modal: false,
    });
    this.props.hideModal();
  }

  public render() {
    const { modal } = this.state;
    return (
      <Modal isOpen={modal} className="add-on-modal">
        <span className="close-btn icon icon-cross" onClick={this.hideModal} />
        <ModalBody>
          <h2>Error</h2>
          <p>Your request has exceeded the 90-day limit. Events can only be scheduled within 90 days of a sent request.</p>
        </ModalBody>
          <div className="button-group">
              <Button color="delete" onClick={this.hideModal}>Close</Button>
          </div>
      </Modal>
    );
  }
}

const connectedReqSendErrorPopupPage = connect()(ReqSendErrorPopup);
export { connectedReqSendErrorPopupPage as ReqSendErrorPopup };
