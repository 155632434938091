import React from 'react';
import ErrorImage from './../../assets/images/Error-404.png';

class NotFoundPage extends React.Component {
  public state = {
    selectedOption: null,
    tooltipOpen: false,
  };
  public handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  }
  public toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  }
  public render() {
    return (
      <div className="error-page">
        <img src={ErrorImage} alt="404 Error" />
      </div>
    );
  }
}
export { NotFoundPage };
