import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';

interface IProps {
  handleDateFilter(minDate: any, key: string): void;
}

function DateFilter({ handleDateFilter }) {
  const [maxDate, setMaxDate] = useState(null);
  const [minDate, setMinDate] = useState(null);

  useEffect(() => {
    minDate && maxDate && handleDateFilter(minDate, maxDate);
  }, [minDate, maxDate]);

  const resetFilter = () => {
    setMaxDate(null);
    setMinDate(null);
    handleDateFilter(null, null);
  }

  return (
    <div className="filter-date-picker-column">
      <DatePicker
        placeholderText="From Date"
        className="form-control"
        selected={minDate}
        onChange={(date) => {
          setMaxDate(null);
          setMinDate(date);
        }
        }
        dateFormat="MMM dd, yyyy"
        maxDate={moment().toDate()}
        showYearDropdown
        showMonthDropdown
        strictParsing
      />
      <DatePicker
        placeholderText="To Date"
        className="form-control"
        dateFormat="MMM dd, yyyy"
        onChange={(date) => {
          setMaxDate(date);
        }}
        selected={maxDate}
        maxDate={moment().toDate()}
        minDate={minDate}
        showYearDropdown
        showMonthDropdown
        strictParsing />
     <span className="btn btn-blank" onClick={() => resetFilter()}>Clear</span>
    </div>
  );
}

export default DateFilter;
