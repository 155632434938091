export const actionTypes = {
  SHORT_PROFILE_SUCCESS: 'SHORT_PROFILE_SUCCESS',
  SHORT_PROFILE_FAILURE: 'SHORT_PROFILE_REQUEST',

  BID_DETAIL_SUCCESS: 'BID_DETAIL_SUCCESS',
  BID_DETAIL_FAILURE: 'BID_DETAIL_FAILURE',

  UPDATE_SETTING_SUCCESS: 'UPDATE_SETTING_SUCCESS',
  UPDATE_SETTING_FAILURE: 'UPDATE_SETTING_FAILURE',
};
