import React from 'react';
import OpenFieldTypes from 'OpenFieldTypes';
import { Sidebar } from '../sidebar';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { IViewProfile, IParams, ILocalUser } from './../../../interfaces';
import { viewProfileAction } from './redux/action';
import { ViewOrgAdminProfile } from './../../shared/updateProfile/ViewOrgAdminProfile';
import { staticConstants, getMyUserId, pageRoutes } from './../../../utils';
import { history } from '../../../helpers';
import { FlagUserPopUp } from '../modals/flagUserPopup';
import { eventAction } from '../../shared/eventManagement/redux/actions';
import { Back } from '../back/back';

interface IProps {
  dispatch: OpenFieldTypes.RootAction;
  profile: IViewProfile;
  location: any;
  user: ILocalUser;
  match: {
    params: {
      [key: string]: IParams;
    };
  };
}

interface IState {
  modal: boolean;
  displayFlagUserModal: boolean;
  modalPopupObj: object;
}

class ViewOrgAdminCompleteProfile extends React.Component<IProps, IState>  {

  constructor(props: IProps) {
    super(props);
    this.state = {
      modal: false,
      displayFlagUserModal: false,
      modalPopupObj: {},
    };
  }
  /**
   * @description componentDidMount is called when component is loaded
   * call goalAction.getrfpDetail to fetch goal detail
   */
  public componentDidMount() {
    const { match: { params } } = this.props;
    const { isScheduleManagement } = this.props['location'].state;
    if (params.id) {
      this.props.dispatch(viewProfileAction.getShortProfile(String(params.id), isScheduleManagement));
    }
  }

  /**
   * @description Function to show flag user popup
   * @param {string} userId conatin unique id
   */
  public showFlagUserModal = (userId: string) => {
    const obj = {
      userId,
    };
    this.setState({
      displayFlagUserModal: true,
      modalPopupObj: obj,
    });
  }

  /**
   * @description Function to hide flag user popup
   */
  public hideFlagUserModal = () => {
    this.setState({
      displayFlagUserModal: false,
      modalPopupObj: null,
    });
  }

  /**
   * @description Function to handle flag user submit reuest
   */
  public handleFlagUserSubmit = (obj: object) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const { goalId, isExpert } = this.props['location'].state;
    obj['goalId'] = goalId;
    obj['flaggedBy'] = user['_id'];
    this.props.dispatch(eventAction.flagUser(obj));
    this.hideFlagUserModal();
    if (isExpert) {
      this.props['location']['state']['isParticipant']['isFlagged'] = true;
    }
  }

  public render() {
    const { profile, match: { params }, user: { role } } = this.props;
    const { isExpert, userRole, isBidManagement, isParticipant, isSystemAdminRole } = this.props['location'].state;
    const { displayFlagUserModal, modalPopupObj } = this.state;
    return (
      <React.Fragment>
        <Sidebar {...this.props} />
        <div className="dashboard-wrapper">
          <Back {...this.props} />
          {profile &&
            <div className="dashboard-content shadow mb-0">
              <div className="tab-panel-wrapper">
                <ViewOrgAdminProfile {...this.props} viewProfile={profile} userRole={userRole} />
              </div>
              {!isSystemAdminRole && !isBidManagement && params.id !== getMyUserId() &&       // id matched to hide message button
                <div className="card-footer d-flex align-items-center justify-content-end">
                  {isExpert &&
                    <Button
                      className={`btn btn-icon mr-auto ${isParticipant['isFlagged'] ? 'heading-disabled' : ''}`}
                      onClick={() => this.showFlagUserModal(isParticipant['userId'])}
                    >
                      <span className="icon icon-flag" />
                      Flag User
                                    </Button>
                  }
                  {role !== staticConstants.ROLE.SYSTEM_ADMIN &&
                    <Button
                      className="btn btn-icon"
                      onClick={() => history.push({
                        pathname: pageRoutes.MESSAGE_BOARD.PATH,
                        state: { userId: params.id },
                      })}
                    >
                      <span className="icon icon-message" />
                      Message
                                </Button>
                  }
                </div>
              }
            </div>
          }
        </div>
        {displayFlagUserModal &&
          <FlagUserPopUp
            isOpenModal={displayFlagUserModal}
            modalPopupObj={modalPopupObj}
            hideModal={this.hideFlagUserModal}
            onClickYes={this.handleFlagUserSubmit}
          />
        }
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  const { viewProfile, authentication: { user } } = state;
  return {
    profile: viewProfile['profile'],
    user,
  };
}

const viewOrgAdminCompleteProfileConnected = connect(mapStateToProps)(ViewOrgAdminCompleteProfile);
export { viewOrgAdminCompleteProfileConnected as ViewOrgAdminCompleteProfile };
