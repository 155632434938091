import { goalServices } from './../../../../services';
import { alertActions } from './../../../alert/redux/actions';
import { endLoading, startLoading } from './../../../loader/redux/actions';
import { actionTypes } from './types';
import OpenFieldTypes from 'OpenFieldTypes';
import { handleError, EVENT_PAYMENT_TYPES, extendEventStatus, MAGIC_NUMBER } from '../../../../utils';
import { history } from '../../../../helpers';
export const eventAction = {
  getRequests,
  getRequestDetails,
  updateRequestStatus,
  getEventManagementDetails,
  updateExtentEventStatus,
  flagUser,
  validateAttendance,
  ratingGoal,
  endMeeting,
  startMeeting,
  extendMeeting,
  meetingExtended,
  updateEventParticipants,
  getExtendSessionFee,
  changeEventParticipants,
  cancelEvent,
  downloadSession,
  displayError,
  recordingCompleted,
  resolveEventConflict,
};

function getRequests(event: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request(event));
    goalServices.getScheduledGoals(event)
      .then(
        (requests) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(requests));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };

  function request(data: string) { return { type: actionTypes.SCHEDULE_LIST_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.SCHEDULE_LIST_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.SCHEDULE_LIST_FAILURE, error }; }
}

function getRequestDetails(goal: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    goalServices.getGoalDetails(goal)
      .then(
        (goals) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(goals));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };
  function success(data: any) { return { type: actionTypes.SCHEDULE_LIST_REQUEST, data }; }
  function failure(error: any) { return { type: actionTypes.SCHEDULE_LIST_REQUEST, error }; }
}

function updateExtentEventStatus(event: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(''));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    goalServices.updateExtentEventStatus(event)
      .then(
        (data) => {
          if (event.status === extendEventStatus.PAID || event === extendEventStatus.CANCEL) {
            setTimeout(() => window.location.reload(), MAGIC_NUMBER.CHAR_LENGTH);
          }
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(data));
          data.message && dispatch(alertActions.successAlert(data.message));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };
  function request(data: string) { return { type: actionTypes.UPDATE_EXTENT_EVENT_STATUS_REQUEST, data }; }
  function success(data: string) { return { type: actionTypes.UPDATE_EXTENT_EVENT_STATUS_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.UPDATE_EXTENT_EVENT_STATUS_FAILURE, error }; }
}

function updateRequestStatus(event: object, reqObj: object) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(reqObj));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    goalServices.updateGoalStatus(event)
      .then(
        (goals) => {

          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(goals));
          dispatch(getRequests(reqObj));
        },
        (error) => {
          handleError(dispatch, failure, error);
        },
      );
  };
  function request(data: any) { return { type: actionTypes.UPDATE_SCHEDULE_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.UPDATE_SCHEDULE_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.UPDATE_SCHEDULE_FAILED, error }; }
}

/**
 * @description Function to get particular event management detail
 * @param {object} eventData conatin values in object
 */
function getEventManagementDetails(eventData: object) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    goalServices.getEventDetails(eventData)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(res || {}));
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };
  function success(data: any) { return { type: actionTypes.EVENT_DETAIL_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.EVENT_DETAIL_FAILURE, error }; }
}

/**
 * @description Function to flag user
 * @param {object} goalData conatin values in object
 */
function flagUser(goalData: object) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    goalServices.flagUser(goalData)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(goalData));
          dispatch(alertActions.successAlert(res.message));
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };
  function success(data: any) { return { type: actionTypes.FLAG_USER_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.FLAG_USER_FAILURE, error }; }
}

/**
 * @description Function to validate attendance
 * @param {object} data conatin values in object
 */
function validateAttendance(data: object) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    goalServices.validateAttendance(data)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(data));
          dispatch(alertActions.successAlert(res['message']));
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };
  function success(datas: object) { return { type: actionTypes.VALIDATE_ATTENDANCE_SUCCESS, data: datas }; }
  function failure(error: object) { return { type: actionTypes.VALIDATE_ATTENDANCE_FAILURE, error }; }
}

/**
 * @description Function to rating session
 * @param {object} data conatin values in object
 */
function ratingGoal(ratingData: object) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    goalServices.ratingGoal(ratingData)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(ratingData));
          dispatch(alertActions.successAlert(res['message']));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };
  function success(data: object) { return { type: actionTypes.RATING_GOAL_SUCCESS, data }; }
  function failure(error: object) { return { type: actionTypes.RATING_GOAL_FAILURE, error }; }
}

function endMeeting(data: object) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch({ type: actionTypes.END_MEETING_SUCCESS, data });
  };
}

function startMeeting(data: object) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch({ type: actionTypes.START_MEETING_SUCCESS, data });
  };
}

function extendMeeting(data: object) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch({ data, type: actionTypes.EXTEND_MEETING_SUCCESS });
  };
}

function meetingExtended(data: object) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    const timeInterval = MAGIC_NUMBER.THREE_THOUSANT;
    data['message'] && dispatch(alertActions.successAlert(data['message']));
    setTimeout(() => { window.location.reload(); }, timeInterval);
  };
}

function updateEventParticipants(goal: { goalId: string; participants: Array<{ isAdmin: boolean; userId: string }>; isMe: boolean }) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    goalServices.updateGoalParticipants(goal)
      .then(
        (data) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(goal));
          dispatch(alertActions.successAlert(data.message));
        },
        (error) => {
          handleError(dispatch, failure, error);
        },
      );
  };
  function success(data: { goalId: string; participants: Array<{ isAdmin: boolean; userId: string }>; isMe: boolean }) {
    return { type: actionTypes.UPDATE_EVENT_PARTICIPANTS_SUCCESS, data };
  }
  function failure(error: string) { return { type: actionTypes.UPDATE_EVENT_PARTICIPANTS_FAILURE, error }; }
}

function getExtendSessionFee(bidId: string, duration: number, extendEventId: string = null) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request(null));
    goalServices.getSessionFee(bidId, EVENT_PAYMENT_TYPES.EXTEND_EVENT_FEE, duration)
      .then(
        (data) => {
          if (extendEventId) {
            history.push({
              pathname: `/payment/checkout/`,
              state: {
                amount: data.data.amount,
                commission: data.data.commission,
                action: 'extendEventFee',
                bidId,
                extendEventId,
                redirectUri: history.location.pathname,
              },
            });
          } else {
            dispatch(endLoading('MAIN_LOADER'));
            dispatch(success(data));
          }
        },
        (error) => {
          handleError(dispatch, failure, error.message);
        },
      );
  };
  function request(data: any) { return { type: actionTypes.GET_EXTEND_SESSION_FEE_REQUEST, data }; }
  function success(data: any) { return { type: actionTypes.GET_EXTEND_SESSION_FEE_SUCCESS, data }; }
  function failure(error: any) { return { type: actionTypes.GET_EXTEND_SESSION_FEE_FAILURE, error }; }
}

function changeEventParticipants(goal: { goalId: string; participants: Array<{ isAdmin: boolean; userId: string }> }, getDetailObj) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    goalServices.changeGoalParticipants(goal)
      .then(
        (data) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(goal));
          dispatch(getEventManagementDetails(getDetailObj));
          dispatch(alertActions.successAlert(data.message));
        },
        (error) => {
          handleError(dispatch, failure, error);
        },
      );
  };
  function success(data: { goalId: string; participants: Array<{ isAdmin: boolean; userId: string }> }) {
    return { type: actionTypes.CHANGE_EVENT_PARTICIPANTS_SUCCESS, data };
  }
  function failure(error: string) { return { type: actionTypes.CHANGE_EVENT_PARTICIPANTS_FAILURE, error }; }
}

function cancelEvent(goal: object, cb?: () => void) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    goalServices.cancelEvent(goal)
      .then(
        (message) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success());
          cb && cb();
          dispatch(alertActions.successAlert(message));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };
  function success() { return { type: actionTypes.CANCEL_EVENT_SUCCESS }; }
}

function downloadSession(bidId: string) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    goalServices.downloadSession(bidId)
      .then(
        (data) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(data));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure());
        },
      );
  };
  function success(data) { return { type: actionTypes.DOWNLOAD_SESSION_SUCCESS, data }; }
  function failure() { return { type: actionTypes.DOWNLOAD_SESSION_FAILURE }; }
}

function displayError(message: string) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(alertActions.errorAlert(message));
  };

}
function recordingCompleted(data: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch({ type: actionTypes.DOWNLOAD_SESSION_SUCCESS, data });
  };
  
}


function resolveEventConflict(bidId: string, requestData: any = null) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    goalServices.resolveMeetingConflicts(bidId)
      .then(
        (data) => {
          debugger;
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(data));
          requestData && dispatch(getRequests(requestData));
        },
        (error) => {
          handleError(dispatch, failure, error);
        },
      );
  };
  function success(data) { return { type: actionTypes.RESOLVE_EVENT_CONFLICT_SUCCESS, data }; }
  function failure() { return { type: actionTypes.RESOLVE_EVENT_CONFLICT_FAILURE }; }
}