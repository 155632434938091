import { tagServices } from '../../../../services';
import { alertActions } from '../../../alert/redux/actions';

import { actionTypes } from './types';
import OpenFieldTypes from 'OpenFieldTypes';

export const searchTagsAction = {
  searchTags,
};

function searchTags(searchText) {
  return (dispatch: OpenFieldTypes.RootAction) => {
      tagServices.searchTags(searchText)
            .then(
                (tags) => {
                  dispatch(success(tags));
                },
                (error) => {
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.errorAlert(error.toString()));
                },
            );
    };

  function success(data: any) { return { type: actionTypes.SEARCH_TAG_LIST_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.SEARCH_TAG_LIST_FAILURE, error }; }
}
