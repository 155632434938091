export const actionTypes = {
  GOAL_LIST_REQUEST: 'GOAL_LIST_REQUEST',
  GOAL_LIST_SUCCESS: 'GOAL_LIST_SUCCESS',
  GOAL_LIST_FAILURE: 'GOAL_LIST_FAILURE',
  CREATE_GOAL_REQUEST: 'CREATE_GOAL_REQUEST',
  CREATE_GOAL_SUCCESS: 'CREATE_GOAL_SUCCESS',
  CREATE_GOAL_FAILURE: 'CREATE_GOAL_FAILURE',
  GOAL_DETAILS_SUCCESS: 'GOAL_DETAILS_SUCCESS',
  GOAL_DETAILS_FAILURE: 'GOAL_DETAILS_FAILURE',
  GOAL_RESET_STATUS: 'GOAL_RESET_STATUS',
  UPDATE_GOAL_SUCCESS: 'UPDATE_GOAL_SUCCESS',
  UPDATE_GOAL_FAILURE: 'UPDATE_GOAL_FAILURE',
  DELETE_GOAL_SUCCESS: 'DELETE_GOAL_SUCCESS',
  DELETE_GOAL_FAILURE: 'DELETE_GOAL_FAILURE',
  UPDATE_GOAL_STATUS_SUCCESS: 'UPDATE_GOAL_STATUS_SUCCESS',
  UPDATE_GOAL_STATUS_FAILURE: 'UPDATE_GOAL_STATUS_FAILURE',
  UPDATE_GOAL_STATUS_DETAIL_SUCCESS: 'UPDATE_GOAL_STATUS_DETAIL_SUCCESS',
  UPDATE_GOAL_STATUS_DETAIL_FAIlURE: 'UPDATE_GOAL_STATUS_DETAIL_FAIlURE',
  UPDATE_GOAL_PARTICIPANTS_SUCCESS: 'UPDATE_GOAL_PARTICIPANTS_SUCCESS',
  UPDATE_GOAL_PARTICIPANTS_FAILURE: 'UPDATE_GOAL_PARTICIPANTS_FAILURE',
};
