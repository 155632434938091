import React from 'react';
import { connect } from 'react-redux';
import { ListingContainer } from '../../../shared/listingManagement/listingContainer';
import { ILocalUser } from '../../../../interfaces';
import { staticConstants, pageRoutes, getSelectedValue, MAGIC_NUMBER, CSS_ClASSES } from '../../../../utils';
import { eventAction } from '../../../shared/eventManagement/redux/actions';
import { FilterComponent } from '../../../shared/filter/filterComponent';
import { Button } from 'reactstrap';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import './../../../../assets/sass/theme/_calendar.scss';
import { history } from '../../../../helpers';
import _ from 'lodash';
import { tagAction } from '../../../shared/tags/redux/actions';

interface IProps {
  activeTab: string;
  user?: ILocalUser;
  userId?: any;
  activeTabRole?: string;
  goals: any;
  redirectFrom: string;
  getRequests: (data: object) => void;
  getDomains: (data: object) => void;
  resolveEventConflict: (bidId: string, data: any) => void;
  resetAllTags: () => void;
}

interface IState {
  activeTab: string;
  fetchEvents: boolean;
  isScheduleManagement: boolean;
  goals: { count: number; list: any[]; fetchedEventListing: boolean };
  items: number;
  activePage: number;
  tableContent: object;
  sort: string;
  sortBy: any;
  title: string;
  eventType: string;
  domain: string;
  eventStatus: string;
  showCalendar: boolean;
}

class GoalEvents extends React.Component<IProps, IState> {

    /**
     * @description getDerivedStateFromProps is called when there in change in property
     * @param fields {Object} props
     */
  public static getDerivedStateFromProps(props: IProps, state: any) {
    const updateState = {};
    const calendarEvents = [];
    if (props.activeTab !== state.activeTab) {
      updateState['activeTab'] = props.activeTab;
      updateState['fetchEvents'] = true;
      updateState['activePage'] = true;
      updateState['isScheduleManagement'] = true;
      updateState['title'] = '';
      updateState['eventType'] = '';
      updateState['domain'] = '';
      updateState['eventStatus'] =  staticConstants.EVENT_MANAGEMENT_TAB.UPCOMING_EVENTS;
    }
    if (props.goals && props.goals.list) {
      _.map(props.goals.list, (goal) => {
        calendarEvents.push({
          start: goal.scheduleAt,
          title: goal.title,
          id: goal._id,
        });
      });
      updateState['goals'] = props.goals;
      updateState['calendarEvents'] = calendarEvents;
      return updateState;
    }
  }
  public calendarComponentRef = React.createRef<FullCalendar>();
  public state = {
    activePage: 1,
    items: MAGIC_NUMBER.TEN,
    goals: {
      count: 0,
      list: [],
      fetchedEventListing: false,
    },
    tableContent: {},
    activeTab: this.props.activeTab,
    eventStatus: staticConstants.EVENT_MANAGEMENT_TAB.UPCOMING_EVENTS,
    sort: '',
    sortBy: 0,
    title: '',
    eventType: null,
    domain: null,
    fetchEvents: false,
    isScheduleManagement: true,
    showCalendar: false,
    calendarWeekends: true,
    calendarEvents: [], // initial event data [{ title: 'Event Now', start: new Date() }],
    callUpdateEvent: false,
  };

  /**
   * @description componentDidMount is called when component is loaded
   * call goalAction.getGoals to fetch goal listings
   * call goalAction.getGoals to fetch goal categories
   */
  public componentDidMount() {
    this.fetchRfpData(1);
    this.props.getDomains({});
  }

  /**
   * @description componentWillUnmount is used when component destroyed
   * call tagAction.resetAllTags() to reset the tags
   */
  public componentWillUnmount() {
    this.props.resetAllTags();
  }

  /**
   * @description componentDidUpdate is called when state gets updated
   * call eventAction.eventAction to fetch event listings
   */
  public componentDidUpdate() {
    if (this.state.fetchEvents) {
      this.fetchRfpData(1);
      this.setState({ fetchEvents: false });
    }
  }

  public getReqObj =  (pageno: number) => {
    const { sort, sortBy, isScheduleManagement } = this.state;
    const { activeTabRole } = this.props;
    let reqObj = {
      page: pageno,
      limit: this.state.items,
      eventType: getSelectedValue(this.state.eventType),
      domain: getSelectedValue(this.state.domain),
      userId: this.props.userId,
      title: this.state.title,
      isScheduleManagement,
      filter: activeTabRole,
      isPast: this.state.eventStatus === staticConstants.EVENT_MANAGEMENT_TAB.PAST_EVENTS,
    };

    if (sort) {
      reqObj = { ...reqObj, ...{ sort, sortBy } };
    }
    return reqObj;
  }

  /**
   * @description fetchGoalsData is used on initial load
   */
  public fetchRfpData(pageno: number) {
    this.props.getRequests(this.getReqObj(pageno));
  }

  /**
   * @description
   * handleChange is used to set the value on state from the input.
   * @param fields {Object} || {String} value
   * @param fields {String} key
   */
  public handleChange = (value: string, key: string) => {
    this.setState({ ...this.state, [key]: value },
                  () => { this.fetchRfpData(1); });
  }

  /**
   * @description
   * sortData is used for sorting purpose.
   * @param fields {Number} sort
   * @param fields {String} sortBy
   */
  public sortData = (sort: string, sortBy: string) => {
    const { activePage } = this.state;
    this.setState({ ...this.state, sort, sortBy },
                  () => this.fetchRfpData(activePage));
  }

  /**
   * @description
   * handlePageChange is called someone click on the pagination.
   * @param fields {Number} pageno
   */
  public handlePageChange = (pageno: number) => {
    this.setState({
      activePage: pageno,
    },            () => {
      this.fetchRfpData(pageno);
    });
  }

  public handleDateClick = (info: any) => {
    history.push({
      pathname: `${pageRoutes.EVENTMANAGEMENT.PATH}${pageRoutes.EVENTMANAGEMENT.DETAIL}/${info.event.id}`,
      state: {
        activeTab: this.state.activeTab,
      },
    });
  }

  public resolveEventConflict = (bidId: string) => {
   this.props.resolveEventConflict(bidId, this.getReqObj(this.state.activePage));
  }

  /**
   * @description
   * changeEventType is called when some click on the event type.
   */
  public changeEventType = (eventStatus: any) => {
    this.setState({
      eventStatus,
      title: '',
      eventType: '',
      domain: '',
    },            () => this.fetchRfpData(1));
  }

  public render() {
    const { goals, activePage, sort, sortBy, showCalendar, activeTab, eventStatus } = this.state;
    const { activeTabRole, redirectFrom } = this.props;
    const filterObj = {
      activePage,
      sort,
      sortBy,
    };
    const filterContent = ['search', 'eventType', 'domain'];
    const tableContent = {
      title: { name: 'Title', class: 'table-col-15', sort: true, path: `${pageRoutes.EVENTMANAGEMENT.PATH}${pageRoutes.EVENTMANAGEMENT.DETAIL}`, activeTab },
      eventType: { name: 'Event Type', class: 'table-col-12', sort: true },
      domain: { name: 'Domain', class: 'table-col-10', sort: true },
    };

    if (eventStatus === staticConstants.EVENT_MANAGEMENT_TAB.UPCOMING_EVENTS) {
      tableContent['scheduleAt'] = { name: 'Schedule For', class: 'table-col-10', sort: true };
      tableContent['scheduleTime'] = { name: 'Schedule Time', class: 'table-col-12' };
      tableContent['totalParticipants'] = { name: 'Participants', class: 'table-col-13 text-center', sort: true };
    } else {
      tableContent['scheduleAt'] = { name: 'Event Completed', class: 'table-col-10', sort: true };
      tableContent['scheduleTime'] = { name: 'Event Time', class: 'table-col-12' };
      tableContent['totalParticipants'] = { name: 'Participants', class: 'table-col-13 text-center', sort: true };
    }

    if (activeTabRole === staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.ADMIN_LEARNING_GOALS) {
      if (redirectFrom === 'eventManagement') {
        tableContent['userId.organizationName'] = { name: 'Team Name', class: 'table-col-15', sort: true, dataLabel: 'Team Name' };
      }
      if (eventStatus === staticConstants.EVENT_MANAGEMENT_TAB.UPCOMING_EVENTS) {
        if (redirectFrom === 'userManagement') {
          tableContent['scheduleAt'] = { name: 'Schedule For', class: 'table-col-15', sort: true };
          tableContent['scheduleTime'] = { name: 'Schedule Time', class: 'table-col-15' };
          tableContent['acceptedBidder.name'] = { name: 'Expert Name', class: 'table-col-20', sort: true, dataLabel: 'Expert Name' };
        } else {
          tableContent['acceptedBidder.name'] = { name: 'Expert Name', class: 'table-col-13', sort: true, dataLabel: 'Expert Name' };
          tableContent['zoomData.hostEmail'] = { name: 'Host Email', class: 'table-col-13', sort: true, dataLabel: 'Host Email' };
          tableContent['resolveEventConflict'] = { name: 'Action', dataLabel: 'Action', class: 'table-col-5' };
        }
      } else {
        tableContent['scheduleAt'] = { name: 'Event Completed', class: 'table-col-13', sort: true };
        tableContent['scheduleTime'] = { name: 'Event Time', class: 'table-col-10' };
        if (redirectFrom === 'eventManagement') {
          tableContent['totalParticipants'] = { name: 'Participants', class: 'table-col-10 text-center', sort: true };
          tableContent['acceptedBidder.name'] = { name: 'Expert Name', class: 'table-col-15', sort: true, dataLabel: 'Expert Name' };
        } else {
          tableContent['totalParticipants'] = { name: 'Participants', class: 'table-col-13 text-center', sort: true };
          tableContent['acceptedBidder.name'] = { name: 'Expert Name', class: 'table-col-17', sort: true, dataLabel: 'Expert Name' };
        }
        if (redirectFrom === 'userManagement') {
          tableContent['sessionFee'] = { name: 'Session Fee', class: 'table-col-10', sort: true };
        }
      }
    }

    if (activeTabRole === staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.PROFESSIONSAL_LEARNING_GOALS) {
      if (redirectFrom === 'eventManagement') {
        tableContent['userId.name'] = { name: 'Individual Name', class: 'table-col-15', sort: true, dataLabel: 'Individual Name' };
      }
      if (eventStatus === staticConstants.EVENT_MANAGEMENT_TAB.UPCOMING_EVENTS) {
        if (redirectFrom === 'userManagement') {
          tableContent['scheduleAt'] = { name: 'Schedule For', class: 'table-col-15', sort: true };
          tableContent['scheduleTime'] = { name: 'Schedule Time', class: 'table-col-15' };
          tableContent['acceptedBidder.name'] = { name: 'Expert Name', class: 'table-col-20', sort: true, dataLabel: 'Expert Name' };
        } else {
          tableContent['acceptedBidder.name'] = { name: 'Expert Name', class: 'table-col-13', sort: true, dataLabel: 'Expert Name' };
        }
      } else {
        tableContent['scheduleAt'] = { name: 'Event Completed', class: 'table-col-13', sort: true };
        tableContent['scheduleTime'] = { name: 'Event Time', class: 'table-col-10' };
        if (redirectFrom === 'eventManagement') {
          tableContent['totalParticipants'] = { name: 'Participants', class: 'table-col-10 text-center', sort: true };
          tableContent['acceptedBidder.name'] = { name: 'Expert Name', class: 'table-col-15', sort: true, dataLabel: 'Expert Name' };
        } else {
          tableContent['totalParticipants'] = { name: 'Participants', class: 'table-col-13 text-center', sort: true };
          tableContent['acceptedBidder.name'] = { name: 'Expert Name', class: 'table-col-17', sort: true, dataLabel: 'Expert Name' };
        }
        if (redirectFrom === 'userManagement') {
          tableContent['sessionFee'] = { name: 'Session Fee', class: 'table-col-10', sort: true };
        }
      }

    }

    if ((activeTabRole === staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.EXPERT_LEARNING_GOALS) && eventStatus === staticConstants.EVENT_MANAGEMENT_TAB.UPCOMING_EVENTS) {
      tableContent['domain'] = { name: 'Domain', class: 'table-col-15', sort: true };
      tableContent['scheduleAt'] = { name: 'Schedule For', class: 'table-col-13', sort: true };
      tableContent['requestedByEvent'] = { name: 'Requested By', class: 'table-col-20' };
    }
    if ((activeTabRole === staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.EXPERT_LEARNING_GOALS) && eventStatus === staticConstants.EVENT_MANAGEMENT_TAB.PAST_EVENTS) {
      tableContent['scheduleAt'] = { name: 'Event Completed', class: 'table-col-15', sort: true };
      tableContent['requestedByEvent'] = { name: 'Requested By', class: 'table-col-15' };
      tableContent['sessionFee'] = { name: 'Session Fee', class: 'table-col-8', sort: true };
    }

    const classVarArr = ['table-head'];
    // eventStatus === staticConstants.EVENT_MANAGEMENT_TAB.UPCOMING_EVENTS && classVarArr.push('upcoming-event-table-format');

    return (
        <React.Fragment>
            <div className="filter-wrapper">
                <FilterComponent filterContent={filterContent} handleFilter={this.handleChange} activeTab={activeTab} tabChange={eventStatus} />
                <div className="table-btn-group">
                    <Button type="button"
                      className={'btn btn-icon h-40 shadow-none br-1 ' + (!showCalendar ? 'info' : '')}
                      onClick={() => this.setState({ showCalendar: false })}>List</Button>
                    <Button type="button"
                      className={'btn btn-icon h-40 shadow-none ' + (showCalendar ? 'info' : '')}
                      onClick={() => this.setState({ showCalendar: true })}>Calendar</Button>
                </div>
            </div>
            <div className="tab-btn-group">
                <Button
                color="secondary"
                className={'btn tab-btn btn-rg h-50 ' +
                  (eventStatus === staticConstants.EVENT_MANAGEMENT_TAB.UPCOMING_EVENTS ? CSS_ClASSES.btnActive : '')}
                onClick={() => this.changeEventType(staticConstants.EVENT_MANAGEMENT_TAB.UPCOMING_EVENTS)}
                >
                Upcoming Events
                </Button>
                <Button
                color="secondary"
                className={'btn tab-btn btn-rg h-50 ' +
                  (eventStatus === staticConstants.EVENT_MANAGEMENT_TAB.PAST_EVENTS ? CSS_ClASSES.btnActive : '')}
                onClick={() => this.changeEventType(staticConstants.EVENT_MANAGEMENT_TAB.PAST_EVENTS)}
                >
                Past Events
                </Button>
            </div>
            {showCalendar &&
                <div className="event-calendar">
                    <FullCalendar
                        defaultView="dayGridMonth"
                        header={{
                          left: 'prev,next today',
                          center: 'title',
                          right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
                        }}
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        ref={this.calendarComponentRef}
                        weekends={this.state.calendarWeekends}
                        events={this.state.calendarEvents}
                        eventClick={this.handleDateClick}
                    />
                </div>
            }
            {
                !showCalendar &&
                <ListingContainer filterObj={filterObj} tableContent={tableContent} listData={goals} 
                tableHeaderClasses={classVarArr.join(' ')}
                resolveEventConflict={this.resolveEventConflict}
                handlePageChange={this.handlePageChange} handleSorting={this.sortData} user={this.props.user} />
            }
        </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication: { user }, scheduledEvent } = state;
  return {
    user,
    role: user.role,
    goals: scheduledEvent,
  };
}

const ConnectedGoalEvents = connect(mapStateToProps, { ...eventAction, ...tagAction })(GoalEvents);
export { ConnectedGoalEvents as GoalEvents };
