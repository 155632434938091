import { actionTypes } from './types';
interface IAction {
  type: string;
  res: any;
  error: any;
}

const initialState = {
  dashboardData: {},
  upcomingEvents: [],
  reviews: [],
};

export function dashboard(state = initialState, action: IAction) {
  switch (action.type) {
    case actionTypes.DASHBOARD_REQUEST:
      return { ...state };
    case actionTypes.DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboardData: action.res,
      };
    case actionTypes.DASHBOARD_SUCCESS_TYPE:
      const data = action.res;
      let detail = state['dashboardData'];
      if (data['type'] === 'totalData') {
        detail = { ...detail, totalData: data['totalData'] };
      } else if (data['type'] === 'learningGoals') {
        detail = { ...detail, learningGoals: data['learningGoals'] };
      } else if (data['type'] === 'learners') {
        detail = { ...detail, learners: data['learners'] };
      } else if (data['type'] === 'totalInvestment') {
        detail = { ...detail, totalInvestment: data['totalInvestment'] };
      } else {
        detail = action.res;
      }
      return {
        ...state,
        dashboardData: detail,
      };
    case actionTypes.DASHBOARD_UPCOMING_EVENTS_FAILURE:
    case actionTypes.DASHBOARD_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case actionTypes.DASHBOARD_UPCOMING_EVENTS_SUCCESS:
      return {
         ...state,
         upcomingEvents: action.res,
       };
    case actionTypes.DASHBOARD_REVIEWS_REQUEST:
    case actionTypes.DASHBOARD_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: action.res,
      };
    default:
      return state;
  }
}
