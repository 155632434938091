import _ from 'lodash';
import React, { Dispatch } from 'react';
import Pagination from 'react-js-pagination';
import { connect } from 'react-redux';
import {
} from 'reactstrap';
import { learnerStatus, assignLicence, deleteLearner, MAGIC_NUMBER, unAssignLicence, raiseLicenceRequestToAdmin, learnerAlreadyAssignToGoalError  } from '../../../../utils/';
import { Sidebar } from '../../sidebar';
import { learnerAction } from '../redux/actions';
import { LearnerItem } from './learner';
import OpenFieldTypes from 'OpenFieldTypes';
import { FilterComponent } from '../../filter/filterComponent';
import { LicenceModalPopup } from '../../modals/licenceModalPopup';
import { userAction } from '../../../systemAdmin/userManagement/redux/actions';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  openCreateLearnerModal: any;
  learners: any;
  list: [];
  licenceRequestToAdmin: boolean;
  alreadyAssignToGoalError: boolean;
}

class ListingContainer extends React.Component<IProps> {

  public static getDerivedStateFromProps(props: IProps, state) {
    if (props.learners && props.learners.list) {
      return {
        learners: props.learners,
        list: props.list,
      };
    }
    if (props.learners && props.learners.licenceRequestToAdmin) {
      return {
        modalPopupObj: raiseLicenceRequestToAdmin,
      };
    }
    if (props.learners && props.learners.alreadyAssignToGoalError) {
      return {
        modalPopupObj: learnerAlreadyAssignToGoalError,
      };
    }
    return null;
  }

  public state = {
    selectedOption: null,
    tooltipOpen: false,
    activePage: 1,
    items: MAGIC_NUMBER.TEN,
    title: '',
    status: { label: 'Status', value: '' },
    sort: '',
    sortBy: 0,
    list: [],
    learners: {
      count: 0,
      list: [],
      successForGettingLearner: false,
    },
    isPagination: true,
    openFilter: false,
    displayAssignPopup: false,
    displayUnassignPopup: false,
    displayDeleteLearnerPopup: false,
    modalPopupObj: {},
    userId: '',
    learnerData: {},
  };

  constructor(props: IProps) {
    super(props);
    this.openCreateLearnerModal = this.openCreateLearnerModal.bind(this);
  }

  public filterToggle = () => {
    this.setState({
      openFilter: true,
    });
  }

  public filterRemove = () => {
    this.setState({
      openFilter: false,
    });
  }

  public componentDidMount() {
    const { items, activePage, isPagination } = this.state;
    this.props.dispatch(learnerAction.getLearner({
      limit: items,
      page: activePage,
      isPagination,
    }));
  }

  public fetchGoalsData(pageno: number) {
    const { sort, sortBy } = this.state;
    let reqObj = {
      limit: this.state.items,
      page: pageno,
      searchKey: this.state.title,
      status: this.state.status && this.state.status.value ? this.state.status.value : null,
      isPagination: this.state.isPagination,
    };
    if (sort) {
      reqObj = Object.assign(reqObj, { sort, sortBy });
    }
    this.props.dispatch(learnerAction.getLearner(reqObj));
  }

  /**
   * @description
   * handleChange is used to set the value on state from the input.
   * @param fields {Object} || {String} value
   * @param fields {String} key
   */
  public handleChange = (value, key) => {
    this.setState({ ...this.state, [key]: value },
                  () => this.fetchGoalsData(1));
  }

  public toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  }

  public handlePageChange = (pageno) => {
    this.setState({
      activePage: pageno,
    },            () => this.fetchGoalsData(pageno));
  }

  public sortData(sort, sortBy) {
    const { activePage } = this.state;
    this.setState({ ...this.state, sort, sortBy },
                  () => this.fetchGoalsData(activePage));
  }

  public openCreateLearnerModal() {
    const { items, activePage, title, status, isPagination } = this.state;
    const { learners } = this.props;
    const reqObj = {
      limit: items,
      page: activePage,
      searchKey: title,
      status: status && status.value ? status.value : null,
      isPagination,
      learners,
    };
    this.props.openCreateLearnerModal(reqObj);
  }

  /**
   * @description
   * showAssignLicencePopup is used show assign licence popup.
   * @param fields {String} id
   */
  public showAssignLicencePopup = (learnerData: object) => {
    const modalPopupObj = assignLicence;
    this.setState({
      displayAssignPopup: true,
      modalPopupObj,
      userId: learnerData['_id'],
      learnerData,
    });
  }

  /**
   * @description
   * assign Licence is called when someone click on the assign button
   */
  public onSubmitAssignLicence = () => {
    const { userId, learnerData } = this.state;
    const reqObj = {
      type: 'assign',
      userId,
      learnerData,
    };
    this.props.dispatch(learnerAction.assignLicence(reqObj));
    this.setState({
      displayAssignPopup: false,
      modalPopupObj: {},
      userId: null,
      learnerData: {},
    });
  }

  /**
   * @description showAssignLicencePopup is used show assign licence popup.
   * @param fields {String} id
   */
  public showUnassignLicencePopup = (learnerData: object) => {
    const modalPopupObj = unAssignLicence;
    this.setState({
      displayUnassignPopup: true,
      modalPopupObj,
      userId: learnerData['_id'],
      learnerData,
    });
  }

  /**
   * @description assign Licence is called when someone click on the assign button
   */
  public onSubmitUnassignLicence = () => {
    const { userId, learnerData } = this.state;
    const reqObj = {
      type: 'remove',
      userId,
      learnerData,
    };
    this.props.dispatch(learnerAction.assignLicence(reqObj));
    this.setState({
      displayUnassignPopup: false,
      modalPopupObj: {},
      userId: null,
      learnerData: {},
    });
  }

  /**
   * @description
   * showDeleteLearnerPopup is used delete learner from the list.
   * @param fields {String} id
   */
  public showDeleteLearnerPopup = (id: string) => {
    const modalPopupObj = deleteLearner;
    this.setState({
      displayDeleteLearnerPopup: true,
      modalPopupObj,
      userId: id,
    });
  }

  /**
   * @description
   * delete Learner is called when someone click on the delete button
   */
  public onSubmitDeleteLearner = () => {
    const { userId } = this.state;
    const reqObj = {
      userId,
    };
    this.props.dispatch(learnerAction.deleteLearner(reqObj));
    this.setState({
      displayDeleteLearnerPopup: false,
      modalPopupObj: {},
      userId: null,
    });
  }

  /**
   * @description
   * reVerifyUser is used to re send mail to un verified user
   * @param fields {string} userId
   */
  public reVerifyUser = (userId: string) => {
    const reqObj = {
      userId,
    };
    this.props.dispatch(userAction.reVerifyUserEmail(reqObj));
  }

  public renderTableData(learnerList: any) {
    return _.map(learnerList, (item, index) => {
      return (
        <LearnerItem learner={item} key={index}
          assignLicence={this.showAssignLicencePopup}
          unAssignLicence={this.showUnassignLicencePopup}
          deleteLearner={this.showDeleteLearnerPopup}
          reVerifyUser={this.reVerifyUser} />
      );
    });
  }

  /**
   * @description
   * cancel request is called to cancel the raised request of assigning licence
   * dispatch an action to change the props
   */
  public cancelRequest = () => {
    this.setState({ userId: null });
    this.props.dispatch(learnerAction.cancelRequest());
  }

  /**
   * @description
   * licence request is called to raise the request of assigning licence to system admin
   */
  public licenceRequest = () => {
    this.props.dispatch(learnerAction.raiseRequest());
  }

  public render() {
    const { activePage, items, learners, sort, sortBy, displayAssignPopup, modalPopupObj,
      displayDeleteLearnerPopup, displayUnassignPopup, list } = this.state;
    const { licenceRequestToAdmin, alreadyAssignToGoalError } = this.props;
    let minDisplay: number;
    let maxDisplay: number;
    if (learners.count === MAGIC_NUMBER.ZERO) {
      maxDisplay = MAGIC_NUMBER.ZERO;
      minDisplay = MAGIC_NUMBER.ZERO;
    } else if (learners.count <= items) {
      maxDisplay = learners.count;
      minDisplay = MAGIC_NUMBER.ONE;
    } else {
      maxDisplay = (Number(activePage) * Number(items)) < learners.count
        ? (Number(activePage) * Number(items)) : learners.count;
      minDisplay = ((Number(activePage) - 1) * Number(items)) + 1;
    }
    const filterContent = ['search', 'status'];
    return (
      <React.Fragment>
        <Sidebar {...this.props} />
        <div className="dashboard-wrapper">
          <h2 className="heading heading-sm roboto-medium text-uppercase">User Management</h2>
          <div className="dashboard-content">
            <div className="filter-wrapper">
              <FilterComponent filterContent={filterContent} handleFilter={this.handleChange.bind(this)}
                status={learnerStatus} placeholder="Search by Keywords, Name" />
              <span onClick={this.openCreateLearnerModal} className="btn btn-primary round-circle add-filter-btn">
                Add User
              </span>
            </div>
          </div>
          <div className="table-wrapper">
            <div className="flex-table">
              <div className="table-head">
                <div className="table-row">
                  <div className="table-col table-col-40">
                    <span className="sorting-icon">Name
                      <strong>
                        <em className={(sort === 'name' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                          onClick={() => { this.sortData('name', MAGIC_NUMBER.ONE); }} />
                        <em className={(sort === 'name' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                          onClick={() => { this.sortData('name', MAGIC_NUMBER.FOUND_INDEX); }} />
                      </strong>
                    </span>
                  </div>
                  <div className="table-col table-col-30">
                    <span className="sorting-icon">Email
                      <strong>
                        <em className={(sort === 'email' && sortBy === MAGIC_NUMBER.ONE) ? 'active' : ''}
                          onClick={() => { this.sortData('email', MAGIC_NUMBER.ONE); }} />
                        <em className={(sort === 'email' && sortBy === MAGIC_NUMBER.FOUND_INDEX) ? 'active' : ''}
                          onClick={() => { this.sortData('email', MAGIC_NUMBER.FOUND_INDEX); }} />
                      </strong>
                    </span>
                  </div>
                  <div className="table-col table-col-17">
                    <span className="sorting-icon">Status</span>
                  </div>
                  <div className="table-col table-col-13 text-center">
                    <span>Action</span>
                  </div>
                </div>
              </div>
              <div className="table-body">
                {this.renderTableData(list)}
                {learners && learners.successForGettingLearner && learners.count === MAGIC_NUMBER.ZERO ?
                  <div className="table-row table-no-data">
                    No Results Found.
                    </div>
                  : ''}
              </div>
                { (learners && learners.count > MAGIC_NUMBER.TEN) &&
                  <div className="table-footer">
                      <React.Fragment>
                        <p>Showing {minDisplay} - {maxDisplay} of {learners.count}</p>
                        <div className="pagination-wrapper">
                          <Pagination
                            hideFirstLastPages
                            activePage={activePage}
                            itemsCountPerPage={items}
                            totalItemsCount={learners.count}
                            pageRangeDisplayed={MAGIC_NUMBER.FIVE}
                            onChange={this.handlePageChange}
                          />
                        </div>
                      </React.Fragment>
                  </div>
                }
            </div>
          </div>
        </div>
        {
          displayAssignPopup ?
            <LicenceModalPopup isOpenModal={displayAssignPopup} modalPopupObj={modalPopupObj}
              hideModal={() => this.setState({ displayAssignPopup: false, userId: null })} onClickYes={this.onSubmitAssignLicence} />
            : ''
        }

        {
          displayUnassignPopup ?
            <LicenceModalPopup isOpenModal={displayUnassignPopup} modalPopupObj={modalPopupObj}
              hideModal={() => this.setState({ displayUnassignPopup: false, userId: null })} onClickYes={this.onSubmitUnassignLicence} />
            : ''
        }

        {
          displayDeleteLearnerPopup ?
            <LicenceModalPopup isOpenModal={displayDeleteLearnerPopup} modalPopupObj={modalPopupObj}
              hideModal={() => this.setState({ displayDeleteLearnerPopup: false, userId: null })} onClickYes={this.onSubmitDeleteLearner} />
            : ''
        }

        {
          licenceRequestToAdmin ?
            <LicenceModalPopup isOpenModal={licenceRequestToAdmin} modalPopupObj={modalPopupObj}
              hideModal={this.cancelRequest} onClickYes={this.licenceRequest} />
            : ''
        }

        {
          alreadyAssignToGoalError ?
            <LicenceModalPopup isOpenModal={alreadyAssignToGoalError} modalPopupObj={modalPopupObj}
              hideModal={this.cancelRequest}/>
            : ''
        }
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: { learner: any }) {
  return {
    learners: state.learner,
    list: state.learner.list,
    licenceRequestToAdmin: state.learner.licenceRequestToAdmin,
    alreadyAssignToGoalError: state.learner.alreadyAssignToGoalError,
  };
}

const connectedLearnerListingContainer = connect(mapStateToProps)(ListingContainer);
export { connectedLearnerListingContainer as ListingContainer };
