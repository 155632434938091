import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Modal,
  ModalBody,
} from 'reactstrap';
import success from '../../../assets/images/icons/success.svg';
import OpenFieldTypes from 'OpenFieldTypes';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  isOpenModal: boolean;
  modalPopupObj: any;
  onClickOk(): void;
}

interface IState {
  modal: boolean;
}

class SuccessPopup extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      modal: props.isOpenModal,
    };
  }

  public render() {
    const { modal } = this.state;
    const { modalPopupObj } = this.props;
    function createMarkup() { return { __html: `${modalPopupObj.body}` }; }
    return (
      <Modal isOpen={modal} className="delete-modal">
        <ModalBody>
          <img src={success} alt="Success" />
          <p dangerouslySetInnerHTML={createMarkup()}/>
          <div className="button-group">
            {modalPopupObj.okBtnText ?
              <Button className={`btn mx-2 ${modalPopupObj.okBtnClass ? modalPopupObj.okBtnClass : 'btn-primary'}`}
                onClick={this.props.onClickOk}>{modalPopupObj.okBtnText}</Button>
              : ''}
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const connectedSuccessPopupPage = connect()(SuccessPopup);
export { connectedSuccessPopupPage as SuccessPopup };
