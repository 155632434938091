import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
} from 'reactstrap';
import ReeValidate from 'ree-validate';
import OpenFieldTypes from 'OpenFieldTypes';
import { IValidator } from './../../../interfaces';
import { formatCurrency, MAGIC_NUMBER } from '../../../utils';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  isOpenModal: boolean;
  hideModal: any;
  addPlan: any;
  editPlan: object;
  onEdit: any;
}

interface IState {
  submitted: boolean;
  productName: string;
  licenceAmount: any;
  learnerAmount: any;
  errors?: any;
  modal: boolean;
}

class PlanModal extends React.Component<IProps, IState> {

  public static getDerivedStateFromProps(props: IProps, state: any) {
    const updateState = {};
    updateState['modal'] = props.isOpenModal;
    if (props.editPlan && !state.submitted) {
      updateState['productName'] = props.editPlan['productName'];
      updateState['licenceAmount'] = props.editPlan['licenceAmount'];
      updateState['learnerAmount'] = props.editPlan['learnerAmount'];
    }
    return updateState;
  }

  public validator: IValidator;
  constructor(props: IProps) {
    super(props);

    // reset login status
    this.validator = new ReeValidate({
      productName: 'required',
      addonAmount: 'required',
      licenceAmount: 'required',
    });

    this.state = {
      productName: '',
      licenceAmount: '',
      learnerAmount: '',
      submitted: false,
      errors: this.validator.errors,
      modal: this.props.isOpenModal,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.validateAndSubmit = this.validateAndSubmit.bind(this);
  }

  public handleChange = (e: any) => {
    const { name, value } = e.target;
    const { errors } = this.validator;
    this.setState({
      submitted: true,
      [name]: value,
    } as any);
    if (name === 'licenceAmount') {
      this.setState({ learnerAmount: ((value * MAGIC_NUMBER.FIFTY) / MAGIC_NUMBER.HUNDRED).toFixed(MAGIC_NUMBER.TWO) });
    }
    errors.remove(name);
    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors });
      });
  }

  public handleKeypress = (e: any) => {
    const characterCode = e.key;
    if (characterCode === 'Backspace') return;
    const characterNumber = Number(characterCode);
    if (characterNumber >= MAGIC_NUMBER.ZERO && characterNumber <= MAGIC_NUMBER.NINE) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      }
    } else {
      e.preventDefault();
    }
  }

  public validateAndSubmit(e: any) {
    e.preventDefault();
    const { productName, licenceAmount, learnerAmount } = this.state;
    const { errors } = this.validator;
    this.validator.validateAll({ productName, licenceAmount, learnerAmount })
    .then((success: any) => {
      if (success) {
        this.handleSubmit();
      } else {
        this.setState({ errors });
      }
    });
  }

  public handleSubmit() {
    const { productName, licenceAmount, learnerAmount } = this.state;
    const { editPlan } = this.props;
    let obj;
    if (productName && editPlan && editPlan['_id']) {
      obj = {
        productName,
        productId: editPlan['_id'],
      };
    } else if (productName && licenceAmount && learnerAmount) {
      obj = {
        productName,
        licenceAmount: Number(licenceAmount),
        learnerAmount: Number(learnerAmount),
      };
    }
    editPlan && editPlan['_id'] ? this.props.onEdit(obj) : this.props.addPlan(obj);
    this.setState({ submitted: false });
  }

  public hideModal() {
    this.setState({
      modal: false,
      productName: '',
      licenceAmount: '',
      learnerAmount: '',
    });
    const { errors } = this.validator;
    errors.clear();
    this.props.hideModal();
  }

  public render() {
    const { modal, productName, licenceAmount, learnerAmount, errors } = this.state;
    const { editPlan } = this.props;
    return (
      <Modal isOpen={modal} className="add-edit-plan-modal" onClosed={this.hideModal}>
        <ModalHeader toggle={this.hideModal}>{editPlan && editPlan['_id'] ? 'Edit Plan' : 'Add Plan'}</ModalHeader>
        <ModalBody>
          <Form onSubmit={this.validateAndSubmit} autoComplete="off" noValidate>
          {editPlan && !editPlan['_id'] ?
            <FormGroup className="mt-0">
                <span className={'selected'}>Billing Cycle Period</span>
                <h4 className="heading heading-lg roboto-bold">Monthly</h4>
            </FormGroup> :
            <FormGroup className="mt-0">
             <Row>
              <Col xs="12" md="6">
                <span className={'selected'}>Billing Cycle Period</span><br/>
                <h6 className="heading">Monthly</h6>
              </Col>
               <Col xs="12" md="6">
               <span className={'selected'}>Licence Amount</span><br/>
                <h6 className="heading">{`$${editPlan && formatCurrency(editPlan['licenceAmount'])}`}</h6>
              </Col>
             </Row>
            </FormGroup> }
            <FormGroup
                className={`floating-label ${
                  errors.has('productName') ? ' has-error' : ''
                  }`}>
                <Input
                    type="text"
                    className="form-control"
                    value={productName}
                    onChange={this.handleChange}
                    name="productName"
                    id="productName"
                    placeholder="Plan Name"
                />
                <Label for="productName" className={productName ? 'selected' : ''}>Plan Name</Label>
                {errors.has('productName') &&
                <div className="error-text">{errors.first('productName')}</div>
                }
            </FormGroup>
            {editPlan && !editPlan['_id'] ? <FormGroup
                className={`floating-label ${
                  errors.has('licenceAmount') ? ' has-error' : ''
                  }`}>
                <Input
                    type="number"
                    className="form-control"
                    id="licenceAmount"
                    value={licenceAmount}
                    onChange={this.handleChange}
                    name="licenceAmount"
                    placeholder="Licence Amount"
                    min="0"
                    onKeyDown={this.handleKeypress}
                />
                <Label for="licenceAmount" className={licenceAmount ? 'selected' : ''}>Licence Amount</Label>
                {errors.has('licenceAmount') &&
                <div className="error-text">{errors.first('licenceAmount')}</div>
                }
            </FormGroup> : ''}
            {editPlan && !editPlan['_id'] ? <FormGroup
                className={`floating-label mb-0`}>
                <Input
                    type="number"
                    className="form-control px-2"
                    id="learnerAmount"
                    value={learnerAmount}
                    onChange={this.handleChange}
                    name="learnerAmount"
                    placeholder="Member Amount"
                    readOnly
                />
                <Label for="learnerAmount" className={learnerAmount ? 'selected' : ''}>Member Amount</Label>
            </FormGroup> : ''}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-regular ml-0" onClick={this.hideModal}>Cancel</Button>{' '}
          <Button color="primary" className="mr-2" onClick={this.validateAndSubmit}>{editPlan && editPlan['_id'] ? 'Update' : 'Add'}</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

function mapStateToProps(state: any) {
  return {
  };
}

const connectedFaqModalPage = connect(mapStateToProps)(PlanModal);
export { connectedFaqModalPage as PlanModal };
