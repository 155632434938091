import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import OpenFieldTypes from 'OpenFieldTypes';
import { Sidebar } from '../../shared/sidebar';
import { ListingContainer } from './listing/listingContainer';
interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
}

class CustomizedPlanContainer extends React.Component<IProps> {

  public render() {
    return (
            <div>
                <Sidebar {...this.props} />
                <div className="dashboard-wrapper">
                    <h2 className="heading heading-sm roboto-medium text-uppercase">PLANS</h2>
                    <div className="dashboard-content">
                        <ListingContainer {...this.props} />
                    </div>
                </div>
            </div >
    );
  }
}

const connectedCustomizedPlanContainer = connect(null)(CustomizedPlanContainer);
export { connectedCustomizedPlanContainer as CustomizedPlanContainer };
