import React, { Dispatch } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { connect } from 'react-redux';
import _ from 'lodash';
import { IGoalDetail } from '../../../interfaces';
import profileImage from './../../../assets/images/profile.png';
import { learnerAction } from '../learnerManagement/redux/actions';
import OpenFieldTypes from 'OpenFieldTypes';
import { MAGIC_NUMBER } from '../../../utils';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  isOpenModal: boolean;
  goalDetail: IGoalDetail;
  learnerList: any;
  hideModal(): void;
  onClickYes(arg0: object): void;
}

interface IState {
  modal: boolean;
  renderParticipantsList: any;
  participantsListInModal: Array<{ firstName: string; lastName: string; profileImage: string; isAdmin?: boolean;
    checked?: boolean; email: string; _id: string; isActive: boolean; isLicenced: boolean; }>;
  isOrgAdmin: boolean;
  searchParticipantsList: any;

}

class ChangeParticipantPopup extends React.Component<IProps, IState> {
  public selectedParticipants: object[] = [];
  constructor(props: IProps) {
    super(props);
    this.state = {
      modal: props.isOpenModal,
      renderParticipantsList: [],
      participantsListInModal: [],
      isOrgAdmin: false,
      searchParticipantsList: [],
    };
  }

  /**
   * @description componentDidMount is called when component is loaded
   */
  public componentDidMount() {
    this.props.dispatch(learnerAction.getLearner({
      limit: MAGIC_NUMBER.TEN,
      skip: 1,
    }));
  }

  /**
   * @description componentWillReceiveProps is called when prop is changed
   * @param {Object} props
   * @param {Object} nextProps
   */
  public componentWillReceiveProps(props: IProps, nextProps: any) {
    if (props.goalDetail) {
      const { participantsRaw, isMe } = props.goalDetail;
      let updatedParticipantsList = [];
      if (props && props.learnerList) {
        updatedParticipantsList = props.learnerList.filter(elem => elem.isLicenced === true);
        updatedParticipantsList.forEach(el => {
          const isSelected = _.find(participantsRaw, { userId: el._id });
          if (isSelected) {
            this.selectedParticipants.push({ userId: isSelected['userId'], isAdmin: isSelected['isAdmin'] });
          }
        });
      }
      this.setState({
        modal: props.isOpenModal,
        renderParticipantsList: this.selectedParticipants,
        participantsListInModal: updatedParticipantsList,
        isOrgAdmin: isMe,
        searchParticipantsList: updatedParticipantsList,
      });
    }
  }

  public hideModal = () => {
    this.setState({
      modal: false,
      renderParticipantsList: [],
      participantsListInModal: [],
    },            () => { this.props.hideModal(); });
    this.selectedParticipants = [];
    this.props.hideModal();
  }
  /**
   * @description handleParticipant is used to set the value on state
   * @param fields {Object} event
   */
  public handleParticipant = (event: any) => {
    const { value, checked } = event.target;
    if (checked) {
      this.selectedParticipants.push({ userId: value, isAdmin: false });
    } else {
      const pos = this.selectedParticipants.findIndex(item => item['userId'] === value);
      if (pos >= 0) this.selectedParticipants.splice(pos, 1);
    }
    this.setState({
      renderParticipantsList: this.selectedParticipants,
    });
  }

  /**
   * @description searchParticipant is used to search Participant.
   * @param fields {Object} event
   */
  public searchParticipant = (event: any) => {
    const { value } = event.target;
    const regX = new RegExp(value, 'i');
    let { participantsListInModal, searchParticipantsList } = this.state;
    if (value) {
      participantsListInModal = searchParticipantsList;
      const filterParticipants = participantsListInModal.filter((participant: any) => {
        return participant.firstName.match(regX);
      });
      this.setState({ participantsListInModal: filterParticipants });
    } else {
      this.setState({
        participantsListInModal,
      });
    }
  }

/**
 * @description changeParticipant is used for update new participant list in event
 */
  public changeParticipant = () => {
    const participants = this.selectedParticipants;
    const { isMe } = this.props.goalDetail;
    this.props.onClickYes({ participants, isMe });
    this.hideModal();
  }

  /**
   * @description check length of renderParticipantsList and participants list
   */
  public getLengthofList = () => {
    const { renderParticipantsList, isOrgAdmin } = this.state;
    const { participantsRaw } = this.props.goalDetail;
    const lengthObj = {};
    lengthObj['renderParticipantsListLength'] = renderParticipantsList.length;
    lengthObj['participantsListLength'] = isOrgAdmin ? participantsRaw.length - 1 : participantsRaw.length;
    return lengthObj;
  }

  /**
   * @description renderParticipantsListInModal is used for render the ParticipantsList in modal
   */
  public renderParticipantsListInModal() {
    const { participantsListInModal } = this.state;
    const lengthObj = this.getLengthofList();
    if (participantsListInModal) {
      participantsListInModal.forEach(el => {
        const isExist = _.find(this.selectedParticipants, { userId: el._id });
        const isSelectedParticipantObj = _.find(this.selectedParticipants, { isAdmin: true });
        el['checked'] = !!isExist;
        if (isSelectedParticipantObj) {
          el['isAdmin'] = el._id === isSelectedParticipantObj['userId'];
        }
      });
    }
    return _.map(participantsListInModal, (item: any, index: number) => {
      const fetchedProfileImage = item.profileImage !== '' ? item.profileImage : profileImage;
      return (
        <div className="list-item" key={`${index}${item._id}`}>
          <span className="profile-image">
            <img src={fetchedProfileImage} alt="" className="img-cover" />
          </span>
          <div className="profile-detail">
            <span>{item.firstName} {item.lastName}</span>
            <em>{item.email}</em>
          </div>
          <label className={`control control--checkbox`}>
            <input type="checkbox" value={item._id} defaultChecked={item['checked']}
              onChange={this.handleParticipant} name="participants"
              disabled={item['isAdmin'] || (lengthObj['renderParticipantsListLength'] === lengthObj['participantsListLength'] && !item['checked'])}/>
            <div className="control__indicator" />
          </label>
        </div>
      );
    });
  }
  public render() {
    const { modal } = this.state;
    const lengthObj = this.getLengthofList();
    return (
      <Modal isOpen={modal} className="add-participaint">
      <ModalHeader toggle={this.hideModal}>Change Participant</ModalHeader>
        <ModalBody>
          <div className="modal-highlight-box">
            <p>The number of participants should remain the same after changing participants.</p>
          </div>
          <div className="search-bar">
            <input type="search" name="search" placeholder="Search by Name"
              onChange={(event: any) => this.searchParticipant(event)} autoComplete="off" />
            <span className="icon icon-search-icon" />
          </div>
          <div className="participaint-list">
            {this.renderParticipantsListInModal()}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.hideModal}>Cancel</Button>
          <Button color="primary" disabled={(lengthObj['renderParticipantsListLength'] !== lengthObj['participantsListLength'])} onClick={this.changeParticipant}>Change</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const connectedChangeParticipantPopup = connect()(ChangeParticipantPopup);
export { connectedChangeParticipantPopup as ChangeParticipantPopup };
