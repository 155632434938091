import _ from 'lodash';
import React, { Dispatch, Fragment } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap';

import { pageRoutes } from '../../../utils/routeConstants';
import { staticConstants, FEEDBACK_TRIGGER } from '../../../utils/staticConstants';
import logoImage from './../../../assets/images/logo.svg';
import profileImage from './../../../assets/images/profile.png';
import { authAction } from './../../auth/redux/actions';
import OpenFieldTypes from 'OpenFieldTypes';
import { connect } from 'react-redux';
import { getLocalUserData, getFeedBackStatus } from './../../../utils/common';
import { getNavToProfile, MAGIC_NUMBER } from './../../../utils/';
import { history } from './../../../helpers/';
import { notificationAction } from './../notfication/redux/actions';
import { NotificationItem } from './../notfication/listings/notification';
import { FeedbackForm } from './feedbackForm/feedbackForm';
import { ILocalUser } from '../../../interfaces';
import { profileAction } from '../updateProfile/redux/actions';

interface IProps {
  user: ILocalUser;
  notifications: any;
  profile: ILocalUser;
  feedBackCount: any;
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
}

interface IState {
  user?: any;
  headerScroll: any;
  notifications?: any;
  feedBackCount?: any;
  isOpen: any;
  feedbackFormOpen: boolean;
}
class Header extends React.Component<IProps, IState>  {

  public static getDerivedStateFromProps(props: any, state: any) {
    const newSate = {};
    if (props.notifications && props.notifications.topList) {
      newSate['notifications'] = props.notifications;
    }
    if (props.feedBackCount && (props.feedBackCount > FEEDBACK_TRIGGER.TRIGGER_COUNT)) {
      const isFeedback = props.user && props.user['feedback'] && props.user['feedback']['isFeedback'];
      const feedBackStatus = getFeedBackStatus();
      if (!isFeedback && !feedBackStatus) {
        newSate['feedbackFormOpen'] = true;
      }
    }
    return newSate;
  }

  constructor(props: IProps) {
    super(props);
    this.state = {
      headerScroll: false,
      isOpen: false,
      feedbackFormOpen: false,
      feedBackCount: 0,
      notifications: {
        count: 0,
        list: [],
        successForGettingNotifications: false,
      },
    };
    this.logout = this.logout.bind(this);
    this.moveToDashBoard = this.moveToDashBoard.bind(this);
    this.moveToNotification = this.moveToNotification.bind(this);
    this.home=this.home.bind(this);
  }

  public componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
  }

  public handleScroll = () => {
    const headerTop = window.scrollY;
    if (headerTop >= 10) {
      this.setState({
        headerScroll: true,
      });
    } else {
      this.setState({
        headerScroll: false,
      });
    }
  }

  /**
   * @description logout when user clicked the logout
   */
  public logout() {
    const dispatch = this.props.dispatch;
    dispatch(authAction.logout());
  }

  public home() {
    window.location.href = `${pageRoutes.HOME}`;
  }
 
  
  /**
   * @description open the feedback form on the click of sign out
   */
  public openFeedbackForm = () => {
    this.setState({
      feedbackFormOpen: true,
    });
  }

  /**
   * @description moveToProfile is used to navigate the profile page
   */
  public moveToProfile() {
    history.push(getNavToProfile());
  }

  /**
   * @description moveToNotification is used to navigate the notification page
   */
  public moveToNotification() {

    if (this.props.user) history.push(`${pageRoutes.NOTIFICATION.PATH}`);
  }

  /**
   * @description componentDidMount is used when component is loaded
   */
  public componentDidMount() {
    document.addEventListener('scroll', this.handleScroll);
    const reqObj = {
      limit: MAGIC_NUMBER.THREE,
      page: MAGIC_NUMBER.ONE,
      isTopListing: true,
    };
    this.props.dispatch(notificationAction.getNotifications(reqObj));
    this.props.dispatch(profileAction.getProfile());
  }
  /**
   * @description hideNotificationMenu is used to hide menu
   */
  public hideNotificationMenu = () => {
    this.setState({
      isOpen: false,
    });
  }

  /**
   * @description sidebarToggle is used to open menu
   */
  public sidebarToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  /**
   * @description moveToDashBoard is used to move the dashboard page
   */
  public moveToDashBoard() {
    let navigatePath = '';
    const { role, isPaid = false } = this.props.user;
    if (role === staticConstants.ROLE.ORGANIZATION_ADMIN && isPaid) {
      navigatePath = `${pageRoutes.ORGADMIN.PATH}${pageRoutes.ORGADMIN.DASHBOARD}`;
    }
    if (role === staticConstants.ROLE.ORGANIZATION_LEARNER) {
      navigatePath = `${pageRoutes.LEARNER.PATH}${pageRoutes.LEARNER.DASHBOARD}`;
    }
    if (role === staticConstants.ROLE.EXPERT) {
      navigatePath = `${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.DASHBOARD}`;
    }
    if (role === staticConstants.ROLE.PROFESSIONAL && isPaid) {
      navigatePath = `${pageRoutes.PROFESSIONAL.PATH}${pageRoutes.PROFESSIONAL.DASHBOARD}`;
    }
    if (role === staticConstants.ROLE.SYSTEM_ADMIN) {
      navigatePath = `${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.SYSTEM_ADMIN.DASHBOARD}`;
    }
    navigatePath && history.push(`${navigatePath}`);
  }

  /**
   * @description renderNotificationListing is used to render the listing
   */
  public renderNotificationListing() {
    const { topList } = this.state.notifications;
    return _.map(topList, (item, index) => {
      return (
        <DropdownItem key={index}>
          <NotificationItem notification={item} hideNotificationMenu={this.hideNotificationMenu} isTopListing={true} />
        </DropdownItem>
      );
    });
  }

  /**
   * @description
   * markAsRead is called to mark the notification status to read
   */
  public markAsRead = () => {
    const { notifications } = this.props;
    if (notifications.unreadCount > MAGIC_NUMBER.ZERO) {
      this.props.dispatch(notificationAction.markAsRead({}));
    }
  }

  /**
   * @description render is used to render the html
   */
  public render() {
    const { email, role, firstName = null, lastName = null, isFirstTime } = getLocalUserData();
    const { notifications } = this.props;
    let unReadNotificationCount: any, totalNotification: any;
    if (notifications) {
      unReadNotificationCount = notifications.unreadCount;
      totalNotification = notifications.count;
    }
    return (
      <Fragment>
        <FeedbackForm isOpenModal={this.state.feedbackFormOpen} hideModal={() => {
          this.setState({
            feedbackFormOpen: false,
          });
        }}/>
        <Navbar light expand="md" className={`${this.state.headerScroll ? 'header-shadow' : ''} ${this.state.isOpen ? 'open-nav' : ''}`}>
          <NavbarBrand>
            {!isFirstTime ? <NavLink href="#" onClick={this.moveToDashBoard} >
              <img src={logoImage} alt="brand logo" />
            </NavLink> : <NavLink href="#" >
              <img src={logoImage} alt="brand logo" />
      </NavLink> }
          </NavbarBrand>
          <NavbarToggler onClick={this.sidebarToggle} />
          <NavbarToggler className="close-sm" onClick={this.sidebarToggle} />
          <Nav className="ml-auto" navbar>
            <UncontrolledDropdown nav inNavbar className="notification-dropdown">
              <DropdownToggle nav onClick={this.markAsRead}>
                <span className="icon icon-notification-icon"><em>{unReadNotificationCount}</em></span>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  <div className="notification-head" onClick={this.moveToNotification}>
                    All Notifications <span>{totalNotification}</span>
                  </div>
                </DropdownItem>
                <div className="notification-col">
                  {this.renderNotificationListing()}
                </div>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar className="profile-nav-item">
              <DropdownToggle nav caret className="profile-dropdown">
                <span className="navbar-profile">
                  <img src={this.props.user.profileImage || profileImage} className="img-cover" alt="profile" />
                </span>
                <em className={`text-truncate ${firstName ? ' text-capitalize' : ''}`}>{firstName ? `${firstName} ${lastName}` : email}</em>
              </DropdownToggle>
              <DropdownMenu right>
                <div className="dropdown-user-btn">
                  <span className="nav-navbar-profile">
                    <img src={this.props.user.profileImage || profileImage} className="img-cover" alt="profile" />
                  </span>
                  <div className="nav-profile-details">
                    <em className={`text-truncate ${firstName ? ' text-capitalize' : ''}`}>{firstName ? `${firstName} ${lastName}` : email}</em>
                  </div>
                </div>
                {
                  (role === staticConstants.ROLE.ORGANIZATION_LEARNER ||
                    (role === staticConstants.ROLE.EXPERT) ||
                     role === staticConstants.ROLE.PROFESSIONAL ||
                     role === staticConstants.ROLE.SYSTEM_ADMIN ||
                     (role === staticConstants.ROLE.ORGANIZATION_ADMIN)) &&

                  <DropdownItem onClick={this.moveToProfile} tag="a">
                    <span className="icon icon-visible"/> View Profile
                  </DropdownItem>
                }
                 <DropdownItem className="home-btn"
                  onClick={this.home} tag="a">
                  <span className="icon icon-home" /> Home
                  </DropdownItem>
                <DropdownItem className="signout-btn"
                  onClick={this.logout} tag="a">
                  <span className="icon icon-logout-icon" /> Sign Out
                  </DropdownItem>
              </DropdownMenu>
           </UncontrolledDropdown>
          </Nav>
        </Navbar>
      </Fragment >
    );
  }
}

function mapStateToProps(state: { authentication: { user: any }; notification: any; updateProfile: any }) {
  const { user } = state.authentication;
  const { profile, feedBackCount } = state.updateProfile;
  const notifications = state.notification;
  const localUser = getLocalUserData();
  if (localUser) {
    if (user['profileImage'] !== localUser['profileImage']) {
      user['profileImage'] = localUser['profileImage'];
    }
    user['isPaid'] = localUser['isPaid'];
    return {
      user,
      notifications,
      profile,
      feedBackCount,
    };
  }
  return {};

}

const connectedHeader = connect(mapStateToProps)(Header);
export { connectedHeader as Header };
