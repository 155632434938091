import { IPayment } from '../../../../interfaces';
import { paymentService } from './../../../../services/paymentServices';
import { alertActions } from './../../../alert/redux/actions';
import { endLoading, startLoading } from './../../../loader/redux/actions';
import { actionTypes } from './types';
import OpenFieldTypes from 'OpenFieldTypes';
import { userServices } from '../../../../services';
import { goalAction } from './../../goalManagement/redux/actions';
import { checkLocalStorageGoal } from './../../../../utils';

export const paymentAction = {
  payment,
  paymentWithExistingCard,
  resetState,
  getProratedInvoiceAmount,
  getTaxRate,
  resetTaxRate,
};

function payment(pay: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(pay));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    paymentService.payment(pay)
            .then(
                (res) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(success(res));
                  checkLocalStorageGoal(dispatch, goalAction.createGoal);
                },
                (error) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.errorAlert(error.toString()));
                },
            );
  };
  function success(res: IPayment) { return { type: actionTypes.PAYMENT_SUCCESS, res }; }
  function request(res: IPayment) { return { type: actionTypes.PAYMENT_REQUEST, res }; }
  function failure(error: string) { return { type: actionTypes.PAYMENT_FAILURE, error }; }
}

function paymentWithExistingCard(pay: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(request(pay));
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    paymentService.paymentWithExistingCard(pay)
            .then(
                (res) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(success(res));
                },
                (error) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.errorAlert(error));
                },
            );
  };
  function success(res: IPayment) { return { type: actionTypes.PAYMENT_SUCCESS, res }; }
  function request(res: IPayment) { return { type: actionTypes.PAYMENT_REQUEST, res }; }
  function failure(error: string) { return { type: actionTypes.PAYMENT_FAILURE, error }; }
}

function getProratedInvoiceAmount(paymentData: { quantity: number; isAddOn: boolean }) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.getProratedInvoiceAmount(paymentData)
            .then(
                (res) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(success(res));
                },
                (error) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.errorAlert(error.toString()));
                },
            );
  };
  function success(data: any) { return { type: actionTypes.GET_PRORATED_AMOUNT_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.GET_PRORATED_AMOUNT_FAIULURE, error }; }
}
function resetState() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch({ type: actionTypes.RESET_STATE });
  };
}

function getTaxRate(postalCode: string, countryCode: string) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request(postalCode));
    paymentService.getTaxRate(postalCode, countryCode)
            .then(
                (res) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(success(res));
                },
                (error) => {
                  dispatch(endLoading('MAIN_LOADER'));
                  dispatch(failure(error.toString()));
                  dispatch(alertActions.errorAlert(error.toString()));
                },
            );
  };
  function success(data: any) { return { type: actionTypes.GET_TAX_RATE_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.GET_TAX_RATE_FAIULURE, error }; }
  function request(res: string) { return { type: actionTypes.GET_PRORATED_AMOUNT_REQUEST, res }; }
}

function resetTaxRate() {
  return success(0);
  function success(data: any) { return { type: actionTypes.RESET_TAX_RATE, data }; }
}
