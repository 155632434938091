import React from 'react';
import { connect } from 'react-redux';
import { FilterComponent } from '../../../shared/filter/filterComponent';
import {
  staticConstants, getUpdatedOptions,
  goalStatusTypes, goalStatus, getSelectedValue,
  MAGIC_NUMBER,
} from '../../../../utils';
import { ListingContainer } from '../../../shared/listingManagement/listingContainer';
import { RequestTypeTab } from '../../../shared/goalManagement/listings/requestTypeTab';
import { pageRoutes } from '../../../../utils/routeConstants';
import { goalAction } from '../../../shared/goalManagement/redux/actions';
import { tagAction } from '../../../shared/tags/redux/actions';

interface IProps {
  activeTab: any;
  goals: any;
  userId?: any;
  tableContent?: any;
  getGoals: (data: object) => void;
  getDomains: (data: object) => void;
  resetAllTags: () => void;
}

const goalContent = {
  title: { name: 'Title', class: 'table-col-17', sort: true, path: `${pageRoutes.GOALMANAGEMENT.PATH}${pageRoutes.GOALMANAGEMENT.DETAIL}` },
  eventType: { name: 'Event Type', class: 'table-col-15', sort: true },
  domain: { name: 'Domain', class: 'table-col-15', sort: true },
  createdAt: { name: 'Goal Created', class: 'table-col-15', sort: true },
  totalParticipants: { name: 'Participants', class: 'table-col-15 text-center', sort: true },
  createdBy: { name: 'Created By', class: 'table-col-13', sort: true },
  status: { name: 'Status', class: 'table-col-10', sort: true },
};
const newRequest = {
  title: { name: 'Title', class: 'table-col-17', sort: true, path: `${pageRoutes.GOALMANAGEMENT.PATH}${pageRoutes.GOALMANAGEMENT.DETAIL}` },
  eventType: { name: 'Event Type', class: 'table-col-10', sort: true },
  domain: { name: 'Domain', class: 'table-col-15', sort: true },
  createdAt: { name: 'Goal Created', class: 'table-col-12', sort: true },
  totalParticipants: { name: 'Participants', class: 'table-col-13 text-center', sort: true },
  createdBy: { name: 'Created By', class: 'table-col-13', sort: true },
  senTo: { name: 'Sent To', class: 'table-col-10', sort: true },
  status: { name: 'Status', class: 'table-col-10', sort: true },
};
const acceptedRequest = {
  title: { name: 'Title', class: 'table-col-17', sort: true, path: `${pageRoutes.GOALMANAGEMENT.PATH}${pageRoutes.GOALMANAGEMENT.DETAIL}` },
  eventType: { name: 'Event Type', class: 'table-col-10', sort: true },
  domain: { name: 'Domain', class: 'table-col-15', sort: true },
  acceptedAt: { name: 'Goal Accepted', class: 'table-col-12', sort: true },
  totalParticipants: { name: 'Participants', class: 'table-col-13 text-center', sort: true },
  createdBy: { name: 'Created By', class: 'table-col-13', sort: true },
  acceptedBy: { name: 'Accepted By', class: 'table-col-10', sort: true },
  status: { name: 'Status', class: 'table-col-10', sort: true },
};
const rejectedRequest = {
  title: { name: 'Title', class: 'table-col-17', sort: true, path: `${pageRoutes.GOALMANAGEMENT.PATH}${pageRoutes.GOALMANAGEMENT.DETAIL}` },
  eventType: { name: 'Event Type', class: 'table-col-15', sort: true },
  domain: { name: 'Domain', class: 'table-col-12', sort: true },
  rejectedAt: { name: 'Goal Rejected', class: 'table-col-15', sort: true },
  totalParticipants: { name: 'Participants', class: 'table-col-15 text-center', sort: true },
  createdBy: { name: 'Created By', class: 'table-col-13', sort: true },
  rejecteddBy: { name: 'Rejected By', class: 'table-col-13', sort: true },
};

class LearningGoal extends React.Component<IProps> {

  /**
   * @description getDerivedStateFromProps is called when there in change in property
   * @param fields {Object} props
   */
  public static getDerivedStateFromProps(props: any, state: any) {
    const updateState = {};
    if (props.activeTab !== state.activeTab) {
      const tableContent = props.activeTab === staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.LERNER_GOAL_REQUESTS
        ? newRequest : props.tableContent ? props.tableContent : goalContent;
      updateState['activeTab'] = props.activeTab;
      updateState['tableContent'] = tableContent;
      updateState['fetchGoal'] = true;
      updateState['activePage'] = 1;
      updateState['sort'] = '';
      updateState['sortBy'] = 0;
      updateState['title'] = '';
    }
    if (props.goals && props.goals.list) {
      updateState['goals'] = props.goals;
    }
    return updateState;
  }

  public state = {
    activePage: 1,
    items: MAGIC_NUMBER.TEN,
    goals: {
      count: 0,
      list: [],
      fetchedListing: false,
    },
    goalStatus: goalStatus.NEW_REQUEST,
    tableContent: (this.props && this.props.tableContent) ? this.props.tableContent : goalContent,
    activeTab: this.props.activeTab,
    sort: '',
    sortBy: 0,
    title: '',
    eventType: null,
    domain: null,
    status: null,
    fetchGoal: false,
  };

  /**
   * @description componentDidMount is called when component is loaded
   * call goalAction.getGoals to fetch goal listings
   * call goalAction.getGoals to fetch goal categories
   */
  public componentDidMount() {
    const { items, activePage } = this.state;
    const reqObj = {
      page: Number(activePage),
      limit: items,
      filter: this.state.activeTab,
      userId: this.props.userId,
    };
    if (this.state.activeTab === staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.LERNER_GOAL_REQUESTS) {
      reqObj['goalStatus'] = this.state.goalStatus;
    }
    // this.props.getDomains('', {});
    this.props.getGoals(reqObj);
  }

  /**
   * @description componentDidUpdate is called when we need to fetch listing again.
   * call goalAction.getGoals to fetch goal listings
   */
  public componentDidUpdate() {
    if (this.state.fetchGoal) {
      this.fetchGoalsData(this.state.activePage);
      this.setState({ fetchGoal: false });
    }
  }
  /**
   * @description componentWillUnmount is used when component destroyed
   * call tagAction.resetAllTags() to reset the tags
   */
  public componentWillUnmount() {
    this.props.resetAllTags();
  }

  /**
   * @description fetchGoalsData is used on initial load
   */
  public fetchGoalsData(pageno: number) {
    const { sort, sortBy } = this.state;
    let reqObj = {
      page: pageno,
      limit: this.state.items,
      eventType: getSelectedValue(this.state.eventType),
      domain: getSelectedValue(this.state.domain),
      status: getSelectedValue(this.state.status),
      title: this.state.title,
      filter: this.state.activeTab,
      userId: this.props.userId,
    };
    if (this.state.activeTab === staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.LERNER_GOAL_REQUESTS) {
      reqObj['goalStatus'] = this.state.goalStatus;
    }
    if (sort) {
      reqObj = { ...reqObj, ...{ sort, sortBy } };
    }
    this.props.getGoals(reqObj);
  }

  /**
   * @description
   * handleChange is used to set the value on state from the input.
   * @param fields {Object} || {String} value
   * @param fields {String} key
   */
  public handleChange = (value: any, key: string) => {
    this.setState({ ...this.state, [key]: value },
                  () => { this.fetchGoalsData(1); });
  }

  /**
   * @description
   * sortData is used for sorting purpose.
   * @param fields {Number} sort
   * @param fields {String} sortBy
   */
  public sortData = (sort: any, sortBy: any) => {
    const { activePage } = this.state;
    this.setState({ ...this.state, sort, sortBy },
                  () => this.fetchGoalsData(activePage));
  }

  /**
   * @description
   * handlePageChange is called someone click on the pagination.
   * @param fields {Number} pageno
   */
  public handlePageChange = (pageno: number) => {
    this.setState({
      activePage: pageno,
    },            () => {
      this.fetchGoalsData(pageno);
    });
  }

  /**
   * @description
   * changeRequestType is called when some click on the child tab.
   */
  public changeRequestType = (learnerGoalStatus: any) => {
    let tableContent;
    switch (learnerGoalStatus) {
      case goalStatus.NEW_REQUEST:
        tableContent = newRequest;
        break;
      case goalStatus.ACCEPT:
        tableContent = acceptedRequest;
        break;
      case goalStatus.REJECT:
        tableContent = rejectedRequest;
        break;
      default:
        break;
    }
    this.setState({
      goalStatus: learnerGoalStatus,
      tableContent,
      title: '',
    },            () => {
      this.fetchGoalsData(1);
    });
  }

  /**
   * @description
   * render is used to render the html.
   */
  public render() {
    const filterContent = ['search', 'status', 'eventType', 'domain'];
    const { activeTab } = this.props;
    const { goals, goalStatus, tableContent, activePage, sort, sortBy } = this.state;
    const filterObj = {
      activePage,
      sort,
      sortBy,
    };
    return (
      <React.Fragment>
        <div className="filter-wrapper">
          <FilterComponent filterContent={filterContent} handleFilter={this.handleChange}
            status={goalStatusTypes} activeTab={activeTab} tabChange={goalStatus}/>
        </div>
        {activeTab === staticConstants.ADMIN_GOAL_MANAGEMENT_TAB.LERNER_GOAL_REQUESTS &&
          <RequestTypeTab requestType={goalStatus} changeRequestType={this.changeRequestType} />
        }
        <ListingContainer tableContent={tableContent} listData={goals} filterObj={filterObj}
          handlePageChange={this.handlePageChange} handleSorting={this.sortData} />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  const { tags: { domains } } = state;
  return {
    goals: state.goals,
    domainOptions: getUpdatedOptions(domains, 'name'),
  };
}

const connectedLearningGoal = connect(mapStateToProps, {
  getDomains: tagAction.getDomains,
  getGoals: goalAction.getGoals,
  resetAllTags: tagAction.resetAllTags,
})(LearningGoal);
export { connectedLearningGoal as LearningGoal };
