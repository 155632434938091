import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { systemAdminPaymentAction } from '../redux/actions';
import {
  getSelectedValue,
  tableItemsCount,
  staticConstants,
  userStatusTypes,
  pageRoutes,
  MAGIC_NUMBER,
  CSS_ClASSES,
} from '../../../../utils';
import OpenFieldTypes from 'OpenFieldTypes';
import queryString from 'query-string';
import { Button } from 'reactstrap';
import { LocationDescriptorObject } from 'history';
import { ListingContainer } from '../../../shared/listingManagement/listingContainer';
import { FilterComponent } from '../../../shared/filter/filterComponent';
import { history } from '../../../../helpers';
import PaymentListingContainer from './paymentRequestListing';
import DateFilter from '../../../shared/filter/dateFilter';
interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  location?: LocationDescriptorObject;
  activeTab: string;
  filterTab?: string;
  systemAdminPayment?: Array<[]>;
  successDownloadReport?: boolean;
}

const paymentsHeaderContent = {
  'bidData.id': { name: 'Bid ID', dataLabel: 'Bid ID', class: 'table-col-15', path: `${pageRoutes.EVENTMANAGEMENT.PATH}${pageRoutes.EVENTMANAGEMENT.DETAIL}`, activeTab: 'payments' },
  'goalData.title': { name: 'Title', dataLabel: 'Title', class: 'table-col-10', sort: true },
  expertName: { name: 'Expert Name', dataLabel: 'Expert Name', class: 'table-col-10', sort: true },
  'senderData.organizationName': { name: 'Participant Name', dataLabel: 'Participant Name', class: 'table-col-15', sort: true,
    path: `${pageRoutes.EVENTMANAGEMENT.PATH}${pageRoutes.EVENTMANAGEMENT.DETAIL}` },
  'bidData.endedAt': { name: 'Event Completed', dataLabel: 'Event Completed', class: 'table-col-15 text-center', sort: true },
  'paymentdata.bidAccepted': { name: 'Bid Accepted', dataLabel: 'Bid Accepted', class: 'table-col-15 text-center', sort: true },
  country: { name: 'Country', dataLabel: 'Country', class: 'table-col-15 text-center', sort: true },
  sessionFee: { name: 'Session Fee', dataLabel: 'Amount', class: 'table-col-10', sort: true },
  expertFee: { name: 'Expert Fee', dataLabel: 'Expert Fee', class: 'table-col-10', sort: true },
  openfieldCommissionAmt: { name: 'Overlap Commission', dataLabel: 'Overlap Commission', class: 'table-col-15', sort: true },
  taxAmount: { name: 'Tax', dataLabel: 'Tax', class: 'table-col-15' },
};

const refundFeeHeaderContent = {
  'bidData.id': { name: 'Bid ID', dataLabel: 'Bid ID', class: 'table-col-20', path: `${pageRoutes.EVENTMANAGEMENT.PATH}${pageRoutes.EVENTMANAGEMENT.DETAIL}`, activeTab: 'payments' },
  'goalData.title': { name: 'Title', dataLabel: 'Title', class: 'table-col-15', sort: true },
  expertName: { name: 'Expert Name', dataLabel: 'Expert Name', class: 'table-col-15', sort: true },
  'senderData.organizationName': { name: 'Participant Name', dataLabel: 'Participant Name', class: 'table-col-20', sort: true,
    path: `${pageRoutes.EVENTMANAGEMENT.PATH}${pageRoutes.EVENTMANAGEMENT.DETAIL}` },
  'bidData.endedAt': { name: 'Event Completed', dataLabel: 'Event Completed', class: 'table-col-15 text-center', sort: true },
  amount: { name: 'Session Fee', dataLabel: 'Amount', class: 'table-col-15', sort: true },
};

const subscriptionFeeHeaderContent = {
  invoice: { name: 'Transaction ID', dataLabel: 'Transaction ID', class: 'table-col-15', sort: true },
  subscriptionId: { name: 'Subscription ID', dataLabel: 'Subscription ID', class: 'table-col-15', sort: true },
  createdAt: { name: 'Date of Transaction', dataLabel: 'Date of Transaction', class: 'table-col-15', sort: true },
  orgName: { name: 'Name', dataLabel: 'Name', class: 'table-col-15', sort: true },
  'senderData.email': { name: 'Email ID', dataLabel: 'Email ID', class: 'table-col-12', sort: true },
  quantity: { name: 'No. of Licences', dataLabel: 'No. of Licences', class: 'table-col-13 text-center', sort: true },
  amount: { name: 'Subscription Fee', dataLabel: 'Subscription Fee', class: 'table-col-15 text-center', sort: true },
};

const professionalFeeHeaderContent = {
  invoice: { name: 'Transaction ID', dataLabel: 'Transaction ID', class: 'table-col-20', sort: true },
  subscriptionId: { name: 'Subscription ID', dataLabel: 'Subscription ID', class: 'table-col-20', sort: true },
  createdAt: { name: 'Date of Transaction', dataLabel: 'Date of Transaction', class: 'table-col-15', sort: true },
  orgName: { name: 'Name', dataLabel: 'Name', class: 'table-col-15', sort: true },
  'senderData.email': { name: 'Email ID', dataLabel: 'Email ID', class: 'table-col-15', sort: true },
  amount: { name: 'Subscription Fee', dataLabel: 'Subscription Fee', class: 'table-col-15 text-center', sort: true },
};

const addOnFeeHeaderContent = {
  invoice: { name: 'Transaction ID', dataLabel: 'Transaction ID', class: 'table-col-15', sort: true },
  subscriptionId: { name: 'Subscription ID', dataLabel: 'Subscription ID', class: 'table-col-15', sort: true },
  createdAt: { name: 'Date of Transaction', dataLabel: 'Date of Transaction', class: 'table-col-15', sort: true },
  orgName: { name: 'Name', dataLabel: 'Name', class: 'table-col-15', sort: true },
  'senderData.email': { name: 'Email ID', dataLabel: 'Email ID', class: 'table-col-15', sort: true },
  userType: { name: 'User Type', dataLabel: 'User Type', class: 'table-col-15', sort: true },
  addOn: { name: 'Add-On', dataLabel: 'Add-On', class: 'table-col-10 text-center' },
};

const headerContentMappings = {
  payments: paymentsHeaderContent,
  paymentTransferred: paymentsHeaderContent,
  subscription: subscriptionFeeHeaderContent,
  professional: professionalFeeHeaderContent,
  orgAdminRefundDispute: paymentsHeaderContent,
  addOn: addOnFeeHeaderContent,
  disputed: { action: { name: 'Action', class: 'table-col-5 text-center', actionType: 'checkbox', type: 'payment' },
    ...paymentsHeaderContent },
  refundTransfer: paymentsHeaderContent,
};

class PaymentsListingContainer extends React.Component<IProps> {

  /**
   * @description getDerivedStateFromProps is called when there in change in property
   * @param {Object} props
   */
  public static getDerivedStateFromProps(props: IProps, state: any) {
    const updateState = {};
    updateState['fetchGenerateReport'] = false;
    if (state.filterTab && state.filterTab === staticConstants.PAYMENTS_TYPE.REFUNDED_PAYMENTS) {
      updateState['tableContent'] = headerContentMappings[state.filterTab];
    }
    if (state.filterTab && state.filterTab === staticConstants.USER_MANAGEMENT_TAB.PROFESSIONAL) {
      updateState['tableContent'] = headerContentMappings[state.filterTab];
    }
    if (state.filterTab && (state.filterTab === staticConstants.PAYMENTS_TYPE.RELEASED_PAYMENTS ||
      state.filterTab === staticConstants.USER_MANAGEMENT_TAB.ORG_ADMIN)) {
      updateState['tableContent'] = headerContentMappings[props.activeTab];
    }
    if (props.systemAdminPayment && props.systemAdminPayment['successDownloadReport']) {
      updateState['downloadUrl'] = props.systemAdminPayment['downloadUrl'];
      updateState['downloadFileName'] = props.systemAdminPayment['downloadFileName'];
      updateState['fetchGenerateReport'] = true;
    }
    if (props.activeTab !== state.activeTab) {
      updateState['tableContent'] = props.activeTab.includes('pending')
        ? headerContentMappings['payments']
        : headerContentMappings[props.activeTab];
      updateState['activeTab'] = props.activeTab;
      if (props.activeTab === staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.RELEASED_REFUNDED_PAYMENTS) {
        updateState['activeSubTab'] = state.activeSubTab || staticConstants.PAYMENTS_TYPE.RELEASED_PAYMENTS;
        updateState['filterTab'] = staticConstants.PAYMENTS_TYPE.RELEASED_PAYMENTS;
      }
      if (props.activeTab === staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.SUBSCRIPTION_FEE) {
        updateState['activeSubTab'] = state.activeSubTab || staticConstants.USER_MANAGEMENT_TAB.ORG_ADMIN;
        updateState['filterTab'] = staticConstants.USER_MANAGEMENT_TAB.ORG_ADMIN;
      }
      if (props.activeTab !== staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.SUBSCRIPTION_FEE && props.activeTab !== staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.RELEASED_REFUNDED_PAYMENTS) {
        updateState['filterTab'] = '';
      }
      updateState['fetchPayments'] = true;
      updateState['title'] = '';
      updateState['activePage'] = 1;
    }
    if (props.systemAdminPayment && props.systemAdminPayment['list']) {
      updateState['payments'] = props.systemAdminPayment;
    }
    if (props.systemAdminPayment && props.systemAdminPayment['updateDisputeStatus']) {
      updateState['fetchPayments'] = true;
    }
    return updateState;
  }

  public selectedBidId = [];

  public state = {
    selectedBidId: [],
    activePage: 1,
    title: '',
    items: tableItemsCount,
    category: null,
    domain: null,
    eventType: null,
    sort: '',
    sortBy: 0,
    payments: {
      count: 0,
      list: [],
      fetchedUsersListing: false,
    },
    reqObj: {},
    activeTab: staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.PENDING_PAYMENTS,
    activeSubTab: '',
    filterTab: '',
    tableContent: paymentsHeaderContent,
    downloadUrl: undefined,
    downloadFileName: undefined,
    fetchGenerateReport: false,
    fetchPayments: false,
    startDate: null,
    endDate: null,
  };

  /**
   * @description componentDidMount is called when component is loaded
   * call userAction.getUsers to fetch goal listings
   * call userAction.getUsers to fetch goal categories
   */
  public componentDidMount() {
    const { items, activePage } = this.state;
    const reqObj = {
      page: Number(activePage),
      limit: items,
    };
    const updateReqObj = this.updateReqObj(reqObj);
    this.props.dispatch(systemAdminPaymentAction.getTotalPayments());
    this.props.dispatch(systemAdminPaymentAction.getPaymentsByType(updateReqObj));
  }

  /**
   * @description Runs before React applies the result of `render` to the document
   * @param prevProps
   */
  public getSnapshotBeforeUpdate(prevProps: IProps) {
    if (prevProps.activeTab !== this.state.activeTab) return true;
    return null;
  }

  /**
   * @description componentDidUpdate is called when we need to fetch listing again.
   * call goalAction.getGoals to fetch goal listings
   */
  public componentDidUpdate(prevProps: IProps, prevState, snapshot) {
    if (snapshot && prevState.activeSubTab) this.setState({ activeSubTab: '' });
    if (this.state.fetchPayments) {
      const reqObj = {
        page: Number(this.state.activePage),
        limit: this.state.items,
      };
      const updateReqObj = this.updateReqObj(reqObj);
      this.props.dispatch(systemAdminPaymentAction.getPaymentsByType(updateReqObj));
      this.setState({ fetchPayments: false });
    }
    if (this.state.fetchGenerateReport) {
      this.downloadReport();
    }
  }

  /**
   * @description updateReqObj to update object before sending to the server
   */
  public updateReqObj(reqObj: any) {
    reqObj.type = this.state.activeTab;
    reqObj.title = this.state.title;
    reqObj.eventType = getSelectedValue(this.state.eventType);
    reqObj.domain = getSelectedValue(this.state.domain);
    reqObj.filter = this.state.filterTab;
    reqObj.startDate = this.state.startDate;
    reqObj.endDate = this.state.endDate;
    return reqObj;
  }

  public getReqObj(pageno: number) {
    const { sort, sortBy, startDate, endDate } = this.state;
    let reqObj = {
      page: Number(pageno),
      limit: this.state.items,
    };
    if (sort) {
      reqObj = { ...reqObj, ...{ sort, sortBy, startDate, endDate } };
    }
    return this.updateReqObj(reqObj);
  }
  /**
   * @description fetchPayments is used on initial load
   */
  public fetchPayments(pageno: number) {
    this.props.dispatch(systemAdminPaymentAction.getPaymentsByType(this.getReqObj(pageno)));
  }

  /**
   * @description
   * handleChange is used to set the value on state from the input.
   * @param fields {Object} || {String} value
   * @param fields {String} key
   */
  public handleChange = (value: any, key: string) => {
    const { location: { search } } = this.props;
    const query = queryString.parse(search);
    query['page'] = '1';
    history.replace({ search: queryString.stringify(query) });
    this.setState({ ...this.state, [key]: value, activePage: 1 }, () => this.fetchPayments(1));
  }

  public handleDateFilter = (startDate: Date, endDate: Date) => {
    const { location: { search } } = this.props;
    const query = queryString.parse(search);
    query['page'] = '1';
    history.replace({ search: queryString.stringify(query) });
    this.setState({ ...this.state, startDate, endDate, activePage: 1 }, () => this.fetchPayments(1));
  }

  /**
   * @description
   * handlePageChange is called someone click on the pagination.
   * @param fields {Number} pageno
   */
  public handlePageChange = (pageno: number) => {
    const { location: { search } } = this.props;
    this.setState({ activePage: pageno }, () => this.fetchPayments(pageno));
    const query = queryString.parse(search);
    query['page'] = String(pageno) || '1';
    history.replace({ search: queryString.stringify(query) });
  }

  /**
   * @description
   * sortData is used for sorting purpose.
   * @param fields {Number} sort
   * @param fields {String} sortBy
   */
  public sortData = (sort: string, sortBy: string) => {
    const { activePage } = this.state;
    this.setState({ ...this.state, sort, sortBy }, () =>
      this.fetchPayments(activePage),
    );
  }

  /**
   * @description
   * handleSubTabs is called when some click on the child tab.
   */
  public handleSubTabs = (activeSubTab: string) => {
    this.setState({ filterTab: activeSubTab, title: '', eventType: '', category: '', activeSubTab, activePage: 1 }, () => {
      this.fetchPayments(1);
    });
  }

  /**
   * @description
   * updateFilterContent is used to update add filters according to different tabs
   */
  public updateFilterContent = () => {
    const { activeTab } = this.state;
    switch (activeTab) {
      case staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.PENDING_PAYMENTS:
      case staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.RECEIVED_PAYMENTS:
      case staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.DISPUTE_PAYMENTS:
      case staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.RELEASED_REFUNDED_PAYMENTS:
        return ['search', 'domain', 'participant'];
      case staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.ADD_ON_FEE:
        return ['search', 'userType'];
      default:
        return ['search'];
    }
  }

  /**
   * @description
   * updateGoalIdForPayment is used to update goal id selection for payments
   * @param fields {Object} goal
   * @param fields {Object} event
   */
  public updateGoalIdForPayment = (goal: any, event) => {
    const isChecked = event.target.checked;
    this.updateSelectGoalId(goal, isChecked);
  }

   /*
   * @description
   * updateSelectGoalId is used to select goals
   * @param {object} user : containing details of current selected user
   * @param {boolean} isChecked status of current selected user
   */
  public updateSelectGoalId = (goal: any, isChecked: boolean) => {
    this.selectedBidId = this.selectedBidId.filter(member => member !== goal.bidData._id);
    if (isChecked) this.selectedBidId.push(goal.bidData._id);
    this.setState({ selectedBidId: this.selectedBidId });
  }

  /**
   * @description
   * updatePaymentStatus is used to hit an api on the selection of payment refunded and payment released buttons
   * @param fields type
   */
  public updatePaymentStatus = (type: string) => {
    const status = type === staticConstants.PAYMENTS_TYPE.REFUNDED_PAYMENTS ?
    staticConstants.PAYMENTS_TYPE.REFUNDED_PAYMENTS :
    staticConstants.PAYMENTS_TYPE.RELEASED_PAYMENTS;
    const reqObj = {
      bidId: this.selectedBidId,
      status,
    };
    this.selectedBidId.length > MAGIC_NUMBER.ZERO &&
    this.props.dispatch(systemAdminPaymentAction.updateDisputeStatus(reqObj, this.getReqObj(this.state.activePage)));
    this.selectedBidId = [];
  }

   /*
   * @description
   * generateReport is used to get the generate report
   */
  public generateReport = (title: string) => {
    const tableContent = {
      ...this.state.tableContent,
    };
    delete tableContent['isDelete'];
    const columnHeaders = [];
    const columnKeys = [];
    for (const [key, value] of Object.entries(tableContent)) {
      columnHeaders.push(value.name);
      columnKeys.push(key);
    }
    this.props.dispatch(systemAdminPaymentAction.generateReport({
      columnHeaders,
      columnKeys,
      reportTitle: `${title}.csv`,
      type: this.state.activeTab,
      filter: this.state.filterTab,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      isGenerateReport: true,
    }));
  }

  public downloadReport = () => {
    this.props.dispatch(systemAdminPaymentAction.resetGenerateReport());
    const filePath = this.state.downloadUrl;
    const a = document.createElement('a');
    a.setAttribute('href', `${filePath}`);
    a.setAttribute('download', this.state.downloadFileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  /**
   * @description
   * render is used to render HTML
   */
  public render() {
    const filterContent = this.updateFilterContent(); 
    const filterObj = {
      activePage: this.state.activePage,
      sort: this.state.sort,
      sortBy: this.state.sortBy,
    };
    const { tableContent, payments, activeTab, filterTab, selectedBidId } = this.state;
    const classVarArr = ['table-head'];
    const classes = classVarArr.join(' ');
    return (
      <React.Fragment>
       
        <div className="filter-datepicker">
        <DateFilter handleDateFilter={this.handleDateFilter}/>
        </div>
       {activeTab !== staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.PAYMENT_REQUEST &&  <div className="filter-wrapper">
          <FilterComponent
            filterContent={filterContent}
            handleFilter={this.handleChange}
            placeholder="Search by Keywords, Name"
            status={userStatusTypes}
            activeTab={activeTab}
          />
            <Button className={`btn btn-primary round-circle generate-report-btn
              ${activeTab === staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.SUBSCRIPTION_FEE ||
              activeTab === staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.ADD_ON_FEE ? 'ml-auto' : ''}`}
              onClick={() => this.generateReport(activeTab)}>
              Generate Report
            </Button>
          </div> }
        {activeTab === staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.DISPUTE_PAYMENTS &&
          <div className="float-right mb-4">
            <Button className="btn btn-primary round-circle mr-2" onClick={() => this.updatePaymentStatus(staticConstants.PAYMENTS_TYPE.REFUNDED_PAYMENTS)}>
              Payment Refunded
            </Button>
            <Button className="btn btn-primary round-circle ml-2" onClick={() => this.updatePaymentStatus(staticConstants.PAYMENTS_TYPE.RELEASED_PAYMENTS)}>
              Payment Released
            </Button>
          </div>
        }
        {/* Internal tabs starts */}
        {activeTab === staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.RELEASED_REFUNDED_PAYMENTS &&
          <div className={`tab-btn-group multi-tab-buttons`}>
            <Button
              color="secondary"
              className={`btn h-50 tab-btn text-uppercase ${filterTab === staticConstants.PAYMENTS_TYPE.RELEASED_PAYMENTS ? CSS_ClASSES.btnActive : ''}`}
              onClick={() => this.handleSubTabs(staticConstants.PAYMENTS_TYPE.RELEASED_PAYMENTS)}
            >
              Released Payments
            </Button>
            <Button
              color="secondary"
              className={`btn h-50 tab-btn text-uppercase ${filterTab === staticConstants.PAYMENTS_TYPE.REFUNDED_PAYMENTS ? CSS_ClASSES.btnActive : ''}`}
              onClick={() => this.handleSubTabs(staticConstants.PAYMENTS_TYPE.REFUNDED_PAYMENTS)}
            >
              Refunded Payments
            </Button>
          </div>
        }
        {activeTab === staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.SUBSCRIPTION_FEE &&
          <div className={`tab-btn-group multi-tab-buttons`}>
            <Button
              color="secondary"
              className={`btn h-50 tab-btn text-uppercase ${filterTab === staticConstants.USER_MANAGEMENT_TAB.ORG_ADMIN ? CSS_ClASSES.btnActive : ''}`}
              onClick={() => this.handleSubTabs(staticConstants.USER_MANAGEMENT_TAB.ORG_ADMIN)}
            >
              Team Admin
            </Button>
            <Button
              color="secondary"
              className={`btn h-50 tab-btn text-uppercase ${filterTab === staticConstants.USER_MANAGEMENT_TAB.PROFESSIONAL ? CSS_ClASSES.btnActive : ''}`}
              onClick={() => this.handleSubTabs(staticConstants.USER_MANAGEMENT_TAB.PROFESSIONAL)}
            >
              Individual
            </Button>
          </div>
        }

        {activeTab === staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.PAYMENT_REQUEST &&
        <>
        <PaymentListingContainer {...this.props} />
        </>
        }
        {/* *** Internal Tabs End *** */}

       {activeTab !== staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.PAYMENT_REQUEST && <ListingContainer
          tableClass={[staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.PAYMENT_REQUEST, staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.PENDING_PAYMENTS, staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.DISPUTE_PAYMENTS,
            staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.RECEIVED_PAYMENTS, 
            staticConstants.SYSTEM_ADMIN_PAYMENT_TAB.RELEASED_REFUNDED_PAYMENTS].indexOf(activeTab) > -1 ? 'fixed-table-wrapper': ''}
          tableContent={tableContent}
          listData={payments}
          filterObj={filterObj}
          tableHeaderClasses={classes}
          handlePageChange={this.handlePageChange}
          handleSorting={this.sortData}
          updateStatus={this.updateGoalIdForPayment}
          selectedUsers={selectedBidId}
          activeSubTab={this.state.activeSubTab}
          activeTab={this.state.activeTab}
          parentTab={staticConstants.PARENT_PAYMENT_TAB}
       /> }
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  const { systemAdminPayment } = state;
  return {
    systemAdminPayment,
  };
}

const connectedPaymentsListingContainer = connect(mapStateToProps)(PaymentsListingContainer);
export { connectedPaymentsListingContainer as PaymentListingContainer };
