import _ from 'lodash';
import moment from 'moment';
import React, { Dispatch } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Select, { createFilter } from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  NavLink,
} from 'reactstrap';
import ReeValidate from 'ree-validate';
import {
  eventTypes, staticConstants, pageRoutes, getUpdatedOptions, updateSelectedOptions,
  revertSelectedOptions, reactSelectFilters, getSelectedValue, goalStatus,
  reeValidators, GOAL_BID_COUNT_STATUS, MAGIC_NUMBER,
} from '../../../../utils';
import { customSelectStyles, restrictSpaceEnter } from './../../../../utils/common';
import { Header } from '../../header';
import { Sidebar } from '../../sidebar';
import profileImage from './../../../../assets/images/profile.png';
import searchIconImg from './../../../../assets/images/icons/search-icon.svg';
import { alertActions } from './../../../alert/redux/actions';
import { profileAction } from './../../../shared/updateProfile/redux/actions';
import { learnerAction } from '../../learnerManagement/redux/actions';
import { tagAction } from './../../tags/redux/actions';
import { goalAction } from './../redux/actions';
import { IValidator, IParams, IGoalDetail } from './../../../../interfaces';
import OpenFieldTypes from 'OpenFieldTypes';
import { Back } from '../../back/back';
import { setHours, setMinutes } from 'date-fns';
import { history } from '../../../../helpers';
import BrandingLogo from '../../header/BrandingLogo';
import { SearchTags } from './../../../shared/searchTags/searchTags';
 

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  domainOptions: any;
  subDomainOptions: any;
  categoryOptions: any;
  subCategoryOptions: any;
  topicOptions: any;
  activityOptions: any;
  role: any;
  selUserId: any;
  users: any;
  licence: any;
  goals: any;
  match: {
    params: {
      [key: string]: IParams;
    };
  };
  goalDetails: IGoalDetail;
  successForGoalDetails: boolean;
  profile: any;
  totalExpert: number;
  taxRate: number;
  loggedIn: any;
}

interface IState {
  errors?: any;
  formData: {
    title: string;
    eventType: string;
    // timeZone: string;
    modeOfDevivery: string;
    description: string;
    fromDate: any;
    toDate: any;
    fromTime: any;
    toTime: any;
    isReviewTags: boolean;
    selectedHours: any;
    selectedMinuts: any;
  };
  isErrorsOnDate: any;
  isErrorsOnTime: any;
  selectedOption: any;
  eventOptions: any;
  selectedEventType: any;
  selectedDomain: any;
  selectedSubDomain: any;
  selectedCategory: any;
  selectedSubCategory: any;
  selectedTopic: any;
  selectMinFromDate: any;
  selectMinToDate: any;
  selectedDuration: any;
  isSubmitted: boolean;
  openModalToAddParticipaints: boolean;
  openModalToAddAdmin: boolean;
  modeOfDevivery: any;
  renderParticipantsList: any;
  participantsListInModal: any;
  addedParticipantsListInModal: any;
  isMe: boolean;
  hourOptions: any;
  minutOptions: any;
  goalDetails: any;
  editGoal: boolean;
  from: string;
  btnText: string;
  labelText: string;
  isParticipantAdded: boolean;
  isAdminAdded: boolean;
  minTime?: Date;
  maxTime?: Date;
  isReviewTags: boolean;
  tagsModal: any;
  rehireId?: string;
  rehireOldGoalTitle?: string;
  isEditRequest?: boolean;
  minToDate?: Date;
  maxToDate?: Date;
  allTags: any;
  show: boolean;
  isShowMore: boolean;
}

class CreateGoalContainer extends React.Component<IProps, IState> {

  public validator: IValidator;
  public selectedParticipants: string[] = [];
  public participantsList: string[] = [];
  public selectedParticipantForAdmin: string;

  /**
   * @description
   * constructor is used to define the initial state and property
   * @param fields {Object} props
   */
  constructor(props: IProps) {
    super(props);
    this.validator = new ReeValidate({
      title: reeValidators.required_min_3_max_50,
      eventType: 'required',
      description: 'required',
      // timeZone: reeValidators.required_min_3_max_100,
    });
    this.state = {
      formData: {
        title: '',
        eventType: '',
        description: '',
        fromDate: '',
        toDate: '',
        fromTime: '',
        toTime: '',
        // timeZone: '',
        modeOfDevivery: '',
        isReviewTags: false,
        selectedHours: { label: 0, value: 0 },
        selectedMinuts: { label: 0, value: 0 },
      },
      selectedOption: null,
      selectedEventType: null,
      selectedDomain: null,
      selectedSubDomain: null,
      selectedCategory: null,
      selectedSubCategory: null,
      selectedTopic: null,
      selectedDuration: null,
      eventOptions: [],
      errors: this.validator.errors,
      selectMinFromDate: moment(new Date()).add(1, 'days').toDate(),
      selectMinToDate: moment(new Date()).add(1, 'days').toDate(),
      isErrorsOnDate: undefined,
      isErrorsOnTime: undefined,
      isSubmitted: false,
      modeOfDevivery: '',
      openModalToAddParticipaints: false,
      openModalToAddAdmin: false,
      renderParticipantsList: [],
      participantsListInModal: [],
      addedParticipantsListInModal: [],
      isMe: false,
      hourOptions: _.range(MAGIC_NUMBER.ZERO, MAGIC_NUMBER.FOUR),
      minutOptions: _.range(MAGIC_NUMBER.ZERO, MAGIC_NUMBER.SIXTY, MAGIC_NUMBER.THIRTY),
      from: '',
      btnText: '',
      labelText: '',
      isReviewTags: false,
      goalDetails: {
        bidderId: '',
        participants: [],
        createdAt: '',
        fromDate: '',
        toDate: '',
        fromTime: '',
        toTime: '',
        title: '',
        eventType: '',
        modeOfDevivery: '',
        description: '',
        topic: {
          name: '',
          id: '',
        },
        domain: {
          name: '',
          id: '',
        },
        subDomain: {
          name: '',
          id: '',
        },
        category: {
          name: '',
          id: '',
        },
        subCategory: {
          name: '',
          id: '',
        },
        isReviewTags: false,
      },
      editGoal: false,
      isParticipantAdded: false,
      isAdminAdded: false,
      tagsModal: false,
      rehireId: null,
      rehireOldGoalTitle: null,
      isEditRequest: false,
      minToDate: null,
      allTags: [],
      show:false,
      isShowMore:false,
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleShowMoreLess = this.handleShowMoreLess.bind(this);
    this.openParticipaintsModal = this.openParticipaintsModal.bind(this);
    this.hideParticipaintsModal = this.hideParticipaintsModal.bind(this);
    this.addParticipaints = this.addParticipaints.bind(this);
    this.handleParticipant = this.handleParticipant.bind(this);
    this.handleAdmin = this.handleAdmin.bind(this);
    this.removeParticipant = this.removeParticipant.bind(this);
    this.searchParticipant = this.searchParticipant.bind(this);
    this.openModalToAddAdmin = this.openModalToAddAdmin.bind(this);
    this.hideAdminModal = this.hideAdminModal.bind(this);
    this.addAdmin = this.addAdmin.bind(this);
  }

  /**
   * @description componentDidMount is called when component is loaded
   */
  public componentDidMount() {

    const { match: { params }, role } = this.props;
    let isRfp = window.location.search.substring(1);
    if(isRfp) {
      isRfp = isRfp.split('=')[1];
    }
    if(isRfp){
      this.setState({isEditRequest: true});
    }
    if (params.id) {
      this.props.dispatch(goalAction.getGoalDetails({ id: params.id }));
      this.setState({
        btnText: 'Update Goal',
        labelText: 'Edit Goal',
      });
    } else {
      this.setState({
        btnText: 'Add Goal',
        labelText: 'Add Goal',
        modeOfDevivery: 'audio',
      });
    }

    role && (() => {
      this.props.dispatch(profileAction.getProfile());
      this.props.dispatch(learnerAction.getLearner({
        limit: MAGIC_NUMBER.TEN,
        skip: MAGIC_NUMBER.ONE,
      }));
    })();
    this.props.dispatch(tagAction.getDomains());
    this.props.dispatch(tagAction.getActivities());
    if (localStorage.getItem('goal')) {
      this.mapLocalStorageGoal();
    }
    if (!role) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'hs-script-loader';
      script.src = '//js.hs-scripts.com/7903349.js';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }
  }
  public openTagModal = () => {
    this.setState({
      tagsModal: !this.state.tagsModal,
    });
  }
  public mapLocalStorageGoal = () => {
    const { selectedDomain = null,
      selectedSubDomain = null, selectedCategory = null, selectedSubCategory = null, selectedTopic = null,
      selectedDuration = null,
      selectedEventType = null,
      title, description = '',
      toDate = '', toTime = '',
      eventType, fromDate, fromTime, modeOfDevivery, isReviewTags, selectedHours, selectedMinuts } = JSON.parse(localStorage.getItem('goal'));
    this.setState({
      selectedDomain,
      selectedSubDomain,
      selectedCategory,
      selectedSubCategory,
      selectedTopic,
      selectedDuration,
      selectedEventType,
      formData: {
        eventType, description, modeOfDevivery, title, isReviewTags,
        selectedHours, selectedMinuts,
        fromDate: fromDate && new Date(fromDate),
        toDate: toDate && new Date(toDate),
        fromTime: fromTime && new Date(fromTime),
        toTime: toTime && new Date(toTime),
      },
    },
      () => {
        const selectedTags = this.getTagsValue();
        this.props.dispatch(tagAction.getExpert(selectedTags));
        this.props.dispatch(tagAction.getSubDomains(this.state.selectedDomain, selectedTags));
        this.props.dispatch(tagAction.getCategories(this.state.selectedSubDomain, selectedTags));
        this.props.dispatch(tagAction.getSubCategories(this.state.selectedCategory, selectedTags));
        this.props.dispatch(tagAction.getTopics(this.state.selectedSubCategory, selectedTags));
        this.props.dispatch(tagAction.getActivities(this.state.selectedTopic, selectedTags));
      });
  }

  /**
   * @description componentWillReceiveProps is called when prop is changed
   * @param {Object} props
   * @param {Object} nextProps
   */
  public componentWillReceiveProps(props: any, nextProps: any) {
    if (props.users) {
      this.participantsList = props.users;
      this.setState({
        participantsListInModal: this.participantsList,
      });
    }
    if (props.goalDetails && props.successForGoalDetails) {
      this.props.dispatch(goalAction.resetGoalStatus());
      this.setState({
        goalDetails: props.goalDetails,
        editGoal: true,
        from: 'edit',
      }, () => this.mapEditData());
    }
  }

  /**
   * @description componentWillUnmount is called when component destroyed
   * called tagAction.resetAllTags()
   */
  public componentWillUnmount() {
    this.props.dispatch(tagAction.resetAllTags());
  }

  /**
   * @description mapEditData is used to edit and map data
   */
  public mapEditData = () => {
    if (this.state.editGoal) {
      const { goalDetails, isEditRequest } = this.state;
      if (goalDetails.bid && goalDetails.bid.length) {
        const { proposedAt, isEnded, userId } = goalDetails.bid[0];
        if (!isEditRequest &&  (isEnded || moment() > moment(proposedAt))) {
          goalDetails.fromDate = '';
          goalDetails.toDate = '';
          goalDetails.fromTime = '';
          goalDetails.toTime = '';
          this.setState({
            rehireId: userId['_id'],
            btnText: 'Send Request',
            labelText: 'Send Request',
            rehireOldGoalTitle: goalDetails.title,
          });
        }
      }

      const { title, eventType, description, fromDate, toDate, isReviewTags,
        fromTime, toTime, modeOfDevivery, duration, domain,
        subDomain, category, subCategory, topic, isMe, participants, participantsRaw } = goalDetails;
      const renderParticipantsList = [];
      const selectedHours = { label: MAGIC_NUMBER.ZERO, value: MAGIC_NUMBER.ZERO };
      const selectedMinuts = { label: MAGIC_NUMBER.ZERO, value: MAGIC_NUMBER.ZERO };

      if (duration) {
        const minutHours = parseFloat(duration).toFixed(MAGIC_NUMBER.TWO).split('.');
        if (minutHours.length > MAGIC_NUMBER.ONE && parseInt(minutHours[1]) > MAGIC_NUMBER.ZERO) {
          selectedHours.value = parseInt(minutHours[MAGIC_NUMBER.ZERO]);
          selectedHours.label = parseInt(minutHours[MAGIC_NUMBER.ZERO]);

          selectedMinuts.label = (parseInt(minutHours[MAGIC_NUMBER.ONE]) * MAGIC_NUMBER.SIXTY) / MAGIC_NUMBER.HUNDRED;
          selectedMinuts.value = (parseInt(minutHours[MAGIC_NUMBER.ONE]) * MAGIC_NUMBER.SIXTY) / MAGIC_NUMBER.HUNDRED;
        } else {
          selectedHours.value = parseInt(minutHours[MAGIC_NUMBER.ZERO]);
          selectedHours.label = parseInt(minutHours[MAGIC_NUMBER.ZERO]);
        }
      }

      _.map(participants, (user) => {
        if (this.props.role === staticConstants.ROLE.PROFESSIONAL) {
          user.isAdmin = true;
          this.selectedParticipants.push(user._id);
          renderParticipantsList.push(user);
        } else {
          const isAdmin = this.isAdmin(participantsRaw, user._id);
          user.isAdmin = isAdmin;
          if (user.organizationId) {
            this.selectedParticipants.push(user._id);
            renderParticipantsList.push(user);
          }
        }
      });

      this.setState({
        formData: {
          eventType, description, modeOfDevivery, title,
          selectedHours, selectedMinuts,
          fromDate: fromDate ? new Date(fromDate) : '',
          toDate: toDate ? new Date(toDate) : '',
          fromTime: fromTime ? new Date(fromTime) : '',
          toTime: toTime ? new Date(toTime) : '',
          isReviewTags,
        },
        isReviewTags,
        modeOfDevivery,
        editGoal: false,
        minToDate: toDate ? new Date(toDate) : null,
        btnText: isEditRequest? 'Update Request': 'Update Goal',
        labelText: isEditRequest? 'Edit Request' : 'Edit Goal',
        maxToDate: moment(new Date()).add(3,'M').toDate(),
        selectedDomain: revertSelectedOptions(domain),
        selectedSubDomain: revertSelectedOptions(subDomain),
        selectedCategory: category && revertSelectedOptions(category),
        selectedSubCategory: subCategory && revertSelectedOptions(subCategory),
        selectedTopic: topic && revertSelectedOptions(topic),
        selectedDuration: duration,
        isMe,
        renderParticipantsList,
        selectedEventType: _.find(eventTypes, (event) => (event.label === eventType)),
      }, () => {
        const selectedTags = this.getTagsValue();
        this.props.dispatch(tagAction.getSubDomains(this.state.selectedDomain, selectedTags));
        this.props.dispatch(tagAction.getCategories(this.state.selectedSubDomain, selectedTags));
        this.props.dispatch(tagAction.getSubCategories(this.state.selectedCategory, selectedTags));
        this.props.dispatch(tagAction.getTopics(this.state.selectedSubCategory, selectedTags));
        this.props.dispatch(tagAction.getActivities(this.state.selectedTopic, selectedTags));
      });
    }
  }

  /**
   * @description
   * handleChange is used to set the value on state from the only input/textarea boxes.
   * also remove the form validation. Once form validated the forward the control to parenet component
   * @param fields {Object} event
   */
  public handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    let { name, value } = event.target;
    if (name === 'modeOfDevivery') {
      this.setState({
        ...this.state,
        [name]: value,
      });
    } else {
      const { errors } = this.validator;
      if (name === 'title') {
        value = value.charAt(0).toUpperCase() + value.slice(1).toString();
      }
      this.setState({ formData: { ...this.state.formData, [name]: value } });
      errors.remove(name);
      this.validator.validate(name, value)
        .then(() => {
          this.setState({ errors });
        });
    }
  }

  public handleShow() {
    this.setState({show:!this.state.show});
  }

  public handleShowMoreLess() {
    this.setState({isShowMore:!this.state.isShowMore});
  }

  /**
   * @description
   * getSelectedValue is used to get all selected dropdown value.
   */
  public getTagsValue = () => {
    return {
      domain: getSelectedValue(this.state.selectedDomain),
      subDomain: getSelectedValue(this.state.selectedSubDomain),
      category: getSelectedValue(this.state.selectedCategory),
      subCategory: getSelectedValue(this.state.selectedSubCategory),
      topic: getSelectedValue(this.state.selectedTopic),
    };
  }

  /**
   * @description
   * handleEventType is used to set the eventType.
   */
  public handleEventType = (eventType: any) => {
    this.setState({ selectedEventType: eventType });
  }

  /**
   * @description
   * handleDomain is used to set the domain.
   */
  public handleDomain = (domain: any) => {
    this.setState({
      selectedDomain: domain,
      selectedSubDomain: null,
      selectedCategory: null,
      selectedSubCategory: null,
      selectedTopic: null
    }, () => {
      const dropdownValues = this.getTagsValue();
      this.props.dispatch(tagAction.getSubDomains(domain, dropdownValues));
    });
  }

  /**
   * @description
   * handleSubDomain is used to set the subDomain.
   */
  public handleSubDomain = (subDomain: any) => {
    this.setState({ selectedSubDomain: subDomain }, () => {
      const dropdownValues = this.getTagsValue();
      this.props.dispatch(tagAction.getCategories(subDomain, dropdownValues));
    });
  }

  /**
   * @description
   * handleCategory is used to set the category.
   */
  public handleCategory = (category: any) => {
    this.setState({ selectedCategory: category }, () => {
      const dropdownValues = this.getTagsValue();
      this.props.dispatch(tagAction.getSubCategories(category, dropdownValues));
    });
  }

  /**
   * @description
   * handleSubCategory is used to set the subCategory.
   */
  public handleSubCategory = (subCategory: any) => {
    this.setState({ selectedSubCategory: subCategory }, () => {
      const dropdownValues = this.getTagsValue();
      this.props.dispatch(tagAction.getTopics(subCategory, dropdownValues));
    });
  }

  /**
   * @description
   * handleTopic is used to set the topic.
   */
  public handleTopic = (topic: any) => {
    this.setState({ selectedTopic: topic }, () => {
      const dropdownValues = this.getTagsValue();
      this.props.dispatch(tagAction.getActivities(topic, dropdownValues));
    });
  }

  /**
   * @description
   * handleDuration is used to set the duration.
   */
  public handleDuration = (duration: any, key: any) => {
    const { formData } = this.state;
    if (key === 'selectedMinuts') {
      formData[key] = duration;
      const decimalMinut = formData[key] ? (parseInt(formData[key]['value']) / MAGIC_NUMBER.SIXTY) : 0;
      const selectedHours = formData['selectedHours']['value'] ? formData['selectedHours']['value'] : 0;
      const calDuration = parseInt(selectedHours) + decimalMinut;
      this.setState({ formData, selectedDuration: calDuration });
    } else {
      formData[key] = duration;
      const hours = formData[key]['value'] ? formData[key]['value'] : 0;
      const decimalMinut = (formData['selectedMinuts'] && formData['selectedMinuts']['value'] > MAGIC_NUMBER.ZERO) ?
        (parseInt(formData['selectedMinuts']['value']) / MAGIC_NUMBER.SIXTY) : 0;
      const calDuration = parseInt(hours) + decimalMinut;
      this.setState({ formData, selectedDuration: calDuration });
    }
  }

  public toggleTags = (event: any) => {
    this.setState({ isReviewTags: event.target.checked });
  }

  /**
   * @description
   * removeErrorAndReValidate is used to remove the validation.
   *  @param fields {String} name
   */
  public removeErrorAndReValidate = (name: string) => {
    const { errors } = this.validator;
    errors.remove(name);
    this.validator.validate(name, this.state.formData[name])
      .then(() => {
        this.setState({ errors });
      });
  }

  /**
   * @description
   * handleFromDate is used to handle from date and other validatopm.
   *  @param fields {String} date
   *  @param fields {key} string
   */
  public handleFromDate = (date: any, key: any) => {
    const { formData: { toDate, fromDate } } = this.state;
    const selectMinToDate = key === 'fromDate' ? date : this.state.selectMinToDate;

    if ((key === 'toDate' && fromDate) || (key === 'fromDate' && toDate)) {
      const startDate = key === 'fromDate' ? date : fromDate;
      const endDate = key === 'toDate' ? date : toDate;
      if ((moment(endDate).diff(moment(startDate), 'days') < 0)) {
        this.setState({
          isErrorsOnDate: key === 'fromDate' ? 'From date can not be greater than to date'
            : 'To date can not be less than from date',
          formData: { ...this.state.formData, fromDate: '' },
          selectMinToDate,
        });
      } else {
        this.setState({
          formData: { ...this.state.formData, [key]: date },
          selectMinToDate,
          isErrorsOnDate: undefined,
          minTime: null,
          maxTime: null,
        }, () => this.validateTime('', null));
      }
    } else {
      this.setState({
        formData: { ...this.state.formData, [key]: date },
        isErrorsOnDate: undefined,
        selectMinToDate,
        minTime: null,
        maxTime: null,
      }, () => this.validateTime('', null));
    }
  }

  /**
   * @description
   * validateTime is used to validate the timings.
   */
  public validateTime(key: any, value: any) {
    let { toTime, fromTime } = this.state.formData;
    const { formData: { toDate, fromDate }, from } = this.state;
    toTime = from === 'edit' ? moment(toDate).add(moment(toTime).minute(), 'm').add(moment(toTime).hour(), 'h') : toTime;
    fromTime = from === 'edit' ? moment(fromDate).add(moment(fromTime).minute(), 'm').add(moment(fromTime).hour(), 'h') : fromTime;
    if (moment(fromDate).isSame(toDate) && ((key && value)
      || (!key && toTime && fromTime))) {
      const beginningTime = key === 'fromTime' ? moment(value, 'h:mma') : moment(fromTime, 'h:mma');
      const endTime = key === 'toTime' ? moment(value, 'h:mma') : moment(toTime, 'h:mma');
      if (!beginningTime.isBefore(endTime)) {
        this.setState({
          isErrorsOnTime: key === 'toTime' ? 'To Time can not be less than From Time'
            : 'From Time can not be greater than To Time ',
          formData: { ...this.state.formData, fromTime: '', toTime: '' },
        });
      } else {
        this.setState({
          formData: { ...this.state.formData, [key]: value },
          isErrorsOnTime: undefined,
        });
      }
    }
  }

  /**
   * @description
   * handleFromTimings is used to handle from date and other validaton.
   *  @param fields {string} value
   *  @param fields {string} key
   */
  public handleFromTimings(value: any, key: string) {
    const { toTime, fromTime, toDate, fromDate } = this.state.formData;
    if (moment(fromDate).isSame(toDate)) {
      const minTimeDateUTC = new Date(value);
      const minutesValue = minTimeDateUTC.getMinutes() === MAGIC_NUMBER.ZERO ? minTimeDateUTC.getMinutes() + MAGIC_NUMBER.THIRTY : MAGIC_NUMBER.ZERO;
      const hoursValue = minTimeDateUTC.getMinutes() !== MAGIC_NUMBER.ZERO ? minTimeDateUTC.getHours() + MAGIC_NUMBER.ONE : minTimeDateUTC.getHours();
      if ((key === 'fromTime' && toTime)
        || (key === 'toTime' && fromTime)) {
        this.setState({
          minTime: setHours(setMinutes(value, (minutesValue)), hoursValue),
          maxTime: setHours(setMinutes(new Date(), MAGIC_NUMBER.THIRTY), MAGIC_NUMBER.TWENTY_THREE),
        });
        this.validateTime(key, value);
      } else {
        this.setState({
          formData: { ...this.state.formData, [key]: value },
          isErrorsOnTime: undefined,
          minTime: setHours(setMinutes(value, (minutesValue)), hoursValue),
          maxTime: setHours(setMinutes(new Date(), MAGIC_NUMBER.THIRTY), MAGIC_NUMBER.TWENTY_THREE),
        });
      }
    } else {
      this.setState({
        formData: { ...this.state.formData, [key]: value },
        isErrorsOnTime: undefined,
        minTime: null,
        maxTime: null,
      });
    }
  }

  /**
   * @description
   * hideParticipaintsModal is used to hide ParticipaintsModal
   */
  public hideParticipaintsModal() {
    this.selectedParticipants = [];
    _.map(this.state.renderParticipantsList, (user) => {
      this.selectedParticipants.push(user._id);
    });
    this.setState({
      openModalToAddParticipaints: false,
    });
  }

  /**
   * @description
   * hideAdminModal is used to hide hideAdminModal
   */
  public hideAdminModal() {
    this.setState({
      openModalToAddAdmin: false,
    });
  }

  /**
   * @description
   * openParticipaintsModal is used to hide openParticipaintsModal
   */
  public openParticipaintsModal() {
    this.setState({
      openModalToAddParticipaints: true,
      participantsListInModal: this.participantsList,
    });
  }

  /**
   * @description
   * openModalToAddAdmin is used to hide openParticipaintsModal
   */
  public openModalToAddAdmin() {
    this.setState({
      openModalToAddAdmin: true,
      addedParticipantsListInModal: this.state.renderParticipantsList,
    });
  }

  /**
   * @description
   * handleChange is used to set the value on state from the only input/textarea boxes.
   * also remove the form validation. Once form validated the forward the control to parenet component
   * @param fields {Object} event
   */
  public handleParticipant(event: any) {
    const { value, checked } = event.target;
    if (checked && this.selectedParticipants.length >= this.props.licence) {
      this.props.dispatch(alertActions.errorAlert(`error:Only ${this.props.licence} participants are allowed`));
      event.target.checked = false;
    } else {
      if (this.selectedParticipants.indexOf(value) > -1) {
        const findIndex = this.selectedParticipants.findIndex((val) => val === value);
        this.selectedParticipants.splice(findIndex, 1);
      } else if (this.selectedParticipants.length < this.props.licence) {
        this.selectedParticipants.push(value);
      }
    }
  }

  /**
   * @description
   * handleAdmin is used to set the value on state
   * @param fields {Object} event
   */
  public handleAdmin(event: any) {
    const { value, checked } = event.target;
    this.selectedParticipantForAdmin = checked ? value : null;
  }


  public handleTagAdd = (tag: any) => {
    const formValues = {
      selectedDomain: null,
      selectedSubDomain: null,
      selectedCategory: null,
      selectedSubCategory: null,
      selectedTopic: null
    };

    switch (tag.type) {
      case 'type1':
        formValues['selectedDomain'] = { value: tag._id, label: tag.name};
        this.setState(formValues, () => {
          const dropdownValues = this.getTagsValue();
          this.props.dispatch(tagAction.getSubDomains('', dropdownValues));
        });
        break;
      case 'type2':
        formValues['selectedDomain'] = { value: tag.connectingId._id, label: tag.connectingId.name};
        formValues['selectedSubDomain'] = { value: tag._id, label: tag.name};
        this.setState(formValues, () => {
          const dropdownValues = this.getTagsValue();
          this.props.dispatch(tagAction.getDomains());
          this.props.dispatch(tagAction.getSubDomains(tag.name, dropdownValues));
          this.props.dispatch(tagAction.getCategories('', dropdownValues));
        });
        break;
      case 'type3':
        formValues['selectedDomain'] = { value: tag.domainId._id, label: tag.domainId.name};
        formValues['selectedSubDomain'] = { value: tag.connectingId._id, label: tag.connectingId.name};
        formValues['selectedCategory'] = { value: tag._id, label: tag.name};
        this.setState(formValues, () => {
          const dropdownValues = this.getTagsValue();
          this.props.dispatch(tagAction.getDomains());
          this.props.dispatch(tagAction.getSubDomains(tag.connectingId.name, dropdownValues));
          this.props.dispatch(tagAction.getCategories(tag.name, dropdownValues));
          this.props.dispatch(tagAction.getSubCategories('', dropdownValues));
        });
        break;
      case 'type4':
        formValues['selectedDomain'] = { value: tag.domainId._id, label: tag.domainId.name};
        formValues['selectedSubDomain'] = { value: tag.subDomainId._id, label: tag.subDomainId.name};
        formValues['selectedCategory'] = { value: tag.connectingId._id, label: tag.connectingId.name};
        formValues['selectedSubCategory'] = { value: tag._id, label: tag.name };
        this.setState(formValues, () => {
          const dropdownValues = this.getTagsValue();
          this.props.dispatch(tagAction.getDomains());
          this.props.dispatch(tagAction.getSubDomains(tag.subDomainId.name, dropdownValues));
          this.props.dispatch(tagAction.getCategories(tag.connectingId.name, dropdownValues));
          this.props.dispatch(tagAction.getSubCategories(tag.name, dropdownValues));
          this.props.dispatch(tagAction.getTopics('', dropdownValues));
        });
        break;
      case 'type5':
        formValues['selectedDomain'] = { value: tag.domainId._id, label: tag.domainId.name};
        formValues['selectedSubDomain'] = { value: tag.subDomainId._id, label: tag.subDomainId.name};
        formValues['selectedCategory'] = { value: tag.categoryId._id, label: tag.categoryId.name};
        formValues['selectedSubCategory'] = { value: tag.connectingId._id, label: tag.connectingId.name};
        formValues['selectedTopic'] = { value: tag._id, label: tag.name };
        
        this.setState(formValues, () => {
          const dropdownValues = this.getTagsValue();
          this.props.dispatch(tagAction.getDomains());
          this.props.dispatch(tagAction.getSubDomains(tag.subDomainId.name, dropdownValues));
          this.props.dispatch(tagAction.getCategories(tag.categoryId.name, dropdownValues));
          this.props.dispatch(tagAction.getSubCategories(tag.connectingId.name, dropdownValues));
          this.props.dispatch(tagAction.getTopics(tag.name, dropdownValues));
        });
        break;
    }
    this.handleShowMoreLess()
  }


  /**
   * @description
   * removeAdmin is used to add remove admin from the list
   */
  public removeAdmin() {
    const list = this.state.renderParticipantsList;
    const updatedList = [];
    for (let i = 0; i < list.length; i++) {
      const l = list[i];
      delete l.isAdmin;
      updatedList.push(l);
    }
    this.setState({ renderParticipantsList: updatedList });
  }

  /**
   * @description
   * addAdmin is used to add participant as admin
   * @param fields {Object} event
   */
  public addAdmin() {
    this.removeAdmin();
    const list = this.state.renderParticipantsList;
    const updatedList = [];
    let isAdminAdded = false;
    for (let i = 0; i < list.length; i++) {
      const l = list[i];
      if (l._id === this.selectedParticipantForAdmin) {
        l.isAdmin = true;
        isAdminAdded = true;
      }
      updatedList.push(l);
    }
    this.setState({
      renderParticipantsList: updatedList,
      openModalToAddAdmin: false,
      isAdminAdded: !isAdminAdded,
    });
  }

  /**
   * @description
   * searchParticipant is used to search Participant.
   * @param fields {Object} event
   */
  public searchParticipant(event: any, isSearchForAdmin: boolean) {
    const { value } = event.target;
    const regX = new RegExp(value, 'i');
    let list: any;
    if (isSearchForAdmin) {
      list = this.state.renderParticipantsList;
    } else {
      list = this.participantsList;
    }
    if (value) {
      const filterParticipants = list.filter((participant: any) => {
        return participant.firstName.match(regX);
      });
      if (isSearchForAdmin) {
        this.setState({
          addedParticipantsListInModal: filterParticipants,
        });
      } else {
        this.setState({
          participantsListInModal: filterParticipants,
        });
      }
    } else {
      if (isSearchForAdmin) {
        this.setState({
          addedParticipantsListInModal: list,
        });
      } else {
        this.setState({
          participantsListInModal: list,
        });
      }
    }
  }

  /**
   * @description
   * addParticipaints is used to add Participant.
   */
  public addParticipaints() {
    const arr = [];
    for (const user of this.selectedParticipants) {
      const filterParticipant = this.props.users.filter((participant: any) => {
        return participant._id === user;
      });
      if (filterParticipant.length > MAGIC_NUMBER.ZERO) {
        arr.push(filterParticipant[0]);
      }
    }
    let isParticipantAdded = false;
    if (arr.length === MAGIC_NUMBER.ZERO) {
      isParticipantAdded = true;
    }
    this.setState({
      renderParticipantsList: arr,
      openModalToAddParticipaints: false,
      isParticipantAdded,
    });
  }

  /**
   * @description
   * removeParticipant is used to remove Participant.
   * @param {String} participantIndex
   */
  public removeParticipant(participantIndex: any) {
    const selectedParticipantIndex = this.selectedParticipants.findIndex((participant: any) =>
      participant === participantIndex);
    this.selectedParticipants.splice(selectedParticipantIndex, 1);
    const stateParticipantIndex = this.state.renderParticipantsList.findIndex((participant: any) =>
      participant._id === participantIndex);
    this.state.renderParticipantsList.splice(stateParticipantIndex, 1);
    this.setState({
      renderParticipantsList: this.state.renderParticipantsList,
    });
  }

  /**
   * @description
   * validateAndSubmit is used to validate form.
   * @param {Object}
   */
  public validateAndSubmit = (e: any, updateAccept = false) => {
    e.preventDefault();
    const { title, description, fromDate, toDate, fromTime, toTime } = this.state.formData;
    const { isErrorsOnDate, isErrorsOnTime, selectedEventType, selectedDomain, selectedSubDomain, selectedDuration } = this.state;
    const { errors } = this.validator;
    this.setState({ isSubmitted: true, isParticipantAdded: false });
    this.validator.validateAll({ title, description })
      .then((success: boolean) => {

        if (success) {
          if (!isErrorsOnDate && !isErrorsOnTime && selectedDomain && selectedSubDomain
            && selectedEventType && fromDate && toDate && fromTime && toTime && selectedDuration) {
            this.handleSubmit(updateAccept);
          }
        } else {
          this.setState({ errors });
        }
      });
  }

  /**
   * @description
   * handleSubmit called after validation
   * @param {Object}
   */
  public handleSubmit = (updateAccept = false) => {
    const {
      formData,
      selectedDomain,
      selectedSubDomain,
      selectedCategory,
      selectedSubCategory,
      selectedTopic,
      modeOfDevivery,
      selectedEventType,
      selectedDuration,
      isMe,
      from,
      isReviewTags,
      rehireId,
      rehireOldGoalTitle,
      isEditRequest,
    } = this.state;
    const { dispatch, role, selUserId } = this.props;
    const { fromDate, toDate, fromTime, toTime } = formData;
    const reqObj = {
      ...formData,
      fromTime: moment(fromDate)
        .hours(moment(fromTime).hours())
        .minutes(moment(fromTime).minutes())
        .format(),
      toTime: moment(toDate)
        .hours(moment(toTime).hours())
        .minutes(moment(toTime).minutes())
        .format(),
      domain: updateSelectedOptions(selectedDomain),
      subDomain: updateSelectedOptions(selectedSubDomain),
      category: selectedCategory && updateSelectedOptions(selectedCategory),
      subCategory:
        selectedSubCategory && updateSelectedOptions(selectedSubCategory),
      topic: selectedTopic && updateSelectedOptions(selectedTopic),
      eventType: selectedEventType.value,
      duration: String(selectedDuration),
      modeOfDevivery,
      isMe,
      isReviewTags,
      rehireId,
      rehireOldGoalTitle,
      isRfp: isEditRequest? true: false,
    };
    const participaints = [];
    const uniqParticipants = _.uniq(this.selectedParticipants);
    for (const uniqParticipant of uniqParticipants) {
      let isAdmin = false;
      if (role === staticConstants.ROLE.PROFESSIONAL) {
        isAdmin = true;
      } else {
        if (this.selectedParticipantForAdmin === uniqParticipant) {
          isAdmin = true;
        }
      }
      participaints.push({
        userId: uniqParticipant,
        isAdmin,
      });
    }
    if (role === staticConstants.ROLE.ORGANIZATION_ADMIN && !isMe && uniqParticipants.length === MAGIC_NUMBER.ZERO) {
      this.setState({
        isParticipantAdded: true,
      });
      return;
    }
    if (role === staticConstants.ROLE.ORGANIZATION_ADMIN && !isMe && uniqParticipants.length !== MAGIC_NUMBER.ZERO) {
      const list = this.state.renderParticipantsList;
      let isAdminAdded = false;
      for (const listItem of list) {
        if (listItem.isAdmin) {
          isAdminAdded = true;
          break;
        }
      }
      if (!isAdminAdded) {
        this.setState({
          isAdminAdded: true,
        });
        return;
      }
    }
    reqObj['participants'] = participaints;
    if (from === 'edit' && !rehireId) {
      const { match: { params } } = this.props;
      reqObj['goalId'] = params.id;
      reqObj['isGoalAccept'] = updateAccept;
      dispatch(goalAction.updateGoal(reqObj));
    } else {
      const localGoal = {
        selectedDomain,
        selectedSubDomain,
        selectedCategory,
        selectedSubCategory,
        selectedTopic,
        modeOfDevivery,
        selectedEventType,
        selectedDuration,
      };

      let feedBackTriggerQuery = null;
      if ([staticConstants.ROLE.ORGANIZATION_ADMIN, staticConstants.ROLE.PROFESSIONAL, staticConstants.ROLE.ORGANIZATION_LEARNER].indexOf(role) !== -1) {
        feedBackTriggerQuery = { userId: selUserId, attribute: GOAL_BID_COUNT_STATUS.GOALS };
      }
      role ? dispatch(goalAction.createGoal(reqObj, feedBackTriggerQuery)) : (() => {
        localStorage.setItem('goal', JSON.stringify({ ...localGoal, ...reqObj, ...{ isMe: true } }));
        history.push(`${pageRoutes.USER_TYPE}`);

      })();
    }
  }
  public updateAndAccept = (e) => {
    e.preventDefault();
    this.validateAndSubmit(e, true);
  }

  /**
   * @description
   * isAdmin is used to check participant is added as
   */
  public isAdmin(participants: any, participantId: any) {
    let isAdmin = false;
    for (const participant of participants) {
      if (participantId === participant['userId'] && participant.isAdmin) {
        isAdmin = true;
        break;
      }
    }
    return isAdmin;
  }
  /**
   * @description
   * renderParticipantsListInModal is used for render the ParticipantsList in modal
   */
  public renderParticipantsListInModal() {

    const data = this.state.participantsListInModal;
    return _.map(data, (item: any, index: any) => {
      const checked = this.selectedParticipants.indexOf(item._id) > -1;
      const fetchedProfileImage = item.profileImage !== '' ? item.profileImage : profileImage;
      return (
        <div className="list-item" key={`${index}${item._id}`} >
          <span className="profile-image">
            <img src={fetchedProfileImage} alt="" className="img-cover" />
          </span>
          <div className="profile-detail">
            <span>{item.firstName} {item.lastName}</span>
            <em>{item.email}</em>
          </div>
          <label className="control control--checkbox">
            <input type="checkbox" value={item._id} defaultChecked={checked}
              onChange={this.handleParticipant} name="participants" />
            <div className="control__indicator" />
          </label>
        </div>
      );
    });
  }

  /**
   * @description
   * renderAddedParticipantsListInModal is used for render the Added ParticipantsList in modal
   */
  public renderAddedParticipantsListInModal() {

    const data = this.state.addedParticipantsListInModal;
    return _.map(data, (item: any, index: any) => {
      const checked = item.isAdmin;
      const fetchedProfileImage = item.profileImage !== '' ? item.profileImage : profileImage;
      return (
        <div className="list-item" key={index}>
          <span className="profile-image">
            <img src={fetchedProfileImage} alt="" className="img-cover" />
          </span>
          <div className="profile-detail">
            <span>{item.firstName} {item.lastName}</span>
            <em>{item.email}</em>
          </div>
          <label className="control control--radio">
            <input type="radio" value={item._id} defaultChecked={checked}
              onChange={this.handleAdmin} name="participants" />
            <div className="control__indicator" />
          </label>
        </div>
      );
    });
  }

  /**
   * @description
   * renderParticipantsList is used for render the ParticipantsList in page
   */
  public renderParticipantsList() {
    const data = _.uniqBy(this.state.renderParticipantsList, 'email');
    const { isEditRequest } = this.state;
    const renderList = _.map(data, (item: any) => {
      const fetchedProfileImage = item.profileImage !== '' ? item.profileImage : profileImage;
      return (
        <div className={item.isAdmin ? `tag-item green-bg` : `tag-item blue-bg`} key={`tag-${item._id}`}>
          <span>
            <img className="img-cover" src={fetchedProfileImage} alt="" />
          </span>
          {item.firstName} {item.lastName}
          <em className="icon icon-cross" onClick={() => this.removeParticipant(item._id)} />
        </div>
      );
    });
    return (
      <React.Fragment>
        {renderList}
        {this.state.isMe ?
          <div className="tag-item green-bg" key="tag-profile">
            <span>
              <img className="img-cover" alt=""
                src={this.props.profile && this.props.profile.profileImage ?
                  this.props.profile.profileImage : profileImage} />
            </span>
            {this.props.profile ?
              `${this.props.profile.firstName} ${this.props.profile.lastName}`
              : ''}
            <em className="icon icon-cross" onClick={() => isEditRequest === false && this.setState({ isMe: !this.state.isMe })} />
          </div>
          : ''}
      </React.Fragment>
    );
  }

  /**
   * @description
   * render is used for render the html
   */
  public render() {
    const {
    
      selectedEventType,
      selectMinFromDate,
      errors,
      formData,
      isErrorsOnDate,
      isErrorsOnTime,
      selectMinToDate,
      selectedDomain,
      selectedSubDomain,
      selectedCategory,
      selectedSubCategory,
      selectedTopic,
      selectedDuration,
      isSubmitted,
      modeOfDevivery,
      isMe,
      hourOptions,
      minutOptions,
      labelText,
      btnText,
      isParticipantAdded,
      isAdminAdded,
      minTime,
      maxTime,
      isReviewTags,
      tagsModal,
      isEditRequest,
      minToDate,
      maxToDate,
      allTags,
      isShowMore,
    } = this.state;
    const {
      domainOptions,
      subDomainOptions,
      categoryOptions,
      subCategoryOptions,
      topicOptions,
      activityOptions,
      role,
      licence,
      totalExpert,
      goalDetails,
    } = this.props;
    const disableClass = 'disabled-select';
    const getErrClass = (field: string) => {
      return errors.has(field) ? ' has-error' : '';
    };

    return (
      <React.Fragment>
        {role ? <Header /> : <BrandingLogo />}
        {role && <Sidebar />}
        <div className="dashboard-wrapper">
          {this.props.loggedIn ? (
            <Back {...this.props} />
          ) : (
            <NavLink href="/" className="back-header-btn b-inline-block">
              {' '}
              Back{' '}
            </NavLink>
          )}
          <div className="dashboard-content shadow">
            <Form
              onSubmit={this.validateAndSubmit}
              autoComplete="off"
              noValidate
            >
              <div className="learning-goal-wrapper">
                <h2 className="heading heading-lg roboto-medium d-flex flex-wrap">
                  {labelText}
                  {totalExpert >= MAGIC_NUMBER.ZERO ? (
                    <div className="expert-count ml-auto">
                      {totalExpert === MAGIC_NUMBER.ZERO ? (
                        `No Matches`
                      ) : (
                        <React.Fragment>
                          {totalExpert === MAGIC_NUMBER.ONE ? (
                            <React.Fragment>
                              <span>01</span> Matches
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <span>
                                {totalExpert >= MAGIC_NUMBER.TEN
                                  ? totalExpert
                                  : `0${totalExpert}`}
                              </span>
                              Matches
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                </h2>

                <FormGroup className="learning-goal-form">
                  <span className="mode-option-heading">Mode of Delivery</span>
                  <div className="d-flex">
                    <label className="control control--radio">
                      <input
                        disabled={isEditRequest === true}
                        type="radio"
                        id="audio"
                        name="modeOfDevivery"
                        value="audio"
                        checked={modeOfDevivery === 'audio'}
                        onChange={this.handleChange}
                      />{' '}
                      Audio
                      <div className="control__indicator" />
                    </label>
                    <label className="control control--radio">
                      <input
                        disabled={isEditRequest === true}
                        type="radio"
                        id="video"
                        name="modeOfDevivery"
                        value="video"
                        checked={modeOfDevivery === 'video'}
                        onChange={this.handleChange}
                      />
                      Video
                      <div className="control__indicator" />
                    </label>
                  </div>
                </FormGroup>
                <Row>
                <Col xs="12">
                  {/* <FormGroup className="text-right">
                    <button className="view-more-btn" onClick={this.handleShow}>{this.state.show ? 'More' : 'Less'}</button>
                  </FormGroup> */}
                </Col>
                </Row>
                {this.state.show  ? (   <Row>
                                    <Col xs="12" sm="6">
                                      <FormGroup
                                        className={`floating-label disabled-input ${getErrClass(
                                          'occupation',
                                        )}`}
                                      >
                                        <Input
                                          type="text"
                                          className="form-control"
                                          name="title"
                                          id="title"
                                          value={formData.title}
                                          onChange={this.handleChange}
                                          placeholder="Title"
                                          disabled={isEditRequest === true}
                                        />
                                        <Label for="title">Title</Label>
                                        {errors.has('title') && (
                                          <div className="error-text">
                                            {errors.first('title')}
                                          </div>
                                        )}
                                      </FormGroup>
                                    </Col>
                                    <Col xs="12" sm="6">
                                      <FormGroup
                                        className={
                                          'floating-label disabled-input' +
                                          (isSubmitted && !selectedEventType ? 'has-error' : '')
                                        }
                                      >
                                        <Select
                                          isDisabled={isEditRequest === true}
                                          value={selectedEventType}
                                          onChange={this.handleEventType}
                                          options={eventTypes}
                                          styles={customSelectStyles}
                                          isClearable
                                          name="eventType"
                                          placeholder="Event Type"
                                          className="react-select-box select-box-group"
                                          filterOption={createFilter(reactSelectFilters)}
                                        />
                                        <Label
                                          for="eventType"
                                          className={selectedEventType ? 'selected' : ''}
                                        >
                                          Event Type
                                        </Label>
                                        {isSubmitted && !selectedEventType && (
                                          <div className="error-text">
                                            The event type field is required.
                                          </div>
                                        )}
                                      </FormGroup>
                                    </Col>
                                
                                    <Col xs="12" sm="12">
                                    <div className="col-12 mb-3">
                                      <div className="input-group search_input search-suggestion">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text"><img src={searchIconImg} alt="search" /></span>
                                        </div>
                                        {/* <input type="text" className="form-control" placeholder="Search here" /> */}
                                        <SearchTags selectedTags={allTags}
                                          onAdd={this.handleTagAdd}
                                          limitTags={MAGIC_NUMBER.TEN}
                                        />
                                      </div>
                                    </div>   
                                      </Col>
                                
                                    <Col xs="12" sm="6">
                                      <FormGroup
                                        className={
                                          'floating-label disabled-input ' +
                                          (isSubmitted && !selectedDomain ? 'has-error' : '')
                                        }
                                      >
                                        <Select
                                          isDisabled={isEditRequest === true}
                                          value={selectedDomain}
                                          isClearable
                                          onChange={this.handleDomain}
                                          styles={customSelectStyles}
                                          options={domainOptions}
                                          name="domain"
                                          placeholder="Select Domain"
                                          className="react-select-box select-box-group"
                                          filterOption={createFilter(reactSelectFilters)}
                                        />
                                        <Label
                                          for="SelectDomain"
                                          className={selectedDomain ? 'selected' : ''}
                                        >
                                          Select Domain
                                        </Label>
                                        {isSubmitted && !selectedDomain && (
                                          <div className="error-text">
                                            The domain field is required.
                                          </div>
                                        )}
                                      </FormGroup>
                                    </Col>
                                    <Col xs="12" sm="6">
                                      <FormGroup
                                        className={
                                          'floating-label disabled-input ' +
                                          (isSubmitted && selectedDomain && !selectedSubDomain
                                            ? 'has-error'
                                            : '')
                                        }
                                      >
                                        <Select value={selectedSubDomain}
                                          onChange={this.handleSubDomain}
                                          styles={customSelectStyles}
                                          options={subDomainOptions}
                                          isDisabled={
                                            subDomainOptions.length === MAGIC_NUMBER.ZERO ||
                                            isEditRequest === true
                                          }
                                          name="subDomain"
                                          isClearable
                                          placeholder="Select Sub-Domain"
                                          className={`react-select-box select-box-group ${
                                            subDomainOptions.length === MAGIC_NUMBER.ZERO
                                              ? disableClass
                                              : ''
                                          }`}
                                          filterOption={createFilter(reactSelectFilters)}
                                        />
                                        <Label
                                          for="SelectSub-Domain"
                                          className={selectedSubDomain ? 'selected' : ''}
                                        >
                                          Select Sub-Domain
                                        </Label>
                                        {isSubmitted && selectedDomain && !selectedSubDomain && (
                                          <div className="error-text">
                                            The sub-domain field is required.
                                          </div>
                                        )}
                                      </FormGroup>
                                    </Col>
                                    <Col xs="12" sm="6">
                                      <FormGroup
                                        className={
                                          'create-duration-input ' +
                                          (isSubmitted && !selectedDuration ? 'has-error' : '')
                                        }
                                      >
                                        <p>Duration</p>
                                        <div className="duration-content input-selecter">
                                          <div className="input-element">
                                            <Select
                                              isDisabled={isEditRequest === true}
                                              value={formData.selectedHours}
                                              onChange={(val) => {
                                                this.handleDuration(val, 'selectedHours');
                                              }}
                                              options={hourOptions.map((item) => ({
                                                label: item,
                                                value: item,
                                              }))}
                                              name="selectedHours"
                                              styles={customSelectStyles}
                                              placeholder="Hours"
                                              className="react-select-box select-box-group"
                                              filterOption={createFilter(reactSelectFilters)}
                                            />
                                            <span>hr</span>
                                          </div>
                                          <div className="input-element">
                                            <Select
                                              isDisabled={isEditRequest === true}
                                              value={formData.selectedMinuts}
                                              onChange={(val) => {
                                                this.handleDuration(val, 'selectedMinuts');
                                              }}
                                              options={minutOptions.map((item) => ({
                                                label: item,
                                                value: item,
                                              }))}
                                              name="selectedMinuts"
                                              styles={customSelectStyles}
                                              placeholder="Minuts"
                                              className="react-select-box select-box-group"
                                              filterOption={createFilter(reactSelectFilters)}
                                            />
                                            <span>min</span>
                                          </div>
                                        </div>
                                        {isSubmitted && !selectedDuration && (
                                          <div className="error-text duration-error">
                                            The duration field is required.
                                          </div>
                                        )}
                                      </FormGroup>
                                    </Col>
                                    <Col xs="12" sm="6">
                                      <FormGroup className={'create-goal-custom-switch'}>
                                        <p>
                                          Check my goal <em onClick={this.openTagModal}>?</em>
                                        </p>
                                        <label className="toggle-switch">
                                          <Input
                                            disabled={isEditRequest === true}
                                            type="checkbox"
                                            name="isReviewTags"
                                            checked={isReviewTags}
                                            onChange={this.toggleTags}
                                          />
                                          <span className="slider round" />
                                        </label>
                                      </FormGroup>
                                    </Col>
                                    <Col xs="12">
                                      <div className="datepicker-wrapper">
                                        <h2>Date & Time Availability</h2>
                                        <div className="time-availability">
                                          <Row>
                                            <Col xs="12" md="3">
                                              <FormGroup className="floating-label disabled-input datepicker-wrapper">
                                                <DatePicker
                                                  placeholderText="Date From"
                                                  className="form-control"
                                                  dateFormat="MMM dd, yyyy"
                                                  name="fromDate"
                                                  selected={formData.fromDate}
                                                  minDate={selectMinFromDate}
                                                  onChange={(e) =>
                                                    this.handleFromDate(e, 'fromDate')
                                                  }
                                                  dropdownMode="select"
                                                  showYearDropdown
                                                  showMonthDropdown
                                                  readOnly={isEditRequest === true}
                                                />
                  
                                                <Label
                                                  for="dateOfBirth"
                                                  className={formData.fromDate ? 'selected' : ''}
                                                >
                                                  Date From
                                                </Label>
                                                <span className="icon icon-calendar" />
                                                {isErrorsOnDate && (
                                                  <div className="error-text">
                                                    {isErrorsOnDate}
                                                  </div>
                                                )}
                                                {isSubmitted && !formData.fromDate && (
                                                  <div className="error-text">
                                                    The date from field is required.
                                                  </div>
                                                )}
                                                <div className="error-text" />
                                              </FormGroup>
                                            </Col>
                                            <Col xs="12" md="3">
                                              <FormGroup className="floating-label disabled-input datepicker-wrapper">
                                                <DatePicker
                                                  placeholderText="Date To"
                                                  className="form-control"
                                                  name="toDate"
                                                  dateFormat="MMM dd, yyyy"
                                                  selected={formData.toDate}
                                                  onChange={(e) =>
                                                    this.handleFromDate(e, 'toDate')
                                                  }
                                                  minDate={(isEditRequest && minToDate) || selectMinToDate}
                                                  maxDate={maxToDate}
                                                  dropdownMode="select"
                                                  showYearDropdown
                                                  showMonthDropdown
                                                />
                                                <Label
                                                  for="dateOfBirth"
                                                  className={formData.toDate ? 'selected' : ''}
                                                >
                                                  Date To
                                                </Label>
                                                <span className="icon icon-calendar" />
                                                {isSubmitted && !formData.toDate && (
                                                  <div className="error-text">
                                                    The date to field is required.
                                                  </div>
                                                )}
                                              </FormGroup>
                                            </Col>
                                            <Col xs="12" md="3">
                                              <FormGroup className="floating-label disabled-input time-form">
                                                <DatePicker
                                                  placeholderText="Time From"
                                                  className="form-control"
                                                  selected={formData.fromTime}
                                                  onChange={(e) =>
                                                    this.handleFromTimings(e, 'fromTime')
                                                  }
                                                  showTimeSelect
                                                  showTimeSelectOnly
                                                  dateFormat="hh:mm aa"
                                                  strictParsing
                                                />
                  
                                                <Label
                                                  for="dateOfBirth"
                                                  className={formData.fromTime ? 'selected' : ''}
                                                >
                                                  Time From
                                                </Label>
                                                <span className="icon icon-clock" />
                                                {isErrorsOnTime && (
                                                  <div className="error-text">
                                                    {isErrorsOnTime}
                                                  </div>
                                                )}
                                                {isSubmitted &&
                                                  !formData.fromTime &&
                                                  !isErrorsOnTime && (
                                                    <div className="error-text">
                                                      The time from field is required.
                                                    </div>
                                                  )}
                                              </FormGroup>
                                            </Col>
                                            <Col xs="12" md="3">
                                              <FormGroup className="floating-label disabled-input time-form">
                                                <DatePicker
                                                  placeholderText="Time To"
                                                  className="form-control"
                                                  selected={formData.toTime}
                                                  onChange={(e) =>
                                                    this.handleFromTimings(e, 'toTime')
                                                  }
                                                  showTimeSelect
                                                  showTimeSelectOnly
                                                  dateFormat="hh:mm aa"
                                                  minTime={minTime}
                                                  maxTime={maxTime}
                                                />
                  
                                                <Label
                                                  for="dateOfBirth"
                                                  className={formData.toTime ? 'selected' : ''}
                                                >
                                                  Time To
                                                </Label>
                                                <span className="icon icon-clock" />
                                                {isSubmitted && !formData.toTime && (
                                                  <div className="error-text">
                                                    The time to field is required.
                                                  </div>
                                                )}
                                              </FormGroup>
                                            </Col>
                                          </Row>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col xs="12">
                                      <FormGroup className="floating-label disabled-input textarea-label">
                                        <textarea
                                          disabled={isEditRequest === true}
                                          className="form-control textarea-md"
                                          name="description"
                                          value={formData.description}
                                          onChange={this.handleChange}
                                          maxLength={MAGIC_NUMBER.CHAR_LENGTH}
                                          placeholder="Describe your goal"
                                        />
                                        <span className="text-count">
                                          {this.state.formData.description.length}/500
                                        </span>
                                        <Label
                                          for="Describe"
                                          className={
                                            this.state.formData.description.length >
                                            MAGIC_NUMBER.ZERO
                                              ? 'selected'
                                              : ''
                                          }
                                        >
                                          Describe your goal
                                        </Label>
                                        {errors.has('description') && (
                                          <div className="error-text">
                                            {errors.first('description')}
                                          </div>
                                        )}
                                      </FormGroup>
                                    </Col>
                                    {role === staticConstants.ROLE.ORGANIZATION_ADMIN && (
                                      <Col xs="12">
                                        <div className="tag-wrapper">
                                          <h2>Participants</h2>
                                          <div className="tag-box">
                                            <em className="tag-line">
                                              {' '}
                                              You can add a maximum of {licence} participants.
                                              Note: Session fees are calculated by (expert hourly
                                              rate) x (duration of event) x (number of
                                              participants).
                                            </em>
                                            <div className="d-flex flex-wrap">
                                              {this.renderParticipantsList()}
                                              {Number(licence) > MAGIC_NUMBER.ZERO ? (
                                                <div
                                                  className="tag-item-btn"
                                                  onClick={isEditRequest === false && this.openParticipaintsModal}
                                                >
                                                  <span className="icon icon-baseline-group-add-24-px" />
                                                  Add Participants
                                                </div>
                                              ) : (
                                                ''
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </Col>
                                    )}
                                  </Row>
                 ) :(
                <Row>
                  <Col xs="12" sm="6">
                    <FormGroup
                      className={`floating-label disabled-input ${getErrClass(
                        'occupation',
                      )}`}
                    >
                      <Input
                        type="text"
                        className="form-control"
                        name="title"
                        id="title"
                        value={formData.title}
                        onChange={this.handleChange}
                        placeholder="Title"
                        disabled={isEditRequest === true}
                      />
                      <Label for="title">Title</Label>
                      {errors.has('title') && (
                        <div className="error-text">
                          {errors.first('title')}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6">
                    <FormGroup
                      className={
                        'floating-label disabled-input' +
                        (isSubmitted && !selectedEventType ? 'has-error' : '')
                      }
                    >
                      <Select
                        isDisabled={isEditRequest === true}
                        value={selectedEventType}
                        onChange={this.handleEventType}
                        options={eventTypes}
                        styles={customSelectStyles}
                        isClearable
                        name="eventType"
                        placeholder="Event Type"
                        className="react-select-box select-box-group"
                        filterOption={createFilter(reactSelectFilters)}
                      />
                      <Label
                        for="eventType"
                        className={selectedEventType ? 'selected' : ''}
                      >
                        Event Type
                      </Label>
                      {isSubmitted && !selectedEventType && (
                        <div className="error-text">
                          The event type field is required.
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="12">
                                    <div className="col-12 mb-3">
                                      <div className="input-group search_input search-suggestion">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text"><img src={searchIconImg} alt="search" /></span>
                                        </div>
                                        {/* <input type="text" className="form-control" placeholder="Search here" /> */}
                                        <SearchTags selectedTags={allTags}
                                          onAdd={this.handleTagAdd}
                                          limitTags={MAGIC_NUMBER.TEN}
                                        />
                                      </div>
                                    </div>   
                                      </Col>
                  <Col xs="12" sm="6">
                    <FormGroup
                      className={
                        'floating-label disabled-input ' +
                        (isSubmitted && !selectedDomain ? 'has-error' : '')
                      }
                    >
                      <Select
                        isDisabled={isEditRequest === true}
                        value={selectedDomain}
                        onChange={this.handleDomain}
                        styles={customSelectStyles}
                        options={domainOptions}
                        isClearable
                        name="domain"
                        placeholder="Select Domain"
                        className="react-select-box select-box-group"
                        filterOption={createFilter(reactSelectFilters)}
                      />
                      <Label
                        for="SelectDomain"
                        className={selectedDomain ? 'selected' : ''}
                      >
                        Select Domain
                      </Label>
                      {isSubmitted && !selectedDomain && (
                        <div className="error-text">
                          The domain field is required.
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6">
                    <FormGroup
                      className={
                        'floating-label disabled-input ' +
                        (isSubmitted && selectedDomain && !selectedSubDomain
                          ? 'has-error'
                          : '')
                      }
                    >
                      <Select
                        value={selectedSubDomain}
                        onChange={this.handleSubDomain}
                        styles={customSelectStyles}
                        options={subDomainOptions}
                        isDisabled={
                          subDomainOptions.length === MAGIC_NUMBER.ZERO ||
                          isEditRequest === true
                        }
                        name="subDomain"
                        isClearable
                        placeholder="Select Sub-Domain"
                        className={`react-select-box select-box-group ${
                          subDomainOptions.length === MAGIC_NUMBER.ZERO
                            ? disableClass
                            : ''
                        }`}
                        filterOption={createFilter(reactSelectFilters)}
                      />
                      <Label
                        for="SelectSub-Domain"
                        className={selectedSubDomain ? 'selected' : ''}
                      >
                        Select Sub-Domain
                      </Label>
                      {isSubmitted && selectedDomain && !selectedSubDomain && (
                        <div className="error-text">
                          The sub-domain field is required.
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  {isShowMore &&
                  <>
                  <Col xs="12" sm="6">
                    <FormGroup
                      className={
                        'floating-label disabled-input ' +
                        (isSubmitted && selectedSubDomain && !selectedCategory
                          ? 'has-error'
                          : '')
                      }
                    >
                      <Select
                        value={selectedCategory}
                        onChange={this.handleCategory}
                        options={categoryOptions}
                        styles={customSelectStyles}
                        isDisabled={categoryOptions.length === MAGIC_NUMBER.ZERO || !selectedDomain || !selectedSubDomain ||
                          isEditRequest === true
                        }
                        name="category"
                        isClearable
                        placeholder="Select Category"
                        filterOption={createFilter(reactSelectFilters)}
                        className={`react-select-box select-box-group ${
                          (categoryOptions.length === MAGIC_NUMBER.ZERO ||  !selectedDomain || !selectedSubDomain)
                            ? disableClass
                            : ''
                        }`}
                      />
                      <Label
                        for="SelectCategory"
                        className={selectedCategory ? 'selected' : ''}
                      >
                        Select Category
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6">
                    <FormGroup
                      className={
                        'floating-label disabled-input ' +
                        (isSubmitted && selectedCategory && !selectedSubCategory
                          ? 'has-error'
                          : '')
                      }
                    >
                      <Select
                        value={selectedSubCategory}
                        onChange={this.handleSubCategory}
                        options={subCategoryOptions}
                        isDisabled={
                         subCategoryOptions.length === MAGIC_NUMBER.ZERO ||
                           !selectedDomain ||
                           !selectedCategory ||
                           !selectedSubDomain ||
                          isEditRequest === true
                        }
                        styles={customSelectStyles}
                        name="subCategory"
                        isClearable
                        placeholder="Select Sub-Category"
                        filterOption={createFilter(reactSelectFilters)}
                        className={`react-select-box select-box-group ${
                          subCategoryOptions.length === MAGIC_NUMBER.ZERO || !selectedDomain || !selectedCategory || !selectedSubDomain
                            ? disableClass
                            : ''
                        }`}
                      />
                      <Label
                        for="SelectSubCategory"
                        className={selectedSubCategory ? 'selected' : ''}
                      >
                        Select Sub-Category
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6">
                    <FormGroup
                      className={
                        'floating-label disabled-input ' +
                        (isSubmitted && selectedSubCategory && !selectedTopic
                          ? 'has-error'
                          : '')
                      }
                    >
                      <Select
                        value={selectedTopic}
                        onChange={this.handleTopic}
                        options={topicOptions}
                        isDisabled={
                          topicOptions.length === MAGIC_NUMBER.ZERO ||
                            !selectedDomain ||
                            !selectedSubDomain ||
                            !selectedCategory ||
                            !selectedSubCategory ||
                            isEditRequest === true
                        }
                        styles={customSelectStyles}
                        isClearable
                        name="topic"
                        placeholder="Select Topic"
                        filterOption={createFilter(reactSelectFilters)}
                        className={`react-select-box select-box-group ${
                          topicOptions.length === MAGIC_NUMBER.ZERO ||
                          !selectedDomain ||
                          !selectedSubDomain ||
                          !selectedCategory ||
                          !selectedSubCategory
                            ? disableClass
                            : ''
                        }`}
                      />
                      <Label
                        for="SelectTopic"
                        className={selectedTopic ? 'selected' : ''}
                      >
                        Select Topic
                      </Label>
                    </FormGroup>
                  </Col>
                  </>}
                  <Col xs="12" className="showMore-button">
                    <Button onClick={() => this.handleShowMoreLess()}>
                      {isShowMore ? 'Less' : 'More'}
                    </Button>
                  </Col>
                  <Col xs="12">
                  </Col>
                  <Col xs="12" sm="6">
                    <FormGroup
                      className={
                        'create-duration-input ' +
                        (isSubmitted && !selectedDuration ? 'has-error' : '')
                      }
                    >
                      <p>Duration</p>
                      <div className="duration-content input-selecter">
                        <div className="input-element">
                          <Select
                            isDisabled={isEditRequest === true}
                            value={formData.selectedHours}
                            onChange={(val) => {
                              this.handleDuration(val, 'selectedHours');
                            }}
                            options={hourOptions.map((item) => ({
                              label: item,
                              value: item,
                            }))}
                            name="selectedHours"
                            styles={customSelectStyles}
                            placeholder="Hours"
                            className="react-select-box select-box-group"
                            filterOption={createFilter(reactSelectFilters)}
                          />
                          <span>hr</span>
                        </div>
                        <div className="input-element">
                          <Select
                            isDisabled={isEditRequest === true}
                            value={formData.selectedMinuts}
                            onChange={(val) => {
                              this.handleDuration(val, 'selectedMinuts');
                            }}
                            options={minutOptions.map((item) => ({
                              label: item,
                              value: item,
                            }))}
                            name="selectedMinuts"
                            styles={customSelectStyles}
                            placeholder="Minuts"
                            className="react-select-box select-box-group"
                            filterOption={createFilter(reactSelectFilters)}
                          />
                          <span>min</span>
                        </div>
                      </div>
                      {isSubmitted && !selectedDuration && (
                        <div className="error-text duration-error">
                          The duration field is required.
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xs="12" sm="6">
                    <FormGroup className={'create-goal-custom-switch'}>
                      <p>
                        Check my goal <em onClick={this.openTagModal}>?</em>
                      </p>
                      <label className="toggle-switch">
                        <Input
                          disabled={isEditRequest === true}
                          type="checkbox"
                          name="isReviewTags"
                          checked={isReviewTags}
                          onChange={this.toggleTags}
                        />
                        <span className="slider round" />
                      </label>
                    </FormGroup>
                  </Col>
                  <Col xs="12">
                    <div className="datepicker-wrapper">
                      <h2>Date & Time Availability</h2>
                      <div className="time-availability">
                        <Row>
                          <Col xs="12" md="3">
                            <FormGroup className="floating-label disabled-input datepicker-wrapper">
                              <DatePicker
                                placeholderText="Date From"
                                className="form-control"
                                dateFormat="MMM dd, yyyy"
                                name="fromDate"
                                selected={formData.fromDate}
                                minDate={selectMinFromDate}
                                onChange={(e) =>
                                  this.handleFromDate(e, 'fromDate')
                                }
                                dropdownMode="select"
                                showYearDropdown
                                showMonthDropdown
                                readOnly={isEditRequest === true}
                              />

                              <Label
                                for="dateOfBirth"
                                className={formData.fromDate ? 'selected' : ''}
                              >
                                Date From
                              </Label>
                              <span className="icon icon-calendar" />
                              {isErrorsOnDate && (
                                <div className="error-text">
                                  {isErrorsOnDate}
                                </div>
                              )}
                              {isSubmitted && !formData.fromDate && (
                                <div className="error-text">
                                  The date from field is required.
                                </div>
                              )}
                              <div className="error-text" />
                            </FormGroup>
                          </Col>
                          <Col xs="12" md="3">
                            <FormGroup className="floating-label disabled-input datepicker-wrapper">
                              <DatePicker
                                placeholderText="Date To"
                                className="form-control"
                                name="toDate"
                                dateFormat="MMM dd, yyyy"
                                selected={formData.toDate}
                                onChange={(e) =>
                                  this.handleFromDate(e, 'toDate')
                                }
                                minDate={(isEditRequest && minToDate) || selectMinToDate}
                                maxDate={maxToDate}
                                dropdownMode="select"
                                showYearDropdown
                                showMonthDropdown
                              />
                              <Label
                                for="dateOfBirth"
                                className={formData.toDate ? 'selected' : ''}
                              >
                                Date To
                              </Label>
                              <span className="icon icon-calendar" />
                              {isSubmitted && !formData.toDate && (
                                <div className="error-text">
                                  The date to field is required.
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col xs="12" md="3">
                            <FormGroup className="floating-label disabled-input time-form">
                              <DatePicker
                                placeholderText="Time From"
                                className="form-control"
                                selected={formData.fromTime}
                                onChange={(e) =>
                                  this.handleFromTimings(e, 'fromTime')
                                }
                                showTimeSelect
                                showTimeSelectOnly
                                dateFormat="hh:mm aa"
                                strictParsing
                              />

                              <Label
                                for="dateOfBirth"
                                className={formData.fromTime ? 'selected' : ''}
                              >
                                Time From
                              </Label>
                              <span className="icon icon-clock" />
                              {isErrorsOnTime && (
                                <div className="error-text">
                                  {isErrorsOnTime}
                                </div>
                              )}
                              {isSubmitted &&
                                !formData.fromTime &&
                                !isErrorsOnTime && (
                                  <div className="error-text">
                                    The time from field is required.
                                  </div>
                                )}
                            </FormGroup>
                          </Col>
                          <Col xs="12" md="3">
                            <FormGroup className="floating-label disabled-input time-form">
                              <DatePicker
                                placeholderText="Time To"
                                className="form-control"
                                selected={formData.toTime}
                                onChange={(e) =>
                                  this.handleFromTimings(e, 'toTime')
                                }
                                showTimeSelect
                                showTimeSelectOnly
                                dateFormat="hh:mm aa"
                                minTime={minTime}
                                maxTime={maxTime}
                              />

                              <Label
                                for="dateOfBirth"
                                className={formData.toTime ? 'selected' : ''}
                              >
                                Time To
                              </Label>
                              <span className="icon icon-clock" />
                              {isSubmitted && !formData.toTime && (
                                <div className="error-text">
                                  The time to field is required.
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                  <Col xs="12">
                    <FormGroup className="floating-label disabled-input textarea-label">
                      <textarea
                        disabled={isEditRequest === true}
                        className="form-control textarea-md"
                        name="description"
                        value={formData.description}
                        onChange={this.handleChange}
                        maxLength={MAGIC_NUMBER.CHAR_LENGTH}
                        placeholder="Describe your goal"
                      />
                      <span className="text-count">
                        {this.state.formData.description.length}/500
                      </span>
                      <Label
                        for="Describe"
                        className={
                          this.state.formData.description.length >
                          MAGIC_NUMBER.ZERO
                            ? 'selected'
                            : ''
                        }
                      >
                        Describe your goal
                      </Label>
                      {errors.has('description') && (
                        <div className="error-text">
                          {errors.first('description')}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  {role === staticConstants.ROLE.ORGANIZATION_ADMIN && (
                    <Col xs="12">
                      <div className="tag-wrapper">
                        <h2>Participants</h2>
                        <div className="tag-box">
                          <em className="tag-line">
                            {' '}
                            You can add a maximum of {licence} participants.
                            Note: Session fees are calculated by (expert hourly
                            rate) x (duration of event) x (number of
                            participants).
                          </em>
                          <div className="d-flex flex-wrap">
                            {this.renderParticipantsList()}
                            {Number(licence) > MAGIC_NUMBER.ZERO ? (
                              <div
                                className="tag-item-btn"
                                onClick={isEditRequest === false && this.openParticipaintsModal}
                              >
                                <span className="icon icon-baseline-group-add-24-px" />
                                Add Participants
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
                )}
                {role === staticConstants.ROLE.ORGANIZATION_ADMIN && (
                  <div className="add-admin-footer">
                    <label className="control control--checkbox goal-control">
                      <Input
                        disabled={isEditRequest === true}
                        type="checkbox"
                        name="isMe"
                        checked={isMe}
                        onChange={() =>
                          this.setState(
                            {
                              isMe: !isMe,
                              isParticipantAdded: false,
                              isAdminAdded: false,
                            },
                            () => {
                              this.removeAdmin();
                            },
                          )
                        }
                      />
                      I am a participant.
                      <div className="control__indicator" />
                      {!isMe && isParticipantAdded && (
                        <div className="error-text">
                          Please add atleast one participant or add yourself as
                          the participant of this goal.
                        </div>
                      )}
                    </label>
                    {!this.state.isMe &&
                      this.state.renderParticipantsList.length !== 0 && (
                        <span
                          className="add-admin-btn"
                          onClick={this.openModalToAddAdmin}
                        >
                          Select Event Lead
                        </span>
                      )}

                    {isAdminAdded && !isParticipantAdded && (
                      <div className="error-text static-error">
                        Please select an Event Lead. This person will verify the
                        completion of the event when the Team Admin is not
                        attending.
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="card-footer mt-0">
                {this.props.loggedIn ? (
                  <Link
                    to={
                      isEditRequest
                        ? pageRoutes.REQUESTMANAGEMENT.PATH
                        : role
                        ? pageRoutes.GOALMANAGEMENT.PATH
                        : '/'
                    }
                    className="btn btn-rg btn-regular"
                  >
                    Cancel
                  </Link>
                ) : (
                  <NavLink
                    href="/"
                    className="btn btn-rg d-inline-block btn-regular"
                  >
                    {' '}
                    Cancel{' '}
                  </NavLink>
                )}
                {role === staticConstants.ROLE.ORGANIZATION_ADMIN &&
                  goalDetails &&
                  goalDetails.status === goalStatus.REQUEST_SEND_BY_LERNER && (
                    <Button
                      color="primary"
                      onClick={this.updateAndAccept}
                      className="btn btn-rg ml-3"
                    >
                      Update and Accept
                    </Button>
                  )}
                <Button
                  onKeyDown={restrictSpaceEnter}
                  color="primary"
                  className="btn btn-rg ml-3"
                >
                  {btnText}
                </Button>
              </div>
            </Form>
          </div>
          <Modal
            isOpen={this.state.openModalToAddParticipaints}
            className="add-participaint"
          >
            <ModalHeader toggle={this.hideParticipaintsModal}>
              Add Participaints
            </ModalHeader>
            <ModalBody>
              <div className="search-bar">
                <input
                  type="search"
                  name="search"
                  placeholder="Search by Name"
                  onChange={(event: any) =>
                    this.searchParticipant(event, false)
                  }
                  autoComplete="off"
                />
                <span className="icon icon-search-icon" />
              </div>
              <div className="participaint-list">
                {this.renderParticipantsListInModal()}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.hideParticipaintsModal}>
                Cancel
              </Button>
              <Button color="primary" onClick={this.addParticipaints}>
                Add
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={this.state.openModalToAddAdmin}
            className="add-participaint"
          >
            <ModalHeader toggle={this.hideAdminModal}>
              Select Event Lead
            </ModalHeader>
            <ModalBody>
              <div className="search-bar">
                <input
                  type="search"
                  name="search"
                  placeholder="Search by Name"
                  onChange={(event: any) => this.searchParticipant(event, true)}
                  autoComplete="off"
                />
                <span className="icon icon-search-icon" />
              </div>
              <div className="participaint-list">
                {this.renderAddedParticipantsListInModal()}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.hideAdminModal}>
                Cancel
              </Button>
              <Button color="primary" onClick={this.addAdmin}>
                Choose
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={tagsModal} className="feedback-modal">
            <ModalBody>
              <p>{staticConstants.CHECK_MY_TAGS}</p>
            </ModalBody>
            <ModalFooter>
              <Button
                className="btn btn-regular ml-0"
                onClick={this.openTagModal}
              >
                Close
              </Button>{' '}
            </ModalFooter>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: { tags: any; authentication: any; learner: any; updateProfile: any; goals: any }) {
  const { tags: { domains, subDomains, categories, subCategories, topics, activities, totalExpert } } = state;
  const { authentication } = state;
  const { user, loggedIn } = authentication;
  const { list } = state.learner;
  const { updateProfile } = state;
  const licence = updateProfile.profile && updateProfile.profile.licence ? updateProfile.profile.licence : 0;
  return {
    domainOptions: getUpdatedOptions(domains, 'name'),
    subDomainOptions: getUpdatedOptions(subDomains, 'name'),
    categoryOptions: getUpdatedOptions(categories, 'name'),
    subCategoryOptions: getUpdatedOptions(subCategories, 'name'),
    topicOptions: getUpdatedOptions(topics, 'name'),
    activityOptions: getUpdatedOptions(activities, 'name'),
    role: user && user.role,
    selUserId: user && user._id,
    users: list,
    licence,
    goals: state.goals,
    goalDetails: state.goals.goalDetails,
    successForGoalDetails: state.goals.successForGoalDetails,
    profile: updateProfile && updateProfile.profile,
    totalExpert,
    loggedIn
  };
}

const connectedCreateGoalContainerPage = connect(mapStateToProps)(CreateGoalContainer);
export { connectedCreateGoalContainerPage as CreateGoalContainer };
