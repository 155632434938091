import { toastsReducer as toasts } from 'react-toastify-redux';
import { combineReducers } from 'redux';
import alert from './alert/redux';
import authentication from './auth/redux';
import registeration from './shared/register/redux';
import updateProfile from './shared/updateProfile/redux';
import createPassword from './shared/createPassword/redux';
import forgotPassword from './shared/forgotPassword/redux';
import bids from './expert/bidManagement/redux';
import goals from './shared/goalManagement/redux';
import learner from './shared/learnerManagement/redux';
import payment from './shared/payment/redux';
import resetPassword from './shared/resetPassword/redux';
import tags from './shared/tags/redux';
import selectTags from './shared/selectTags/redux/';
import searchTags from './shared/searchTags/redux/';
import rfpRequest from './shared/requestManagement/redux';
import scheduledEvent from './shared/eventManagement/redux';
import viewProfile from './shared/viewProfile/redux';
import notification from './shared/notfication/redux';
import chatMessages from './shared/chat/redux';
import card from './shared/paymentCard/redux';
import sendFeedback from '../app/shared/header/feedbackForm/redux';
import loader from './loader/redux';
import dashboard from '../app/orgadmin/dashboard/redux';
import logs from '../app/shared/logs/redux';
import users from '../app/systemAdmin/userManagement/redux';
import applicationFeedBack from '../app/systemAdmin/applicationFeedback/redux';
import contentManagement from '../app/systemAdmin/contentManagement/redux';
import systemAdmimUser from '../app/systemAdmin/viewProfile/redux';
import systemAdminDashboard from '../app/systemAdmin/dashboard/redux';
import home from '../app/home/redux';
import expertPayments from '../app/expert/payment/redux';
import systemAdminPayment from '../app/systemAdmin/payment/redux';
import subscriptionPlan from '../app/shared/subscriptionPlan/redux';
import customizedPlan from '../app/systemAdmin/plans/redux';
import bankDetails from '../app/expert/manulaPayments/redux';

export default combineReducers({
  authentication,
  alert,
  registeration,
  createPassword,
  toasts,
  loader,
  forgotPassword,
  resetPassword,
  updateProfile,
  tags,
  payment,
  goals,
  bids,
  learner,
  selectTags,
  searchTags,
  rfpRequest,
  scheduledEvent,
  viewProfile,
  notification,
  chatMessages,
  card,
  sendFeedback,
  dashboard,
  logs,
  users,
  applicationFeedBack,
  contentManagement,
  systemAdmimUser,
  systemAdminDashboard,
  home,
  expertPayments,
  systemAdminPayment,
  subscriptionPlan,
  customizedPlan,
  bankDetails,
});
