import { userServices } from './../../../../services';
import { endLoading, startLoading } from './../../../loader/redux/actions';
import { actionTypes } from './types';
import { handleError } from './../../../../utils';
import OpenFieldTypes from 'OpenFieldTypes';
import { alertActions } from './../../../alert/redux/actions';
import { learnerProfileAction } from './../../../expert/updateProfile/redux/actions';

export const manualPaymentActions = {
  updateRegion,
  getBankInfo,
};

function updateRegion(data: any) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.updateRegion(data)
      .then(
        (res) => {
          dispatch(learnerProfileAction.getProfile());
          dispatch(getBankInfo());
          dispatch(endLoading('MAIN_LOADER'));
          const response = res;
          dispatch(alertActions.successAlert(res));
          dispatch(success(response));
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };

  function success(res: any) { return { res, type: actionTypes.ACCOUNT_DETAIL_SAVE_SUCCESS }; }
  function failure(error: string) { return { error, type: actionTypes.ACCOUNT_DETAIL_SAVE_FAILURE }; }
}

function getBankInfo() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.getBankInfo()
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          const response = res;
          dispatch(success(response));
        },
        (error) => {
          handleError(dispatch, failure, error.toString());
        },
      );
  };

  function success(res: any) { return { res, type: actionTypes.ACCOUNT_DETAIL_FETCH_SUCCESS }; }
  function failure(error: string) { return { error, type: actionTypes.ACCOUNT_DETAIL_FETCH_FAILURE }; }
}
