import { actionTypes } from './types';

interface IAction {
  type: string;
  data: {};
}

export function card(state = {}, action: IAction) {
  switch (action.type) {
    case actionTypes.CARDS_LISTING_REQUEST:
      return { requestingCardListing: true };
    case actionTypes.CARDS_LISTING_SUCCESS:
      return {
        requestedCardListingSuccess: true,
        list: action.data && action.data['data'] ? action.data['data'] : null,
        default_source: action.data && action.data['default_source'] ? action.data['default_source'] : null,
      };
    case actionTypes.ADD_PAYMENT_CARD_REQUEST:
      return { requestingAddingCard: true };
    case actionTypes.ADD_PAYMENT_CARD_SUCCESS:
      return { requestedAddingCardSuccess: true };
    case actionTypes.DELETE_PAYMENT_CARD_SUCCESS:
      const findIndex = state['list'].findIndex((val) => val['id'] === action['data']['cardId']);
      state['list'].splice(findIndex, 1);
      return {
        ...state,
        requestedDeletedCardSuccess: true,
      };
    case actionTypes.UPDATE_PAYMENT_CARD_SUCCESS:
      return {
        ...state,
        default_source: action.data && action.data['cardId'] ? action.data['cardId'] : null,
      };
    case actionTypes.CARDS_LISTING_FAILURE:
    case actionTypes.ADD_PAYMENT_CARD_FAILURE:
    case actionTypes.RESET_STATE:
      return {};
    default:
      return state;
  }
}
