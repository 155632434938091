import React, { Fragment, Dispatch } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import queryString from 'query-string';
import { history } from '../../../helpers';
import { ListingContainer } from './listings/listingContainer';
import OpenFieldTypes from 'OpenFieldTypes';
import { Sidebar } from '../../shared/sidebar';
import { staticConstants, BID_MANAGEMENT_CONST } from '../../../utils';

interface IProps {
  isOpenModal: boolean;
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  location?: any;
}

interface IState {
  isOpenModal: boolean;
  fetchReqParams: any;
  activeTab: string;
}

class ManagementContainer extends React.Component<IProps, IState> {

  public static getDerivedStateFromProps(props: IProps) {
     const newState = {};
     if (props.location) {
      const queryParams = props.location.search;
      const { tab } = queryString.parse(queryParams);
      newState['activeTab'] = tab || staticConstants.BID_MANAGEMENT_TAB.NEW_REQUEST;
    }
     if (!props.location || !props.location.search) {
      history.replace({ search: `tab=${staticConstants.BID_MANAGEMENT_TAB.NEW_REQUEST}` });
    }
     return newState;
   }
  constructor(props: IProps) {
    super(props);
    this.state = {
      isOpenModal: false,
      fetchReqParams: {},
      activeTab: this.props.location.state ? this.props.location.state.activeTab : staticConstants.BID_MANAGEMENT_TAB.NEW_REQUEST,
    };
    this.openCreateLearnerModal = this.openCreateLearnerModal.bind(this);
  }

  public componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.hasOwnProperty('isOpenModal')) {
      this.setState({
        isOpenModal: nextProps.isOpenModal,
      });
    }
  }

  public openCreateLearnerModal(fetchReqParams: any) {
    this.setState({
      isOpenModal: true,
      fetchReqParams,
    });
  }

  public handleActiveTabs = (activeTab: string) => {
    this.setState({ activeTab });
    history.replace({
      search: `tab=${activeTab}`,
    });
  }

  public render() {
    const { activeTab } = this.state;
    return (
      <Fragment>
        <Sidebar {...this.props} />
        <div className="dashboard-wrapper">
          <h2 className="heading heading-sm roboto-medium text-uppercase">Bid Management</h2>
          <div className="dashboard-content">
            <div className="tab-btn-group multi-tab-buttons button-count-5">
              <Button
                color="secondary"
                className={`btn h-50 tab-btn text-uppercase ${activeTab === staticConstants.BID_MANAGEMENT_TAB.NEW_REQUEST ? BID_MANAGEMENT_CONST.ACTIVE_CLASS : ''}`}
                onClick={() => this.handleActiveTabs(staticConstants.BID_MANAGEMENT_TAB.NEW_REQUEST)}
              >
                New Requests
            </Button>
              <Button
                color="secondary"
                className={`btn h-50 tab-btn text-uppercase ${activeTab === staticConstants.BID_MANAGEMENT_TAB.OPEN_REQUEST ? BID_MANAGEMENT_CONST.ACTIVE_CLASS : ''}`}
                onClick={() => this.handleActiveTabs(staticConstants.BID_MANAGEMENT_TAB.OPEN_REQUEST)}
              >
                Open Requests
            </Button>
              <Button
                color="secondary"
                className={`btn h-50 tab-btn text-uppercase ${activeTab === staticConstants.BID_MANAGEMENT_TAB.SAVED_REQUEST ? BID_MANAGEMENT_CONST.ACTIVE_CLASS : ''}`}
                onClick={() => this.handleActiveTabs(staticConstants.BID_MANAGEMENT_TAB.SAVED_REQUEST)}
              >
                Saved Requests
            </Button>
              <Button
                color="secondary"
                className={`btn h-50 tab-btn text-uppercase ${activeTab === staticConstants.BID_MANAGEMENT_TAB.SENT_BIDS ? BID_MANAGEMENT_CONST.ACTIVE_CLASS : ''}`}
                onClick={() => this.handleActiveTabs(staticConstants.BID_MANAGEMENT_TAB.SENT_BIDS)}
              >
                Sent Bids
            </Button>
              <Button
                color="secondary"
                className={`btn h-50 tab-btn text-uppercase ${activeTab === staticConstants.BID_MANAGEMENT_TAB.REJECTED_BIDS ? BID_MANAGEMENT_CONST.ACTIVE_CLASS : ''}`}
                onClick={() => this.handleActiveTabs(staticConstants.BID_MANAGEMENT_TAB.REJECTED_BIDS)}
              >
                Closed Bids
            </Button>
            </div>
            <ListingContainer activeTab={activeTab} />
          </div>
        </div>
      </Fragment>
    );
  }
}

const connectedManagementPage = connect(null)(ManagementContainer);
export { connectedManagementPage as ManagementContainer };
