import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ModalFooter,
} from 'reactstrap';
import OpenFieldTypes from 'OpenFieldTypes';
import { IValidator } from './../../../interfaces';
import { reeValidators, MAGIC_NUMBER } from '../../../utils';
import ReeValidate from 'ree-validate';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  isOpenModal: boolean;
  onClickYes: any;
  ratePerHour?: any;
  hideModal(): void;
}

interface IState {
  modal: boolean;
  errors?: any;
  formData: {
    ratePerHour: number;
    comment: string;
  };
}

class ChangeRatePerHourPopUp extends React.Component<IProps, IState> {
  public validator: IValidator;
  constructor(props: IProps) {
    super(props);
    this.validator = new ReeValidate({
      comment: reeValidators.required_min_3_max_500,
      ratePerHour: 'required',
    });

    this.state = {
      modal: props.isOpenModal,
      formData: {
        ratePerHour: props.ratePerHour,
        comment: '',
      },
      errors: this.validator.errors,
    };
  }

  public hideModal = () => {
    this.setState({
      modal: false,
    });
    this.props.hideModal();
  }

  public handleChange = (event: any) => {
    const { name, value } = event.target;
    const { errors } = this.validator;
    this.setState({ formData: { ...this.state.formData, [name]: value } });
    errors.remove(name);
    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors });
      });
  }

  public validateAndSubmit = (e) => {
    e.preventDefault();
    const { ratePerHour, comment } = this.state.formData;
    const { errors } = this.validator;
    this.validator.validateAll({ ratePerHour, comment })
      .then((success: boolean) => {
        if (success) {
          this.hideModal();
          this.handleSubmit();
        } else {
          this.setState({ errors });
        }
      });
  }

  public handleSubmit() {
    const obj = {};
    obj['ratePerHour'] = this.state.formData.ratePerHour;
    obj['comment'] = this.state.formData.comment;
    this.props.onClickYes(obj);
  }

  public render() {
    const { modal, formData, errors } = this.state;
    return (
      <Modal isOpen={modal} className="organization-code-modal">
        <ModalBody>
          <h2 className="heading heading-lg roboto-bold text-center my-4">Change Request for Rate</h2>
          <Form onSubmit={this.validateAndSubmit}>
            <FormGroup
              className={`floating-label disabled-input price-placeholder ${
                errors.has('ratePerHour') ? ' has-error' : ''
                }`}>
              <Input
                type="number"
                className="form-control"
                name="ratePerHour"
                id="ratePerHour"
                value={formData.ratePerHour}
                onChange={this.handleChange}
              />
              <em>$</em>
              <Label for="ratePerHour" className="selected">Rate Per Hour</Label>
              {errors.has('ratePerHour') &&
                <div className="error-text">{errors.first('ratePerHour').replace('ratePerHour', 'rate per hour')}</div>
              }
            </FormGroup>
            <FormGroup
              className={`floating-label disabled-input textarea-label ${
                errors.has('comment') ? ' has-error' : ''
                }`}>
              <textarea
                className="form-control textarea-md"
                name="comment"
                id="comment"
                value={formData.comment}
                onChange={this.handleChange}
                maxLength={MAGIC_NUMBER.CHAR_LENGTH}
                placeholder="Supporting Statement"
              />
              <span className="text-count">{formData.comment.length}/500</span>
              <Label for="summary" className={formData.comment ? 'selected' : ''}>Supporting Statement</Label>
              {errors.has('comment') &&
                <div className="error-text">{errors.first('comment').replace('comment', 'supporting statement')}</div>
              }
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-regular mr-2" onClick={this.hideModal}>Cancel</Button>{' '}
          <Button color="primary" onClick={this.validateAndSubmit}>Send Request</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const connectedChangeRatePerHourPopUp = connect()(ChangeRatePerHourPopUp);
export { connectedChangeRatePerHourPopUp as ChangeRatePerHourPopUp };
