import React from 'react';
import {
    Navbar,
    NavbarBrand,
    NavLink,
  } from 'reactstrap';
import logoImage from './../../../assets/images/logo.png';

interface IState {
  headerScroll?: any;
}

interface IProps {}

export default class BrandingLogo extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      headerScroll: false,
    };
  }

  /**
   * @description componentDidMount is used when component is loaded
   */
  public componentDidMount() {
    document.addEventListener('scroll', this.handleScroll);
  }

  public handleScroll = () => {
    const headerTop = window.scrollY;
    if (headerTop >= 10) {
      this.setState({
        headerScroll: true,
      });
    } else {
      this.setState({
        headerScroll: false,
      });
    }
  }

  public render() {
    return (
        <Navbar light expand="md" className={`${this.state.headerScroll ? 'header-shadow' : ''} `}>
          <NavbarBrand>
            <NavLink href="/">
              <img src={logoImage} alt="brand logo" />
            </NavLink>
          </NavbarBrand>
        </Navbar>
    );
  }
}
