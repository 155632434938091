import { map, startCase, toLower } from 'lodash';
import moment from 'moment-timezone';
import OpenFieldTypes from 'OpenFieldTypes';
import { alertActions } from './../app/alert/redux/actions';
import { endLoading } from './../app/loader/redux/actions';
import { store } from '../helpers';
import { POSTCODE_REGEXES } from '../utils/postcodeRegex';
import { availableDays, goalStatus, nameTitle, notificationType, pageRoutes, staticConstants, MAGIC_NUMBER } from './../utils';
import { message } from 'react-toastify-redux';

export const handleResponse = (response: any) => {
  const data = response.data;
  if (!data || (response.errors && response.errors.length > 0)
    && response.errors.length > 0) {
    if (response.errors[0].message) {
      return Promise.reject(response.errors[0].message);
    }
    return Promise.reject(response.errors);
  }
  return data;
};

export const handleError = (dispatch: OpenFieldTypes.RootAction, failure: any, error: string) => {
  dispatch(endLoading('MAIN_LOADER'));
  dispatch(failure(error));
  dispatch(alertActions.errorAlert(error));
};

export const getBase64 = (file: File, cb: any) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    cb(reader.result);
  };
};

export const getRedirectPathAfterLogin = (userData: { role: string; isPaid: boolean }) => {
  const role = userData.role;
  let path: string;
  if (role === staticConstants.ROLE.ORGANIZATION_ADMIN) {
    path = (!userData.isPaid) ?
      `${pageRoutes.PAYMENT.PATH}${pageRoutes.PAYMENT.SUBSCRIPTION}` :
      `${pageRoutes.ORGADMIN.PATH}${pageRoutes.ORGADMIN.DASHBOARD}`;
  }
  if (role === staticConstants.ROLE.EXPERT) {
    path = `${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.DASHBOARD}`;
  }
  if (role === staticConstants.ROLE.ORGANIZATION_LEARNER) {
    path = `${pageRoutes.LEARNER.PATH}${pageRoutes.LEARNER.DASHBOARD}`;
  }
  if (role === staticConstants.ROLE.PROFESSIONAL) {
    path = `${pageRoutes.PROFESSIONAL.PATH}${pageRoutes.PROFESSIONAL.DASHBOARD}`;
  }
  if (role === staticConstants.ROLE.SYSTEM_ADMIN) {
    path = `${pageRoutes.SYSTEM_ADMIN.PATH}${pageRoutes.SYSTEM_ADMIN.DASHBOARD}`;
  }
  return path;
};

export const getLocalUserData = () => {
  return JSON.parse(localStorage.getItem('user'));
};

export const getFeedBackStatus = () => {
  return localStorage.getItem('feedback');
};

export const getUpdatedOptions = (arr: any, key: string) => {
  const updateArr = [];
  if (arr) {
    for (const val of arr) {
      if (val[key] !== '') {
        updateArr.push({
          value: val._id,
          label: val[key],
        });
      }
    }
  }
  return updateArr;
};

export const updateSelectedOptions = (obj: { value: string; label: string }) => {
  return {
    id: obj.value,
    name: obj.label,
  };
};

export const getSelectedValue = (obj: any) => {
  return obj ? obj.value : '';
};

export const updateKeyLocalUser = (key: string, value: string | boolean) => {
  const user = JSON.parse(localStorage.getItem('user'));
  user[key] = value;
  localStorage.setItem('user', JSON.stringify(user));
};

export const getRandomString = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength: number = characters.length;
  for (let i = 0; i < length; i = i + 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const mapTagstoAPIRequest = (tags: Array<{ label: string; value: string }>) => {
  const newTags = [];
  tags.forEach((element) => {
    newTags.push({
      name: element['label'],
      id: element['value'],
    });
  });

  return newTags;
};

export const mapTagsFromAPIRequest = (tags: any) => {
  const newTags = [];
  tags.forEach((element) => {
    newTags.push({
      label: element['name'],
      value: element['_id'] || element['id'],
    });
  });

  return newTags;
};

export const mapEducationFromAPIRequest = (education: string) => {
  return {
    value: education,
    label: education,
  };
};

export const capitalizeEveryWord = (word: string) => {
  return startCase(toLower(word));
};

export const mapNameTitleFromApi = (title: any) => nameTitle.find(t => t.value === title);

export const revertSelectedOptions = (obj: { id: string; name: string; _id?: string }) => {
  return {
    value: obj.id || obj._id,
    label: obj.name,
  };
};

export const mapGroupsFromApi = (obj) => {
  return {
    value: obj._id || obj.groupName,
    label: obj.groupName,
  };
};

export const selectAllCheckboxes = (tagName: any) => {
  const items = document.getElementsByName(tagName);
  for (let i = 0; i < items.length; i = i + 1) {
    if (items[i]['type'] === 'checkbox') {
      items[i]['checked'] = true;
    }
  }
};

export const unSelectAllCheckboxes = (tagName: any) => {
  const items = document.getElementsByName(tagName);
  for (let i = 0; i < items.length; i = i + 1) {
    if (items[i]['type'] === 'checkbox') {
      items[i]['checked'] = false;
    }
  }
};

export const getGoalStatusText = (status: string, role = '') => {
  switch (status) {
    case goalStatus.CREATED: return 'Created';
    case goalStatus.RFP_REQUEST: return 'Request Sent';
    case goalStatus.NEW_REQUEST: return 'Acceptance Pending';
    case goalStatus.REQUEST_SEND_BY_LERNER: return 'Acceptance Pending';
    case goalStatus.ACCEPT: return (role === staticConstants.ROLE.EXPERT ? 'Proposal Accepted' : 'Accepted');
    case goalStatus.REJECT: return 'Rejected';
    case goalStatus.RESCHEDULED:
    case goalStatus.SCHEDULED: return 'Scheduled';
    case goalStatus.PROPOSED: return 'Proposed';
    case goalStatus.BID_ACCEPT: return 'Accepted';
    case goalStatus.DELETED: return 'Deleted';
    case goalStatus.EVENT_COMPLETED: return 'Event Completed';
    case goalStatus.EVENT_CANCELLED: return 'Event Cancelled';
    default: return 'Created';
  }
};

export const getGoalStatusTextTitle = (status: string, role = '') => {
  switch (status) {
    case goalStatus.CREATED: return 'Goal Created';
    case goalStatus.RFP_REQUEST: return 'Request Sent';
    case goalStatus.NEW_REQUEST: return 'Acceptance Pending';
    case goalStatus.REQUEST_SEND_BY_LERNER: return 'Acceptance Pending';
    case goalStatus.ACCEPT: return (role === staticConstants.ROLE.EXPERT ? 'Proposal Accepted' : 'Goal Accepted');
    case goalStatus.REJECT: return 'Goal Rejected';
    case goalStatus.SCHEDULED: return 'Event Scheduled';
    case goalStatus.PROPOSED: return 'Proposed';
    case goalStatus.BID_ACCEPT: return 'Accepted';
    case goalStatus.DELETED: return 'Goal Deleted';
    case goalStatus.EVENT_COMPLETED: return 'Event Completed';
    default: return 'Goal Created';
  }
};

export const getIncomeLogStatus = (status: string) => {
  switch (status) {
    case staticConstants.PAYMENT_STATUS.PENDING: return 'Pending';
    case staticConstants.PAYMENT_STATUS.ON_HOLD: return 'On Hold';
    case staticConstants.PAYMENT_STATUS.DISPUTED: return 'On Hold';
    case staticConstants.PAYMENT_STATUS.RESOLVED: return 'Resolved';
    case staticConstants.PAYMENT_STATUS.ABORTED: return 'Aborted';
    case staticConstants.PAYMENT_STATUS.ADDON: return 'Addon';
    case staticConstants.PAYMENT_STATUS.REFUND_TRANSFER: return 'Refund Transfer';
    case staticConstants.PAYMENT_STATUS.SUBSCRIPTION: return 'Subscription';
    case staticConstants.PAYMENT_STATUS.PAYMENT_TRANSFERED: return 'Payment Transfer';
    case staticConstants.PAYMENT_STATUS.PAYMENT_RELEASED: return 'Payment Released';
    case staticConstants.PAYMENT_STATUS.PAYMENT_FAILURE: return 'Payment Failer';
    case staticConstants.PAYMENT_STATUS.ORG_ADMIN_REFUND_DISPUTE: return 'Refund Dispute';
    case staticConstants.PAYMENT_STATUS.EXPERT_TRANSFER_DISPUTE: return 'Expert Transfer Dispute';
    case staticConstants.PAYMENT_STATUS.EVENT_CANCEL_AMOUNT_REFUND: return 'Event Cancel Refund';
    case staticConstants.PAYMENT_STATUS.EVENT_CANCEL_AMOUNT_TRANSFER_EXPERT:
    case staticConstants.PAYMENT_STATUS.EVENT_CANCEL_AMOUNT_SYSTEM_ADMIN: return 'Event Cancel Transfer';
    default: return 'Pending';
  }
};

// Note:- comment this code for show search result with single char also
// export type matchFromType = 'start' | 'any';
// const matchFrom = 'start';

export const reactSelectFilters = {
  ignoreCase: true,
  ignoreAccents: false,
  trim: true,
  // Note:- comment this code for show search result with single char also
  // matchFrom: matchFrom as matchFromType,
};

export const customSelectStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#4CE59E' : '#ffffff',
  }),
};

export const converDateToUserTimeZone = (dt: string) => {
  const userData = getLocalUserData();
  const timezone = userData['timeZone'];
  const tzOffset = moment.tz(timezone).utcOffset();
  if (tzOffset < 0) {
    return new Date(moment.utc(dt).add(Math.abs(tzOffset), 'm').valueOf());
  }
  return new Date(moment.utc(dt).subtract(Math.abs(tzOffset), 'm').valueOf());

};

export const showDateToUserTimeZone = (dt: string) => {
  const userData = getLocalUserData();
  const timezone = userData['timeZone'];
  const tzOffset = moment.tz(timezone).utcOffset();
  const date = tzOffset < 0 ?
    new Date(moment.utc(dt).add(Math.abs(tzOffset), 'm').valueOf()) :
    new Date(moment.utc(dt).subtract(Math.abs(tzOffset), 'm').valueOf());
  return moment(date).format(staticConstants.DATE_FORMAT);
};

export const getModeOfDeliveryText = (status: string) => {
  switch (status) {
    case 'audio': return 'Audio';
    case 'video': return 'Video';
    case 'both': return 'Audio and Video';
    default: return '';
  }
};

export const inOrderDays = days => {
  const list = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  return list.filter(each => days.includes(each));
};

/* @description  return days in  Monday, Tuesday format */
export const renderDaysOfAvailability = (days: string[]) => {
  if (days && days.length) {
    if (days.length === availableDays.length) {
      return 'All Days';
    }
    const updatedDays = map(inOrderDays(days), (availableDay) => {
      return availableDay.charAt(0).toUpperCase() + availableDay.slice(1).toString();
    });
    return updatedDays.join(', ');
  }
  return '';
};

export const checkAddOn = () => {
  const localUser = getLocalUserData();
  return localUser['isAddOn'] || localUser['role'] === staticConstants.ROLE.SYSTEM_ADMIN;
};

export const getNotificationText = (notification: any) => {
  const operation = notification.operation;
  const notifierId = notification.notifierId;
  const actionOnUserId = notification.actionOnUserId;
  const userId = notification.userId;
  const goalId = notification.goalId;
  const bid = notification.bidId;
  const oldEmail = notification.oldEmail;
  const newEmail = notification.newEmail;
  const amount = notification.amount;
  const message = notification.message;
  const localData = getLocalUserData();
  switch (operation) {
    case notificationType.EXTEND_EVENT: return ` The Event ${goalId.title} has been extended for ${notification.duration / MAGIC_NUMBER.SIXTY} ${notification.duration / MAGIC_NUMBER.SIXTY > 1 ? 'hrs' : 'hr'}.`;
    case notificationType.WELCOME: return `Welcome ${notifierId.firstName} ${notifierId.lastName}! It's great to have you onboard.`;
    case notificationType.RFP_REQUEST_LEARNER: return `A Request has been raised on your learning goal, ${goalId.title}.`;
    case notificationType.REJECT_BID: return `Your proposal for ${goalId.title} has been not selected.`;
    case notificationType.DELETE_BID: return `Your bid has been deleted on ${goalId.title}.`;
    case notificationType.LICENCE_MOVEMENT_APPROVE: return `Your request to exceed monthly limit of licence movement between learners has been approved by System Admin`;
    case notificationType.LICENCE_MOVEMENT_REJECT: return `Your request to exceed monthly limit of licence movement between learners has been denied by System Admin`;
    case notificationType.DELETE_GOAL:
      return (localData._id === notifierId._id) ? `You have deleted your Goal, ${goalId.title}.` :
        `Your goal, ${goalId.title}, has been deleted by the ${notifierId.firstName} ${notifierId.lastName}.`;
    case notificationType.ACCEPT_BID: return `The event for ${goalId.title}  is scheduled for
    ${bid ? moment(bid.proposedDate).format(staticConstants.DATE_FORMAT) : ''} with ${userId.firstName} ${userId.lastName}.`;
    case notificationType.SCHEDULE_EVENT: return `Event has been scheduled on ${goalId.title}.`;
    case notificationType.SHORTLIST_BID: return `Your bid on ${goalId.title} has been shortlisted.`;
    case notificationType.EXPERT_REGISTER:
      if (userId) {
        return `You have received a new expert request from ${userId.firstName} ${userId.lastName}.`;
      }
      break;

    case notificationType.EXPERT_ADDED:
      if (userId) {
        return `You have added a new expert to the system, ${userId.firstName} ${userId.lastName}.`;
      }
      break;

    case notificationType.LICENCE_MOVEMENT_REQUEST:
      return `You have received a new licence movement request from ${userId.firstName} ${userId.lastName}.`;
    case notificationType.EDIT_GOAL:
      return `${goalId.title} has been edited.`;
    case notificationType.PAYMENT_RECEIVE: return `Payment has been released for completion of ${goalId.title}.`;
    case notificationType.REQUEST_ADMIN: return `You have received a new request.`;
    case notificationType.REVIEW_REQUEST: return `New request to check goal by ${userId.firstName} ${userId.lastName} on their goal ${goalId.title}.`;
    case notificationType.ACCEPT_GOAL: return `Your goal has been accepted by ${userId.firstName} ${userId.lastName}.`;
    case notificationType.REJECT_GOAL: return `Your goal has been rejected by ${userId.firstName} ${userId.lastName}.`;
    case notificationType.RFP_REQUEST_EXPERT: return `You have received a new request.`;
    case notificationType.SENT_BID: return `A new bid for ${goalId.title} has been received.`;
    case notificationType.FLAG_USER_SYSTEM_ADMIN: return `${actionOnUserId.firstName} ${actionOnUserId.lastName} has been flagged by ${userId.firstName} ${userId.lastName}.`;
    case notificationType.HOURLY_CHANGE_REQUEST_SYSTEM_ADMIN: return `You have received a rate change request from ${userId.firstName} ${userId.lastName}.`;
    case notificationType.PAYMENT_HELD_SYSTEM_ADMIN: return `The payment for ${userId.firstName} ${userId.lastName} has been held for the ${goalId.title}.`;
    case notificationType.CANCEL_EVENT: return `The event, ${goalId.title} has been cancelled.`;
    case notificationType.REHIRE_EXPERT: return `Rehired: You have been requested to deliver another session by ${userId.firstName} ${userId.lastName}`;
    case notificationType.RESCHEDULE_REQUESTED: return `A request to reschedule bid on ${goalId ? goalId.title : ''} has been received.`;
    case notificationType.RESCHEDULE_RESPONSE_RECEIVED: return `A rescheduled bid has been received on ${goalId ? goalId.title : ''}.`;
    case notificationType.RESCHEDULEREQUESTEXPIRED: return `Request to reschedule bid by ${userId.firstName} ${userId.lastName} on ${goalId ? goalId.title : ''} has expired.`;
    case notificationType.LEARNER_GOAL_ACCECPTED: return `${userId.firstName} ${userId.lastName} has accepted your ${goalId.title} goal.`;
    case notificationType.LEARNER_GOAL_REJECTED: return `${userId.firstName} ${userId.lastName} has rejected your ${goalId.title} goal.`;
    case notificationType.LEARNER_EVENT_ACCEPTED: return `Event has been scheduled on ${goalId.title}.`;
    case notificationType.GOAL_CREATED_FOR_LEARNERS: return `${userId.firstName} ${userId.lastName} has added you to a new goal, ${goalId.title}.`;
    case notificationType.BID_CANCELED_BY_EXPERT: return `A bid on ${goalId.title} has been cancelled.`
    case notificationType.ZOOM_MEETING_CONFLICTS: return `Zoom meeting conflicts having title ${goalId.title}.`
    case notificationType.EMAIL_CHANGE: return `Email changed successfully from ${oldEmail} to ${newEmail}`
    case notificationType.PROCESSED_WITHDRAW_REQUEST: return `Your payment request of $${amount} is in processed. It will take 4- 5 working days to reflect in your account`;
    case notificationType.MESSAGE_REQUEST: return `You have a new message - ${message}`;
    default: return '';
  }
  return '';
};

const getGoalNotificationLink = (role: string, notification: any) => {
  const { goalId } = notification;
  if (!goalId) { return null; }
  if (goalId && (goalId.status === goalStatus.SCHEDULED || goalId.status === goalStatus.PAYMENT_RELEASED)) {
    return { link: `${pageRoutes.EVENTMANAGEMENT.PATH}${pageRoutes.EVENTMANAGEMENT.DETAIL}/${goalId._id}`, stateVars: {} };
  }
  if ([goalStatus.CREATED, goalStatus.ACCEPT, goalStatus.REJECT, goalStatus.REQUEST_SEND_BY_LERNER]
    .indexOf(notification.goalId.status) > -1) {
    return { link: `${pageRoutes.GOALMANAGEMENT.PATH}${pageRoutes.GOALMANAGEMENT.DETAIL}/${goalId._id}`, stateVars: {} };
  }
  if ([goalStatus.RFP_REQUEST].indexOf(notification.goalId.status) > -1 && role === pageRoutes.EXPERT.PATH) {
    return { link: `${pageRoutes.EVENTMANAGEMENT.PATH}?tab=${staticConstants.EVENT_MANAGEMENT_TAB.UPCOMING_EVENTS}`, stateVars: {} };
  }
  if ([goalStatus.RFP_REQUEST]
    .indexOf(notification.goalId.status) > -1) {
    return { link: `${pageRoutes.REQUESTMANAGEMENT.PATH}${pageRoutes.REQUESTMANAGEMENT.DETAIL}/${goalId._id}`, stateVars: { isRequestManagment: true } };
  }
  if (goalId && goalId._id) {
    return { link: `${pageRoutes.GOALMANAGEMENT.PATH}${pageRoutes.GOALMANAGEMENT.DETAIL}/${goalId._id}`, stateVars: {} };
  }
  return { link: null, stateVars: {} };
};
const getViewProfileNotificationLink = (profileData: { role: string; _id: string }) => {
  const { role, _id } = profileData;
  switch (role) {
    case staticConstants.ROLE.ORGANIZATION_ADMIN:
      return `${pageRoutes.VIEW_PROFILE_ORGADMIN.PATH}/${_id}`;
    case staticConstants.ROLE.EXPERT:
      return `${role}${pageRoutes.EXPERT.VIEW_PROFILE.PATH}/${_id}`;
    case staticConstants.ROLE.PROFESSIONAL:
      return `${pageRoutes.VIEW_PROFILE_PROFESSIONAL.PATH}/${_id}`;
    case staticConstants.ROLE.ORGANIZATION_LEARNER:
      return `${pageRoutes.VIEW_PROFILE_LEARNER.PATH}/${_id}`;
    default: return null;
  }
};

export const getNotificationLink = (notification: any) => {
  const operation = notification.operation;
  const actionOnUserId = notification.actionOnUserId;
  switch (operation) {
    case notificationType.MESSAGE_REQUEST:
    return { link: `${pageRoutes.EXPERT.MESSAGE.PATH}`};
    case notificationType.WELCOME: return ``;
    case notificationType.REJECT_BID:
      return { link: `${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.BID_MANAGEMENT.PATH}?tab=${staticConstants.BID_MANAGEMENT_TAB.REJECTED_BIDS}` };
    case notificationType.DELETE_BID:
    case notificationType.ACCEPT_BID:
    case notificationType.SHORTLIST_BID:
    case notificationType.CANCEL_BID_EXPERT:
      return getGoalNotificationLink(pageRoutes.EXPERT.PATH, notification);
    case notificationType.SCHEDULE_EVENT:
    case notificationType.EDIT_GOAL:
    case notificationType.REQUEST_ADMIN:
    case notificationType.ACCEPT_GOAL:
    case notificationType.RFP_REQUEST_LEARNER:
    case notificationType.REJECT_GOAL:
    case notificationType.SEND_RFP:
    case notificationType.SENT_BID:
    case notificationType.EXTEND_EVENT:
    case notificationType.LEARNER_GOAL_ACCECPTED:
    case notificationType.LEARNER_GOAL_REJECTED:
    case notificationType.CANCEL_EVENT:
      return getGoalNotificationLink('', notification);
    case notificationType.MEMBERSHIP: return `${pageRoutes.ORGADMIN.PATH}${pageRoutes.ORGADMIN.VIEW_PROFILE}`;
    case notificationType.FLAG_USER_SYSTEM_ADMIN: return { link: getViewProfileNotificationLink(actionOnUserId) };
    case notificationType.HOURLY_CHANGE_REQUEST_SYSTEM_ADMIN:
      return { link: `/${staticConstants.ROLE.SYSTEM_ADMIN}${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.PATH}?tab=${staticConstants.USER_MANAGEMENT_TAB.EXPERTS}&filter=${staticConstants.USER_MANAGEMENT_EXPERT_FILTER_TAB.HOURLY_CHANGE_REQUEST}` };
    case notificationType.EXPERT_REGISTER:
      return { link: `/${staticConstants.ROLE.SYSTEM_ADMIN}${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.PATH}?tab=${staticConstants.USER_MANAGEMENT_TAB.EXPERTS}&filter=${staticConstants.USER_MANAGEMENT_EXPERT_FILTER_TAB.REQUEST}` };
    case notificationType.LICENCE_MOVEMENT_REQUEST:
      return { link: `/${staticConstants.ROLE.SYSTEM_ADMIN}${pageRoutes.SYSTEM_ADMIN.USER_MANAGEMENT.PATH}?tab=${staticConstants.USER_MANAGEMENT_TAB.ORG_ADMIN}&filter=${staticConstants.USER_MANAGEMENT_ORG_ADMIN_FILTER_TAB.LICENCE_MOVEMENT_REQUESTS}` };
    case notificationType.REHIRE_EXPERT:
      return { link: `/${staticConstants.ROLE.EXPERT}${pageRoutes.EXPERT.BID_MANAGEMENT.PATH}?tab=${staticConstants.BID_MANAGEMENT_TAB.NEW_REQUEST}` };
    case notificationType.RESCHEDULE_REQUESTED:
      if (!notification.goalId) {
        return { link: `/${staticConstants.ROLE.EXPERT}${pageRoutes.EXPERT.BID_MANAGEMENT.PATH}?tab=${staticConstants.BID_MANAGEMENT_TAB.SENT_BIDS}` };
      }
      return { link: `/${staticConstants.ROLE.EXPERT}${pageRoutes.EXPERT.BID_MANAGEMENT.PATH}${pageRoutes.EXPERT.BID_MANAGEMENT.DETAIL}/${notification.goalId._id}` };
    case notificationType.RESCHEDULE_RESPONSE_RECEIVED:
      return { link: `${pageRoutes.REQUESTMANAGEMENT.PATH}` };
    case notificationType.REVIEW_REQUEST:
      return { link: `${pageRoutes.GOALMANAGEMENT.PATH}${pageRoutes.GOALMANAGEMENT.DETAIL}/${notification.goalId._id}`, stateVars: {} };
    case notificationType.GOAL_CREATED_FOR_LEARNERS:
      return { link: `${pageRoutes.GOALMANAGEMENT.PATH}${pageRoutes.GOALMANAGEMENT.DETAIL}/${notification.goalId._id}`, stateVars: {} };
    case notificationType.BID_CANCELED_BY_EXPERT:
      return { link: `${pageRoutes.GOALMANAGEMENT.PATH}${pageRoutes.GOALMANAGEMENT.DETAIL}/${notification.goalId._id}`, stateVars: {} };
    default: return '';
  }
};

export const getFilterTypes = (value: any, role: any) => {
  const reqArr = [];
  if (role === staticConstants.ROLE.ORGANIZATION_ADMIN) {
    if (value === 'learningGoals') {
      reqArr.push(notificationType.REQUEST_ADMIN);
    }
    if (value === 'scheduledEvents') {
      reqArr.push(notificationType.SCHEDULE_EVENT);
      reqArr.push(notificationType.CANCEL_EVENT);
    }
    if (value === 'requests') {
      reqArr.push(notificationType.SENT_BID);
    }
    if (value === 'membership') {
      reqArr.push(notificationType.MEMBERSHIP);
    }
    if (value === 'eventExtended') {
      reqArr.push(notificationType.EXTEND_EVENT);
    }
    if (value === 'emailChange') {
      reqArr.push(notificationType.EMAIL_CHANGE);
    }
    if (value === 'messageRequest') {
      reqArr.push(notificationType.MESSAGE_REQUEST);
    }
  }
  if (role === staticConstants.ROLE.ORGANIZATION_LEARNER) {
    if (value === 'learningGoals') {
      reqArr.push(notificationType.ACCEPT_GOAL);
      reqArr.push(notificationType.REJECT_GOAL);
      reqArr.push(notificationType.DELETE_GOAL);
      reqArr.push(notificationType.RFP_REQUEST_LEARNER);
    }
    if (value === 'scheduledEvents') {
      reqArr.push(notificationType.SCHEDULE_EVENT);
      reqArr.push(notificationType.CANCEL_EVENT);
    }
  }
  if (role === staticConstants.ROLE.EXPERT) {
    if (value === 'requests') {
      reqArr.push(notificationType.RFP_REQUEST_EXPERT);
    }
    if (value === 'scheduledEvents') {
      reqArr.push(notificationType.ACCEPT_BID);
    }
    if (value === 'rejectBid') {
      reqArr.push(notificationType.REJECT_BID);
    }
    if (value === 'eventExtended') {
      reqArr.push(notificationType.EXTEND_EVENT);
    }
    if (value === 'processedWithdrawrequest') {
      reqArr.push(notificationType.PROCESSED_WITHDRAW_REQUEST);
    }
    if (value === 'emailChange') {
      reqArr.push(notificationType.EMAIL_CHANGE);
    }
    if (value === 'messageRequest') {
      reqArr.push(notificationType.MESSAGE_REQUEST);
    }
  }

  if (role === staticConstants.ROLE.PROFESSIONAL) {
    if (value === 'scheduledEvents') {
      reqArr.push(notificationType.SCHEDULE_EVENT);
      reqArr.push(notificationType.CANCEL_EVENT);
    }
    if (value === 'requests') {
      reqArr.push(notificationType.SENT_BID);
    }
    if (value === 'membership') {
      reqArr.push(notificationType.MEMBERSHIP);
    }
    if (value === 'eventExtended') {
      reqArr.push(notificationType.EXTEND_EVENT);
    }
    if (value === 'emailChange') {
      reqArr.push(notificationType.EMAIL_CHANGE);
    }
    if (value === 'messageRequest') {
      reqArr.push(notificationType.MESSAGE_REQUEST);
    }
  }

  if (role === staticConstants.ROLE.SYSTEM_ADMIN) {
    if (value === 'hourlyChangeRequestSystemAdmin') {
      reqArr.push(notificationType.HOURLY_CHANGE_REQUEST_SYSTEM_ADMIN);
    }
    if (value === 'paymentHeldSystemAdmin') {
      reqArr.push(notificationType.PAYMENT_HELD_SYSTEM_ADMIN);
    }
    if (value === 'flagUserSystemAdmin') {
      reqArr.push(notificationType.FLAG_USER_SYSTEM_ADMIN);
    }
    if (value === 'eventExtended') {
      reqArr.push(notificationType.EXTEND_EVENT);
    }
  }
  return reqArr.join(',');
};

export const getMyUserId = () => {
  if (getLocalUserData()) {
    return getLocalUserData()._id;
  }
  if (store.getState().authentication.user) {
    return store.getState().authentication.user._id;
  }
  return null;
};

export const hashString = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash += Math.pow(str.charCodeAt(i) * MAGIC_NUMBER.THIRTY_ONE, str.length - i);
    // tslint:disable-next-line: no-bitwise
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};

export const formatCurrency = (curValue: any = 0) => {
  const amount = new Intl.NumberFormat().format(curValue);
  const numberVal: number = Number(amount);
  return isNaN(numberVal) ? amount : numberVal.toFixed(2);
};

export const validatePostalCode = (countryCode: string, postalCode: string): boolean => {
  if (POSTCODE_REGEXES.hasOwnProperty(countryCode.toUpperCase())) {
    return POSTCODE_REGEXES[countryCode.toUpperCase()].test(postalCode);
  }
  return true;
};

export const restrictSpaceEnter = (e) => {
  if (e.keyCode === MAGIC_NUMBER.THIRTY_TWO || e.keyCode === MAGIC_NUMBER.THIRTEEN) {
    e.preventDefault();
  }
};

export function checkLocalStorageGoal(dispatch: OpenFieldTypes.RootAction, createGoal) {
  const goal = localStorage.getItem('goal') ? JSON.parse(localStorage.getItem('goal')) : null;
  const user = JSON.parse(localStorage.getItem('user'));
  localStorage.removeItem('goal');
  if (!goal || !goal.duration || [staticConstants.ROLE.ORGANIZATION_ADMIN, staticConstants.ROLE.PROFESSIONAL].indexOf(user.role) === MAGIC_NUMBER.FOUND_INDEX) { return; }
  dispatch(createGoal(goal));
}
