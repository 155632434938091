import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Button,
} from 'reactstrap';
import { staticConstants, CSS_ClASSES } from '../../../utils/staticConstants';
import { Sidebar } from '../sidebar';
import { tagAction } from './../tags/redux/actions';
import { ILocalUser } from './../../../interfaces';
import OpenFieldTypes from 'OpenFieldTypes';
import { ListingContainer } from './listings/listingContainer';
import { GoalRequestListing } from './listings/goalRequestListing';
import { history } from '../../../helpers';
import queryString from 'query-string';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  user: ILocalUser;
  role: string;
  location: any;
}

class GoalManagementContainer extends React.Component<IProps> {

  public state = {
    activeTab: null,
  };

  /**
   * @description componentDidMount is called when component is loaded
   * call goalAction.getGoals to fetch goal listings
   */
  public componentDidMount() {
    const { location: { search } } = this.props;
    const activeTab = this.props.role === staticConstants.ROLE.ORGANIZATION_ADMIN ?
      staticConstants.GOAL_MANAGEMENT_TAB.ADMIN_LEARNING_GOALS
      : staticConstants.GOAL_MANAGEMENT_TAB.MY_LERNERING_GOALS;

    if (!this.props.location.search) {
      history.push({ search: `?tab=${activeTab}` });
      this.setState({
        activeTab,
      });
    } else if (queryString.parse(search).tab) {
      history.push({ search: `?tab=${queryString.parse(search).tab}` });
      this.setState({ activeTab: queryString.parse(search).tab });
    }
  }

  /**
   * @description componentWillUnmount is used when component destroyed
   * call tagAction.resetAllTags() to reset the tags
   */
  public componentWillUnmount() {
    this.props.dispatch(tagAction.resetAllTags());
  }

  public handleActiveTabs = (activeTab: string) => {
    history.replace({ search: `?tab=${activeTab}` });
    this.setState({ activeTab });
  }

  /**
   * @description
   * render is used to render the html.
   */
  public render() {
    const { activeTab } = this.state;
    const role = this.props.role;
    return (
      <React.Fragment>
        <Sidebar {...this.props} />
        <div className="dashboard-wrapper">
          <h2 className="heading heading-sm roboto-medium text-uppercase">Goal Management</h2>
          <div className="dashboard-content">
            <div className="tab-btn-group white-space-normal">
              {
                role === staticConstants.ROLE.ORGANIZATION_ADMIN &&
                <>
                  <Button
                    color="secondary"
                    className={`btn btn-50 h-50 tab-btn text-uppercase ${activeTab === staticConstants.GOAL_MANAGEMENT_TAB.ADMIN_LEARNING_GOALS ? CSS_ClASSES.btnActive : ''}`}
                    onClick={() => this.handleActiveTabs(staticConstants.GOAL_MANAGEMENT_TAB.ADMIN_LEARNING_GOALS)}
                  >
                    Admin Goals
              </Button>
                  <Button
                    color="secondary"
                    className={`btn btn-50 h-50 tab-btn text-uppercase ${activeTab === staticConstants.GOAL_MANAGEMENT_TAB.LERNER_GOAL_REQUESTS ? CSS_ClASSES.btnActive : ''}`}
                    onClick={() => this.handleActiveTabs(staticConstants.GOAL_MANAGEMENT_TAB.LERNER_GOAL_REQUESTS)}
                  >
                    Team Goals
              </Button>
                </>
              }
              {
                role === staticConstants.ROLE.ORGANIZATION_LEARNER &&
                <>
                  <Button
                    color="secondary"
                    className={`btn btn-50 h-50 tab-btn text-uppercase ${activeTab === staticConstants.GOAL_MANAGEMENT_TAB.MY_LERNERING_GOALS ? CSS_ClASSES.btnActive : ''}`}
                    onClick={() => this.handleActiveTabs(staticConstants.GOAL_MANAGEMENT_TAB.MY_LERNERING_GOALS)}
                  >
                    My Goals
              </Button>
                  <Button
                    color="secondary"
                    className={`btn btn-50 h-50 tab-btn text-uppercase ${activeTab === staticConstants.GOAL_MANAGEMENT_TAB.ADMIN_LEARNING_GOALS ? CSS_ClASSES.btnActive : ''}`}
                    onClick={() => this.handleActiveTabs(staticConstants.GOAL_MANAGEMENT_TAB.ADMIN_LEARNING_GOALS)}
                  >
                    Admin Goals
              </Button>
                </>
              }
            </div>
            {
              (role === staticConstants.ROLE.ORGANIZATION_ADMIN
                && activeTab === staticConstants.GOAL_MANAGEMENT_TAB.ADMIN_LEARNING_GOALS) &&
              <ListingContainer />
            }

            {
              (role === staticConstants.ROLE.ORGANIZATION_ADMIN
                && activeTab === staticConstants.GOAL_MANAGEMENT_TAB.LERNER_GOAL_REQUESTS) &&
              <GoalRequestListing />
            }

            {
              (role === staticConstants.ROLE.ORGANIZATION_LEARNER
                && activeTab === staticConstants.GOAL_MANAGEMENT_TAB.MY_LERNERING_GOALS) &&
              <ListingContainer isGoalsCreatedMyLerner={true} />
            }

            {
              (role === staticConstants.ROLE.ORGANIZATION_LEARNER
                && activeTab === staticConstants.GOAL_MANAGEMENT_TAB.ADMIN_LEARNING_GOALS) &&
              <ListingContainer isGoalsCreatedMyLerner={false} />
            }
            {role === staticConstants.ROLE.PROFESSIONAL &&
              <ListingContainer />
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  const { authentication } = state;
  const { user } = authentication;
  return {
    user,
    role: user.role,
  };
}

const connectedGoalManagementContainer = connect(mapStateToProps)(GoalManagementContainer);
export { connectedGoalManagementContainer as GoalManagementContainer };
