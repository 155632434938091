import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Modal,
  Form,
  FormGroup,
  Label,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Row,
  Col,
} from 'reactstrap';
import OpenFieldTypes from 'OpenFieldTypes';
import { IValidator, IReschedule, IGoalDetailWithId } from '../../../interfaces';
import ReeValidate from 'ree-validate';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { setHours, setMinutes } from 'date-fns';
import { rfpAction } from '../requestManagement/redux/actions';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  isOpenModal: boolean;
  hideModal: any;
  goal: IGoalDetailWithId;
  bidId: string;
}

interface IState {
  modal: boolean;
  errors?: any;
  formData?: IReschedule;
  minDate?: Date;
  maxDate?: Date;
  minTime?: Date;
  maxTime?: Date;
  goal?: IGoalDetailWithId;
  isExpired?: boolean;
  isTimeError: any;
  btnText: string;
}

class ReschedulePopup extends React.Component<IProps, IState> {
  public validator: IValidator;
  constructor(props: IProps) {
    super(props);

    this.validator = new ReeValidate({
      proposedDate: 'required',
      proposedTime: 'required',
      fromTime: 'required',
    });
    this.state = {
      modal: props.isOpenModal,
      isExpired: false,
      btnText: 'Reschedule',
      formData: {
        proposedDate: null,
        proposedTime: null,
        fromTime: null,
        bidId: null,
      },
      isTimeError: false,
      errors: this.validator.errors,
    };
    this.hideModal = this.hideModal.bind(this);
  }

  public componentDidMount = () => {
    if (this.props.goal) {
      const formData = this.state.formData;
      const minTimeDateUTC = new Date(this.props.goal.fromTime);
      const maxTimeDateUTC = new Date(this.props.goal.toTime);
      const minDate = moment(this.props.goal.fromDate).isBefore() ? new Date() : new Date(this.props.goal.fromDate);
      const maxDate = new Date(this.props.goal.toDate);
      formData['bidId'] = this.props.bidId;

      this.setState({
        isExpired: moment(maxDate).isBefore(),
        minDate,
        maxDate,
        modal: this.props.isOpenModal,
        goal: this.props.goal,
        minTime: setHours(setMinutes(new Date(), minTimeDateUTC.getMinutes()), minTimeDateUTC.getHours()),
        maxTime: setHours(setMinutes(new Date(), maxTimeDateUTC.getMinutes()), maxTimeDateUTC.getHours()),
        formData,
      });
    }

  }
  public validateAndSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const { proposedDate, proposedTime, fromTime } = this.state.formData;
    const { errors } = this.validator;

    this.validator.validateAll({ proposedDate, proposedTime, fromTime })
      .then((success: boolean) => {
        if (success) {
          const beginningTime = moment(fromTime, 'h:mma');
          const endTime = moment(proposedTime, 'h:mma');
          if (moment(fromTime).isSame(proposedTime) || !beginningTime.isBefore(endTime)) {
            this.setState({ isTimeError: 'To Time can not be less or equal than from time.' });
          } else {
            this.handleSubmit();
          }
        } else {
          this.setState({ errors });
        }
      });
  }

  public handleSubmit = () => {
    const { dispatch, goal } = this.props;
    const formData = this.state.formData;
    const data: IReschedule = formData;
    data.proposedTime = new Date(moment(data.proposedDate).hours(moment(data.proposedTime).hours()).minutes(moment(data.proposedTime).minutes()).format());
    data.fromTime = new Date(moment(data.fromTime).hours(moment(data.fromTime).hours()).minutes(moment(data.fromTime).minutes()).format());
    dispatch(rfpAction.rescheduleRequest(data, goal['_id']));
    this.hideModal();
  }
  public removeErrorAndReValidate = (name: string) => {
    const { errors } = this.validator;
    errors.remove(name);
    this.validator.validate(name, this.state.formData[name])
      .then(() => {
        this.setState({ errors, isTimeError: false });
      });
  }

  public handleChange = (e: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const { errors } = this.validator;
    this.setState({
      [name]: value,
    } as any);
    errors.remove(name);
    this.setState({ formData: { ...this.state.formData, [name]: value } });

    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors });
      });
  }
  public handleDateChange = (date: Date, name: string) => {
    this.setState({ formData: { ...this.state.formData, [name]: date } }, () => {
      this.removeErrorAndReValidate(name);
    });
  }

  public hideModal() {
    this.setState({
      modal: false,
      formData: {
        proposedDate: null,
        proposedTime: null,
        fromTime: null,
        bidId: null,
      },
    });
    const { errors } = this.validator;
    errors.clear();

    this.props.hideModal();
  }

  public render() {
    const { modal, errors, formData, minDate, isExpired, isTimeError } = this.state;
    return (
      <Modal isOpen={modal} className="make-bid-modal" onClosed={this.hideModal}>
        <ModalHeader toggle={this.hideModal}>Reschedule</ModalHeader>
        <ModalBody >
          <Form onSubmit={this.validateAndSubmit} autoComplete="off" noValidate>
            <Label > Select a new time and date availability below: </Label>
            <Row>
              <Col xs="12">
                <FormGroup
                  className={`floating-label  disabled-input datepicker-wrapper ${
                    errors.has('firstName') ? ' has-error' : ''
                    }`}>
                  <DatePicker
                    placeholderText="Date"
                    className="form-control"
                    dateFormat="MMM dd, yyyy"
                    onChange={e => this.handleDateChange(e, 'proposedDate')}
                    selected={formData.proposedDate}
                    minDate={minDate}
                    showYearDropdown
                    showMonthDropdown
                    strictParsing />
                  <Label for="proposedDate" className={formData.proposedDate ? 'selected' : ''}>Proposed Date</Label>
                  <span className="icon icon-calendar" />
                  {errors.has('proposedDate') &&
                    <div className="error-text">
                      {errors.first('proposedDate').replace('proposedDate', 'Proposed Date')}</div>
                  }
                </FormGroup>
              </Col>
              <Col xs="12">
                <FormGroup
                  className={`floating-label disabled-input time-form${
                    errors.has('lastName') ? ' has-error' : ''
                    }`}
                >
                  <DatePicker
                    placeholderText="From Time"
                    className="form-control"
                    selected={formData.fromTime}
                    onChange={(e) => this.handleDateChange(e, 'fromTime')}
                    showTimeSelect
                    showTimeSelectOnly
                    dateFormat="hh:mm aa"
                    strictParsing
                  />
                  <Label for="fromTime" className={formData.fromTime ? 'selected' : ''}>From Time</Label>
                  <span className="icon icon-clock" />
                  {errors.has('fromTime') &&
                    <div className="error-text">{errors.first('fromTime').replace('fromTime', 'From Time')}
                    </div>
                  }
                </FormGroup>
              </Col>
              <Col xs="12">
                <FormGroup
                  className={`floating-label disabled-input time-form${
                    errors.has('lastName') ? ' has-error' : ''
                    }`}
                >
                  <DatePicker
                    placeholderText="To Time"
                    className="form-control"
                    selected={formData.proposedTime}
                    onChange={(e) => this.handleDateChange(e, 'proposedTime')}
                    showTimeSelect
                    showTimeSelectOnly
                    dateFormat="hh:mm aa"
                    strictParsing
                  />
                  <Label for="proposedTime" className={formData.proposedTime ? 'selected' : ''}>To Time</Label>
                  <span className="icon icon-clock" />
                  {errors.has('proposedTime') &&
                    <div className="error-text">{errors.first('proposedTime').replace('proposedTime', 'To Time')}
                    </div>
                  }
                  {isTimeError &&
                    <div className="error-text">{isTimeError}</div>
                  }
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button className="btn btn-regular mr-2" onClick={this.hideModal}>Cancel</Button>{' '}
          <Button color="primary" disabled={isExpired} onClick={this.validateAndSubmit}>Send</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const connectedReschedulePopupPage = connect()(ReschedulePopup);
export { connectedReschedulePopupPage as ReschedulePopup };
