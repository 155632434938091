import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import OpenFieldTypes from 'OpenFieldTypes';
import { history } from './../../../../helpers/';
import { notificationAction } from '../redux/actions';
import { getNotificationText, getNotificationLink } from '../../../../utils';
import ReactTimeAgo from 'react-time-ago/tooltip';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  notification: any;
  isTopListing: boolean;
  hideNotificationMenu?: any;
}

class NotificationItem extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.moveToDetailPage = this.moveToDetailPage.bind(this);
  }

  public moveToDetailPage (path: {link: string; stateVars: {}}) {
    history.push(path.link, path.stateVars);
  }

  /**
   * @description
   * markAsRead is called to mark the notification status to read
   */
  public markAsRead = (id: string, path: any) => {
    if (this.props.notification && !this.props.notification.isRead) {
      this.props.dispatch(notificationAction.markAsRead({
        notificationId: id,
      }));
    }
    this.moveToDetailPage(path);
  }

  /**
   * @description render is used to render the html
   */
  public render() {
    const notificationText = getNotificationText(this.props.notification);
    const notificationLink = getNotificationLink(this.props.notification);
    const isRead = this.props.notification.isRead;
    if (this.props.isTopListing) {
      return (
          <div className="notification-row" onClick={() => { this.markAsRead(this.props.notification._id, notificationLink); }}>
              <p>{notificationText} <em>{this.props.notification && this.props.notification.createdAt ?
              <em><><ReactTimeAgo date={new Date(this.props.notification.createdAt)}/></></em>
              : ''}</em></p>
          </div>
      );
    }
    return (
        <div className={isRead ? `notification-panel` : `notification-panel unread`}
        onClick={() => { this.markAsRead(this.props.notification._id, notificationLink); }}>
          <p><span>{notificationText}</span></p>
          {this.props.notification && this.props.notification.createdAt ?
          <em><><ReactTimeAgo date={new Date(this.props.notification.createdAt)}/></></em>
          : ''}
        </div>
    );
  }
}

const notificationItemPage = connect(null)(NotificationItem);
export { notificationItemPage as NotificationItem };
