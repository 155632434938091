import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import OpenFieldTypes from 'OpenFieldTypes';
import profileImage from '../../../../assets/images/profile.png';
import { staticConstants } from '../../../../utils/staticConstants';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  log: any;
  user: any;
}

class LogItem extends React.Component<IProps> {

  /**
   * @description getLogText is used to prepare log text
   */
  public getLogText = (log) => {
    const { data, content } = log;
    const resObj = {
      content,
    };
    for (const key in data) {
      switch (key) {
        case 'date':
          let date;
          date = moment(data[key]).format(staticConstants.DATE_FORMAT);
          resObj['content'] = resObj['content'].replace(`#date`, date);
          break;
        case 'time':
          const time = moment(data[key]).format(staticConstants.TIME_FORMAT);
          resObj['content'] = resObj['content'].replace(`#time`, time);
          break;
      }
    }
    return resObj;
  }

   /**
    * @description render is used to render the html
    */
  public render() {
    const { log } = this.props;
    const logObj = this.getLogText(log);
    const createdDate = moment(log.createdAt).format(staticConstants.LOG_TIME_FORMAT);
    return (
      <div className="notification-panel unread cursor-default">
        <span className="navbar-profile">
          <img src={log['receiverId']['profileImage'] || profileImage} className="img-cover" alt="profile" />
        </span>
        <p>{logObj.content}</p>
        {createdDate ?
          <em>{createdDate}</em>
          : ''}
      </div>
    );
  }
}

const logItemPage = connect(null)(LogItem);
export { logItemPage as LogItem };
