import React, { Component, Dispatch } from 'react';
import _ from 'lodash';
import { CardCVCElement, CardExpiryElement, CardNumberElement } from 'react-stripe-elements';
import classnames from 'classnames';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Nav,
  Row,
  TabContent,
  TabPane,
  Card,
  CardText,
  CardTitle,
  Input,
} from 'reactstrap';
import { ILocalUser, IViewProfile } from '../../../interfaces';
import { staticConstants, EVENT_PAYMENT_TYPES, customSelectStyles } from '../../../utils';
import { alertActions } from './../../alert/redux/actions';
import countryList from 'react-select-country-list';
import Select from 'react-select';
import { DebounceInput } from 'react-debounce-input';
import { validatePostalCode, MAGIC_NUMBER } from './../../../utils';
import OpenFieldTypes from 'OpenFieldTypes';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  profile?: IViewProfile;
  action: string;
  user: ILocalUser;
  defaultSource: string;
  isExpertPurchaseAddon: boolean;
  isAddOn: boolean;
  isPaymentButtonDisable: boolean;
  amount: number;
  amountDue: number;
  taxRate: number;
  licence: number;
  cards: any;
  canAvailTrialPeriod: boolean;
  resetTaxData: () => void;
  fetchTaxRate: (postalCode: string, countryCode: string) => void;
  handleSubmit: (activeTab: string, cardHolderName: string, postalCode: string, country: string, cardId: string) => void;
}

interface IState {
  isPaymentButtonDisable: boolean;
  isPaymentSuccessModalOpen: false;
  activeTab: string;
  isNewCard: boolean;
  cardId: string;
  cardHolderName: string;
  subscriptionProrationDate?: string;
  proRatedAmount?: number;
  postalCode: string;
  countryCode: string;
  isTaxFetched: boolean;
}

class CardDetailForm extends Component<IProps, IState> {

  public cardBrandToPfClass = {
    visa: 'pf-visa',
    mastercard: 'pf-mastercard',
    amex: 'pf-american-express',
    discover: 'pf-discover',
    diners: 'pf-diners',
    jcb: 'pf-jcb',
    unknown: 'pf-credit-card',
  };

  constructor(props: IProps) {
    super(props);
    this.state = {
      isPaymentButtonDisable: true,
      isTaxFetched: false,
      isPaymentSuccessModalOpen: false,
      activeTab: staticConstants.PAYMENT_CARD_TAB.NEW_CARD,
      isNewCard: false,
      cardId: undefined,
      cardHolderName: '',
      postalCode: '',
      countryCode: '',
    };
  }

  /**
   * @description
   * handleChange is used to set the value on state from the only input/textarea boxes.
   * also remove the form validation. Once form validated the forward the control to parenet component
   * @param fields {Object} event
   */
  public handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    this.setState({ cardId: value }, () => {
      const card = this.props.cards.find(ca => ca.id === value);
      if (card) {
        this.props.fetchTaxRate(card.address_zip, card.address_country);
      }

    });

  }

  public setBrandIcon(brand: string) {
    const brandIconElement = document.getElementById('brand-icon');
    let pfClass = 'pf-credit-card';
    if (brand in this.cardBrandToPfClass) {
      pfClass = this.cardBrandToPfClass[brand];
    }
    for (let i = brandIconElement.classList.length - 1; i >= 0; i = i - 1) {
      brandIconElement.classList.remove(brandIconElement.classList[i]);
    }
    brandIconElement.classList.add('pf');
    brandIconElement.classList.add(pfClass);
  }

  public handleCardChange = (event: { brand: string }) => {
    if (event.brand) {
      this.setBrandIcon(event.brand);
    }
  }

  /**
   * @description renderCardListing is called to fetch card listing
   */
  public renderCardListing() {
    const list = this.props.cards;
    return list && _.map(list, (card, index) => {
      let pfClass = 'pf-credit-card';
      const brand = card.brand.toLowerCase();
      if (brand in this.cardBrandToPfClass) {
        pfClass = this.cardBrandToPfClass[brand];
      }
      let isDefaultCard = false;
      if (this.props.defaultSource === card.id) {
        isDefaultCard = true;
        if (!this.state.isTaxFetched) {
          this.updateTax();
          this.setState({ isTaxFetched: true}, () => { });
        }
      }
      return (
        <Card key={index}>
          <label className="control control--radio">
            <input type="radio" id="audio" name="modeOfDevivery" defaultChecked={isDefaultCard}
            value={card.id} onChange={this.handleChange} />
            <div className="control__indicator" />
          </label>
          <CardTitle className="text-truncate">{card.name}</CardTitle>
          <span className="brand"><i className={`pf ${pfClass}`} /></span>
          <CardText>XXXXXXXXXXXX{card.last4}</CardText>
          <CardText>Expiry: {card.exp_month}/{card.exp_year}  </CardText>
        </Card>
      );
    });
  }

  public handleCardHolderChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    this.setState({ cardHolderName: value });
  }

  public handleCountryChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({ countryCode : event['value'] });
    if (event['value']  && this.state.postalCode) {
      validatePostalCode(event['value'], this.state.postalCode) && this.props.fetchTaxRate(this.state.postalCode, event['value']);
      return;
    }
  }

  public handleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ postalCode: value });
      if (String(value).length >= 5 && this.state.countryCode && validatePostalCode(this.state.countryCode, value)) {
        this.props.fetchTaxRate(value, this.state.countryCode);
        return;
      }
  }

  public toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab }, () => {   this.updateTax(); });
    }
    if (this.state.activeTab === staticConstants.PAYMENT_CARD_TAB.SAVED_CARD) {
      this.setState({ isTaxFetched: false}, () => {
        this.props.resetTaxData();
      });
    }
  }

  public updateTax = () => {
    if (this.state.activeTab === staticConstants.PAYMENT_CARD_TAB.NEW_CARD) {
      const { postalCode, countryCode } =  this.state;
        if (postalCode && countryCode && String(postalCode).length >= 5
          && validatePostalCode(countryCode, postalCode)) {
          this.props.fetchTaxRate(postalCode, countryCode);
          return;
        }
    }
    if (this.state.activeTab === staticConstants.PAYMENT_CARD_TAB.SAVED_CARD && this.props.cards) {
      const { cards, defaultSource  } =  this.props;
      const { cardId } =  this.state;
      let  card = null;
      card = cardId ? cards.find(ca => ca.id === cardId) : cards.find(ca => ca.id === defaultSource);
      if (card) {
        this.props.fetchTaxRate(card.address_zip, card.address_country);
      }
    }
  }

  public handleSubmit = (e) => {
    e.preventDefault();
    const { activeTab, cardId } = this.state;
    if (activeTab === staticConstants.PAYMENT_CARD_TAB.NEW_CARD &&
       (!this.props.profile.subscriptionId ||
                        [EVENT_PAYMENT_TYPES.SESSION_FEE, EVENT_PAYMENT_TYPES.EXTEND_EVENT_FEE].indexOf(this.props.action) > -1)) {
      const { countryCode, postalCode } =  this.state;
      if (this.state.cardHolderName.trim().length === MAGIC_NUMBER.ZERO || !postalCode || !countryCode) {
        this.props.dispatch(alertActions.errorAlert(`error: Some of the card information is missing.`));
        return;
      }
      if (postalCode && countryCode && String(postalCode).length >= 5
      && !validatePostalCode(countryCode, postalCode)) {
        this.props.dispatch(alertActions.errorAlert(`error: Invalid zip code.`));
        return;
      }
      this.props.handleSubmit(activeTab, this.state.cardHolderName, postalCode, countryCode, null);
    } else if (activeTab === staticConstants.PAYMENT_CARD_TAB.SAVED_CARD && this.props.cards && this.props.cards.length &&
        [EVENT_PAYMENT_TYPES.SESSION_FEE, EVENT_PAYMENT_TYPES.EXTEND_EVENT_FEE].indexOf(this.props.action) >= -1) {
      const { address_zip: postalCode, countryCode } = this.props.cards.find(el => cardId ? el.id === cardId : el.id === this.props.defaultSource) ;
      this.props.handleSubmit(activeTab, this.state.cardHolderName, postalCode, countryCode, cardId || this.props.defaultSource);
      return;
    }
  }

  public render() {

    const { user: { isPaid }, isPaymentButtonDisable, amount, action, canAvailTrialPeriod } = this.props;

    const { activeTab } = this.state;

    if ((!this.props.cards || this.props.cards.length === MAGIC_NUMBER.ZERO)
      && this.props.isExpertPurchaseAddon
      && activeTab !== staticConstants.PAYMENT_CARD_TAB.NEW_CARD ) {
        this.setState({activeTab: staticConstants.PAYMENT_CARD_TAB.NEW_CARD});
    }
    return (
          <div className="payment-wrapper">
            <h2 className="heading roboto-medium">Payment Details</h2>
            {isPaid &&
              <div className="tab-btn-group">
                <Nav tabs className="border-0">
                  <Button
                    color="secondary"
                    className={`btn btn-50 h-50 tab-btn text-uppercase ${classnames({ 'btn-active': activeTab === staticConstants.PAYMENT_CARD_TAB.SAVED_CARD })}`}
                    onClick={() => { this.toggle(staticConstants.PAYMENT_CARD_TAB.SAVED_CARD); }}>
                    Saved Cards
                  </Button>
                  <Button
                    color="secondary"
                    className={`btn btn-50 h-50 tab-btn text-uppercase ${classnames({ 'btn-active': activeTab === staticConstants.PAYMENT_CARD_TAB.NEW_CARD })}`}
                    onClick={() => { this.toggle(staticConstants.PAYMENT_CARD_TAB.NEW_CARD); }}>
                    Add New Card
                  </Button>
                </Nav>
              </div>
            }
            <TabContent activeTab={activeTab} className="mt-3">
              <TabPane tabId={staticConstants.PAYMENT_CARD_TAB.NEW_CARD}>
              <Form onSubmit={this.handleSubmit} noValidate>
                <div className="m-10">
                  <Row>
                    <Col xs="12" md="12">
                      <FormGroup>
                        <Label>Card Number</Label>
                        <CardNumberElement
                          className="card-input"
                          placeholder="4242 4242 4242 4242"
                          onChange={this.handleCardChange}
                        />
                        <span className="brand"><i className="pf pf-credit-card" id="brand-icon" /></span>
                      </FormGroup>

                    </Col>
                    <Col xs="12" md="6">
                      <FormGroup>
                        <Label>Card Expiry</Label>
                        <CardExpiryElement className="card-input" />
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="6">
                      <FormGroup>
                        <Label>CVC/CVV</Label>
                        <CardCVCElement className="card-input" placeholder="CVV" />
                      </FormGroup>
                    </Col>
                    <Col xs="12" md="12">
                      <FormGroup
                        className="floating-label disabled-input disabled opacity-1"
                      >
                        <Input
                          className="form-control border-0"
                          id="cardHolderName"
                          name="cardHolderName"
                          placeholder="Card Holder Name"
                          value={this.state.cardHolderName}
                          onChange={this.handleCardHolderChange}
                        />
                        <Label for="cardHolderName">Card Holder Name</Label>
                      </FormGroup>
                    </Col>

                    <Col xs="12" md="6">
                    <FormGroup>
                    <Label>Country</Label>
                    <Select
                        name="Country"
                        styles={customSelectStyles}
                        onChange={this.handleCountryChange}
                        options={countryList().getData()}
                        id="Country"
                        placeholder="Country"
                        className="react-select-box select-box-group"
                      />
                      </FormGroup>
                      </Col>
                      <Col xs="12" md="6">
                      <FormGroup>
                        <Label>Postal code</Label>
                        <DebounceInput
                          type="text"
                          className="form-control"
                          minLength={MAGIC_NUMBER.THREE}
                          debounceTimeout={MAGIC_NUMBER.THOUSANT}
                          onChange={this.handleZipCodeChange} />
                      </FormGroup>
                    </Col>
                    <Col xs="12" className="d-flex">
                      <Button color="primary" disabled={isPaymentButtonDisable} className="btn-rg ml-auto">
                        Pay
                      </Button>
                    </Col>
                  </Row>
                </div>
                </Form>
              </TabPane>
              <TabPane tabId={staticConstants.PAYMENT_CARD_TAB.SAVED_CARD}>
                <div className="table-body m-10">
                  <div className="collapse-body add-card-body payment-cards">
                    {this.renderCardListing()}
                  </div>
                  <Col xs="12" className="d-flex">
                    <Button onClick={this.handleSubmit} color="primary" disabled={isPaymentButtonDisable || amount <= 0} className="btn-rg ml-auto">
                    Pay
                    </Button>
                  </Col>
                </div>
              </TabPane>

            </TabContent>

          {/* {
            canAvailTrialPeriod && [EVENT_PAYMENT_TYPES.SESSION_FEE, EVENT_PAYMENT_TYPES.EXTEND_EVENT_FEE].indexOf(action) === MAGIC_NUMBER.FOUND_INDEX  &&
            <p className="mt-5 text-left">
            Your 1-month free trial starts now. Your first payment will be deducted next month. You can cancel anytime during your free trial without charge.
            </p>
            } */}
          </div>

    );
  }
}

export default CardDetailForm;
