export const actionTypes = {
  SCHEDULE_LIST_REQUEST: 'SCHEDULE_LIST_REQUEST',
  SCHEDULE_LIST_SUCCESS: 'SCHEDULE_LIST_SUCCESS',
  SCHEDULE_LIST_FAILURE: 'SCHEDULE_LIST_FAILURE',
  UPDATE_SCHEDULE_REQUEST: 'UPDATE_SCHEDULE_REQUEST',
  UPDATE_SCHEDULE_SUCCESS: 'UPDATE_SCHEDULE_SUCCESS',
  UPDATE_SCHEDULE_FAILED: 'UPDATE_SCHEDULE_FAILED',
  UPDATE_SCHEDULE_STATUS: 'UPDATE_SCHEDULE_STATUS',
  EVENT_DETAIL_REQUEST: 'EVENT_DETAIL_REQUEST',
  EVENT_DETAIL_SUCCESS: 'EVENT_DETAIL_SUCCESS',
  EVENT_DETAIL_FAILURE: 'EVENT_DETAIL_FAILURE',
  RESET_SCHEDULE_STATUS: 'RESET_SCHEDULE_STATUS',
  FLAG_USER_SUCCESS: 'FLAG_USER_SUCCESS',
  FLAG_USER_FAILURE: 'FLAG_USER_FAILURE',
  VALIDATE_ATTENDANCE_SUCCESS: 'VALIDATE_ATTENDANCE_SUCCESS',
  VALIDATE_ATTENDANCE_FAILURE: 'VALIDATE_ATTENDANCE_FAILURE',
  RATING_GOAL_SUCCESS: 'RATING_GOAL_SUCCESS',
  RATING_GOAL_FAILURE: 'RATING_GOAL_FAILURE',
  UPDATE_EXTENT_EVENT_STATUS_REQUEST: 'UPDATE_EXTENT_EVENT_STATUS_REQUEST',
  UPDATE_EXTENT_EVENT_STATUS_SUCCESS: 'UPDATE_EXTENT_EVENT_STATUS_SUCCESS',
  UPDATE_EXTENT_EVENT_STATUS_FAILURE: 'UPDATE_EXTENT_EVENT_STATUS_FAILURE',
  END_MEETING_SUCCESS: 'END_MEETING_SUCCESS',
  START_MEETING_SUCCESS: 'START_MEETING_SUCCESS',
  EXTEND_MEETING_SUCCESS: 'EXTEND_MEETING_SUCCESS',
  EXTEND_MEETING_FAILURE: 'EXTEND_MEETING_SUCCESS',
  UPDATE_EVENT_PARTICIPANTS_SUCCESS: 'UPDATE_EVENT_PARTICIPANTS_SUCCESS',
  UPDATE_EVENT_PARTICIPANTS_FAILURE: 'UPDATE_EVENT_PARTICIPANTS_FAILURE',

  GET_EXTEND_SESSION_FEE_REQUEST: 'GET_EXTEND_SESSION_FEE_REQUEST',
  GET_EXTEND_SESSION_FEE_SUCCESS: 'GET_EXTEND_SESSION_FEE_SUCCESS',
  GET_EXTEND_SESSION_FEE_FAILURE: 'GET_EXTEND_SESSION_FEE_FAILURE',
  CHANGE_EVENT_PARTICIPANTS_SUCCESS: 'CHANGE_EVENT_PARTICIPANTS_SUCCESS',
  CHANGE_EVENT_PARTICIPANTS_FAILURE: 'CHANGE_EVENT_PARTICIPANTS_FAILURE',

  CANCEL_EVENT_SUCCESS: 'CANCEL_EVENT_SUCCESS',

  DOWNLOAD_SESSION_SUCCESS: 'DOWNLOAD_SESSION_SUCCESS',
  DOWNLOAD_SESSION_FAILURE: 'DOWNLOAD_SESSION_FAILURE',

  RESOLVE_EVENT_CONFLICT_SUCCESS: 'RESOLVE_EVENT_CONFLICT_SUCCESS',
  RESOLVE_EVENT_CONFLICT_FAILURE: 'RESOLVE_EVENT_CONFLICT_FAILURE',

};
