import {
    GET_BID_LIST_QUERY, GET_BID_QUERY, GET_BID_DETAILS_QUERY, REFER_BID_QUERY,
} from '../graphql-client/queries/bidsQuery';
import {
    MAKE_BID_MUTATION,
    ADD_FAVOURITE,
    ACCEPT_BID,
    SHORTLIST_BID,
    REJECT_BID,
    CANCEL_BID,
    RESCHEDULE_STATUS,
} from '../graphql-client/mutations';
import { client } from '../index';
import { handleResponse } from '../utils/common';
import { IMakeBid, IReferBid, IRescheduleStatus } from '../interfaces';

export const bidServices = {
  getBids,
  makeBid,
  addToFavourite,
  getBidDetail,
  acceptBid,
  shortlistBid,
  rejectBid,
  cancelBid,
  getBid,
  referBid,
  rescheduleRequest,
};

async function getBids(bid: string) {
  const response = await client.query({
    query: GET_BID_LIST_QUERY,
    variables: bid,
  });
  const resp = await handleResponse(response);
  return resp.rfp;
}

async function makeBid(bid: IMakeBid) {
  const response = await client.query({
    query: MAKE_BID_MUTATION,
    variables: bid,
  });
  const resp = await handleResponse(response);
  if (resp.addBid) {
    resp.addBid['goalId'] = bid;
  }
  return resp.addBid;
}

async function addToFavourite(favourite: any) {
  const response = await client.mutate({
    mutation: ADD_FAVOURITE,
    variables: {
      goalId: favourite.bidId,
      isFavourite: favourite.isFavourite,
    },
  });
  const resp = await handleResponse(response);
  return resp.favourite.data;
}

/**
 * @description function to get bid detail
 * @param {object} bid contain object value
 */
async function getBidDetail(bid: { id: string }) {
  const response = await client.query({
    query: GET_BID_DETAILS_QUERY,
    variables: { id: bid.id },
  });
  const resp = await handleResponse(response);
  return resp.rfpDetail;
}

/**
 * @description function to accept bid
 * @param {string} bidId
 */
async function acceptBid(bidId: string) {
  const response = await client.mutate({
    mutation: ACCEPT_BID,
    variables: { bidId },
  });
  const resp = await handleResponse(response);
  return resp.acceptBid;
}

/**
 * @description function to shortlist bid
 * @param {object} bidObj contain object value
 */
async function shortlistBid(bidObj: { bidId: string; isShortlisted: boolean }) {
  const response = await client.mutate({
    mutation: SHORTLIST_BID,
    variables: bidObj,
  });
  const resp = await handleResponse(response);
  return resp.shortlistBid;
}

/**
 * @description function to shortlist bid
 * @param {object} bidObj contain object value
 */
async function rejectBid(bidObj: {bidId: string}) {
  const response = await client.mutate({
    mutation: REJECT_BID,
    variables: bidObj,
  });
  const resp = await handleResponse(response);
  return resp.rejectBid;
}

/**
 * @description function to cancel bid
 * @param {object} bidId contain object value
 */
async function cancelBid(bidId: object) {
  const response = await client.mutate({
    mutation: CANCEL_BID,
    variables: bidId,
  });
  const resp = await handleResponse(response);
  return resp.cancelBid;
}

/**
 * @description function to get bid only
 * @param {object} bid contain object value
 */
async function getBid(bidId: string) {
  const response = await client.query({
    query: GET_BID_QUERY,
    variables: { bidId },
  });
  const resp = await handleResponse(response);
  return resp.bidDetail.data;
}

async function referBid(referData: IReferBid) {
  const response = await client.query({
    query: REFER_BID_QUERY,
    variables: referData,
  });
  const resp = await handleResponse(response);
  return resp.referExpert;
}

async function rescheduleRequest(data: IRescheduleStatus) {
  const response = await client.query({
    query: RESCHEDULE_STATUS,
    variables: {
      bidId: data['bidId'],
      status: data['status'].toUpperCase(),
      toTimeRequested: data['toTimeRequested']
    },
  });
  const resp = await handleResponse(response);
  return resp.acceptRejectReschedule;
}
