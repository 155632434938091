export const actionTypes = {
  TOTAL_PAYMENTS_REQUEST: 'TOTAL_PAYMENTS_REQUEST',
  TOTAL_PAYMENTS_SUCCESS: 'TOTAL_PAYMENTS_SUCCESS',
  TOTAL_PAYMENTS_FAILURE: 'TOTAL_PAYMENTS_FAILURE',
  PAYMENTS_LIST_REQUEST: 'PAYMENTS_LIST_REQUEST',
  PAYMENTS_LIST_SUCCESS: 'PAYMENTS_LIST_SUCCESS',
  PAYMENTS_LIST_FAILURE: 'PAYMENTS_LIST_FAILURE',
  UPDATE_DISPUTE_SUCCESS: 'UPDATE_DISPUTE_SUCCESS',
  GENERATE_REPORT_REQUEST: 'GENERATE_REPORT_REQUEST',
  GENERATE_REPORT_SUCCESS: 'GENERATE_REPORT_SUCCESS',
  GENERATE_REPORT_FAILURE: 'GENERATE_REPORT_FAILURE',
  RESET_GENERATE_REPORT: 'RESET_GENERATE_REPORT',
  WITHDRAW_LIST_REQUEST: 'withdrawRequestListRequest',
  WITHDRAW_LIST_SUCCESS: 'withdrawRequestListSuccess',
  WITHDRAW_LIST_FAILURE: 'withdrawRequestListFailure',
  WITHDRAW_REPORT_REQUEST: 'WITHDRAW_REPORT_REQUEST',
  WITHDRAW_REPORT_SUCCESS: 'WITHDRAW_REPORT_SUCCESS',
  RESET_WITHDRAW_REPORT: 'RESET_GENERATE_REPORT',
  WITHDRAW_REPORT_FAILURE: 'WITHDRAW_REPORT_FAILURE',
  UPDATE_WITHDRAWN_REQUEST_SUCCESS: 'updateWithdrawnRequestSuccess',
  UPDATE_WITHDRAWN_REQUEST_FAILURE: 'updateWithdrawnRequestFailure',
  WITHDRAWN_HISTORY_LIST_SUCCESS: 'withdrawHistoryListSuccess',
  WITHDRAWN_HISTORY_LIST_FAILURE: 'withdrawHistoryListFailure',
};
