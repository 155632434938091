import { actionTypes } from './types';
interface IAction {
  type: string;
  data: {};
  error: string;
}

const initialState = {
  status: {},
};

export function systemAdmimUser(state = initialState, action: IAction) {
  switch (action.type) {
    case actionTypes.SYSTEM_ADMIN_DETAIL_REQUEST:
      return {
        ...state,
        fetchingUsersdetail: true,
      };
    case actionTypes.SYSTEM_ADMIN_DETAIL_SUCCESS:
      return {
        ...state,
        detail: action.data,
      };
    case actionTypes.UPDTAE_SYSTEM_ADMIN_STATUS_SUCCESS:
      return {
        ...state,
        isProfileUpdate: true,
        fetchingUsersListing: false,
        profile: action.data['data'],
      };
    case actionTypes.UPDTAE_SYSTEM_ADMIN_STATUS_FAILURE:
      return {
        error: action.error,
        isProfileUpdate: false,
        fetchingUsersListing: false,
      };
    case actionTypes.SYSTEM_ADMIN_DETAIL_FAILURE:
    default:
      return state;
  }
}
