export const actionTypes = {
  CATEGORY_LIST_REQUEST: 'CATEGORY_LIST_REQUEST',
  CATEGORY_LIST_SUCCESS: 'CATEGORY_LIST_SUCCESS',
  CATEGORY_LIST_FAILURE: 'CATEGORY_LIST_FAILURE',
  SUB_CATEGORY_LIST_REQUEST: 'SUB_CATEGORY_LIST_REQUEST',
  SUB_CATEGORY_LIST_SUCCESS: 'SUB_CATEGORY_LIST_SUCCESS',
  SUB_CATEGORY_LIST_FAILURE: 'SUB_CATEGORY_LIST_FAILURE',
  DOMAIN_LIST_REQUEST: 'DOMAIN_LIST_REQUEST',
  DOMAIN_LIST_SUCCESS: 'DOMAIN_LIST_SUCCESS',
  DOMAIN_LIST_FAILURE: 'DOMAIN_LIST_FAILURE',
  SUB_DOMAIN_LIST_REQUEST: 'SUB_DOMAIN_LIST_REQUEST',
  SUB_DOMAIN_LIST_SUCCESS: 'SUB_DOMAIN_LIST_SUCCESS',
  SUB_DOMAIN_LIST_FAILURE: 'SUB_DOMAIN_LIST_FAILURE',
  TOPIC_LIST_REQUEST: 'TOPIC_LIST_REQUEST',
  TOPIC_LIST_SUCCESS: 'TOPIC_LIST_SUCCESS',
  TOPIC_LIST_FAILURE: 'TOPIC_LIST_FAILURE',
  ACTIVITY_LIST_REQUEST: 'ACTIVITY_LIST_REQUEST',
  ACTIVITY_LIST_SUCCESS: 'ACTIVITY_LIST_SUCCESS',
  ACTIVITY_LIST_FAILURE: 'ACTIVITY_LIST_FAILURE',
  RESET_ALL_TAGS: 'RESET_ALL_TAGS',
  TAG_LIST_REQUEST: 'TAG_LIST_REQUEST',
  TAG_LIST_SUCCESS: 'TAG_LIST_SUCCESS',
  TAG_LIST_FAILURE: 'TAG_LIST_FAILURE',
  EXPERT_COUNT_REQUEST: 'EXPERT_COUNT_REQUEST',
  EXPERT_COUNT_SUCCESS: 'EXPERT_COUNT_SUCCESS',
  EXPERT_COUNT_FAILURE: 'EXPERT_COUNT_FAILURE',
  CREATE_TAG_REQUEST: 'CREATE_TAG_REQUEST',
  CREATE_TAG_SUCCESS: 'CREATE_TAG_SUCCESS',
  CREATE_TAG_FAILURE: 'CREATE_TAG_FAILURE',
  REMOVE_TAG_SUCCESS: 'REMOVE_TAG_SUCCESS',
  REMOVE_TAG_FAILURE: 'REMOVE_TAG_FAILURE',
  TAG_SET_STATUS: 'TAG_SET_STATUS',
  TAG_RESET_STATUS: 'TAG_RESET_STATUS',
  PUBLISH_TAG_SUCCESS: 'PUBLISH_TAG_SUCCESS',
  PUBLISH_TAG_FAILURE: 'PUBLISH_TAG_FAILURE',
  EDIT_TAG_SUCCESS: 'EDIT_TAG_SUCCESS',
  EDIT_TAG_FAILURE: 'EDIT_TAG_FAILURE',
};
