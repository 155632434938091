export const actionTypes = {
  APPLICATION_FEEDBACK_LIST_REQUEST: 'APPLICATION_FEEDBACK_LIST_REQUEST',
  APPLICATION_FEEDBACK_LIST_SUCCESS: 'APPLICATION_FEEDBACK_LIST_SUCCESS',
  APPLICATION_FEEDBACK_LIST_FAILURE: 'APPLICATION_FEEDBACK_LIST_FAILURE',
  DELETE_FEEDBACK_SUCCESS: 'DELETE_FEEDBACK_SUCCESS',
  DELETE_FEEDBACK_FAILURE: 'DELETE_FEEDBACK_FAILURE',
  SEND_REPLY_FEEDBACK_SUCCESS: 'SEND_REPLY_FEEDBACK_SUCCESS',
  SEND_REPLY_FEEDBACK_FAILURE: 'SEND_REPLY_FEEDBACK_FAILURE',
  MESSAGE_REQUEST_SUCCESS: 'MESSAGE_REQUEST_SUCCESS',
  MESSAGE_REQUEST_FAILURE: 'MESSAGE_REQUEST_FAILURE',
};
