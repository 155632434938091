import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import OpenFieldTypes from 'OpenFieldTypes';
import { profileAction } from './redux/actions';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { pageRoutes } from '../../../utils';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  match?: any;
  profile: any;
}

class VerifyEmailContainer extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  public componentWillMount = () => {
    const { token } = this.props.match.params;
    this.props.dispatch(profileAction.verifyChangeEmailToken(token));
  }

  public render () {
    const { profile } = this.props;
    return (
      <div className="container-fluid">
      <div className="row min-vh-100">
        <div className="col-lg-6 col-md-12 login_bg">
          <div className="login_banner_text">
            <p>Everyone is going to need to understand [climate change] the same way you’d assume everyone in business needs to have some fluency in social media today.</p>
            <h5>Andrew Winston</h5>
            <p className="text-right">Author</p>
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="login_form">
            <div className="row">
              <div className="col-12 link-verified-section">
                {profile && !profile.verifyingToken ?
                  (<div className="login_details">
                      { !profile.isValidToken &&
                        <div>
                          <h2 className="common_heading mt-2"> Error!</h2>
                          <p className="mb-4">This link has already been verified</p>
                        </div>
                      }
                      { profile.isValidToken &&
                        <div>
                          <h2 className="common_heading mt-2">Email Verification Successful!</h2>
                          <p className="mb-4">
                            The link has been verified successfuly
                          </p>
                        </div>
                      }
                      <Link className="w-100" to={`${pageRoutes.LOGIN}`}>
                        <Button color="get_start_btn" className="get_start_btn cta_btn d-block text-center w-100">
                          Done
                        </Button>
                      </Link>
                    </div>
                  ) :''
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { updateProfile } = state;
  return {
    profile: updateProfile,
  };
}

const connectedVerifyEmailForm = connect(mapStateToProps)(VerifyEmailContainer);
export { connectedVerifyEmailForm as VerifyEmailContainer };
