import React from 'react';
import { connect } from 'react-redux';
import {
  TabContent,
  TabPane,
  UncontrolledTooltip
} from 'reactstrap';

interface IProps {
  activeTab?: string;
  content: Array<[]>;
  openModal: any;
  openPopup: any;
  onEdit: any;
}

interface IState {
  modal: any;
  faqId: string;
  modalPopupObj: object;
}

class FaqContainer extends React.Component<IProps, IState> {

  public state = {
    modal: true,
    modalPopupObj: {},
    faqId: '',
  };

  /**
   * @description open the faq modal on the click of add faq button
   */
  public openFaqModal = () => {
    this.props.openModal();
  }

   /**
    * @description
    * showDeletePopup is used show delete popup.
    */
  public showDeletePopup(id) {
    this.props.openPopup(id);
  }

  public updateFaq(id) {
    this.props.onEdit(id);
  }

  public render() {
    const { content } = this.props;
    return (
      <React.Fragment>
            { content && content.length !== 0 ? content.map((data: any, index: any) => {
              return (
                <TabContent key={index}>
                <TabPane>
                  <div className="faq-list-wrapper">
                    <div className="faq-list">
                      <h2 className="heading heading-md roboto-medium">{data['question']}
                      <span>
                        <em className="icon icon-edit" id={`tooltip_update_${data['_id']}`} onClick={() => this.updateFaq(data['_id'])} />
                        <UncontrolledTooltip placement="top" target={`tooltip_update_${data['_id']}`} >Update</UncontrolledTooltip>
                        <em className="icon icon-delete" id={`tooltip_delete_${data['_id']}`} onClick={() => this.showDeletePopup(data['_id'])} />
                        <UncontrolledTooltip placement="top" target={`tooltip_delete_${data['_id']}`} >Delete</UncontrolledTooltip>
                      </span>
                      </h2>
                      <p>{data['answer']}</p>
                      {data['faqType'] ? <p className="faq-type">- {data['faqType'].toLowerCase()}</p>:''}
                    </div>
                  </div>
                </TabPane>
              </TabContent>
              );
            }) :
              <div className="table-row table-no-data text-center">
                No Results Found.
                  </div>
              }
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: any) {
  return {
  };
}

const connectedFaqContainer = connect(mapStateToProps)(FaqContainer);
export { connectedFaqContainer as FaqContainer };
