export const firestoreQueryConstants = {
  ROOMS: 'rooms',
  CHAT: 'chat',
  MESSAGE_BOARD: 'messageBoard',
  MESSAGES: 'messages',
  GOAL_ID: 'goalId',
  CHAT_ROOMS: 'ChatRooms',
  CHAT_HISTORY: 'history',
  TIMESTAMP: 'timeStamp',
  BROADCAST: 'broadcast',
};
