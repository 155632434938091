import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import ReeValidate from 'ree-validate';
import logoImage from './../../../assets/images/logo.svg';
import { forgotPasswordAction } from './redux/actions';
import { IValidator } from './../../../interfaces';
import OpenFieldTypes from 'OpenFieldTypes';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
}

interface IState {
  errors?: any;
  formData: { email: string };
}
class ForgotPasswordForm extends React.Component<IProps, IState> {
  public validator: IValidator;
  constructor(props: IProps) {
    super(props);
    this.validator = new ReeValidate({
      email: 'required|email',
    });
    this.state = {
      formData: { email: '' },
      errors: this.validator.errors,
    };
  }

  public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const { errors } = this.validator;
    this.setState({ formData: { ...this.state.formData, [name]: value } });
    errors.remove(name);
    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors });
      });
  }

  public validateAndSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const { email } = this.state.formData;
    const { errors } = this.validator;

    this.validator.validateAll({ email })
      .then((success: boolean) => {
        if (success) {
          this.handleSubmit();
        } else {
          this.setState({ errors });
        }
      });

  }

  public handleSubmit = () => {
    const { formData } = this.state;
    const { dispatch } = this.props;
    dispatch(forgotPasswordAction.forgotPassword(formData.email));
  }

  public render() {
    const { formData, errors } = this.state;
    return (
      <div className="container-fluid">
        <div className="row min-vh-100">
          <div className="col-lg-6 col-md-12 login_bg">
            <div className="login_banner_text">
              <p>Everyone is going to need to understand [climate change] the same way you’d assume everyone in business needs to have some fluency in social media today.</p>
              <h5>Andrew Winston</h5>
              <p className="text-right">Author</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="login_form">
              <div className="row">
                <div className="col-12">
                  <div className="login_details">
                    <Link to="/"> <img src={logoImage} alt="Logo" className="img-fluid mb-4" /></Link>
                    <h2 className="common_heading mt-2">Forgot password</h2>
                    <p className="mb-4">Enter your email address to reset your password.</p>

                    {/* <div className="form-group">
                      <div className="common_input">
                        <input type="text" id="loginpassword" className="form-control" required />
                        <label >Enter your registered email address</label>
                      </div>
                      <div className="text-right forget_pass mt-1">
                        <a href="login.html">Log In</a>
                      </div>
                      <div className="login_button mt-4">
                        <a className="get_start_btn cta_btn d-block text-center" href="#">Submit</a>
                      </div>
                    </div> */}
                    <Form onSubmit={this.validateAndSubmit}>
                      <FormGroup
                        className={`floating-label with-action ${
                          errors.has('email') ? ' has-error' : ''
                          }`}>
                        <Input
                          type="text"
                          className="form-control"
                          name="email"
                          id="email"
                          value={formData.email}
                          onChange={this.handleChange}
                          placeholder="Enter your registered email address"
                        />
                        <Label for="email">Enter your registered email address</Label>
                        {errors.has('email') &&
                          <div className="error-text">{errors.first('email')}</div>
                        }
                        <div className="text-right forget_pass mt-1">
                          <Link to="/login" color="light">Log In</Link>
                        </div>
                      </FormGroup>
                      <div className="login_button mt-3">
                        <Button color="get_start_btn" className="get_start_btn cta_btn d-block text-center w-100">
                          Submit
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { registering } = state.registeration;
  return {
    registering,
  };
}

const connectedForgotPasswordPage = connect(mapStateToProps)(ForgotPasswordForm);
export { connectedForgotPasswordPage as ForgotPasswordForm };
