import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import ReeValidate from 'ree-validate';
import {
  Button,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import { IValidator } from '../../../interfaces';
import { validator } from './../../../helpers';
import { IViewProfile } from '../../../interfaces/index';
import OpenFieldTypes from 'OpenFieldTypes';
import { viewProfileAction } from './redux/action';

validator(ReeValidate);

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  viewProfile: IViewProfile;
}

interface IState {
  formData: {
    notifications: boolean;
    linkedIn: string;
    // google: string;
    twitter: string;
    facebook: string;
  };
  errors: any;
}

class ProfileSettingPage extends React.Component<IProps, IState> {
  public validator: IValidator;

  constructor(props: IProps) {
    super(props);
    this.validator = new ReeValidate({
      notifications: 'required',
      linkedIn: 'verify_linkedin_url',
      // google: 'verify_google_url',
      twitter: 'verify_twitter_url',
      facebook: 'verify_facebook_url',
    });

    this.state = {
      formData: {
        notifications: false,
        linkedIn: '',
        // google: '',
        twitter: '',
        facebook: '',
      },
      errors: this.validator.errors,
    };
  }
  /**
   * @description componentWillReceiveProps is called when there in change in property
   * @param fields {Object} props
   */
  public componentWillReceiveProps(props) {
    if (props.viewProfile) {
      const formData = {
        notifications: props.viewProfile.notifications,
        linkedIn: props.viewProfile.linkedIn,
        // google: props.viewProfile.google,
        twitter: props.viewProfile.twitter,
        facebook: props.viewProfile.facebook,
      };
      this.setState({ formData });
    }
  }

  /**
   * @description function to validate and handle error
   */
  public removeErrorAndReValidate = (name: string) => {
    const { errors } = this.validator;
    errors.remove(name);
    this.validator.validate(name, this.state.formData[name])
      .then(() => {
        this.setState({ errors });
      });
  }

  /**
   * @description function to validate and handle setting data
   */
  public updateSettingData = (event: any) => {
    const { name, value, checked } = event.target;
    const data = name === 'notifications' ? checked : value;
    this.setState({ formData: { ...this.state.formData, [name]: data } }, () => {
      this.removeErrorAndReValidate(name);
    });
  }

  /**
   * @description function to validate and handle setting data
   */
  public toggleNotificationPreferance = (event: any) => {
    const { name, checked } = event.target;
    const data = checked;
    this.setState({ formData: { ...this.state.formData, [name]: data } }, () => {
      this.removeErrorAndReValidate(name);
    });

    this.props.dispatch(viewProfileAction.updateSettings({ [name]: data }));
  }

  /**
   * @description function to call api to update setting data
   */
  public updateSetting = (e: any) => {
    e.preventDefault();
    const { linkedIn, twitter, facebook, notifications } = this.state.formData;
    const { errors } = this.validator;
    this.validator.validateAll({ linkedIn, twitter, facebook, notifications })
      .then((success: boolean) => {
        if (success) {
          this.props.dispatch(viewProfileAction.updateSettings(this.state.formData));
        } else {
          this.setState({ errors });
        }
      });
  }

  public render() {
    const { errors, formData: { linkedIn, twitter, facebook, notifications } } = this.state;
    return (
      <React.Fragment>
        <div className="tab-panel-wrapper white-bg box-shadow">
          <div className="assigned-licence">
            <h2 className="heading heading-rg roboto-medium collapse-header">
              Set Preferences </h2>
            <h2 className="heading heading-rg collapse-header notification-toggle">
              Notifications
                      <label className="toggle-switch">
                <Input
                  type="checkbox"
                  name="notifications"
                  checked={notifications}
                  onChange={this.toggleNotificationPreferance}
                />
                <span className="slider round"/>
              </label>
            </h2>
          </div>
        </div>
        <div className="tab-panel-wrapper white-bg box-shadow mt-3" >
          <div className="social-links-row-wrapper">
            <h2 className="heading heading-rg roboto-medium collapse-header">
              Social Profile </h2>
            <div className="social-links-row">
              <span className="heading heading-rg">LinkedIn</span>
              <FormGroup className="floating-label">
                <Input
                  type="text"
                  className="form-control"
                  name="linkedIn"
                  id="linkedIn"
                  placeholder="Add Link"
                  value={linkedIn}
                  onChange={this.updateSettingData}
                />
                <Label for="LinkedIn">Add Link</Label>
                {errors.has('linkedIn') &&
                  <div className="error-text">{errors.first('linkedIn')}</div>
                }
              </FormGroup>
            </div>
            <div className="social-links-row">
              <span className="heading heading-rg">Facebook</span>
              <FormGroup className="floating-label">
                <Input
                  type="text"
                  className="form-control"
                  name="facebook"
                  id="facebook"
                  placeholder="Add Link"
                  value={facebook}
                  onChange={this.updateSettingData}
                />
                <Label for="Facebook">Add Link</Label>
                {errors.has('facebook') &&
                  <div className="error-text">{errors.first('facebook')}</div>
                }
              </FormGroup>
            </div>
            <div className="social-links-row border-0">
              <span className="heading heading-rg">Twitter</span>
              <FormGroup className="floating-label">
                <Input
                  type="text"
                  className="form-control"
                  name="twitter"
                  id="twitter"
                  placeholder="Add Link"
                  value={twitter}
                  onChange={this.updateSettingData}
                />
                <Label for="Twitter">Add Link</Label>
                {errors.has('twitter') &&
                  <div className="error-text">{errors.first('twitter')}</div>
                }
              </FormGroup>
            </div>
            <div className="card-footer d-flex align-items-center justify-content-end">
              <Button color="primary" className="btn btn-rg" onClick={this.updateSetting}>Update</Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const connectedProfileSettingPage = connect()(ProfileSettingPage);
export { connectedProfileSettingPage as ProfileSettingPage };
