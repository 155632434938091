import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import OpenFieldTypes from 'OpenFieldTypes';
import { Link } from 'react-router-dom';
import {
  Button,
} from 'reactstrap';
import { Sidebar } from '../../sidebar';
import { GoalRequestListing } from '../../goalManagement/listings/goalRequestListing';
import { pageRoutes } from './../../../../utils/routeConstants';
import { viewProfileAction } from '../../viewProfile/redux/action';
import userImage from './../../../../assets/images/profile.png';
import { ViewLearnerProfile } from '../../../learner/updateProfile/ViewLearnerProfile';
import { IViewProfile } from '../../../../interfaces';
import { Back } from '../../back/back';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  match: any;
  location: any;
  learnerProfile: IViewProfile;
}

class LearnerDetailContainer extends React.Component<IProps> {
 /**
  * @description getDerivedStateFromProps is called when there in change in property
  * @param fields {Object} props
  */
  public static getDerivedStateFromProps(props: IProps) {

    if (props.learnerProfile) {
      return {
        learnerProfile: props.learnerProfile,
      };
    }
    return null;
  }
  public state = {
    learnerProfile: {},
    showProfile: 'false',
  };

  /**
   * @description componentDidMount is called when component is loaded
   * call viewProfileAction.getShortProfile to fetch profile detail
   */
  public componentDidMount() {
    const { match: { params } } = this.props;
    if (params.id) {
      const pId = params.id.split('?');
      this.props.dispatch(viewProfileAction.getShortProfile(pId[0]));
    }
  }

  public render() {
    let { showProfile } = this.state;
    const { learnerProfile } = this.state;
    const { match: { params }, location: { search } } = this.props;
    const profileImage = learnerProfile['profileImage'] ? learnerProfile['profileImage'] : userImage;
    if (search) {
      showProfile = search.split('viewProfile=')[1];
    }
    return (
      <React.Fragment>
        <Sidebar {...this.props} />
        <div className="dashboard-wrapper">
          <Back {...this.props} />
          {showProfile === 'false' &&
            <div className="view-profile-box box-shadow white-bg mt-4 mb-0">
              <div className="user-profile-details">
                <span className="profile-picture-view-only">
                <img
                  src={profileImage}
                  alt=""
                  className="img-cover"
                />
                </span>
                <div className="profile-email-view-only text-truncate ">
                  <h3 className="text-truncate">
                    {learnerProfile['firstName']} {learnerProfile['lastName']}
                  </h3>
                  <p>{learnerProfile['email']}</p>
                  <Link to={`${pageRoutes.LEARNERMANAGEMENT.PATH}${pageRoutes.LEARNERMANAGEMENT.DETAIL}/${params.id}?viewProfile=true`}> View Full Profile</Link>
                </div>
              </div>
              {showProfile === 'false' &&
                <Button type="button" className="btn btn-assign btn-rg cursor-default">
                  {learnerProfile['isLicenced'] ?
                    `Assigned`
                    :
                    `Unassigned`
                  }
                </Button>
              }
            </div>
          }
          {showProfile === 'true' &&
            <div className="dashboard-content shadow mb-0">
              <div className="tab-panel-wrapper">
              <ViewLearnerProfile {...this.props} from="learnerManagement" viewProfile={learnerProfile} />
              </div>
            </div>
          }
          {showProfile === 'false' &&
            <div className="dashboard-content">
              <GoalRequestListing from="learnerManagement" match={this.props.match} />
            </div>
          }
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: { viewProfile: any; authentication: { user: any }; goals: any }) {
  const { user } = state.authentication;
  const { viewProfile } = state;
  return {
    goals: state.goals,
    user,
    learnerProfile: viewProfile['profile'],
  };
}

const mapLearnerDetailContainer = connect(mapStateToProps)(LearnerDetailContainer);
export { mapLearnerDetailContainer as LearnerDetailContainer };
