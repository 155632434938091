import { messages } from '../utils/messages';
import { staticConstants } from '../utils/staticConstants';
export const validator = (ReeValidate: any) => {
  // password custom validation
  ReeValidate.extend('verify_password', {
    getMessage: () => `Password must be at least 8 characters in length and include: 1 lowercase letter, 1 uppercase letter, 1 number
     and 1 special character (Eg. !@#$etc.)`,
    validate: (value) => {
      const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
      return strongRegex.test(value);
    },
  });

  ReeValidate.extend('verify_profile_image', {
    getMessage: () => messages.profileImageInvalidExtension,
    validate: (value: File) => {
      if (staticConstants.PROFILE_IMAGE_MIME.indexOf(value.type) < 0) {
        return false;
      }
      return true;
    },
  });

  ReeValidate.extend('verify_profile_image_size', {
    getMessage: () => messages.profileImageMaxSize,
    validate: (value: File) => {
      if (value.size > staticConstants.MAX_PROFILE_IMAGE_SIZE) {
        return false;
      }
      return true;
    },
  });

  ReeValidate.extend('verify_linkedin_url', {
    getMessage: () => 'LinkedIn url is not valid.',
    validate: (value) => {
      const strongRegex = new RegExp(/http(s)?:\/\/([\w]+\.)?linkedin\.com\/in\/[A-z 0-9 _ -]\/?/i);
      return strongRegex.test(value);
    },
  });

  ReeValidate.extend('verify_twitter_url', {
    getMessage: () => 'Twitter url is not valid.',
    validate: (value) => {
      const strongRegex = new RegExp(/http(s)?:\/\/(.*\.)?twitter\.com\/[A-z 0-9 _]+\/?/i);
      return strongRegex.test(value);
    },
  });

  ReeValidate.extend('verify_google_url', {
    getMessage: () => 'Google url is not valid.',
    validate: (value) => {
      const strongRegex = new RegExp(/https?:\/\/plus.google.com\/\d{21}\/?/i);
      return strongRegex.test(value);
    },
  });

  ReeValidate.extend('verify_facebook_url', {
    getMessage: () => 'Facebook url is not valid.',
    validate: (value) => {
      const strongRegex = new RegExp(/http(s)?:\/\/(www\.)?(facebook|fb)\.com\/[A-z 0-9 _ - .]\/?/i);
      return strongRegex.test(value);
    },
  });

  ReeValidate.extend('verify_commissioon_limit', {
    getMessage: () => messages.commissionLimit,
    validate: (value) => {
      if (value < 0 || value > staticConstants.HUNDRED) {
        return false;
      }
      return true;
    },
  });
};
