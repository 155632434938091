import { history } from '../../../../helpers';
import { paymentService } from './../../../../services/paymentServices';
import { userServices } from './../../../../services/userServices';
import { alertActions } from './../../../alert/redux/actions';
import { endLoading, startLoading } from './../../../loader/redux/actions';
import { actionTypes } from './types';
import OpenFieldTypes from 'OpenFieldTypes';
import { pageRoutes, handleError } from '../../../../utils';

export const expertPaymentAction = {
  expertFeedback,
  changeRequestRatePerHour,
  getPaymentLogs,
  getExpertIncome,
  getWithdrawHistory,
  withdrawAmount,
  withdrawNonStripe,
};

function expertFeedback(feedback: object) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    userServices.sendExpertFeedback(feedback)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          history.push(`${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.PAYMENT.PATH}`);
          dispatch(success(res));
          dispatch(alertActions.successAlert(res.message));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(alertActions.errorAlert(error.toString()));
          dispatch(failure(error.toString()));
        },
      );
  };

  function success(data: object) { return { data, type: actionTypes.EXPERT_FEEDBACK_SUCCESS }; }
  function failure(error: string) { return { error, type: actionTypes.EXPERT_FEEDBACK_FAILURE }; }
}

function changeRequestRatePerHour(data: object) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request());
    userServices.changeRequestRatePerHour(data)
        .then(
            (res) => {
              dispatch(endLoading('MAIN_LOADER'));
              dispatch(success(res));
              dispatch(alertActions.successAlert(res.message));
            },
            (error) => {
              dispatch(endLoading('MAIN_LOADER'));
              dispatch(failure(error.toString()));
              dispatch(alertActions.errorAlert(error.toString()));
            },
        );
  };

  function request() { return { type: actionTypes.CHANGE_RATE_PER_HOUR_REQUEST }; }
  function success(sdata: any) { return { type: actionTypes.CHANGE_RATE_PER_HOUR_SUCCESS, data: sdata }; }
  function failure(error: string) { return { type: actionTypes.CHANGE_RATE_PER_HOUR_FAILURE, error }; }
}

function getPaymentLogs(req) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request());
    userServices.getPaymentLogs(req)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          const list = res.data.map((ele) => {
            const raw1 = { ...ele, ...ele.goalData };
            delete raw1.goalData;
            delete raw1.__typename;
            return raw1;
          });
          const data = { count: res.count, list };
          dispatch(success(data));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };
  function request() { return { type: actionTypes.GET_PAYMENT_LOGS_REQUEST }; }
  function success(data: any) { return { type: actionTypes.GET_PAYMENT_LOGS_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.GET_PAYMENT_LOGS_FAILURE, error }; }
}

function getExpertIncome(requestObject = {}) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request());
    userServices.getExpertIncome(requestObject)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success(res.data));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };
  function request() { return { type: actionTypes.GET_EXPERT_INCOME_REQUEST }; }
  function success(data: any) { return { type: actionTypes.GET_EXPERT_INCOME_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.GET_EXPERT_INCOME_FAILURE, error }; }
}

function getWithdrawHistory(req) {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request());
    userServices.getWithdrawHistory(req)
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          const data = { ...res, list: res.data };
          delete data.data;
          dispatch(success(data));
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };
  function request() { return { type: actionTypes.GET_WITHDRAW_HISTORY_REQUEST }; }
  function success(data: any) { return { type: actionTypes.GET_WITHDRAW_HISTORY_SUCCESS, data }; }
  function failure(error: string) { return { type: actionTypes.GET_WITHDRAW_HISTORY_FAILURE, error }; }
}

function withdrawAmount() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request());
    paymentService.withdrawAmount()
      .then(
        (res) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(success());
          dispatch(alertActions.successAlert(res.message));
          history.push(`${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.PAYMENT.PATH}`);
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(failure(error.toString()));
          dispatch(alertActions.errorAlert(error.toString()));
        },
      );
  };
  function request() { return { type: actionTypes.GET_WITHDRAW_AMOUNT_REQUEST }; }
  function success() { return { type: actionTypes.GET_WITHDRAW_AMOUNT_SUCCESS }; }
  function failure(error: string) { return { type: actionTypes.GET_WITHDRAW_AMOUNT_FAILURE, error }; }
}

function withdrawNonStripe() {
  return (dispatch: OpenFieldTypes.RootAction) => {
    dispatch(startLoading('MAIN_LOADER', 'Loading'));
    dispatch(request());
    paymentService.withdrawNonStripeAmount()
      .then(
        (res) => {
          dispatch(success());
          dispatch(endLoading('MAIN_LOADER'));
          dispatch(alertActions.successAlert(res.message));
          history.push(`${pageRoutes.EXPERT.PATH}${pageRoutes.EXPERT.PAYMENT.PATH}`);
        },
        (error) => {
          dispatch(endLoading('MAIN_LOADER'));
          handleError(dispatch, failure, String(error));
        },
      );
  };
  function request() { return { type: actionTypes.GET_WITHDRAW_AMOUNT_REQUEST }; }
  function success() { return { type: actionTypes.GET_WITHDRAW_AMOUNT_SUCCESS }; }
  function failure(error: string) { return { type: actionTypes.GET_WITHDRAW_AMOUNT_FAILURE, error }; }
}
