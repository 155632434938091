import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    Button,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import OpenFieldTypes from 'OpenFieldTypes';
import { IValidator } from './../../../interfaces';
import { checkAddOn, staticConstants } from './../../../utils';

interface IProps {
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  isOpenModal: boolean;
  modalPopupObj: object;
  hideModal(): void;
  addOnPopup(): void;
}

interface IState {
  modal: boolean;
  displayUnlockProfilePopup: boolean;
}

class SeeFullDetailPopup extends React.Component<IProps, IState> {
  public validator: IValidator;
  constructor(props: IProps) {
      super(props);
      this.state = {
          modal: props.isOpenModal,
          displayUnlockProfilePopup: false,
        };
    }

  public hideModal = () => {
      this.setState({
          modal: false,
        });
      this.props.hideModal();
    }

  public render() {
      const { modal } = this.state;
      const userDetail = this.props.modalPopupObj;
      return (
            <React.Fragment>
                <Modal isOpen={modal} className="organization-code-modal">
                    <ModalHeader toggle={this.hideModal}>Participant</ModalHeader>
                    <ModalBody>
                        <div className="tag-card-data">
                            <h3 className="heading heading-md roboto-medium">{checkAddOn() ? `${userDetail['firstName']} ${userDetail['lastName']}`
                                : userDetail['role'] === staticConstants.ROLE.PROFESSIONAL ? `Individual` : `Participant ${Number(userDetail['index']) + 1}`}</h3>
                            <p className="small-heading">{checkAddOn() ? userDetail['email'] : userDetail['occupation']}</p>
                            {userDetail && userDetail['tags'] &&
                                <p>
                                    <span className="tag-heading">Tags</span>
                                    <div className="w-100">
                                        {userDetail['tags'].map(tag =>
                                            <span key={tag['value']} className="tags-view-only">{tag['name']}</span>)}
                                    </div>
                                </p>
                            }
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn btn-regular ml-0" onClick={this.hideModal}>Cancel</Button>{' '}
                        <Button color="primary" className="mr-2" onClick={this.props.addOnPopup}>
                            See Full Details
                        </Button>
                    </ModalFooter>
                </Modal >
            </React.Fragment>
        );
    }
}

const connectedSeeFullDetailPopup = connect()(SeeFullDetailPopup);
export { connectedSeeFullDetailPopup as SeeFullDetailPopup };
