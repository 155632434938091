
import React from 'react';
interface IProps {
  history?: any;
}

export class Back extends React.Component<IProps> {

  public render() {
      const { history } = this.props;
      return (
             <span className="back-header-btn b-inline-block" onClick={history.goBack}>Back</span>
        );
    }
}

