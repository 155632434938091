import React, { Fragment, Dispatch } from 'react';
import { connect } from 'react-redux';
import { RegisterForm } from './RegisterForm';
import logoImage from '../../../assets/images/logo.svg';
import roundImage from '../../../assets/images/right_round.png';
import { Link } from 'react-router-dom';
import fbImage from '../../../assets/images/fb.png';
import twImage from '../../../assets/images/tw.svg';
import linkdImage from '../../../assets/images/lnkd.svg';
import { getLocalUserData, getNavToDashboard } from '../../../utils';
import OpenFieldTypes from 'OpenFieldTypes';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import {
    Collapse,
    Modal,
    ModalBody,
    ModalHeader,
    NavbarToggler,
    Nav,
    NavItem
} from 'reactstrap';
interface IProps {
    dispatch: Dispatch<OpenFieldTypes.RootAction>;
    isloggedIn?: any;
}

interface IState {
    sharingExpertiseModal: any;
    isOpen: any;
}

class RegisterContainer extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            sharingExpertiseModal: false,
            isOpen: false,
        };
        this.toggleMenu = this.toggleMenu.bind(this);
    }
    public componentDidMount() {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = 'hs-script-loader';
        script.src = '//js.hs-scripts.com/7903349.js';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
    }

    public toggleMenu() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }
    public openExpertiseModal = () => {
        this.setState({
            sharingExpertiseModal: true,
        });
    }
    public hideExpertiseModal = () => {
        this.setState({
            sharingExpertiseModal: false,
        });
    }
    /**
     * @description
     * render method is used to render the form
    * */
    public render() {
        const { isloggedIn } = this.props;
        if (isloggedIn === true) {
            var { email, role, firstName = null, lastName = null, isFirstTime } = getLocalUserData();
        }
        else {
            var email = null, role = null, firstName = null, lastName = null, isFirstTime = null;
        }
        const { sharingExpertiseModal } = this.state;
        return (
            <Fragment>
                <div className="step_banner">
                    <div className="my_nav">
                        <div className="container">
                            <nav className="navbar navbar-expand-lg">
                                <Link to="/" className="navbar-brand" >
                                    <img src={logoImage} alt="logo" />
                                </Link>
                                <NavbarToggler onClick={this.toggleMenu} />
                                <Collapse isOpen={this.state.isOpen} navbar>
                                    <Nav className="ml-auto" navbar>
                                        <NavItem>
                                            <Link to="/pricing" className="nav-link" >Pricing</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link to="/register/expert" className="nav-link" >Share Your Expertise</Link>
                                        </NavItem>
                                        {isloggedIn ? <>
                                            <NavItem>
                                                <Link to={getNavToDashboard()} className="nav-link" >{firstName ? `${firstName} ${lastName}` : email}</Link>
                                            </NavItem>
                                        </> :
                                            <>
                                                <NavItem>
                                                    <Link to="/login" className="nav-link" >Log In</Link>
                                                </NavItem>
                                                <NavItem>
                                                    <Link to="/usertype" className="nav-link" >Sign Up</Link>
                                                </NavItem>
                                            </>
                                        }
                                    </Nav>
                                </Collapse>
                            </nav>
                        </div>
                    </div>
                    <div className="container">
                        <div className="inner_page_banner text-center">
                            <h2>You’re an Expert</h2>
                            <p className="text-white step_banner_p">Earn Up To $500/hour Sharing Your Expertise<span onClick={this.openExpertiseModal} >?</span></p>
                            <div>
                                <AnchorLink className="nav-link get_start_btn d-inline-block joinUs" href="#joinUs">Get Started</AnchorLink>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="step_list_bg">
                    <div className="container">
                        <div className="step_list_top">
                            <div><img src={roundImage} alt="icon" />Goal-to-expert matchmaking</div>
                            <div><img src={roundImage} alt="icon" />Remote conferencing technology</div>
                            <div><img src={roundImage} alt="icon" />5000+ sector tags</div>
                            <div><img src={roundImage} alt="icon" />International user base</div>
                            <div><img src={roundImage} alt="icon" />Payment tracking</div>
                        </div>
                    </div>
                </div>
                <RegisterForm />
                <Modal isOpen={sharingExpertiseModal} className="modal-dialog-centered modal-lg expert-detail-modal overlap-container">
                    <ModalHeader toggle={this.hideExpertiseModal}></ModalHeader>
                    <ModalBody>
                        <p>Experts earn an hourly rate for providing knowledge services to Overlap users. Rates are determined by several factors, including area of expertise, experience and profile. Comparing these factors to other experts, and accounting for user demand will determine starting rates. Activity on the platform will determine any changes made to the rate. Rate changes can be initiated by the expert themselves or after an annual audit of overall activity on the platform.</p>
                        <h4 className="heading heading-sm roboto-bold mb-3">Expert hourly rates are as follows:</h4>
                        <p className="mb-3">Level One: less than 5 years’ experience ($100-$250).</p>
                        <p className="mb-3">Level Two: more than 5 but less than 10 years’ experience ($255-$385).</p>
                        <p className="mb-2">Level Three: more than 10 years’ experience ($390-$500).</p>
                    </ModalBody>
                </Modal>
                <footer>
                    <div className="container">
                        <div className="footer_div">
                            <nav className="navbar navbar-expand-lg order-xl-2">
                                <div className="social_link mb-4 pb-3 d-lg-block d-none">
                                    <a href="https://www.facebook.com/ovlpapp/" target="_blank" ><img src={fbImage} alt="Facebook" /></a>
                                    <a href="https://twitter.com/Overlap_app_" target="_blank" ><img src={twImage} alt="Twitter" /></a>
                                    <a href="https://www.linkedin.com/company/overlap-incorporated" target="_blank" ><img src={linkdImage} alt="Linkedin" /></a>
                                </div>
                                <ul className="navbar-nav">
                                    <li className="nav-item"><Link to="/" className="nav-link" >Home</Link></li>
                                    <li className="nav-item"><Link to="/howItWork" className="nav-link" >How It Works</Link></li>
                                    <li className="nav-item"><Link to="/pricing" className="nav-link" >Pricing</Link></li>
                                    <li className="nav-item"><Link to="/faq" className="nav-link" >FAQs</Link></li>
                                    <li className="nav-item"><Link to="/termsofuse" className="nav-link" target="_blank" >Terms of Use</Link></li>
                                    <li className="nav-item"><Link to="/privacyPolicy" className="nav-link" target="_blank" >Privacy Policy</Link></li>
                                    <li className="nav-item"><Link to="/contactus" className="nav-link" >Contact Us</Link></li>
                                    <li className="nav-item"><a target="_blank" className="nav-link" href="https://www.howtooverlap.app">User Guide</a></li>
                                </ul>
                            </nav>
                            <div className="social_link mb-3 d-lg-none d-md-block">
                                <a href="https://www.facebook.com/ovlpapp/" target="_blank" ><img src={fbImage} alt="Facebook" /></a>
                                <a href="https://twitter.com/Overlap_app_" target="_blank" ><img src={twImage} alt="Twitter" /></a>
                                <a href="https://www.linkedin.com/company/overlap-incorporated" target="_blank" ><img src={linkdImage} alt="Linkedin" /></a>
                            </div>
                            <div className="footer_logo">
                                <Link to="/"><img src={logoImage} alt="logo" /></Link>
                                <p>©2021 Overlap Group Inc. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </footer>
            </Fragment>
        );
    }
}

function mapStateToProps(state: { tags: any,authentication : any }) {
    const { authentication } = state;
    return {
      isloggedIn: authentication.loggedIn,
    };
  }

const connectedHomePage = connect(mapStateToProps)(RegisterContainer);
export { connectedHomePage as RegisterContainer };
