import { actionTypes } from './types';

const initialState = {
  content: [],
  getContentRequest: false,
  updateContent: false,
  getFaqSuccess: false,
  contentSuccess: false,
};

export function contentManagement(state = initialState, action: any) {
  switch (action.type) {
    case actionTypes.GET_CONTENT_REQUEST:
      return {
        getContentRequest: true,
        content: state.content,
      };
    case actionTypes.GET_CONTENT_SUCCESS:
      return {
        contentSuccess: true,
        content: action.data,
      };
    case actionTypes.UPDATE_CONTENT_REQUEST:
      return {
        ...state,
      };
    case actionTypes.UPDATE_CONTENT_SUCCESS:
      return {
        updateContent: true,
      };
    case actionTypes.DELETE_FAQ_REQUEST:
      return {
        getFaqSuccess: true,
      };
    case actionTypes.DELETE_FAQ_SUCCESS:
      return { updateContent: true };
    case actionTypes.CONTENT_LIST_CLEAR:
      return { };
    default:
      return state;
  }
}
