import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import Select, { createFilter } from 'react-select';
import ReeValidate from 'ree-validate';
import NumberFormat from 'react-number-format';
import OpenFieldTypes from 'OpenFieldTypes';
import { reeValidators, nameTitle } from './../../../utils/staticConstants';
import { validator } from './../../../helpers';
import { IValidator } from '../../../interfaces';
import { registerAction } from './redux/actions';
import { locations, messages, customSelectStyles, reactSelectFilters, MAGIC_NUMBER } from '../../../utils/';

validator(ReeValidate);

interface IProps {
  personalInfo?: any;
  dispatch: Dispatch<OpenFieldTypes.RootAction>;
  successValidateSubmit: any;
}

interface IState {
  personalInfo?: any;
  errors?: any;
  bio?: any,
}

class PersonalInfoForm extends React.Component<IProps, IState> {

  public validator: IValidator;

  /**
   * @description
   * constructor is used to define the initial state and property
   * @param fields {Object} props
   */
  constructor(props: IProps) {
    super(props);
    this.validator = new ReeValidate({
      title: 'required',
      firstName: reeValidators.required_min_3_max_100,
      lastName: reeValidators.required_min_3_max_100,
      email: 'required|email',
      occupation: reeValidators.required_min_3_max_100,
      position: reeValidators.required_min_3_max_100,
      phoneNumber: 'min:5|max:15',
      bio: reeValidators.required_min_3_max_500,
      address: 'required',
      location: 'required'
    });
    this.state = {
      personalInfo: {
        firstName: '',
        lastName: '',
        email: '',
        address: '',
        occupation: '',
        position: '',
        linkedinProfile: '',
        publishLink: '',
        phoneNumber: '',
        title: null,
        location: null
      },
      bio: '',
      errors: this.validator.errors,
    };
  }

  /**
   * @description
   * handleChange is used to set the value on state from the only input/textarea boxes.
   * also remove the form validation. Once form validated the forward the control to parenet component
   * @param fields {Object} event
   */
  public handleChange = (event: any) => {
    const { name, value } = event.target;
    if (name === 'linkedinProfile' || name === 'publishLink') {
      this.setState({ personalInfo: { ...this.state.personalInfo, [name]: value } });
    } else {
      const { errors } = this.validator;
      this.setState({ personalInfo: { ...this.state.personalInfo, [name]: value } });
      errors.remove(name);
      this.validator.validate(name, value)
        .then(() => {
          this.setState({ errors });
        });
    }
  }

  public handleLocation = (location: any) => {
    const { personalInfo } = this.state;
    personalInfo['location'] = location.value;
    this.setState({ personalInfo });
    const { errors } = this.validator;
    errors.remove('location');
    this.validator.validate('location', location.value)
      .then(() => {
        this.setState({ errors });
      });
  }

  public handleBioChange = (event: any, key?: string) => {
    const { value, name } = event.target;
    this.setState({ bio: value });
    const { errors } = this.validator;
    errors.remove(name);
    this.validator.validate(name, value)
            .then(() => {
              this.setState({ errors });
            });
  }


  /**
   * @description
   * Check if email is already registered
   * @param {function} callback
   */
  public checkExistingExpert = (cb: (status: boolean) => void) => {
    const { personalInfo: { email } } = this.state;
    this.props.dispatch(registerAction.checkExistingExpert(email, cb));
  }

  /**
   * @description
   * validateAndSubmit is used to validate the form by clicking on the next button.
   * @param fields {Object} event
   */
  public validateAndSubmit = () => {

    const {
      firstName,
      lastName,
      email,
      occupation,
      position,
      title,
      phoneNumber,
      address,
      location
    } = this.state.personalInfo;
    const { bio } = this.state;
    const { errors } = this.validator;
    this.validator.validateAll({
      title,
      firstName,
      lastName,
      email,
      occupation,
      position,
      phoneNumber,
      bio,
      address,
      location
    })
      .then((success: boolean) => {
        if (success) {
          this.checkExistingExpert((status: boolean) => {
            if (status) {
              this.props.successValidateSubmit();
            } else {
              errors.has('email') && errors.remove('email');
              errors.add('email', messages.alreadyExistingEmail);
              this.setState({ errors });
            }
          });
        } else {
          this.setState({ errors });
        }
      });

  }

  /**
   * @description
   * handlePhoneChange is used to set the value on state from the phone.
   * @param fields {Object} num
   */
  public handlePhoneChange = (num: { formattedValue: string }) => {
    const { errors } = this.validator;
    this.setState(
      {
        personalInfo: {
          ...this.state.personalInfo,
          phoneNumber: num.formattedValue.replace('-', ''),
        },
      },
      () => {
        errors.remove('phoneNumber');
        this.validator
          .validate('phoneNumber', this.state.personalInfo['phoneNumber'])
          .then(() => {
            this.setState({ errors });
          });
      },
    );

  }

  public handleTitleChange = (selectedOption) => {
    const { personalInfo } = this.state;
    const { errors } = this.validator;
    personalInfo.title = selectedOption;
    this.setState({ personalInfo }, () => {
      errors.remove('title');
      this.validator
        .validate('title', this.state.personalInfo['title'])
        .then(() => this.setState({ errors }));
    });
  }

  /**
   * @description
   * render method is used to render the form
   */
  public render() {
    const { personalInfo, errors, bio } = this.state;
    const getErrClass = (field: string) => {
      return errors.has(field) ? ' has-error' : '';
    };
    let value = null;
    if (personalInfo.location) {
      value = { label: personalInfo.location , value: personalInfo.location };
    }
    return (
      <div className="form-wrapper">
        <Form autoComplete="off">
          <Row>
            <Col xs="12" sm="4">
              <FormGroup className="floating-label disabled-input">
                <Select
                  name="title"
                  styles={customSelectStyles}
                  value={personalInfo.title}
                  onChange={this.handleTitleChange}
                  options={nameTitle}
                  id="title"
                  placeholder="Title"
                  className="react-select-box select-box-group"
                />
                <Label for="eventType" className={personalInfo.title ? 'selected' : ''}>Title</Label>
                {errors.has('title') &&
                  <div className="error-text">
                    {errors.first('title')}
                  </div>
                }
              </FormGroup>
            </Col>
            <Col xs="12" sm="4">
              <FormGroup className={`floating-label ${getErrClass('firstName')}`}>
                <Input
                  type="text"
                  className="form-control"
                  name="firstName"
                  id="firstName"
                  placeholder="First Name"
                  value={personalInfo.firstName}
                  onChange={this.handleChange}
                />
                <Label for="firstName">First Name</Label>
                {errors.has('firstName') &&
                  <div className="error-text">
                    {
                      errors.first('firstName').replace('firstName', 'first name')}
                  </div>
                }
              </FormGroup>
            </Col>
            <Col xs="12" sm="4">
              <FormGroup className={`floating-label  ${getErrClass('lastName')}`}>
                <Input
                  type="text"
                  className="form-control"
                  name="lastName"
                  id="lastName"
                  placeholder="Last Name"
                  value={personalInfo.lastName}
                  onChange={this.handleChange}
                />
                <Label for="lastname">Last Name</Label>
                {errors.has('lastName') &&
                  <div className="error-text">
                    {
                      errors.first('lastName').replace('lastName', 'last name')}</div>
                }
              </FormGroup>
            </Col>
            <Col xs="12" sm="4">
              <FormGroup className={`floating-label ${getErrClass('email')}`}>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  id="email"
                  value={personalInfo.email}
                  onChange={this.handleChange}
                  placeholder="Email"
                />
                <Label for="email">Email Address</Label>
                {errors.has('email') &&
                  <div className="error-text">{errors.first('email')}</div>
                }
              </FormGroup>
            </Col>
            <Col xs="12" sm="4">
              <FormGroup className="floating-label">
                <NumberFormat
                  prefix={'+'}
                  className="form-control"
                  name="phoneNumber"
                  mask=""
                  placeholder="Phone Number (Optional)"
                  maxLength={MAGIC_NUMBER.FIFTEEN}
                  onValueChange={this.handlePhoneChange}
                  value={personalInfo.phoneNumber}
                />
                <Label for="phoneNumber">Phone Number (Optional)</Label>
                {errors.has('phoneNumber') &&
                  <div className="error-text">
                    {messages.invalidPhoneNumber}
                  </div>
                }
              </FormGroup>
            </Col>
            <Col xs="12" sm="4">
              <FormGroup className={`floating-label ${getErrClass('occupation')}`}>
                <Input
                  type="text"
                  className="form-control"
                  name="occupation"
                  id="occupation"
                  placeholder="Occupation"
                  onChange={this.handleChange}
                />
                <Label for="occupation">Occupation</Label>
                {errors.has('occupation') &&
                  <div className="error-text">{errors.first('occupation')}</div>
                }
              </FormGroup>
            </Col>
            <Col xs="12" sm="4">
              <FormGroup className={`floating-label ${getErrClass('position')}`}>
                <Input
                  type="text"
                  className="form-control"
                  name="position"
                  id="position"
                  placeholder="Position"
                  onChange={this.handleChange}
                />
                <Label for="position">Position</Label>
                {errors.has('position') &&
                  <div className="error-text">{errors.first('position')}</div>
                }
              </FormGroup>
            </Col>
            <Col xs="12" sm="4">
              <FormGroup className="floating-label">
                <Input
                  type="text"
                  className="form-control"
                  name="address"
                  id="address"
                  placeholder="City or Town"
                  onChange={this.handleChange}
                />
                <Label for="address">City or Town</Label>
                {errors.has('address') &&
                  <div className="error-text">City or Town is required.</div>
                }
              </FormGroup>
            </Col>
            <Col xs="12" sm="4">
            <FormGroup className="floating-label disabled-input">
                  <Select
                    name="location"
                    options={locations}
                    id="location"
                    styles={customSelectStyles}
                    placeholder="Country"
                    filterOption={createFilter(reactSelectFilters)}
                    onChange={(e) => this.handleLocation(e)}
                    className="react-select-box select-box-group"
                    value={value}
                  />
                  <Label for="location" className={personalInfo.location !== null ? 'selected' : ''} >Country</Label>
                  {errors.has(`location`) &&
                    <div className="error-text">The Country field is required.</div>
                  }
                </FormGroup>
            </Col>
            <Col xs="12" sm="6">
              <FormGroup className="floating-label">
                <Input
                  type="text"
                  className="form-control"
                  name="linkedinProfile"
                  id="linkedinProfile"
                  placeholder=" Linkedin Profile (Optional)"
                  onChange={this.handleChange}
                />
                <Label for="linkedinProfile"> Linkedin Profile (Optional)</Label>
              </FormGroup>
            </Col>
            <Col xs="12" sm="6">
              <FormGroup className="floating-label">
                <Input
                  type="text"
                  className="form-control"
                  name="publishLink"
                  id="publishLink"
                  placeholder=" Links to Published Work (Optional)"
                  onChange={this.handleChange}
                />
                <Label for="work"> Links to Published Work (Optional)</Label>
              </FormGroup>
            </Col>
            <Col>
            <FormGroup className={`floating-label disabled-input textarea-label ${getErrClass('bio')}`}>
                <textarea
                  className="form-control textarea-md"
                  name="bio"
                  maxLength={MAGIC_NUMBER.CHAR_LENGTH}
                  id="text-count"
                  placeholder="Describe Your Area of Expertise"
                  onChange={e => this.handleBioChange( e, 'bio')}
                />
                <span className="text-count">{bio.length}/500</span>
                <Label for="text-count" className={bio.length > MAGIC_NUMBER.ZERO ? 'selected' : ''} >
                  Area of expertise</Label>
                {errors.has('bio') &&
                  <div className="error-text">{errors.first('bio').replace('bio', 'area of expertise')}</div>
                }
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  const { registering } = state.registeration;
  return {
    registering,
  };
}

const connectedPersonalInforPage = connect(mapStateToProps, null, null, { forwardRef: true })(PersonalInfoForm);
export { connectedPersonalInforPage as PersonalInfoForm };
