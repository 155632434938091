import React, { Dispatch } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import OpenFieldTypes from 'OpenFieldTypes';
import { staticConstants } from '../../../utils';

interface IProps {
  dispatch?: Dispatch<OpenFieldTypes.RootAction>;
  isOpenModal: boolean;
  groupMembers?: Array<[{ userId: string; name: string; email: string }]>;
  hideModal(): void;
}

interface IState {
  modal: boolean;
}

class ViewGroupMembersModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      modal: props.isOpenModal,
    };
  }

  public hideModal = () => {
    this.setState({ modal: false });
    this.props.hideModal();
  }

  public render() {
    const { isOpenModal, groupMembers } = this.props;
    return (
      <Modal isOpen={isOpenModal} className="add-participaint">
        <ModalHeader toggle={this.hideModal}>Group Members</ModalHeader>
        <ModalBody>
          <div className="participaint-list">
            {groupMembers.map((member: any) => (
              <div className="list-item member-list py-2" key={member.userId}>
                <div className="profile-detail">
                  <span>{member.firstName} {member.lastName}</span>
                  <em>{member.email}</em>
                  <em>{staticConstants.DISPLAY_ROLE[member.role]}</em>
                </div>
              </div>
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.hideModal}>Close</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export { ViewGroupMembersModal };
