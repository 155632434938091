import gql from 'graphql-tag';

const UPDATE_NOTIFICATION_STATUS_MUTATION = gql`
  mutation readNotification( $notificationId: String) {
    readNotification( notificationId: $notificationId) {
        message
    }
}`;

export {
    UPDATE_NOTIFICATION_STATUS_MUTATION,
};
