import React from 'react';
import { PaymentPage } from './paymentPage';
import { LocationDescriptorObject } from 'history';
interface IProps {
  location: LocationDescriptorObject;
}
class ExpertPaymentContainer extends React.Component<IProps, {}> {

  public render() {
    return (
            <div className="form-container d-flex">
                <PaymentPage {...this.props}/>
            </div>
    );
  }
}
export { ExpertPaymentContainer };
