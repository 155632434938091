import {
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import React, { Dispatch } from 'react';
import OpenFieldTypes from 'OpenFieldTypes';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import { MAGIC_NUMBER } from '../../../utils';

interface IProps {
  dispatch?: Dispatch<OpenFieldTypes.RootAction>;
  breadcrumb: [];
}

export class BreadcrumbRender extends React.Component<IProps> {

  public renderBreadCrumbItem(breadcrumb: []) {
    return map(breadcrumb, (item: object, index: number) => {
      return (
        <>
          <BreadcrumbItem key={index}
            active={index + 1 === breadcrumb.length}
            className={`text-uppercase`}
          >
            {item['link'] ? <Link
              to={{
                pathname: `${item['link']}`,
                state: item['activeTab'] ? { activeTab: item['activeTab'] } : item['state'],
                hash: item['hash'],
              }}
              className={`heading heading-sm text-uppercase`}
            >
              {item['title'] || 'goal title'}</Link>
              : item['title']}
          </BreadcrumbItem>
        </>
      );
    });
  }
  public render() {
    const { breadcrumb } = this.props;
    return (
      <div>
        {breadcrumb && breadcrumb.length > MAGIC_NUMBER.ZERO &&
          <Breadcrumb>
            {this.renderBreadCrumbItem(breadcrumb)}
          </Breadcrumb>
        }
      </div>
    );
  }
}
