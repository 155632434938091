export const actionTypes = {
  LEARNER_LIST_REQUEST: 'LEARNER_LIST_REQUEST',
  LEARNER_LIST_SUCCESS: 'LEARNER_LIST_SUCCESS',
  LEARNER_LIST_FAILURE: 'LEARNER_LIST_FAILURE',
  CREATE_LEARNER_REQUEST: 'CREATE_LEARNER_REQUEST',
  CREATE_LEARNER_SUCCESS: 'CREATE_LEARNER_SUCCESS',
  CREATE_LEARNER_FAILURE: 'CREATE_LEARNER_FAILURE',
  ASSIGN_LICENCE_SUCCESS: 'ASSIGN_LICENCE_SUCCESS',
  ASSIGN_LICENCE_FAILURE: 'ASSIGN_LICENCE_FAILURE',
  DELETE_LEARNER_SUCCESS: 'DELETE_LEARNER_SUCCESS',
  DELETE_LEARNER_FAILURE: 'DELETE_LEARNER_FAILURE',
  LICENCE_ASSIGN_REQUEST: 'LICENCE_ASSIGN_REQUEST',
  CANCEL_LICENCE_REQUEST: 'CANCEL_LICENCE_REQUEST',
  ALLREADY_ASSIGN_GOAL_ERROR: 'ALLREADY_ASSIGN_GOAL_ERROR',
  RESET_ALLREADY_ASSIGN_GOAL_ERROR: 'RESET_ALLREADY_ASSIGN_GOAL_ERROR',
};
