import gql from 'graphql-tag';

const GET_CONTENT_QUERY = gql`
  query getContent($type: String!, $searchKey: String) {
    getContent(type: $type, searchKey: $searchKey) {
      message,
      data {
        content,
        question,
        answer,
        faqType,
        createdAt,
        _id,
      }
    }
}`;

export { GET_CONTENT_QUERY };
