import { GET_MESSAGE_BOARD_CONNECTIONS, GET_GROUPS } from '../graphql-client/queries/messageBoardQuery';
import { BID_MESSAGE_REQUEST, CREATE_GROUP, GET_CONNECTION, REMOVE_GROUP } from '../graphql-client/mutations/messageBoardMutation';
import { client } from '../index';
import { handleResponse } from '../utils/common';

async function getMessageBoardConnections() {
  const response = await client.query({
    query: GET_MESSAGE_BOARD_CONNECTIONS,
  });
  const resp = await handleResponse(response);
  return resp.messageBoard;
}

async function getGroups() {
  const response = await client.query({
    query: GET_GROUPS,
  });
  const resp = await handleResponse(response);
  return resp.getGroup;
}

async function createGroup(groupName: string, users: Array<[string]>) {
  const response = await client.mutate({
    mutation: CREATE_GROUP,
    context: { useApolloNetworkStatus: true },
    variables: {
      groupName,
      users,
    },
  });
  const resp = await handleResponse(response);
  return resp.createGroup;
}

async function getConnection(bidderId: string) {
  const response = await client.mutate({
    mutation: GET_CONNECTION,
    context: { useApolloNetworkStatus: true },
    variables: { bidderId },
  });
  const resp = await handleResponse(response);
  return resp.getConnection;
}

async function removeGroup(groupId: string) {
  const response = await client.mutate({
    mutation: REMOVE_GROUP,
    context: { useApolloNetworkStatus: true },
    variables: { groupId },
  });
  const resp = await handleResponse(response);
  return resp.removeGroup;
}

async function bidMessageRequest(reqObj) {
  const response = await client.mutate({
    mutation: BID_MESSAGE_REQUEST,
    context: { useApolloNetworkStatus: true },
    variables: { message: reqObj.message, goalId: reqObj.goalId, expertId: reqObj.expertId },
  });
  const resp = await handleResponse(response);
  return resp.bidMessageRequest;
}

export const messageBoardServices = {
  getMessageBoardConnections,
  getGroups,
  createGroup,
  removeGroup,
  getConnection,
  bidMessageRequest,
};
