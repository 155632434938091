import { actionTypes } from './types';

interface IAction {
  type: string;
  data: {};
  error: string;
}

const initialState = {
  allInboxUsers: [],
  allMessages: [],
  currentSelectedUser: {},
  totalUnreadCount: 0,
  newUnreadMessage: false,
  totalUnreadChats: 0,
  allGroups: [],
};

export function chatMessages(state = initialState, action: IAction) {
  switch (action.type) {
    case actionTypes.ALL_INBOX_USERS_SUCCESS:
    case actionTypes.ALL_CONNECTIONS_SUCCESS:
      return {
        ...state,
        allInboxUsers: action.data,
        fetchingAllInboxUser: false,
      };
    case actionTypes.ALL_INBOX_USERS_REQUEST:
    case actionTypes.ALL_CONNECTIONS_REQUEST:
      return {
        ...state,
        fetchingAllInboxUser: true,
      };
    case actionTypes.ALL_INBOX_USERS_FAILURE:
    case actionTypes.ALL_CONNECTIONS_FAILURE:
      return {
        ...state,
        fetchingAllInboxUser: false,
        error: action.error,
      };
    case actionTypes.SET_SELECTED_USER:
      return {
        ...state,
        currentSelectedUser: action.data,
        allMessages: [],
      };
    case actionTypes.ALL_MESSAGES_REQUEST:
      return {
        ...state,
        fetchingAllMessages: true,
      };
    case actionTypes.ALL_MESSAGES_SUCCESS:
      return {
        ...state,
        allMessages: action.data,
        fetchingAllMessages: false,
      };
    case actionTypes.ALL_MESSAGES_FAILURE:
      return {
        ...state,
        fetchingAllMessages: false,
        error: action.error,
      };
    case actionTypes.NEW_UNREAD_MESSAGE:
      return {
        ...state,
        newUnreadMessage: action.data,
        totalUnreadCount: action['totalUnreadCount'],
        totalUnreadChats: action['totalUnreadChats'],
      };
    case actionTypes.GET_GROUPS_REQUEST:
      return {
        ...state,
        fetchingGroups: true,
      };
    case actionTypes.GET_GROUPS_SUCCESS:
      return {
        ...state,
        allGroups: action.data,
        fetchingGroups: false,
        fetchedGroups: true,
      };
    case actionTypes.GET_GROUPS_FAILURE:
      return {
        ...state,
        fetchingGroups: false,
        error: action.error,
      };
    case actionTypes.ADD_GROUP_REQUEST:
      return {
        ...state,
        addingGroup: true,
        addedGroup: false,
      };
    case actionTypes.ADD_GROUP_SUCCESS:
      return {
        ...state,
        addingGroup: false,
        addedGroup: true,
      };
    case actionTypes.ADD_GROUP_FAILURE:
      return {
        ...state,
        addingGroup: false,
        addedGroup: false,
        error: action.error,
      };
    case actionTypes.REMOVE_GROUP_REQUEST:
      return {
        ...state,
        removingGroup: true,
      };
    case actionTypes.REMOVE_GROUP_SUCCESS:
      return {
        ...state,
        removingGroup: false,
        removedGroup: true,
      };
    case actionTypes.REMOVE_GROUP_FAILURE:
      return {
        ...state,
        removingGroup: false,
        error: action.error,
      };
    case actionTypes.CLEAR_CHAT_REDUCERS:
      return {
        ...state,
        allInboxUsers: [],
        allMessages: [],
        currentSelectedUser: {},
        newUnreadMessage: false,
      };
    default:
      return state;
  }
}
